import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import { Button, ButtonBlock } from '../../atoms';
import { cardRemoved } from '../../redux/user/actions';

import './modal.scss';

const Modal = ({
  title,
  text,
  paragraph,
  textSubmit,
  textCancel,
  onSubmit,
  isModalOpen,
  setIsModalOpen,
  useRevert,
  hideButtons = false,
  hideTitle,
  textClassName,
  disableBackdropClick,
  disableEscapeKeyDown,
  buttonTextA11y,
  alert,
  withoutFocusTrap,
  customCss,
  richtext,
  customClass,
  onClose,
  isCustomClose = false,
  buttonTextCloseA11y,
}) => {
  const dispatch = useDispatch();
  const focusDiv = useRef();
  const closeModal = () => {
    setIsModalOpen(false);
    //dispatch(cardRemoved(false));
  };

  useEffect(() => {
    if (isModalOpen) {
      focusDiv.current.focus();
    }
  }, [isModalOpen]);

  const textOnlyClass = hideTitle && hideButtons ? 'modal-text-only' : '';

  return (
    isModalOpen && (
      <>
        <div
          className="modal-overlay"
          role="presentation"
          onClick={disableBackdropClick ? () => null : closeModal}
          onKeyDown={disableEscapeKeyDown ? () => null : closeModal}
        />
        {withoutFocusTrap ? (
          <div
            aria-modal="true"
            id="dialogmodal"
            role="dialog"
            tabIndex="-1"
            ref={focusDiv}
            aria-labelledby="dialog_label"
            aria-describedby="modal-description"
            className={`modal-wrapper ${textOnlyClass}`}
          >
            {!hideTitle && (
              <div className="modal-title" id="dialog_label">
                {title}
              </div>
            )}
            {richtext ? (
              <div id="modal-description" className={`modal-text ${textClassName}`}>
                <RichText field={text} />
              </div>
            ) : (
              <div id="modal-description" className={`modal-text ${textClassName}`}>
                {text}
              </div>
            )}
            {/* {!paragraph ? (
              <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                {text}
              </div>
            ) : (
              <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                {paragraph}
              </div>
            )} */}
            {paragraph && (
              <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                {paragraph}
              </div>
            )}
            {!hideButtons && (
              <ButtonBlock>
                <ButtonBlock right>
                  <Button
                    type="button"
                    onClick={useRevert ? closeModal : onSubmit}
                    buttonTextA11y={buttonTextA11y}
                  >
                    {textSubmit}
                  </Button>
                  {textCancel && (
                    <Button
                      type="button"
                      onClick={useRevert ? onSubmit : closeModal}
                      white
                      firstOrder
                    >
                      {textCancel}
                    </Button>
                  )}
                </ButtonBlock>
              </ButtonBlock>
            )}
          </div>
        ) : (
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: !disableBackdropClick,
              returnFocusOnDeactivate: true,
              escapeDeactivates: !disableEscapeKeyDown,
              fallbackFocus: '#dialogmodal',
            }}
          >
            <div
              aria-modal="true"
              id="dialogmodal"
              tabIndex="-1"
              ref={focusDiv}
              className={`modal-wrapper ${textOnlyClass}  ${customCss ? 'sp-wrapper' : ''} ${customClass && 'daterange-modal'
                }`}
              role="dialog"
              aria-label={!hideTitle ? title : ''}
              aria-describedby="modal-description"
            >
              {!hideTitle && <div className="modal-title">{title}</div>}
              {richtext ? (
                <div id="modal-description" className={`modal-text ${textClassName}`}>
                  <RichText field={text} />
                </div>
              ) : (
                <div id="modal-description" className={`modal-text ${textClassName}`}>
                  {text}
                </div>
              )}
              {/* {!paragraph ? (
                <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                  {text}
                </div>
              ) : (
                <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                  {paragraph}
                </div>
              )} */}

              {paragraph && (
                <div className={`modal-text ${textClassName}  ${customCss ? 'sp-text' : ''}`}>
                  {paragraph}
                </div>
              )}
              {!hideButtons && (
                <ButtonBlock>
                  <ButtonBlock right>
                    <Button
                      type="button"
                      onClick={useRevert ? closeModal : onSubmit}
                      buttonTextA11y={buttonTextA11y}
                    >
                      {textSubmit}
                    </Button>
                    {textCancel && (
                      <Button
                        type="button"
                        onClick={useRevert ? onSubmit : isCustomClose
                          ? onClose : closeModal}
                        white
                        firstOrder
                        buttonTextCloseA11y={isCustomClose && buttonTextCloseA11y}
                      >
                        {textCancel}
                      </Button>
                    )}
                  </ButtonBlock>
                </ButtonBlock>
              )}
            </div>
          </FocusTrap>
        )}
      </>
    )
  );
};

export default Modal;
