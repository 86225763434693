import React, { useState } from 'react';

import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useSwipeable } from 'react-swipeable';

import { IconButton } from '../../atoms';
import circleBlack from '../../assets/images/circle-black.svg';
import circleWhite from '../../assets/images/circle-white.svg';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import './carousel.scss';

const Carousel = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { 'presto-carousel': prestoCarousel } = props.rendering.placeholders;
  const totalpagecount = prestoCarousel.length;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const selectStep = (nextPosition) => {
    if (currentStep + nextPosition === -1) {
      setCurrentStep(3);
    } else if (currentStep + nextPosition === 4) {
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + nextPosition);
    }
  };

  const goToNextStep = () => {
    setIsFirstLoad(false);
    selectStep(1);
  };
  const goToPrevStep = () => {
    setIsFirstLoad(false);
    selectStep(-1);
  };

  const carouselDots = prestoCarousel.map((item, index) => {
    const progressA11y = props.fields.progressA11y.value
      .replace('{pagenumber}', index + 1)
      .replace('{totalpagecount}', totalpagecount);

    const addAccessibilityAlert = index === currentStep && !isFirstLoad;

    return (
      <span
        aria-label={progressA11y}
        aria-hidden={index === currentStep ? 'false' : 'true'}
        className="carousel-bottom-steps-circle"
        id={item.uid}
      >
        <img
          role={addAccessibilityAlert ? 'alert' : ''}
          aria-roledescription=" "
          src={index === currentStep ? circleBlack : circleWhite}
          alt={progressA11y}
        />
      </span>
    );
  });

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNextStep,
    onSwipedRight: goToPrevStep,
  });

  return (
    <div className="carousel-wrapper">
      <div className="carousel" {...swipeHandlers}>
        <Placeholder
          name="presto-carousel"
          rendering={props.rendering}
          currentStep={currentStep}
          prestoCarousel={prestoCarousel}
        />
      </div>
      <div className="carousel-bottom">
        <div className="carousel-bottom-previous">
          <a tabIndex="0" role="button" onKeyPress={() => null} onClick={goToPrevStep}>
            <Text field={props.fields.previous} />
          </a>
          <IconButton
            onClick={goToPrevStep}
            icon={arrowLeft}
            iconAlt={props.fields.previous.value}
          />
        </div>
        <div className="carousel-bottom-steps">{carouselDots}</div>
        <div className="carousel-bottom-next">
          <a tabIndex="0" role="button" onKeyPress={() => null} onClick={goToNextStep}>
            <Text field={props.fields.next} />
          </a>
          <IconButton onClick={goToNextStep} icon={arrowRight} iconAlt={props.fields.next.value} />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
