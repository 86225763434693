import React, { useEffect, useState, useRef } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, ButtonBlock, Loader } from '../../atoms';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import signInFormFields from '../../constants/signInFormFields';
import api from '../../services/api';
import routes from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  fetchAvailableSettings,
  setWeakAccount,
  setGuestUserCartCheckout,
  setGuestUserEnabled,
  setGuestSignPopupEnabled,
  setUserSignedfromGuestFlow,
  setGuestShoppingCartPayload,
  removeFromShoppingCart,
  addShoppingCartMessage,
  setShoppingCart,
  setGuestShoppingCartToken,
} from '../../redux/user/actions';
import {
  localstorageService,
  redirectToUrl,
  updateRecaptchaToken,
  resetAccessTokenValidity,
} from '../../services/utils';
import { Error, TextInput, MediaImageList } from '../../molecules';
import handleServerError from '../../services/handleServerError';
import ReCaptchaFormField from '../shared/ReCaptchaFormField';
import './GuestSignin.scss';
import HeaderLogo from '../../assets/images/PRESTO-Header-Logo.svg';

const GuestSignin = (props) => {
  const [error, setError] = useState('');
  const [errorTitleMsg, setErrorTitleMsg] = useState(false);
  const [errorTitle] = useState(props.t('registeredAccountSignInTitleErrorMessage'));
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [settings, setSettings] = useState([]);
  const [startedLogin, setStartedLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let usernameInputRef = useRef();
  const guestSigninPopup = useSelector((reduxState) => reduxState.user.guestSignPopupEnabled);
  const guestuserCheckout = useSelector((reduxState) => reduxState.user.guestuserCheckoutEnabled);
  const shoppingCart = useSelector((reduxState) => reduxState.user.shoppingCart);
  const cartItems = useSelector((reduxState) => reduxState.user.shoppingCart.items);
  const guestSCPayload = useSelector((reduxState) => reduxState.user.guestShoppingCartPayload);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const guestpopupfromordercard = useSelector(
    (reduxState) => reduxState.user.guestsiginPopupfromOrderCard
  );
  const userInfo = useSelector((s) => s.user);
  const configKeys = useSelector((state) => state.settings.configKeys);
  const [captchaDisable, setCaptchaDisable] = useState(true);
  const {
    fetchAllDataSuccess,
    isWeakPasswordAndQaAVisible,
    isWeakPasswordVisible,
    isWeakQaAVisible,
    emailError,
  } = userInfo;
  const orderLineId =
    cartItems &&
    cartItems.filter((item) => {
      return item.name === 'Single Farecard';
    });

  const handleClose = () => {
    // setOpen(false);
  };

  const GuestContinueCheckoutHandler = () => {
    setOpen(false);
    dispatch(
      setGuestUserCartCheckout({
        continueCheckout: true,
      })
    );
  };

  const GuestContinuefromOrderCardHandler = () => {
    setOpen(false);
    let payload = {
      customerId: '',
      VisibleId: 'NEWMEDIA',
      mediaId: '',
      SctToken: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
      CctToken: '',
      IsGuestFlow: guestUserInfo?.isGuestLogin ? true : false,
    };

    api
      .buyAprestoCardCommenceCheckOut(payload)
      .then((res) => {
        dispatch(setGuestShoppingCartToken(res.data.Cookies));
        dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
        const redirectUrl = routes.checkout;
        redirectToUrl(redirectUrl);
      })
      .catch(() => { });
  };

  const GuestSigninPopupClose = () => {
    dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
    setOpen(false);
  };

  const GuestSiginkeydownHandler = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) {
      dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
      setOpen(false);
    }
  };

  const backToCartHandler = () => {
    dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
    setOpen(false);
  };

  const removeItemfromCart = (callback) => {
    if (orderLineId && orderLineId.length) {
      const data = {
        customerId: userInfo.customerId,
        mediaId: userInfo.selectedCard.visibleId,
        orderLineId: orderLineId && orderLineId.length ? orderLineId[0].orderLineId : '',
        IsAnonymousUser: anonymous ? true : false,
      };
      dispatch(removeFromShoppingCart(data, callback));
      dispatch(addShoppingCartMessage(''));
    } else if (cartItems && cartItems.length) {
      cartItems.forEach((item, index, array) => {
        const data = {
          customerId: userInfo.customerId,
          mediaId: userInfo.selectedCard.visibleId,
          orderLineId: item.orderLineId,
          IsAnonymousUser: anonymous ? true : false,
        };
        if (index === array.length - 1) {
          dispatch(removeFromShoppingCart(data, callback));
        } else {
          dispatch(removeFromShoppingCart(data));
        }
        dispatch(addShoppingCartMessage(''));
      });
    }
  };

  function login(values, setFieldError) {
    setError('');
    // dispatch(itemsAreLoading(true));
    setIsLoading(true);
    dispatch(fetchAllDataSuccessfullyLoaded(undefined));
    return api
      .login({
        [signInFormFields.username]: values[signInFormFields.username],
        [signInFormFields.password]: values[signInFormFields.password],
        [signInFormFields.googleToken]: captchaDisable
          ? 'googleCaptchaToken'
          : values[signInFormFields.googleToken],
      })
      .then((res) => {
        if (res.data.Success) {
          setStartedLogin(true);
          localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Refresh_token);
          localstorageService().setItem(
            localStorageKeys.accessTokenLifetime,
            res.data.Data.Expires_in
          );
          resetAccessTokenValidity();
          dispatch(setGuestShoppingCartPayload(shoppingCart));
          dispatch(changeUserStatus(true));
          dispatch(fetchAllData());
          dispatch(setGuestUserEnabled({ isGuestLogin: false }));
          dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
          GuestSigninPopupClose();

          if (values[signInFormFields.remember]) {
            localstorageService().setItem(
              localStorageKeys.rememberedUsername,
              values[signInFormFields.username] || ''
            );
          } else {
            localstorageService().setItem(localStorageKeys.rememberedUsername, '');
          }
          // setIsLoading(false);
        } else {
          setIsLoading(false);

          if (res.data.Message) {
            const data = JSON.parse(res.data.Message);
            if (data.Code === 'AFMS-DAX-FE-0000' || data.Code === 'AFMS-DAX-FE-0005') {
              setError(props.fields.InvalidUsernamePassword.value);
            }
            if (data.Code === 'AFMS-CRT-FE-0032') {
              setError(props.fields.UnVerifiedEmailError.value);
            }
            if (data.Code === 'AFMS-DAX-FE-0008') {
              setErrorTitleMsg(props.fields.PasswordLimitErrorNext.value);
              setError(props.fields.PasswordLimitErrorNext.value);
            }
          }
        }
      })
      .catch((res) => {
        setIsLoading(false);
        setFieldError(signInFormFields.googleToken, 1);
        handleServerError(undefined, setError)(res);
      });
  }

  const addGuestProducttoCart = async (payload) => {
    await api.addProductToCart(payload).then((res) => {
      dispatch(setShoppingCart(res.data.ShoppingCart.Order, false));
      dispatch(setUserSignedfromGuestFlow(true));
      GuestSigninPopupClose();
      setIsLoading(false);
      // if (guestSigninPopup) {
      //   const redirectUrl = routes.checkout;
      //   redirectToUrl(redirectUrl);
      // }
    });
  };

  const reAddtheItemstoCart = () => {
    let epurse = guestSCPayload.items.filter((x) => x.productFamily === 'Epurse');
    let passes = guestSCPayload.items.filter((x) => x.productFamily === 'CAPP');

    if (epurse.length > 0) {
      let payload = {
        customerId: userInfo.customerId !== undefined ? userInfo.customerId : null,
        visibleId: 'NEWMEDIA',
        productId: epurse[0].id,
        price: epurse[0].subTotal,
        quantity: 1,
        IsNewCardAdded: false,
        IsOrderCard: true,
        IsAnonymousUser: anonymous ? true : false,
      };
      api.addFundToCart(payload).then((res) => {
        dispatch(setShoppingCart(res.data.ShoppingCart.Order, false));
        dispatch(setUserSignedfromGuestFlow(true));
        GuestSigninPopupClose();
        setIsLoading(false);
        // if (guestSigninPopup) {
        //   const redirectUrl = routes.checkout;
        //   redirectToUrl(redirectUrl);
        // }
      });
    }

    if (passes.length > 0) {
      for (let i = 0; i < passes.length; i++) {
        let payload = {
          customerId: userInfo.customerId !== undefined ? userInfo.customerId : null,
          visibleId: 'NEWMEDIA',
          productId: passes[i].id,
          price: passes[i].subTotal,
          quantity: 1,
          IsNewCardAdded: epurse.length > 0 ? true : false,
          IsOrderCard: true,
          IsAnonymousUser: anonymous ? true : false,
        };
        addGuestProducttoCart(payload);
      }
    }
  };

  function redirectAfterLogin() {
    reAddtheItemstoCart();
    // const redirectUrl = routes.checkout;
    // redirectToUrl(redirectUrl);
  }

  function onRedirection() {
    dispatch(itemsAreLoading(false));
    if (isWeakPasswordAndQaAVisible || isWeakPasswordVisible || isWeakQaAVisible) {
      dispatch(setWeakAccount(true));
      // setState({ showPopup: 'true' });
    } else if (!isWeakPasswordAndQaAVisible || !isWeakPasswordVisible || !isWeakQaAVisible) {
      if (!fetchAllDataSuccess && emailError) {
        // handleServerSuccessError(emailError, setError);
        if (emailError) {
          const data = JSON.parse(emailError);
          if (data.Code === 'AFMS-CRT-FE-0032') {
            setError(props.fields.UnVerifiedEmailError.value);
          }
        }
      } else if (fetchAllDataSuccess) {
        redirectAfterLogin();
      } else {
        //console.log('error redirection', fetchAllDataSuccess);
        redirectAfterLogin();
        // handleLogOut(dispatch, '/technical-difficulties');
      }
    }
  }

  useEffect(() => {
    api.getAllBusinessRules().then((res) => {
      if (res.status === 200) {
        setSettings(res.data);
      }
    });
    fetchAvailableSettings(dispatch);
    if (window.outerWidth < 920) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener(
      'resize',
      function (event) {
        if (this.window.outerWidth < 920) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      },
      true
    );
  }, []);

  useEffect(() => {
    if (error) {
      usernameInputRef.focus();
    }
  }, [error]);

  useEffect(() => {
    const reCaptchaDisable = configKeys
      .filter((item) => item.key === 'GoogleRecaptchaV3.Disable')
      .map((config) => config.setting);
    if (reCaptchaDisable.toString() === 'true') {
      setCaptchaDisable(true);
    } else {
      setCaptchaDisable(false);
    }
  }, [configKeys]);

  useEffect(() => {
    if (guestSigninPopup?.enablePopup) {
      setOpen(true);
    }
  }, [guestSigninPopup]);

  useEffect(() => {
    if (!startedLogin || fetchAllDataSuccess === undefined) {
      return;
    }
    setStartedLogin(false);
    if (cartItems && cartItems.length > 0) {
      removeItemfromCart(onRedirection);
    } else {
      onRedirection();
    }
  }, [startedLogin, fetchAllDataSuccess]);

  return (
    <>
      {isLoading && <Loader />}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="modal-header"
        aria-describedby="modal-subHeader"
        open={open}
        className="guest-signin-modal"
      >
        <DialogContent>
          <div className="guest-signin-wrapper">
            <div className="guest-flow-header">
              {!isMobile && (
                <>
                  <div className="presto-title">
                    <img alt="" src={HeaderLogo} />
                  </div>
                  <div
                    tabIndex="0"
                    className="presto-close"
                    role="button"
                    onClick={() => {
                      GuestSigninPopupClose();
                    }}
                    onKeyDown={GuestSiginkeydownHandler}
                  />
                </>
              )}
              {isMobile && (
                <>
                  <div className="guest-flow-backoption" role="button" onClick={backToCartHandler}>
                    <img src={props?.fields?.backToOptionImg?.value?.src} alt="" />
                  </div>
                  <div className="guest-flow-backText">{props.fields.BacktoCart.value}</div>
                </>
              )}
            </div>
            <div className="guest-flow-content">
              <div className="guest-flow-content-title">
                <Text field={props.fields.heading} />
              </div>
              <div className="guest-flow-imagelist">
                <MediaImageList all />
              </div>
              <div className="guest-flow-content-subtext">
                <Text field={props.fields.description} />
              </div>
              {error ? (
                <Error
                  title={errorTitleMsg === error ? props.fields.SignInAttempts.value : errorTitle}
                  text={error}
                  custom
                  noMargin
                />
              ) : null}
              <Formik
                enableReinitialize
                initialValues={{
                  [signInFormFields.username]:
                    localstorageService().getItem(localStorageKeys.rememberedUsername) || '',
                  [signInFormFields.password]: '',
                  [signInFormFields.remember]: !!localstorageService().getItem(
                    localStorageKeys.rememberedUsername
                  ),
                  [signInFormFields.googleToken]: '',
                }}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  username: Yup.string()
                    .required(props.t('registeredAccountMissingUsernameErrorMessage'))
                    .max(100, props.t('registeredAccountInvalidUsernameEmail'))
                    .min(6, props.t('registeredAccountInvalidUsernameEmail'))
                    .matches(
                      /^.[a-zA-Z0-9@\-._+]+$/,
                      props.t('registeredAccountInvalidUsernameEmail')
                    ),
                  password: Yup.string().required(
                    props.t('registeredAccountMissingPasswordErrorMessage')
                  ),
                })}
                onSubmit={(values, { setFieldError }) => {
                  // if (captchaDisable) {
                  //   login(values, setFieldError);
                  // } else {
                  //   updateRecaptchaToken(values, (updatedValues) =>
                  //     login(updatedValues, setFieldError)
                  //   );
                  // }
                  api.b2cGuestSignIn();
                }}
              >
                {({ errors, touched, handleSubmit }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="signin-textfield">
                      <TextInput
                        name={signInFormFields.username}
                        errors={errors}
                        touched={touched}
                        label={props.fields.usernameLabel}
                        length={settings && settings.EmailLength}
                        innerRef={(inputRef) => {
                          usernameInputRef = inputRef;
                        }}
                      />
                    </div>
                    <div className="signin-textfield">
                      <TextInput
                        name={signInFormFields.password}
                        type="password"
                        errors={errors}
                        touched={touched}
                        label={props.fields.passwordLabel}
                        // length={settings && settings.PasswordLength}
                        length={25}
                        showIconA11y={props.fields.showPasswordA11y?.value}
                        hideIconA11y={props.fields.hidePasswordA11y?.value}
                        withViewIcon
                        disabledAutocomplete
                      />
                    </div>

                    <div className="guest-flow-signIn-forgot">
                      <RichText field={props.fields.forgotText} />
                    </div>

                    <label className="guest-flow-signIn-remember">
                      <Field name={signInFormFields.remember} type="checkbox" />
                      <Text field={props.fields.checkboxLabel} />
                    </label>

                    {!captchaDisable && <ReCaptchaFormField />}

                    <div className="guest-flow-button-signIn">
                      <Button type="submit" textTransform>
                        <Text field={props.fields.signInButtonText} />
                      </Button>
                      <div className="guest-flow-or-text">
                        <h2>{props.fields.ortext.value}</h2>
                      </div>
                      {!guestpopupfromordercard && (
                        <>
                          <div className="guest-flow-checkout-button">
                            <Button
                              white
                              firstOrder
                              textTransform
                              onClick={() => GuestContinueCheckoutHandler()}
                            >
                              <Text field={props.fields.checkouttext} />
                            </Button>
                          </div>
                        </>
                      )}

                      {guestpopupfromordercard && (
                        <>
                          <div className="guest-flow-checkout-button">
                            <Button
                              white
                              firstOrder
                              textTransform
                              onClick={() => GuestContinuefromOrderCardHandler()}
                            >
                              <Text field={props.fields.checkouttext} />
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
              <div className="guest-flow-signup-link">
                <RichText field={props.fields.Signuptext} />
              </div>
              <div className="guest-flow-signIn-policy">
                <RichText field={props.fields.policy} />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withTranslation()(GuestSignin);
