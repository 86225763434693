import React, { useState, useRef, useEffect } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import printIcon from '../../assets/images/printer.svg';
import { IconButton, Checkbox, Button, Loader } from '../../atoms';
import routes from '../../constants/routes';
import { StepTracker, TextInput } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import api from '../../services/api';
import {
  fetchUserInfo,
  setMediaNickName,
  setContactlessMediaNickName,
} from '../../redux/user/actions';
import { setEditCardMode } from '../../redux/products/actions';
import cardTypes from '../../constants/mediaSubTypes';
import './updateCardNickName.scss';

const NICKNAME_REGEX = /^([a-zA-Z0-9]){1,25}$/;

const UpdateCardNickName = (props) => {
  const { t } = props;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [nickName, updateNickName] = useState('');
  const manageMyProduct = useSelector((reduxState) => reduxState.products.manageMyProduct);
  const toNextStep = () => setStep(step + 1);
  const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
  const user = useSelector((reduxState) => reduxState.user);
  const prestoCards = useSelector((reduxState) => reduxState.user.fareMedias);
  const contactlessCards = useSelector((reduxState) => reduxState.user.contactlessMedias);
  const fareMedia = useSelector((reduxState) => {
    let presto = reduxState.user.fareMedias.find(
      (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
    );
    let contactless = reduxState.user.contactlessMedias.find(
      (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
    );
    if (presto) return presto;
    if (contactless) return contactless;
    return {};
  });
  const [isPrimary, setIsPrimary] = useState(fareMedia?.isPrimary);
  const dispatch = useDispatch();
  const focusDiv = useRef();

  const prestoUpdateNickName = (payload) => {
    api
      .prestoCardUpdateNickname(payload)
      .then((response) => {
        if (response?.data?.Success) {
          setApiStatus({ submit: true, state: 'SUCCESS' });
          toNextStep();
          dispatch(
            setMediaNickName({
              NickName: nickName,
              IsPrimary: isPrimary,
              visibleId: fareMedia?.visibleId,
            })
          );
          //  fetchUserInfo(dispatch);
        }
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setApiStatus({ submit: false, state: 'ERROR' });
        setLoading(false);
      });
  };
  const contactlessUpdateNickName = (payload) => {
    api
      .contactlessUpdateNickname(payload)
      .then((response) => {
        if (response?.data?.Success) {
          setApiStatus({ submit: true, state: 'SUCCESS' });
          toNextStep();
          //fetchUserInfo(dispatch);
          dispatch(
            setContactlessMediaNickName({
              NickName: nickName,
              visibleId: fareMedia?.visibleId,
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setApiStatus({ submit: false, state: 'ERROR' });
        setLoading(false);
      });
  };
  const checkIsPresto = () => fareMedia.type === cardTypes.presto;
  const handleUpdateNickName = () => {
    let payload = {};
    setLoading(true);
    if (checkIsPresto()) {
      payload = {
        CustomerId: user?.customerId,
        Nickname: nickName,
        MediaId: fareMedia?.mediaId,
        IsPrimary: isPrimary,
        VisibleId: fareMedia?.visibleId,
      };
      prestoUpdateNickName(payload);
    } else {
      payload = {
        AccountId: user?.accountId,
        Nickname: nickName,
        MediaId: fareMedia?.visibleId?.split('|')[0],
      };
      contactlessUpdateNickName(payload);
    }
  };
  const onNickNameChange = (e) => {
    updateNickName(e.target.value);
  };

  useEffect(() => {
    if (manageMyProduct?.editCardDetailsEnabled) {
      focusDiv.current.focus();
      document.body.scrollTop = 0;
    }
  }, [manageMyProduct]);

  useEffect(() => {
    if (apiStatus.submit) {
      focusDiv.current.focus();
      document.body.scrollTop = 0;
    }
  }, [apiStatus]);
  const showMessage = () => {
    let successMsg = props.fields.SucessMessage.value;
    return (
      <div clasName="update-nickname-step2-container">
        <div className="update-nickname-step2" style={{ color: 'green', backgroundColor: 'white' }}>
          <div className="success-message" role="alert">
            <div id="message">{successMsg}</div>
          </div>
          <div className="success-description">
            <p>{props.fields.successDescription.value}</p>
            <p>{nickName}</p>
          </div>
        </div>
        <div className="updated-nickname-footer ">
          <div id="manage-button">
            <Button
              onClick={() => {
                setStep(0);
                updateNickName('');
                setApiStatus({ submit: false, state: 'INITIAL' });
                dispatch(
                  setEditCardMode({
                    editCardDetailsEnabled: false,
                    selectedCard: {},
                  })
                );
              }}
            >
              <Text field={props.fields.ManageCardCTA} />
            </Button>
          </div>
          <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
            {props.fields.PrintText.value}
          </IconButton>
        </div>
      </div>
    );
  };

  return [
    manageMyProduct?.editCardDetailsEnabled ? (
      <div className="update-nick-name ">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <div className="steps">
          <StepTracker
            activeIndex={step}
            items={[
              {
                text: <Text field={props.fields.FirstStepText} />,
                textInProgressA11y: props.fields.Step1InProgressA11y.value,
                textNotCompletedA11y: props.fields.Step2NotCompletedA11y.value,
                textCompletedA11y: props.fields.Step1CompletedA11y.value,
              },
              {
                text: <Text field={props.fields.SecondStepText} />,
                textInProgressA11y: props.fields.Step2InProgressA11y.value,
                textNotCompletedA11y: props.fields.Step2NotCompletedA11y.value,
                textCompletedA11y: props.fields.Step2CompletedA11y.value,
              },
            ]}
          />
        </div>
        <div>
          <div className="update-nickname-edit">
            <Text field={props.fields.Title} />
          </div>

          {!apiStatus.submit ? (
            <div>
              <div className="nickname-wrapper">
                <div className="nickname-example no-focus-outline">
                  <div className="example-text1">
                    <p>
                      {props.fields.CardIdLabel.value}
                      {`${checkIsPresto() ? ` ` : ` ${fareMedia.type.toUpperCase()} `}`}
                      {checkIsPresto() ? fareMedia?.visibleId : fareMedia?.mediaId}
                    </p>
                  </div>
                  <div className="example-text2">
                    <p>{fareMedia?.nickName}</p>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    NickName: nickName,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.NickName) {
                      errors.NickName = props.fields.ErrorMessage1.value;
                    } else if (!NICKNAME_REGEX.test(values.NickName)) {
                      errors.NickName = props.fields.ErrorMessage2.value;
                    } else if (checkIsPresto()) {
                      let checkPrestDuplicate = prestoCards.find(
                        (el) => el.nickName === values.NickName
                      );
                      if (checkPrestDuplicate) {
                        errors.NickName = props.fields.ErrorMessage3.value;
                      }
                    } else if (!checkIsPresto()) {
                      let checkContactlessDuplicate = contactlessCards.find(
                        (el) => el.nickName === values.NickName
                      );
                      if (checkContactlessDuplicate) {
                        errors.NickName = props.fields.ErrorMessage3.value;
                      }
                    }
                    if (Object.prototype.hasOwnProperty.call(errors, 'NickName')) {
                      setCheckError(true);
                    } else {
                      setCheckError(false);
                    }
                    return errors;
                  }}
                  onSubmit={(values) => updateNickName(values.NickName)}
                >
                  {({ errors, touched, handleSubmit, handleChange }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="nickname">
                          <div className="nickname-label">
                            <Text field={props.fields.NewNickNameLabel} />
                          </div>
                          <div className="nickname-textwrap">
                            <TextInput
                              noMarginTop
                              type="text"
                              value={nickName}
                              name="NickName"
                              errors={errors}
                              touched={touched}
                              onChange={(e) => {
                                handleChange(e);
                                onNickNameChange(e);
                              }}
                              label={props.fields.newNicknameLabel}
                              inputA11y={props.fields.NewNickNameLabel.value}
                            />
                          </div>
                          {errors && errors.NickName ? null : (
                            <div className="nickname-sublabel">
                              <Text field={props.fields.TextboxSubLabel} />
                            </div>
                          )}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
                {checkIsPresto() ? (
                  <div className="nickname-checkbox">
                    <div id="checkbox-label">
                      <Checkbox
                        field={props.fields.CheckBoxText}
                        checkboxA11y={props.fields.CheckBoxTextAccessabilityLbl.value}
                        onChange={() => {
                          setIsPrimary(!isPrimary);
                        }}
                        checked={isPrimary}
                      >
                        <Text field={props.fields.CheckBoxText} />
                      </Checkbox>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="updatenickname-buttons">
                <span id="confirmbutton">
                  <Button
                    isDisabled={checkError || nickName.trim() === ''}
                    onClick={() => {
                      handleUpdateNickName();
                    }}
                  >
                    <Text field={props.fields.ConfirmCTAText} />
                  </Button>
                </span>
                <div id="cancelbutton">
                  <Button white firstOrder onClick={() => redirectToUrl(routes.manageMyCards)}>
                    <Text field={props.fields.CancelCTAText} />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            showMessage()
          )}
        </div>
      </div>
    ) : null,
    loading ? <Loader /> : null,
  ];
};

export default withTranslation()(UpdateCardNickName);
