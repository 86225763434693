import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Modal } from '../../../molecules';
import { formatDate, formatDecimalWithCurrency } from '../../../services/utils';
import { Checkbox, RadioInput, Button } from '../../../atoms';
import api from '../../../services/api';

import './agencyOption.scss';

export default function AgencyOption({
  children,
  product,
  name,
  fields,
  showAutoRenew = true,
  values,
  viewPaymentPlan,
  autoRenewName,
  t,
}) {
  const [{ value }] = useField(name);
  const logo = useSelector(
    (state) => state.products?.logos[product?.ProductServiceProvider.Id]?.Logo
  );
  const radioInputA11y = `${product?.ProductName} ${fields.transitPassSelectionA11y?.value}`;
  const [discountResponse, setDiscountResponse] = useState('');
  const [saveAverageValue, setSaveAverageValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  function getTimePeriod(startDate, endDate) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }

  //console.log('TTCSaveAverage', t('TTCSaveAverage'));

  let ttcSaveAverage = t('TTCSaveAverage');
  let updatedttcAverage = '';

  const getPaymentPlan = (productId) => {
    api
      .getAutorenewPassDiscounts({
        productId,
        IsStubEnabled: false,
        IsErrorFlowEnabled: true,
      })
      .then((response) => {
        setDiscountResponse(response?.data?.MDPDetails);
        updatedttcAverage = ttcSaveAverage.replace(
          '{0}',
          response?.data?.MDPDetails[0]?.Discount.toString()
        );
        setSaveAverageValue(updatedttcAverage);
      })
      .catch((error) => {
        //console.log('error', error);
      });
  };

  const viewPaymentPlanModal = () => {
    setIsModalOpen(true);
    setIsPaymentModalOpen(true);
  };

  useEffect(() => {
    if (product?.ProductFamily === 'MDP') {
      getPaymentPlan(product.ProductId);
    }
  }, []);
  return (
    <>
      {isPaymentModalOpen ? (
        <div className="payment-plan-modal">
          <Modal
            title={fields.TTC12MonthMDPTitle?.value}
            text={
              <>
                <RichText field={fields.TTC12MonthMDPDescription} />
                <div className="payment-plan-wrapper">
                  <div className="payment-plan-header">
                    <Text field={fields.TTC12MonthMDPSubTitle} />
                  </div>
                  <div className="payment-plan-content">
                    <div className="payment-plan-subtitle">
                      <span className="ttcTerm">
                        <Text field={fields.TermMonth} />
                      </span>
                      <span className="ttcDiscount">
                        <Text field={fields.TTCPassDiscount} />
                      </span>
                      <span className="ttcPrice">
                        <Text field={fields.DiscountPrice} />
                      </span>
                    </div>
                    {discountResponse &&
                      discountResponse.length > 0 &&
                      discountResponse.map((data) => {
                        return (
                          <div className="payment-plan-data">
                            <span className="ttcTerm">{data.Term}</span>
                            <span className="ttcDiscount">{data.Discount}%</span>
                            <span className="ttcPrice">
                              {formatDecimalWithCurrency(data.DiscountedPrice)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="payment-plan-button">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsPaymentModalOpen(false);
                    }}
                  >
                    {fields.CloseCTA?.value}
                  </Button>
                </div>
              </>
            }
            textSubmit={fields.CloseCTA?.value}
            isModalOpen={isModalOpen}
            onSubmit={() => {
              setIsModalOpen(false);
              setIsPaymentModalOpen(false);
            }}
            setIsModalOpen={setIsModalOpen}
            hideButtons
            withoutFocusTrap={true}
          />
        </div>
      ) : null}
      <div className={`agencyOption ${value === product.ProductId ? 'active' : ''}`}>
        <div className="agencyOption-wrapper">
          <div className="agencyOption-radio">
            <RadioInput
              name={name}
              value={product.ProductId}
              type="radio"
              a11y={radioInputA11y}
              checked={value === product.ProductId}
            />
          </div>
          <div className="agencyOption-title-logo-block">
            {logo && logo.Src ? (
              <img alt={logo.Alt} className="agencyOption-title-logo" src={logo.Src} />
            ) : null}
          </div>
          <div className="agencyOption-title-block">
            <div>
              <div className="agencyOption-title" aria-label={product.ProductName}>
                {product.ProductName}
              </div>
              <div
                className="agencyOption-date"
                aria-label={getTimePeriod(product.ValidityStartDate, product.ValidityEndDate)}
              >
                {getTimePeriod(product.ValidityStartDate, product.ValidityEndDate)}
              </div>
            </div>
          </div>
          <div className="agencyOption-value-block">
            {product.ProductFamily === 'CAPP' ? (
              <div
                className="agencyOption-value"
                aria-label={formatDecimalWithCurrency(product.ProductPrice)}
              >
                {formatDecimalWithCurrency(product.ProductPrice)}
              </div>
            ) : (
              <div>
                <div className="agencyOption-save">
                  {discountResponse && discountResponse?.length && <> {saveAverageValue}</>}
                </div>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    viewPaymentPlanModal();
                  }}
                  type="button"
                  className="agencyOption-plan-link"
                  href=""
                  tabIndex="0"
                >
                  <Text field={fields.paymentPlan} />
                </a>
              </div>
            )}
          </div>
        </div>
        {showAutoRenew ? (
          <div className="agencyOption-autorenew">
            <div className="agencyOption-autorenew-text">
              <RichText field={fields.autorenewDescription} />
            </div>
            <Checkbox name={autoRenewName} checkboxA11y={fields.addAutorenewCheckboxTextA11y.value}>
              <Text field={fields.addAutorenewCheckboxText} />
            </Checkbox>
            {values[autoRenewName] ? <div className="dates">{children}</div> : null}
          </div>
        ) : null}
      </div>
      {/* <Modal
        title="Payment Plan"
        text="You are being redirected to the TTC MDP sign-up page where you can complete your transaction. If you have any items in your shopping cart, they will remain there until you have completed the TTC MDP sign-up and must be processed separately."
        textCancel="Cancel"
        textSubmit="Sign Up"
        useRevert
        onSubmit={() => alert(1)}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      /> */}
    </>
  );
}
