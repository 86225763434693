import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { isFrench } from '../../services/utils';
import { fetchAvailableSettings } from '../../redux/user/actions';

const VirtualAssistant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  const configKeys = useSelector((state) => state.settings.configKeys);

  const baseUrl =
    configKeys &&
    configKeys
      .filter((item) => item.key === 'VirtualAssistant.BaseUrl')
      .map((config) => config.setting);

  const lookupEndpointSettings =
    configKeys &&
    configKeys
      .filter((item) => item.key === 'VirtualAssistant.LookupEndpoint')
      .map((config) => config.setting);

  const langCode = isFrench() ? 'fr' : '';
  const lookupEndpoint = `${baseUrl}${lookupEndpointSettings}`;
  const lookupEndpointWithLangCode = `${lookupEndpoint}${langCode}`;

  useEffect(() => {
    if (
      props.fields.enableWebChat.value &&
      typeof window !== 'undefined' &&
      configKeys?.length > 1
    ) {
      const element = document.getElementById('cv_va_script');
      if (element === null) {
        const script = document.createElement('script');
        script.id = 'cv_va_script';
        script.src = `${baseUrl}cv-va.js`;
        script.async = true;
        document.body.appendChild(script);
        window.cvVASettings = {
          lookup: 'remote',
          lookupEndpoint: lookupEndpointWithLangCode,
        };
      }
      setIsLoaded(true);
    }
  }, [configKeys]);

  useEffect(() => {
    if (configKeys?.length <= 1) {
      fetchAvailableSettings(dispatch);
    }

    var timeout = 0,
      poll = window.setInterval(function () {
        const element = document.getElementById('btncvwebchat');
        if (element !== null) {
          element.addEventListener('click', function () {
            window.CVlaunchVA();
          });
          window.clearInterval(poll);
        } else if (timeout++ > 50) {
          window.clearInterval(poll);
        }
      }, 50);
  }, []);

  return isLoaded ? <script type="text/javascript">window.CVlaunchVA();</script> : null;
};

export default VirtualAssistant;
