import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import routes from '../../constants/routes';
import { ButtonBlock, Button } from '../../atoms';
import { CardBlock, Title, Note } from '../../molecules';
import './redeemVoucherStepperBlock.scss';
import {
  getTimePeriod,
  formatDateTx,
  formatDecimalWithCurrency,
  isFrench,
} from '../../services/utils';

export default function RedeemVoucherStepperBlock1({ fields, toNextStep, toRedeemVoucher }) {
  let redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const program = redeemStepper?.apiData?.VoucherProducts?.program;
  const include = program?.ProgramProducts.filter(
    (ProgramProduct) =>
      ProgramProduct.ProductType === 1 ||
      ProgramProduct.ProductType === 4 ||
      ProgramProduct.ProductType === 5
  );
  const ProductTypeID_5 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 5
  );
  const ProductTypeID_1 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 1
  );
  const ProductTypeID_4 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 4
  );
  const ProductTypeID_3 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 3
  );
  const vn = { value: <RichText field={fields.VoucherDistributionNote} /> };
  const logo = useSelector((state) => state.products.logos);
  const voucherNote = fields.VoucherDistributionNote.value.toString().split('{0}');
  const voucherNote1 = () => {
    const note1 = voucherNote[0].toString();
    const note2 = redeemStepper.voucherCode.toString();
    const note3 = voucherNote[1].toString();
    const note4 = redeemStepper.apiData.ProgrameName.toString();
    const note5 = voucherNote[2].toString();
    const voucherDistributionNote = { value: `${note1}(${note2})${note3}${note4}${note5}` };

    return (
      <div className="voucher-title">
        <RichText field={voucherDistributionNote} />
      </div>
    );
  };
  const getItem = (text1, text2) => (
    <div className="item-wrapper">
      <div className="item">{text1}</div>
      <div className="item">
        <b>{text2}</b>
      </div>
    </div>
  );
  const getItemWithImage = (text1, text2, image, ProgramProduct) => (
    <div className="item-image-wrapper">
      <div className="item-image">
        <img alt={logo[image]?.Logo?.Alt} src={logo[image]?.Logo?.Src} />
      </div>
      <div className="item-wrapper">
        <div className="item">
          <b>{text1}</b>
          <br />
          <div
            className="agencyOption-date"
            aria-label={getTimePeriod(
              ProgramProduct?.PeriodPassStartDate,
              ProgramProduct?.PeriodPassEndDate
            )}
          >
            {getTimePeriod(ProgramProduct?.PeriodPassStartDate, ProgramProduct?.PeriodPassEndDate)}
          </div>
        </div>
        <div className="item">
          <b>{text2}</b>
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <h1 className="redeemvoucher-header">
        <Text field={fields.RedeemVoucherMainTitle} />
      </h1>
      <div className="redeemvoucher-whole-wrapper">
        <div className="redeemvoucher-wrapper">
          <div className="redeemvoucher-tab">
            {voucherNote1()}
            {include.length > 0 && (
              <CardBlock title={fields?.NoCostTitle?.value}>
                {ProductTypeID_5?.map((ProgramProduct) => {
                  if (ProgramProduct.ProductType === 5) {
                    let productName = '';
                    if (isFrench()) {
                      if (
                        fields?.FareTypeStudent ===
                        ProgramProduct?.Concession?.ConcessionShortName_FR
                      ) {
                        productName = fields?.FareTypeYouth;
                      } else {
                        productName = ProgramProduct?.Concession?.ConcessionShortName_FR;
                      }
                    } else if (
                      fields?.FareTypeStudent === ProgramProduct?.Concession?.ConcessionShortName_EN
                    ) {
                      productName = fields?.FareTypeYouth;
                    } else {
                      productName = ProgramProduct?.Concession?.ConcessionShortName_EN;
                    }
                    return [
                      getItem(fields.FareTypeUpdate.value, productName?.toString()),
                      ProgramProduct?.ConcessionEndDate !== null &&
                      ProgramProduct?.Concession?.IsEndDateRequired
                        ? getItem(
                            fields.ExpiryTitle.value,
                            formatDateTx(ProgramProduct?.ConcessionEndDate)
                          )
                        : null,
                    ];
                  }
                })}

                {ProductTypeID_1?.map((ProgramProduct) => {
                  return getItem(
                    fields.VouchercreditTitle.value,
                    '$' + ProgramProduct?.ProductValue?.toString())
                })}

                {ProductTypeID_4?.map((ProgramProduct) => {
                  let productName = '';
                  if (isFrench()) {
                    productName = ProgramProduct?.ProductNameFR;
                  } else {
                    productName = ProgramProduct?.ProductNameEN;
                  }
                  return getItemWithImage(
                    productName ? productName : ProgramProduct?.ProductName,
                    fields?.PassFare?.value?.toString(),
                    ProgramProduct?.ProductOwner,
                    ProgramProduct
                  );
                })}
              </CardBlock>
            )}
          </div>
        </div>
        <div className="redeemvoucher-wrapper secondBlock">
          <div className="redeemvoucher-tab">
            {ProductTypeID_3.length > 0 && (
              <CardBlock title={fields?.RequirePaymentTitle?.value}>
                {ProductTypeID_3.map((ProgramProduct) => {
                  let productName = '';
                  if (isFrench()) {
                    productName = ProgramProduct.ProductNameFR;
                  } else {
                    productName = ProgramProduct.ProductNameEN;
                  }
                  return getItemWithImage(
                    productName ? productName : ProgramProduct?.ProductName,
                    formatDecimalWithCurrency(ProgramProduct?.ProductValue?.toString()),
                    ProgramProduct?.ProductOwner,
                    ProgramProduct
                  );
                })}
              </CardBlock>
            )}
          </div>
        </div>
        <div className="redeemvoucher-wrapper">
          <div className="redeemvoucher-tab note-step1">
            {ProductTypeID_3.length > 0 && (
              <Note withIcon>
                <RichText field={fields.VoucherDetailsEndNote} />
              </Note>
            )}

            <ButtonBlock>
              <ButtonBlock right>
                <Button onClick={toNextStep} buttonTextA11y={fields.NextButtonA11y.value}>
                  <Text field={fields.NextButton} />
                </Button>
                <Button
                  white
                  firstOrder
                  buttonTextA11y={fields.CancelButtonA11y.value}
                  onClick={toRedeemVoucher}
                >
                  <Text field={fields.CancelButton} />
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </div>
        </div>
      </div>
    </>
  );
}
