export default {
  accessToken: 'cws_access_token',
  accessTokenValidity: 'cws_access_token_validity',
  accessTokenLifetime: 'cws_access_token_lifetime',
  dismissedWelcomePopup: 'cws_dismissed_welcome_popup',
  guestToken: 'cws_guest_token',
  reCaptchaKey: 'cws_recaptcha_key',
  refreshToken: 'cws_refresh_token',
  rememberedUsername: 'cws_remembered_username',
  gtmContainerId: 'cws_gtm_container_id',
  uniqueSessionId: 'unique_session_id',
  stsAccessToken: 'sts_access_token',
  redux: 'redux',
  unpaidFaresData: 'unpaidFaresData',
  unpaidFaresReposneData: 'unpaidFaresReposneData',
  unpaidFaresIndex: 'unpaidFaresIndex',
  paymentsToVerifyData: 'paymentsToVerifyData',
  isRiskyUser: 'isRiskyUser',
  isRiskyUserDismissed: 'isRiskyUserDismissed',
};
