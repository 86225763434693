import { useState, useEffect } from 'react';

const useDetectOutsideClick = (el, initialState, handleKeyDown = false, callback = () => null) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (el.current !== null && !el.current.contains(e.target)) {
        if (callback) callback();
        setIsActive(!isActive);
      }
    };

    const pageFocusEvent = () => {
      setTimeout(() => {
        if (el.current !== null && !el.current.contains(document.activeElement)) {
          if (callback) callback();
          setIsActive(!isActive);
        }
      }, 50);
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
      if (handleKeyDown) {
        window.addEventListener('keydown', pageFocusEvent);
      }
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
      if (handleKeyDown) {
        window.removeEventListener('keydown', pageFocusEvent);
      }
    };
  }, [isActive, el]);
  return [isActive, setIsActive];
};

export default useDetectOutsideClick;
