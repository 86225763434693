import React from 'react';
import './inlineError.scss';

const InlineError = ({ children, cssClass }) => {
  return (
    <div className={`inlineError ${cssClass ? cssClass : ''}`} role="alert">
      {children}
    </div>
  );
};

export default InlineError;
