import React, { useCallback } from 'react';

const NavLink = ({ title, isActive, isDropdown, onClick }) => (
  <a
    role="button"
    tabIndex="0"
    aria-label={title}
    aria-expanded={isDropdown}
    aria-pressed={isDropdown}
    onKeyPress={useCallback(() => onClick(title, isActive), [title, isActive])}
    onClick={useCallback(() => onClick(title, isActive), [title, isActive])}
    className={`${isActive ? 'active' : ''} header-link ${isDropdown ? 'dropdown' : ''}`}
  >
    <span>{title}</span>
  </a>
);

export default NavLink;
