import React, { useState, useEffect } from 'react';

import { useFormikContext, useField } from 'formik';
import DatePicker from 'react-datepicker';
import useMedia from 'use-media';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerInput.scss';
import InlineError from '../InlineError';
import { isFrench } from '../../services/utils';

export default function DatePickerInput({ ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);
  const error = props.touched
    ? props.touched[props.name] && props.errors[props.name]
    : props.errors[props.name];
  const isMobile = useMedia({ maxWidth: '920px' });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const customFrLocale = {
    localize: {
      month: (n) => months[n],
      day: (n) => days[n],
    },
    formatLong: {},
  };
  useEffect(() => {
    document.querySelectorAll('.datePickerInput input').forEach((inputElement) => {
      inputElement.setAttribute('aria-expanded', 'false');
      inputElement.setAttribute('role', 'button');
      if (inputElement === document.activeElement && isCalendarOpen) {
        inputElement.setAttribute('aria-expanded', 'true');
      }
    });
  });
  return (
    <div aria-label={props['aria-label']} className="datePickerInput">
      {props.label ? (
        <label className="datePickerInput-label" htmlFor={field.name}>
          {props.label}
        </label>
      ) : null}
      <DatePicker
        {...field}
        {...props}
        // withPortal={isMobile}
        locale={isFrench() ? customFrLocale : 'en'}
        selected={field.value}
        value={field.value}
        placeholderText={isFrench() ? 'MM/JJ/AAAA' : 'MM/DD/YYYY'}
        useWeekdaysShort
        onChange={(val) => {
          if (props.onChange) {
            props.onChange(val);
          }
          setFieldValue(field.name, val);
        }}
        className={isCalendarOpen && 'calendar-is-open'}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
      />

      {error ? (
        <InlineError>{error}</InlineError>
      ) : props.belowText ? (
        <div className="datePickerInput-belowText">{props.belowText}</div>
      ) : null}
    </div>
  );
}
