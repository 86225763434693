import React, { useEffect, useState } from 'react';

import './success.scss';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const SuccessBlock = ({ title, titleA11y, Content, contextText, richText }) => {
  return (
    <div className="success-block">
      <div
        className={`success-block-title ${contextText ? '' : ' without-margin-bottom'}`}
        aria-label={titleA11y}
        role="alert"
      >
        <p>
          <Text field={title} />
        </p>
      </div>
      {contextText && (
        <div className="success-context-text">
          {richText ? <RichText field={contextText} /> : <Text field={contextText} />}
        </div>
      )}
      <div className="success-block-text">{Content ? <Content /> : null}</div>
    </div>
  );
};

export default function Success({ title, titleA11y, Content, contextText, richText }) {
  const [successContent, setSuccessContent] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setSuccessContent(
        <SuccessBlock
          Content={Content}
          title={title}
          titleA11y={titleA11y}
          contextText={contextText}
          richText={richText}
        />
      );
    }, 100);
  }, []);

  return (
    <div aria-live="assertive" aria-atomic="true">
      {successContent}
    </div>
  );
}
