import React, { useState, useEffect, useRef } from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { ImageWithContent } from '../../molecules';
import { chunk } from 'lodash';

const TwoColumnImagesContent = (props) => {
  const { datasource, contextItem } = props.fields.data ? props.fields.data : {};
  //console.log(datasource.children, "Datasource");
  return (
    <div id="presto-perks-first-item">
      {datasource && chunk(datasource.children, 2).map(child => (
        <div class="row perks-column-layout" >
          {child.map(imagecontent => <ImageWithContent imagecontent={imagecontent} />)}
        </div>

      ))}
    </div>
  );
};

export default TwoColumnImagesContent;
