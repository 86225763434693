import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBlock, IconButton } from '../../../atoms';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Error, Success } from '../../../molecules';
import './DeactivateAndTransferAnother2.scss';
import printIcon from '../../../assets/images/printer.svg';
import api from '../../../services/api';

import {
  setUserInfo,
  fetchUserInfoData,
  setDeactivateCardType,
  selectCard,
} from '../../../redux/user/actions';
import { getErrorTranslation } from '../../../services/utils';

const DeactivateAndTransferAnother2 = ({ fields }) => {
  const transferToNickName = useSelector((redux) => redux.user.selectedCardData.nickName);
  const hotlistApiSuccess = useSelector((reduxState) => reduxState.user.hotlistApiSuccess);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const error = hotlistApiSuccess?.Error && JSON.parse(hotlistApiSuccess?.Error);
  let allfareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  const selectedCardData = useSelector((redux) => redux.user.selectedCardData);
  var successMessage = fields?.SuccessMessageForTransferToAnotherCard.value;
  successMessage = {
    value: successMessage.replace('{0}', transferToNickName),
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (hotlistApiSuccess?.Success) {
      dispatch(fetchUserInfoData());
    }
  }, []);

  useEffect(() => {
    if (hotlistApiSuccess?.Success) {
      let targetCard = allfareMedias.find(
        (data) => data.visibleId === selectedCardData?.cardNumber
      );
      if (targetCard === undefined) {
        dispatch(selectCard(allfareMedias[0]));
      } else {
        dispatch(selectCard(targetCard));
      }
    }
  }, [allfareMedias]);

  const GotoDashBoardHandler = () => {
    // dispatch(setDeactivateCardType(''));
    window.location.href = fields?.GotoDashBoardLink?.value?.href;
    // setIsLoading(true);
    // api
    //   .getUserInfo()
    //   .then((response) => {
    //     if (response?.data?.Success) {
    //       dispatch(setUserInfo(response.data.Data));
    //       setIsLoading(false);
    //       window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //     }
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //   });
  };

  const label = {
    value: 'Label',
  };
  const errorBlock = () => {
    const errorCode = error?.Code;
    // let errorMsg = '';
    // errorMsg = getErrorTranslation(errorCode);
    getErrorTranslation(errorCode, (error) => {
      if (error && error.data) {
        setErrorMsg(error.data);
      } else {
        switch (errorCode) {
          case 'AFMS-CRT-MA-0001':
            setErrorMsg(fields.AFMSCRTMA1.value);
            break;
          case 'AFMS-CRT-FE-0030':
            setErrorMsg(fields.AFMSCRTFE3.value);
            break;
          case 'AFMS-DAX-FX-0010':
            setErrorMsg(fields.AFMSDAXFX1.value);
            break;

          default:
            setErrorMsg(fields.AFMSCRTTE1.value);
            break;
        }
      }
    });

    return (
      <>
        <Error
          title={fields.ErrorMessage.value}
          titleA11y={fields.ErrorMessage.value}
          text={<span>{errorMsg}</span>}
          custom={true}
        />
      </>
    );
  };

  const dateTime = '';
  const referenceNumber = '';
  return (
    <>
      <div className="deactivated-card-withButton">
        {hotlistApiSuccess?.Success ? (
          <Success
            title={successMessage}
            titleA11y={successMessage.value}
            contextText={fields?.SuccessDescription}
            Content={() => (
              <div className="success-content-block">
                <div className="content-header">
                  <Text field={fields.ReferenceLabel} />
                </div>
                {dateTime && (
                  <div className="date-time">
                    <span>
                      <Text field={fields.DateTimeLabel} />
                    </span>
                    {/* <span className="bold-content">20 Apr 2022 00:00AM</span> */}
                  </div>
                )}
                {referenceNumber && (
                  <div className="reference-number">
                    <span>
                      <Text field={fields.ReferenceNumber} />
                    </span>
                    {/* <span className="bold-content">0123456789</span> */}
                  </div>
                )}
              </div>
            )}
          />
        ) : (
          errorBlock()
        )}
        <div className="deactivate-print">
          <ButtonBlock className="Deactivated-Button">
            <Button onClick={GotoDashBoardHandler}>
              <Text field={fields.GotoDashBoard} />
            </Button>
          </ButtonBlock>
          <IconButton onClick={() => window.print()} icon={printIcon}>
            {fields.Print.value}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default DeactivateAndTransferAnother2;
