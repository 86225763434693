import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { isJsonString, hasLongInputLabel } from '../../services/utils';
import apiResponses from '../../constants/apiResponses';
import api from '../../services/api';
import { updateUserInfo } from '../../redux/user/actions';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { ButtonBlock, Button } from '../../atoms';
import { TextInput, CardBlock, Header, Error } from '../../molecules';

export default function AddEmail0({ fields, toNextStep, setState, t, userInfo, dispatch }) {
  const [inlineErrorEmail, setInlineErrorEmail] = useState();
  const [inlineErrorPassword, setInlineErrorPassword] = useState();
  const [error, setIsError] = useState();

  return (
    <>
      <Header headerTitleA11y={fields.headingA11y?.value}>
        <Text field={fields.heading} />
      </Header>
      <CardBlock
        title={fields.verificationHeading.value}
        titleA11y={fields.verificationHeadingA11y?.value}
        errorIcon
      >
        <Text field={fields.verificationNote} />

        {error && <Error title={t('updateEmailCouldNotBeProcessed')} />}

        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            confirmEmail: '',
            password: '',
          }}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required(t('updateEmailNotValidEmail'))
              .matches(
                /^[\w+_-]+(?:\.[\w+_-]+)*@(?:[\w\d-]+\.)+\w{2,6}$/,
                t('updateEmailNotValidEmail')
              ),
            confirmEmail: Yup.string()
              .required(t('updateEmailNotValidEmail'))
              .oneOf([Yup.ref('email'), null], t('updateEmailNotMatching')),
            password: Yup.string().required(t('updateEmailBlankPassword')),
          })}
          onSubmit={(values) => {
            api
              .updateLoginEmail({
                CustomerId: userInfo.customerId,
                LoginEmail: values.email,
                Password: values.password,
              })
              .then((response) => {
                setInlineErrorPassword('');
                setInlineErrorEmail('');
                setIsError('');

                if (response.data.Success) {
                  setState({
                    newEmail: values.email,
                  });
                  dispatch(updateUserInfo());
                  toNextStep();
                } else {
                  const parsedMessageObject =
                    response.data.Error &&
                    isJsonString(response.data.Error) &&
                    JSON.parse(response.data.Error);
                  if (parsedMessageObject.Code === apiResponses.securityWrongPassword) {
                    setInlineErrorPassword(t('updateEmailWrongPassword'));
                  } else if (parsedMessageObject.Code === apiResponses.securityEmailAlreadyExists) {
                    setInlineErrorEmail(t('updateEmailAlreadyUsed'));
                  } else {
                    handleServerSuccessError(response.data.Error, setIsError);
                  }
                }
              });
          }}
        >
          {({ errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="emailVerification-formGrid">
                <div className="emailVerification-formRow">
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="email"
                      errors={errors}
                      touched={touched}
                      label={fields.enterEmailLabel}
                      hasLongLabel={hasLongInputLabel(
                        fields.enterEmailLabel,
                        fields.confirmEmailLabel
                      )}
                      serverError={inlineErrorEmail}
                    />
                  </div>
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="confirmEmail"
                      errors={errors}
                      touched={touched}
                      label={fields.confirmEmailLabel}
                      hasLongLabel={hasLongInputLabel(
                        fields.enterEmailLabel,
                        fields.confirmEmailLabel
                      )}
                    />
                  </div>
                </div>
                <div className="emailVerification-formRow">
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="password"
                      errors={errors}
                      touched={touched}
                      label={fields.passwordLabel}
                      type="password"
                      serverError={inlineErrorPassword}
                      withViewIcon
                      showIconA11y={fields?.showPasswordA11y?.value}
                      hideIconA11y={fields?.hidePasswordA11y?.value}
                    />
                  </div>
                </div>
              </div>

              <ButtonBlock>
                <ButtonBlock right>
                  <Button type="submit" buttonTextA11y={fields.verifyEmailButtonText?.value}>
                    <Text field={fields.verifyEmailButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </CardBlock>
    </>
  );
}
