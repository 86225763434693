export default function handleAVSValidationErrorSPM(queryParams, t) {
  if (!queryParams) {
    // eslint-disable-next-line consistent-return
    return;
  }
  if (queryParams.AVSResponse !== undefined && queryParams.AVSResponse.toUpperCase() === 'FALSE') {
    if (
      queryParams.CVVResponse !== undefined &&
      queryParams.CVVResponse.toUpperCase() === 'FALSE'
    ) {
      // eslint-disable-next-line consistent-return
    } else {
      // eslint-disable-next-line consistent-return
      return t('transactionDeclinedAVS');
    }
  }
}
