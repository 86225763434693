import React, { useState } from 'react';

import { Formik } from 'formik';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import { formatDecimalWithCurrency } from '../../services/utils';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { Button, ButtonBlock } from '../../atoms';
import { Error, ValueRow } from '../../molecules';

export default function CancelAutoload1({ toNextStep, toFirstStep, userInfo, product, fields }) {
  const [error, setIsError] = useState('');
  if (product === null) {
    return null;
  }
  return (
    <>
      {error && <Error title={error} />}
      <div className="loadMyCard-wrapper">
        <h1 tabIndex="0" className="autoload-header">
          <Text field={fields.heading} />
        </h1>
        <div className="autoload-setup">
          <div className="autoload-subheader">
            <Text field={fields.description} />
          </div>
        </div>
        <div className="autoload-tab">
          <div className="autoload-rows">
            <ValueRow left={fields.cardNameLabel.value} right={userInfo.selectedCard.nickName} />
            <ValueRow
              left={fields.loadLabel.value}
              right={formatDecimalWithCurrency(product.Amount)}
            />
            <ValueRow
              left={fields.dropLabel.value}
              right={formatDecimalWithCurrency(product.Threshold)}
              noBorder
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              customerId: '',
              visibleId: '',
            }}
            validateOnChange={false}
            onSubmit={() => {
              api
                .cancelAutoloadSubscription({
                  customerId: userInfo.customerId,
                  visibleId: userInfo.selectedCard.visibleId,
                })
                .then((response) => {
                  if (response.data.IsCancelled) {
                    toNextStep();
                  } else {
                    handleServerSuccessError(response.data.Error, setIsError);
                  }
                });
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ButtonBlock>
                  <ButtonBlock right>
                    <Button type="submit" buttonTextA11y={fields.confirmButtonTextA11y.value}>
                      <Text field={fields.confirmButtonText} />
                    </Button>
                    <Button
                      firstOrder
                      white
                      onClick={toFirstStep}
                      buttonTextA11y={fields.backButtonTextA11y.value}
                    >
                      <Text field={fields.backButtonText} />
                    </Button>
                  </ButtonBlock>
                </ButtonBlock>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
