import React, { useEffect, useRef, useState } from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { SkipContentLink } from '../../organisms';
import './fullWidthLayout.scss';
import { documentService, scrollToView } from '../../services/utils';

const mainContentId = 'mainContent';

const FullWidthLayout = ({ rendering, t }) => {
  const pageRef = useRef();
  const [showcontent, setShowContent] = useState(false);

  const googleWalletPage = rendering?.params?.pageCSS;

  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);

  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');

    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
    setIsSkipTranslateHidden(skipTranslateElement && getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none');
    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');

    if (targetElement && !isSkipTranslateHidden) {
      if (!smartBanner) {
        targetElement.style.top = '0px';
      }
      else {
        targetElement.style.top = '80px';
      }
    }
  };

  useEffect(() => {
    // Call the handleDOMChanges function initially
    handleDOMChanges();

    // Set up a recurring check using setInterval
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 100); // Check every 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    pageRef.current.focus();
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setShowContent(true);
      }
    }, 100);
  }, []);
  return (
    <>
      <div ref={pageRef} className="full-width-wrapper">
        <SkipContentLink linkToId={mainContentId} linkText={t('skipMainMenuText')} />
        <div className={isSmartBannerPresent ? 'presto-fullwidth-navbar-with-padding' : 'presto-fullwidth-navbar'}>
          <Placeholder name="presto-header" rendering={rendering} />
        </div>
        <div
          id={mainContentId}
          className={`main-content ${showcontent ? '' : 'd-none'} ${
            googleWalletPage ? 'google-wallet-page-main-content' : ''
          }`}
        >
          <Placeholder name="presto-fullwidth-content" rendering={rendering} />
        </div>
      </div>
      <div className={`${showcontent ? '' : 'd-none'}`}>
        <Placeholder name="presto-footer" rendering={rendering} />
      </div>
    </>
  );
};

export default withTranslation()(FullWidthLayout);
