import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton, Loader } from '../../../atoms';
import { Error, Success } from '../../../molecules';
import printIcon from '../../../assets/images/printer.svg';
import { formatDecimalWithCurrency, getErrorTranslation } from '../../../services/utils';
import api from '../../../services/api';
import {
  setUserInfo,
  fetchUserInfoData,
  setDeactivateCardType,
  selectCard,
} from '../../../redux/user/actions';

const DeactivateAndTransfer2 = ({ toPreviousStep, fields, userInfo }) => {
  const visibleId = userInfo?.selectedCard?.visibleId;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const hotlistApiSuccess = useSelector((reduxState) => reduxState.user.hotlistApiSuccess);
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias = fareMedias.find((data) => data.visibleId === visibleId);
  const currentBalance = fareMedias?.currentBalance;
  const userFareMedias = hotlistApiSuccess?.data?.Data?.Customer?.FareMedias;
  const verificationNumber =
    userFareMedias && userFareMedias.length > 0 && userFareMedias[0]?.VerificationNumber;
  const transitPasses =
    fareMedias && fareMedias?.passes && fareMedias?.passes.length > 0 && fareMedias?.passes;
  const newCardDetails = useSelector((reduxState) => reduxState.user.newCardDetails);
  const error = hotlistApiSuccess?.Error && JSON.parse(hotlistApiSuccess.Error);
  let allfareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  useEffect(() => {
    document.body.scrollTop = 0;
    if (hotlistApiSuccess?.Success) {
      dispatch(fetchUserInfoData());
    }
  }, []);

  useEffect(() => {
    if (hotlistApiSuccess?.Success) {
      let targetCard = allfareMedias.find(
        (data) => data.visibleId === newCardDetails?.newCardNumber
      );
      if (targetCard === undefined) {
        dispatch(selectCard(allfareMedias[0]));
      } else {
        dispatch(selectCard(targetCard));
      }
    }
  }, [allfareMedias]);

  const GotoDashBoardHandler = () => {
    //dispatch(setDeactivateCardType(''));
    window.location.href = fields?.GotoDashBoardLink?.value?.href;
    // setIsLoading(true);
    // api
    //   .getUserInfo()
    //   .then((response) => {
    //     if (response?.data?.Success) {
    //       dispatch(setUserInfo(response.data.Data));
    //       setIsLoading(false);
    //       window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //     }
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //   });
  };
  const errorBlock = () => {
    const errorCode = error?.Code;
    // let errorMsg = '';
    // let errorMsg = getErrorTranslation(errorCode);
    getErrorTranslation(errorCode, (error) => {
      if (error && error.data) {
        setErrorMsg(error.data);
      } else {
        switch (errorCode) {
          case 'AFMS-CRT-MA-0001':
            setErrorMsg(fields.AFMSCRTMA1.value);
            break;
          case 'AFMS-CRT-FE-0008':
            setErrorMsg(fields.AFMSCRTFE8.value);
            break;
          case 'AFMS-CRT-FE-0038':
            setErrorMsg(fields.AFMSCRTFE6.value);
            break;
          case 'AFMS-CRT-FE-0006':
            setErrorMsg(fields.AFMSCRTFE6.value);
            break;
          case 'AFMS-DAX-FE-0003':
            setErrorMsg(fields.AFMSDAXFE3.value);
            break;
          case 'AFMS-DAX-FE-0008':
            setErrorMsg(fields.AFMSDAXFE8.value);
            break;
          case 'RLCCCTP-AStep3-06':
            setErrorMsg(fields.AFMSDAXFE3.value);
            break;
          case 'AFMS-CRT-FE-0039':
            setErrorMsg(fields.AFMSCRTFE39.value);
            break;

          default:
            setErrorMsg(fields.OrderFailureMessage.value);
            break;
        }
      }
    });

    return (
      <>
        <Error
          title={fields.ErrorMessage.value}
          titleA11y={fields.ErrorMessage.value}
          text={<span>{errorMsg}</span>}
          custom={true}
        />
      </>
    );
  };

  let formattedBalance = formatDecimalWithCurrency(currentBalance);
  return (
    <>
      <>
        {isLoading && <Loader />}
        <div className="deactivated-card-withButton">
          {hotlistApiSuccess?.Success ? (
            <Success
              title={fields.NewCardSucessMessage}
              titleA11y={fields.NewCardSucessMessage.value}
              contextText={fields.NewCardSuccessSubNote}
              Content={() => (
                <>
                  <div className="deactivated-card-block">
                    <div className="card-content">
                      {newCardDetails?.newCardNumber && (
                        <div className="card-Number">
                          <RichText field={fields.NewPrestoCardNumber} />
                          <span>
                            <b>{newCardDetails.newCardNumber}</b>
                          </span>
                        </div>
                      )}
                      {verificationNumber && (
                        <div className="card-nick-name">
                          <RichText field={fields.VerificationNumber} />
                          <span>
                            <b>{newCardDetails?.cardVerificationNumber}</b>
                          </span>
                        </div>
                      )}
                      {currentBalance && (
                        <div className="card-balance">
                          <RichText field={fields.BalanceLabel} />
                          <span>
                            <b>{formattedBalance}</b>
                          </span>
                        </div>
                      )}

                      <div className="card-transitPass">
                        <RichText field={fields.TransitPassLabel} />
                        {transitPasses && transitPasses.length > 0 ? (
                          <div>
                            {transitPasses.map((pass) => {
                              if (pass.ProductName !== null) {
                                return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                              }
                            })}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
          ) : (
            errorBlock()
          )}
          <div className="deactivate-print">
            {!hotlistApiSuccess.hotlistApiSuccess ? (
              <div className="manage-button">
                <Button onClick={GotoDashBoardHandler}>
                  <Text field={fields.GotoDashBoard} />
                </Button>
              </div>
            ) : (
              <div className="manage-button">
                <Button
                  onClick={() => {
                    toPreviousStep();
                  }}
                >
                  <Text field={fields.TryAgainBtnLable} />
                </Button>
              </div>
            )}
            <IconButton onClick={() => window.print()} icon={printIcon}>
              {fields.Print.value}
            </IconButton>
          </div>
        </div>
      </>
    </>
  );
};

export default DeactivateAndTransfer2;
