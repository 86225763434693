import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, PrintButton } from '../../atoms';
import { Success, Header } from '../../molecules';

export default function AddEmail1({ state, toNextStep, fields, onVerifyEmailAddress }) {
  return (
    <>
      <Header headerTitleA11y={fields.headingA11y?.value}>
        <Text field={fields.heading} />
      </Header>
      <Success
        title={fields.successfullyVerifiedHeading}
        Content={() => (
          <div className="emailVerification-successBlockContent regular-font">
            <Text field={fields.successfullyVerifiedNote1} />
            <div className="emailVerification-newEmail bold-font">{state.newEmail}</div>
            <Text field={fields.successfullyVerifiedNote2} />
          </div>
        )}
      />
      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={toNextStep}
            white
            buttonTextA11y={fields.updateEmailButtonTextA11y?.value}
          >
            <Text field={fields.updateEmailButtonText} />
          </Button>
          <Button
            buttonTextA11y={fields.resendVerificationButtonTextA11y?.value}
            onClick={() => onVerifyEmailAddress()}
          >
            <Text field={fields.resendVerificationButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
}
