import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { CardBlock } from '../../molecules';

const Card = (props) => (
  <CardBlock title={props.fields.heading.value} titleA11y={props.fields.headingA11y.value}>
    <Placeholder name="presto-card-content" rendering={props.rendering} />
  </CardBlock>
);

export default Card;
