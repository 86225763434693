import React from 'react';

import './digital-distribution.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const DigitalDistributionServices = (props) => {
  return (
    <div>
      <div className="learn-note">
        <RichText field={props.fields.heading} />
      </div>
      <div className="digital-distribution-app-icon">
        <a
          className="icon"
          href={props.fields.appStoreLink.value.href}
          title={props.fields.appStoreLink.value.title}
          target={props.fields.appStoreLink.value.target}
        >
          <img
            alt={props.fields.appStoreImage.value.alt}
            src={props.fields.appStoreImage.value.src}
          />
        </a>

        <a
          className="icon"
          href={props.fields.googlePlayLink.value.href}
          title={props.fields.googlePlayLink.value.title}
          target={props.fields.googlePlayLink.value.target}
        >
          <img
            alt={props.fields.googlePlayImage.value.alt}
            src={props.fields.googlePlayImage.value.src}
          />
        </a>
      </div>
    </div>
  );
};

export default DigitalDistributionServices;
