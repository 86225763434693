import React from 'react';
const SogrButton = (props) => (
  <div>
    <a href={props.fields.buttonLink.value.href} arialabel={props.fields.AccesibilityLabel.value}>
      {props.fields.buttonLink.value.text}
    </a>
  </div>
);

export default SogrButton;
