import React from 'react';

import { withTranslation } from 'react-i18next';
import './printButton.scss';

const PrintButton = ({ t, onClick }) => (
  <button
    type="button"
    onClick={onClick ? onClick : () => window.print()}
    className="print-button"
    aria-label={t('printButtonLabelA11y')}
  >
    {t('printButtonLabel')}
  </button>
);

export default withTranslation()(PrintButton);
