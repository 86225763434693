import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { formatDecimalWithCurrency, formatDate, redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import { showScreenHeader } from '../../redux/user/actions';
import { PrintButton, ButtonBlock, Button } from '../../atoms';
import { Success, InlineButtonBlock } from '../../molecules';

export default function CancelAutoload2({ fields, product }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showScreenHeader(false));
  }, []);

  if (product === null) {
    return null;
  }

  return (
    <div className="autoload-success-wrapper">
      <h1 tabIndex="0" className="autoload-header">
        <Text field={fields.heading} />
      </h1>

      <Success
        Content={() => (
          <>
            <div
              className="success-block-title"
              aria-label={fields.successMessageA11y.value}
              tabIndex="0"
            >
              <Text field={fields.successMessage} />
            </div>
            <div className="autoload-setup autoload-success">
              <div
                className="autoload-description"
                aria-label={fields.confirmationDescriptionA11y.value}
                tabIndex="0"
              >
                <RichText field={fields.confirmationDescription} />
              </div>
              <div className="setup-section">
                <div className="section-details-amount no-top-border">
                  <div>
                    <Text field={fields.canceledAmount} />
                  </div>
                  <div className="amount">{formatDecimalWithCurrency(product.Amount)}</div>
                </div>
                <div className="section-details-amount no-top-border">
                  <div>
                    <Text field={fields.dropLabel} />
                  </div>
                  <div className="amount">{formatDecimalWithCurrency(product.Threshold)}</div>
                </div>
                <div className="section-details-amount no-top-border">
                  <div>
                    <Text field={fields.cancelationDate} />
                  </div>
                  <div className="amount">{formatDate(Date.now())}</div>
                </div>
              </div>
              <div className="autoload-description">
                <Text field={fields.confirmationEmailAnnotation} />
              </div>
              <InlineButtonBlock
                className="mobileOnly"
                onClick={() => {
                  dispatch(showScreenHeader(true));
                  redirectToUrl(routes.loadMyCard);
                }}
                buttonText={fields.backToCardButtonText}
                buttonTextA11y={fields.backToCardButtonTextA11y.value}
              />
            </div>
          </>
        )}
      />
      <ButtonBlock className="desktopOnly">
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={() => {
              dispatch(showScreenHeader(true));
              redirectToUrl(routes.loadMyCard);
            }}
            buttonTextA11y={fields.backToCardButtonTextA11y.value}
          >
            <Text field={fields.backToCardButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </div>
  );
}
