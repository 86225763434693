import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  setContactlessUserSignedIn,
  setSignInOverlay,
  setIframeEnabled,
  setUserInfoData,
  setSignOutRedirectUrl,
  setSettings,
  setRedirectUrl,
  setContactlessLogInTime,
  setContactlessLogoutTime,
} from '../../redux/contactless/actions';
import { MediaImage } from '../../atoms';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  getLocalStorageItem,
  localstorageService,
  sessionstorageService,
  getShortLanguageCode,
} from '../../services/utils';
import { MediaImageList } from '../../molecules';
import localStorageKeys from '../../constants/localStorageKeys';
import moment from 'moment';
import CloseSideNavImage from '../../assets/images/HamburgerMenuClose.svg';
import './contactLessSignInOverlay.scss';
import api from '../../services/api';
import { Error } from '../../molecules';
import { useLocation } from 'react-router-dom';
import { truncate } from 'lodash';
import useMediaWidth from '../../hooks/useMediaWidth';

const ContactLessSignInOverlay = (props) => {
  const signinItems = props?.fields?.items[1]?.fields;
  const dispatch = useDispatch();
  const isMobile = useMediaWidth();
  const signInModal = useSelector((state) => state.contactless.signInModal);
  const isIframeEnabled = useSelector((state) => state.contactless.isIframeEnabled);
  const isUserSignedIn = useSelector((state) => state.contactless.isContactlessUserSignedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [isInteracTab, setInteracTab] = useState(false);
  const tavEnable = props?.fields?.items[2]?.fields?.EnableCard?.value;
  const location = useLocation();
  const configKeys = useSelector((state) => state.contactless.configKeys);
  const signOutRedirectLink = useSelector((state) => state.contactless.signOutRedirectUrl);
  const pageLanguage = getShortLanguageCode();
  const uniqueSessionId = getLocalStorageItem(localStorageKeys.uniqueSessionId);

  useEffect(() => {
    const tokenExpiry = sessionstorageService().getItem('TokenExpiry');
    const currentTime = new Date();
    const loginTime = sessionstorageService().getItem('LoginTime');

    const formattedLoginTime = new Date(loginTime);
    const expiredTime = new Date(formattedLoginTime?.getTime() + tokenExpiry * 1000);
    const timeInterval = expiredTime - currentTime;
    const isShowSessionOut = sessionStorage?.getItem('ShowSessionOut');
    const accessToken = localstorageService().getItem(localStorageKeys.accessToken);
    const refreshToken = localstorageService().getItem(localStorageKeys.refreshToken);
    if (isUserSignedIn) {
      if (timeInterval <= 0 || !isShowSessionOut || !accessToken || !refreshToken) {
        signOutUser();
      }
    } else {
      if (timeInterval > 0 && isShowSessionOut && accessToken && refreshToken) {
        dispatch(setContactlessUserSignedIn(true));
      }
    }
    dispatch(setSignOutRedirectUrl(signinItems?.SignOutRedirectLink?.value?.href));
    dispatch(setRedirectUrl(signinItems?.RedirectLink?.value?.href));
    configData();
  }, []);

  const configData = () => {
    return new Promise((resolve, reject) => {
      api
        .getAvailableSettings()
        .then(({ data }) => {
          if (!data?.Success) {
            reject();
          } else {
            dispatch(setSettings(data));
            resolve();
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  const signOutUser = () => {
    localstorageService().removeItem(localStorageKeys?.accessToken);
    localstorageService().removeItem(localStorageKeys?.refreshToken);
    sessionstorageService().clear();
    dispatch(setContactlessUserSignedIn(false));
    let path = location?.pathname?.split('/');
    path = path[path?.length - 1];
    let redirectUrl = signOutRedirectLink?.split('/');
    redirectUrl = redirectUrl[redirectUrl?.length - 1];
    if (path !== redirectUrl) {
      window.location.href = signOutRedirectLink;
    }
  };

  const getUserInfo = () => {
    const redirectUrl = signinItems?.RedirectLink?.value?.href;
    return new Promise((resolve, reject) => {
      api
        .getUserinfoContactless()
        .then((response) => {
          dispatch(setUserInfoData(response?.data?.Data));
          resolve();
          setIsLoading(false);
          dispatch(setSignInOverlay(false));
          dispatch(setContactlessLogInTime(moment.utc().format()));
          dispatch(setContactlessLogoutTime(moment.utc().add(15, 'm').format()));
          window.location.href = redirectUrl;
        })
        .catch(() => {
          //console.log('error');
          reject();
          setIsLoading(false);
          dispatch(setSignInOverlay(false));
        });
    });
  };

  if (typeof window !== 'undefined') {
    window.addEventListener(
      'message',
      (event) => {
        if (typeof event?.data === 'string' && event?.data !== 'recaptcha-setup') {
          const data = JSON.parse(event?.data);
          if (data && data?.statusCode != null) {
            if (
              data?.statusCode === 'STS_E_1002' ||
              data?.statusCode === 'STS_E_1005' ||
              data?.statusCode === 'STS_E_1008'
            ) {
              setErrorCode(data?.statusCode);
              dispatch(setIframeEnabled(false));
            } else if (data?.statusCode === 'STS_E_1010' || data?.statusCode === 'STS_E_1003' || data?.statusCode === 'STS_E_1020') {
              setErrorCode(data?.statusCode);
              dispatch(setIframeEnabled(true));
              document.querySelectorAll('iframe').forEach(function (e) {
                e.src += '';
              });
            }
          }

          if (
            data &&
            (data?.statusCode === 'STS_I_1001' ||
              data?.statusCode === 'STS_I_1007' ||
              data?.statusCode === 'STS_E_1004')
          ) {
            setErrorCode('');
            dispatch(setIframeEnabled(false));
            setIsLoading(true);
            setTimeout(function () {
              sessionstorageService().setItem('ShowSessionOut', true);
            }, data?.TokenExpiry);
            sessionstorageService().setItem('ShowSessionOut', true);
            sessionstorageService().setItem('TokenExpiry', data?.TokenExpiry);
            sessionstorageService().setItem('LoginTime', new Date());
            localstorageService().setItem(localStorageKeys?.accessToken, data?.AccessToken);
            localstorageService().setItem(localStorageKeys?.refreshToken, data?.RefreshToken);
            dispatch(setContactlessUserSignedIn(true));
            getUserInfo();
          }
        }
        event.stopImmediatePropagation();
        event.preventDefault();
      },
      false
    );
  }

  const loader = () => {
    return (
      <div className="loader">
        <img src={signinItems?.['Loading Image']?.value?.src} />
      </div>
    );
  };

  const errorBlock = () => {
    const errorItem = props?.fields?.items?.filter((data) => {
      return data?.fields['Error Code']?.value === errorCode;
    });
    const errorContent = errorItem[0]?.fields?.['Error Title']?.value;
    const errorDescription = errorItem[0]?.fields?.['Error Message'];
    const refreshValue = errorItem[0]?.fields['Error Button Text']?.value;
    if (errorCode === 'STS_E_1008' || errorCode === 'STS_E_1002') {
      return (
        <>
          <Error
            title={errorContent ? errorContent : ''}
            titleA11y={errorDescription?.value}
            text={errorDescription}
          />
          <div className="popup-button">
            <button
              onClick={() => {
                setErrorCode('');
                dispatch(setIframeEnabled(true));
                document.querySelectorAll('iframe').forEach(function (e) {
                  e.src += '';
                });
              }}
            >
              {refreshValue}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <Error
          title={errorContent ? errorContent : ''}
          titleA11y={errorDescription?.value}
          text={errorDescription}
        />
      );
    }
  };

  const closeOverlay = () => {
    const event = window.event;
    if (event?.keyCode === 13) {
      dispatch(setSignInOverlay(false));
    }
  };

  useEffect(() => {
    let tabs = document.querySelectorAll('[role="tab"]');
    if (isInteracTab && tabs.length > 0) {
      tabs[1].setAttribute('tabindex', '0');
      tabs[0].setAttribute('tabindex', '-1');
    } else if (tabs.length > 0) {
      tabs[0].setAttribute('tabindex', '0');
      tabs[1].setAttribute('tabindex', '-1');
    }
  }, [isInteracTab]);

  const cardsTabChangeHandler = (index, e) => {
    let tabs = document.querySelectorAll('[role="tab"]');
    if (e.keyCode == 39 || e.keyCode == 37) {
      if (index == 0 && tabs.length > 0) {
        tabs[1].focus();
      } else if (tabs.length > 0) {
        tabs[0].focus();
      }
    }
  };

  const getTab = (cardType, isCredit, isDebit) => {
    return (
      <button
        className={`card ${isCredit && !isInteracTab ? 'active' : ``} ${isDebit && isInteracTab ? 'active' : ``
          }`}
        onClick={() => {
          setInteracTab(isCredit ? false : true);
        }}
        role="tab"
        aria-selected={isCredit && !isInteracTab ? true : isDebit && isInteracTab ? true : false}
        aria-controls={isCredit ? 'credit-tab' : 'debit-tab'}
        id={isCredit ? 'creditsection' : 'debitsection'}
        tabIndex={isCredit ? '0' : '-1'}
        onKeyDown={(e) => {
          let index = isCredit ? 0 : 1;
          cardsTabChangeHandler(index, e);
        }}
        aria-label={cardType}
      >
        <div className="card-info-wrapper">
          <div className="card-header">
            <div>{cardType}</div>
          </div>
          <div className="card-image">
            {isCredit ? (
              <MediaImageList showInterac={false} />
            ) : (
              <MediaImage interacCard {...{ cardImgAlt: '' }} />
            )}
          </div>
        </div>
      </button>
    );
  };
  return [
    <>
      <div className={`overlay-wrapper ${signInModal ? '' : 'hide'}`}>
        <div className="signin-main">
          <div className="content-container">
            <div
              tabIndex="-1"
              id="contactlessaccountsignin"
              className="content-title no-focus-outline"
              autofocus=""
            >
              {signinItems?.Header?.value}
            </div>
            <div className="content-subtext">
              <p className="bodyText-paragraph">
                <RichText field={signinItems?.BodyText} />
              </p>
            </div>
            {tavEnable ? '' : <MediaImageList />}

            <div className="main-wrapper">
              <>{errorCode && errorBlock()}</>
              {isLoading ? loader() : ''}
              {tavEnable ? (
                !isLoading && isIframeEnabled ? (
                  <div>
                    <div className="tabBorder">
                      <div className="tab" role="tablist">
                        {isMobile && <div className="middleBorder"></div>}
                        {getTab(props?.fields?.items[2]?.fields?.Title?.value, true, false)}
                        <div className="middleBorder"></div>
                        {getTab(props?.fields?.items[3]?.fields?.Title?.value, false, true)}
                        {isMobile && <div className="middleBorder"></div>}
                      </div>

                      {configKeys &&
                        configKeys
                          .filter((item) => item?.key === 'CreditcardIframe.Url')
                          .map((configKey) =>
                            !isInteracTab ? (
                              <div
                                id="credit-tab"
                                class="tabcontent"
                                role="tabpanel"
                                aria-labelledby="creditsection"
                              >
                                <iframe
                                  title="moneris"
                                  id="monerisCheckoutDiv-Frame"
                                  src={`${configKey.setting}${uniqueSessionId}/${pageLanguage}/false`}
                                  allowpaymentrequest="true"
                                />
                              </div>
                            ) : (
                              <div
                                id="debit-tab"
                                role="tabpanel"
                                class="tabcontentInterac"
                                aria-labelledby="debitsection"
                              >
                                <iframe
                                  title="moneris"
                                  id="monerisCheckoutDiv"
                                  src={`${configKey.setting}${uniqueSessionId}/${pageLanguage}/true`}
                                  allowpaymentrequest="true"
                                />
                              </div>
                            )
                          )}
                    </div>

                    <div></div>
                  </div>
                ) : (
                  ''
                )
              ) : !isLoading && isIframeEnabled ? (
                <div>
                  <div>
                    {configKeys &&
                      configKeys
                        .filter((item) => item?.key === 'CreditcardIframe.Url')
                        .map((configKey) => (
                          <iframe
                            title="moneris"
                            id="monerisCheckoutDiv-Frame"
                            src={`${configKey.setting}${uniqueSessionId}/${pageLanguage}/false`}
                            allowpaymentrequest="true"
                          // eslint-disable-next-line react/style-prop-object
                          />
                        ))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>,
  ];
};

export default withTranslation()(ContactLessSignInOverlay);
