import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Image } from '@sitecore-jss/sitecore-jss-react';

import { MediaImageWrapper } from '../../atoms';
import mediaSubTypes from '../../constants/mediaSubTypes';
import { SAMPLE_PAYLOAD } from '../ContactlessTransactionHistory/mockData';
import routes from '../../constants/routes';
import './contactlessAccountCardImage.scss';
import { redirectToUrl } from '../../services/utils';

const ContactlessAccountCardImage = (props) => {
  const [cardtype, setCardtype] = useState(false);
  const userInfoData = useSelector((state) => {
    if (state?.contactless?.userInfoData && state?.contactless?.userInfoData?.CardType) {
      return state.contactless.userInfoData;
    }
    return {};
    // return SAMPLE_PAYLOAD;
  });
  const shortenPan = userInfoData?.MaskedPan?.slice(userInfoData.MaskedPan.length - 4);
  const [cardProp, setCardProp] = useState({});
  useEffect(() => {
    if (userInfoData && userInfoData.CardType) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [cardName, value] of Object.entries(props.fields)) {
        if (cardName.toLowerCase().includes(mediaSubTypes[userInfoData.CardType])) {
          setCardProp(value);
        }
      }

      if (userInfoData?.CardType === 'OPENP_VISA') {
        setCardtype(true);
      } else {
        setCardtype(false);
      }
    }
  }, [userInfoData]);

  let contactlessCardIDLabel = 'Contactless Card ID';
  if (props?.fields?.contactlessCardIDLabel && props?.fields?.contactlessCardIDLabel?.value) {
    contactlessCardIDLabel = props.fields.contactlessCardIDLabel.value;
  }
  return (
    <div className="contactless-user-card">
      <MediaImageWrapper>
        <Image media={cardProp} loading="lazy"/>
        <div className={`masked-pan ${cardtype ? 'color-white' : 'color-black'}`}>
          <span className={`card-dot ${cardtype ? 'color-whitebg' : 'color-blackbg'}`} />
          <span className={`card-dot ${cardtype ? 'color-whitebg' : 'color-blackbg'}`} />
          <span className={`card-dot ${cardtype ? 'color-whitebg' : 'color-blackbg'}`} />
          <span className={`card-dot ${cardtype ? 'color-whitebg' : 'color-blackbg'}`} />{' '}
          {shortenPan}
        </div>
      </MediaImageWrapper>
      <div className="contactless-card-details">
        <span>{contactlessCardIDLabel}:</span>
        <br />
        <span className="contactless-card-number">{userInfoData?.MediaReferenceId}</span>
      </div>
    </div>
  );
};

export default withTranslation()(ContactlessAccountCardImage);
