import React from 'react';

import styles from './valueRow.module.scss';

export default function ValueRow({ left, right, noBorder = false, noPaddingBottom = false }) {
  return (
    <div
      className={`${styles.row}  ${noBorder ? styles.noBorder : null} ${
        noPaddingBottom ? styles.noPaddingBottom : null
      }`}
    >
      <div className={styles.firstCell}>{left}</div>
      <div>{right}</div>
    </div>
  );
}
