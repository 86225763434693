import React, { useState } from 'react';

import { Modal } from '../../molecules';

import './skipContentLink.scss';

const SkipContentLink = ({ linkToId = '', linkText = '' }) => {
  const [showModal, setShowModal] = useState(false);

  const link = (
    <a className="skip-content-link" href={`#${linkToId}`} tabIndex="-1">
      {linkText}
    </a>
  );

  const skipContentClickHandler = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) {
      window.location.hash = '#' + linkToId;
    }
  };

  const skipContentFocusHandler = (e) => {
    window.location.hash = '';
    setShowModal(true);
  };

  const skipClickHandler = () => {
    window.location.hash = '#' + linkToId;
  };

  return (
    <div
      className="skip-overlay"
      tabIndex="0"
      onClick={skipClickHandler}
      onFocus={skipContentFocusHandler}
      onBlur={() => setTimeout(() => setShowModal(false), 100)}
      onKeyDown={skipContentClickHandler}
    >
      {/* <Modal
        hideButtons
        hideTitle
        isModalOpen={showModal}
        text={link}
        textClassName="text-center"
        withoutFocusTrap
      /> */}
      {showModal && (
        <div className="skip-content-wrap">
          <a className="skip-content-link" onKeyDown={skipContentClickHandler}>
            {linkText}
          </a>
        </div>
      )}
    </div>
  );
};

export default SkipContentLink;
