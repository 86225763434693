import React from 'react';
import './anchorComponent.scss';

const AnchorComponent = ({ fields }) => {
  return (
    <>
      <div className="scroll-margin" id={fields?.anchorLink?.value} />
    </>
  );
};
export default AnchorComponent;
