import React from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { formatDecimal, redirectToUrl, formatDate } from '../../services/utils';
import { ButtonBlock, PrintButton, Button } from '../../atoms';
import { Success, InlineButtonBlock, ThirdPartySystemErrorWarning } from '../../molecules';

export default function SetAutoload3({
  fields,
  state,
  backToMyCardButtonLink,
  thirdPartySystemErrorType,
}) {
  return (
    <div className="autoload-success-wrapper">
      <h1 className="autoload-header" tabIndex="0">
        <Text field={fields.heading} />
      </h1>
      {thirdPartySystemErrorType ? (
        <ThirdPartySystemErrorWarning {...{ fields, errorCode: thirdPartySystemErrorType }} />
      ) : (
        <>
          <Success
            title={fields.successMessage}
            Content={() => (
              <div className="autoload-setup autoload-success">
                <div
                  className="autoload-description"
                  aria-level={fields.descriptionSetupA11y.value}
                >
                  <RichText field={fields.descriptionSetup} />
                </div>
                <div className="setup-section">
                  <div className="section-details-amount no-top-border">
                    <div aria-label={fields.descriptionSetupA11y.value}>
                      <Text field={fields.amountText} />
                    </div>
                    <div className="amount">
                      <Text field={fields.amountPlaceholder} />
                      {formatDecimal(state.loadAmount)}
                    </div>
                  </div>
                  <div className="section-details-amount no-top-border">
                    <div aria-label={fields.dropLevelTextA11y.value}>
                      <Text field={fields.dropLevelText} />
                    </div>
                    <div className="amount">
                      <Text field={fields.amountPlaceholder} />
                      {formatDecimal(state.thresholdAmount)}
                    </div>
                  </div>
                  <div className="section-details-amount no-top-border">
                    <div aria-label={fields.dateTextA11y.value}>
                      <Text field={fields.dateText} />
                    </div>
                    <div className="amount">{state.transactionDate || formatDate(Date.now())}</div>
                  </div>
                </div>
                <div
                  className="autoload-description"
                  aria-label={fields.emailConfirmationTextA11y.value}
                >
                  <Text field={fields.emailConfirmationText} />
                </div>
                <InlineButtonBlock
                  onClick={() => redirectToUrl(backToMyCardButtonLink)}
                  buttonText={fields.backLinkText}
                />
              </div>
            )}
          />
          <ButtonBlock>
            <PrintButton />
            {thirdPartySystemErrorType ? (
              <ButtonBlock right>
                <Button Click={() => redirectToUrl(backToMyCardButtonLink)}>
                  <Text field={fields.backLinkText} />
                </Button>
              </ButtonBlock>
            ) : null}
          </ButtonBlock>
        </>
      )}
    </div>
  );
}
