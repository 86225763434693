import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import downIcon from '../../assets/images/down.svg';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import './accordianItemwithImageIcon.scss';

const AccordianItemwithImageIcon = ({ fields, t }) => {
  const cardDropdownRef = useRef(null);

  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);

  const onClickSelect = () => openDropdown(!isArrowIconClicked);

  const iconText = isArrowIconClicked ? t('AccordionOpenText') : t('AccordionCloseText');
  return (
    <div
      className={
        fields?.CssClass && fields.CssClass.value
          ? fields.CssClass.value
          : 'presto-products_accordian'
      }
    >
      {/* <div className="presto-products_accordian"> */}
      <button
        onClick={onClickSelect}
        aria-expanded={isArrowIconClicked}
        id={fields?.AccordionId && fields?.AccordionId?.value ? fields.AccordionId.value : ''}
        aria-controls={
          fields?.AccordionRegionId && fields?.AccordionRegionId?.value
            ? fields.AccordionRegionId.value
            : ''
        }
      >
        <Image className="side-icons" field={fields.Icon} loading="lazy"/>
        <p class="home-accordian-heading">
          <Text field={fields.Heading} />
        </p>

        <img alt="" className={isArrowIconClicked ? 'up' : 'arrow-icon'} src={downIcon} />
      </button>
      {isArrowIconClicked && (
        <div>
          <div
            className="home-accordian-description"
            role="region"
            id={
              fields?.AccordionRegionId && fields?.AccordionRegionId?.value
                ? fields.AccordionRegionId.value
                : ''
            }
            aria-labelledby={
              fields?.AccordionId && fields?.AccordionId?.value ? fields.AccordionId.value : ''
            }
          >
            <Image field={fields.Image} loading="lazy"/>
            <RichText field={fields.Descrption} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AccordianItemwithImageIcon);
