import React, { useEffect } from 'react';

import {
  Placeholder,
  isExperienceEditorActive,
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';

import api from './services/api';
import { localstorageService, redirectToUrl, getQueryParams } from './services/utils';
import localStorageKeys from './constants/localStorageKeys';
import mediaSubTypes from './constants/mediaSubTypes';
import mediaTypes from './constants/mediaTypes';
import routes from './constants/routes';
import { Loader } from './atoms';
import usePrevious from './hooks/usePrevious';
import {
  setSelectCardRedirect,
  setLastVisitedSecuredRoute,
  setIsMobile,
} from './redux/user/actions';
import useMediaWidth from './hooks/useMediaWidth';

const Layout = ({ route, sitecoreContext }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaWidth();
  const routePath = useLocation();
  const queryParams = getQueryParams(useLocation().search);

  const isWeakAccount = useSelector((state) => state.user.isWeakAccount);
  const selectedCard = useSelector((state) => state.user.selectedCard);
  const contactlessMedias = useSelector((state) => state.user.contactlessMedias);
  const redirectAfterCardSelected = useSelector((state) => state.user.redirectAfterCardSelected);
  const isRegAuthenticated = useSelector((state) => state.user.authenticated);
  const isAnonymousAuthenticated = useSelector((state) => state.user.anonymous);
  const isContactlessAuthenticated = useSelector(
    (state) => state.contactless.isContactlessUserSignedIn
  );
  const assignedPestroSidenavClass = () => {
    let contentPageLayout = document.getElementsByClassName('new-pagesidenav');
    let contentPageLayoutMain = document.getElementsByClassName('newMainPageContent');
    if (contentPageLayout && contentPageLayout.length && !route?.fields?.isContactlessPage?.value) {
      //  contentPageLayout[0].id = 'presto-side-nav';
      contentPageLayoutMain[0].id = 'presto-page-layout';
      contentPageLayoutMain[0].style.backgroundColor = '#f3f3f3';
    } else if (
      contentPageLayout &&
      contentPageLayout.length &&
      route?.fields?.isContactlessPage?.value
    ) {
      contentPageLayoutMain[0].id = 'contactless-page-layout';
      if (document) document.body.style.height = '';
    }
  };
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (!routePath.pathname.includes('#')) {
      onTop();
    }
  }, [routePath]);
  useEffect(() => {
    if (queryParams.signout === undefined && queryParams.IsMaxTryExceeds === undefined && queryParams.IsMfaVerificationFailed === undefined) {
      dispatch(setIsMobile(isMobile));
    }
    else {
      if (window.CVVA !== undefined && window.CVVA !== null) {
        window.CVVA.flushVaSession();
      }
      localstorageService().removeItem('redux');
      localstorageService().removeItem(localStorageKeys.accessToken);
      localstorageService().removeItem(localStorageKeys.accessTokenValidity);
      localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
      localstorageService().removeItem(localStorageKeys.refreshToken);
      localstorageService().removeItem(localStorageKeys.guestToken);
      localstorageService().removeItem(localStorageKeys.uniqueSessionId);
      localstorageService().removeItem(localStorageKeys.gtmContainerId);
      localstorageService().removeItem(localStorageKeys.unpaidFaresData);
      localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
      localstorageService().removeItem(localStorageKeys.stsAccessToken);
      localstorageService().removeItem(localStorageKeys.unpaidFaresIndex);
      localstorageService().removeItem(localStorageKeys.paymentsToVerifyData);
      localstorageService().removeItem(localStorageKeys.isRiskyUser);
      localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
    }
  }, [isMobile]);

  useEffect(() => {
    assignedPestroSidenavClass();
  }, [route.fields.isContactlessPage]);
  const updateMediaSession = (callback) => {
    api.UpdateFareMediaSession({ VisibleId: selectedCard.visibleId }).then(({ data }) => {
      if (callback) callback();
    });
  };

  useEffect(() => {
    if (
      !isRegAuthenticated &&
      !isAnonymousAuthenticated &&
      !isContactlessAuthenticated &&
      route.fields.securedRoute.value &&
      !window.location.href.includes('/sign-in-success') &&
      !window.location.href.includes('/account-dashboard')
    ) {
      // console.log("From Layout.js : " + route.fields.securedRoute.value);
      // console.log("path: " + window.location.href);
      dispatch(setLastVisitedSecuredRoute(window.location.href));
      // localstorageService().setItem("last-visited-secured-url", window.location.href);
    }
  }, []);

  const prevValue = usePrevious(selectedCard);
  useEffect(() => {
    if (
      selectedCard &&
      selectedCard?.visibleId &&
      (selectedCard?.visibleId !== prevValue?.visibleId ||
        (selectedCard?.visibleId == prevValue?.visibleId &&
          !redirectAfterCardSelected?.redirected)) &&
      isRegAuthenticated
    ) {
      let isOPCard = selectedCard?.type !== mediaSubTypes.presto;
      let isIniitlaLoad =
        prevValue?.visibleId === undefined &&
        !redirectAfterCardSelected?.url &&
        !redirectAfterCardSelected?.redirected;
      let cardChangedWithReload =
        prevValue?.visibleId &&
        selectedCard?.visibleId &&
        redirectAfterCardSelected?.url &&
        !redirectAfterCardSelected?.redirected;
      let cardChangedWithOutReload =
        prevValue?.visibleId && selectedCard?.visibleId && !redirectAfterCardSelected?.url;
      if (isIniitlaLoad || cardChangedWithReload || cardChangedWithOutReload) {
        if (isOPCard) {
          // If is it s OP card no Faremedia session API called
          if (redirectAfterCardSelected?.url) {
            dispatch(
              setSelectCardRedirect({ ...redirectAfterCardSelected, redirected: true })
            ).then((res) => {
              // console.log('Redirect cleared', res);
            });
            // redirectToUrl(routes.transactionHistory);
            redirectToUrl(redirectAfterCardSelected.url, redirectAfterCardSelected.includeLanguage);
          }
        } else {
          /* console.log(
            `Card switched from: ${prevValue?.nickName}'s ${prevValue?.visibleId}, to: ${selectedCard?.nickName}'s ${selectedCard?.visibleId}`
          ); */
          api.UpdateFareMediaSession({ VisibleId: selectedCard.visibleId }).then(({ data }) => {
            if (redirectAfterCardSelected?.url) {
              // console.log('Redirection: ', redirectAfterCardSelected?.url);
              dispatch(
                setSelectCardRedirect({ ...redirectAfterCardSelected, redirected: true })
              ).then((res) => {
                // console.log('Redirect cleared', res);
              });
              redirectToUrl(
                ...Object.values(
                  redirectAfterCardSelected,
                  redirectAfterCardSelected.includeLanguage
                )
              );
            }
          });
        }
      }
    }
  }, [selectedCard, redirectAfterCardSelected]);
  return (
    <>
      {useSelector((state) => state.user.isLoading) ? <Loader /> : null}
      {!isExperienceEditorActive() &&
        sitecoreContext.itemId !== 'available-in-connected-mode' && // Disconnected Mode not active
        route.fields &&
        route.fields.securedRoute &&
        route.fields.securedRoute.value &&
        (!localstorageService().getItem(localStorageKeys.accessToken) ||
          localstorageService().getItem(localStorageKeys.accessToken).length < 10 ||
          isWeakAccount ||
          (!isRegAuthenticated && !isAnonymousAuthenticated && !isContactlessAuthenticated)) ? (
        redirectToUrl('/B2CRedirect/B2CRegisteredSignIn')
      ) : (
        <>
          {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
          <Helmet>
            <html lang={route.itemLanguage} />
            <link rel="alternate" href={`/${route.itemLanguage}/`} hrefLang={route.itemLanguage} />
            <title>
              {(route.fields &&
                route.fields.pageTitle &&
                route.fields.pageTitle.value &&
                `${route.fields && route.fields.pageTitle && route.fields.pageTitle.value}`) ||
                'PRESTO'}
            </title>
            <meta name="description"
              content={(route.fields &&
                route.fields.description &&
                route.fields.description.value &&
                `${route.fields && route.fields.description && route.fields.description.value}`) ||
                'PRESTO'} />
            <meta name="keywords"
              content={(route.fields &&
                route.fields.keywords &&
                route.fields.keywords.value &&
                `${route.fields && route.fields.keywords && route.fields.keywords.value}`) ||
                'PRESTO'} />
            <meta name="title"
              content={(route.fields &&
                route.fields.pageTitle &&
                route.fields.pageTitle.value &&
                `${route.fields && route.fields.pageTitle && route.fields.pageTitle.value}`) ||
                'PRESTO'} />
          </Helmet>
          {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.
      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
          <VisitorIdentification />
          {/* root placeholder for the app, which we add components to using route data */}
          <Placeholder name="presto-main" rendering={route} />
        </>
      )}
    </>
  );
};
export default withSitecoreContext()(Layout);
