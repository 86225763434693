import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';

import { NEWMEDIA_VISIBLE_ID } from '../../constants/product.constants';
import { removeFromShoppingCart, addShoppingCartMessage } from '../../redux/user/actions';
import { formatDecimalWithCurrency, formatDate } from '../../services/utils';
import names from '../../constants/shoppingCart';
import MediaImage from '../MediaImage';
import './checkoutGridItem.scss';

const CheckoutGridItem = ({
  fields,
  item,
  deactivateFlow,
  orderCardResponse,
  paymentApiResponse,
  hideRemove = false,
  lastStep,
  filterItemLength,
  index,
  step,
  disableBorderBottom = false,
  currentBalance,
}) => {
  const customerId = useSelector((state) => state.user.customerId);
  const visibleId = useSelector((state) => state.user.selectedCard.visibleId);
  const logo = useSelector((state) => state.products.logos[item.serviceProvider]?.Logo);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const selectedCard = useSelector((state) => state.user.selectedCard);
  let customerData = orderCardResponse?.data?.Order?.Customer?.FareMedias
    ? orderCardResponse.data.Order.Customer.FareMedias
    : paymentApiResponse?.data?.Order?.Customer?.FareMedias;
  customerData = customerData && customerData.length > 0 && customerData[0];
  let currentfareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  currentfareMedias = currentfareMedias.find((data) => data.visibleId === visibleId);
  const transitPasses =
    currentfareMedias &&
    currentfareMedias?.products &&
    currentfareMedias?.products.length > 0 &&
    currentfareMedias?.products;
  const dispatch = useDispatch();

  const removeItem = (orderLineId) => {
    const data = {
      customerId,
      mediaId: visibleId,
      orderLineId,
      Sct: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
      Cct: guestUserInfo?.isGuestLogin ? guestSCT.cct : '',
      IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
      IsAnonymousUser: anonymous ? true : false,
    };
    dispatch(removeFromShoppingCart(data));
    dispatch(addShoppingCartMessage(''));
  };
  return (
    <div
      className={`checkoutGridItem ${disableBorderBottom ? `checkoutGridItem-disable-border` : ``}`}
    >
      <div className="checkoutGridItem-content">
        <div className="checkoutGridItem-logo-block">
          {item?.serviceProvider !== 'Presto' ? (
            <img alt={logo?.Alt} className="checkoutGrid-logo" src={logo?.Src} />
          ) : (
            <MediaImage prestoCard medium />
          )}
        </div>
        <div className="checkoutGridItem-data-block">
          <div
            className={`checkoutGridItem-block ${
              item.isManageAutoRenew || item?.autorenew ? `show-by-col` : ``
            }`}
          >
            <div className="checkoutGridItem-title">{item?.name}</div>
            <div className="checkoutGridItem-date">
              {item?.name === !names.funds || item.isManageAutoRenew || item?.autorenew
                ? `${formatDate(item?.validityStartDate)} - ${formatDate(item?.validityEndDate)}`
                : null}
            </div>
            {!hideRemove && (
              <div
                role="link"
                className="checkoutGridItem-remove"
                aria-label={`${item?.name} ${fields.removeButtonTextA11y?.value}`}
                tabIndex="0"
                onClick={() => removeItem(item?.orderLineId)}
                onKeyPress={() => null}
              >
                <Text field={fields.removeButtonText} />
              </div>
            )}
          </div>

          {filterItemLength - 1 == index && (item?.nickNameValue || item?.cardLanguage) && (
            <div className="checkoutGridItem-details">
              {item?.nickNameValue && (
                <div className="checkoutGridItem-nickname">
                  <span>
                    <Text field={fields.NickName} />{' '}
                  </span>
                  <span>{item?.nickNameValue}</span>
                </div>
              )}
              {item?.cardLanguage && (
                <div className="checkoutGridItem-language">
                  <span>
                    <Text field={fields.Language} />{' '}
                  </span>
                  <span>{item?.cardLanguage}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {item?.isManageAutoRenew ? null : (
          <div className="checkoutGridItem-value-block quantity">
            <div className="checkoutGrid-value">{item?.quantity}</div>
          </div>
        )}
        {item?.isManageAutoRenew ? null : (
          <div className="checkoutGridItem-value-block price">
            <div className="checkoutGrid-value">{formatDecimalWithCurrency(item?.subTotal)}</div>
          </div>
        )}
      </div>
      {deactivateFlow && (
        <div className="checkoutGridItem-details-deactivate">
          <p>
            {fields?.NickNameLabel.value}: {selectedCard?.nickName}
          </p>
          <p>
            {fields?.BalanceLabel.value}: {formatDecimalWithCurrency(currentBalance)}
          </p>
          <p className="checkoutgrid-flex">
            <span>{fields?.TransitPassLabel.value}: </span>{' '}
            <div className="checkout-grid-passes">
              {transitPasses &&
                transitPasses.length > 0 &&
                transitPasses.map((pass) => {
                  if (pass.ProductName !== null) {
                    return <p>{pass.ProductName}</p>;
                  }
                })}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default CheckoutGridItem;
