import React, { useState, forwardRef, useEffect } from 'react';

import { RichText, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import api from '../../services/api';
import { ButtonBlock, Button, InlineError, DatePickerInput, Loader } from '../../atoms';
import sharedFormConstants from '../../constants/sharedFormConstants';
import apiResponses from '../../constants/apiResponses';
import { CardBlock, TextInput } from '../../molecules';
import Calendar from '../../assets/images/Calendar.svg';
import './redeemVoucherStepperBlock.scss';
import {
  getTimePeriod,
  formatDateTx,
  formatDecimalWithCurrency,
  isFrench,
  getErrorTranslation,
} from '../../services/utils';
import { setShoppingCart } from '../../redux/user/actions';

const RedeemVoucherStepperBlock2 = ({
  fields,
  toNextStep,
  toFirstStep,
  setState,
  t,
  rendering,
}) => {
  const MAX_DATE = new Date();
  const [date, setDate] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [DOBError, setDOBError] = useState(fields.DOBError.value);
  const [errorBorder, setErrorBorder] = useState(false);
  const dispatch = useDispatch();
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const program = redeemStepper?.apiData?.VoucherProducts?.program;
  const include = program?.ProgramProducts.filter(
    (ProgramProduct) =>
      ProgramProduct.ProductType === 1 ||
      ProgramProduct.ProductType === 4 ||
      ProgramProduct.ProductType === 5
  );
  const ProductTypeID_5 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 5
  );
  const ProductTypeID_1 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 1
  );
  const ProductTypeID_4 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 4
  );
  const ProductTypeID_3 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 3
  );
  const validateBirthDate = redeemStepper?.apiData?.ValidateBirthDate;
  const user = useSelector((reduxState) => reduxState.user);
  const logo = useSelector((state) => state.products.logos);
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.mediaId === reduxState.user.selectedCard.mediaId)
  );
  const voucherNote = fields.CardNote.value.toString().split('{0)');
  const voucherNote1 = () => {
    const note1 = voucherNote[0].toString();
    const note2 = fareMedia.nickName.toString();
    const note3 = fareMedia.visibleId.toString();
    const note4 = voucherNote[1].toString();

    return (
      <div className="redeem-card-note">
        {note1}
        <b>{note2}</b>(<b>{note3}</b>
        {')'}
        {note4}
      </div>
    );
  };
  const voucherCard = fields.CartNote.value.toString().split('{0)');
  const voucherCard1 = () => {
    const card1 = voucherCard[0].toString();
    const card2 = fareMedia.nickName.toString();
    const card3 = fareMedia.visibleId.toString();
    const card4 = voucherCard[1].toString();

    return (
      <div className="redeem-card-note">
        {card1}
        <b>{card2}</b>(<b>{card3}</b>
        {')'}
        {card4}
      </div>
    );
  };

  const redeemApiSubmit = () => {
    setLoading(true);
    const payload = {
      CustomerId: user?.customerId,
      VoucherCode: redeemStepper.voucherCode,
      VisibleId: fareMedia?.visibleId,
      ConcessionDate: moment(selectedDate).format(),
      MediaId: fareMedia?.mediaId,
      EnableStub: true,
      EnableErrorFlow: false,
      EnableShopingCartFlow: true,
      VerifyConcessionDate: redeemStepper.apiData.ValidateBirthDate,
    };
    api
      .redeemVoucher(payload)
      .then((response) => {
        if (response?.data?.Sucess) {
          if (ProductTypeID_3 && ProductTypeID_3.length > 0) {
            api
              .getShoppingCart({
                customerId: user?.customerId,
                visibleId: fareMedia?.visibleId,
                mediaId: fareMedia?.mediaId,
              })
              .then((cartRes) => {
                if (cartRes.status === 200) {
                  const order = cartRes?.data?.ShoppingCart?.Order;
                  const isNewItem = false;
                  dispatch(setShoppingCart(order, isNewItem));
                  setLoading(false);
                }
                setState({ ...response?.data });
                toNextStep();
              });
          } else {
            setState({ ...response?.data });
            setLoading(false);
            toNextStep();
          }
        } else if (response?.data?.ErrorMessage) {
          if (response?.data?.ErrorMessage === apiResponses.redeemVoucherDOBError) {
            getErrorTranslation(response?.data?.ErrorMessage, (errorMsg) => {
              if (errorMsg && errorMsg.data) {
                setDOBError(errorMsg.data);
                setErrorBorder(true);
              }
            });
            setLoading(false);
          } else {
            setState(response?.data);
            setLoading(false);
            toNextStep();
          }
        } else {
          setState(response?.data);
          setLoading(false);
          toNextStep();
        }
      })
      .catch((err) => {
        //console.log('error', err);
      });
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      aria-label={fields.DatePickerA11y.value}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    >
      <img src={Calendar} />
    </button>
  ));
  const getItem = (text1, text2) => (
    <div className="item-wrapper">
      <div className="item">{text1}</div>
      <div className="item">
        <b>{text2}</b>
      </div>
    </div>
  );
  const getItemWithImage = (text1, text2, image, ProgramProduct) => (
    <div className="item-image-wrapper">
      <div className="item-image">
        <img alt={logo[image]?.Logo?.Alt} src={logo[image]?.Logo?.Src} />
      </div>
      <div className="item-wrapper">
        <div className="item">
          <b>{text1}</b>
          <br />
          <div
            className="agencyOption-date"
            aria-label={getTimePeriod(
              ProgramProduct?.PeriodPassStartDate,
              ProgramProduct?.PeriodPassEndDate
            )}
          >
            {getTimePeriod(ProgramProduct?.PeriodPassStartDate, ProgramProduct?.PeriodPassEndDate)}
          </div>
        </div>
        <div className="item">
          <b>{text2}</b>
        </div>
      </div>
    </div>
  );

  const handleDateInput = (value, type) => {
    if (type == 'DATE') {
      setDate(value);
    } else if (type == 'MONTH') {
      setMonth(value);
    } else if (type == 'YEAR') {
      setYear(value);
    }
  };

  useEffect(() => {
    if (validateBirthDate) {
      if (!isNaN(date) && !isNaN(month) && !isNaN(year)) {
        let isDateValid = moment([year, Number(month) - 1, date]).isValid();
        if (isDateValid) {
          let setMomentDate = moment([year, Number(month) - 1, date]).toDate();

          setSelectedDate(setMomentDate);
        }

        if (!date && !month && !year) {
          setSelectedDate(MAX_DATE.setFullYear(MAX_DATE.getFullYear() - 45));
        }
      }
      if (date && month && year) {
        setErrorBorder(false);
      }
      document.body.scrollTop = 0;
    }
  }, [date, month, year]);

  const syncDate = (date) => {
    setSelectedDate(date);
    setDate(moment(date).format('DD'));
    setMonth(moment(date).format('MM'));
    setYear(moment(date).format('YYYY'));
  };
  const getDateOfBirthField = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          [sharedFormConstants.terms]: false,
        }}
        onSubmit={(values) => {
          if (validateBirthDate) {
            let regex = /^[0-9]{0,2}$/;
            let regex1 = /^[0-9]{0,4}$/;
            if (!regex.test(month) || !regex.test(date) || !regex1.test(year)) {
              setDOBError(fields.DOBError.value);
              setErrorBorder(true);
            } else if (!month || !date || !year) {
              if (!month || !date || !year) {
                setDOBError(fields.DOBError.value);
                setErrorBorder(true);
              }
            } else {
              if (values?.terms) {
                redeemApiSubmit(values);
              }
            }
          } else {
            if (values?.terms) {
              redeemApiSubmit(values);
            }
          }
        }}
      >
        {({ errors, touched, handleSubmit, values, setFieldValue, setFieldTouched, handleChange }) => {
          if (values.FromDate != selectedDate) {
            setFieldValue('FromDate', selectedDate);
          }
          return (
            <>
              {' '}
              {loading && <Loader />}
              <div className="redeemvoucher-add">
                <form onSubmit={handleSubmit}>
                  <h1 className="redeemvoucher-header">{fields.Title}</h1>
                  <div className="redeemvoucher-whole-wrapper">
                    {validateBirthDate ? (
                      <div className="redeemvoucher-wrapper">
                        <div className="redeemvoucher-tab">
                          <CardBlock title={fields.DOBTitle.value}>
                            <Text field={fields.DOBDescrption} />
                            <span className="birthdate-redeem">
                              <Text field={fields.BirthdateField} />
                            </span>
                            <div className="text-input-redeem">
                              <TextInput
                                isError={errorBorder}
                                type="text"
                                value={month}
                                name="Month"
                                errors=""
                                touched={touched}
                                placeholder={fields.MonthPlaceholder.value}
                                inputA11y={fields.MonthInputA11y.value}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleDateInput(e.target.value, 'MONTH');
                                }}
                              />
                              <TextInput
                                isError={errorBorder}
                                type="text"
                                value={date}
                                name="Date"
                                errors=""
                                touched={touched}
                                placeholder={fields.DatePlaceholder.value}
                                inputA11y={fields.DateInputA11y.value}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleDateInput(e.target.value, 'DATE');
                                }}
                              />
                              <TextInput
                                isError={errorBorder}
                                type="text"
                                value={year}
                                name="Year"
                                errors=""
                                touched={touched}
                                placeholder={fields.YearPlaceholder.value}
                                inputA11y={fields.YearInputA11y.value}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleDateInput(e.target.value, 'YEAR');
                                }}
                              />
                              <div className="date-picker">
                                <DatePickerInput
                                  name="FromDate"
                                  onChange={(date) => {
                                    syncDate(date);
                                  }}
                                  customInput={<CustomInput />}
                                  selected={selectedDate}
                                  maxDate={MAX_DATE}
                                  errors={errors}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  useShortMonthInDropdown
                                  yearDropdownItemNumber={120}
                                  minDate={moment().subtract(120, 'years')._d}
                                  yearItemNumber={12}
                                />
                              </div>
                            </div>
                            {errorBorder && <InlineError>{DOBError}</InlineError>}
                            {!errorBorder && <RichText field={fields.DOBnote} />}
                          </CardBlock>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {include.length > 0 && (
                      <div className="redeemvoucher-wrapper card-block">
                        <div className="redeemvoucher-tab">
                          <CardBlock redeemVoucherTitle={voucherNote1()}>
                            {ProductTypeID_5?.map((ProgramProduct) => {
                              if (ProgramProduct.ProductType === 5) {
                                let productName = '';
                                if (isFrench()) {
                                  if (
                                    fields?.FareTypeStudent ===
                                    ProgramProduct?.Concession?.ConcessionShortName_FR
                                  ) {
                                    productName = fields?.FareTypeYouth;
                                  } else {
                                    productName = ProgramProduct?.Concession?.ConcessionShortName_FR;
                                  }
                                } else if (
                                  fields?.FareTypeStudent ===
                                  ProgramProduct?.Concession?.ConcessionShortName_EN
                                ) {
                                  productName = fields?.FareTypeYouth;
                                } else {
                                  productName = ProgramProduct?.Concession?.ConcessionShortName_EN;
                                }
                                return [
                                  getItem(fields.FareTypeUpdate.value, productName?.toString()),
                                  ProgramProduct?.ConcessionEndDate !== null &&
                                    ProgramProduct?.Concession?.IsEndDateRequired
                                    ? getItem(
                                      fields.ExpiryTitle.value,
                                      formatDateTx(ProgramProduct?.ConcessionEndDate)
                                    )
                                    : null,
                                ];
                              }
                            })}

                            {ProductTypeID_1?.map((ProgramProduct) => {
                              return getItem(
                                fields.VouchercreditTitle.value,
                                '$' + ProgramProduct?.ProductValue?.toString()
                              );
                            })}

                            {ProductTypeID_4?.map((ProgramProduct) => {
                              let productName = '';
                              if (isFrench()) {
                                productName = ProgramProduct?.ProductNameFR;
                              } else {
                                productName = ProgramProduct?.ProductNameEN;
                              }
                              return getItemWithImage(
                                productName ? productName : ProgramProduct?.ProductName,
                                fields?.PassFare?.value?.toString(),
                                ProgramProduct?.ProductOwner,
                                ProgramProduct
                              );
                            })}
                          </CardBlock>
                        </div>
                      </div>
                    )}
                    <div className="redeemvoucher-wrapper card-block-image">
                      <div className="redeemvoucher-tab">
                        <CardBlock redeemVoucherTitle={ProductTypeID_3.length > 0 && voucherCard1()}>
                          {ProductTypeID_3.map((ProgramProduct) => {
                            let productName = '';
                            if (isFrench()) {
                              productName = ProgramProduct.ProductNameFR;
                            } else {
                              productName = ProgramProduct.ProductNameEN;
                            }
                            return getItemWithImage(
                              productName ? productName : ProgramProduct?.ProductName,
                              formatDecimalWithCurrency(ProgramProduct?.ProductValue?.toString()),
                              ProgramProduct?.ProductOwner,
                              ProgramProduct
                            );
                          })}
                          <div className="redeem-terms-block">
                            <div className="checkout-terms-block">
                              <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                              {!values.terms && touched[sharedFormConstants.terms] && (
                                <InlineError cssClass="checkbox-error">
                                  {fields.TermsandConditionError.value}
                                </InlineError>
                              )}
                            </div>
                          </div>
                        </CardBlock>
                      </div>
                    </div>
                    <div className="redeemvoucher-wrapper submit-button">
                      <div className="redeemvoucher-tab">
                        <ButtonBlock>
                          <ButtonBlock right>
                            <Button type="submit" onClick={() => { !values.terms ?? setFieldTouched(sharedFormConstants.terms, 'true') }} buttonTextA11y={fields.SubmitTextA11y.value}>
                              <Text field={fields.SubmitText} />
                            </Button>
                            <Button
                              firstOrder
                              white
                              buttonTextA11y={fields.GoBackTextA11y.value}
                              onClick={toFirstStep}
                            >
                              <Text field={fields.GoBackText} />
                            </Button>
                          </ButtonBlock>
                        </ButtonBlock>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          );
        }}
      </Formik>
    );
  };
  return (
    <>
      <h1 className="redeemvoucher-header">
        <Text field={fields.RedeemVoucherMainTitle} />
      </h1>

      {getDateOfBirthField()}
    </>
  );
};

export default RedeemVoucherStepperBlock2;
