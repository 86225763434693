import React, { useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import {
  formatUtcDate,
  formatUtcTimeFromDate,
  formatDecimalWithCurrency,
  getCardTransactionTypes,
  blockTransactionHistoryEntry,
} from '../../../services/utils';
import downIcon from '../../../assets/images/down.svg';
import upIcon from '../../../assets/images/up.svg';
import { Modal } from '../../../molecules';
import './transactionGridItem.scss';

const TransactionGridItem = ({ data, expand, fields }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modelData, setModelData] = useState({ title: '', text: '' });
  const logos = useSelector((state) => state.products?.logos);
  const logo = logos && logos[data.ServiceProviderID]?.Logo;
  const prestoLogo = logos && logos['Presto']?.Logo;
  const {
    nullType,
    tapOn,
    tapOff,
    defaultTrip,
    farePayment,
    fareAdjTapOffNotDetected,
    tapOffNotDetected,
    loadAmount,
    autoLoadAmount,
    transitPassTap,
    freeTransfer,
    inPerson,
    webOrAppLoad,
    transitPassAutoRenew,
    defaultType,
    cardSettingChange,
    cardActivation,
    paymentByCredit,
    paymentbyCash,
  } = useMemo(() => getCardTransactionTypes(data, fields), [data, fields]);
  useEffect(() => {
    setIsOpen(expand);
  }, [expand]);
  const transactionTypeTextMap = {
    0: fields.transactionTypeNull.value,
    1: fields.transactionTypeLoads.value,
    2: fields.transactionTypeTransitPassLoads.value,
    3: fields.transactionTypeCardIssuanceFee.value,
    4: fields.transactionTypePaymentByCash.value,
    5: fields.transactionTypeFarecardReplacement.value,
    6: fields.transactionTypeFarecardPhotoAdd.value,
    7: fields.transactionTypeRefund.value,
    8: fields.transactionTypeTransitPassRefund.value,
    9: fields.transactionTypeReimburseOnFailure.value,
    10: fields.transactionTypeBlockEvent.value,
    11: fields.transactionTypeCardSettingChange.value,
    12: fields.transactionTypeCardActivation.value,
    13: fields.transactionTypePurchase.value,
    14: fields.transactionTypeFarePayment.value,
    15: fields.transactionTypeTransitPassPayment.value,
    16: fields.transactionTypeTransfer.value,
    17: fields.transactionTypeFareInspection.value,
    18: fields.transactionTypeDeviceEvent.value,
    19: fields.transactionTypeOperationsConfigurationEvent.value,
    20: fields.transactionTypeOperationsCardBlockEvent.value,
    21: fields.transactionTypeDeviceReport.value,
    22: fields.transactionTypePaymentCardBalance.value,
    23: fields.transactionTypePaymentByCredit.value,
    24: fields.transactionTypeDriverShiftReport.value,
    25: fields.transactionTypeOpenPaymentTap.value,
    26: fields.transactionTypeOpenPaymentFarePaymentTravel.value,
    27: fields.transactionTypeOpenPaymentPeriodPassTravel.value,
    28: fields.transactionTypeOpenPaymentFreeTransferTravel.value,
    29: fields.transactionTypeLUMUsage.value,
    99: fields.transactionTypeShift.value,
    100: fields.transactionTypePaperTicketFarePayment.value,
  };

  const getTransactionTypeText = (transactionTypeId) => {
    return transactionTypeTextMap[transactionTypeId];
  };

  const transactionType = () => {
    let final;
    if (nullType) {
      final = fields.transactionTypeNull.value;
    } else if (tapOn) {
      final = fields.TapOnLabel.value;
    } else if (tapOff) {
      final = fields.TapOffLabel.value;
    } else if (defaultTrip) {
      final = fields.DefaultTrip.value;
    } else if (fareAdjTapOffNotDetected) {
      final = (
        <button
          aria-label={fields?.FATapOffNoteDetectedLabelA11y?.value}
          type="button"
          className="link"
          onClick={() => {
            setIsModalOpen(true);
            setModelData({
              title: fields?.FATapOffNotDetectedModelTitle?.value,
              text: fields?.FATapOffNotDetectedModelDescription?.value,
            });
          }}
        >
          {fields.FATapOffNoteDetectedLabel.value}
        </button>
      );
    } else if (tapOffNotDetected) {
      final = (
        <button
          aria-label={fields.automaticTapOffLabelA11y.value}
          type="button"
          className="link"
          onClick={() => {
            setIsModalOpen(true);
            setModelData({
              title: fields.missedTapOffModalTitle.value,
              text: fields.missedTapOffModalText.value,
            });
          }}
        >
          {fields.automaticTapOffLabel.value}
        </button>
      );
    } else if (inPerson) {
      final = fields.TransitPassInPersonLoad?.value;
    } else if (webOrAppLoad) {
      final = fields?.TransitPassWebAppLoad?.value;
    } else if (transitPassTap) {
      final = fields?.TransitPassTap?.value;
    } else if (cardSettingChange) {
      final = (
        <button
          aria-label={fields?.transactionTypeCardSettingChange?.value}
          className="link"
          onClick={() => {
            setIsModalOpen(true);
            setModelData({
              title: fields?.CardSettingChangeModelTitle?.value,
              text: <RichText field={fields.CardSettingChangeModelDescription} />,
            });
          }}
        >
          {fields.transactionTypeCardSettingChange.value}
        </button>
      );
    } else if (freeTransfer) {
      final = fields.FreeTransfer.value;
    } else if (paymentByCredit) {
      final = fields?.transactionTypePaymentByCredit?.value;
    } else if (farePayment) {
      final = fields?.transactionTypeFarePayment?.value;
    } else if (loadAmount) {
      final = fields?.transactionTypeLoads?.value;
    } else if (autoLoadAmount) {
      final = fields?.transactionTypeAutoLoad?.value;
    } else if (transitPassAutoRenew) {
      final = fields?.TransitPassAutoRenew?.value;
    } else if (cardActivation) {
      final = fields?.transactionTypeCardActivation?.value;
    } else if (defaultType) {
      final = `${data.Type}:`;
    } else {
      final = getTransactionTypeText(data.TrxTypeId);
    }
    return final;
  };
  const showTransitAgencyName = () => {
    return data?.ServiceProviderName ? data?.ServiceProviderName : fields?.PrestoLabel?.value;
  };
  const getTransactionMoreRight = () => {
    if (cardSettingChange || transitPassTap || freeTransfer || cardActivation) return null;
    if (inPerson || webOrAppLoad || transitPassAutoRenew) {
      return data?.ServiceProvider?.Products[0]?.ProductName;
    }
    return [
      <div>
        {data?.DiscountSum > 0
          ? `${fields.entryDiscountBalanceLabel.value}: ${formatDecimalWithCurrency(
              data.DiscountSum
            )}`
          : null}
      </div>,
      <div>
        {data?.ServiceClass ? `${fields.entryServiceClasslabel.value}: ${data.ServiceClass}` : null}
      </div>,
    ];
  };
  const getLogo = () => {
    return logo && logo.Src ? (
      <img alt={logo.Alt} className="checkoutGrid-logo" src={logo.Src} />
    ) : prestoLogo && prestoLogo.Src ? (
      <img alt={prestoLogo.Alt} className="checkoutGrid-logo" src={prestoLogo.Src} />
    ) : null;
  };
  const getCurrency = () => {
    if (cardSettingChange || transitPassTap || freeTransfer || cardActivation) {
      return <span />;
    }
    if (loadAmount || autoLoadAmount) {
      return <span className={data.Amount <0 ? "data-inblack" :"data-ingreen"}>{formatDecimalWithCurrency(data.Amount)}</span>;
    }
    return `${formatDecimalWithCurrency(data.Amount)}`;
  };
  const getBalance = () => {
    if (
      cardSettingChange ||
      transitPassTap ||
      freeTransfer ||
      cardActivation ||
      transitPassAutoRenew ||
      paymentByCredit ||
      paymentbyCash ||
      (paymentbyCash && (inPerson || webOrAppLoad)) ||
      (paymentByCredit && (inPerson || webOrAppLoad))
    ) {
      return '';
    }
    return `${fields.entryBalanceLabel.value}: ${formatDecimalWithCurrency(data.Balance)}`;
  };

  return (
    <>
      {blockTransactionHistoryEntry(data) ? null : (
        <div className="transactionGridItem">
          <div
            className="transactionGridItem-head"
            role="button"
            onClick={() => setIsOpen(!isOpen)}
            onKeyPress={() => setIsOpen(!isOpen)}
            tabIndex="0"
            aria-expanded={isOpen}
          >
            <div className="transactionGridItem-logo-block">{getLogo()}</div>
            <div className="transactionGridItem-right">
              <div className="transactionGridItem-block">
                <div>
                  <div className="transactionGridItem-title">{formatUtcDate(data.Date)}</div>
                  <div className="transactionGridItem-subTitle">
                    {formatUtcTimeFromDate(data.Date)}
                  </div>
                  <div className="transactionGridItem-subTitle">{showTransitAgencyName()}</div>
                </div>
              </div>
              <div className="transactionGridItem-value-block">
                <div>
                  <div className="transactionGridItem-title">
                    {transactionType()} {getCurrency()}
                  </div>
                  <div className="transactionGridItem-subTitle">{getBalance()}</div>
                </div>
              </div>
            </div>
            <div
              className="transactionGridItem-control"
              // tabIndex="0"
              // aria-expanded={isOpen}
              // aria-label={
              //   isOpen
              //     ? fields.collapseTransactionButtonA11y.value
              //     : fields.expandTransactionButtonA11y.value
              // }
            >
              {isOpen ? (
                <img alt={fields.collapseTransactionButtonA11y.value} src={upIcon} />
              ) : (
                <img alt={fields.expandTransactionButtonA11y.value} src={downIcon} />
              )}
            </div>
          </div>
          {isOpen ? (
            <div className="transactionGridItem-more">
              <div className="transactionGridItem-more-left">
                {`${fields.entryLocationLabel.value}: ${data.Location}`}
                <br />
                {`${fields.entryTransactionNumberLabel.value}: ${data.SequenceNumber}`}
              </div>
              <div className="transactionGridItem-more-right">{getTransactionMoreRight()}</div>
            </div>
          ) : null}
        </div>
      )}
      <Modal
        title={modelData.title}
        text={modelData.text}
        withoutFocusTrap={false}
        disableBackdropClick
        textSubmit={fields.missedTapOffModalCloseButtonText.value}
        onSubmit={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default TransactionGridItem;
