import React, { useEffect, useState, useRef } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import routes from '../../constants/routes';
import { getServerUrl, getQueryParams } from '../../services/utils';
import handleThirdPartyError from '../../services/handleThirdPartyError';
import CheckoutStep0 from './CheckoutStep0';
import CheckoutStep1 from './CheckoutStep1';
import CheckoutStep2 from './CheckoutStep2';
import CheckoutStep01 from './CheckoutStep01';
import { StepTracker, Header } from '../../molecules';

import './checkout.scss';

let stepsMap = [CheckoutStep0, CheckoutStep1, CheckoutStep2];

const Checkout = ({ fields, t, rendering }, props) => {
  const userInfo = useSelector((s) => s.user);
  const fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  const cartItems = useSelector((reduxState) => reduxState.user.shoppingCart.items);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const subTotal = cartItems ? cartItems.reduce((acc, val) => acc + val.subTotal, 0) : 0;
  const [step, setStep] = useState(0);
  const [state, setState] = useState({});
  const [isError, setIsError] = useState(false);
  const focusDiv = useRef();
  const goBackURL = useSelector((reduxState) => reduxState.user.goBackURL);
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => setStep(0);
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };

  const Stepper = stepsMap[step];
  const location = useLocation();
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');
  const returnUrl = `${getServerUrl()}${location.pathname}`;
  const queryParams = getQueryParams(location.search);
  const [isOrderCard, setIsOrderCard] = useState(false);
  let goBackUrl = goBackURL;
  // state?.items?.length && state.items?.every((item) => item.productFamily !== 'Epurse')
  //   ? routes.transitPasses
  //   : routes.loadMyCard;
  // goBackUrl =
  //   state?.items?.length &&
  //   state.items?.some((data) => data.productFamily === 'Fare Media') &&
  //   routes.orderNewCard;
  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [configKeys]);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, [configKeys]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0 && cartItems[0].visibleId === 'NEWMEDIA') {
      setIsOrderCard(true);
      stepsMap = [CheckoutStep0, CheckoutStep01, CheckoutStep1, CheckoutStep2];
    }
    const searchValues = location.search.substring(1);
    setState({ items: cartItems, fareMedias, total: subTotal });

    if (queryParams.error) {
      handleThirdPartyError(queryParams.error, (error) => {
        setThirdPartySystemErrorType(error);
        setState({ items: cartItems, reference: queryParams.reference || '' });
        setStep(2);
        focusDiv.current.focus();
      });
    }

    if (queryParams.success === 'True') {
      setState({
        items: cartItems,
        fareMedias,
        total: subTotal,
        ...JSON.parse(
          `{"${decodeURI(searchValues)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')}"}`
        ),
      });
      if (isOrderCard) {
        setStep(2);
      } else {
        setStep(3);
      }
      focusDiv.current.focus();
    } else if (queryParams.success === 'False') {
      setIsError(true);
      if (cartItems && cartItems.length > 0 && cartItems[0].visibleId === 'NEWMEDIA') {
        setStep(2);
      } else {
        setStep(1);
      }
      focusDiv.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!state.savedPaymentMethodUsed && queryParams.success !== 'True') {
      setState({
        ...state,
        items: cartItems,
        fareMedias,
        total: subTotal,
        reference: queryParams.reference || '',
      });
    }
  }, [cartItems, subTotal, queryParams.reference]);

  return (
    <div className="checkout ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      {isOrderCard ? (
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.step1Title} />,

              labelA11y: fields.step1Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textInProgressA11y: fields.step1TitleInProgressA11y.value,
              // textCompletedA11y: fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step2Title} />,

              labelA11y: fields.step2Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
              // textInProgressA11y: fields.step2TitleInProgressA11y.value,
              // textCompletedA11y: fields.step2TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step3Title} />,

              labelA11y: fields.step3Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textNotCompletedA11y: fields.step3TitleNotCompletedA11y.value,
              // textInProgressA11y: fields.Step3TitleInProgressA11y.value,
              // textCompletedA11y: fields.step3TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.Step4Title} />,

              labelA11y: fields.Step4Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textNotCompletedA11y: fields.Step4TitleNotCompletedA11y.value,
              // textCompletedA11y: fields.Step4TitleCompletedA11y.value,
              hasError: thirdPartySystemErrorType !== '',
            },
          ]}
        />
      ) : (
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.step1Title} />,

              labelA11y: fields.step1Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textInProgressA11y: fields.step1TitleInProgressA11y.value,
              // textCompletedA11y: fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step3Title} />,

              labelA11y: fields.step3Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
              // textInProgressA11y: fields.step2TitleInProgressA11y.value,
              // textCompletedA11y: fields.step2TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.Step4Title} />,

              labelA11y: fields.Step4Title.value,
              StepA11y: fields?.StepA11y.value,
              OfA11y: fields?.OfA11y.value,
              CompleteA11y: fields?.CompleteA11y.value,
              NotCompleteA11y: fields?.NotCompleteA11y.value,
              CurrentStepA11y: fields?.CurrentStepA11y.value,
              // textNotCompletedA11y: fields.step3TitleNotCompletedA11y.value,
              // textCompletedA11y: fields.step3TitleCompletedA11y.value,
              hasError: thirdPartySystemErrorType !== '',
            },
          ]}
        />
      )}
      <Header centered headerTitleA11y={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>
      <Stepper
        {...props}
        t={t}
        state={state}
        setState={setState}
        userInfo={userInfo}
        fields={fields}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
        returnUrl={returnUrl}
        setIsError={setIsError}
        isError={isError}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
        rendering={rendering}
        goBackUrl={goBackUrl}
        isOrderCard={isOrderCard}
        isSuccess={queryParams.success === 'True'}
      />
    </div>
  );
};

export default withTranslation()(Checkout);
