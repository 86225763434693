import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const ThumbnailCollections = (props) => (
  <li>
    <a href={props.fields.Link.value.href} target="_blank">
      <Image media={props.fields.Thumbnail} loading="lazy"/>
    </a>
  </li>
);

export default withTranslation()(ThumbnailCollections);
