import React from 'react';

import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

import './cardItem.scss';

const CardItem = ({ fields }) => (
  <div className="card-item">
    <div className="card-border" />
    <div className="card-item-wrapper">
      <div className="card-item-content">
        <p className="heading">
          <Text field={fields.heading} />
        </p>
        <RichText className="description" field={fields.description} />
      </div>
      <div className="item-image">
        <Image media={fields.image} loading="lazy"/>
      </div>
    </div>
  </div>
);

export default CardItem;
