import React, { useState, useEffect } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';

import { getQueryParams, getLongLanguageCode } from '../../services/utils';
import api from '../../services/api';
import { SubHeader, Loader, ButtonBlock } from '../../atoms';
import { Header, CardBlock, Pagination } from '../../molecules';

import './searchResults.scss';

const SearchResults = ({ fields }) => {
  const pageSize = 10;
  const queryParams = getQueryParams(useLocation().search);
  const [keyword, setKeyword] = useState(queryParams?.keywords);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchResults, setSearchResults] = useState();
  const [isLoading, setIsLoading] = useState(true);

  function getResultNumber(resultNumberField) {
    if (!searchResults) {
      return '';
    }

    const firstDisplayedItemNumber = (pageNumber - 1) * pageSize + 1;
    const lastDisplayedItemNumber =
      searchResults && Math.min(searchResults.totalSearchResults, pageNumber * pageSize);
    return resultNumberField.value
      .replace('{firstDisplayedItemNumber}', firstDisplayedItemNumber)
      .replace('{lastDisplayedItemNumber}', lastDisplayedItemNumber)
      .replace('{totalItemCount}', searchResults.totalSearchResults);
  }

  function getKeyword(keywordField) {
    return keywordField.value.replace('{keywords}', keyword);
  }

  useEffect(() => {
    const searchRequest = {
      keyword,
      page: pageNumber - 1,
      pageSize,
      keywordsBoosting: fields?.keywordsBoosting?.value,
      pageTitleEqualsBoosting: fields?.pageTitleEqualsBoosting?.value,
      pageTitleContainsBoosting: fields?.pageTitleContainsBoosting?.value,
      descriptionEqualsBoosting: fields?.descriptionEqualsBoosting?.value,
      descriptionContainsBoosting: fields?.descriptionContainsBoosting?.value,
    };

    setIsLoading(true);
    api
      .getSearchResults(searchRequest)
      .then((response) => {
        setSearchResults(response.data);
      })
      .catch(() => {
        // TODO handle errors
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageNumber]);
  return (
    <div className="search-results">
      <div className="header-search-results" headerTitleA11y={getKeyword(fields.keywordsA11y)}>
        <Text field={fields.heading} />
        {` ${getKeyword(fields.keywords)}`}
      </div>
      {isLoading && <Loader />}
      {searchResults?.totalSearchResults ? (
        <>
          <div>
            <div className="result-number" aria-label={getResultNumber(fields.resultNumbersA11y)}>
              {getResultNumber(fields.resultNumbers)}
            </div>
            {searchResults.results.map((item) => (
              <CardBlock
                title={<a href={`${item.url}`}>{item.pageTitle}</a>}
                titleA11y={item.pageTitle}
              >
                <span>{item.description}</span>
                <a className="result-link" href={`${item.url}`}>
                  {`${item.url}`}
                </a>
              </CardBlock>
            ))}
          </div>
          <ButtonBlock>
            <ButtonBlock right>
              {searchResults.totalSearchResults > pageSize && (
                <Pagination
                  total={Math.ceil(searchResults.totalSearchResults / pageSize)}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </ButtonBlock>
          </ButtonBlock>
        </>
      ) : (
        <CardBlock>
          <p className="no-results" aria-label={fields.noResultsA11y.value}>
            <Text field={fields.noResults} />
          </p>
        </CardBlock>
      )}
    </div>
  );
};

export default SearchResults;
