import React, { useState, useEffect, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch } from 'react-redux';

import { showScreenHeader } from '../../../redux/user/actions';
import { Header, StepTracker } from '../../../molecules';
import CardVerification0 from './CardVerification0';
import CardVerification1 from './CardVerification1';

const CardVerification = (props) => {
  const { fields, t } = props;
  const dispatch = useDispatch();
  const stepsMap = [CardVerification0, CardVerification1];
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => props.toggleVerificationFlow(false);
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const Stepper = stepsMap[step];
  const [state, setState] = useState({
    hasSuccess: false,
    verificationError: '',
  });

  useEffect(() => {
    dispatch(showScreenHeader(false));

    return () => {
      dispatch(showScreenHeader(true));
    };
  }, []);

  return (
    <div className="cardVerification">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={fields.step1Title} />,
            textInProgressA11y: fields.step1TitleInProgressA11y?.value,
            textCompletedA11y: fields.step1TitleCompletedA11y?.value,
          },
          {
            text: <Text field={fields.step2Title} />,
            textNotCompletedA11y: fields.step2TitleNotCompletedA11y?.value,
            textCompletedA11y: fields.step2TitleCompletedA11y?.value,
            hasError: state.verificationError !== '',
          },
        ]}
      />
      <Header centered aria-label={fields.verifyCardTitleA11y.value}>
        <Text field={fields.verifyCardTitle} />
      </Header>
      <Stepper
        {...props}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
      />
    </div>
  );
};

export default withTranslation()(CardVerification);
