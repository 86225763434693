import initialState from '../initialState';
import types from './types';

export function contactless(state = initialState, action = '') {
  switch (action.type) {
    case types.SET_OVERLAY:
      return { ...state, signInModal: action.payload };

    case types.IS_IFRAME_ENABLED:
      return { ...state, isIframeEnabled: action.payload };

    case types.IS_CONTACTLESS_USER_SIGNED_IN:
      return { ...state, isContactlessUserSignedIn: action.payload };

    case types.SET_USER_INFO_DATA:
      return { ...state, userInfoData: action.payload };

    case types.SET_SIGN_OUT_REDIRECT_URL:
      return { ...state, signOutRedirectUrl: action.payload };

    case types.SET_SETTINGS:
      const { Data } = action.payload;
      return {
        ...state,
        configKeys: Data.map((item) => ({
          key: item.Key,
          setting: item.Value,
        })),
      };

    case types.SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload };
    case types.CONTACTLESS_LOGIN_TIME:
      return {
        ...state,
        contactlessLogInTime: action.payload.contactlessLogInTime,
      };
    case types.CONTACTLESS_LOGOUT_TIME:
      return {
        ...state,
        contactlessLogoutTime: action.payload.contactlessLogoutTime,
      };
    case types.SET_CONTACTLESS_TRANS_ITEMS:
      return { ...state, transactionHistory: action.payload };
    case types.SET_UNPAID_FARES:
      return { ...state, unpaidFares: action.payload };
    case types.SET_MULTI_UNPAID_FARES_PROPS:
      return { ...state, multipleUnpaidFareProps: action.payload };
    case types.UPDATE_RETRY_COUNTER:
      return { ...state, retryLimit: action.payload };
    case types.SET_PAYMENTS_TO_VERIFY:
      return { ...state, paymentsToVerify: action.payload };
    default:
      return state;
  }
}
