import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { getLongLanguageCode } from '../../services/utils';
import api from '../../services/api';
import { Error, InlineButtonBlock, TextInput } from '../../molecules';
import handleServerError from '../../services/handleServerError';
import handleServerSuccessError from '../../services/handleServerSuccessError';

export default function SecurityQuestionStep({
  fields,
  toNextStep,
  state,
  setState,
  t,
  apiUrl,
  setIsServerError,
  securityQuestionSubmitsForm,
}) {
  const [error, setIsError] = useState('');
  const question =
    state.questions && state.questions.find((i) => i.LanguageCode === getLongLanguageCode());

  return (
    <>
      {error ? <Error title={error} /> : null}
      <div className="forgot-content">
        <div className="forgot-step">
          <div className="forgot-text">
            <Text field={fields.securityQuestionsTitle} />
          </div>

          <div className="forgot-question">
            <div className="forgot-question-title">
              <Text field={fields.securityQuestionLabel} />
            </div>
            <div className="forgot-question-text">{question && question.Text}</div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              SecurityAnswer: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              SecurityAnswer: Yup.string()
                .required(fields.enterSecurityQuestion.value)
                .max(50, t('forgotUsernameMaxSecurityAnswer'))
                .min(4, t('forgotUsernameMinSecurityAnswer')),
            })}
            onSubmit={(values) => {
              api[apiUrl || 'validateSecurityQA']({
                ...state,
                SecurityQuestionId: question.Id,
                SecurityAnswer: values?.SecurityAnswer?.trim(),
                questions: undefined,
              }).then(({ data }) => {
                if (data.Success) {
                  setState({
                    ...state,
                    username: data.UserName,
                    encryptedSecurityKeyToken: data.encryptedSecurityKeyToken,
                  });
                  toNextStep();
                } else {
                  handleServerSuccessError(data.Error, setIsError, setIsServerError);
                }
              }, handleServerError(setIsServerError, setIsError));
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="SecurityAnswer"
                  errors={errors}
                  touched={touched}
                  label={fields.securityAnswerLabel}
                />
                <InlineButtonBlock
                  type="submit"
                  buttonText={
                    securityQuestionSubmitsForm ? fields.buttonSubmit : fields.buttonContinue
                  }
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
