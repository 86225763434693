import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import sharedFormConstants from '../../constants/sharedFormConstants';
import { isJsonString, redirectToUrl } from '../../services/utils';
import api from '../../services/api';
import { InlineError, MediaImage, MediaImageWrapper } from '../../atoms';
import { CardBlock, Error, InlineButtonBlock } from '../../molecules';
import routes from '../../constants/routes';

const AddContactlessCard2 = (props) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const { fields, isInterac, state, setState, toNextStep, t, rendering } = props;
  const [error, setIsError] = useState('');
  //const returnpath = `${routes.addCard}?contactLessCard=true&nextstep=3`

  return (
    <CardBlock
      title={fields.travelQuestionTitle.value}
      titleA11y={fields.travelQuestionTitleA11y.value}
    >
      {error ? <Error title={error} small /> : null}
      <div aria-label={fields.travelDescriptionA11y.value}>
        <RichText field={fields.travelDescription} />
      </div>
      <div role="group" aria-label={fields.paymentOptionsA11y.value}>
        <MediaImageWrapper>
          {isInterac ? (
            <MediaImage interacCard />
          ) : (
            <>
              <MediaImage visaCard />
              <MediaImage masterCard />
              <MediaImage amexCard />
            </>
          )}
        </MediaImageWrapper>
      </div>

      <Formik
        initialValues={{
          [sharedFormConstants.terms]: false,
        }}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          [sharedFormConstants.terms]: Yup.boolean().oneOf(
            [true],
            t('addContactlessCardTermsAndConditions')
          ),
        })}
        onSubmit={(values) => {
          if (!userInfo.accountId) {
            const data = {
              CustomerId: userInfo.customerId,
            };
            api.contactlessCreateAccount(data).then((response) => {
              if (response.data.Success) {
                setState({
                  ...state,
                  ...values,
                  AccountId: response.data.AccountId,
                });
                //Sts Cloud Policy Call
                const returnpath = `${routes.addCard}?contactLessCardDebit=${isInterac}&state= ${state}&values=${values}&accountId=${response.data.AccountId}`;
                redirectToUrl(`${routes.b2cRegisteredSignIn}?contactLessCard=true&returnUrl=${returnpath}`);

              } else if (isJsonString(response.data.Error)) {
                const errorMessage = JSON.parse(response.data.Error);
                setIsError(errorMessage.Description);
              }
            });
          } else {
            setState({
              ...state,
              ...values,
              AccountId: userInfo.accountId,
            });
            //Sts Cloud Policy Call
            const returnpath = `${routes.addCard}?contactLessCardDebit=${isInterac}&state= ${state}&values=${values}&accountId=${userInfo.accountId}`;
            redirectToUrl(`${routes.b2cRegisteredSignIn}?contactLessCard=true&returnUrl=${returnpath}`);
          }
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Placeholder name="presto-terms-and-conditions" rendering={rendering} />

            {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
              <div className="addCard-termsAndConditions">
                <InlineError>{errors[sharedFormConstants.terms]}</InlineError>
              </div>
            )}
            <InlineButtonBlock type="submit" buttonText={fields.confirmTravelButtonText} />
          </form>
        )}
      </Formik>
    </CardBlock>
  );
};

export default AddContactlessCard2;
