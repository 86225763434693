import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { PrestoLink, Button } from '../../atoms';
import api from '../../services/api';
import './MainHeroBanner.scss';

const MainHeroBanner = (props) => {
  const { datasource, contextItem } = props.fields && props.fields.data ? props.fields.data : {};
  const [slide, setSlide] = useState([
    { screenIndex: 0, visible: false },
    { screenIndex: 1, visible: false },
  ]);
  const [flag, setFlag] = useState(false);
  const sliderScreen = () => {
    return (
      <>
        <div>
          {datasource.children.map((child, index) => {
            const isVisible = slide.filter(
              (el) => el.screenIndex === index && el.visible === true
            ).length;
            return (
              <div
                className={`presto-users-${index} ${
                  isVisible ? `show` : `hide`
                } animation-screen1-screen2`}
              >
                <div className="presto-options-heading-wrapper">
                  <span className="back-icon">
                    <button
                      onClick={() => {
                        setSlide([
                          { screenIndex: 0, visible: false },
                          { screenIndex: 1, visible: false },
                        ]);
                        setFlag(true);
                      }}
                    >
                      <img
                        alt={child.ArrowIcon.jss.value.alt}
                        src={child.ArrowIcon.jss.value.src}
                      />
                      <p>{child.GoBack.jss.value}</p>
                    </button>
                  </span>
                  <div className="home-presto-title">
                    <h1>
                      <Text field={child.Title.jss} />
                    </h1>
                    <span>{child.Description.jss.value}</span>
                  </div>
                </div>
                <div className="presto-available-options">
                  <div data-e2e-id="graphql-integrated">
                    <ul>
                      {child.children.map((subchild, index) => {
                        return (
                          <li className={`presto-options-list-${index}`}>
                            <PrestoLink
                              field={subchild.Pagelink?.jss}
                              onClickCustom={
                                subchild.Pagelink?.jss.value.href.indexOf('B2CRegisteredSignIn') >
                                -1
                                  ? () => api.b2cRegisteredSignIn()
                                  : ''
                              }
                            >
                              <img
                                alt={subchild.Icon.jss.value.alt}
                                src={subchild.Icon.jss.value.src}
                              />
                              <div className="presto-options-description">
                                <h3>{subchild.Title.jss.value}</h3>
                                <p>{subchild.Description.jss.value}</p>
                              </div>
                            </PrestoLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };
  return slide.filter((el) => el.visible).length ? (
    sliderScreen()
  ) : (
    <div className={`homepage-banner ${flag ? `animation-hero` : ``}`}>
      <div class="heroimage-banner-home">
        {/* {datasource.BackgroundImage?.jss.value && (
          <img
            src={datasource.BackgroundImage.jss.value.src}
            alt={datasource.BackgroundImage.jss.value.alt}
          />
        )} */}
      </div>
      <div data-e2e-id="graphql-integrated" className="prsto-intro-wrapper">
        <div class="presto-intro">
          <h2>{datasource.Title.jss.value}</h2>
          <div class="presto-intro-buttons">
            <Button
              type="submit"
              onClick={() =>
                setSlide([
                  { screenIndex: 0, visible: true },
                  { screenIndex: 1, visible: false },
                ])
              }
              id="new-presto-button"
            >
              <RichText field={datasource?.Herobuttontext1?.jss} />
            </Button>
            <Button
              type="submit"
              id="presto-user-button"
              onClick={() =>
                setSlide([
                  { screenIndex: 0, visible: false },
                  { screenIndex: 1, visible: true },
                ])
              }
            >
              <RichText field={datasource?.Herobuttontext2?.jss} />
            </Button>
          </div>
          <PrestoLink field={datasource.Subtitlelink?.jss}>
            <span className={datasource.Subtitlelink?.jss.value.href === contextItem.url}>
              {datasource.Subtitle.jss.value}
            </span>
          </PrestoLink>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(MainHeroBanner);
