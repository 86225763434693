import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import './ctaButton.scss';

const CTAButton = (props) => {
  return (
    <div className="container" id={props.fields && props?.fields?.ButtonId?.value}>
      <div className="container-paragraph">
        <RichText field={props?.fields?.Title} />

        <div className="perks-btn">
          <button
            data-testid="cta-perks-btn"
            onClick={() => {
              window.location.href = props?.fields?.ButtonLink?.value?.href;
            }}
          >
            {props?.fields?.ButtonText?.value}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CTAButton;
