import React from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, SubHeader } from '../../atoms';
import { Header } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import './errorPage.scss';

const ErrorPage = (props) => (
  <div className="errorPage">
    <Header centered>
      <Text field={props.fields.heading} />
    </Header>
    <SubHeader centered>
      <RichText field={props.fields.description} />
    </SubHeader>
    {props.fields.redirectToLink.value?.href !== '' && (
      <ButtonBlock>
        <ButtonBlock center>
          <Button onClick={() => redirectToUrl(props.fields.redirectToLink.value?.href)}>
            {props.fields.redirectToLink.value?.text}
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    )}
  </div>
);

export default ErrorPage;
