import React, { useState, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { showScreenHeader } from '../../redux/user/actions';
import { StepTracker } from '../../molecules';
import { getServerUrl, getQueryParams } from '../../services/utils';
import handleThirdPartyError from '../../services/handleThirdPartyError';
import handleAVSValidationError from '../../services/handleAVSValidationError';
import SetAutoload1 from './SetAutoload1';
import SetAutoload2 from './SetAutoload2';
import SetAutoload3 from './SetAutoload3';

import '../Autoload/autoload.scss';
import '../Autoload/autoloadPayment.scss';
import '../Autoload/autoloadSuccess.scss';

const stepsSetAutoloadMap = [SetAutoload1, SetAutoload2, SetAutoload3];

const SetAutoload = ({ fields, t, rendering, backToMyCardButtonLink }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => window.location.reload();
  const [state, setState] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const [firstLoad, setIsFirstLoad] = useState(false);
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');

  const returnUrl = `${getServerUrl()}${location.pathname}`;

  const queryParams = getQueryParams(location.search);
  const errorDisplay = handleAVSValidationError(queryParams, t);
  useEffect(() => {
    if (queryParams.success !== 'true' && step === 0 && !firstLoad) {
      dispatch(showScreenHeader(true));
      setIsFirstLoad(true);
    }
    if (queryParams.success === 'true') {
      const { loadAmount, thresholdAmount, transactionDate } = queryParams;
      dispatch(showScreenHeader(false));
      setState({
        ...state,
        loadAmount,
        thresholdAmount,
        transactionDate,
      });
      setStep(2);
      focusDiv.current.focus();
    }
    if (queryParams.error !== undefined || queryParams.cancel !== undefined) {
      const { loadAmount, thresholdAmount } = queryParams;
      let stepToSet = 1;

      setState({
        ...state,
        loadAmount,
        thresholdAmount,
      });

      handleThirdPartyError(queryParams.error, (error) => {
        setThirdPartySystemErrorType(error);
        stepToSet = 2;
      });
      dispatch(showScreenHeader(false));
      setStep(stepToSet);
      focusDiv.current.focus();
    }
  }, []);

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  const Stepper = stepsSetAutoloadMap[step];

  return (
    <>
      {step !== 0 ? (
        <div className="no-focus-outline">
          <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
            {t('StepperChangeSRText')}
          </span>
          <StepTracker
            activeIndex={step - 1}
            items={[
              {
                text: <Text field={fields.step1Title} />,
                textInProgressA11y: fields.step1TitleInProgressA11y.value,
                textCompletedA11y: fields.step1TitleCompletedA11y.value,
              },
              {
                text: <Text field={fields.step2Title} />,
                textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
                textCompletedA11y: fields.step2TitleCompletedA11y.value,
                hasError: thirdPartySystemErrorType !== '',
              },
            ]}
          />
        </div>
      ) : null}
      <Stepper
        t={t}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        toPreviousStep={toPreviousStep}
        fields={fields}
        rendering={rendering}
        userInfo={userInfo}
        configKeys={configKeys}
        locationSearch={location.search}
        dispatch={dispatch}
        backToMyCardButtonLink={backToMyCardButtonLink}
        toFirstStep={toFirstStep}
        returnUrl={returnUrl}
        isError={queryParams.error !== undefined}
        errorContent={errorDisplay}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
      />
    </>
  );
};
export default withTranslation()(SetAutoload);
