import React from 'react';

import { withTranslation } from 'react-i18next';

import { Icon } from '../../atoms';

import './pagination.scss';

const Pagination = ({ pageNumber, total, setPageNumber, t }) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === total;

  return (
    <div className="pagination">
      <button
        onClick={() => (isFirstPage ? null : setPageNumber(1))}
        aria-disabled={isFirstPage}
        className={`pagination-button ${isFirstPage ? 'disabled' : ''}`}
        aria-label={t('paginationFirstPageButtonA11y')}
      >
        <Icon
          size="lg"
          color={`${isFirstPage ? 'disabled' : ''}`}
          iconName="iconLeftEnd"
          ariaLabel={t('paginationFirstPageButtonA11y')}
        />
      </button>
      <button
        onClick={() => (isFirstPage ? null : setPageNumber(pageNumber - 1))}
        aria-disabled={isFirstPage}
        className={`pagination-button second ${isFirstPage ? 'disabled' : ''}`}
        aria-label={t('paginationPreviousPageButtonA11y')}
      >
        <Icon
          size="lg"
          color={`${isFirstPage ? 'disabled' : ''}`}
          iconName="iconLeft"
          ariaLabel={t('paginationPreviousPageButtonA11y')}
        />
      </button>

      <div tabIndex="0" className="pagination-page">
        {pageNumber}/{total}
      </div>

      <button
        onClick={() => (isLastPage ? null : setPageNumber(pageNumber + 1))}
        aria-disabled={isLastPage}
        className={`pagination-button ${isLastPage ? 'disabled' : ''}`}
        aria-label={t('paginationNextPageButtonA11y')}
      >
        <Icon
          size="lg"
          color={`${isLastPage ? 'disabled' : ''}`}
          iconName="iconRight"
          ariaLabel={t('paginationNextPageButtonA11y')}
        />
      </button>
      <button
        onClick={() => (isLastPage ? null : setPageNumber(total))}
        aria-disabled={isLastPage}
        className={`pagination-button second ${isLastPage ? 'disabled' : ''}`}
        aria-label={t('paginationLastPageButtonA11y')}
      >
        <Icon
          size="lg"
          color={`${isLastPage ? 'disabled' : ''}`}
          iconName="iconRightEnd"
          ariaLabel={t('paginationLastPageButtonA11y')}
        />
      </button>
    </div>
  );
};

export default withTranslation()(Pagination);
