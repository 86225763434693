import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { CheckoutGrid, Header, Success } from '../../molecules';
import { formatDate, formatDecimalWithCurrency, redirectToUrl } from '../../services/utils';
import { Button, IconButton } from '../../atoms';
import printIcon from '../../assets/images/printer.svg';

const ManageAutoRenew2 = ({ fields, userInfo, backToTransitPassesButtonLink }) => {
  const { Heading } = fields;
  const { checkoutData } = useSelector((reduxState) => reduxState.products.manageAutoRenew);
  const [checkoutItems, setcheckoutItems] = useState([]);
  useEffect(() => {
    if (checkoutData.validityStartDate && checkoutData.oldEndDate) {
      setcheckoutItems([
        {
          ...checkoutData,
          validityEndDate: checkoutData.oldEndDate.replace(/\s+/g, '+'),
          validityStartDate: checkoutData.validityStartDate,
          visibleId: userInfo.selectedCard.visibleId,
        },
      ]);
    }
  }, [checkoutData]);
  return (
    <div className="autorenew manage-autorenew">
      <div className="manage-autoRenew1--title">
        <Header aria-label={Heading}>
          <Text field={Heading} />
        </Header>
      </div>
      {checkoutItems.length ? (
        <Success
          title={fields.SucessMessage}
          Content={() => (
            <>
              <CheckoutGrid
                fields={fields}
                items={checkoutItems}
                fareMedia={{ visibleId: userInfo.selectedCard.visibleId, autoRenew: 'true' }}
                success
              />
              <div className="autorenew-agreement-success">
                <div className="autorenew-agreement-title no-padding">
                  <Text field={fields.AutoRenewAgrrementLabel} />
                </div>
                {checkoutData.autorenew.endDate ? (
                  <div>
                    {`${formatDate(new Date())} - ${formatDate(checkoutData.autorenew.endDate)}`}
                  </div>
                ) : null}

                <div className="autorenew-agreement-note">
                  {fields.autorenewAgreementPeriodDescription.value
                    .replace(
                      '{monthly-payment-amount}',
                      formatDecimalWithCurrency(checkoutData.subTotal)
                    )
                    .replace('{transit-pass-name}', checkoutData.name)}
                </div>
              </div>
              <div className="checkout-total successModifier manageRenew">
                <Text field={fields.MonthlyOrderTotalLabel} />
                <span>{formatDecimalWithCurrency(checkoutData.subTotal)}</span>
              </div>
            </>
          )}
        />
      ) : null}
      <div className="manageautorenew2-footer ">
        <div className="manageautorenew2-transitpass-btn">
          <Button
            firstOrder
            onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
            buttonTextA11y={fields.GoToTransitPassCTA.value}
          >
            <Text field={fields.GoToTransitPassCTA} />
          </Button>
        </div>
        <div id="manageautorenew2-printicon">
          <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
            {fields.PrintCTA.value}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ManageAutoRenew2;
