import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getQueryParams } from '../../services/utils';
import routes from '../../constants/routes';
import { StepTracker } from '../../molecules';
import handleThirdPartyError from '../../services/handleThirdPartyError';
import handleAVSValidationError from '../../services/handleAVSValidationError';
import SetAutorenew1 from './SetAutorenew1';
import SetAutorenew2 from './SetAutorenew2';

const backToTransitPassesButtonLink = routes.transitPasses;
const stepsMap = [SetAutorenew1, SetAutorenew2];
const checkoutStep1 = 0;
const checkoutStep2 = 1;

const SetAutorenew = ({ fields, t, state, setState, action, isSuccess, error, rendering }) => {
  const userInfo = useSelector((s) => s.user);
  const configKeys = useSelector((s) => s.settings.configKeys);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toCheckoutStep1 = () => {
    setStep(checkoutStep1);
    focusDiv.current.focus();
  };
  const toCheckoutStep2 = () => {
    setStep(checkoutStep2);
    focusDiv.current.focus();
  };
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const errorDisplay = handleAVSValidationError(queryParams, t);

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  useEffect(() => {
    const serializedData = queryParams?.serializedData && JSON.parse(queryParams.serializedData);
    if (action === 'set' && serializedData.flow !== 'ttc') {
      if (isSuccess === 'true') {
        setStep(checkoutStep2);
        focusDiv.current.focus();
      } else {
        let stepToSet = checkoutStep1;

        if (error) {
          handleThirdPartyError(error, (err) => {
            setThirdPartySystemErrorType(err);
            stepToSet = checkoutStep2;
          });
        }
        setStep(stepToSet);
        focusDiv.current.focus();
      }
    }
  }, []);

  const Stepper = stepsMap[step];
  return (
    <div className="set-autoRenew--steps ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={fields.step1Title} />,
            textInProgressA11y: fields.step1TitleInProgressA11y.value,
            textCompletedA11y: fields.step1TitleCompletedA11y.value,
          },
          {
            text: <Text field={fields.step2Title} />,
            textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
            textCompletedA11y: fields.step2TitleCompletedA11y.value,
            hasError: thirdPartySystemErrorType !== '',
          },
        ]}
      />

      <Stepper
        fields={fields}
        t={t}
        state={state}
        setState={setState}
        errorContent={errorDisplay}
        userInfo={userInfo}
        configKeys={configKeys}
        toCheckoutStep1={toCheckoutStep1}
        toCheckoutStep2={toCheckoutStep2}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        rendering={rendering}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
      />
    </div>
  );
};
export default withTranslation()(SetAutorenew);
