import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';

import {
  formatDate,
  formatDecimalWithCurrency,
  formatUtcTimeFromDate,
  formatUtcTimeFromDate2,
  redirectToUrl,
  TransactionCodeValue,
} from '../../../services/utils';
import { removeAllFromShoppingCart } from '../../../redux/user/actions';
import { ButtonBlock, Button, PrintButton, IconButton, Loader } from '../../../atoms';
import { CheckoutGrid, Error, Success, ThirdPartySystemErrorWarning } from '../../../molecules';
import { transactionType } from '../../../constants/transactionType';
import printIcon from '../../../assets/images/printer.svg';
import api from '../../../services/api';
import { setUserInfo, setDeactivateCardType, fetchUserInfoData } from '../../../redux/user/actions';

import './DeactivateAndTransferNew3.scss';

const DeactivateAndTransferNew3 = ({
  fields,
  state,
  thirdPartySystemErrorType,
  toPreviousStep,
  userInfo,
  t,
  isTransferBalance,
  step,
}) => {
  const visibleId = userInfo.selectedCard.visibleId;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const orderCardResponse = useSelector((reduxData) => reduxData.user.orderCardResponse);
  const paymentApiResponse = useSelector((reduxState) => reduxState.user.paymentApiSuccess);
  const isErrorBlock = !orderCardResponse?.data?.Success;
  const newShippingDetails = useSelector((redux) => redux.user.newShippingDetails);
  const defaultShippingDetails = useSelector((redux) => redux.user.currentShippingDetails);
  const addressType = useSelector((reduxState) => reduxState.user.addressType);
  const paymentApiSuccess = paymentApiResponse?.data?.Success;
  const shippingDetails = orderCardResponse?.data?.Order?.ShippingAddress
    ? orderCardResponse.data.Order.ShippingAddress
    : paymentApiResponse?.data?.Order?.ShippingAddress;
  let paymentDetails = orderCardResponse?.data?.Order?.Payment?.Details
    ? orderCardResponse.data.Order?.Payment?.Details
    : paymentApiResponse?.data?.Order?.Payment?.Details;
  // paymentDetails =
  //   paymentDetails && paymentDetails.length > 0 && paymentDetails[0].PaymentDetailModel;
  let dateAndTime = orderCardResponse?.data?.Order?.Payment?.Details?.TransactionDateTime
    ? orderCardResponse.data.Order.Payment.Details.TransactionDateTime
    : paymentApiResponse?.data?.Order?.Payment?.Details?.TransactionDateTime;
  // let dateSplit = dateAndTime?.split(' ');
  const formattedDate = dateAndTime ? formatUtcTimeFromDate2(dateAndTime) : null;
  //  const formattedTime = formatUtcTimeFromDate2(dateAndTime);
  let TxType = TransactionCodeValue(paymentDetails?.TransactionType);
  const referenceNumber = orderCardResponse?.data?.Order?.AFMSSalesId
    ? orderCardResponse.data.Order.AFMSSalesId
    : paymentApiResponse?.data?.Order?.AFMSSalesId;
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias = fareMedias.find((data) => data.visibleId === visibleId);
  useEffect(() => {
    document.body.scrollTop = 0;
    dispatch(removeAllFromShoppingCart());
    if (!isErrorBlock || paymentApiSuccess) {
      dispatch(fetchUserInfoData());
    }
  }, []);

  const GotoDashBoardHandler = () => {
    // dispatch(setDeactivateCardType(''));
    window.location.href = fields?.GotoDashBoardLink?.value?.href;
    // setIsLoading(true);
    // api
    //   .getUserInfo()
    //   .then((response) => {
    //     if (response?.data?.Success) {
    //       dispatch(setUserInfo(response.data.Data));
    //       setIsLoading(false);
    //       window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //     }
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     window.location.href = fields?.GotoDashBoardLink?.value?.href;
    //   });
  };

  const label = {
    value: 'Label',
  };

  const orderLines = orderCardResponse?.data?.Order?.OrderLines;
  const newCardDetails = orderLines && orderLines.length > 0 && orderLines[0].FareMedia;
  const subTotalValue = fields.PriceValue.value;
  const newbalanceval = fareMedias?.currentBalance - parseInt(subTotalValue.substring(1));
  const items = [
    {
      image: fields.PrestoCardIcon.value,
      name: fields.SingleFarecard.value,
      quantity: 1,
      subTotal: subTotalValue.substring(1),
      productFamily: '',
      visibleId: 'NEWMEDIA',
      serviceProvider: 'Presto',
      productFamily: 'Epurse',
    },
  ];

  const orderLinesNew = paymentApiResponse?.data?.Order?.OrderLines;
  const fareMediasNew =
    paymentApiResponse?.data?.Order?.Customer &&
    paymentApiResponse?.data?.Order?.Customer.FareMedias;
  const itemsNew = [
    {
      id: 1,
      name: fields.SingleFarecard.value,
      quantity: 1,
      subTotal: subTotalValue.substring(1),
      productFamily: fareMediasNew && fareMediasNew.length > 0 && fareMediasNew[0].ProductFamily,
      visibleId: fareMediasNew && fareMediasNew.length > 0 && fareMediasNew[0].VisibleId,
      serviceProvider: 'Presto',
      productFamily: 'Epurse',
    },
  ];
  return (
    <>
      {thirdPartySystemErrorType ? (
        <ThirdPartySystemErrorWarning
          {...{ fields, errorCode: thirdPartySystemErrorType, referenceNumber: state.reference }}
        />
      ) : (
        <>
          {!isErrorBlock || paymentApiSuccess ? (
            <>
              {isLoading && <Loader />}
              <div className="header-subTitle">
                {state.orderid && !state.savedPaymentMethodUsed && (
                  <>
                    <Text field={label} /> {state.orderid}
                  </>
                )}
              </div>
              <Success
                title={fields?.SuccessMessage}
                richText
                Content={() => (
                  <>
                    {
                      fareMedias.currentBalance >= t('prestoCardPrice') ? (
                        state.fareMedias &&
                        state.fareMedias.map((fareMedia, index) => {
                          if (index == 0) {
                            return (
                              <CheckoutGrid
                                fields={fields}
                                items={itemsNew}
                                fareMedia={fareMedia}
                                success
                                deactivateFlow
                                paymentApiResponse={paymentApiResponse}
                                t={t}
                                step={step}
                                fundsSelected
                                currentBalance={newbalanceval}
                              />
                            );
                          }
                        })
                      ) : (
                        //state.fareMedias &&
                        //   state.fareMedias.map((fareMedia, index) => {
                        //     if (index == 0) {
                        //       return (
                        <CheckoutGrid
                          fields={fields}
                          items={items}
                          fareMedia={fareMedias}
                          success
                          deactivateFlow
                          orderCardResponse={orderCardResponse}
                          hideRemove={true}
                          t={t}
                          fundsSelected
                          currentBalance={fareMedias.currentBalance}
                        />
                      )
                      //     );
                      //   }
                      // })
                    }
                    <div className="checkout-total successModifier">
                      <Text field={fields?.OrderTotal} />{' '}
                      {formatDecimalWithCurrency(
                        fareMedias.currentBalance >= t('prestoCardPrice') ? itemsNew[0].subTotal : items[0].subTotal
                      )}
                    </div>
                  </>
                )}
              />

              {!state.savedPaymentMethodUsed && (
                <div className="checkout-transactionInfoGrid">
                  {addressType == 'new' ? (
                    <div className="checkout-shippingDetails">
                      <div>
                        <Text field={fields?.ShippingAddressTitle} />
                      </div>
                      <div>
                        <p>
                          <b>
                            {newShippingDetails?.Line1} {newShippingDetails?.Line2}
                          </b>
                        </p>
                        <p>
                          {newShippingDetails?.City} {newShippingDetails?.AreaCode}
                        </p>
                        <p>
                          {newShippingDetails?.CountryId} {newShippingDetails?.PostalCode}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="checkout-shippingDetails">
                      <div>
                        <Text field={fields?.ShippingAddressTitle} />
                      </div>
                      <div>
                        <p>
                          <b>
                            {defaultShippingDetails?.Line1} {defaultShippingDetails?.Line2}
                          </b>
                        </p>
                        <p>
                          {defaultShippingDetails?.City} {defaultShippingDetails?.Area?.Code}
                        </p>
                        <p>
                          {defaultShippingDetails?.Country?.Id} {defaultShippingDetails?.PostalCode}
                        </p>
                      </div>
                    </div>
                  )}
                  {TxType !== undefined && paymentDetails?.TransactionType !== null && (
                    <div className="checkout-transactionInfoGrid-row">
                      <div>
                        <Text field={fields?.TransactionType} />
                      </div>
                      <div>{TxType}</div>
                    </div>
                  )}
                  {paymentDetails?.ResponseCode && (
                    <div className="checkout-transactionInfoGrid-row">
                      <div>
                        <Text field={fields?.ResponseCodeMessage} />
                      </div>
                      <div>
                        {paymentDetails.ResponseCode} - {paymentDetails.AcceptanceStatus}
                      </div>
                    </div>
                  )}
                  {paymentDetails?.ISOCode && (
                    <div className="checkout-transactionInfoGrid-row">
                      <div>
                        <Text field={fields?.ISOCode || '-'} />
                      </div>
                      <div>{paymentDetails.ISOCode}</div>
                    </div>
                  )}
                  {dateAndTime && (
                    <div className="checkout-transactionInfoGrid-row">
                      <div>
                        <Text field={fields?.DateTime} />
                      </div>
                      <div>{`${formattedDate}`}</div>
                    </div>
                  )}
                  {referenceNumber && (
                    <div className="checkout-transactionInfoGrid-row">
                      <div>
                        <Text field={fields?.ReferenceNumber} />
                      </div>
                      <div>{referenceNumber}</div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <Error
                title={fields.ErrorMessage.value}
                titleA11y={fields.ErrorMessage.value}
                text={fields.OrderFailureMessage}
              />
            </>
          )}
          <ButtonBlock className="print-hidden transfer-new-print">
            <IconButton className="print-hide" onClick={() => window.print()} icon={printIcon}>
              {fields.Print.value}
            </IconButton>
            <ButtonBlock right>
              {paymentApiSuccess || !isErrorBlock ? (
                <Button
                  firstOrder
                  buttonTextA11y={fields?.GotoDashBoard.value}
                  onClick={GotoDashBoardHandler}
                >
                  <Text field={fields?.GotoDashBoard} />
                </Button>
              ) : (
                <Button
                  firstOrder
                  buttonTextA11y={fields?.TryAgainBtnLable.value}
                  onClick={() => {
                    toPreviousStep();
                  }}
                >
                  <Text field={fields?.TryAgainBtnLable} />
                </Button>
              )}
            </ButtonBlock>
          </ButtonBlock>
        </>
      )}
    </>
  );
};

export default withTranslation()(DeactivateAndTransferNew3);
