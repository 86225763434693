import { isJsonString } from './utils';

export default function handleServerError(setIsServerError, setIsError) {
  return function handler({ response }) {
    if (response.status !== 400 && response.status !== 200) {
      if (!setIsServerError) return;
      setIsServerError(true);
      return;
    }

    const parsedMessage =
      response &&
      response.data.Message &&
      (isJsonString(response.data.Message)
        ? JSON.parse(response.data.Message)
        : response.data.Message);

    let message = parsedMessage && (parsedMessage.Description || parsedMessage);

    if (!message) {
      message = '';
    }

    setIsError(message);
  };
}
