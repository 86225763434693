import React from 'react';

import { useHistory } from 'react-router-dom';

import { Success, InlineButtonBlock } from '../../molecules';

export default function ForgotPassword4({ fields }) {
  const history = useHistory();
  return (
    <Success
      title={fields.passwordResetSuccessful}
      Content={() => (
        <>
          <InlineButtonBlock onClick={() => history.push('/')} buttonText={fields.buttonSignIn} />
        </>
      )}
    />
  );
}
