import React from 'react';

import { withTranslation } from 'react-i18next';

import visaCardImage from '../../assets/images/card_visa.svg';
import masterCardImage from '../../assets/images/card_masterCard.svg';
import interacCardImage from '../../assets/images/card_interac.svg';
import amexCardImage from '../../assets/images/card_amex.svg';
import prestoCardImage from '../../assets/images/card_presto.svg';
import './mediaImage.scss';

const MediaImage = (props) => {
  const { big, small, medium, sm, t, cardImgSrc = '', cardImgAlt = '', cardAltText = '' } = props;
  const cards = {
    visaCard: {
      src: visaCardImage,
      alt: t('visaCardLogo'),
    },
    masterCard: {
      src: masterCardImage,
      alt: t('masterCardLogo'),
    },
    interacCard: {
      src: interacCardImage,
      alt: t('interacCardLogo'),
    },
    amexCard: {
      src: amexCardImage,
      alt: t('amexCardLogo'),
    },
    prestoCard: {
      src: prestoCardImage,
      alt: t('prestoCardLogo'),
    },
    activeGWalletCard: {
      src: '',
      alt: '',
    },
    inActiveGWalletCard: {
      src: '',
      alt: '',
    },
  };
  const smallOrFallbackCssClass = small ? '-small' : '';
  // eslint-disable-next-line no-nested-ternary
  const cssClass = big ? '-big' : medium ? '-medium' : sm ? '-sm' : smallOrFallbackCssClass;
  let mediaImageElm = null;

  if (
    cardImgSrc !== '' &&
    (props.cardAltText == 'visaCard' ||
      props.cardAltText == 'masterCard' ||
      props.cardAltText == 'mastercard' ||
      props.cardAltText == 'amexCard' ||
      props.cardAltText == 'interacCard' ||
      props.cardImgAlt.length > 0)
  ) {
    return (
      <img
        src={cardImgSrc}
        className={`mediaImage${cssClass}`}
        alt={cardImgAlt ? cardImgAlt : cards[props.cardAltText].alt}
      />
    );
  }

  Object.keys(cards).forEach((cardName) => {
    if (props[cardName]) {
      mediaImageElm = (
        <img
          className={`mediaImage${cssClass}`}
          src={cards[cardName].src}
          alt={cardImgAlt ? cardImgAlt : cards[cardName].alt}
        />
      );
    }
  });

  return mediaImageElm;
};

export default withTranslation()(MediaImage);
