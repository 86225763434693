import React, { useEffect, useState } from 'react';

import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import './imageWithProcessStep.scss';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { is } from '../../services/utils';

const ImageWithProcessStep = ({ fields, params }) => {
  const [carouselstate, setCarouselState] = useState({
    activeSlide: 0,
    activeSlide2: 0,
  });
  const settings = {
    accessibility: true,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCarouselState({ activeSlide: next }),
    afterChange: (current) => setCarouselState({ activeSlide2: current }),

    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          slidesToShow: 1,

          slidesToScroll: 1,
        },
      },
    ],
    };
  return (
    <>
      {/* desktop view */}
      <div className="mobile-process-step-container process-step-layout-md">
        <div
          className={`process-step-main-container ${fields?.steps[0]?.fields?.link?.value !== '' && fields?.steps?.length === 1
            ? 'one-column'
            : params?.fourColumnLayout == 1
              ? 'four-column'
              : 'three-column-layout'
            }`}
        >
          {fields?.steps?.map((stepItem) => (
            <>
              {stepItem?.fields?.link?.value !== '' &&
                fields?.steps?.length === 1 &&
                stepItem?.fields?.stepImage?.value?.src && (
                  <>
                    <div>
                      <div className="process-step-layout">
                        <div className="process-step-image-block">
                          <Image field={stepItem?.fields?.stepImage} loading="lazy" />
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {params?.fourColumnLayout == '1' && fields?.steps?.length >= 4 && (
                <div className="process-step-layout process-step-layout-md">
                  {is(stepItem?.fields?.stepImage?.value?.src) ? (
                    <div className="process-step-image-block">
                      <Image field={stepItem?.fields?.stepImage} loading="lazy" />
                    </div>
                  ) : null}
                  {is([
                    stepItem?.fields?.stepTitle?.value,
                    stepItem?.fields?.stepDescription?.value,
                  ]) ? (
                    <div className="process-step-content-block">
                      {is(stepItem?.fields?.stepTitle?.value) ? (
                        <h2>{stepItem?.fields?.stepTitle?.value}</h2>
                      ) : null}
                      {is(stepItem?.fields?.stepDescription?.value) ? (
                        <p>{stepItem?.fields?.stepDescription?.value}</p>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}

              {params?.fourColumnLayout === undefined && fields?.steps?.length >= 3 && fields?.isImageAtBottom?.value === false && (
                //<div className="process-step-layout process-step-layout-md">
                <div className={fields?.className?.value === '' ? "process-step-layout process-step-layout-md" : fields?.className?.value}>
                  {is(stepItem?.fields?.stepImage.value?.src) ? (
                    <div className={fields?.className?.value === '' ? "process-step-image-block" : fields?.className?.value + "-image-block"}>
                      <Image field={stepItem?.fields?.stepImage} loading="lazy" />
                    </div>
                  ) : null}
                  {is([
                    stepItem?.fields?.stepTitle?.value,
                    stepItem?.fields?.stepDescription?.value,
                  ]) ? (
                    <div className={fields?.className?.value === '' ? "process-step-content-block" : fields?.className?.value + "-content-block"}>
                      {is(stepItem?.fields?.stepTitle?.value) ? (
                        <h2>{stepItem?.fields?.stepTitle?.value}</h2>
                      ) : null}
                      {is(stepItem?.fields?.stepDescription?.value) ? (
                        <p>{stepItem?.fields?.stepDescription?.value}</p>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
              {params?.fourColumnLayout === undefined && fields?.steps?.length >= 3 && fields?.isImageAtBottom?.value === true && (
                //<div className="process-step-layout process-step-layout-md">
                <div className={fields?.className?.value === '' ? "process-step-layout process-step-layout-md" : fields?.className?.value}>
                  {is([
                    stepItem?.fields?.stepTitle?.value,
                    stepItem?.fields?.stepDescription?.value,
                  ]) ? (
                    <div className={fields?.className?.value === '' ? "process-step-content-block" : fields?.className?.value + "-content-block"}>
                      {is(stepItem?.fields?.stepTitle?.value) ? (
                        <h2>{stepItem?.fields?.stepTitle?.value}</h2>
                      ) : null}
                      {is(stepItem?.fields?.stepDescription?.value) ? (
                        <p>{stepItem?.fields?.stepDescription?.value}</p>
                      ) : null}
                    </div>
                  ) : null}
                  {is(stepItem?.fields?.stepImage.value?.src) ? (
                    <div className={fields?.className?.value === '' ? "process-step-image-block" : fields?.className?.value + "-image-block"}>
                      <Image field={stepItem?.fields?.stepImage} loading="lazy" />
                    </div>
                  ) : null}
                </div>
              )}
              {is(fields?.googleWalletLink?.value?.href) ? (
                <div className="process-step-layout-external-link">
                  <p>
                    <a
                      tabIndex="0"
                      href={fields?.googleWalletLink?.value?.href}
                      target={fields?.googleWalletLink?.value?.target}
                    >
                      {is(fields?.googleWalletLinkText?.value) ? (
                        <Text field={fields?.googleWalletLinkText} />
                      ) : null}
                      {is(fields?.googleWalletLinkImage?.value?.src) ? (
                        <Image field={fields?.googleWalletLinkImage} />
                      ) : null}
                    </a>
                  </p>
                </div>
              ) : null}
            </>
          ))}
        </div>
      </div>

      {/* mobile view  */}
      <div
        className="mobile-process-step-container process-step-layout-sm"
        id={
          params?.fourColumnLayout === 1 && fields?.steps?.length >= 4
            ? 'stepper-four-column'
            : params?.fourColumnLayout === 0 && fields?.steps?.length >= 3
              ? 'stepper-three-column'
              : ''
        }
      >{fields?.isMobileNotASlideShow?.value === true && typeof window !== 'undefined' &&
        typeof (document.getElementsByClassName('mobile-process-step-container process-step-layout-md')[0]) !== "undefined" &&
        typeof (document.getElementsByClassName('mobile-process-step-container process-step-layout-sm')[0]) !== "undefined" ?
        (document.getElementsByClassName('mobile-process-step-container process-step-layout-md')[0].style.display = 'block',
          document.getElementsByClassName('mobile-process-step-container process-step-layout-sm')[0].style.display = 'none')
        :
        <Slider {...settings}>
          {fields?.steps?.map((stepItem) => (
            <>
              {is([
                stepItem?.fields?.stepTitle?.value,
                stepItem?.fields?.stepDescription?.value,
              ]) ? (
                <div className="process-step-layout-sm-content-block">
                  {is(stepItem?.fields?.stepTitle?.value) ? (
                    <h2>{stepItem?.fields?.stepTitle?.value}</h2>
                  ) : null}
                  {is(stepItem?.fields?.stepDescription?.value) ? (
                    <p className="process-step-layout-sm-content-block-p-tag">
                      {stepItem?.fields?.stepDescription?.value}
                    </p>
                  ) : null}
                </div>
              ) : null}
              <div className="process-step-layout-sm-image-block">
                {is(stepItem?.fields?.stepImage?.value?.src) ? (
                  <Image
                    width={
                      fields?.steps[0]?.fields?.link?.value !== '' && fields?.steps?.length === 1
                        ? '230'
                        : params?.fourColumnLayout === 1 && fields?.steps?.length >= 4
                          ? '210'
                          : '230'
                    }
                    field={stepItem?.fields?.stepImage}
                  />
                ) : null}
              </div>
              {stepItem?.fields?.link?.value !== '' && fields?.steps?.length === 1 && (
                <div className="process-step-one-item" />
              )}
            </>
          ))}
        </Slider>}
        <div className="stepper-sr-only" aria-live="polite" aria-atomic="true">
          <p>
            {fields?.steps[carouselstate.activeSlide]?.fields?.stepTitle?.value}
            {fields?.steps[carouselstate.activeSlide]?.fields?.stepDescription?.value}
          </p>
        </div>
      </div>
      {is(fields?.googleWalletLink?.value?.href) ? (
        <div className="process-step-layout-external-link process-mobile-link">
          <p>
            <a
              tabIndex="0"
              href={fields?.googleWalletLink?.value?.href}
              target={fields?.googleWalletLink?.value?.target}
            >
              {is(fields?.googleWalletLinkText?.value) ? (
                <Text field={fields?.googleWalletLinkText} />
              ) : null}
              {is(fields?.googleWalletLinkImage?.value?.src) ? (
                <Image field={fields?.googleWalletLinkImage} />
              ) : null}
            </a>
          </p>
        </div>
      ) : null}
    </>
  );
};

export default ImageWithProcessStep;
