import api from './api';
import { getRefreshToken, localstorageService, redirectToUrl } from './utils';
import { itemsAreLoading } from '../redux/user/actions';
import localStorageKeys from '../constants/localStorageKeys';

const handleLogOut = (dispatch, redirectUrl = '/B2CRedirect/B2CRegisteredSignIn') => {
  const finishLogOut = (url) => {
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.accessTokenValidity);
    localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.guestToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    localstorageService().removeItem('redux');
    localstorageService().removeItem(localStorageKeys.gtmContainerId);
    localstorageService().removeItem(localStorageKeys.unpaidFaresData);
    localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
    localstorageService().removeItem(localStorageKeys.stsAccessToken);
    localstorageService().removeItem(localStorageKeys.isRiskyUser);
    localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
    api.b2cSignOut();
  };

  dispatch(itemsAreLoading(true));

  api
    .logOut({ Token: getRefreshToken() })
    .then(() => {
      // dispatch(itemsAreLoading(false));
      finishLogOut(redirectUrl);
    })
    .catch((e) => {
      console.warn(e);
      // dispatch(itemsAreLoading(false));
      finishLogOut(redirectUrl);
    });
};

export default handleLogOut;
