import React from 'react';

import { MediaImage, MediaImageWrapper } from '../../atoms';

const MediaImageList = ({
  all,
  big,
  small,
  prestoCardAlt = '',
  visaCardAlt = '',
  masterCardAlt = '',
  amexCardAlt = '',
  interacCardAlt = '',
  showInterac = true,
}) => {
  const mediaImageProps = { big, small };

  return (
    <MediaImageWrapper small={small}>
      {all ? (
        <MediaImage prestoCard {...{ ...mediaImageProps, cardImgAlt: prestoCardAlt }} />
      ) : null}
      <MediaImage visaCard {...{ ...mediaImageProps, cardImgAlt: visaCardAlt }} />
      <MediaImage masterCard {...{ ...mediaImageProps, cardImgAlt: masterCardAlt }} />
      <MediaImage amexCard {...{ ...mediaImageProps, cardImgAlt: amexCardAlt }} />
      {showInterac ? (
        <MediaImage interacCard {...{ ...mediaImageProps, cardImgAlt: interacCardAlt }} />
      ) : null}
    </MediaImageWrapper>
  );
};

export default MediaImageList;
