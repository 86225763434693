import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getQueryParams, redirectToUrl } from '../../services/utils';
import { setWeakAccount } from '../../redux/user/actions';
import routes from '../../constants/routes';
import Password0 from './Password0';
import Password1 from './Password1';
import Questions0 from './Questions0';
import Questions1 from './Questions1';
import './securityAlerts.scss';

const stepsSecurityAlertsMap = [Password0, Password1, Questions0, Questions1];

const SecurityAlerts = ({ t, fields }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [state, setState] = useState({});
  const toNextStep = () => setStep(step + 1);
  const Stepper = stepsSecurityAlertsMap[step];
  const queryParams = getQueryParams(useLocation().search);
  const returnUrl = queryParams.returnUrl;

  function redirectAfterLogin() {
    const redirectUrl = returnUrl ? returnUrl : routes.dashboard;
    dispatch(setWeakAccount(false));
    redirectToUrl(redirectUrl);
  }

  useEffect(() => {
    if (userInfo.isWeakQaAVisible) {
      setStep(2);
    }
  }, []);

  return (
    <Stepper
      setState={setState}
      state={state}
      t={t}
      fields={fields}
      toNextStep={toNextStep}
      userInfo={userInfo}
      redirectAfterLogin={redirectAfterLogin}
    />
  );
};
export default withTranslation()(SecurityAlerts);
