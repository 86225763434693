import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { SubHeader } from '../../atoms';
import { Header } from '../../molecules';

const Faqs = (props) => (
  <>
    <Header>
      <Text field={props.fields.heading} />
    </Header>
    <SubHeader>
      <Text field={props.fields.description} />
    </SubHeader>
  </>
);

export default Faqs;
