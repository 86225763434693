export default {
  pendingFare: 'pendingFare',
  unpaidFare: 'unpaidFare',
  inspectionFare: 'inspectionFare',
  cancelled: 'cancelled',
  verifyCard: 'verifyCard',
  debtClearedByCustomer: 'debtClearedByCustomer',
  settledUnpaidFares: 'settledUnpaidFares',
  negativeSalesOrder: 'negativeSalesOrder',
  fareAdjustmentExistsInSalesOrder: 'fareAdjustmentExistsInSalesOrder',
  automaticTapOffExistsInSalesOrder: 'automaticTapOffExistsInSalesOrder',
  allOtherScenarios: 'allOtherScenarios',
};
