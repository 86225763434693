import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import './notification.scss';

const Notification = ({ title, text }) => (
  <div className="notification">
    <div className="notification-block">
      {title ? (
        <div className="notification-title">
          <Text field={{ value: title }} />
        </div>
      ) : null}
      <div className="notification-text">
        <Text field={text} />
      </div>
    </div>
  </div>
);

export default Notification;
