import React from 'react';

import './accordianSection.scss';
import { Text, Placeholder, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const AccordianSection = (props) => {
  return (
    <div className="accordian-component-main">
      <div className="accordiancomponent">
        <div className={`accordionSection ${props.fields.SubLinkId?.value}`}>
          {props.fields.Backtopagelink.value.href && (
            <Link className="section-link" field={props.fields.Backtopagelink} />
          )}
          {props.fields.Title.value && (
            <div className="accordionSection-heading">
              <Text field={props.fields.Title} />
            </div>
          )}
          <div
            id={props.fields.SubLinkId?.value}
            className={`accordian-content ${props.fields.SubTitle.value !== '' ? 'support' : ''}`}
          >
            <div>
              {props.fields.SubTitle.value && (
                <h2 className="accordionSection-heading-subtitle">
                  <span>
                    <Text field={props.fields.SubTitle} />
                  </span>
                </h2>
              )}
              <div>
                <p className="accordionSection-SubTitleDesc">
                  <Text field={props.fields.SubTitleDesc} />
                </p>
              </div>
            </div>
            <div className="accordionSection-image">
              <Image field={props.fields.AccordianIcon} loading="lazy"/>
            </div>
          </div>
        </div>
        <Placeholder name="presto-accordionitem-section" rendering={props.rendering} />
      </div>
      {props.fields.ShowViewAllLink.value && (
        <Link className="main-link" field={props.fields.ViewAllLink} />
      )}
    </div>
  );
};

export default withTranslation()(AccordianSection);
