import React, { useState, useEffect, useRef } from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
//import './imageWithContent.scss';
import './imageWithContent.scss';

const ImageWithContent = (props) => {
  const [toggle, setToggle] = useState(true);
  const focusDiv = useRef();
  //console.log(props, "ImageContent");
  //console.log(props.imagecontent.Id, "Id");

  const prestoPerkLinkHandler = (Id) => {
    setToggle(!toggle);
    const element = document.getElementById(Id);
    if (element !== null) {
      const links = element.querySelectorAll('.attractions-link');
      for (let i = 0; i < links.length; i++) {
        if (!toggle) {
          links[i].setAttribute('tabindex', '-1');
        } else {
          links[i].setAttribute('tabindex', '0');
        }
      }
    }
  };

  useEffect(() => {
    const element = document.getElementById(props.imagecontent.Id.value);
    if (element !== null) {
      const links = element.querySelectorAll('.attractions-link');
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute('tabindex', '-1');
      }
    }
  }, []);

  useEffect(() => {
    if (!toggle) {
      focusDiv.current.focus();
    }
  }, [toggle]);

  

  return (
    <div classname="column">
        <div className="PrestoPerkImage">
            <div className={props.imagecontent.CssClass.value ? props.imagecontent.CssClass.value : ''}>
                <div className="attractions_title">
                <h3>
                    <Text field={props.imagecontent.Title} />
                </h3>
                <div>
                    <div className="save-label">
                    <Text field={props.imagecontent.SaveLabel} />
                    </div>
                    <p className="status-label">
                    <Text field={props.imagecontent.Status} />
                    </p>
                </div>
                </div>
                <Image media={props.imagecontent.Image.jss.value} loading="lazy"/>
                <div
                tabIndex="-1"
                ref={focusDiv}
                id={props.imagecontent.Id.value ? props.imagecontent.Id.value : ''}
                className={`${toggle ? 'description-toggle' : 'description-notoggle'} no-focus-outline`}
                aria-hidden={toggle ? true : false}
                >
                <RichText field={props.imagecontent.Description.jss} />
                </div>
                <div className="view_buttons">
                {toggle ? (
                    <button onClick={() => prestoPerkLinkHandler(props.imagecontent.Id.value)} className="viewmore_button">
                    <Text field={props.imagecontent.ViewMoreLabel} />
                    </button>
                ) : (
                    <button onClick={() => prestoPerkLinkHandler(props.imagecontent.Id.value)} className="viewless_button">
                    <Text field={props.imagecontent.ViewLessLabel} />
                    </button>
                )}
                </div>
            </div>
        </div>
    </div>
  );
};

export default ImageWithContent;
