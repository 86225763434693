import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '../../atoms';
import './targetedMessage.scss';
import { useSelector } from 'react-redux';

const TargetedMessage = (props) => {
  const authenticated = useSelector((state) => state.user.authenticated);
  const anonymous = useSelector((state) => state.user.anonymous);
  return authenticated && !anonymous ? null : (
    <div className="targeted-main">
      <div className="top-container">
        <div className="title-message">
          <Text field={props.fields.Title} />
        </div>
        <div className="description-message">
          <p>
            <Text field={props.fields.Description} />
          </p>
        </div>
      </div>
      <div className="bottom-container">
        {props?.fields?.FirstLink?.value?.href && (
          <Button
            onClick={() => {
              window.location.href = props?.fields?.FirstLink?.value?.href;
            }}
          >
            {props?.fields?.FirstLink?.value.text}
          </Button>
        )}
        {props?.fields?.SecondLink?.value?.href && (
          <Button
            onClick={() => {
              window.location.href = props?.fields?.SecondLink?.value?.href;
            }}
          >
            {props?.fields?.SecondLink?.value.text}
          </Button>
        )}
        {props?.fields?.ThirdLink?.value?.href && (
          <Button
            onClick={() => {
              window.location.href = props?.fields?.ThirdLink?.value?.href;
            }}
          >
            {props?.fields?.ThirdLink?.value.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TargetedMessage;
