import React, { useState, useEffect } from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './TwocolumnLayout.scss';

const TwocolumnLayout = (props) => {
  const [twoColumnbg, setTwoColumnbg] = useState(false);
  useEffect(() => {
    if (props?.fields?.WhiteBackGround.value) {
      setTwoColumnbg(true);
    }
  }, [props]);

  return (
    <div
      className={
        (`${twoColumnbg ? 'twocolumn-wrapper' : ''}`,
        `${props?.fields?.WhiteBackGround.value ? 'get-presto-ready-white' : ''}`)
      }
    >
      <div
        className={`row ${
          props?.fields?.Cssclass && props.fields.Cssclass.value ? props.fields.Cssclass.value : ''
        }`}
        id={props?.fields?.Id && props?.fields.Id.value ? props?.fields.Id.value : ''}
      >
        <Placeholder name="presto-twocolumnlayout" rendering={props.rendering} />
        <div className="column">
          <Placeholder name="firstColumn" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="second-column" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};
export default TwocolumnLayout;
