import React, { useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Header, StepTracker } from '../../molecules';
import CancelAutorenew1 from './CancelAutorenew1';
import CancelAutorenew2 from './CancelAutorenew2';
import { withTranslation } from 'react-i18next';

const stepsMap = [CancelAutorenew1, CancelAutorenew2];

const CancelAutorenew = ({
  fields,
  t,
  state,
  setState,
  subscriptionInstance,
  backToTransitPassesButtonLink,
  TTCrendering,
}) => {
  const userInfo = useSelector((s) => s.user);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };

  const Stepper = stepsMap[step];

  if (!subscriptionInstance) {
    return null;
  }

  return (
    <>
      <div className="no-focus-outline">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.step1Title} />,
              textInProgressA11y: fields.step1TitleInProgressA11y.value,
              textCompletedA11y: fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step2Title} />,
              textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
              textCompletedA11y: fields.step2TitleCompletedA11y.value,
            },
          ]}
        />
      </div>
      <Header centered aria-label={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>

      <Stepper
        fields={fields}
        t={t}
        state={state}
        setState={setState}
        userInfo={userInfo}
        subscriptionInstance={subscriptionInstance}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        toNextStep={toNextStep}
        TTCrendering={TTCrendering}
      />
    </>
  );
};

export default withTranslation()(CancelAutorenew);
