import React from 'react';

import { useSelector } from 'react-redux';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { hasAnyCard } from '../../services/utils';

const MyProductsPage = (props) => {
  let content;

  content = hasAnyCard(useSelector((state) => state.user)) ? (
    <Placeholder name="presto-my-products-redirection" rendering={props.rendering} />
  ) : (
    <Placeholder
      name="presto-my-products-overview"
      rendering={props.rendering}
      linkableHeadingForNoMedia={false}
    />
  );

  return <div className="myProducts">{content}</div>;
};

export default MyProductsPage;
