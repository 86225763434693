import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';

import './thumbnailCollection.scss';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const ThumbnailCollection = (props) => {
  const thumbDivider = props?.params.pageCss;
  const [isLoaded, setIsLoaded] = useState(false);
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [props]);
  if (!isLoaded) return null;
  return (
    <div className="thumbnail-container">
      <div className="thumbnail-collection">
        <h2
          className={
            props?.params?.TitleAlign === 'left'
              ? 'thumbnail-collection-title-left-align'
              : 'thumbnail-collection-title-center-align'
          }
        >
          {props?.fields?.Title?.value}
        </h2>
        {props?.fields?.SubTitle ? (
          <p>
            <Text field={props?.fields?.SubTitle} />
          </p>
        ) : null}
        <ul
          className={`transit-agencies-icons ${thumbDivider ? 'thumbnail-collection-divider' : ''}`}
        >
          <Placeholder name="presto-thumbnail-section" rendering={props?.rendering} />
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(ThumbnailCollection);
