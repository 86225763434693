export const monerisErrorCodes = ['TPSM-AL-01', 'TPSM-AR-01', 'TPSM-SPM-01', 'TPSM-CO-01'];
export const daxErrorCodes = ['AFMS-CRT-TE-0001', 'AFMS-CRT-TE-0002', 'AFMS-CRT-TE-0003'];

export default function handleThirdPartyError(error, callback) {
  if (!error) {
    return;
  }

  const hasMonerisError = monerisErrorCodes.indexOf(error) > -1;
  const hasDaxError = daxErrorCodes.indexOf(error) > -1;

  if (hasDaxError || hasMonerisError) {
    callback(error);
  }
}
