import React, { useRef } from 'react';

import { withTranslation } from 'react-i18next';
import { Text, Placeholder, Image, Link } from '@sitecore-jss/sitecore-jss-react';

import './accordianWithImage.scss';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

const AccordianItemwithImage = ({ fields, rendering, t }) => {
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);
  const onClickSelect = () => openDropdown(!isArrowIconClicked);
  const iconText = isArrowIconClicked ? t('AccordionOpenText') : t('AccordionCloseText');

  return (
    <div className="accordion_demo_Section">
      <div aria-expanded={isArrowIconClicked} className="accordionHeading">
        <p className="accordionContainer__imageHolder">
          <Image field={fields.AccordionIcon} loading="lazy"/>
        </p>
        <p className="accordionContainer__paragraph">
          <Text field={fields.Title} />
        </p>
        <p className="accordionContainer__accordionButton" onClick={onClickSelect}>
          <a href="javascript:void(0)">{iconText}</a>
        </p>
      </div>

      {isArrowIconClicked && (
        <div className="accordionContent">
          <Placeholder name="presto-accordionitem-contentsection" rendering={rendering} />

          <div className="accordianLink">
            <Link field={fields.ViewAllLink} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AccordianItemwithImage);
