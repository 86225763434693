import initialState from '../initialState';
import types from './types';

export function businessRules(state = initialState, action = '') {
  if (action.type === types.SET_BUSINESS_RULES) {
    const data = action.payload;
    return {
      ...state,
      rules: data,
    };
  }
  return state;
}
