import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import './featuresBlock.scss';

const FeaturesBlock = (props) => (
  <>
    <RichText field={props.fields.signInLink} />
    <div className="features-list-info">
      <RichText field={props.fields.heading} />
      <div className="features-list">
        <div className="list">
          <RichText field={props.fields.leftSideList} />
        </div>
        <div className="list">
          <RichText field={props.fields.rightSideList} />
        </div>
      </div>
    </div>
  </>
);

export default FeaturesBlock;
