import React from 'react';
import './iconButton.scss';

const IconButton = ({
  className,
  ariaLabel,
  ariaExpanded,
  onClick,
  icon,
  children,
  iconAlt = '',
  tabIndex = '',
}) => (
  <button
    type="button"
    onClick={onClick}
    className={`${className ? className : ''} icon-button`}
    aria-label={ariaLabel}
    tabIndex={tabIndex}
    aria-expanded={ariaExpanded}
  >
    <img alt={iconAlt} src={icon} /> {children}
  </button>
);

export default IconButton;
