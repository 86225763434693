import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';

import {
  formatDate,
  formatDecimalWithCurrency,
  formatUtcTimeFromDate,
  formatUtcTimeFromDate2,
  formattoLocalDate,
  redirectToUrl,
} from '../../services/utils';
import {
  removeAllFromShoppingCart,
  setUserSignedfromGuestFlow,
  setGuestShoppingCartPayload,
} from '../../redux/user/actions';
import { ButtonBlock, Button, PrintButton } from '../../atoms';
import { CheckoutGrid, Note, Success, ThirdPartySystemErrorWarning } from '../../molecules';
import './checkout.scss';
import routes from '../../constants/routes';
import names from '../../constants/shoppingCart';
import { NEWMEDIA_VISIBLE_ID } from '../../constants/product.constants';

const CheckoutStep2 = ({
  fields,
  state,
  thirdPartySystemErrorType,
  goBackUrl,
  isOrderCard,
  isSuccess,
}) => {
  const dispatch = useDispatch();
  const shippingDetails = useSelector((redux) => redux.user.currentShippingDetails);
  const orderDetails = useSelector((redux) => redux.user.newCardDetails);
  const newShippingDetails = useSelector((redux) => redux.user.newShippingDetails);
  const addressType = useSelector((reduxState) => reduxState.user.addressType);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const dateAndTime = state?.TransactionDateTime?.split(' ');
  const formattedDate = dateAndTime && dateAndTime.length > 0 ? formatDate(dateAndTime[0]) : null;
  const formattedTime = formatUtcTimeFromDate(dateAndTime);
  const formattedDateTime = state?.TransactionDateTime
    ? formatUtcTimeFromDate2(state?.TransactionDateTime)
    : null;
  let items = [];
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(removeAllFromShoppingCart());
    dispatch(setUserSignedfromGuestFlow(false));
    dispatch(setGuestShoppingCartPayload({}));
    if (orderDetails && orderDetails.OrderLines && orderDetails.OrderLines.length > 0) {
      let temp = orderDetails.OrderLines.map((data) => {
        let nickName;
        let cardLang;
        if (data?.SalesProductInfo?.ProdInstAttCollection?.NickName) {
          nickName = data?.SalesProductInfo?.ProdInstAttCollection?.NickName;
        } else {
          nickName = state?.nickNameValue;
        }
        if (data?.SalesProductInfo?.ProdInstAttCollection?.Language) {
          cardLang =
            data?.SalesProductInfo?.ProdInstAttCollection?.Language === 'en'
              ? fields?.EnglishLanguageText?.value
              : fields?.FrenchLanguageText?.value;
        } else {
          cardLang = state?.cardLanguage;
        }
        return {
          id: data?.Product?.ProductId,
          name: data?.Product?.ProductName?.replace(names.addMoney, names.funds),
          nickName: null,
          orderLineId: data?.OrderLineId,
          productFamily: data?.Product?.ProductFamily,
          quantity: data?.Quantity,
          subTotal: data?.SubTotal,
          validityEndDate: data?.Product?.ValidityEndDate,
          validityStartDate: data?.Product?.ValidityStartDate,
          visibleId: 'NEWMEDIA',
          serviceProvider: data?.Product?.ProductServiceProvider?.Id,
          nickNameValue: nickName,
          cardLanguage: cardLang,
        };
      });
      items = temp;
    } else {
      items = state?.items.map((el) => ({
        ...el,
        nickNameValue: state.nickNameValue,
        cardLanguage: state.cardLanguage,
      }));
    }
  }, []);

  const orderDate =
    !!state.date &&
    new Date(new Date('0001').getTime() + state.date / 10000).toLocaleString('en-US');

  const newDate = new Date(new Date('0001').getTime() + state.date / 10000);
  //const formattedDate = formatDate(newDate);
  //const formattedTime = formatUtcTimeFromDate(newDate);
  const shipping = {
    value: 'Shipping Address',
  };

  const hasNewCard = state?.items?.some((el) => el.visibleId === NEWMEDIA_VISIBLE_ID);
  return (
    <>
      {thirdPartySystemErrorType ? (
        <ThirdPartySystemErrorWarning
          {...{ fields, errorCode: thirdPartySystemErrorType, referenceNumber: state.reference }}
        />
      ) : (
        <>
          <div className="header-subtitle">
            {/*!state.savedPaymentMethodUsed &&*/ (state.AFMSSalesId || state.orderid) && (
              <>
                <Text field={fields.confirmationNumberLabel} /> {state.AFMSSalesId ? state.AFMSSalesId : state.orderid}
              </>
            )}
          </div>
          <Success
            title={
              isOrderCard ? fields?.orderConfirmedDescription : fields?.AddFundsConfirmedDescription
            }
            contextText={isOrderCard ? fields?.ConfirmationSubNote : ''}
            Content={() => (
              <>
                {hasNewCard ? (
                  <CheckoutGrid
                    fields={fields}
                    items={items}
                    success
                    isOrderCard={isOrderCard}
                    state={state}
                    lastStep
                  />
                ) : (
                  state.fareMedias &&
                  state.fareMedias.map((fareMedia) => (
                    <CheckoutGrid
                      fields={fields}
                      items={state.items}
                      fareMedia={fareMedia}
                      success
                      isOrderCard={isOrderCard}
                      isSuccess={isSuccess}
                      state={state}
                    />
                  ))
                )}
                <div className="checkout-total successModifier">
                  <Text field={fields.orderTotalLabel} /> {formatDecimalWithCurrency(state.total)}
                </div>
              </>
            )}
          />

          {!state.savedPaymentMethodUsed && (
            <div className="checkout-transactionInfoGrid">
              {isOrderCard && (shippingDetails || newShippingDetails) && (
                <div className="checkout-shippingDetails">
                  <div>
                    <Text field={shipping} />
                  </div>
                  {addressType === 'current' ? (
                    <div>
                      <p>
                        {shippingDetails?.Line1} {shippingDetails?.Line2}
                      </p>
                      <p>
                        {shippingDetails?.City}
                        {shippingDetails?.City && shippingDetails?.Area?.Code ? `, ` : ''}
                        {shippingDetails?.Area?.Code}
                      </p>
                      <p>
                        {shippingDetails?.Country?.Id}
                        {shippingDetails?.Country?.Id && shippingDetails?.PostalCode ? `, ` : ''}
                        {shippingDetails?.PostalCode}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {newShippingDetails?.Line1} {newShippingDetails?.Line2}
                      </p>
                      <p>
                        {newShippingDetails?.City}
                        {newShippingDetails?.City && newShippingDetails?.AreaCode ? `, ` : ''}
                        {newShippingDetails?.AreaCode}
                      </p>
                      <p>
                        {newShippingDetails?.CountryId}
                        {newShippingDetails?.CountryId && newShippingDetails?.PostalCode
                          ? `, `
                          : ''}
                        {newShippingDetails?.PostalCode}
                      </p>
                    </div>
                  )}
                </div>
              )}
              {/* {guestUserInfo?.isGuestLogin && (
                <>
                  <div className="checkout-shippingDetails">
                    <div>
                      <Text field={shipping} />
                    </div>
                    <div>
                      <p>
                        {newShippingDetails?.Line1} {newShippingDetails?.Line2}
                      </p>
                      <p>
                        {newShippingDetails?.City}
                        {newShippingDetails?.City && newShippingDetails?.AreaCode?.value
                          ? `, `
                          : ''}
                        {newShippingDetails?.AreaCode?.value}
                      </p>
                      <p>
                        {newShippingDetails?.CountryId}
                        {newShippingDetails?.CountryId && newShippingDetails?.PostalCode
                          ? `, `
                          : ''}
                        {newShippingDetails?.PostalCode}
                      </p>
                    </div>
                  </div>
                </>
              )} */}
              <div className="checkout-transactionInfoGrid-row">
                <div>
                  <Text field={fields.transactionTypeLabel} />
                </div>
                <div>{state.TransactionType || '-'}</div>
              </div>
              <div className="checkout-transactionInfoGrid-row">
                <div>
                  <Text field={fields.responseCodeMessageLabel} />
                </div>
                <div>
                  {state.ResponseCode} - {decodeURIComponent(state.AcceptanceStatus)}
                </div>
              </div>
              <div className="checkout-transactionInfoGrid-row">
                <div>
                  <Text field={fields.isoCodeLabel || '-'} />
                </div>
                <div>{state.ISOCode || '-'}</div>
              </div>
              <div className="checkout-transactionInfoGrid-row">
                <div>
                  <Text field={fields.dateTimeLabel} />
                </div>
                <div>{formattedDateTime}</div>
                {/* <div>{state?.TransactionDateTime ? state.TransactionDateTime : '-'}</div> */}
                {/* <div>{isOrderCard ? formattedDate + ' ' + formattedTime : orderDate || '-'}</div> */}
              </div>
              <div className="checkout-transactionInfoGrid-row">
                <div>
                  <Text field={fields.referenceNumberLabel} />
                </div>
                <div>{state.TransactionNumber || '-'}</div>
              </div>
            </div>
          )}
          <ButtonBlock className="print-hidden">
            <ButtonBlock right>
              {!guestUserInfo?.isGuestLogin ? (
                <Button firstOrder onClick={() => redirectToUrl(goBackUrl)}>
                  <Text field={fields.toMyCardbuttonText} />
                </Button>
              ) : (
                <Button firstOrder onClick={() => redirectToUrl('/')}>
                  <Text field={fields.Closetextguestuser} />
                </Button>
              )}
            </ButtonBlock>
            <PrintButton />
          </ButtonBlock>
          {!isSuccess && isOrderCard && !state?.savedPaymentMethodUsed && (
            <Note>
              <div className="checkout-note">
                <RichText field={fields.CheckoutNote1} />
              </div>
            </Note>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(CheckoutStep2);
