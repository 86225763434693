export default {
  user: {
    isMobile: false,
    loggedInTime: '',
    contactlessLogInTime: '',
    lastVisitedSecuredRoute: '',
    firstNameUpdatePersonalInfo: '',
    checkoutTransitPage: false,
    selectedCardData: '',
    autoLoadData: '',
    shoppingCartShippingDetails: '',
    newCardDetails: {},
    orderCardResponse: '',
    hotlistApiSuccess: false,
    paymentApiSuccess: false,
    deactivateCardType: '',
    shoppingCartId: '',
    addressType: '',
    newShippingDetails: '',
    currentShippingDetails: '',
    authenticated: false,
    anonymous: false,
    isLoading: false,
    shoppingCartQuantity: 0,
    isScreenHeaderVisible: true,
    isWeakPasswordAndQaAVisible: '',
    isWeakPasswordVisible: '',
    isWeakQaAVisible: '',
    isWeakAccount: false,
    userName: '',
    loginEmail: '',
    email: '',
    phoneNumber: '',
    emailVerificationStatus: null,
    showCaslPopUp: false,
    emailError: null,
    sessionInstanceId: '',
    userSignedFromGuestFlow: false,
    userSignedFromCreateAccount: false,
    guestsiginPopupfromOrderCard: false,
    isCardRemoved: false,
    mdpPausedProductId: '',
    isAccountDeleted:false,
    guestShoppingCartToken: {},
    goBackURL: '',
    contactlessMedias: [{ mediaId: '', nickName: '', type: '', language: '' }],
    fareMedias: [
      {
        mediaId: '',
        visibleId: '',
        nickName: '',
        cardStateCode: null,
        currentBalance: null,
        tripDetails: {},
        type: '',
        language: '',
        fareType: '',
        specificFare: '',
        expiryDateValue: '',
        productConcessionExpiryDate: '',
        isPrimary: false,
        passes: [],
      },
    ],
    selectedCard: { mediaId: '', visibleId: '', nickName: '', type: '', status: '' },
    redirectAfterCardSelected: {
      url: '',
      includeLanguage: true,
      isInternalLink: true,
      redirected: false,
    },
    shoppingCart: {
      addMessage: [],
      isNewItem: false,
      items: [],
    },
    guestShoppingCartPayload: {},
    notifications: [],
    pendingProducts: {
      list: [],
      orders: [],
    },
    guestSignPopupEnabled: {
      enablePopup: false,
    },
    loginAndSecurity: {
      isUpdatePasswordSucceed: null,
      passwordUpdateErrCode: { value: '' },
      FailurePwdChange: { value: '' },
      FailurePwdChangeA11y: { value: '' },
      FailurePwdChangeDescription1: { value: '' },
      FailurePwdChangeDescription: { value: '' },
      WrongPwdDescription: { value: '' },
      SuccessPwdchange: { value: '' },
      SetupEnrollSuccessMsg: { value: '' },
      SetupMaxEntryErrorMsg: { value: '' },
      SetupInfoMsg: { value: '' },
      SetupUpdateDisableSuccessMsg: { value: '' },
    },
    guestuserCheckoutEnabled: {
      continueCheckout: false,
    },
    b2cClaimsResponse: null,
  },
  products: {
    transitPasses: [
      {
        id: '',
        logo: {},
      },
    ],
    manageMyProduct: {
      editCardDetailsEnabled: false,
      activateCardDetailsEnabled: false,
      removeCardEnabled: false,
      addToActivate: false,
      selectedCard: {},
    },
    activateCardfromMyProducts: false,
    manageAutoRenew: { visibility: false, instance: {}, TCrendering: {}, checkoutData: {} },
    redeemVoucher: {
      redeemVoucherStepperBlock: false,
      redeemVoucherBlock: true,
      apiData: {},
      voucherCode: '',
      submitApiData: {},
    },
  },
  settings: {
    configKeys: [{ key: '', setting: '' }],
  },
  businessRules: {
    rules: [],
  },
  contactless: {
    signInModal: false,
    isIframeEnabled: true,
    isContactlessUserSignedIn: false,
    userInfoData: {},
    signOutRedirectUrl: '',
    configKeys: [],
    redirectUrl: '',
    transactionHistory: {
      HasMoreItems: false,
      textMapping: {},
      transactions: [],
    },
    unpaidFares: {
      HasMoreItems: false,
      textMapping: {},
      transactions: [],
    },
    multipleUnpaidFareProps: [],
    retryLimit: 0,
    paymentsToVerify: [],
  },
};
