import React, { useState, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { StepTracker } from '../../molecules';
import CancelAutoload1 from './CancelAutoload1';
import CancelAutoload2 from './CancelAutoload2';

import '../Autoload/autoload.scss';
import '../Autoload/autoloadPayment.scss';
import '../Autoload/autoloadSuccess.scss';

const stepsCancelAutoloadMap = [CancelAutoload1, CancelAutoload2];

const CancelAutoload = ({ t, fields, product, backToMyCardButtonLink }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const [state, setState] = useState({});
  const toFirstStep = () => window.location.reload();

  const Stepper = stepsCancelAutoloadMap[step];

  return (
    <>
      <div tabIndex="-1" ref={focusDiv} className="no-focus-outline">
        <span className="stepper-sr-only">{t('StepperChangeSRText')}</span>
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.step1Title} />,
              textInProgressA11y: fields.step1TitleInProgressA11y.value,
              textCompletedA11y: fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step2Title} />,
              textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
              textCompletedA11y: fields.step2TitleCompletedA11y.value,
            },
          ]}
        />
      </div>

      <Stepper
        t={t}
        fields={fields}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        userInfo={userInfo}
        product={product}
        backToMyCardButtonLink={backToMyCardButtonLink}
      />
    </>
  );
};
export default withTranslation()(CancelAutoload);
