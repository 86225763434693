import React from 'react';

import { ReactComponent as Tick } from '../../assets/images/Tick.svg';

const DONE = 'done';
const ACTIVE = 'active';

export default function Step({
  index,
  text,
  activeIndex,
  last,
  total,
  textA11y,
  labelA11y,
  StepA11y,
  OfA11y,
  CompleteA11y,
  NotCompleteA11y,
  CurrentStepA11y,
  textNotCompletedA11y,
  textInProgressA11y,
  textCompletedA11y,
  hasError = false,
  inProgress = false,
}) {
  let cssClass = index < activeIndex ? DONE : '';

  let a11yLabel = textA11y;
  let currentStep = index + 1;
  let cuurentStepText = currentStep + ' ' + OfA11y + ' ';

  if (!cssClass) {
    cssClass = index === activeIndex ? ACTIVE : '';
  }

  if (!hasError && last && index === activeIndex) {
    cssClass = DONE;
  }
  if (inProgress && last && index === activeIndex) {
    cssClass = ACTIVE;
  }

  if (labelA11y) {
    if (index < activeIndex) {
      a11yLabel = StepA11y + ' ' + cuurentStepText + total + ' ' + labelA11y + ' ' + CompleteA11y;
    }

    if (index === activeIndex) {
      if (last && !hasError) {
        a11yLabel = StepA11y + ' ' + cuurentStepText + total + ' ' + labelA11y + ' ' + CompleteA11y;
      } else if (last && hasError) {
        a11yLabel =
          StepA11y + ' ' + cuurentStepText + total + ' ' + labelA11y + ' ' + NotCompleteA11y;
      } else {
        a11yLabel =
          StepA11y + ' ' + cuurentStepText + total + ' ' + labelA11y + ' ' + CurrentStepA11y;
      }
    }

    if (index > activeIndex) {
      a11yLabel =
        StepA11y + ' ' + cuurentStepText + total + ' ' + labelA11y + ' ' + NotCompleteA11y;
    }
  } else if (!a11yLabel) {
    if (index < activeIndex) {
      a11yLabel = textCompletedA11y;
    }

    if (index === activeIndex) {
      if (last && !hasError) {
        a11yLabel = textCompletedA11y;
      } else if (last && hasError) {
        a11yLabel = textNotCompletedA11y;
      } else {
        a11yLabel = textInProgressA11y;
      }
    }

    if (index > activeIndex) {
      a11yLabel = textNotCompletedA11y;
    }
  }

  return (
    <li className={cssClass}>
      <div className="step-number">{cssClass === DONE ? <Tick /> : index + 1}</div>
      <span role="tab" aria-label={a11yLabel} tabIndex="0">
        {text}
      </span>
    </li>
  );
}
