import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Error, Success } from '../../molecules';
import api from '../../services/api';
import { getQueryParams, localstorageService } from '../../services/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import InComplete from '../../molecules/InComplete';
import './style.scss';
import localStorageKeys from '../../constants/localStorageKeys';
import { setB2cClaimsResponse, updateUserInfo } from '../../redux/user/actions';
import handleServerError from '../../services/handleServerError';
import b2cQueryParams from '../../constants/b2cQueryParams';
import useMediaWidth from '../../hooks/useMediaWidth';

export default function LoginAndSecurityMessage(props) {
  const divid = props.fields && props.fields.ID?.value ? props.fields.ID?.value : 'rlc-card';
  const idname = divid === 'rlc' || divid === 'rlc-card' ? 'rlc-card' : props.fields.ID?.value;
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const [error, setError] = useState('');
  const [showMessage, setShowMessage] = useState('');
  const isMobile = useMediaWidth();
  const loginAndSecurityProps = useSelector((reduxState) => reduxState.user.loginAndSecurity);

  const showPasswordChangeMessage = () => {
    return showMessage === b2cQueryParams.Success
      ? showSuccessMessage(
        'password-success-block',
        loginAndSecurityProps?.SuccessPwdchange,
        loginAndSecurityProps?.SuccessPwdchange
      )
      : loginAndSecurityProps?.passwordUpdateErrCode === 'AFMS-DAX-FE-0001'
        ? showErrorMessage(
          'password-error-block',
          loginAndSecurityProps?.FailurePwdChange,
          loginAndSecurityProps?.FailurePwdChangeA11y,
          loginAndSecurityProps?.FailurePwdChangeDescription1
        )
        : loginAndSecurityProps?.passwordUpdateErrCode === 'AFMS-DAX-FE-0008'
          ? showErrorMessage(
            'password-error-block',
            loginAndSecurityProps?.FailurePwdChange,
            loginAndSecurityProps?.FailurePwdChangeA11y,
            loginAndSecurityProps?.FailurePwdChangeDescription
          )
          : showErrorMessage(
            'password-error-block',
            loginAndSecurityProps?.FailurePwdChange,
            loginAndSecurityProps?.FailurePwdChangeA11y,
            loginAndSecurityProps?.WrongPwdDescription
          );
  };
  const showSuccessMessage = (className = '', title = '') => {
    return (
      <div className={className}>
        <Success title={title} />
      </div>
    );
  };
  const showErrorMessage = (
    className = '',
    title = { value: '' },
    titleA11y = { value: '' },
    text = ''
  ) => {
    return (
      <div className={className}>
        <Error title={title.value} titleA11y={titleA11y.value} text={text} />
      </div>
    );
  };
  const showInCompleteMessage = (className = '', title = '', text = '') => {
    return (
      <div className={className}>
        <InComplete title={title} text={text} />
      </div>
    );
  };
  const handleShowMessage = () => {
    if (showMessage === b2cQueryParams.Success) {
      return showPasswordChangeMessage();
    } else if (showMessage === b2cQueryParams.Enroll) {
      return showSuccessMessage(
        'mfa-success-block',
        loginAndSecurityProps?.SetupEnrollSuccessMsg,
        loginAndSecurityProps?.SetupEnrollSuccessMsg
      );
    } else if (showMessage === b2cQueryParams.Update) {
      return showSuccessMessage(
        'mfa-success-block',
        loginAndSecurityProps?.SetupUpdateSuccessMsg,
        loginAndSecurityProps?.SetupUpdateSuccessMsg
      );
    } else if (showMessage === b2cQueryParams.Disable) {
      return showSuccessMessage(
        'mfa-success-block',
        loginAndSecurityProps?.SetupDisableSuccessMsg,
        loginAndSecurityProps?.SetupDisableSuccessMsg
      );
    } else if (showMessage === b2cQueryParams.EnrollCancel) {
      return showInCompleteMessage(
        'mfa-incomplete-block',
        loginAndSecurityProps?.SetupInfoMsg,
        loginAndSecurityProps?.SetupInfoMsg
      );
    } else if (showMessage === b2cQueryParams.UpdateCancel) {
      return showInCompleteMessage(
        'mfa-incomplete-block',
        loginAndSecurityProps?.SetupInfoUpdateMsg,
        loginAndSecurityProps?.SetupInfoUpdateMsg
      );
    } else if (showMessage === b2cQueryParams.DisableCancel) {
      return showInCompleteMessage(
        'mfa-incomplete-block',
        loginAndSecurityProps?.SetupInfoDisableMsg,
        loginAndSecurityProps?.SetupInfoDisableMsg
      );
    } else if (showMessage === b2cQueryParams.IsMaxTryExceedsOnEnroll) {
      return showErrorMessage(
        'mfa-error-block',
        loginAndSecurityProps?.SetupMaxEntryOnEnrollErrorMsg,
        loginAndSecurityProps?.SetupMaxEntryOnEnrollErrorMsg
      );
    } else if (showMessage === b2cQueryParams.IsMaxTryExceedsOnUpdate) {
      return showErrorMessage(
        'mfa-error-block',
        loginAndSecurityProps?.SetupMaxEntryOnUpdateErrorMsg,
        loginAndSecurityProps?.SetupMaxEntryOnUpdateErrorMsg
      );
    } else if (showMessage === b2cQueryParams.IsMaxTryExceedsOnDisable) {
      return showErrorMessage(
        'mfa-error-block',
        loginAndSecurityProps?.SetupMaxEntryOnDisableErrorMsg,
        loginAndSecurityProps?.SetupMaxEntryOnDisableErrorMsg
      );
    }
  };

  useEffect(() => {

    if (showMessage !== '' && !isMobile) {

      const onPageLoad = () => {

        const intervalId = setInterval(() => {

          const classNames = [

            'password-error-block',

            'password-success-block',

            'mfa-success-block',

            'mfa-error-block',

            'mfa-incomplete-block'

          ];

          const roleRemoveElement = classNames.map(className => document.querySelector(`.${className} div div div`))

            .find(element => element !== null);

          const passwordWrapper = classNames.map(className => document.querySelector(`.${className} div`))

            .find(element => element !== null);

          if (passwordWrapper) {

            passwordWrapper.setAttribute('aria-hidden', 'true');

            passwordWrapper.setAttribute('tabindex', '0');

            const clickEvent = new MouseEvent('click', {

              bubbles: true,

              cancelable: true,

              view: window,

            });

            passwordWrapper.dispatchEvent(clickEvent);

            passwordWrapper.focus();

            passwordWrapper.removeAttribute('aria-hidden');

            if (roleRemoveElement) {

              roleRemoveElement.removeAttribute('role');

            }

            clearInterval(intervalId);

          }

        }, 100);

        return () => clearInterval(intervalId);

      };

      if (document.readyState === 'complete') {

        onPageLoad();

      } else {

        window.addEventListener('load', onPageLoad);

      }

      return () => {

        window.removeEventListener('load', onPageLoad);

      };

    }

  }, [showMessage]);

  useEffect(() => {
    if (
      showMessage === b2cQueryParams.Enroll ||
      showMessage === b2cQueryParams.Disable ||
      showMessage === b2cQueryParams.Update
    ) {
      api
        .getClaims()
        .then((res) => {
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate:
                  res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin:
                  String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, [showMessage]);
  useEffect(() => {
    const queryParams1 = new URLSearchParams(search);
    if (showMessage === '' && queryParams1.has('Update')) {
      setShowMessage('Update');
      queryParams1.delete('Update');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('Disable')) {
      setShowMessage('Disable');
      queryParams1.delete('Disable');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('Enroll')) {
      setShowMessage('Enroll');
      queryParams1.delete('Enroll');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('Success')) {
      setShowMessage('Success');
      queryParams1.delete('Success');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('EnrollCancel')) {
      setShowMessage('EnrollCancel');
      queryParams1.delete('EnrollCancel');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('UpdateCancel')) {
      setShowMessage('UpdateCancel');
      queryParams1.delete('UpdateCancel');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('DisableCancel')) {
      setShowMessage('DisableCancel');
      queryParams1.delete('DisableCancel');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('IsMaxTryExceedsOnEnroll')) {
      setShowMessage('IsMaxTryExceedsOnEnroll');
      queryParams1.delete('IsMaxTryExceedsOnEnroll');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('IsMaxTryExceedsOnUpdate')) {
      setShowMessage('IsMaxTryExceedsOnUpdate');
      queryParams1.delete('IsMaxTryExceedsOnUpdate');
      history.replace({
        search: queryParams1.toString(),
      });
    } else if (showMessage === '' && queryParams1.has('IsMaxTryExceedsOnDisable')) {
      setShowMessage('IsMaxTryExceedsOnDisable');
      queryParams1.delete('IsMaxTryExceedsOnDisable');
      history.replace({
        search: queryParams1.toString(),
      });
    }
  }, []);

  return (
    <div>
      <div className="login-security-heading">
        <span>
          <Text
            tag="h1"
            className={`headline ${props?.fields?.CssClass && props.fields.CssClass?.value
                ? props.fields.CssClass?.value
                : 'bodyText__headline'
              }`}
            field={props?.fields?.Title}
          />
        </span>
      </div>
      <div>{handleShowMessage()}</div>
    </div>
  );
}
