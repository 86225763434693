import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonBlock } from '../../atoms';
import { CardBlock } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import './accountDeletion.scss';

const AccountDeletion = ({ fields }) => {
    const deleteAccountUrl = () => {
        redirectToUrl(fields.RedirectUrl?.value?.href, false);
    };
    return (
        <div className="account-deletion-container">
            <CardBlock title={fields?.Heading?.value} tabIndex="0">
                <RichText field={fields?.Content} />
                <ButtonBlock>
                    <ButtonBlock right>
                        <Button
                            dataTestId="request-btn"
                            buttonTextA11y={fields?.RequestButtonA11y?.value}
                            onClick={() => deleteAccountUrl()}                >
                            <Text field={fields?.RequestButtonText} />
                        </Button>
                    </ButtonBlock>
                </ButtonBlock>
            </CardBlock>
        </div>
    );
};

export default withTranslation()(AccountDeletion);
