import React, { useState, useEffect } from 'react';

import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

import './imageWithWalletInfo.scss';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { is } from '../../services/utils';

const ImageWithWalletInfo = ({ fields }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [fields]);

  useEffect(() => {
    if (typeof window !== 'undefined' && document) {
      let h1 = document.getElementById('pay-with-title1');
      let h2 = document.getElementById('pay-with-title2');
      let h3 = document.getElementById('pay-with-title3');
      if (h1) {
        let width = Math.round(h1.getBoundingClientRect().width);
        let targetEl = document.getElementById('pay-with-desc1');
        if (width && targetEl) {
          targetEl.style.width = `${width}px`;
        }
      }
      if (h2) {
        let width = Math.round(h2.getBoundingClientRect().width);
        let targetEl = document.getElementById('pay-with-desc2');
        if (width && targetEl) {
          targetEl.style.width = `${width}px`;
        }
      }
      if (h3) {
        let width = Math.round(h3.getBoundingClientRect().width);
        let targetEl = document.getElementById('pay-with-desc3');
        if (width && targetEl) {
          targetEl.style.width = `${width}px`;
        }
      }
    }
  });
  if (!isLoaded) return null;

  return (
    <div className="image-wallet-info-main">
      <div className="wallet-info-main">
        <div className="wallet-info-main-content-block">
          <div className="wallet-info-content-block">
            {is(fields?.WalletInfoHeadline1.value) ? (
              <div className="wallet-info-header">
                <h2 className="display-4">
                  <Text field={fields?.WalletInfoHeadline1} />
                </h2>
              </div>
            ) : null}
            {is(fields?.WalletInfoContent1.value) ? (
              <RichText field={fields?.WalletInfoContent1} />
            ) : null}
          </div>
          <div className="wallet-info-content-block">
            {is(fields?.WalletInfoHeadline2.value) ? (
              <div className="wallet-info-header">
                <h2 className="display-4">
                  <Text field={fields?.WalletInfoHeadline2} />
                </h2>
              </div>
            ) : null}
            {is(fields?.WalletInfoContent2.value) ? (
              <RichText field={fields?.WalletInfoContent2} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithWalletInfo;
