import React, { useEffect, useState, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { CardBlock } from '../../molecules';
import CollapsibleNotification from './collapsibleNotification';
import NotificationToggleButton from './notificationToggleButton';
import {
  setNotification,
  dismissNotification,
  updateNotification,
  setB2cClaimsResponse,
} from '../../redux/user/actions';
import api from '../../services/api';
import langConstants from '../../constants/lang';
import localStorageKeys from '../../constants/localStorageKeys';
import handleServerError from '../../services/handleServerError';
import emailVerificationStatus from '../../constants/emailVerificationStatus';
import { formatDate, localstorageService, getLongLanguageCode } from '../../services/utils';

import './notifications.scss';

const notificationTypes = {
  noEmailVerificationNotification: 'noEmailVerificationNotification',
  unverifiedEmailVerificationNotification: 'unverifiedEmailVerificationNotification',
  mfaLoginNotification: 'mfaLoginNotification',
  passwordExpiryNotification: 'passwordExpiryNotification',
  verificationSuccessNotification: 'verificationSuccessNotification',
};

const Notifications = ({ fields }) => {
  const history = useHistory();
  const { search } = useLocation();
  const langCode = getLongLanguageCode();
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const notifications = useSelector((state) => state.user.notifications);
  const b2cClaimsResponse = useSelector((state) => state.user.b2cClaimsResponse);
  const currentEmailVerificationStatus = useSelector((state) => state.user.emailVerificationStatus);
  const [error, setError] = useState('');
  const isMfaEnabled = b2cClaimsResponse?.IsMfAEnabled;
  const isRiskyUser = localstorageService().getItem(localStorageKeys.isRiskyUser) === 'true';
  const isRiskyUserDismissed = localstorageService().getItem(localStorageKeys.isRiskyUserDismissed) === 'true';
  const mfaLastViewedDateEpoch = b2cClaimsResponse?.MFANotificationLastViewedDate;
  const lastPasswordViewedDateEpoch = b2cClaimsResponse?.PasswordNotificationLastViewedDate;
  const isFirstTimeLogin = b2cClaimsResponse?.IsFirstTimeLogin;
  const [expandedNotifications, setExpandedNotifications] = useState([]);
  const [
    verificationSuccessNotificationExists,
    setVerificationSuccessNotificationExists,
  ] = useState(false);
  const fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  const [visibleNotifications, setVisibleNotifications] = useState([]);
  const productConcessionExpiryDate = fareMedias?.filter((fareMedia) => {
    const isSpecificFareIncluded = fields?.ExcludeSpecificFare?.some((field) =>
      fareMedia.specificFare.includes(field.fields?.Value?.value)
    );
    if (fareMedia?.productConcessionExpiryDate !== '0001-01-01T00:00:00+00:00') {
      if (
        ((fareMedia?.fareType === 'Adult' || fareMedia?.fareType === 'Senior') &&
          fareMedia?.specificFare === '') ||
        isSpecificFareIncluded
      ) {
        return false;
      }
      return true;
    }
    return false;
  });
  // const visibleNotifications = useMemo(
  //   () => notifications.filter((notification) => !notification?.isDismissed),
  //   [notifications]
  // );
  const hasAllExpanded = visibleNotifications.length === expandedNotifications.length;
  const dispatch = useDispatch();

  const getIndexOfNotification = (type) => {
    let indexOfVisibleNotification;

    for (let i = 0; i < notifications?.length; i += 1) {
      if (notifications[i]?.type === type) {
        indexOfVisibleNotification = i;
        break;
      }
    }

    return indexOfVisibleNotification;
  };
  const expandFirstVisibleNotification = () => {
    const parsedPasswordDate = langCode === langConstants.longFr ? moment(lastPasswordViewedDate,
      'D MMM YYYY').toDate() : new Date(lastPasswordViewedDate);
    const isPasswordNotificationRequired =
      lastPasswordViewedDate &&
      Date.now() >=
      new Date(parsedPasswordDate).getTime() + passwordNotiEveryXDays * 24 * 60 * 60 * 1000;
    const parsedMFADate = langCode === langConstants.longFr ? moment(mfaLastViewedDate,
      'D MMM YYYY').toDate() : new Date(mfaLastViewedDate);
    if (visibleNotifications.length && expandedNotifications.length === 0) {
      let indexOfVisibleNotification = [];
      for (let i = 0; i < visibleNotifications.length; i += 1) {
        if (!visibleNotifications[i]?.isDismissed) {
          indexOfVisibleNotification.push(visibleNotifications[i].orderIndex);
          break;
        }
      }
      setExpandedNotifications(indexOfVisibleNotification);
    } else if (expandedNotifications.length === 1 && !isPasswordNotificationRequired) {
      let indexOfVisibleNotification = [];
      for (let i = 0; i < visibleNotifications.length; i += 1) {
        if (!visibleNotifications[i]?.isDismissed) {
          indexOfVisibleNotification.push(visibleNotifications[i].orderIndex);
          break;
        }
      }
      setExpandedNotifications(indexOfVisibleNotification);
    }
  };

  const setUnverifiedEmailValidationNotification = () => {
    const notificationAlredyExist = notifications?.some(
      (notification) =>
        notification?.type === notificationTypes.unverifiedEmailVerificationNotification
    );

    if (notificationAlredyExist) {
      if (
        currentEmailVerificationStatus !== emailVerificationStatus.newPending &&
        currentEmailVerificationStatus !== emailVerificationStatus.pending
      ) {
        const index = getIndexOfNotification(
          notificationTypes.unverifiedEmailVerificationNotification
        );

        if (typeof index === 'number') {
          dispatch(dismissNotification(index));
        }
      }
    } else if (
      currentEmailVerificationStatus === emailVerificationStatus.newPending ||
      currentEmailVerificationStatus === emailVerificationStatus.pending
    ) {
      dispatch(
        setNotification({
          type: notificationTypes.unverifiedEmailVerificationNotification,
          titleField: 'verifyEmailNotificationTitle',
          contentField: 'verifyEmailNotificationText',
          ctaField: 'verifyEmailNotificationCta',
          isDismissed: false,
        })
      );
    }
  };

  const setNoEmailValidationNotification = () => {
    const notificationAlredyExist = notifications.some(
      (notification) => notification?.type === notificationTypes.noEmailVerificationNotification
    );

    if (notificationAlredyExist) {
      if (currentEmailVerificationStatus !== emailVerificationStatus.isNull) {
        const index = getIndexOfNotification(notificationTypes.noEmailVerificationNotification);

        if (typeof index === 'number') {
          dispatch(dismissNotification(index));
        }
      }
    } else if (currentEmailVerificationStatus === emailVerificationStatus.isNull) {
      dispatch(
        setNotification({
          type: notificationTypes.noEmailVerificationNotification,
          titleField: 'noEmailNotificationTitle',
          contentField: 'noEmailNotificationText',
          ctaField: 'noEmailNotificationCta',
          isDismissed: false,
        })
      );
    }
  };

  const toggle = (orderIndex) => {
    let isExpanded = expandedNotifications.includes(orderIndex);
    if (isExpanded) {
      setExpandedNotifications(expandedNotifications.filter((el) => el !== orderIndex));
    } else {
      setExpandedNotifications([...expandedNotifications, orderIndex]);
    }
  };

  const toggleAll = () => {
    if (hasAllExpanded) {
      setExpandedNotifications([]);
    } else {
      let indexesOfVisibleNotifications = [];
      notifications.forEach((notification) => {
        if (!notification?.isDismissed) {
          indexesOfVisibleNotifications.push(notification.orderIndex);
        }
      });

      setExpandedNotifications(indexesOfVisibleNotifications);
    }
  };

  useEffect(() => {
    setUnverifiedEmailValidationNotification();
    setNoEmailValidationNotification();
  }, []);

  useEffect(() => {
    setVisibleNotifications(notifications.filter((notification) => !notification?.isDismissed));
  }, [notifications]);

  useEffect(() => {
    expandFirstVisibleNotification();
  }, [visibleNotifications]);

  useEffect(() => {
    fareTypeExpiryNotification();
  }, []);
  const passwordNotiEveryXDays = fields?.PassweordNotificationDays?.value;
  const mfaNotiEveryXDays = fields?.MFANotificationDays?.value;
  const mfaLastViewedDate = mfaLastViewedDateEpoch ? formatDate(mfaLastViewedDateEpoch * 1000) : '';
  const lastPasswordViewedDate = lastPasswordViewedDateEpoch
    ? formatDate(lastPasswordViewedDateEpoch * 1000)
    : '';

  const queryParams = new URLSearchParams(search);
  useEffect(() => {
    if (queryParams.has('isMFASuccess')) {
      setShowSuccessNotification(true);
      queryParams.delete('isMFASuccess');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
  useEffect(() => {
    if (queryParams.has('isUpdateNotification')) {
      api
        .getClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate:
                  res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin:
                  String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
      queryParams.delete('isUpdateNotification');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
  useEffect(() => {
    let verificationSuccessNotification =
      notifications?.find((el) => el.type === notificationTypes.verificationSuccessNotification) ||
      {};
    if (authenticated && isMfaEnabled && isFirstTimeLogin) {
      if (showSuccessNotification) {
        if (
          !Object.keys(verificationSuccessNotification).length &&
          !verificationSuccessNotification.isDismissed
        ) {
          const verificationNotification = {
            type: 'verificationSuccessNotification',
            titleField: 'MFAVerificationSuccessHeading',
            contentField: 'MFAVerificationSuccessContent',
            isDismissed: false,
          };
          dispatch(setNotification(verificationNotification));
        }
      } else {
        let successNotificationIndex = notifications?.findIndex(
          (el) =>
            el.type === notificationTypes.verificationSuccessNotification &&
            !el.isDismissed &&
            !showSuccessNotification
        );
        if (successNotificationIndex >= 0) {
          dispatch(dismissNotification(successNotificationIndex));
          dispatch(
            updateNotification('type', notificationTypes.verificationSuccessNotification, {
              isDismissed: true,
            })
          );
        }
      }
      setVerificationSuccessNotificationExists(Object.keys(verificationSuccessNotification).length);
    }
  }, [isMfaEnabled, isFirstTimeLogin, notifications, showSuccessNotification]);

  useEffect(() => {
    const parsedPasswordDate = langCode === langConstants.longFr ? moment(lastPasswordViewedDate,
      'D MMM YYYY').toDate() : new Date(lastPasswordViewedDate);
    const isPasswordNotificationRequired =
      lastPasswordViewedDate &&
      Date.now() >=
      new Date(parsedPasswordDate).getTime() + passwordNotiEveryXDays * 24 * 60 * 60 * 1000;
    const parsedMFADate = langCode === langConstants.longFr ? moment(mfaLastViewedDate,
      'D MMM YYYY').toDate() : new Date(mfaLastViewedDate);
    const isMfaNotificationRequired =
      ((mfaLastViewedDate &&
        Date.now() >=
        new Date(parsedMFADate).getTime() + mfaNotiEveryXDays * 24 * 60 * 60 * 1000) ||
        (mfaLastViewedDate === null ||
          mfaLastViewedDate === undefined ||
          mfaLastViewedDate === '')) &&
      !isMfaEnabled && !isRiskyUser;
    const isRiskUserNotificationRequired = !isMfaEnabled && isRiskyUser;
    const { mfaNotification, passwordExpiryNotification } = notifications?.reduce(
      (prev, curr) => {
        if (curr.type === notificationTypes.mfaLoginNotification) {
          prev.mfaNotification = curr;
        }
        if (curr.type === notificationTypes.passwordExpiryNotification) {
          prev.passwordExpiryNotification = curr;
        }
        return prev;
      },
      { mfaNotification: {}, passwordExpiryNotification: {} }
    );

    let passwordNotificationIndex = notifications?.findIndex(
      (el) => el.type === notificationTypes.passwordExpiryNotification && el.isDismissed === false
    );

    if (passwordNotificationIndex >= 0 && !isPasswordNotificationRequired) {
      dispatch(dismissNotification(passwordNotificationIndex));
      dispatch(
        updateNotification('type', notificationTypes.passwordExpiryNotification, {
          isDismissed: true,
        })
      );
    }

    let passwordNotificationIsMfaUpdatedIndex = notifications?.findIndex(
      (el) => el.type === notificationTypes.passwordExpiryNotification && el.isDismissed === true
    );

    if (passwordNotificationIsMfaUpdatedIndex >= 0 && isPasswordNotificationRequired) {
      dispatch(dismissNotification(passwordNotificationIsMfaUpdatedIndex));
      dispatch(
        updateNotification('type', notificationTypes.passwordExpiryNotification, {
          isDismissed: false,
        })
      );
    }

    let mfaNotificationIndex = notifications?.findIndex(
      (el) => el.type === notificationTypes.mfaLoginNotification && el.isDismissed === false && !isRiskUserNotificationRequired
    );

    if (mfaNotificationIndex >= 0 && !isMfaNotificationRequired) {
      dispatch(dismissNotification(mfaNotificationIndex));
      dispatch(
        updateNotification('type', notificationTypes.mfaLoginNotification, {
          isDismissed: true,
        })
      );
    }
    let riskyNotificationIndex = notifications?.findIndex(
      (el) => el.type === notificationTypes.mfaLoginNotification && el.isDismissed === true && isRiskUserNotificationRequired && !isRiskyUserDismissed
    );

    if (riskyNotificationIndex >= 0) {
      dispatch(dismissNotification(riskyNotificationIndex));
      dispatch(
        updateNotification('type', notificationTypes.mfaLoginNotification, {
          isDismissed: false,
        })
      );
    }

    let filteredNotifications = notifications?.filter((notification) => !notification?.isDismissed);
    if (authenticated && !isMfaEnabled) {
      if (isPasswordNotificationRequired && !passwordExpiryNotification.isDismissed) {
        if (!Object.keys(passwordExpiryNotification).length) {
          const passwordNotification = {
            type: 'passwordExpiryNotification',
            titleField: 'PasswordNotificationsHeading',
            contentField: 'PasswordNotificationsContent',
            ctaField: 'UpdateLink',
            isDismissed: false,
          };
          filteredNotifications.push(passwordNotification);
          dispatch(setNotification(passwordNotification));
        }
      }
      if (isMfaNotificationRequired && !mfaNotification.isDismissed) {
        if (!Object.keys(mfaNotification).length) {
          const mfaNotification = {
            type: 'mfaLoginNotification',
            titleField: 'MFANotificationsHeading',
            contentField: 'MFANotificationsContent',
            ctaField: 'UpdateMFALink',
            isDismissed: false,
          };
          filteredNotifications.push(mfaNotification);
          dispatch(setNotification(mfaNotification));
        }
      }
      if (isRiskUserNotificationRequired && !mfaNotification.isDismissed) {
        if (!Object.keys(mfaNotification).length) {
          const mfaNotification = {
            type: 'mfaLoginNotification',
            titleField: 'MFANotificationsHeading',
            contentField: 'MFANotificationsContent',
            ctaField: 'UpdateMFALink',
            isDismissed: false,
          };
          filteredNotifications.push(mfaNotification);
          dispatch(setNotification(mfaNotification));
        }
      }

    } else {
      let passwordNotificationIndex = notifications?.findIndex(
        (el) => el.type === notificationTypes.passwordExpiryNotification
      );
      let mfaNotificationIndex = notifications?.findIndex(
        (el) => el.type === notificationTypes.mfaLoginNotification && !isRiskUserNotificationRequired
      );
      if (mfaNotificationIndex >= 0) {
        dispatch(dismissNotification(mfaNotificationIndex));
        dispatch(
          updateNotification('type', notificationTypes.mfaLoginNotification, {
            isDismissed: true,
          })
        );
      }
      if (passwordNotificationIndex >= 0) {
        dispatch(dismissNotification(passwordNotificationIndex));
        dispatch(
          updateNotification('type', notificationTypes.passwordExpiryNotification, {
            isDismissed: true,
          })
        );
      }
    }
  }, [isMfaEnabled, mfaLastViewedDate, lastPasswordViewedDate, notifications]);

  const fareTypeExpiryNotification = () => {
    const fareTypeExpiryPeriod = parseInt(fields?.fareTypeExpiryPeriod?.value);
    let fareTypeNotifications = productConcessionExpiryDate.map((fareCards) => {
      if (
        !notifications.some(
          (noti) => noti.isFareTypeExpiry && noti.visibleId === fareCards.visibleId
        )
      ) {
        // calculating difference between current date and expiry date
        const expiryDate = fareCards.productConcessionExpiryDate;
        const diffDays = moment(expiryDate).diff(moment(), 'days', true);
        if (diffDays >= 0 && diffDays <= fareTypeExpiryPeriod) {
          return {
            titleField: 'fareTypeNotificationTitle',
            contentField: 'fareTypeNotificationText',
            isDismissed: false,
            fareType: fareCards.fareType,
            nickName: fareCards.nickName,
            expiryDate: fareCards.productConcessionExpiryDate,
            isFareTypeExpiry: true,
            diffDays,
            visibleId: fareCards.visibleId,
            type: fareCards.type,
            specificFare: fareCards.specificFare,
          };
        }
      } else if (
        notifications.some(
          (noti) =>
            noti?.isFareTypeExpiry &&
            noti?.visibleId === fareCards?.visibleId &&
            noti?.nickName !== fareCards?.nickName
        )
      ) {
        dispatch(
          updateNotification('visibleId', fareCards.visibleId, { nickName: fareCards.nickName })
        );
      }
      return null;
    });
    if (fareTypeNotifications && fareTypeNotifications.length) {
      fareTypeNotifications.forEach((i) => {
        if (i) dispatch(setNotification(i));
      });
    }
  };

  const NoNotificationsMsg = () => (
    <>
      <h2 className="h2-title">
        <Text field={fields?.heading} />
      </h2>
      <CardBlock cssClass="notifications-emptyMsg text-center">
        <div className="myProductsOverview-notificationText">
          <RichText field={fields?.noNotificationsMessage} />
        </div>
      </CardBlock>
    </>
  );
  return (
    <div className="notifications">
      {visibleNotifications.length ? null : <NoNotificationsMsg />}
      {visibleNotifications.length ? (
        <div className="notifications-header">
          <h2 className="h2-title">
            <Text field={fields?.heading} />
          </h2>
          <NotificationToggleButton
            collapseTextField={fields?.collapseAll}
            expandTextField={fields?.expandAll}
            showCollapse={hasAllExpanded}
            onClickHandler={toggleAll}
          />
        </div>
      ) : null}
      {visibleNotifications.length
        ? visibleNotifications?.map((notification, index) => (
          <CollapsibleNotification
            key={index}
            index={notification.orderIndex}
            notification={notification}
            fields={fields}
            isExpanded={expandedNotifications.includes(notification.orderIndex)}
            onToggle={toggle}
            notificationTypes={notificationTypes}
            verificationSuccessNotificationExists={verificationSuccessNotificationExists}
            IsRiskyUser={isRiskyUser}
            IsRiskyUserDismissed={isRiskyUserDismissed}
          />
        ))
        : null}
    </div>
  );
};

export default Notifications;
