import React, { useRef } from 'react';

import './CSOLocator.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import downIcon from '../../assets/images/down.svg';
import useMediaWidth from '../../hooks/useMediaWidth';
import { is } from '../../services/utils';

export default function Accordion({
  props,
  getDirections,
  CSOLocatorLookUpData,
  newVisibleMarkers,
  handleAccordionClick,
  checkIsExist,
  gotToStore,
  t,
}) {
  const accordionContainerRef = useRef(null);
  const isMobile = useMediaWidth();
  const finalStationData = newVisibleMarkers?.map((station) => {
    const CustomerServiceOutlet = station?.properties?.customerServiceOutlet
      ?.split('|')
      .map((csoId) => {
        const serviceOutletName = CSOLocatorLookUpData?.CsoLocators?.find(
          (cso) => cso.Id === csoId
        );
        return serviceOutletName ? serviceOutletName.ServiceOutletName : '';
      })
      .filter((serviceOutletName) => serviceOutletName !== '')
      .join(', ');
    const AvailableServices = station?.properties?.availableServices
      ?.split('|')
      .map((serviceId) => {
        const serviceName = CSOLocatorLookUpData?.AvailableServices?.find(
          (cso) => cso.Id === serviceId
        );
        return serviceName ? serviceName.ServiceName : '';
      })
      .filter((serviceName) => serviceName !== '')
      .join(', ');
    const TypeofFacility = station?.properties?.typeofFacility
      ?.split('|')
      .map((facilityId) => {
        const facilityName = CSOLocatorLookUpData?.TypeofFacilities?.find(
          (cso) => cso.Id === facilityId
        );
        return facilityName ? facilityName.FacilityName : '';
      })
      .filter((facilityName) => facilityName !== '')
      .join(', ');
    const distanceVal = station?.properties?.distance === null ? '' : station?.properties?.distance;
    const kMValue = props?.fields?.StoreDistanceText?.value;
    const finalKmValue = kMValue?.replace('{0}', distanceVal);
    const street = station?.properties?.street ? ` ${station?.properties?.street}` : '';
    const city = station?.properties?.city ? `, ${station?.properties?.city}` : '';
    const province = station?.properties?.province ? `, ${station.properties.province}` : '';
    const postalCode = station?.properties?.postalCode
      ? `, ${station?.properties?.postalCode}`
      : '';
    return {
      ...station,
      storeUniqueId: station?.properties?.id,
      icon: station?.properties?.icon?.url,
      distance: finalKmValue,
      storeNumber: station?.properties?.number,
      street,
      storeName: station?.properties?.storeName,
      address: station?.properties?.address,
      storePage: station?.properties?.storePage,
      city,
      cityExpanded: station?.properties?.city,
      province,
      postalCode,
      CustomerServiceOutlet,
      AvailableServices,
      TypeofFacility,
    };
  });

  return (
    finalStationData &&
    finalStationData.map((item) => {
      let locationID = item?.properties?.locationID;
      const isExpanded = checkIsExist(locationID);
      return (
        <div className="accordion-container" id={`cso-result-id-${locationID}`}>
          <div
            id="stationContainer"
            className={`accordian-title ${
              isExpanded ? 'accordian-title-without-border' : 'accordian-title-with-border'
            }`}
          >
            <div
              ref={accordionContainerRef}
              role="button"
              aria-expanded={isExpanded ? true : false}
              className={`accordion-header ${isExpanded ? 'expanded' : ''}`}
              onClick={() => handleAccordionClick(locationID)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleAccordionClick(locationID);
              }}
              aria-controls={`cso-result-acc-${locationID}`}
              tabIndex={0}
            >
              <div className="test-class">
                {/* <div className="label">
                  <label>{item?.distance}</label>
                </div> */}
                <div className="station-name">
                  <p>{item?.storeName}</p>
                </div>
                <div className="image-label">
                  {is(props?.fields?.AddressLabelA11y.value) ? (
                    <img
                      src={item?.icon}
                      alt={props?.fields?.AddressLabelA11y.value}
                      aria-label={props?.fields?.AddressLabelA11y.value}
                      className=""
                    />
                  ) : null}
                  <label className="label">{`${item?.storeNumber}${item?.street}${item?.city}${item?.province}${item.postalCode}`}</label>
                </div>
              </div>
              <img alt="" className={isExpanded ? 'up' : ''} src={downIcon} />
            </div>
          </div>
          {isExpanded && (
            <div className="accordion-content" id={`cso-result-acc-${locationID}`}>
              {is(props?.fields?.GetDirectionsLabel?.value) ? (
                <div className="get-directions">
                  {is(props?.fields?.StoreDirectionsIcon?.value?.src) ? (
                    <img
                      src={props?.fields?.StoreDirectionsIcon?.value?.src}
                      alt={props?.fields?.StoreDirectionsIcon?.value?.alt}
                    />
                  ) : null}
                  <a
                    role="link"
                    onClick={() => getDirections(item)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        getDirections(item);
                      }
                    }}
                    tabIndex={0}
                    title={t('opensNewWindowA11y')}
                    rel="noopener noreferrer"
                    aria-label={props?.fields?.GetDirectionsLabel?.value}
                  >
                    {props?.fields?.GetDirectionsLabel?.value}
                  </a>
                </div>
              ) : null}
              <ul>
                {is(props?.fields?.LocationLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.LocationLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.storeName}</div>
                  </li>
                ) : null}
                {/* {isMobile ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.LocationLabel} />
                    </div>
                    <div className="loc-detail-item">{agencyName?.ServiceOutletName}</div>
                  </li>
                ) : null} */}
                {is(props?.fields?.StationPageLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.StationPageLabel} />
                    </div>
                    {/* <div className={`loc-detail-item ${isMobile ? `cso-blue-txt` : ``}`}>
                    {item?.storePage}
                  </div> */}
                    <div className="loc-detail-item">
                      {item?.storePage.startsWith('https://') ? (
                        <a
                          className="cso-result-link-txt"
                          role="link"
                          aria-label={props?.fields?.StationPageLabel?.value}
                          onClick={() => gotToStore(item)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') gotToStore(item);
                          }}
                          tabIndex={0}
                          title={t('opensNewWindowA11y')}
                          rel="noopener noreferrer"
                        >
                          {item?.storePage}
                        </a>
                      ) : (
                        item?.storePage
                      )}
                    </div>
                  </li>
                ) : null}
                {is(props?.fields?.AddressLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.AddressLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.address}</div>
                  </li>
                ) : null}
                {is(props?.fields?.CityLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.CityLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.cityExpanded}</div>
                  </li>
                ) : null}
                {is(props?.fields?.CustomerServiceOutLetLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.CustomerServiceOutLetLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.CustomerServiceOutlet}</div>
                  </li>
                ) : null}
                {is(props?.fields?.TypeofFacilityLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.TypeofFacilityLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.TypeofFacility}</div>
                  </li>
                ) : null}
                {is(props?.fields?.AvailableServicesLabel.value) ? (
                  <li className="accordion-content-location-details">
                    <div className="loc-detail-item">
                      <Text field={props?.fields?.AvailableServicesLabel} />
                    </div>
                    <div className="loc-detail-item">{item?.AvailableServices}</div>
                  </li>
                ) : null}
              </ul>
            </div>
          )}
        </div>
      );
    })
  );
}
