import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, ButtonBlock, Loader } from '../../atoms';
import { TextInput } from '../../molecules';
import api from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import './passwordChange.scss';
import { useLocation } from 'react-router-dom';
import { getQueryParams, localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';
import handleServerError from '../../services/handleServerError';
import { updateLoginAndSecurityFields, setB2cClaimsResponse } from '../../redux/user/actions';

const PASSWORD_REGEX = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])((?=.*\d)|(?=.*[@$!%*#?&\'\"<>()+,”]))[A-Za-z\d@$!%*#?&\'\"<>()+,”]{8,25}$/;

const PasswordChange = ({ fields }) => {
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const [successBlock, setSuccessBlock] = useState('');
  const queryParams = getQueryParams(useLocation().search);
  const customerId = useSelector((s) => s.user.customerId);
  const [isLoading, setIsLoading] = useState(false);
  const [errorItem, setErrorItem] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const loginAndSecurityProps = useSelector((reduxState) => reduxState.user.loginAndSecurity);

  const handleSubmitData = (data) => {
    setIsLoading(true);
    api
      .updatePassword({
        Password: data.currentPassword,
        NewPassword: data.newPassword,
        CustomerId: customerId,
      })
      .then((response) => {
        setIsLoading(false);
        setIsEditPassword(false);
        if (response?.data?.Success) {
          setSuccessBlock('true');
        } else {
          dispatch(updateLoginAndSecurityFields({ passwordUpdateErrCode: response?.data?.Error }));
          setErrorItem(response?.data?.Error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsEditPassword(false);
        dispatch(updateLoginAndSecurityFields({ isUpdatePasswordSucceed: false }));
      });
  };

  useEffect(() => {
    if (queryParams.Success !== undefined && queryParams.Success === 'true') {
      api
        .getClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate:
                  res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin:
                  String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, []);
  useEffect(() => {
    if (
      fields?.SuccessPwdchange?.value != '' &&
      (loginAndSecurityProps?.SuccessPwdchange?.value === '' ||
        loginAndSecurityProps?.SuccessPwdchange?.value !== fields?.SuccessPwdchange?.value)
    ) {
      dispatch(updateLoginAndSecurityFields({ ...fields }));
    }
  }, [fields]);
  return [
    isLoading && <Loader />,
    !isEditPassword ? (
      <>
        <div className="password-wrapper">
          <div className="password-flex">
            <div className="password-label">
              <span>
                <Image field={fields?.CurrentPasswordImage} loading="lazy" />
              </span>
              <div className="labelName" tabIndex="0">
                <Text field={fields.CurrentPasswordLabel} />
              </div>
            </div>
            <div className="password-content">
              <input type="password" size={12} name="password" value="************" disabled />
            </div>
          </div>
          <ButtonBlock className="password-button">
            <Button
              onClick={() => api.b2cChangePassword()}
              buttonTextA11y={fields?.UpdateButtonLabelA11y.value}
            >
              <Text field={fields?.UpdateButtonLabel} />
            </Button>
          </ButtonBlock>
        </div>
      </>
    ) : (
      <div className="password-wrapper">
        <div className="password-header">
          <Text field={fields.UpdatePasswordLabel} />
        </div>
        <div className="update-pass-descr">
          <Text field={fields.Updatepassworddescription} />
        </div>
        <div className="update-pass-form">
          <Formik
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.currentPassword) {
                errors.currentPassword = fields?.CurrentPasswordError.value;
              }
              if (!values.newPassword) {
                errors.newPassword = fields?.NewPasswordError.value;
              } else if (
                !PASSWORD_REGEX.test(values.newPassword) ||
                values.newPassword.length < 8 ||
                values.newPassword.length > 25
              ) {
                errors.newPassword = fields?.NewpwdValidateerror.value;
              }
              if (!values.confirmNewPassword) {
                errors.confirmNewPassword = fields?.ConfirmPasswordError.value;
              } else if (values.newPassword !== values.confirmNewPassword) {
                errors.confirmNewPassword = fields?.ConfirmPwdValidateerror.value;
              }
              return errors;
            }}
            onSubmit={(values) => {
              handleSubmitData(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="update-input-block">
                  <div className="current-password">
                    <div className="update-current">
                      {/* <div className="input-block-label">
                        <RichText field={fields.CurrentPwdLabel} />
                      </div> */}
                      <div className="input-block-field">
                        <TextInput
                          type={currentPassword ? 'text' : 'password'}
                          name="currentPassword"
                          onChange={handleChange}
                          value={values.currentPassword}
                          errors={errors}
                          touched={touched}
                          label={fields.CurrentPwdLabel}
                          inputA11y={fields.CurrentPwdLabelA11y.value}
                          showIconA11y={fields.showCurrentPasswordA11y?.value}
                          hideIconA11y={fields.hideCurrentPasswordA11y?.value}
                          withViewIcon
                          disabledAutocomplete
                        />
                        {errors.currentPassword && touched.currentPassword}
                      </div>
                    </div>
                  </div>
                  <div className="new-pass-block">
                    <div className="update-new-pass">
                      {/* <div className="input-block-label">
                        <RichText field={fields?.NewPwdLabel} />
                      </div> */}
                      <div className="update-new-field">
                        <TextInput
                          type={newPassword ? 'text' : 'password'}
                          name="newPassword"
                          onChange={handleChange}
                          value={values.newPassword}
                          errors={errors}
                          label={fields.NewPwdLabel}
                          touched={touched}
                          inputA11y={fields.NewPwdLabelA11y.value}
                          showIconA11y={fields.showNewPasswordA11y?.value}
                          hideIconA11y={fields.hideNewPasswordA11y?.value}
                          withViewIcon
                          disabledAutocomplete
                        />
                        {errors.newPassword && touched.newPassword}
                      </div>
                    </div>
                    <div className="confirm-new-pass">
                      {/* <div className="input-block-label">
                        <RichText field={fields.ConfirmPasswordLabel} />
                      </div> */}
                      <div className="confirm-new-field">
                        <TextInput
                          type={confirmNewPassword ? 'text' : 'password'}
                          name="confirmNewPassword"
                          onChange={handleChange}
                          value={values.confirmNewPassword}
                          label={fields.ConfirmPasswordLabel}
                          errors={errors}
                          touched={touched}
                          inputA11y={fields.ConfirmPasswordLabel.value}
                          showIconA11y={fields.showConfirmNewPasswordA11y?.value}
                          hideIconA11y={fields.hideConfirmNewPasswordA11y?.value}
                          withViewIcon
                          disabledAutocomplete
                        />
                        {errors.confirmNewPassword && touched.confirmNewPassword}
                      </div>
                    </div>
                  </div>
                </div>
                <ButtonBlock className="update-Pass-Button">
                  <Button onClick={() => setIsEditPassword(false)}>
                    <Text field={fields?.DiscardChangesLabel} />
                  </Button>
                  <Button type="submit">
                    <Text field={fields?.SaveChangesLabel} />
                  </Button>
                </ButtonBlock>
              </form>
            )}
          </Formik>
        </div>
      </div>
    ),
  ];
};

export default PasswordChange;
