import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton, Loader } from '../../../atoms';
import { Error, Success } from '../../../molecules';
import printIcon from '../../../assets/images/printer.svg';
import './Deactivate1.scss';
import { formatDecimalWithCurrency } from '../../../services/utils';
import api from '../../../services/api';
import { setUserInfo, fetchUserInfoData } from '../../../redux/user/actions';

const Deactivate1 = ({ toPreviousStep, fields, userInfo }) => {
  const hotlistApiSuccess = useSelector((reduxState) => reduxState.user.hotlistApiSuccess);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const visibleId = userInfo.selectedCard.visibleId;
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias =
    fareMedias && fareMedias.length > 0 && fareMedias.find((data) => data.visibleId === visibleId);
  const currentBalance = fareMedias?.currentBalance;
  const userFareMedias = hotlistApiSuccess?.data?.Data?.Customer?.FareMedias;
  const verificationNumber =
    userFareMedias && userFareMedias.length > 0 && userFareMedias[0]?.VerificationNumber;
  const transitPasses =
    fareMedias && fareMedias?.passes && fareMedias?.passes.length > 0 && fareMedias?.passes;
  useEffect(() => {
    document.body.scrollTop = 0;
    if (hotlistApiSuccess?.hotlistApiSuccess) {
      dispatch(fetchUserInfoData());
      api.UpdateFareMediaSession({ VisibleId: visibleId }).then(({ data }) => {});
    }
  }, []);
  const GotoTransferHandler = () => {
    window.location.href = fields?.TransferBalanceCTALink?.value?.href;
    // setIsLoading(true);
    // api
    //   .getUserInfo()
    //   .then((response) => {
    //     if (response?.data?.Success) {
    //       dispatch(setUserInfo(response.data.Data));
    //       setIsLoading(false);
    //       window.location.href = fields?.TransferBalanceCTALink?.value?.href;
    //     }
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     window.location.href = fields?.TransferBalanceCTALink?.value?.href;
    //   });
  };
  let formattedBalance = formatDecimalWithCurrency(currentBalance);
  return (
    <>
      {isLoading && <Loader />}
      <div className="deactivated-card-withButton">
        {hotlistApiSuccess?.hotlistApiSuccess ? (
          <Success
            title={fields.DeactivateCardSuccessNote}
            titleA11y={fields.DeactivateCardSuccessNote.value}
            contextText={fields.DeactivateCardSuccessSubNote}
            Content={() => (
              <>
                <div className="deactivated-card-block">
                  <div className="card-content">
                    {visibleId && (
                      <div className="card-Number">
                        <RichText field={fields.PrestoCardNumberLable} />
                        <span>
                          <b>{visibleId}</b>
                        </span>
                      </div>
                    )}
                    {verificationNumber && (
                      <div className="card-nick-name">
                        <RichText field={fields.VerificationNumber} />
                        <span>
                          <b>{verificationNumber}</b>
                        </span>
                      </div>
                    )}
                    {currentBalance && (
                      <div className="card-balance">
                        <RichText field={fields.BalanceLabel} />
                        <span>
                          <b>{formattedBalance}</b>
                        </span>
                      </div>
                    )}

                    <div className="card-transitPass">
                      <RichText field={fields.TransitPassLabel} />
                      {transitPasses && transitPasses.length > 0 ? (
                        <div>
                          {transitPasses.map((pass) => {
                            if (pass.ProductName !== null) {
                              return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                            }
                          })}
                        </div>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          />
        ) : (
          <Error
            title={fields.ErrorMessage.value}
            titleA11y={fields.ErrorMessage.value}
            text={fields.OrderFailureMessage}
          />
        )}
        <div className="deactivate-print">
          {hotlistApiSuccess.hotlistApiSuccess ? (
            <div className="manage-button">
              <Button onClick={GotoTransferHandler}>
                <Text field={fields.TransferBalanceCTALabel} />
              </Button>
            </div>
          ) : (
            <div className="manage-button">
              <Button
                onClick={() => {
                  toPreviousStep();
                }}
              >
                <Text field={fields.TryAgainBtnLable} />
              </Button>
            </div>
          )}

          <IconButton onClick={() => window.print()} icon={printIcon}>
            {fields.Print.value}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Deactivate1;
