import React, { useRef, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import routes from '../../constants/routes';
import { isFrench } from '../../services/utils';
import langConstants from '../../constants/lang'

import handleLogOut from '../../services/handleLogOut';
import {
  addShoppingCartMessage,
  emptyShoppingCartMessage,
  removeNewItemFlag,
  selectCard,
  fetchWalletCardDetails,
} from '../../redux/user/actions';
import {
  setLanguage,
  togglePageScroll,
  redirectToUrl,
  getRefreshToken,
  localstorageService,
  getQueryParams,
  checkTokenExpiry,
  getShortLanguageCode,
  getLongLanguageCode,
} from '../../services/utils';
import mediaSubTypes from '../../constants/mediaSubTypes';
import mediaTypes from '../../constants/mediaTypes';
import mediaSubType from '../../constants/mediaSubType';
import { NavLink, PrestoLink, IconButton, Loader } from '../../atoms';
import {
  ShoppingCart,
  HeaderSearch,
  HeaderSearchMobile,
  CardControlledMenuItem,
} from '../../molecules';
import SessionTimeoutModal from './SessionTimeoutModal';
import useSelectedCard from '../../hooks/useSelectedCard';
import usePrevious from '../../hooks/usePrevious';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useMediaWidth from '../../hooks/useMediaWidth';
import OpenSideNavImage from '../../assets/images/HamburgerMenu.svg';
import CloseSideNavImage from '../../assets/images/HamburgerMenuClose.svg';
import downIcon from '../../assets/images/down.svg';
import accountIcon from '../../assets/images/Account.svg';
import accountIconActive from '../../assets/images/Account_Active.svg';
import shoppingCartIcon from '../../assets/images/ShoppingCart.svg';
import TopNavigationCategoryItem from '../TopNavigation/topNavigationCategoryItem';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';

import './topNavigationAuthenticated.scss';

const TopNavigationAuthenticated = (props) => {
  const { fareMedias, contactlessMedias } = useSelector((state) => state.user);
  const loggedInTime = useSelector((state) => state.user.loggedInTime);
  const loggedOutTime = useSelector((state) => state.user.logOutTime);
  const authenticated = useSelector((state) => state.user.authenticated);
  const isAnonymousAuthenticated = useSelector((state) => state.user.anonymous);
  const isContactlessAuthenticatedRedux = useSelector(
    (state) => state.contactless.isContactlessUserSignedIn
  );
  const selectedCard = useSelectedCard();
  const allCards = fareMedias.concat(contactlessMedias);
  const isMobile = useMediaWidth();
  const [isLoading, setIsLoading] = useState(false);
  const { datasource, contextItem } = props.fields.data ? props.fields.data : {};
  const dropdownRef = useRef(null);
  const shoppingCartRef = useRef(null);
  const secondLevelDropdownRef = useRef(null);
  const [secondLevelactive, setsecondLevelActive] = useDetectOutsideClick(
    secondLevelDropdownRef,
    false
  );
  const [langCode, setLangCode] = useState('');
  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState('');
  const userDropdownRef = useRef(null);
  const [isUserActive, setUserActive] = useDetectOutsideClick(userDropdownRef, false);
  const onClickUser = () => setUserActive(!isUserActive);
  const cardSwitcherDropdownRef = useRef(null);
  const [isCardSwitcherActive, setCardSwitcherActive] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const dispatch = useDispatch();
  const mobileMenuRef = useRef(null);
  const [isMenuClicked, setMenuActive] = useState(false);
  const [isOpenShoppingCart, setOpenShoppingCart] = useDetectOutsideClick(
    shoppingCartRef,
    false,
    true,
    () => {
      dispatch(emptyShoppingCartMessage());
    }
  );
  const prevValue = usePrevious(isOpenShoppingCart);
  useEffect(() => {
    if (prevValue) {
      dispatch(emptyShoppingCartMessage());
    }
  }, [isOpenShoppingCart]);
  const showMobileMenu = () => setMenuActive(!isMenuClicked);
  const urlParams = useParams();
  const queryParams = getQueryParams(useLocation().search);
  const PathParams = useLocation().pathname.toLowerCase();
  const location = useLocation();
  const cartQuantity = useSelector((state) => state.user.shoppingCartQuantity);
  const isNewItem = useSelector((state) => state.user.shoppingCart.isNewItem);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveThirdLevelMenu, setIsActiveThirdLevelMenu] = useState(false);
  const [currentLevel, setCurrentLevel] = useState('');

  const thirdLevelHandle = (newCurrentLevel) => {
    setCurrentLevel(newCurrentLevel);
    setIsActiveThirdLevelMenu((current) => !current);
  };

  const businessRules = useSelector((state) => state.businessRules?.rules) || [];
  const idleTime = businessRules?.IdleTime ? businessRules?.IdleTime : 15;

  useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    events: [
      // 'mousemove',
      'keydown',
      // 'wheel',
      // 'DOMMouseScroll',
      // 'mousewheel',
      'mousedown',
      'touchstart',
      // 'touchmove',
      'MSPointerDown',
      // 'MSPointerMove',
      // 'visibilitychange',
    ],
    onIdle: () => setIsModalOpen(true),
    debounce: 500,
  });
  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');
    const mobileMenuWrapper = document.querySelector('.mobile-menu-wrapper');

    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
    setIsSkipTranslateHidden(
      skipTranslateElement &&
      getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none'
    );
    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');
    if (mobileMenuWrapper) {
      if (smartBanner && targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '176px';
      } else if (document.querySelector('html.smartbanner-show')) {
        mobileMenuWrapper.style.top = '136px';
      } else if (targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '96px';
      } else {
        mobileMenuWrapper.style.top = '56px'; // Default value if neither is present
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 100); // Check every 1 second

    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    if (isNewItem) {
      setOpenShoppingCart(true);
      setTimeout(() => {
        setOpenShoppingCart(false);
        dispatch(removeNewItemFlag());
        dispatch(addShoppingCartMessage(''));
      }, 3000);
    }
  }, [isNewItem]);

  useEffect(() => {
    togglePageScroll(isMenuClicked);
  }, [isMenuClicked]);

  const changeLanguage = () => {
    let url = { ...urlParams };
    let searchKeyword = location?.search;
    if (searchKeyword) {
      url = { ...url, sitecoreRoute: `${url?.sitecoreRoute}${searchKeyword}` };
    }
    setLanguage(url);
  };
  useEffect(() => {
    let language = isFrench() ? langConstants.longFr : langConstants.en;
    setLangCode(language);
  }, []);

  const handleLogoutHandler = () => {
    setUserActive(!isUserActive);
    // handleLogOut(dispatch);
    setIsLoading(true);
    api
      .logOut({ Token: getRefreshToken() })
      .then(() => {
        clearReduxHistory();
      })
      .catch((e) => {
        setIsLoading(false);
        clearReduxHistory();
        console.warn(e);
      });
  };

  useEffect(() => {

    const isValidSession = (() => {
      const { isAfter1Hr } = checkTokenExpiry(loggedInTime, loggedOutTime);
      if (isAfter1Hr) {
        // Logging out the user since its been more than 1 hour since they logged in.
        handleLogoutHandler();
      } else {
        // Session is still valid, and user is not idle i.e. no action needed.
      }
    });
    isValidSession();
    const timer = setInterval(isValidSession, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (authenticated && (PathParams == '/' || PathParams == '/en/' || PathParams == '/fr-CA/' || PathParams == '/en' || PathParams == '/fr-CA')) {

      redirectToUrl(routes.dashboard);
    } else if (
      isContactlessAuthenticatedRedux &&
      (PathParams == '/' ||
        PathParams == '/en/' ||
        PathParams == '/fr-CA/' ||
        PathParams == '/en' ||
        PathParams == '/fr-CA')
    ) {
      //redirectToUrl(routes.contactlessTransactionHistory);
    } else if (
      isAnonymousAuthenticated &&
      (PathParams == '/' ||
        PathParams == '/en/' ||
        PathParams == '/fr-CA/' ||
        PathParams == '/en' ||
        PathParams == '/fr-CA')
    ) {
      redirectToUrl(routes.dashboard);
    } else {
      //console.log('not true');
    }

  }, [authenticated, isContactlessAuthenticatedRedux, isAnonymousAuthenticated]);



  /*useEffect(() => {
    const { logOutTime, isAfter24Hrs } = check24hrsTillLogin(loggedOutTime);
    if (isAfter24Hrs && authenticated) {
      console.log('Session Invalid, Logged valid till: ', logOutTime, '. Logging out..');
      setlogoutUser(true);
      setIsModalOpen(true);
      //handleLogoutHandler();
    } else {
      console.log('Session Valid, Logged valid till: ', logOutTime);
      setlogoutUser(false);
      setIsModalOpen(false);
    }
  });*/


  const clearReduxHistory = () => {
    localstorageService().removeItem('redux');
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.accessTokenValidity);
    localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.guestToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    localstorageService().removeItem(localStorageKeys.gtmContainerId);
    localstorageService().removeItem(localStorageKeys.unpaidFaresData);
    localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
    localstorageService().removeItem(localStorageKeys.stsAccessToken);
    localstorageService().removeItem(localStorageKeys.unpaidFaresIndex);
    localstorageService().removeItem(localStorageKeys.paymentsToVerifyData);
    localstorageService().removeItem(localStorageKeys.isRiskyUser);
    localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
    api.b2cSignOut();
  };

  const isNavigationItemActive = (routeName) => contextItem.url.indexOf(`/${routeName}/`) !== -1;

  useEffect(() => {
    if (isNavigationItemActive('my-products')) {
      const selectedCardIndex = allCards.findIndex((card) => card.mediaId === selectedCard.mediaId);
      if (typeof selectedCardIndex === 'number') {
        setActiveCardIndex(selectedCardIndex);
        setCardSwitcherActive(true);
        setActiveNavItem(datasource.children[0].Link?.jss.value?.text);
      }
    }
    if (isNavigationItemActive('support')) {
      setActiveNavItem(datasource.children[2].Title?.value);
    }

    if (isNavigationItemActive('account')) {
      setActiveNavItem(datasource.children[3].Title?.value);
    }
  }, []);

  useEffect(() => {
    if (queryParams.IsMaxTryExceeds !== undefined) {
      setIsLoading(true);
      clearReduxHistoryB2CSignout();
      api.b2cMfaMaxRetryExceeds();
    }
    else if (queryParams.IsMfaVerificationFailed !== undefined) {
      setIsLoading(true);
      clearReduxHistoryB2CSignout();
      api.b2cRegisteredSignIn();
    }
    else if (queryParams.signout !== undefined) {
      setIsLoading(true);
      clearReduxHistoryB2CSignout();
      redirectToUrl('/');
    }
  }, []);

  const clearReduxHistoryB2CSignout = () => {
    localstorageService().removeItem('redux');
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.accessTokenValidity);
    localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.guestToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    localstorageService().removeItem(localStorageKeys.gtmContainerId);
    localstorageService().removeItem(localStorageKeys.unpaidFaresData);
    localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
    localstorageService().removeItem(localStorageKeys.stsAccessToken);
    localstorageService().removeItem(localStorageKeys.isRiskyUser);
    localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
    setIsLoading(false);
    if (window.CVVA !== undefined && window.CVVA !== null) {
      window.CVVA.flushVaSession();
    }
  };
  const selectThisCard = (card, payload) => {
    dispatch(selectCard(card, payload));
  };
  async function redirectMyproductsMobile(card, linkItem) {
    const isMobileWallet = card?.mediaType === mediaTypes.CRDNC;
    const checkLang = () => {
      return [getShortLanguageCode(), getLongLanguageCode()].some((lang) =>
        linkItem.Link?.jss.value.href.includes(lang)
      );
    };
    let isSameURL = window.location.pathname === linkItem.Link?.jss.value.href;
    let isSameCardSelected = card.mediaId === selectedCard.mediaId;
    let payload = {
      url: linkItem.Link?.jss.value.href,
      includeLanguage: !checkLang(),
      redirected: isSameURL && isSameCardSelected,
    };
    if (isMobileWallet) {
      if (contactlessMedias[0].dpan === card.dpan && !card.cardNumber) {
        dispatch(fetchWalletCardDetails);
        selectThisCard(card, payload);
      } else {
        api
          .getStoredMediaTypeInSession({
            MediaType: card.mediaSubTypeWallet,
          })
          .then((res) => {
            selectThisCard(card, payload);
          });
      }
    } else {
      selectThisCard(card, payload);
    }
  }
  return (
    <div data-e2e-id="top-nav-auth" role="navigation" className="top-nav-authenticated">
      {datasource && (
        <>{isLoading && <Loader />}
          <SessionTimeoutModal
            t={props.t}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />

          {!isMobile && (
            <div className="header">
              <div className="header-nav-links">
                <li role="banner">
                  <PrestoLink
                    field={datasource.LogoLink?.jss}
                    image={datasource.parent.Logo.jss}
                    imageClassName="logo-header"
                  />
                </li>
                {datasource.children.map((child) => (
                  <TopNavigationCategoryItem navigationItem={child} contextItem={contextItem} />
                ))}
              </div>
              <div>
                <HeaderSearch />

                <li>
                  <a
                    role="button"
                    tabIndex="0"
                    aria-label={props.t('topNavigationLanguageSwitcherA11y')}
                    onClick={changeLanguage}
                    onKeyPress={changeLanguage}
                  >
                    {props.t('topNavigationLanguageSwitcher', { lng: langCode })}
                  </a>
                </li>
                <li>
                  <div
                    className="cart"
                    role="button"
                    onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                    onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                    tabIndex="0"
                    aria-expanded={isOpenShoppingCart}
                    aria-label={`${props
                      .t('shoppingCartItemsInCartA11y')
                      .replace('{number}', cartQuantity)}`}
                  >
                    <img
                      src={shoppingCartIcon}
                      alt={`${props
                        .t('shoppingCartItemsInCartA11y')
                        .replace('{number}', cartQuantity)}`}
                    />
                    {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                  </div>
                  <ShoppingCart
                    forwardRef={shoppingCartRef}
                    cartQuantity={cartQuantity}
                    isOpenShoppingCart={isOpenShoppingCart}
                    closeShoppingCart={() => setOpenShoppingCart(false)}
                    t={props.t}
                  />
                </li>
                <li className="user-icon">
                  <a
                    role="button"
                    aria-expanded={isUserActive}
                    tabIndex="0"
                    aria-label={props.t('topNavigationMyProfileA11y')}
                    onClick={onClickUser}
                    onKeyPress={onClickUser}
                    className={`user 
                  ${isUserActive ? 'dropdown' : ''} 
                  ${isNavigationItemActive('account') ? 'active' : ''}
                  `}
                  >
                    <img
                      src={`${isNavigationItemActive('account') ? accountIconActive : accountIcon}`}
                      alt={props.t('topNavigationMyProfileA11y')}
                    />
                  </a>
                  <ul ref={userDropdownRef} className="dropdown-menu">
                    <li>
                      <PrestoLink field={datasource.AccountLink?.jss} />
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        role="button"
                        onClick={handleLogoutHandler}
                        onKeyPress={handleLogoutHandler}
                      >
                        {props.t('topNavigationSignOut', { lng: langCode })}
                      </a>
                    </li>
                  </ul>
                </li>
              </div>
            </div>
          )}

          {isMobile && (
            <ul className="header-mobile">
              <PrestoLink
                field={datasource.LogoLink?.jss}
                image={datasource.parent.Logo.jss}
                imageClassName="logo-header-mobile"
              />
              <div className="mobile-menu-right">
                <div
                  className="cart"
                  role="button"
                  tabIndex="0"
                  aria-expanded={isOpenShoppingCart}
                  aria-label={`${props
                    .t('shoppingCartItemsInCartA11y')
                    .replace('{number}', cartQuantity)}`}
                  onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                  onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                >
                  <img
                    src={shoppingCartIcon}
                    alt={`${props
                      .t('shoppingCartItemsInCartA11y')
                      .replace('{number}', cartQuantity)}`}
                  />
                  {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                </div>
                <ShoppingCart
                  forwardRef={shoppingCartRef}
                  cartQuantity={cartQuantity}
                  isOpenShoppingCart={isOpenShoppingCart}
                  closeShoppingCart={() => setOpenShoppingCart(false)}
                  t={props.t}
                />
                <div className="hamburger">
                  <button
                    type="button"
                    onClick={() => {
                      showMobileMenu();
                    }}
                  >
                    <img
                      alt={
                        !isMenuClicked
                          ? props.t('topNavigationOpenMenuA11y')
                          : props.t('topNavigationCloseMenuA11y')
                      }
                      src={!isMenuClicked ? OpenSideNavImage : CloseSideNavImage}
                    />
                  </button>
                </div>
              </div>

              <div className={`mobile-menu-wrapper ${isMenuClicked ? 'active' : 'inactive'}`}>
                <ul
                  ref={mobileMenuRef}
                  className={`dropdown-menu-mobile ${isMenuClicked ? 'active' : 'inactive'}`}
                >
                  <div
                    className="nav-border"
                    onClick={() => {
                      showMobileMenu();
                    }}
                  />
                  <HeaderSearchMobile />
                  {datasource.children.map((child) => (
                    <li>
                      {child.Link && child.children.length === 0 && (
                        <PrestoLink field={child.Link?.jss} />
                      )}
                      {(child.Title || (child.Link && child.children.length > 0)) && (
                        <>
                          {child.Title && (
                            <NavLink
                              title={child.Title?.value}
                              onClick={(title, isActive) => {
                                if (isActive) {
                                  setActiveNavItem('');
                                } else {
                                  setActiveNavItem(title);
                                }
                              }}
                              isActive={activeNavItem === child.Title?.value}
                              isDropdown={activeNavItem === child.Title?.value}
                              mobile
                            />
                          )}
                          {child.Link && !child.WithCardSwitcher && child.children.length > 0 && (
                            <NavLink
                              title={child.Link?.jss?.value?.text}
                              onClick={(title, isActive) => {
                                if (isActive) {
                                  setActiveNavItem('');
                                } else {
                                  setActiveNavItem(title);
                                }
                              }}
                              isActive={activeNavItem === child.Link?.jss?.value?.text}
                              isDropdown={activeNavItem === child.Link?.jss?.value?.text}
                              mobile
                            />
                          )}
                          <ul ref={dropdownRef} className="dropdown-secondlevelmenu-mobile">
                            {child.WithCardSwitcher?.jss.value && (
                              <>
                                {allCards?.map((card, index) => (
                                  <>
                                    {/* eslint-disable */}
                                    <li
                                      tabIndex="0"
                                      role="button"
                                      aria-expanded={
                                        isCardSwitcherActive && activeCardIndex === index
                                      }
                                      onClick={() => {
                                        setActiveCardIndex(index);
                                        setCardSwitcherActive((prevState) =>
                                          activeCardIndex === index ? !prevState : true
                                        );
                                      }}
                                      onKeyPress={() => {
                                        if (activeCardIndex !== index) {
                                          setActiveCardIndex(index);
                                          setCardSwitcherActive(true);
                                        } else {
                                          setActiveCardIndex(null);
                                          setCardSwitcherActive(false);
                                        }
                                      }}
                                    >
                                      {/* eslint-enable */}
                                      <div className="card-name">{card.nickName}</div>
                                      <IconButton
                                        tabIndex="-1"
                                        icon={downIcon}
                                        iconAlt={
                                          isCardSwitcherActive && activeCardIndex === index
                                            ? props.t('expandA11y')
                                            : props.t('collapseA11y')
                                        }
                                        className={
                                          isCardSwitcherActive && activeCardIndex === index
                                            ? 'up'
                                            : ''
                                        }
                                      />
                                      <div className="card-id">
                                        {card.type === mediaSubTypes.presto
                                          ? card.visibleId
                                          : card.mediaType === mediaTypes.CRDNC
                                            ? card.cardNumber
                                            : card.mediaId}
                                      </div>
                                    </li>
                                    {isCardSwitcherActive && activeCardIndex === index ? (
                                      <ul
                                        ref={cardSwitcherDropdownRef}
                                        className="dropdown-third-level"
                                      >
                                        {child.children?.map((grandchild) => (
                                          <>
                                            {card.mediaType === mediaTypes.CRDNC ? (
                                              grandchild.Link &&
                                                card.mediaSubTypeWallet === mediaSubType.GOOGLE &&
                                                grandchild.ShowForGoogleWalletCard.jss.value ? (
                                                <CardControlledMenuItem
                                                  item={grandchild}
                                                  selectedCard={card}
                                                  wrapperClassName="third-level-link"
                                                  onClickCustom={(e) => {
                                                    e.preventDefault();
                                                    redirectMyproductsMobile(card, grandchild);
                                                  }}
                                                />
                                              ) : grandchild.Link &&
                                                card.mediaSubTypeWallet === mediaSubType.APPLE &&
                                                grandchild.ShowForAppleWalletCard.jss.value ? (
                                                <CardControlledMenuItem
                                                  item={grandchild}
                                                  selectedCard={card}
                                                  wrapperClassName="third-level-link"
                                                  onClickCustom={(e) => {
                                                    e.preventDefault();
                                                    redirectMyproductsMobile(card, grandchild);
                                                  }}
                                                />
                                              ) : grandchild.Link &&
                                                (card.mediaSubTypeWallet === null || '') &&
                                                !grandchild.ShowForPrestoCard.jss.value &&
                                                !grandchild.ShowForAppleWalletCard.jss.value &&
                                                !grandchild.ShowForGoogleWalletCard.jss.value &&
                                                !grandchild.ShowForContactlessCard.jss.value ? (
                                                <CardControlledMenuItem
                                                  item={grandchild}
                                                  selectedCard={card}
                                                  wrapperClassName="third-level-link"
                                                  onClickCustom={(e) => {
                                                    e.preventDefault();
                                                    redirectMyproductsMobile(card, grandchild);
                                                  }}
                                                />
                                              ) : (
                                                <></>
                                              )
                                            ) : (
                                              grandchild.Link && (
                                                <CardControlledMenuItem
                                                  item={grandchild}
                                                  selectedCard={card}
                                                  wrapperClassName="third-level-link"
                                                  onClickCustom={(e) => {
                                                    e.preventDefault();
                                                    redirectMyproductsMobile(card, grandchild);
                                                  }}
                                                />
                                              )
                                            )}
                                          </>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            )}
                            {child.WithCardSwitcher === null &&
                              child.children?.map((grandchild) => (
                                <li>
                                  {(grandchild.Link && grandchild.children.length === 0) && (
                                    <>
                                      {grandchild.Link?.jss.value.href.includes(routes.loginAndSecurityRefresh) ?
                                        (
                                          <PrestoLink field={grandchild.Link?.jss} onClickCustom={() => redirectToUrl(routes.loginAndSecurityRefresh)} />
                                        ) :
                                        (
                                          <PrestoLink field={grandchild.Link?.jss} />
                                        )}
                                    </>
                                  )}
                                  {(grandchild.Title ||
                                    (grandchild.Link && grandchild.children.length > 0)) && (
                                      <>
                                        {grandchild.Title && (
                                          <NavLink
                                            title={grandchild.Title?.value}
                                            onClick={setsecondLevelActive}
                                            isDropdown={secondLevelactive === grandchild.Title?.value}
                                            mobile
                                          />
                                        )}
                                        {grandchild.Link && grandchild.children.length > 0 && (
                                          <NavLink
                                            title={grandchild.Link?.jss?.value?.text}
                                            onClick={(newCurrentLevel) =>
                                              thirdLevelHandle(newCurrentLevel)
                                            }
                                            isDropdown={
                                              secondLevelactive === grandchild.Link?.jss?.value?.text
                                            }
                                            mobile
                                          />
                                        )}
                                        {grandchild.Link?.jss?.value?.text === currentLevel ? (
                                          <ul
                                            ref={secondLevelDropdownRef}
                                            className="dropdown-thirdlevelmenu-mobile"
                                          >
                                            {isActiveThirdLevelMenu &&
                                              grandchild.children?.map((grandgrandchild) => (
                                                <li>
                                                  <PrestoLink field={grandgrandchild.Link?.jss} />
                                                </li>
                                              ))}
                                          </ul>
                                        ) : null}
                                      </>
                                    )}
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                    </li>
                  ))}

                  <li>
                    <div className="sign-out-link">
                      <a
                        tabIndex="0"
                        role="button"
                        onClick={handleLogoutHandler}
                        onKeyPress={handleLogoutHandler}
                      >
                        {props.t('topNavigationSignOut', { lng: langCode })}
                      </a>
                    </div>
                  </li>
                  <li>
                    <a
                      role="button"
                      tabIndex="0"
                      onClick={changeLanguage}
                      onKeyPress={changeLanguage}
                    >
                      {props.t('topNavigationLanguageSwitcherMobile', { lng: langCode })}
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default withTranslation()(TopNavigationAuthenticated);
