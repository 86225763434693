import React, { useRef, useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import downIcon from '../../assets/images/down.svg';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import './googleWallet.scss';
import './accordion.scss';

const Accordion = ({ fields, t }) => {
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);
  const onClickSelect = () => openDropdown(!isArrowIconClicked);
  const iconText = isArrowIconClicked ? t('expandA11y') : t('collapseA11y');
  const [showAccordianWrapper, setshowAccordianWrapper] = useState(false);

  useEffect(() => {
    if (fields?.IsAccordianWrapper.value) {
      setshowAccordianWrapper(true);
    }
  }, [fields]);

  useEffect(() => {
    if (fields?.accordionOpenState.value) {
      openDropdown(true);
    }
    if (window.location.hash === `#${fields?.ID?.value}`) {
      openDropdown(true);
    }
  }, []);
  let sectionWrapperClass = showAccordianWrapper ? 'accordion-staticpage-section-wrapper' : '';
  let sectionClass = fields?.CssClass?.value ? fields?.CssClass?.value : 'accordion-section';
  let sectionId = fields?.ID && fields?.ID?.value ? fields?.ID?.value : '';
  return (
    <div className={sectionWrapperClass}>
      <div className={sectionClass} id={sectionId}>
        <button
          onClick={onClickSelect}
          aria-expanded={isArrowIconClicked}
          className="accordion-heading"
          id={fields?.AccordionId && fields?.AccordionId?.value ? fields?.AccordionId?.value : ''}
          aria-controls={
            fields?.AccordionRegionId && fields?.AccordionRegionId?.value
              ? fields?.AccordionRegionId?.value
              : ''
          }
        >
          <p>
            <RichText field={fields?.heading} />
          </p>
          <img alt="" className={isArrowIconClicked ? 'up' : ''} src={downIcon} />
        </button>
        {isArrowIconClicked && (
          <div
            className="accordion-content"
            id={
              fields?.AccordionRegionId && fields?.AccordionRegionId?.value
                ? fields?.AccordionRegionId?.value
                : ''
            }
            aria-labelledby={
              fields?.AccordionId && fields?.AccordionId?.value ? fields?.AccordionId?.value : ''
            }
          >
            <RichText field={fields?.description} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Accordion);
