import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Header } from '../../molecules';
import PayIframe from './PayIframe';

const UnpaidFares1 = (props) => {
  const { fields, state, setState, toNextStep, toPreviousStep, t } = props;
  return (
    <>
      <Header centered headerTitleA11y={fields.payUnpaidFareHeadingA11y.value}>
        <Text field={fields.payUnpaidFareHeading} />
      </Header>
      <PayIframe
        state={state}
        setState={setState}
        fields={fields}
        toPreviousStep={toPreviousStep}
        toNextStep={toNextStep}
        t={t}
      />
    </>
  );
};

export default UnpaidFares1;
