import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';

import { Button, IconButton } from '../../atoms';
import printIcon from '../../assets/images/printer.svg';
import Heading from '../Heading';
import { redirectToUrl } from '../../services/utils';

function ContactlessUnpaidFareError({
  fields,
  backToHome,
  retryCurrentPaymant,
  isThresholdReached,
  getUnpaidFareContent,
}) {
  const goToLink = () => {
    redirectToUrl(fields?.LearnMoreLink?.value?.href, false);
  };
  return (
    <div className="unpaid-fare-error-container">
      <Heading
        fields={{ Title: fields?.PaymentFailureTitle, ID: { value: 'unpaid-fare-failure-title' } }}
      />
      <div className="unpaid-fare-error-content" role="alert">
        <div className="unpaid-fare-error--title">
          <span id="message">{fields?.FailureMessage?.value}</span>
        </div>
        <div className="unpaid-fare-error--subtext">
          <span id="message">
            {isThresholdReached
              ? fields?.SigninDeclineErrorMessage?.value
              : fields?.RetryBodyText?.value}
          </span>
        </div>
        <a
          className="unpaid-fare-error--sublink"
          tabIndex="0"
          role="button"
          onKeyPress={() => null}
          onClick={goToLink}
        >
          {fields?.LearrnMoreLinkText?.value}
        </a>
      </div>
      {getUnpaidFareContent()}
      <div className="unpaid-fare-error--footer">
        <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="-1">
          Print
        </IconButton>
        <div className="unpaid-fare--float-right">
          <Button white={isThresholdReached ? false : true} firstOrder onClick={() => backToHome()}>
            <Text field={fields?.BackToHomeButtonText} />
          </Button>
          {!isThresholdReached ? (
            <Button onClick={() => retryCurrentPaymant()}>
              <Text field={fields?.RetryButtonText} />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ContactlessUnpaidFareError;
