import React from 'react';

import { withTranslation } from 'react-i18next';
import './heroBannerWithBackground.scss';
import { Text, Image, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

const HeroBannerWithBackground = (props) => {
  return (
    <div className="home-banner">
      <div id={props.fields.ID?.value} className="banner-inner">
        <div className="container-fluid">
          <div className="slogan">
            <h1>
              <Text field={props.fields.Title} />
            </h1>
            <div className="row heroimage03">
              <Image
                alt={
                  props.fields.BackgroundImage.value.alt
                    ? props.fields.BackgroundImage.value.alt
                    : ''
                }
                media={props.fields.BackgroundImage}
                loading="lazy"
              />
            </div>
            <p className="prestoLink">
              <RichText field={props.fields.Description} />
            </p>
          </div>
          <div className="payment-banner">
            <p>
              <Text field={props.fields.SecondaryDescription} />
            </p>
            <Image
              alt={props.fields.SmallImage.value.alt ? props.fields.SmallImage.value.alt : ''}
              media={props.fields.SmallImage}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(HeroBannerWithBackground);
