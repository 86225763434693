import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { Placeholder, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';

import api from '../../services/api';
import {
  getLanguageLcid,
  shallowObjEqual,
  isFrench,
  formatDate,
  blockTransactionHistoryEntry,
} from '../../services/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import TransactionGridItem from './TransactionGridItem';
import TransactionFilters from './TransactionFilters';
import filterIcon from '../../assets/images/Filter.svg';
import expandIcon from '../../assets/images/down.svg';
import calendarIcon from '../../assets/images/Calendar.svg';
import searchIcon from '../../assets/images/search_empty.svg';
import { ExportButton, ButtonBlock, PrintButton, IconButton } from '../../atoms';
import { ConnectionError, Note, Pagination } from '../../molecules';
import transactionHistoryConsts from '../../constants/transactionHistory';
import mediaTypes from '../../constants/mediaTypes';
import './transactionHistory.scss';
import TA from '../../constants/transitAgency.constants';

const TransactionHistoryPresto = ({ t, fields, rendering }) => {
  const defaultFiltersState = transactionHistoryConsts.defaultPrestoTxFiltersState;
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isFilters, setIsFilters] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [items, setItems] = useState({ items: [], total: 0 });
  const [pageNumber, setPageNumber] = useState(1);
  const [sorting, setSorting] = useState('DESC');
  const [filters, setFilters] = useState(defaultFiltersState);
  const selectedCard = useSelector((state) => state.user.selectedCard);
  const configKeys = useSelector((state) => state.settings.configKeys);
  const [txData, setTxData] = useState([]);
  const [csvHeader, setCsvHeader] = useState('');
  const langCode = isFrench();
  const isGoogleWallet = selectedCard.mediaType === mediaTypes.CRDNC;
  function updateHistory(data) {
    setIsLoadingData(true);
    api
      .getTransactionHistory({
        MediaId: selectedCard.mediaId,
        LanguageCode: getLanguageLcid(),
        PageNumber: pageNumber,
        SortingOrder: sorting,
        PageCount: 10,
        ...filters,
        FromDate: filters.PeriodId.toString() === '6' ? filters.FromDate : '',
        ToDate: filters.PeriodId.toString() === '6' ? filters.ToDate : '',
        PeriodId: filters.PeriodId.toString() === '6' ? 5 : filters.PeriodId,
        TransactionCategoryId:
          filters.TransactionCategoryId.length === 7 ? '0' : filters.TransactionCategoryId,
        ...data,
      })
      .then((res) => {
        if (res.data.Success) {
          const itemsObject = { items: res.data.TravelList };
          if (res.data.PageCount) {
            itemsObject.total = res.data.PageCount;
          }
          setTxData(res.data.TravelList);
          setItems({ ...items, ...itemsObject });
          setHasConnectionError(false);
        } else {
          setItems({ items: [], total: 0 });
          setHasConnectionError(true);
        }
        setIsLoadingData(false);
      })
      .catch(() => {
        setHasConnectionError(true);
        setIsLoadingData(false);
      });
  }
  const onExport = (customerData) => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    let headers = csvHeader.toString();
    csvContent += `${headers}\r\n`;
    let exportableData = customerData.filter((el) => !blockTransactionHistoryEntry(el));
    let rowData = exportableData.reduce((previous, next) => {
      let options = [];

      options.push(
        ...[
          formatDate(next.Date, 'MM/DD/YYYY h:mm:ss a'),
          next.SequenceNumber,
          next.ServiceProviderName,
          next.Location,
          next.Type,
          next.ServiceClass,
          next.DiscountSum,
          next.Amount,
          next.Balance,
        ]
      );
      previous.push(options);

      return previous;
    }, []);
    csvContent += rowData.map((e) => e.join(',')).join('\n');

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('id', 'csv_download_link');
    link.setAttribute('download', 'TX history.csv');
    document.body.appendChild(link);
    link.click();

    let elem = document.getElementById('csv_download_link');
    elem.parentNode.removeChild(elem);
  };
  const getAllDataToExport = () => {
    api
      .getTransactionHistory({
        MediaId: selectedCard.mediaId,
        LanguageCode: getLanguageLcid(),
        PageNumber: 1,
        SortingOrder: sorting,
        PageCount: 10 * Number(items?.total),
        ...filters,
        FromDate: filters.PeriodId.toString() === '6' ? filters.FromDate : '',
        ToDate: filters.PeriodId.toString() === '6' ? filters.ToDate : '',
        PeriodId: filters.PeriodId.toString() === '6' ? 5 : filters.PeriodId,
        TransactionCategoryId:
          filters.TransactionCategoryId.length === 7 ? '0' : filters.TransactionCategoryId,
      })
      .then((res) => {
        if (res.data.Success) {
          onExport(res.data.TravelList);
        }
      });
  };
  function clearFilters() {
    setFilters(defaultFiltersState);
  }
  useEffect(() => {
    if (langCode) {
      const frenchheader = configKeys
        .filter((item) => item.key === 'PrestoTxHistoryFrenchHeaders')
        .map((config) => config.setting);
      setCsvHeader(frenchheader);
    } else {
      const englishheader = configKeys
        .filter((item) => item.key === 'PrestoTxHistoryEnglishHeaders')
        .map((config) => config.setting);
      setCsvHeader(englishheader);
    }
  }, []);

  useEffect(() => {
    if (selectedCard.mediaType !== mediaTypes.CRDNC) updateHistory();
  }, [pageNumber, sorting]);

  useDidMountEffect(() => {
    if (pageNumber === 1) {
      updateHistory();
    } else {
      setPageNumber(1);
    }
    setHasFiltersApplied(!shallowObjEqual(defaultFiltersState, filters));
  }, [filters]);

  let paymentbyCardTx = items?.items?.filter((el) => el.TrxTypeId === 22);
  let displayableData = items?.items?.filter((el) => !blockTransactionHistoryEntry(el));
  displayableData = displayableData.map((trans) => {
    if (
      (trans.TrxTypeId === 1 &&
        (trans.LoadType === '5' || trans.LoadType === '6') &&
        (trans.ServiceProviderID === TA.GO_TRANSIT || trans.ServiceProviderID === TA.UP_EXPRESS)) ||
      (trans.TrxTypeId === 2 && (trans.LoadType === '1' || trans.LoadType === '2')) ||
      (trans.TrxTypeId === 2 && (trans.LoadType === '4' || trans.LoadType === '6')) ||
      (trans.TrxTypeId === 2 && (trans.LoadType === '5' || trans.LoadType === '7'))
    ) {
      let bal = paymentbyCardTx.filter((x) => x.SequenceNumber === trans.SequenceNumber);
      return {
        ...trans,
        Balance: bal?.length > 0 ? bal[0].Balance : trans.Balance,
      };
    } else {
      return trans;
    }
  });
  return (
    <div className="transactionHistory">
      <Placeholder name="presto-system-status-message" rendering={rendering} />
      <Placeholder name="presto-my-products-balance" rendering={rendering} />
      <Note withIcon cssClass="note-top-margin" noteA11y={fields?.historyNoteA11y?.value}>
        <RichText field={fields?.historyNote} />
      </Note>
      {!isGoogleWallet && (
        <>
          <div className="transactionHistory-buttons">
            <IconButton
              onClick={() => setSorting(sorting === 'ASC' ? 'DESC' : 'ASC')}
              className="transactionHistory-buttons-sorting desktopOnly"
              icon={calendarIcon}
            >
              <Text field={fields?.sortLabel} />
              {sorting === 'ASC' ? (
                <Text field={fields?.sortOldestLabel} />
              ) : (
                <Text field={fields?.sortLatestLabel} />
              )}
            </IconButton>
            <IconButton
              onClick={() => setIsFilters(!isFilters)}
              className="transactionHistory-buttons-filters"
              icon={filterIcon}
              ariaExpanded={isFilters}
            >
              <Text field={fields?.filterLabel} />
            </IconButton>
            <IconButton
              onClick={() => setIsExpand(!isExpand)}
              className="transactionHistory-buttons-expand"
              icon={expandIcon}
              ariaLabel={isExpand ? fields?.collapseLabelA11y.value : fields?.expandLabelA11y.value}
              ariaExpanded={isExpand}
            >
              {isExpand ? (
                <Text field={fields?.collapseLabel} />
              ) : (
                <Text field={fields?.expandLabel} />
              )}
            </IconButton>
          </div>
          {isFilters ? (
            <TransactionFilters
              setIsFilters={setIsFilters}
              filters={filters}
              setFilters={setFilters}
              fields={fields}
              clearFilters={clearFilters}
              t={t}
            />
          ) : null}
          {hasFiltersApplied ? (
            <div className="transactionHistory-activeFilters">
              <p className="transactionHistory-activeFilters-text">
                <Text field={fields?.filterAppliedLabel} />
              </p>
              <button className="transactionHistory-activeFilters-clear" onClick={clearFilters}>
                <Text field={fields?.clearFiltersLabel} />
              </button>
            </div>
          ) : null}
          <div className="transactionHistory-grid">
            {displayableData?.map((data, index) => (
              <TransactionGridItem
                key={`${index}${data.Date}`}
                expand={isExpand}
                data={data}
                fields={fields}
              />
            ))}
          </div>
          {displayableData?.length ? (
            <ButtonBlock className="Tx-history-export">
              <ExportButton onClick={getAllDataToExport} />
              <PrintButton onClick={() => window.print()} />
              <ButtonBlock right>
                {items.total ? (
                  <Pagination
                    total={items.total}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                ) : null}
              </ButtonBlock>
            </ButtonBlock>
          ) : null}
          {displayableData?.length === 0 && !isLoadingData && !hasConnectionError ? (
            <div className="transactionHistory-grid no-transactions">
              <img src={searchIcon} alt="" />
              <p aria-label={fields?.emptyHistoryTextA11y?.value}>
                <Text field={fields?.emptyHistoryText} />
              </p>
            </div>
          ) : null}
          {hasConnectionError ? (
            <ConnectionError
              title={t('connectionErrorTitle')}
              titleA11y={t('connectionErrorTitle')}
              message={t('connectionErrorTransactionHistoryMessage')}
              messageA11y={t('connectionErrorTransactionHistoryMessage')}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default withTranslation()(TransactionHistoryPresto);
