import React, { useEffect, useState } from 'react';
import { PrestoLink } from '../../atoms';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { merge } from 'lodash';

import api from '../../services/api';
import TransactionGridItem from '../TransactionHistoryContactless/TransactionGridItem';
import {
  getLanguageAtsId,
  isOneOf,
  redirectToUrl,
  formatDecimalWithCurrency,
  convertToDollars,
  downloadContactlessCsvFile,
  isFrench,
} from '../../services/utils';
import { InlineButtonBlock, Title } from '../../molecules';
import downIcon from '../../assets/images/down.svg';
import upIcon from '../../assets/images/up.svg';
import exportIcon from '../../assets/images/Terms-And-Conditions.svg';
import printIcon from '../../assets/images/printer.svg';
import { IconButton, Button, Loader, MediaImage, ButtonBlock } from '../../atoms';
import searchIcon from '../../assets/images/search_empty.svg';
import './contactlessTransactionHistory.scss';
import Heading from '../Heading';
import ParagraphContent from '../ParagraphContent';
import handleLogOut from '../../services/handleLogOut';
import { setTransitAgencyLogos } from '../../redux/products/actions';
import routes from '../../constants/routes';
import Debit from '../../assets/images/Debit.png';
import {
  setContactlessTransItems,
  setMultiUnpaidFareProps,
  setPaymentsToVerify,
  setUnpaidFares,
  updateRetryCounter,
} from '../../redux/contactless/actions';
import MEDIA_SUB_TYPES from '../../constants/mediaSubTypes';

const EN_HEADER_ID = 'ContactlessOpTxHistoryEnglishHeaders';
const FR_HEADER_ID = 'ContactlessOpTxHistoryFrenchHeaders';
const ITEMS_PER_PAGE = 10;
const DAYS_TO_SUBRACT = 7;
const ContactlessTransactionHistory = (props) => {
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [sorting, setSorting] = useState('DESC');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isCardBlocked, setIsCardBlocked] = useState(false);
  const [isInteracCard, setIsInteracCard] = useState(false);
  const [showVerifySuccess, setShowVerifySuccess] = useState(false);
  const [showVerifyBlock, setShowVerifyBlock] = useState(false);
  const [csvHeader, setCsvHeader] = useState('');
  const [verifyError, setVerifyError] = useState('');
  const [isInteracVerifyCardFlowVisible, setIsInteracVerifyCardFlowVisible] = useState(false);
  const userInfoData = useSelector((state) => {
    if (state?.contactless?.userInfoData && state?.contactless?.userInfoData?.CardType) {
      return state.contactless.userInfoData;
    }
  });
  const configKeys = useSelector((state) => state.contactless.configKeys);
  const transactionHistory = useSelector((reduxState) => reduxState.contactless.transactionHistory);
  const unpaidFares = useSelector((reduxState) => reduxState.contactless.unpaidFares);
  const paymentsToVerify = useSelector((reduxState) => reduxState.contactless.paymentsToVerify);

  const [totalUnpaidFares, setTotalUnpaidFares] = useState(0);
  const shortenPan = userInfoData?.MaskedPan?.slice(userInfoData.MaskedPan.length - 4);
  const dispatch = useDispatch();
  const langCode = isFrench();
  useEffect(() => {
    if (langCode) {
      const frenchheader = configKeys
        .filter((item) => item.key === FR_HEADER_ID)
        .map((config) => config.setting);
      setCsvHeader(frenchheader);
    } else {
      const englishheader = configKeys
        .filter((item) => item.key === EN_HEADER_ID)
        .map((config) => config.setting);
      setCsvHeader(englishheader);
    }

    //console.log("card type: " + userInfoData?.CardType);
    if (userInfoData?.CardType === 'OPENP_INTR') {
      setIsInteracCard(true);
    } else {
      setIsInteracCard(false);
    }
  }, []);

  useEffect(() => {
    //console.log("isInteracCard Flag after2: " + isInteracCard);
    if (isInteracCard) setIsInteracVerifyCardFlowVisible(true);
    if (!isInteracCard) setIsInteracVerifyCardFlowVisible(false);
  }, [isInteracCard]);

  useEffect(() => {
    //console.log("isInteracVerifyCardFlowVisible Flag after2: " + isInteracVerifyCardFlowVisible);
  }, [isInteracVerifyCardFlowVisible]);

  const checkIsCardBlocked = (data) => {
    const mediaInfo = data.media.mediaInfo;
    return (
      isOneOf(
        [
          'FAILED_TOO_MANY_INSPECTIONS',
          'CHARGEBACK_FRAUD',
          'BACK_OFFICE_OVERRIDE',
          'SUSPECTED_COUNTERFEIT',
        ],
        mediaInfo.statusReason
      ) && mediaInfo.mediaStatus === 'HOTLISTED'
    );
  };
  function fetchPaymentsToVerify() {
    let payload = {
      MediaReferenceId: userInfoData.MediaReferenceId,
      cardType: userInfoData?.CardType,
      StartTime: moment().subtract(DAYS_TO_SUBRACT, 'days').toDate().toISOString(),
      EndTime: moment().toDate().toISOString(),
      Language: getLanguageAtsId,
    };
    setIsLoadingData(true);
    api
      .getPaymentsToVerify(payload)
      .then((res) => {
        setIsLoadingData(false);
        if (res.data.PaymentsToVerify?.length > 0) {
          dispatch(setPaymentsToVerify(res.data.PaymentsToVerify));
          setShowVerifyBlock(true);
          if (isInteracCard) {
            setIsInteracVerifyCardFlowVisible(true);
          }
        } else {
          setShowVerifyBlock(false);
          dispatch(setPaymentsToVerify(res.data.PaymentsToVerify));
        }
      })
      .catch(() => {
        setIsLoadingData(false);
        setHasConnectionError(true);
      });
  }
  const postAPIfunctions = (response) => {
    let transactionHistoryTemp = {
      ...response,
    };
    if (response && response?.transactions?.length) {
      const transactionItems = response?.transactions[0];
      const transactionItemsnew = response?.textMapping;
      if (checkIsCardBlocked(transactionItems)) setIsCardBlocked(true);
      if (transactionHistory.HasMoreItems) {
        const mergedListOfItems = {};
        const mergedtextmapping = {};
        const newItemsmapping = { ...transactionItemsnew };
        const newItems = [...transactionItems.payments];
        const newServicemapping = { ...transactionItemsnew.serviceProvider };

        if (newItems.length > 10) {
          if (sorting === 'DESC') {
            newItems.pop();
            newItemsmapping.pop();
          } else {
            newItems.shift();
            newItemsmapping.shift();
          }
        }
        mergedtextmapping.locations = merge(mergedtextmapping.locations, newItemsmapping.locations);

        mergedListOfItems.payments = [...newItems];

        mergedtextmapping.serviceProvider = {
          ...mergedtextmapping.serviceProvider,
          ...newServicemapping,
        };
        transactionHistoryTemp.transactions = {
          ...transactionHistoryTemp?.transactions[0],
          payments: mergedListOfItems,
        };
        transactionHistoryTemp.textMapping = mergedtextmapping;
      } else {
        transactionHistoryTemp.transactions = {
          ...transactionHistoryTemp?.transactions[0],
          payments: transactionItems.payments,
        };
        transactionHistoryTemp.textMapping = transactionItemsnew;
      }
    } else {
      transactionHistoryTemp.transactions = {
        ...transactionHistoryTemp?.transactions,
        payments: [],
      };
      transactionHistoryTemp.textMapping = [];
    }
    dispatch(setContactlessTransItems(transactionHistoryTemp));
  };

  const onExport = () => {
    let payload = {
      MediaInstanceIds: userInfoData?.MediaKeyDetails?.map((el) => el.mediaInstanceId).join('|'),
      cardType: userInfoData?.CardType,
      StartTime: moment().subtract(DAYS_TO_SUBRACT, 'days').toDate().toISOString(),
      EndTime: moment().toDate().toISOString(),
      Records: ITEMS_PER_PAGE,
      SortDescending: sorting === 'DESC',
      Language: getLanguageAtsId(),
      MediaReferenceId: userInfoData?.MediaReferenceId,
    };
    api
      .getExportContactlessTransactionHistory(payload)
      .then((response) => {
        downloadContactlessCsvFile(
          csvHeader,
          response?.data,
          `tx_history_${userInfoData?.MediaReferenceId}.csv`
        );
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  function CalculateTotalUnpaidFares(transactions) {
    let sumOfUnpaidFares = 0;
    let tempMultipleUnpaidFareProps = [];
    if (transactions?.length) {
      for (let i = 0; i < transactions.length; i += 1) {
        for (let j = 0; j < transactions[i].payments.length; j += 1) {
          tempMultipleUnpaidFareProps.push({
            id: transactions[i].payments[j].uuid,
            isPaid: false,
            selected: false,
          });
          sumOfUnpaidFares += Number.parseInt(
            transactions[i].payments[j].lines.reduce((acc, val) => acc + val.amount, 0),
            10
          );
        }
      }
    }
    dispatch(setMultiUnpaidFareProps(tempMultipleUnpaidFareProps));
    dispatch(updateRetryCounter(0));
    return sumOfUnpaidFares;
  }
  const getUnPaidFares = () => {
    setIsLoadingData(true);
    api
      .getContactlessUnpaidFares({
        MediaReferenceId: userInfoData.MediaReferenceId,
        CardType: userInfoData?.CardType,
        Language: getLanguageAtsId(),
        StartTime: moment().subtract(DAYS_TO_SUBRACT, 'days').toDate().toISOString(),
        EndTime: moment().toDate().toISOString(),
      })
      .then((response) => {
        setIsLoadingData(false);
        if (response.data?.transactions?.length) {
          dispatch(setUnpaidFares(response.data));
        }
      })
      .catch((err) => {
        setIsLoadingData(false);
      });
  };
  const getContactlessTransactionHistoryData = () => {
    let payload = {
      MediaReferenceId: userInfoData.MediaReferenceId,
      cardType: userInfoData?.CardType,
      StartTime: moment().subtract(DAYS_TO_SUBRACT, 'days').toDate().toISOString(),
      EndTime: moment().toDate().toISOString(),
      Records: ITEMS_PER_PAGE,
      SortDescending: sorting === 'DESC',
      Language: getLanguageAtsId,
    };
    setIsLoadingData(true);
    api
      .getContactlessTransactionHistory(payload)
      .then((response) => {
        setIsLoadingData(false);
        if (response.data) {
          setContactlessTransItems(response.data);
          postAPIfunctions(response.data);
        }
      })
      .catch((err) => {
        //console.log(err);
        setIsLoadingData(false);
      });
  };
  const setTransitPassLogos = () => {
    api
      .getTransitAgencyLogos()
      .then(({ data }) => {
        dispatch(setTransitAgencyLogos(data));
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const verifyCardAPI = () => {
    setIsLoadingData(true);
    setVerifyError('');
    let payload = {
      SalesOrderUid: paymentsToVerify[0]?.Uuid,
      MediaInstanceId: paymentsToVerify[0]?.MediaInstanceId,
      MediaType: paymentsToVerify[0]?.MediaType,
      MediaSubType: paymentsToVerify[0]?.MediaSubType,
    };
    api
      .processContactlessUnpaidFare(payload)
      .then((response) => {
        //console.log(response);
        setIsLoadingData(false);
        if (response.status === 200 && response?.data?.Success) {
          setShowVerifySuccess(true);
        } else {
          setVerifyError(props.fields?.verifyCardErrorCVRCTAStep0301?.value);
        }
      })
      .catch((error) => {
        //console.log(error);
        setIsLoadingData(false);
        setVerifyError(props.fields?.verifyCardErrorCVRCTAStep0301?.value);
      });
  };
  const showVerifyCardMessage = () => {
    let title = props.fields.verifyCardAlertTitle;
    if (showVerifySuccess)
      return (
        <div>
          <div className="verify-card-success" role="alert">
            <div className="verify-card-success-title">
              <span>{props.fields.verifyCardSuccessTitle.value}</span>
            </div>
            <div className="verify-card-success-dec">
              {props.fields.verifyCardSuccessText.value}
            </div>
            <div className="verify-card-success-footer">
              <Button firstOrder onClick={() => setShowVerifyBlock(false)}>
                <Text field={{ value: 'DISMISS' }} />
              </Button>
            </div>
          </div>
        </div>
      );
    if (verifyError) {
      return (
        <div>
          <div className="verify-card-error" role="alert">
            <div className="verify-card-error-title">
              <span>{props.fields.verifyCardErrorTitle.value}</span>
            </div>
            <div className="verify-card-error-dec">
              <p>{verifyError}</p>
              <Link className="main-link" field={props.fields.verifyCardErrorLearnMoreLink} />
            </div>
            <div className="verify-card-error-footer">
              <Button firstOrder onClick={() => verifyCardAPI()}>
                <Text field={props.fields.verifyCardRetryVerificationButton} />
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (isInteracVerifyCardFlowVisible)
      return (
        <div className="contactlessTransactionHistory-unpaidFare-error">
          <div className={`content-error-block`}>
            <div title={props.fields?.verifyCardInteracTitle?.value} noMargin>
              <div className="content-error-block-title" noMargin>
                {props.fields?.verifyCardInteracTitle?.value}
              </div>

              <div className="contactless-unpaid-fare--desc">
                <RichText field={props.fields?.verifyCardInteracDescription} />
              </div>

              <div className="contactlessTransactionHistory-unpaidFare-link">
                <PrestoLink field={props.fields?.verifyCardInteracLearnMoreLink} />
              </div>
            </div>
          </div>
        </div>
      );

    if (!isInteracVerifyCardFlowVisible)
      return (
        <div>
          <div className="tx-verify-card-container">
            <div className="tx-verify-card--title">
              <span id="message">{title.value}</span>
            </div>
            <div className="tx-verify-card--info">
              {/* <MediaImage cardImgSrc={Debit} sm cardImgAlt="card" /> */}
              <img src={Debit} className="mediaImage-sm" alt="" />
              <div>
                {MEDIA_SUB_TYPES[userInfoData?.CardType]}...{shortenPan}
              </div>
            </div>
            <ParagraphContent fields={{ Description: props.fields.verifyCardAlertText }} />
            <div className="tx-verify-card-footer">
              <Button onClick={verifyCardAPI}>
                <Text field={props.fields.verifyCardAlertButton} />
              </Button>
            </div>
          </div>
        </div>
      );
  };
  const showBlockCardMessage = () => {
    let title = props.fields.blockedCardErrorText;
    return (
      <div>
        <div className="tx-block-card-container">
          <div className="tx-block-card--title">
            <span id="message">{title.value}</span>
          </div>
          <ParagraphContent fields={{ Description: props.fields.blockedCardErrorTitle }} />
        </div>
      </div>
    );
  };
  const manageCollapseHandler = (e) => {
    if (e.keyCode == 13) {
      setIsExpand(!isExpand);
    }
  };

  useEffect(() => {
    if (userInfoData?.MediaReferenceId != '' || userInfoData?.MediaKeyDetails != '') {
      //console.log("inside if confdition");
      getContactlessTransactionHistoryData();
      getUnPaidFares();
      setTransitPassLogos();
      fetchPaymentsToVerify();
    } else {
      //console.log("else block");
      transactionHistory.transactions.payments = [];
    }
  }, []);
  useEffect(() => {
    setTotalUnpaidFares(CalculateTotalUnpaidFares(unpaidFares?.transactions));
  }, [unpaidFares]);
  const { textMapping, transactions } = transactionHistory;
  return (
    <div className="contactless-transaction-history">
      {showVerifyBlock ? showVerifyCardMessage() : null}
      {isCardBlocked ? showBlockCardMessage() : null}
      {transactions.payments &&
      !transactions.payments.length &&
      !isLoadingData &&
      !hasConnectionError ? (
        <div className="contactlessTransactionHistory-grid no-transactions">
          <Title>
            <Text field={props.fields.NoTransactionHistoryHeadline} />
          </Title>
          <p aria-label={props.fields.NoTransactionHistoryContactlessText?.value}>
            <RichText field={props.fields.NoTransactionHistoryContactlessText} />
          </p>
          <InlineButtonBlock
            onClick={() => handleLogOut(dispatch)}
            buttonText={props.fields.SignOutText}
            buttonTextA11y={props?.fields?.SignOutText?.value}
          />
        </div>
      ) : (
        [
          <div>
            <div className="contactless-transaction-history-header">
              <Heading fields={{ Title: props.fields.heading }} />
              <ParagraphContent
                fields={{
                  Description: {
                    value: `<p class="trans-history-desc">${props?.fields?.transactionHistorySubText?.value}</p>`,
                  },
                }}
              />
            </div>
          </div>,
          <div>
            {totalUnpaidFares && totalUnpaidFares > 0 ? (
              <div className="contactlessTransactionHistory-unpaidFare-error">
                <div className="content-error-block " role="alert">
                  <div className="contactless-unpaid-fare--title">
                    {props?.fields?.unpaidFareTitle?.value}
                    <div className="content-error-block-title--right">
                      {formatDecimalWithCurrency(convertToDollars(totalUnpaidFares))}
                    </div>
                  </div>
                  <div className="contactless-unpaid-fare--desc">
                    <ParagraphContent
                      fields={{ Description: props.fields.unpaidFareDescription }}
                    />
                  </div>
                  <a
                    className="unpaid-fare-total-link"
                    href={props.fields.verifyCardErrorLearnMoreLink.value?.href}
                  >
                    {props?.fields?.LearMoreText?.value}
                  </a>
                  <div className="tx-verify-card-footer">
                    <Button onClick={() => redirectToUrl(`${routes.contactlessUnpaidFares}`)}>
                      <Text field={props.fields.payNowButton} />
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>,
          <div>
            <div className="cth-expand-all">
              <div
                className="contactlessTransactionGridItem-control cth-expand-btn"
                tabIndex="0"
                onClick={() => setIsExpand(!isExpand)}
                onKeyDown={manageCollapseHandler}
                aria-expanded={isExpand}
                aria-label={
                  isExpand
                    ? props.fields.collapseTransactionButtonA11y.value
                    : props.fields.expandTransactionButtonA11y.value
                }
                role="button"
              >
                {isExpand ? (
                  <>
                    <img alt={props.fields.collapseTransactionButtonA11y.value} src={upIcon} />
                    <div>{props.fields.collapseLabel.value}</div>
                  </>
                ) : (
                  <>
                    <img alt={props.fields.expandTransactionButtonA11y.value} src={downIcon} />
                    <div>{props.fields.expandLabel.value}</div>
                  </>
                )}
              </div>
            </div>
          </div>,
          <div>
            {transactions?.payments?.map((payment) => {
              return (
                <TransactionGridItem
                  key={payment.acquirerSalesOrderId}
                  fields={props.fields}
                  textMapping={textMapping}
                  data={payment}
                  expand={isExpand}
                  isInteracCard={isInteracCard}
                  processUnpaidFare={(data) => {
                    redirectToUrl(`${routes.contactlessUnpaidFares}?id=${data.uuid}`);
                  }}
                />
              );
            })}
          </div>,
          <ButtonBlock>
            <IconButton className="export-margin" onClick={onExport} icon={exportIcon}>
              {props.fields.exportButtonText.value}
            </IconButton>
            <IconButton className="print-hide" onClick={() => window.print()} icon={printIcon}>
              {props.fields.printButtonText.value}
            </IconButton>
          </ButtonBlock>,
        ]
      )}
      {hasConnectionError ? (
        <div className="contactlessTransactionHistory-grid no-connection">
          <div className="connection-title">
            <p aria-label={props.fields.connectionErrorTitleA11y} className="title">
              <Text field={props.fields.connectionErrorTitle} />
            </p>
            <p aria-label={props.fields.connectionErrorTextA11y}>
              <Text field={props.fields.connectionErrorText} />
            </p>
          </div>
        </div>
      ) : null}
      {isLoadingData ? <Loader message={props.fields.historyLoadingSpinnerText?.value} /> : null}
    </div>
  );
};

export default ContactlessTransactionHistory;
