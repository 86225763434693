import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBlock } from '../../../atoms';
import { CheckoutGrid } from '../../../molecules';
import { setDeactivateCardType } from '../../../redux/user/actions';
import { formatDecimalWithCurrency } from '../../../services/utils';
import './DeactivateAndTransferNew0.scss';

const DeactivateAndTransferNew0 = ({
  state,
  toNextStep,
  fields,
  userInfo,
  isTransferBalance,
  step,
}) => {
  const visibleId = userInfo?.selectedCard?.visibleId;
  const nickname = userInfo?.selectedCard?.nickName;
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias =
    fareMedias && fareMedias.length > 0 && fareMedias.find((data) => data.visibleId === visibleId);
  const currentBalance = fareMedias?.currentBalance;
  const transitPass =
    fareMedias && fareMedias?.passes && fareMedias?.passes.length > 0 && fareMedias?.passes;
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  const subTotalValue = fields.PriceValue.value;
  const items = [
    {
      id: 1,
      image: fields.PrestoCardIcon.value,
      name: fields.SingleFarecard.value,
      quantity: 1,
      subTotal: subTotalValue.substring(1),
      productFamily: '',
      visibleId: '',
      serviceProvider: 'Presto',
      productFamily: 'Epurse',
    },
  ];
  let formattedBalance = formatDecimalWithCurrency(currentBalance);
  return (
    <>
      {isTransferBalance ? (
        ''
      ) : (
        <div className="deactivate-new-card-block">
          <div className="card-header">
            <Text field={fields.CardToDeactivate} />
          </div>
          <div className="card-content">
            <div className="card-Number">
              <RichText field={fields.PrestoCardNumberLable} />
              <span>
                <b>{visibleId}</b>
              </span>
            </div>
            <div className="card-nick-name">
              <RichText field={fields.CardNickNameLable} />
              <span>
                <b>{nickname}</b>
              </span>
            </div>
            <div className="card-balance">
              <RichText field={fields.BalanceLabel} />
              <span>
                <b>{formattedBalance}</b>
              </span>
            </div>

            <div className="card-transitPass">
              <RichText field={fields.TransitPassLabel} />
              {transitPass && transitPass.length > 0 ? (
                <div>
                  {transitPass.map((pass) => {
                    if (pass.ProductName !== null) {
                      return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                    }
                  })}
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      )}

      {step == 0
        ? state.fareMedias &&
          state.fareMedias.map((fareMedia, index) => {
            if (index == 0) {
              return (
                <div className="checkoutgrid-margin-bottom ">
                  <CheckoutGrid
                    fields={fields}
                    items={items}
                    fareMedia={fareMedia}
                    deactivate={true}
                    hideRemove={true}
                    step={step}
                  />
                </div>
              );
            }
          })
        : state.fareMedias &&
          state.fareMedias.map((fareMedia) => {
            if (fareMedia.visibleId === visibleId) {
              return (
                <div className="checkoutgrid-margin-bottom ">
                  <CheckoutGrid
                    fields={fields}
                    items={state.items}
                    fareMedia={fareMedia}
                    totalAmount={state.total}
                    deactivate={true}
                    hideRemove={true}
                  />
                </div>
              );
            }
          })}
      <ButtonBlock>
        <ButtonBlock right className="Deactivated-Button">
          <Button
            type="submit"
            buttonTextA11y=""
            onClick={() => {
              toNextStep();
            }}
          >
            <Text field={fields.NextCTALabel} />
          </Button>
          <Button buttonTextA11y="" onClick={() => dispatch(setDeactivateCardType(''))}>
            <Text field={fields.GoBackCTALabel} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default DeactivateAndTransferNew0;
