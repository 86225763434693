import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Iframe from 'react-iframe';

import { itemsAreLoading } from '../../redux/user/actions';
import {
  getShortLanguageCode,
  redirectToUrl,
  formatDecimalWithCurrency,
  convertToDollars,
  localstorageService,
} from '../../services/utils';
import monerisConstants from '../../constants/moneris';
import creditCardIcon from '../../assets/images/credit_card.svg';
import api from '../../services/api';
import { IconWithText, ButtonBlock, Button } from '../../atoms';
import { Error, CardBlock, ConnectionError } from '../../molecules';
import localStorageKeys from '../../constants/localStorageKeys';
import mediaSubTypes from '../../constants/mediaSubTypes';

const UnpaidFareTotal = (props) => {
  return <span>{formatDecimalWithCurrency(convertToDollars(props.unpaidAmount))}</span>;
};

const PayIframe = (props) => {
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const userInfo = useSelector((reduxState) => reduxState.user);
  const { fields, state, setState, toNextStep, t } = props;
  //const { unpaidFaresData, unpaidFareIndex } = state;
  const unpaidFaresData = JSON.parse(localstorageService().getItem(localStorageKeys.unpaidFaresData));
  const unpaidFareIndex = JSON.parse(localstorageService().getItem(localStorageKeys.unpaidFaresIndex));

  const dispatch = useDispatch();
  const customerId = userInfo.customerId;
  const accountId = userInfo.accountId;
  const sessionInstanceId = userInfo.sessionInstanceId;
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [hideIframe, setHideIframe] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const linkToTransactionHistory = fields.linkToTransactionHistory.value?.href;

  function refreshIframe() {
    document.getElementById('iframe').src += '';
    setConnectionError(false);
    setHideIframe(false);
  }

  function setErrorAndRefreshIframe(errorText) {
    setError(errorText);
    refreshIframe();
  }

  const verifyCard = (monerisToken, associatedMedia, selectedCard) => {
    const mediaInstanceIds = selectedCard.visibleId.split('|');
    const selectedCardMediaSubType = Object.keys(mediaSubTypes).find(
      (key) => mediaSubTypes[key] === selectedCard.type
    );

    if (
      associatedMedia.MediaReferenceId === selectedCard.mediaId &&
      associatedMedia.MediaIdentifiers.length === mediaInstanceIds.length &&
      associatedMedia.MediaIdentifiers.every(
        (item) =>
          mediaInstanceIds.indexOf(item.mediaInstanceId) > -1 &&
          item.mediaSubType === selectedCardMediaSubType &&
          item.mediaType === 'OPENP'
      )
    ) {
      dispatch(itemsAreLoading(true));
      api
        .contactlessRecoveryDept({
          MonerisToken: monerisToken,
          VerifyItem: [
            {
              MediaInstanceId: unpaidFaresData[unpaidFareIndex].mediaInstanceId,
              Uuid: unpaidFaresData[unpaidFareIndex].uuid,
              MediaSubType: selectedCardMediaSubType,
              MediaType: 'OPENP',
            },
          ],
        })
        .then((response) => {
          setState({
            ...state,
            ...response,
          });

          toNextStep();
        })
        .then(() => dispatch(itemsAreLoading(false)))
        .catch((error) => {
          dispatch(itemsAreLoading(false));
          //console.log('error in paying debt', error);
        });
    } else {
      dispatch(itemsAreLoading(false));
      setErrorAndRefreshIframe(t('unpaidFareCardDetailsDontMatch'));
    }
  };

  const handleIframeTask = (res) => {
    let iframeResponse;
    try {
      iframeResponse = JSON.parse(res.data);
    } catch (e) {
      return;
    }

    if (iframeResponse.StatusCode === monerisConstants.responses.success) {
      const monerisToken = iframeResponse?.DataKey;
      const associatedMedia = iframeResponse?.AssociatedMedia;

      verifyCard(monerisToken, associatedMedia, userInfo.selectedCard);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.unauthorized ||
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorE
    ) {
      setError('');
      setConnectionError(true);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededI ||
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededE
    ) {
      setError(t('monerisErrorTooManyAttempts'));
      setConnectionError(false);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorE
    ) {
      setErrorAndRefreshIframe(t('monerisErrorAuthenticationError'));
    } else if (iframeResponse.StatusCode === monerisConstants.responses.expiredCard) {
      setErrorAndRefreshIframe(t('monerisErrorExpiredCard'));
    } else {
      setErrorAndRefreshIframe(t('unpaidFareCardNotFound'));
    }
  };

  useEffect(() => {
    let stsAccessToken = localstorageService().getItem(localStorageKeys.stsAccessToken);
    setToken(stsAccessToken);
    window.addEventListener('message', handleIframeTask);
    return () => window.removeEventListener('message', handleIframeTask);
  }, []);

  return (
    <>
      <CardBlock
        title={fields?.unpaidFareAmountLabel?.value}
        titleA11y={fields?.unpaidFareAmountLabelA11y?.value}
        titleSectionRight={
          <UnpaidFareTotal unpaidAmount={unpaidFaresData[unpaidFareIndex]?.amount} />
        }
      >
        {error ? <Error title={error} /> : null}
        <RichText field={fields?.unpaidFareDescription} />

        <IconWithText text={userInfo.selectedCard?.nickName} iconSrc={creditCardIcon} isBold />

        <div>
          {configKeys &&
            token &&
            configKeys
              .filter((item) => item.key === 'Ats.GatewayUrl')
              .map((configKey) => (
                <Iframe
                  id="iframe"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  position="relative"
                  scrolling="no"
                  display={hideIframe ? 'none' : 'block'}
                  url={`${configKey.setting
                    }/DebtRecovery/${sessionInstanceId}/${getShortLanguageCode()}/${customerId}/${accountId}/${token}`}
                />
              ))}

          {connectionError && (
            <ConnectionError
              title={t('connectionErrorTitle')}
              titleA11y={t('connectionErrorTitle')}
              message={t('unpaidFaresMonerisConnectionError')}
              messageA11y={t('unpaidFaresMonerisConnectionError')}
              buttonA11y={fields?.refreshButtonTextA11y?.value}
              buttonText={fields?.refreshButtonText?.value}
              buttonOnClick={() => refreshIframe()}
            />
          )}
        </div>
      </CardBlock>
      <ButtonBlock>
        <ButtonBlock right>
          <Button onClick={() => redirectToUrl(linkToTransactionHistory)} white>
            <Text field={fields?.cancelButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default PayIframe;
