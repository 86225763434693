import React, { useEffect, useState, useRef } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import StationDetails from './StationDetails';
import './CSOLocator.scss';
import FilterStations from './FilterStations';
import api from '../../services/api';
import { getServerUrl, redirectToUrl, localstorageService } from '../../services/utils';
import { Loader } from '../../atoms';
import routes from '../../constants/routes';
import useMediaWidth from '../../hooks/useMediaWidth';

const ZOOM_THRESHOLD = 3;

const CSOLocator = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const defaultLat = parseFloat(props?.fields?.DefaultLatitude?.value);
  const defaultLng = parseFloat(props?.fields?.DefaultLongitude?.value);
  const [map, setMap] = useState(null);
  const destinationRef = useRef(null);
  const [mapInit, setMapInit] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: defaultLat, lng: defaultLng });
  const [inputValue, setInputValue] = useState('');
  const [showLocation, setShowLocation] = useState('');
  const [CSOLocatorLookUpData, setCSOLocatorLookUpData] = useState();
  const [stationData, setStationData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [newVisibleMarkers, setNewVisibleMarkers] = useState([]);
  const [storeIndex, setStoreIndex] = useState();
  const [markerClicked, setMarkerClicked] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [visibleMarkersWithDistance, setVisibleMarkersWithDistance] = useState([]);
  const [visibleMarkersWithFilter, setVisibleMarkersWithFilter] = useState([]);
  const [getJsonObject, setGeoJsonObject] = useState();
  const [markerLayer, setMarkerLayer] = useState(null);
  const [blobData, setBlobData] = useState();
  const [visibleMarkerBaseData, setVisibleMarkerBaseData] = useState([]);
  const domain = getServerUrl();
  const countValue = props?.fields?.SearchResultCountLabel?.value;
  const [visibleMarkersCount, setVisibleMarkersCount] = useState();
  const searchCountValue = countValue
    ?.replace('{0}', visibleMarkersCount)
    .replace('{1}', showLocation);
  const defaultMapIcon = props?.fields?.DefaultMapPinIcon?.value?.src;
  const defaultSelectedMapIcon = props?.fields?.DefaultSelectedMapPinIcon?.value?.src;
  const zoomLevel = parseInt(props?.fields?.ZoomLevel?.value);
  const searchZoomLevel = parseInt(props?.fields?.SearchZoomLevel?.value);
  const [currentZoomlevel, setCurrentZoomlevel] = useState(null);
  const [tryZoomOut, setTryZoomOut] = useState({ enable: false, count: 0 });
  const [iconUpdate, setIconUpdate] = useState(false);
  const isMobile = useMediaWidth();
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);

  useEffect(() => {
    if (map && isLocationEnabled) {
      map.setCenter(new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng));
      map.setZoom(searchZoomLevel);
    }
  }, [isLocationEnabled, currentLocation]);

  const changeUserLocation = () => {
    if (isLocationDisabled === true) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setShowLocation('');
          setInputValue('');
          setIsLocationEnabled(true);
        },
        (error) => {
          console.log(error);
          setIsLocationDisabled(true);
          setIsLocationEnabled(false);
        }
      );
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setIsLoading(true);
    api
      .getCSOLocatorLookUpData()
      .then((response) => {
        if (response.status === 200) {
          setCSOLocatorLookUpData(response?.data);
        } else {
          redirectToUrl(routes.technicalDifficulties);
          setIsLoading(false);
        }
      })
      .catch(() => {
        redirectToUrl(routes.technicalDifficulties);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (CSOLocatorLookUpData?.GoogleMapKey) {
      getBlobData();
    }
  }, [CSOLocatorLookUpData]);

  const getBlobData = () => {
    if (CSOLocatorLookUpData) {
      const account = CSOLocatorLookUpData?.BlobAccountName;
      const blobName = CSOLocatorLookUpData?.BlobName;
      const containerName = CSOLocatorLookUpData?.BlobContainerName;

      const url = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;

      api
        .getAzureData(url)
        .then((response) => {
          setBlobData(response?.data);
        })
        .catch((error) => {
          console.log('Azure API response', error);
        });
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setTryZoomOut({ enable: false, count: 0 });
  };

  const handleClearClick = () => {
    setInputValue('');
  };

  const getSelectedIcon = (selectedIconUrl) => {
    return {
      url: selectedIconUrl,
      scaledSize: new window.google.maps.Size(32, 32),
      anchor: new window.google.maps.Point(25, 50),
      origin: new window.google.maps.Point(0, 0),
      clickable: true,
    };
  };

  const getIcon = (iconUrl) => {
    return {
      url: iconUrl,
      scaledSize: new window.google.maps.Size(32, 32),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(25, 50),
      clickable: true,
    };
  };

  const handleSearch = () => {
    setTryZoomOut({ count: 0, enable: true });
    if (destinationRef?.current?.value === '') {
      return;
    }
    setExpandedItems([]);
    const geocoder = new window.google.maps.Geocoder();
    const address = destinationRef?.current?.value;
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        setCurrentLocation({ lat, lng });
        setShowLocation(destinationRef?.current?.value);
        localstorageService().setItem('searchFlag', JSON.stringify(true));
        map.panTo(location);
        if (results[0].geometry?.viewport) {
          map.fitBounds(results[0].geometry.viewport);
        } else {
          map.setZoom(searchZoomLevel);
        }
      } else {
        console.error(`Geocode request failed due to ${status}`);
      }
    });
  };

  const getDirections = (item) => {
    const destination = `${item?.geometry?.coordinates[1]},${item?.geometry?.coordinates[0]}`;
    const url = `https://www.google.com/maps/dir//${destination}`;
    window.open(url, '_blank');
  };

  const gotToStore = (item) => {
    const goToStoreUrl = item?.storePage;
    window.open(goToStoreUrl, '_blank');
  };

  useEffect(() => {
    if (blobData != undefined) {
      LoadGoogleMap();
    }
  }, [blobData]);

  const LoadGoogleMap = () => {
    if (blobData?.Result?.StoreLocations?.length > 0 && CSOLocatorLookUpData?.GoogleMapKey) {
      if (typeof window !== 'undefined') {
        const googleMapsScript = document.createElement('script');
        googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${CSOLocatorLookUpData?.GoogleMapKey}&callback=initMapAndAutocomplete&libraries=geometry,places`;
        googleMapsScript.async = true;
        window.document.body.appendChild(googleMapsScript);
      }

      const geoJSON = {
        type: 'FeatureCollection',
        features: [],
      };
      const storeList = blobData?.Result?.StoreLocations;
      const coordinates = storeList?.filter((store) => store.Latitude && store.Longitude);
      const latLngSet = new Set();
      const uniqueStores = coordinates?.filter((store) => {
        const latLng = `${store.Latitude},${store.Longitude}`;
        if (latLngSet.has(latLng)) {
          return false;
        }
        latLngSet.add(latLng);
        return true;
      });
      const storeCoordinates = uniqueStores?.map((location) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(location?.Longitude), parseFloat(location?.Latitude)],
        },
        properties: {
          id: location?.StoreUniqueId,
          icon: '',
          number: location?.StoreNumber,
          street: location?.Street,
          storeName: location?.StoreName,
          address: location?.Address,
          storePage: location?.StorePage,
          city: location?.City,
          province: location?.Province,
          postalCode: location?.PostalCode,
          customerServiceOutlet: location?.CustomerServiceOutlet,
          typeofFacility: location?.TypeofFacility,
          availableServices: location?.AvailableServices,
          locationID: location.LocationID,
        },
      }));

      geoJSON.features = [...storeCoordinates];
      const geojsonString = JSON.stringify(geoJSON);
      const geojsonObject = JSON.parse(geojsonString);
      setGeoJsonObject(geojsonObject);

      window.initMapAndAutocomplete = () => {
        initMap();
      };
    } else {
      redirectToUrl(routes.technicalDifficulties);
      setIsLoading(false);
    }
  };

  const updateShowMarkers = () => {
    setIconUpdate(false);
    if (map) {
      if (selectedFilters.length == 0) {
        markerLayer.forEach(function (feature) {
          markerLayer.remove(feature);
        });

        const filterGeoJsonNew = {
          type: 'FeatureCollection',
          features: [],
        };
        const filteredMarkers = newVisibleMarkers.map((filterObject) => {
          return filterObject;
        });
        filterGeoJsonNew.features = [...filteredMarkers];

        setVisibleMarkersCount(filteredMarkers.length);
        markerLayer.addGeoJson(filterGeoJsonNew);
        let iconsSet = 0;
        const totalIcons = filterGeoJsonNew.features.length;
        markerLayer.setStyle(function (feature) {
          const csoId = feature.getProperty('customerServiceOutlet').split('|')[0];
          const iconData = CSOLocatorLookUpData.CsoLocators.find((icon) => csoId.includes(icon.Id));
          const iconUrl = iconData ? `${domain}${iconData.MapIcon}` : defaultMapIcon;
          const icon = getIcon(iconUrl);
          feature.setProperty('icon', icon);
          iconsSet++;
          if (iconsSet === totalIcons) {
            setIconUpdate(true);
          }
          return {
            icon,
            title: feature?.getProperty('storeName'),
          };
        });
      }
    }
  };

  const showFilteredPins = () => {
    setIconUpdate(false);
    if (selectedFilters.length > 0) {
      const filterGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };
      const filteredMarkers = newVisibleMarkers.map((filterObject) => {
        return filterObject;
      });
      filterGeoJSON.features = [...filteredMarkers];
      markerLayer.forEach(function (feature) {
        markerLayer.remove(feature);
      });

      setVisibleMarkersCount(filteredMarkers.length);
      markerLayer.addGeoJson(filterGeoJSON);

      let iconsSet = 0;
      const totalIcons = filterGeoJSON.features.length;
      markerLayer.setStyle(function (feature) {
        const csoId1 = feature.getProperty('customerServiceOutlet').split('|')[0];
        const iconData1 = CSOLocatorLookUpData?.CsoLocators.find((icon) =>
          csoId1.includes(icon.Id)
        );
        const iconUrl = iconData1 ? `${domain}${iconData1.MapIcon}` : defaultMapIcon;
        const icon = getIcon(iconUrl);
        feature.setProperty('icon', icon);
        iconsSet++;
        if (iconsSet === totalIcons) {
          setIconUpdate(true);
        }
        return {
          icon,
          title: feature?.getProperty('storeName'),
        };
      });
    }
  };

  useEffect(() => {
    showFilteredMarkers();
  }, [visibleMarkersWithDistance]);

  const calculateDistances = () => {
    if (visibleMarkerBaseData.length > 0) {
      const destinations = visibleMarkerBaseData?.map((marker) => {
        const coords = marker.geometry.coordinates;
        return { lat: coords[1], lng: coords[0] };
      });
      const origin = currentLocation;
      for (let i = 0; i < destinations.length; i++) {
        const destination = destinations[i];
        const distance = haversineDistance(origin, destination);
        visibleMarkerBaseData[i].properties.distance = distance.toFixed(1);
      }
      const sortedMarkers = visibleMarkerBaseData.sort((marker1, marker2) => {
        const distance1 = marker1.properties.distance;
        const distance2 = marker2.properties.distance;
        if (distance1 === null || distance2 === null) {
          return distance1 === null ? 1 : -1;
        }
        return distance1 - distance2;
      });
      // Capping limit of 100 nearest location
      const newSortedMarkers = sortedMarkers.slice(0, 100);
      setVisibleMarkersWithDistance(newSortedMarkers);
    } else {
      setVisibleMarkersWithDistance([]);
    }
  };

  function haversineDistance(coord1, coord2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const lat1 = toRadians(coord1.lat);
    const lon1 = toRadians(coord1.lng);
    const lat2 = toRadians(coord2.lat);
    const lon2 = toRadians(coord2.lng);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    return distance;
  }

  // Utility function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  useEffect(() => {
    calculateDistances();
  }, [visibleMarkerBaseData]);

  const [csoFilterCounter, setCsoFilterCounter] = useState(0);
  const [asFilterCounter, setAsFilterCounter] = useState(0);
  const [tfFilterCounter, setTfFilterCounter] = useState(0);
  const [csoFilter, setCsoFilter] = useState([]);
  const [availableFilter, setAvailableFilter] = useState([]);
  const [facilityFilter, setFacilityFilter] = useState([]);

  const handleFilterChange = (event) => {
    const filterId = event.target.value;
    const isChecked = event.target.checked;
    const cso = event.target.classList.contains('csoLocators');
    const availSer = event.target.classList.contains('availableServices');
    const typeFac = event.target.classList.contains('typeofFacilities');
    if (isChecked) {
      if (cso) {
        setCsoFilterCounter(csoFilterCounter + 1);
        setCsoFilter((prevFilters) => [...prevFilters, filterId]);
      }
      if (availSer) {
        setAsFilterCounter(asFilterCounter + 1);
        setAvailableFilter((prevFilters) => [...prevFilters, filterId]);
      }
      if (typeFac) {
        setTfFilterCounter(tfFilterCounter + 1);
        setFacilityFilter((prevFilters) => [...prevFilters, filterId]);
      }
      setSelectedFilters((prevFilters) => [...prevFilters, filterId]);
    } else {
      setSelectedFilters((prevFilters) => prevFilters.filter((id) => id !== filterId));
      if (cso) {
        setCsoFilterCounter(csoFilterCounter - 1);
        setCsoFilter((prevFilters) => prevFilters.filter((id) => id !== filterId));
      }
      if (availSer) {
        setAsFilterCounter(asFilterCounter - 1);
        setAvailableFilter((prevFilters) => prevFilters.filter((id) => id !== filterId));
      }
      if (typeFac) {
        setTfFilterCounter(tfFilterCounter - 1);
        setFacilityFilter((prevFilters) => prevFilters.filter((id) => id !== filterId));
      }
    }
    setExpandedItems([]);
  };
  useEffect(() => {}, [visibleMarkerBaseData]);
  useEffect(() => {
    showFilteredMarkers();
  }, [selectedFilters]);

  const showFilteredMarkers = () => {
    let finalMarker = [...visibleMarkersWithDistance];
    if (selectedFilters.length > 0 && map) {
      let filteredVisibleMarkers = {};
      const bounds = map.getBounds();
      const filterObj = {
        availableServices: availableFilter,
        customerServiceOutlet: csoFilter,
        typeofFacility: facilityFilter,
      };
      const query = buildFilter(filterObj);
      const result = newpinsFilterData(visibleMarkersWithDistance, query);

      const filteredGeojsonObject = {
        type: 'FeatureCollection',
        features: result,
      };
      finalMarker = filteredGeojsonObject.features.filter((feature) => {
        const geometry = feature.geometry;
        return bounds.contains(
          new window.google.maps.LatLng(geometry.coordinates[1], geometry.coordinates[0])
        );
      });
    }
    setNewVisibleMarkers(finalMarker);
    if (tryZoomOut.enable) {
      if (!finalMarker.length) {
        startZoomingOut();
      } else {
        stopZoomingOut();
      }
    }
  };

  const startZoomingOut = () => {
    if (
      tryZoomOut.enable &&
      tryZoomOut.count <= ZOOM_THRESHOLD &&
      currentZoomlevel >= searchZoomLevel
    ) {
      let updatedCount = { ...tryZoomOut, count: tryZoomOut.count + 1 };
      setTryZoomOut(updatedCount);
      map.setZoom(currentZoomlevel - 1);
    }
  };
  const stopZoomingOut = () => {
    if (
      tryZoomOut.enable &&
      tryZoomOut.count >= ZOOM_THRESHOLD &&
      currentZoomlevel <= searchZoomLevel
    ) {
      setTryZoomOut({ enable: false, count: 0 });
    }
  };
  useEffect(() => {
    stopZoomingOut();
  }, [tryZoomOut]);
  const buildFilter = (filterObj) => {
    let query = {};
    for (let keys in filterObj) {
      if (filterObj[keys].constructor === Array && filterObj[keys].length > 0) {
        query[keys] = filterObj[keys];
      }
    }
    return query;
  };

  const newpinsFilterData = (data, query) => {
    const specialKeys = ['availableServices', 'typeofFacility'];
    const filteredData = data.filter((item, index) => {
      let properties = item.properties;
      for (let key in query) {
        if (properties[key] === undefined) {
          return false;
        }
        if (specialKeys.includes(key)) {
          const itemKeyArr = properties[key].split('|');
          const queryKeyArr = query[key];
          for (let val in queryKeyArr) {
            if (!itemKeyArr.includes(queryKeyArr[val])) {
              return false;
            }
          }
        } else if (!query[key].includes(properties[key])) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };

  const handleBoundsChanged = () => {
    setIconUpdate(false);
    let visibleMarkers = {};
    if (map && currentLocation?.lat && currentLocation?.lng) {
      const bounds = map.getBounds();
      if (!bounds) return;
      const storedSearchFlag = JSON.parse(localstorageService().getItem('searchFlag'));
      if (storedSearchFlag === false) {
        setShowLocation('');
      }
      localstorageService().setItem('searchFlag', JSON.stringify(false));
      visibleMarkers = getJsonObject.features.filter((feature) => {
        const geometry = feature.geometry;
        return bounds.contains(
          new window.google.maps.LatLng(geometry.coordinates[1], geometry.coordinates[0])
        );
      });
      setExpandedItems([]);
      setVisibleMarkerBaseData(visibleMarkers);
    }
  };
  const checkIsExist = (locId) => expandedItems.includes(locId);

  const handleAccordionClick = (locId, keepExpanded = false) => {
    const newExpandedItems = [...expandedItems];
    if (checkIsExist(locId)) {
      let index = newExpandedItems.findIndex((el) => el === locId);
      if (index >= 0) {
        if (!keepExpanded) {
          newExpandedItems.splice(index, 1);
        }
      }
    } else {
      newExpandedItems.push(locId);
    }
    setExpandedItems(newExpandedItems);
  };
  const gotToResult = (id) => {
    let ele = document.getElementById(`cso-result-id-${id}`);
    let container = document?.getElementsByClassName('stations-container');

    if (isMobile && ele) {
      ele.style.scrollMarginTop = '64px';
      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      ele.style.scrollMarginTop = '0px';

      handleAccordionClick(id, true);
    } else if (!isMobile && container.length && ele) {
      let resultCont = container[0];
      const index = Array.from(ele.parentElement.children).indexOf(ele);
      let childPos = ele.getBoundingClientRect();
      let pixelToScroll = index * childPos.height;
      resultCont.scrollTop = 0;
      resultCont.scrollTop = pixelToScroll;
      resultCont.scrollIntoView({ behavior: 'smooth', block: 'center' });
      handleAccordionClick(id, true);
    }
    if (ele) {
      let buttonContainer = ele.firstElementChild;
      if (buttonContainer && buttonContainer.firstElementChild) {
        buttonContainer.firstElementChild.focus({ preventScroll: true });
      }
    }
  };

  const showMarkers = () => {
    if (map) {
      const markerLayer = new window.google.maps.Data({ map });
      setMarkerLayer(markerLayer);
      setIsLoading(false);
      setExpandedItems([]);
      // click event listener to the markerLayer
      markerLayer.addListener('click', function (event) {
        const feature = event.feature;
        const csoId = feature.getProperty('customerServiceOutlet').split('|')[0];
        markerLayer.revertStyle();
        const selectedIconData = CSOLocatorLookUpData?.CsoLocators.find((icon) =>
          csoId.includes(icon.Id)
        );
        const selectedIconUrl = selectedIconData
          ? `${domain}${selectedIconData.SelectedMapIcon}`
          : defaultSelectedMapIcon;
        const selectedIcon = getSelectedIcon(selectedIconUrl);
        markerLayer.overrideStyle(feature, { icon: selectedIcon });
        const storeNumber = event.feature.getProperty('locationID');
        setStoreIndex(storeNumber);
        setMarkerClicked(true);
        setExpandedItems([]);
        gotToResult(event.feature.getProperty('locationID'));
      });

      map.addListener('idle', () => {
        handleBoundsChanged();
      });
      map.addListener('bounds_changed', () => {
        setCurrentZoomlevel(map.getZoom());
      });
      map.addListener('tilesloaded', () => {
        if (!mapInit) setMapInit(true);
      });

      const autocomplete = new window.google.maps.places.Autocomplete(destinationRef.current);
      if (autocomplete) {
        autocomplete.addListener('place_changed', () => {
          setTryZoomOut({ count: 0, enable: true });
          const place = autocomplete.getPlace();
          if (place && place.geometry) {
            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            setCurrentLocation({ lat, lng });
            setInputValue(place.formatted_address);
            setShowLocation(place.formatted_address);
            localstorageService().setItem('searchFlag', JSON.stringify(true));
            map.panTo(location);
            if (place?.geometry?.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setZoom(searchZoomLevel);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    showMarkers();
  }, [map]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      showFilteredPins();
    } else {
      updateShowMarkers();
    }
  }, [newVisibleMarkers]);

  const initMap = () => {
    let finalZoom;
    if (currentLocation) {
      if (zoomLevel < 0) {
        finalZoom = 0;
      } else if (zoomLevel > 22) {
        finalZoom = 22;
      } else {
        finalZoom = zoomLevel;
      }
      setCurrentZoomlevel(finalZoom);
      const googleMap = new window.google.maps.Map(document.getElementById('map'), {
        center: currentLocation,
        zoom: finalZoom,
        disableDefaultUI: true, // remove all default options
      });

      const dataLayer = new window.google.maps.Data({ map: googleMap });
      setMap(googleMap);
    }
  };
  const manageAutoComplete = (event, mode) => {
    let pacContainer = document.getElementsByClassName('pac-container');
    let inputField = document.getElementById('cso-search-input');
    if (mode === 'show' && pacContainer.length) {
      inputField.appendChild(pacContainer[0]);
    } else if (mode === 'hide' && pacContainer.length) {
      console.log('remove');
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="search-white-box">
        <label className="address-or-postal-co">
          <Text field={props?.fields?.SearchHeading} />
        </label>
        <div className="search-box" id="cso-search-input">
          <div className="search-box-input">
            <input
              className="text-filed-input"
              type="text"
              ref={destinationRef}
              placeholder={props?.fields?.StoreSearchBoxText?.value}
              value={inputValue}
              onFocus={(e) => manageAutoComplete(e, 'show')}
              onBlur={(e) => manageAutoComplete(e, 'hide')}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSearch();
                }
              }}
            />
          </div>
          <div className="search-box-button-group">
            <button className="tooltip-button" type="submit" onClick={changeUserLocation}>
              <img
                src={props?.fields?.ChangeLocationIcon?.value?.src}
                alt={props?.fields?.ChangeLocationIcon?.value?.alt}
                className="icon-search"
              />
              <span
                className="tooltip-text"
                aria-label={
                  isLocationDisabled
                    ? props?.fields?.DisableLocationText?.value
                    : props?.fields?.EnableLocationText?.value
                }
              >
                {isLocationDisabled
                  ? props?.fields?.DisableLocationText?.value
                  : props?.fields?.EnableLocationText?.value}
              </span>
            </button>
            <div className="vertical-line" />
            <button
              className={inputValue ? 'search-icon-button' : 'search-icon-cso'}
              type="submit"
              onClick={handleSearch}
            >
              <img
                src={props?.fields?.StoreSearchIcon?.value?.src}
                alt={props?.fields?.StoreSearchIcon?.value?.alt}
                className="icon-search"
              />
            </button>
            {inputValue && (
              <>
                <div className="vertical-line" />
                <button className="close-icon-button" onClick={handleClearClick}>
                  <img
                    src={props?.fields?.CloseButtonIcon?.value?.src}
                    alt={props?.fields?.CloseButtonIcon?.value?.alt}
                    className="icon-close"
                  />
                </button>
              </>
            )}
          </div>
        </div>
        <FilterStations
          props={props}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          CSOLocatorLookUpData={CSOLocatorLookUpData}
          visibleMarkersWithDistance={visibleMarkersWithDistance}
          setVisibleMarkersWithFilter={setVisibleMarkersWithFilter}
          handleFilterChange={handleFilterChange}
          csoFilterCounter={csoFilterCounter}
          asFilterCounter={asFilterCounter}
          tfFilterCounter={tfFilterCounter}
          setCsoFilterCounter={setCsoFilterCounter}
          setAsFilterCounter={setAsFilterCounter}
          setTfFilterCounter={setTfFilterCounter}
          setCsoFilter={setCsoFilter}
          setAvailableFilter={setAvailableFilter}
          setFacilityFilter={setFacilityFilter}
          isMapLoaded={mapInit}
          newVisibleMarkers={newVisibleMarkers}
        />
      </div>

      <div id="map" />
      <div className="cso-station-result-container">
        <label className="showing-stations">{searchCountValue}</label>
        <>
          <div className="stations-container">
            <StationDetails
              props={props}
              getDirections={getDirections}
              stationData={stationData}
              CSOLocatorLookUpData={CSOLocatorLookUpData}
              newVisibleMarkers={newVisibleMarkers}
              visibleMarkersWithDistance={visibleMarkersWithDistance}
              storeIndex={storeIndex}
              markerClicked={markerClicked}
              setMarkerClicked={setMarkerClicked}
              expandedItems={expandedItems}
              setExpandedItems={setExpandedItems}
              handleAccordionClick={handleAccordionClick}
              checkIsExist={checkIsExist}
              gotToStore={gotToStore}
              t={props.t}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default withTranslation()(CSOLocator);
