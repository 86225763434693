import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { updateUserInfo } from '../../redux/user/actions';
import apiResponses from '../../constants/apiResponses';
import api from '../../services/api';
import FormikOnError from '../../services/FormikOnError';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { Button, ButtonBlock } from '../../atoms';
import { CardBlock, Select, TextInput } from '../../molecules';

export default function AccountSecurity1({
  state,
  toPreviousStep,
  fields,
  t,
  setState,
  userInfo,
  questions,
  answers,
  setQuestions,
  setAnswers,
  language,
}) {
  const [allQuestions, setAllQuestions] = useState([]);
  const dispatch = useDispatch();
  const [inlineError, setInlineError] = useState('');
  const [error, setIsError] = useState('');
  useEffect(() => {
    setState({
      securityQuestionsResult: null,
    });
    api.getAllSecurityQuestions().then((response) => {
      if (response.status === 200) {
        setAllQuestions(
          response.data.map((i) => ({
            label: i.Item2,
            value: i.Item1,
          }))
        );
      }
    });
  }, [language]);

  if (allQuestions.length === 0 && !questions[0] && !answers[0]) return null;
  return (
    <>
      <div className="editSecurity-wrapper">
        <CardBlock
          title={fields.editSecurityQuestionTitle.value}
          titleA11y={fields.editSecurityQuestionTitleA11y.value}
        >
          <RichText field={fields.description} aria-label={fields.descriptionA11y.value} />

          <Formik
            initialValues={{
              Password: '',
              UserName: userInfo.userName,
              Question1Id: questions[0].value,
              Question2Id: questions[1].value,
              Question3Id: questions[2].value,
              Answer1: answers[0].answer,
              Answer2: answers[1].answer,
              Answer3: answers[2].answer,
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              UserName: Yup.string()
                .required(t('accountSecurityEnterUsername'))
                .min(6, t('accountSecurityUsernameValidation'))
                .matches(/^.[a-zA-Z0-9\-._+]+$/, t('accountSecurityUsernameValidation')),
              Question1Id: Yup.string().required(t('accountSecuritySelectQuestion')),
              Question2Id: Yup.string().required(t('accountSecuritySelectQuestion')),
              Question3Id: Yup.string().required(t('accountSecuritySelectQuestion')),
              Answer1: Yup.string()
                .required(t('accountSecurityEnterAnswer'))
                .matches(/^[A-Za-z0-9 ]{4,50}$/, t('accountSecurityAnswerValidation')),
              Answer2: Yup.string()
                .required(t('accountSecurityEnterAnswer'))
                .matches(/^[A-Za-z0-9 ]{4,50}$/, t('accountSecurityAnswerValidation')),
              Answer3: Yup.string()
                .required(t('accountSecurityEnterAnswer'))
                .matches(/^[A-Za-z0-9 ]{4,50}$/, t('accountSecurityAnswerValidation')),
              Password: Yup.string().required(t('accountSecurityEnterPassword')),
            })}
            onSubmit={(values) => {
              api
                .setSecurityQuestionsWithAnswers({
                  CustomerId: userInfo.customerId,
                  UserName: values.UserName,
                  Question1Id: values.Question1Id,
                  Question2Id: values.Question2Id,
                  Question3Id: values.Question3Id,
                  Answer1: values.Answer1.trim(),
                  Answer2: values.Answer2.trim(),
                  Answer3: values.Answer3.trim(),
                  Password: values.Password,
                })
                .then((response) => {
                  if (response.data.Success) {
                    dispatch(updateUserInfo(dispatch));
                    api.getSecurityQuestionsWithAnswers().then((response) => {
                      if (response.data.Success) {
                        setQuestions(
                          response.data.Data.map((i) => ({
                            label: i.Item1.Text,
                            value: i.Item1.Id,
                          }))
                        );
                        setAnswers(
                          response.data.Data.map((i) => ({
                            answer: i.Item2,
                          }))
                        );
                      } else {
                        handleServerSuccessError(response.data.Error, setIsError);
                      }
                    });

                    setState({
                      ...state,
                      securityQuestionsResult: 'updated',
                    });
                    toPreviousStep();
                    // setQuestions(null);
                    // setAnswers(null);
                  } else if (
                    (!response.data.Success &&
                      JSON.parse(response.data.Error).Code ===
                        apiResponses.securityWrongPassword) ||
                    JSON.parse(response.data.Error).Code ===
                      apiResponses.securityUsernameAlreadyExists
                  ) {
                    setState({
                      errorField: JSON.parse(response.data.Error),
                    });
                    handleServerSuccessError(response.data.Error, () => {}, null, setInlineError);
                  } else {
                    setState({
                      ...state,
                      securityQuestionsResult: 'notUpdated',
                    });
                    toPreviousStep();
                  }
                });
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <FormikOnError>
                  <div className="security-item">
                    <TextInput
                      type="text"
                      name="UserName"
                      errors={errors}
                      touched={touched}
                      label={fields.editUsernameLabel}
                      bellowText={fields.editUsernameBellowText}
                      serverError={
                        state.errorField?.Code === apiResponses.securityUsernameAlreadyExists
                          ? inlineError
                          : null
                      }
                    />
                  </div>
                  <div className="security-item">
                    <Select
                      label={fields.editSecurityQuestion1Label?.value}
                      fullWidth
                      name="Question1Id"
                      errors={errors}
                      touched={touched}
                      options={allQuestions.filter(
                        (i) => i.value !== values.Question2Id && i.value !== values.Question3Id
                      )}
                      placeholder={fields.editSecurityQuestion1Placeholder?.value}
                      labelA11y={fields.editSecurityQuestion1LabelA11y?.value}
                      selectablePlaceholder
                    />
                    <TextInput
                      name="Answer1"
                      errors={errors}
                      touched={touched}
                      label={fields.editSecurityAnswer1Label}
                      labelA11y={fields.editSecurityAnswer1LabelA11y?.value}
                      marginTopSpacing2
                      withViewIcon
                      showIconA11y={fields.showAnswer1A11y?.value}
                      hideIconA11y={fields.hideAnswer1A11y?.value}
                      disabledAutocomplete
                    />
                  </div>
                  <div className="security-item">
                    <Select
                      label={fields.editSecurityQuestion2Label?.value}
                      name="Question2Id"
                      fullWidth
                      errors={errors}
                      touched={touched}
                      options={allQuestions.filter(
                        (i) => i.value !== values.Question1Id && i.value !== values.Question3Id
                      )}
                      placeholder={fields.editSecurityQuestion2Placeholder?.value}
                      labelA11y={fields.editSecurityQuestion2LabelA11y?.value}
                      selectablePlaceholder
                    />
                    <TextInput
                      name="Answer2"
                      errors={errors}
                      touched={touched}
                      label={fields.editSecurityAnswer2Label}
                      labelA11y={fields.editSecurityAnswer2LabelA11y?.value}
                      marginTopSpacing2
                      withViewIcon
                      showIconA11y={fields.showAnswer2A11y?.value}
                      hideIconA11y={fields.hideAnswer2A11y?.value}
                      disabledAutocomplete
                    />
                  </div>
                  <div className="security-item">
                    <Select
                      label={fields.editSecurityQuestion3Label?.value}
                      name="Question3Id"
                      fullWidth
                      errors={errors}
                      touched={touched}
                      options={allQuestions.filter(
                        (i) => i.value !== values.Question1Id && i.value !== values.Question2Id
                      )}
                      placeholder={fields.editSecurityQuestion3Placeholder?.value}
                      labelA11y={fields.editSecurityQuestion3LabelA11y?.value}
                      selectablePlaceholder
                    />
                    <TextInput
                      name="Answer3"
                      errors={errors}
                      touched={touched}
                      label={fields.editSecurityAnswer3Label}
                      labelA11y={fields.editSecurityAnswer3LabelA11y?.value}
                      marginTopSpacing2
                      withViewIcon
                      showIconA11y={fields.showAnswer3A11y?.value}
                      hideIconA11y={fields.hideAnswer3A11y?.value}
                      disabledAutocomplete
                    />
                  </div>
                  <div className="security-item">
                    <Text field={fields.editEnterPasswordText} />
                    <TextInput
                      name="Password"
                      errors={errors}
                      touched={touched}
                      label={fields.editPasswordLabel}
                      labelA11y={fields.editPasswordLabelA11y?.value}
                      marginTopSpacing1
                      withViewIcon
                      serverError={
                        state.errorField?.Code === apiResponses.securityWrongPassword
                          ? inlineError
                          : null
                      }
                      disabledAutocomplete
                    />
                  </div>
                  <ButtonBlock>
                    <ButtonBlock right>
                      <Button type="submit" buttonTextA11y={fields.editSaveButtonTextA11y?.value}>
                        <Text field={fields.editSaveButtonText} />
                      </Button>
                      <Button
                        firstOrder
                        white
                        onClick={toPreviousStep}
                        buttonTextA11y={fields.editDiscardButtonTextA11y?.value}
                      >
                        <Text field={fields.editDiscardButtonText} />
                      </Button>
                    </ButtonBlock>
                  </ButtonBlock>
                </FormikOnError>
              </form>
            )}
          </Formik>
        </CardBlock>
      </div>
    </>
  );
}
