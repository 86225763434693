import React, { useState, useEffect, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import { StepTracker } from '../../molecules';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';
import { setRedeemVoucherStepperBlock } from '../../redux/products/actions';

import RedeemVoucherStepperBlock1 from './RedeemVoucherStepperBlock1';
import RedeemVoucherStepperBlock2 from './RedeemVoucherStepperBlock2';
import RedeemVoucherStepperBlock3 from './RedeemVoucherStepperBlock3';

const stepsRedeemVoucherStepperBlockMap = [
  RedeemVoucherStepperBlock1,
  RedeemVoucherStepperBlock2,
  RedeemVoucherStepperBlock3,
];

const RedeemVoucherStepperBlock = ({ fields, t, rendering }) => {
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const userInfo = useSelector((reduxState) => reduxState.user);
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const toFirstStep = () => {
    setStep(0);
    focusDiv.current.focus();
  };

  const toRedeemVoucher = () => {
    dispatch(
      setRedeemVoucherStepperBlock({
        redeemVoucherStepperBlock: false,
        redeemVoucherBlock: true,
        voucherCode: '',
      })
    );
  };

  const Stepper = stepsRedeemVoucherStepperBlockMap[step];

  useEffect(() => {
    setState({ ...state });
  }, []);

  return redeemStepper?.redeemVoucherStepperBlock ? (
    <>
      <div className="redeemvoucher-stepper-wrapper">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.RedeemVoucherStepperTitle1} />,
              textInProgressA11y: fields.Step1InProgressA11y.value,
              textCompletedA11y: fields.Step1CompletedA11y.value,
            },
            {
              text: <Text field={fields.ReviewdetailsStepperTitle} />,
              textNotCompletedA11y: fields.Step2NotCompletedA11y.value,
              textInProgressA11y: fields.Step2InProgressA11y.value,
              textCompletedA11y: fields.Step2CompletedA11y.value,
            },
            {
              text: <Text field={fields.ConfirmationStepperTitle} />,
              textNotCompletedA11y: fields.Step3NotCompletedA11y.value,
              textCompletedA11y: fields.Step3CompletedA11y.value,
              hasError: state.Sucess ? false : true,
            },
          ]}
        />
      </div>
      <Stepper
        fields={fields}
        t={t}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        userInfo={userInfo}
        configKeys={configKeys}
        toFirstStep={toFirstStep}
        rendering={rendering}
        toRedeemVoucher={toRedeemVoucher}
      />
    </>
  ) : null;
};

export default withTranslation()(RedeemVoucherStepperBlock);
