import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import Warning from '../Warning';
import { ButtonBlock, Button, PrintButton } from '../../atoms';
import { monerisErrorCodes, daxErrorCodes } from '../../services/handleThirdPartyError';
import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';

import './thirdPartySystemErrorWarning.scss';

const ThirdPartySystemErrorWarning = ({ errorCode, fields, referenceNumber = '' }) => {
  let title = null;
  let description = null;

  if (!errorCode) {
    return null;
  }

  if (monerisErrorCodes.indexOf(errorCode) > -1) {
    title = fields.thirdPartyMonerisErrorTitle;
    description = fields.thirdPartyMonerisErrorDescription;
  }

  if (daxErrorCodes.indexOf(errorCode) > -1) {
    title = fields.thirdPartyDaxErrorTitle;
    description = fields.thirdPartyDaxErrorDescription;
  }

  return (
    <>
      {referenceNumber !== '' ? (
        <div className="thirdPartySystemErrorWarning-refNumber">
          <Text field={fields.referenceNumberLabel} /> - {referenceNumber}
        </div>
      ) : null}
      <Warning
        {...{ title, description, titleA11y: { title }, descriptionA11y: { description } }}
      />
      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            firstOrder
            buttonTextA11y={fields.toMyCardbuttonTextA11y.value}
            onClick={() => redirectToUrl(routes.manageMyCards)}
          >
            <Text field={fields.toMyCardbuttonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default ThirdPartySystemErrorWarning;
