import React from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { IconButton } from '../../atoms';
import chevronDown from '../../assets/images/down.svg';
import chevronUp from '../../assets/images/up.svg';

const NotificationToggleButton = ({
  collapseTextField,
  expandTextField,
  showCollapse,
  onClickHandler,
  t,
}) => {
  return (
    <IconButton
      className="notifications-headerToggleBtn"
      icon={showCollapse ? chevronUp : chevronDown}
      iconAlt={showCollapse ? `${t('expandA11y')}` : `${t('collapseA11y')}`}
      onClick={onClickHandler}
      ariaExpanded={showCollapse}
    >
      {!collapseTextField?.value || !expandTextField?.value ? null : (
        <span>
          <Text field={showCollapse ? collapseTextField : expandTextField} />
        </span>
      )}
    </IconButton>
  );
};

export default withTranslation()(NotificationToggleButton);
