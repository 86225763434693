import React, { useState } from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import './globalNotification.scss';

const GlobalNotification = (props) => {
  const [divAppear, setDivAppear] = useState(true);

  return divAppear ? (
    <div className="global-notifications">
      <Image media={props?.fields?.Image} loading="lazy"/>

      <RichText field={props?.fields?.Description} />

      <button data-testid="global-notification-btn" onClick={() => setDivAppear(false)}>
        <Image media={props?.fields?.CloseIcon} loading="lazy"/>
      </button>
    </div>
  ) : null;
};

export default GlobalNotification;
