import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// import * as serviceWorker from './serviceWorker';
import AppRoot from './AppRoot';
import { setServerSideRenderingState } from './RouteHandler';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import i18ninit from './i18n';
import api from './services/api';
import { localstorageService } from './services/utils';
import localStorageKeys from './constants/localStorageKeys';

const AppendGtmScript = () => {
    const tagManagerArgs = {
        gtmId: localstorageService().getItem(localStorageKeys.gtmContainerId),
    };

    TagManager.initialize(tagManagerArgs);
};
const scriptExists = (url) => {
    let scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
    }
    return false;
};

export const analytics = (w, d, s, l, i) => {
    // console.log(i);
    w.dataLayer = window.dataLayer || [];
    w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    let dl = l !== 'dataLayer' ? `&l=${l}` : '';
    let scr = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    /*
        To avoid Multiple installations of google tag manager detected warning
    */
    if (!scriptExists(scr)) {
        // let scriptEl = d.getElementsByTagName(s)[0],
        //   j = d.createElement('script');
        // j.async = true;
        // j.src = scr;
        // let addScript = scriptEl?.parentNode?.insertBefore(j, scriptEl);
        // console.log(addScript);
        let tagName = 'script';
        let script = document.createElement(tagName);
        script.src = scr;
        script.async = true;
        let head = document.getElementsByTagName('head')[0];
        head.insertBefore(script, head.childNodes[0]);
    }
    let IframeEl = document.getElementById('tag-manager');
    let gtmUrl = 'https://www.googletagmanager.com/ns.html?id=';
    IframeEl.src = gtmUrl + i;
};

/* eslint-disable no-underscore-dangle */

let renderFunction = ReactDOM.render;

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
    __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
    // push the initial SSR state into the route handler, where it will be used
    setServerSideRenderingState(__JSS_STATE__);

    // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
    renderFunction = ReactDOM.hydrate;

    // set i18n language SSR state language instead of static config default language
    initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState =
    __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

const graphQLClient = GraphQLClientFactory(config.graphQLEndpoint, false, initialGraphQLState);

if (!localstorageService() || !localstorageService().getItem(localStorageKeys.gtmContainerId)) {
    api.getSetting({ settingName: 'GoogleTagManager.ContainerId' }).then((res) => {
        if (res.status === 200) {
            localstorageService().setItem(localStorageKeys.gtmContainerId, `GTM-${res.data}`);
            analytics(window, document, 'script', 'dataLayer', `GTM-${res.data}`);
        }
    });
    // AppendGtmScript();
} else {
    // AppendGtmScript();
    analytics(
        window,
        document,
        'script',
        'dataLayer',
        localstorageService().getItem(localStorageKeys.gtmContainerId)
    );
}

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage).then(() => {
    // HTML element to place the app into
    const rootElement = document.getElementById('root');

    renderFunction(
        <AppRoot
            path={window.location.pathname}
            Router={BrowserRouter}
            graphQLClient={graphQLClient}
        />,
        rootElement
    );
});
// serviceWorker.register();
