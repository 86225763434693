import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import { isJsonString, redirectToUrl } from '../../services/utils';
import handleLogOut from '../../services/handleLogOut';
import { refreshUserDataAfterPrestoCardAdded } from '../../redux/user/actions';
import routes from '../../constants/routes';
import { Button, ButtonBlock } from '../../atoms';
import { Error, Modal, ValueRow } from '../../molecules';
import './addPrestoCard2.scss';

const AddPrestoCard2 = (props) => {
  const PAPER_FORM_REGISTERED_CARD_STATUS = 1;
  const { fields, state, toNextStep, toPreviousStep, toFirstStep, setState } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaperFormModalOpen, setIsPaperFormModalOpen] = useState(false);
  const [error, setIsError] = useState('');
  const dispatch = useDispatch();
  const loginEmail = useSelector((state) => state.user.loginEmail);
  //const returnpath = `${routes.addCard}?addCardtype=presto`

  const handleClick = () => {
    if (state.CardStatus === PAPER_FORM_REGISTERED_CARD_STATUS) {
      api.addPaperFormPrestoCard(state).then((response) => {
        if (response.data.Success) {
          setIsPaperFormModalOpen(true);
        } else if (isJsonString(response.data.Error)) {
          const errorMessage = JSON.parse(response.data.Error);
          setIsError(errorMessage.Description);
        }
      });
    } else {
      api.addPrestoCard(state).then((response) => {
        if (response.data.Success) {
          setState({
            ...state,
            MediaId: state.MediaId,
            CVN: state.CVN,
            CustomerId: state.CustomerId,
          });
          const returnpath = `${routes.addCard}?addCardtype=presto&param1= ${state.MediaId}&param2=${state.CVN}&param3=${state.CustomerId}`;
          redirectToUrl(`${routes.b2cRegisteredSignIn}?email=${loginEmail}&refreshToken=true&returnUrl=${returnpath}`);
        } else if (isJsonString(response.data.Error)) {
          const errorMessage = JSON.parse(response.data.Error);
          setIsError(errorMessage.Description);
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={fields.cancelMessageTitle?.value}
        text={fields.cancelMessage?.value}
        textCancel={fields.confirmCancellationButtonText?.value}
        textSubmit={fields.abortCancellationButtonText?.value}
        useRevert
        onSubmit={toFirstStep}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <Modal
        text={fields.paperFormSignOutMessage?.value}
        textSubmit={fields.paperFormSignOutButtonText?.value}
        onSubmit={() => {
          handleLogOut(
            dispatch,
            `${routes.addCard}?action=addPrestoCard&paperForm&visibleId=${state.MediaId}`
          );
        }}
        isModalOpen={isPaperFormModalOpen}
        setIsModalOpen={setIsPaperFormModalOpen}
      />

      <div className="add-card-2">
        <h1 className="block-header" aria-label={fields.headingA11y.value}>
          <Text field={fields.heading} />
        </h1>
        <div className="block-context-text" aria-label={fields.reviewDescriptionA11y.value}>
          <Text field={fields.reviewDescription} />
        </div>
        {error ? <Error small title={error} /> : null}
        <div className="values-grid block">
          <ValueRow left={<Text field={fields.reviewCardNumberText} />} right={state.MediaId} />
          <ValueRow left={<Text field={fields.reviewVerificationNumberText} />} right={state.CVN} />
          {state.NickName ? (
            <ValueRow left={<Text field={fields.nicknameText} />} right={state.NickName} />
          ) : null}
        </div>
        <ButtonBlock>
          <ButtonBlock right>
            <Button
              type="submit"
              onClick={handleClick}
              buttonTextA11y={fields.confirmTextA11y.value}
            >
              <Text field={fields.confirmText} />
            </Button>
            <Button
              onClick={toPreviousStep}
              white
              firstOrder
              buttonTextA11y={fields.backButtonTextA11y.value}
            >
              <Text field={fields.backButtonText} />
            </Button>
          </ButtonBlock>

          <div className="mobile-button-line" />

          <Button
            buttonTextA11y={fields.cancelButtonTextA11y.value}
            onClick={() => setIsModalOpen(true)}
            firstOrder
            white
          >
            <Text field={fields.cancelButtonText} />
          </Button>
        </ButtonBlock>
      </div>
    </>
  );
};

export default AddPrestoCard2;
