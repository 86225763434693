import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  getLongLanguageCode,
  getQueryParams,
  formatDecimalWithCurrency,
} from '../../../services/utils';
import AgencyOption from '../AgencyOption';
import api from '../../../services/api';
import './AddTransitPass.scss';
import {
  setShoppingCart,
  addShoppingCartMessage,
  setCheckoutTransitPage,
  setGuestShoppingCartToken,
  setGuestShoppingCartPayload,
} from '../../../redux/user/actions';
import { setTransitPasses } from '../../../redux/products/actions';
import { ButtonBlock, Button, Loader } from '../../../atoms';
import { Error, Select, Note, Title, CardBlock, Modal } from '../../../molecules';
import downIcon from '../../../assets/images/down.svg';
import useDetectOutsideClick from '../../../hooks/useDetectOutsideClick';
import {
  CAPP_PRD_FAMILY,
  MAX_NO_OF_TRANSIT_PASSES,
  MDP_PRD_FAMILY,
  NEWMEDIA_VISIBLE_ID,
  SINGLE_FARE_CRD_CART_ITEM_NAME,
  NO_OF_BASIC_PERIOD,
} from '../../../constants/product.constants';
import '../transitpass.scss';
import TTCCheckout from './TTCCheckout';

const AddTransitPass = ({
  fields,
  t,
  state,
  setState,
  goToSetAutorenew,
  autorenewSubscriptionList,
  mergedSubscriptionList,
  rendering,
  action,
  isSuccess,
  error,
  pausedState,
}) => {
  const [loading, setLoading] = useState(false);
  const [isAccordionClicked, setIsAccordionClicked] = useState(false);
  const focusDiv = useRef();
  const [disableAgencyDropdown, setDisableAgencyDropdown] = useState(true);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const shoppingCart = useSelector((reduxState) => reduxState.user.shoppingCart);
  const selectedCard = useSelector((reduxState) => reduxState.user.selectedCard);
  const userInfo = useSelector((s) => s.user);
  const [is12MonthPass, setIs12MonthPass] = useState(false);
  const [TTCProduct, setTTCProduct] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [submittedValues, setSubmittedValues] = useState('');
  const checkoutTransitPage = useSelector((redux) => redux.user.checkoutTransitPage);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const checkAnyNonNewmediaItemInCart = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter((el) => el.visibleId !== NEWMEDIA_VISIBLE_ID);
      return check.length ? true : false;
    }
    return false;
  };
  const isProviderAddedToCart = (currentService) => {
    if (
      currentService &&
      shoppingCart &&
      shoppingCart.items &&
      shoppingCart.items.length &&
      selectedCard &&
      selectedCard.visibleId
    ) {
      let IsOrderCard = fields?.IsOrderCard?.value;
      let filteredItems = shoppingCart.items.filter(
        (item) =>
          item.serviceProvider === currentService &&
          (item.visibleId === selectedCard.visibleId ||
            (IsOrderCard && item.visibleId === NEWMEDIA_VISIBLE_ID))
      );
      if (filteredItems && filteredItems.length) {
        return true;
      }
    }
    return false;
  };
  const dispatch = useDispatch();
  const [discountResponse, setDiscountResponse] = useState('');
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);
  const onClickSelect = () => {
    openDropdown(!isArrowIconClicked);
    setIsAccordionClicked(true);
  };
  const iconText = isArrowIconClicked ? t('expandA11y') : t('collapseA11y');
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // counting months from 1 instead of 0 (e.g. January will be 1 not 0)

  function isMonthValid(selectedYear, monthOption) {
    if (selectedYear > currentYear) {
      return true;
    }
    return monthOption.value > currentMonth;
  }

  const getItemId = (values) => {
    const service = serviceOptions && serviceOptions.find((i) => i.value === values.service);
    const product =
      service && service.products && service.products.find((p) => p.ProductId === values.productId);
    return product?.ProductAttibutes?.NoOfBasicPeriod;
  };

  useEffect(() => {
    const serializedData = queryParams?.serializedData && JSON.parse(queryParams.serializedData);
    if (serializedData?.flow === 'ttc' && !is12MonthPass) {
      setIs12MonthPass(true);
      dispatch(setCheckoutTransitPage(true));
    } else {
      dispatch(setCheckoutTransitPage(false));
    }
    if (!userInfo.fareMedias || !userInfo.selectedCard) return;
    if (!guestUserInfo?.isGuestLogin) {
      api
        .getServiceProviders({
          visibleId: userInfo.selectedCard.visibleId,
          IsOrderCard: fields?.IsOrderCard?.value,
        })
        .then(({ data }) => {
          if (!data.Success) return;

          setServiceOptions(
            data.serviceProvider.map((i) => ({
              label: i.Name,
              value: i.Id,
              products: i.Products,
            }))
          );
          setDisableAgencyDropdown(!data.serviceProvider.length);
          dispatch(setTransitPasses(data));
        });
    } else {
      api
        .getTransitAgencyServiceProvidersforGuestFlow({
          visibleId: '',
          IsOrderCard: true,
        })
        .then(({ data }) => {
          if (!data.Success) return;

          setServiceOptions(
            data.serviceProvider.map((i) => ({
              label: i.Name,
              value: i.Id,
              products: i.Products,
            }))
          );
          setDisableAgencyDropdown(!data.serviceProvider.length);
          dispatch(setTransitPasses(data));
        });
    }

    api.getAutorenewCalendar().then((calendarResponse) => {
      if (!calendarResponse.data) return;

      const opts = {};
      Object.keys(calendarResponse.data).forEach((language) => {
        const dataPerLanguage = calendarResponse.data[language];

        const yearOptions = [...Array(dataPerLanguage.amountOfFutureYears + 1).keys()]
          .map((i) => i + currentYear)
          .map((year) => {
            return { value: year, label: year };
          });

        const monthOptions = Object.keys(dataPerLanguage.months).map((key) => {
          return { value: key, label: dataPerLanguage.months[key] };
        });

        opts[language] = { yearOptions, monthOptions };
      });
      setCalendarOptions(opts);
    });
  }, []);

  useEffect(() => {
    if (isPaymentModalOpen) {
      document.body.scrollTop = 0;
    }
  }, [isPaymentModalOpen]);

  useEffect(() => {
    if (checkoutTransitPage && autorenewSubscriptionList.length) {
      const serviceValue =
        autorenewSubscriptionList &&
        autorenewSubscriptionList.length > 0 &&
        autorenewSubscriptionList[0]?.Product &&
        autorenewSubscriptionList[0]?.Product?.ProductServiceProvider &&
        autorenewSubscriptionList[0]?.Product?.ProductServiceProvider?.Id;

      const productValue =
        autorenewSubscriptionList &&
        autorenewSubscriptionList.length > 0 &&
        autorenewSubscriptionList[0]?.Product &&
        autorenewSubscriptionList[0]?.Product?.ProductId;

      const service = serviceOptions && serviceOptions.find((i) => i.value === serviceValue);
      const product =
        service?.products && service.products.find((p) => p.ProductId === productValue);
      const chosenService = serviceOptions && serviceOptions.find((i) => i.value === serviceValue);
      const chosenServiceId = chosenService?.value;
      const choosenProduct =
        chosenService?.products && chosenService.products.find((p) => p.ProductId === productValue);

      if (product) setTTCProduct(product);
      if (choosenProduct && chosenServiceId) {
        setState({
          ...state,
          product: choosenProduct,
          serviceProviderId: chosenServiceId,
        });
      }
    }
    if (checkoutTransitPage) {
      setIs12MonthPass(true);
    } else {
      setIs12MonthPass(false);
    }
  }, [checkoutTransitPage]);

  useEffect(() => {
    if (isAccordionClicked) {
      if (focusDiv.current) {
        focusDiv.current.focus();
      }
    }
  }, [isArrowIconClicked]);

  const checkIsNewCardExist = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter(
        (el) =>
          el.name.toUpperCase() === SINGLE_FARE_CRD_CART_ITEM_NAME ||
          el.visibleId === NEWMEDIA_VISIBLE_ID
      );
      return check.length ? true : false;
    }
    return false;
  };
  function addToCart(productId) {
    let IsOrderCard = fields?.IsOrderCard?.value;
    let visibleIdCheck = fields?.IsOrderCard?.value
      ? NEWMEDIA_VISIBLE_ID
      : userInfo?.selectedCard?.visibleId;
    let IsNewCardAdded = checkIsNewCardExist();
    setLoading(true);
    api
      .addProductToCart({
        customerId: userInfo.customerId,
        visibleId: visibleIdCheck,
        productId,
        quantity: 1,
        IsNewCardAdded,
        IsOrderCard,
        Sct: guestUserInfo?.isGuestLogin && guestSCT?.sct ? guestSCT.sct : '',
        //  Cct: guestUserInfo?.isGuestLogin  && guestSCT?.cct  ? guestSCT.cct : '',
        IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
        IsAnonymousUser: anonymous ? true : false,
      })
      .then((res) => {
        setLoading(false);
        dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));

        //if (guestUserInfo?.isGuestLogin) {
        dispatch(setGuestShoppingCartToken(res.data.Cookies));
        // dispatch(
        //   setGuestShoppingCartPayload({
        //     productId,
        //     price: res.data.ShoppingCart.Order.TotalAmount,
        //   })
        // );
        //}
        if (checkIsNewCardExist()) {
          dispatch(addShoppingCartMessage(t('orderACardCheckoutNotification')));
        }
        dispatch(addShoppingCartMessage(t('shoppingCartNotificationsAddTransitPass')));
        setSelectedService('');
        setSelectedProductId('');
      })
      .catch(() => {
        setLoading(false);
      });
  }
  const checkIsTransitPassInCart = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter((el) => el.productFamily === CAPP_PRD_FAMILY);
      return check.length ? true : false;
    }
    return false;
  };
  const Heading = () => (
    <Title>
      <Text field={fields.addTransitPassTitle} />
    </Title>
  );
  const viewPaymentPlan = (productId) => {
    setLoading(true);
    api
      .getAutorenewPassDiscounts({
        productId,
        IsStubEnabled: false,
        IsErrorFlowEnabled: true,
      })
      .then((response) => {
        setLoading(false);
        setDiscountResponse(response?.data?.MDPDetails);
        setIsModalOpen(true);
        setIsPaymentModalOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        //console.log('error', error);
      });
  };
  const checkout = {
    value: 'Checkout',
  };
  const TransitPassContent = () => (
    <>
      {isPaymentModalOpen ? (
        <div className="payment-plan-modal">
          <Modal
            title={fields.TTC12MonthMDPTitle?.value}
            text={
              <>
                <RichText field={fields.TTC12MonthMDPDescription} />
                <div className="payment-plan-wrapper">
                  <div className="payment-plan-header">
                    <Text field={fields.TTC12MonthMDPSubTitle} />
                  </div>
                  <div className="payment-plan-content">
                    <div className="payment-plan-subtitle">
                      <span className="ttcTerm">
                        <Text field={fields.TermMonth} />
                      </span>
                      <span className="ttcDiscount">
                        <Text field={fields.TTCPassDiscount} />
                      </span>
                      <span className="ttcPrice">
                        <Text field={fields.DiscountPrice} />
                      </span>
                    </div>
                    {discountResponse &&
                      discountResponse.length > 0 &&
                      discountResponse.map((data) => {
                        return (
                          <div className="payment-plan-data">
                            <span className="ttcTerm">{data.Term}</span>
                            <span className="ttcDiscount">{data.Discount}%</span>
                            <span className="ttcPrice">
                              {formatDecimalWithCurrency(data.DiscountedPrice)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="payment-plan-button">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsPaymentModalOpen(false);
                    }}
                  >
                    {fields.CloseCTA?.value}
                  </Button>
                </div>
              </>
            }
            textSubmit={fields.CloseCTA?.value}
            isModalOpen={isModalOpen}
            onSubmit={() => {
              setIsModalOpen(false);
              setIsPaymentModalOpen(false);
            }}
            setIsModalOpen={setIsModalOpen}
            hideButtons
            withoutFocusTrap={true}
          />
        </div>
      ) : getItemId(submittedValues) === NO_OF_BASIC_PERIOD ? (
        <Modal
          title={fields.PaymentPlanPopupTitle?.value}
          text={fields.PaymentPlanPopupDescription}
          textCancel={fields.CancelCTA?.value}
          textSubmit={fields.SignUpCTA?.value}
          isModalOpen={isModalOpen}
          richtext
          onSubmit={() => {
            setIs12MonthPass(true);
            dispatch(setCheckoutTransitPage(true));
          }}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
      <div className={`${fields?.IsOrderCard?.value ? `order-a-card-content` : ``}`}>
        {mergedSubscriptionList.length < MAX_NO_OF_TRANSIT_PASSES ? (
          <Formik
            enableReinitialize
            initialValues={{
              service: selectedService,
              productId: selectedProductId,
              autoRenewCheck: false,
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              productId: Yup.string().required(t('transitPassSelectionRequired')),
              // })}
              // validate={(values) => {
              //   let errors = {};
              //   // if (values?.autoRenewCheck && !values.year) {
              //   //   errors.year = t('transitPassYearRequired');
              //   // } else if (values?.autoRenewCheck && !values.month) {
              //   //   errors.month = t('transitPassMonthRequired');
              //   // } else if (values?.autoRenewCheck && values.month && !values.year) {
              //   //   errors.year = t('transitPassYearRequired');
              //   // }
              //   return errors;
              // }}
              // month: Yup.number().ensure()
              month: Yup.number()
                .when(['year'], {
                  is: (year) => {
                    return year;
                  },
                  then: Yup.number().required(t('transitPassMonthRequired')),
                })
                .when(['year'], {
                  is: (year) => {
                    return year === currentYear;
                  },
                  then: Yup.number().min(currentMonth + 1, t('transitPassMonthRequired')),
                }),
              year: Yup.number(),
            })}
            onSubmit={(values) => {
              setSubmittedValues(values);
              const service =
                serviceOptions && serviceOptions.find((i) => i.value === values.service);
              const product =
                service.products && service.products.find((p) => p.ProductId === values.productId);
              if (product.ProductFamily === MDP_PRD_FAMILY && !fields?.IsOrderCard?.value) {
                setIsModalOpen(true);
                setTTCProduct(product);
                const chosenService =
                  serviceOptions && serviceOptions.find((i) => i.value === values.service);
                const chosenServiceId = chosenService?.value;
                const choosenProduct =
                  chosenService.products &&
                  chosenService.products.find((p) => p.ProductId === values.productId);
                // setState({
                //   ...state,
                //   product: choosenProduct,
                //   serviceProviderId: chosenServiceId,
                //   autorenew: {
                //     month: values.month,
                //     year: values.year,
                //   },
                // });
                setLoading(true);
                api
                  .getAutorenewPassDiscounts({
                    productId: product.ProductId,
                    IsStubEnabled: false,
                    IsErrorFlowEnabled: true,
                  })
                  .then((response) => {
                    setLoading(false);
                    if (response?.data?.MDPDetails?.length > 0) {
                      setState({
                        ...state,
                        product: choosenProduct,
                        serviceProviderId: chosenServiceId,
                        autorenew: {
                          month: values.month,
                          year: values.year,
                        },
                        discountedProduct: response?.data?.MDPDetails[0],
                      });
                      if (getItemId(values) !== NO_OF_BASIC_PERIOD) {
                        setIs12MonthPass(true);
                        dispatch(setCheckoutTransitPage(true));
                      }
                    }
                  })
                  .catch((error) => {
                    //console.log('error', error);
                  });
              } else if (!values?.autoRenewCheck) {
                addToCart(values.productId);
              } else if (values?.autoRenewCheck) {
                const chosenService =
                  serviceOptions && serviceOptions.find((i) => i.value === values.service);
                const chosenServiceId = chosenService?.value;
                const choosenProduct =
                  chosenService.products &&
                  chosenService.products.find((p) => p.ProductId === values.productId);
                setState({
                  ...state,
                  product: choosenProduct,
                  serviceProviderId: chosenServiceId,
                  autorenew: {
                    month: values.month,
                    year: values.year,
                  },
                });
                goToSetAutorenew();
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, setFieldValue, setFieldTouched }) => {
              const chosenService =
                serviceOptions && serviceOptions.find((i) => i.value === values.service);

              let checkIsProviderAddedToCart = isProviderAddedToCart(chosenService?.value);
              let checkIsAnyProviderAddedWithNewCard =
                checkIsNewCardExist() && checkIsTransitPassInCart();
              let checkAnyNonNewmediaItemInCartInOrderACard =
                fields?.IsOrderCard?.value && checkAnyNonNewmediaItemInCart();
              let checkAnyNewmediaItemInCartInAddPass =
                !fields?.IsOrderCard?.value && checkIsNewCardExist();
              function onKeyDown(keyEvent) {
                if ((((keyEvent.charCode || keyEvent.keyCode) === 13) ||
                  ((keyEvent.charCode || keyEvent.keyCode) === 32)) && (checkIsProviderAddedToCart ||
                    checkIsAnyProviderAddedWithNewCard ||
                    checkAnyNonNewmediaItemInCartInOrderACard ||
                    checkAnyNewmediaItemInCartInAddPass)) {
                  keyEvent.preventDefault();
                }
              }
              const handleChange = (e) => {
                const { name } = e.target;
                if (name === 'productId') {
                  setFieldValue('autoRenewCheck', false);
                  setFieldValue('month', '');
                  setFieldValue('year', '');
                }
                if (name === 'autoRenewCheck') {
                  setFieldValue('month', '');
                  setFieldValue('year', '');
                }

              }

              const handleChangedropdown = (e) => {
                setFieldValue('productId',"");
                 setFieldValue('autoRenewCheck', false);
                  setFieldValue('month', '');
                  setFieldValue('year', '');  
              }
              
              return (
                <div className="transitpass-add">
                  <form onSubmit={handleSubmit} onKeyDown={onKeyDown} onChange={handleChange}>
                    <Select
                      label={fields.agencyLabel.value}
                      name="service"
                      onChange={handleChangedropdown}
                      errors={errors}
                      touched={touched}
                      isDisabled={disableAgencyDropdown}
                      options={serviceOptions}
                      showMatAutoComplete={false}
                      showPlaceholder={true}
                      placeholder={fields.agencyDropdownPlaceholderText.value}
                      labelA11y={fields.agencyLabelA11y.value}
                      selectA11y={fields.agencyDropdownPlaceholderTextA11y.value}                     
                    />
                    {touched.productId && errors.productId ? (
                      <Error small title={errors.productId} titleA11y={errors.productId} />
                    ) : null}
                    {values.service &&
                      chosenService &&
                      (!chosenService.products[0] ||
                        chosenService.products?.some((product) =>
                          autorenewSubscriptionList?.some(
                            (subscription) => subscription.Product.ProductId === product.ProductId
                          )
                        )) ? (
                      <div
                        style={{ marginTop: 16 }}
                        aria-label={fields.unavailablePassesForOptionTextA11y.value}
                      >
                        <Text field={fields.unavailablePassesForOptionText} />
                      </div>
                    ) : (
                      <>
                        {values.service && !checkIsProviderAddedToCart ? (
                          <div className="options-block">
                            {chosenService.products.map((i) => {
                              if (
                                i?.ProductAttibutes?.NoOfBasicPeriod === 12 &&
                                fields?.IsOrderCard?.value
                              ) {
                                return null;
                              }
                              return (
                                <AgencyOption
                                  key={i.ProductId}
                                  product={i}
                                  name="productId"
                                  autoRenewName="autoRenewCheck"
                                  fields={fields}
                                  isSelectable
                                  showAutoRenew={
                                    authenticated && i.IsAutoRenewable && i.ProductFamily !== 'MDP'
                                      ? !fields?.IsOrderCard?.value
                                      : false
                                  }
                                  values={values}
                                  viewPaymentPlan={(data) => {
                                    viewPaymentPlan(data);
                                  }}
                                  t={t}
                                >
                                  <div className="description">
                                    <RichText field={fields.addAutorenewEndDateDescriptionText} />
                                  </div>
                                  <div className="label">
                                    <Text field={fields.addAutorenewEndDateText} />
                                  </div>
                                  <div className="transit-pass-renew-dropdowns">
                                    <div className="trasnit-pass-month-select">
                                      <Select
                                        onClose={() => {
                                          if (!values.month) setFieldValue('month', '');
                                        }}
                                        placeholder={fields.addAutorenewEndDateYearText.value}
                                        labelA11y={fields.addAutorenewEndDateYearTextA11y}
                                        name="year"
                                        options={calendarOptions[getLongLanguageCode()].yearOptions}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>

                                    <div className="trasnit-pass-year-select">
                                      <Select
                                        onClose={() => {
                                          if (!values.year) {
                                            setFieldTouched('year', true);
                                            setFieldValue('year', '');
                                          }
                                        }}
                                        placeholder={fields.addAutorenewEndDateMonthText.value}
                                        labelA11y={fields.addAutorenewEndDateMonthTextA11y}
                                        name="month"
                                        options={calendarOptions[
                                          getLongLanguageCode()
                                        ].monthOptions.filter((month) => {
                                          return isMonthValid(values.year, month);
                                        })}
                                        isDisabled={!values.year}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                </AgencyOption>
                              );
                            })}
                          </div>
                        ) : (
                          checkIsProviderAddedToCart && (
                            <div
                              className="option-already-added"
                              tabIndex="0"
                              aria-label={fields.transitPassAddedToCartA11y.value}
                            >
                              <Text field={fields.transitPassAddedToCart} />
                            </div>
                          )
                        )}
                        {values.service && !checkIsProviderAddedToCart ? (
                          <ButtonBlock
                            className={guestUserInfo?.isGuestLogin ? 'guest-login-add-pass' : ''}
                          >
                            <ButtonBlock right>
                              <Button
                                type="submit"
                                className="button"
                                isDisabled={
                                  checkIsProviderAddedToCart ||
                                  checkIsAnyProviderAddedWithNewCard ||
                                  checkAnyNonNewmediaItemInCartInOrderACard ||
                                  checkAnyNewmediaItemInCartInAddPass
                                }
                                buttonTextA11y={
                                  values.service === 'TTC' && !fields?.IsOrderCard?.value
                                    ? checkout.value
                                    : values?.autoRenewCheck
                                      ? fields.addAutorenewSetupText.value
                                      : fields.addToCartTextA11y.value
                                }
                              >
                                <Text
                                  field={
                                    values.service === 'TTC' &&
                                      !fields?.IsOrderCard?.value &&
                                      getItemId(values) === NO_OF_BASIC_PERIOD
                                      ? fields.SignUpCTA
                                      : values.service === 'TTC' && !fields?.IsOrderCard?.value
                                        ? checkout
                                        : values?.autoRenewCheck
                                          ? fields.addAutorenewSetupText
                                          : fields.addToCartText
                                  }
                                />
                              </Button>
                            </ButtonBlock>
                          </ButtonBlock>
                        ) : null}
                      </>
                    )}
                  </form>
                </div>
              );
            }}
          </Formik>
        ) : (
          <CardBlock cssClass="text-center">
            <strong>
              <Text field={fields.maxNumberOfTransitPassesReached} />
            </strong>
          </CardBlock>
        )}
        <Note
          cssClass="note-top-margin"
          Title={() => (
            <div aria-label={fields.transitPassNoteTitleA11y.value}>
              <RichText field={fields.transitPassNoteTitle} />
            </div>
          )}
          noteA11y={fields.transitPassNoteA11y.value}
        >
          <RichText field={fields.transitPassNote} />
        </Note>
      </div>
    </>
  );
  const AddTransitPassWithAccordion = () => (
    <div
      className="accordion-section"
      id={`${fields.IsOrderCard.value ? 'transit-pass-accordion' : ''}`}
    >
      <button
        onClick={onClickSelect}
        ref={focusDiv}
        aria-expanded={isArrowIconClicked}
        className="accordion-heading"
        id="transitpassAccordionID"
        aria-controls="transitpassAccordionRegionID"
      >
        <Heading />
        <img alt={iconText} className={isArrowIconClicked ? 'up' : ''} src={downIcon} />
      </button>
      {isArrowIconClicked && (
        <div
          className="accordion-wrapper"
          id="transitpassAccordionRegionID"
          aria-labelledby="transitpassAccordionRegionID"
        >
          <TransitPassContent />
        </div>
      )}
    </div>
  );
  if (loading) {
    return <Loader />;
  }
  if (!fields.IsOrderCard.value && is12MonthPass) {
    return (
      <TTCCheckout
        TTCProduct={TTCProduct}
        rendering={rendering}
        set12MonthPass={(data) => {
          setIs12MonthPass(data);
        }}
        serviceOptions={serviceOptions}
        state={state}
        setState={setState}
        action={action}
        isSuccess={isSuccess}
        error={error}
        setIsModalOpen={setIsModalOpen}
        pausedState={pausedState}
        autorenewSubscriptionList={autorenewSubscriptionList}
        transitPassfields={fields}
      />
    );
  }
  if (fields.IsOrderCard.value) {
    return <AddTransitPassWithAccordion />;
  }
  if (!fields.IsOrderCard.value) {
    return [<Heading />, <TransitPassContent />];
  }
};

export default withTranslation()(AddTransitPass);
