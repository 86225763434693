export default {
  SET_TRANSIT_PASSES: 'products/SET_TRANSIT_PASSES',
  SET_TRANSIT_AGENCY_LOGOS: 'products/SET_TRANSIT_AGENCY_LOGOS',
  SET_DISCOUNT_TYPES: 'products/SET_DISCOUNT_TYPES',
  SET_EDIT_CARD_MODE: 'products/SET_EDIT_CARD_MODE',
  SET_ACTIVATE_CARD_MODE: 'products/SET_ACTIVATE_CARD_MODE',
  SET_MANAGE_AUTO_RENEW_MODE: 'products/SET_MANAGE_AUTO_RENEW_MODE',
  SET_REDEEMVOUCHER_STEPPER_BLOCK: 'products/SET_REDEEMVOUCHER_STEPPER_BLOCK',
  SET_CHANGE_PIN: 'products/SET_CHANGE_PIN',
  SET_ACTIVATE_CARD_MYPRODUCTS: 'products/SET_ACTIVATE_CARD_MYPRODUCTS',
  SET_REMOVE_CARD: 'products/SET_REMOVE_CARD'
};
