import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { Button, ButtonBlock, Loader } from '../../atoms';
import { CardBlock } from '../../molecules';
import '../../molecules/page/Header/header.scss';
import { redirectToUrl } from '../../services/utils';
import './deleteAccountsuccess.scss';

const DeleteAccountSuccess = ({ fields }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const isAccountDeleted = userInfo.isAccountDeleted;
  const authenticated = userInfo.authenticated;
  const anonymous = userInfo.anonymous;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if ((authenticated && !isAccountDeleted) || (anonymous)) {
      setIsLoading(true);
      redirectToUrl(fields.DeleteAccountRedirectUrl?.value?.href);
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="delete-account-success">
        <CardBlock>
          <RichText field={fields?.Content} />
          <ButtonBlock>
            <ButtonBlock right>
              <Button
                buttonTextA11y={fields?.DoneButtonA11y?.value}
                onClick={() => redirectToUrl(fields?.DoneButtonLink?.value?.href)}
              >
                {fields?.DoneButtonText?.value}
              </Button>
            </ButtonBlock>
          </ButtonBlock>
        </CardBlock>
      </div>
    </>
  );
};

export default withTranslation()(DeleteAccountSuccess);
