import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';

import './advancedModal.scss';

import { Checkbox, Button, ButtonBlock } from '../../atoms';
import { localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';

export default function AdvancedModal({
  header,
  subHeader,
  buttonText,
  checkBoxText,
  showPopup,
  children,
  className,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setOpen(true);
    }
  }, [showPopup]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!showPopup) {
      handleClose();
    }
  }, [showPopup]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleClose}
      aria-labelledby="modal-header"
      aria-describedby="modal-subHeader"
      open={open}
    >
      <MuiDialogContent className={className}>
        {header || subHeader ? (
          <div className="advancedModal-header">
            <h2 id="modal-header" className="advancedModal-header-text">
              {header}
            </h2>
            <div id="modal-subHeader" className="advancedModal-header-subText">
              {subHeader}
            </div>
          </div>
        ) : null}
        <div className="content">{children}</div>
      </MuiDialogContent>
      {checkBoxText || buttonText ? (
        <div className="popup-footer-section">
          <ButtonBlock>
            {checkBoxText ? (
              <Checkbox
                checkboxA11y={checkBoxText}
                onChange={(value) => {
                  localstorageService().setItem(localStorageKeys.dismissedWelcomePopup, value);
                }}
              >
                {checkBoxText}
              </Checkbox>
            ) : null}
            {buttonText ? (
              <ButtonBlock right>
                <Button type="button" onClick={handleClose}>
                  {buttonText}
                </Button>
              </ButtonBlock>
            ) : null}
          </ButtonBlock>
        </div>
      ) : null}
    </Dialog>
  );
}
