import React from 'react';

import './inlineButtonBlock.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

export default function InlineButtonBlock({
  buttonText,
  onClick,
  buttonTextA11y,
  type = 'button',
  className = '',
}) {
  return (
    <div className={`inline-button-wrapper ${className ? className : ''}`}>
      {/* TODO Can we refactor above using ButtonBlock atom? */}
      {/* eslint-disable-next-line react/button-has-type */}
      {/* TODO Can we refactor this as using Button atom? */}
      <button onClick={onClick} type={type} className="inline-button" aria-label={buttonTextA11y}>
        <Text field={buttonText} />
      </button>
    </div>
  );
}
