import React from 'react';

import { Field } from 'formik';

import './RadioInput.scss';
import MediaImage from '../MediaImage';

export default function RadioInput({
  name,
  value,
  label,
  a11y,
  disabled,
  regularFont,
  cardImgSrc,
  cardImgAlt,
  sm,
  onChange = '',
  checked,
}) {
  return (
    <div className={`radioInput ${label ? 'withLabel' : ''}`}>
      {onChange !== '' ? (
        <Field
          aria-label={a11y}
          name={name}
          id={label}
          value={value}
          type="radio"
          disabled={disabled}
          onChange={onChange}
          checked={checked}
        />
      ) : (
        <Field
          aria-label={a11y}
          name={name}
          id={label}
          value={value}
          type="radio"
          disabled={disabled}
          checked={checked}
        />
      )}

      <label htmlFor={label} />
      {label ? (
        <div className={`radioInput-label ${regularFont ? 'regular-font' : ''}`}>
          {cardImgSrc ? (
            <MediaImage cardImgSrc={cardImgSrc} cardImgAlt={cardImgAlt} sm={sm} />
          ) : null}
          {label}
        </div>
      ) : null}
    </div>
  );
}
