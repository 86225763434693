import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import api from '../../services/api';
import routes from '../../constants/routes';
import { Error, Header, Warning } from '../../molecules';
import AddTransitPass from './AddTransitPass';
import TransitPassListing from './TransitPassListing';
import {
  hasAnyCard,
  getQueryParams,
  formatDate,
  formatDateWithComma,
  formatDateWithoutUTC,
} from '../../services/utils';
import './transitpass.scss';
import { setManageAutoRenewMode } from '../../redux/products/actions';
import { Button, ButtonBlock, Loader } from '../../atoms';
import { setCheckoutTransitPage, setMDPPausedPass } from '../../redux/user/actions';

const backToTransitPassesButtonLink = routes.transitPasses;

const mergeSubscriptionLists = (productList, autorenewList) => {
  const mergedList = [];
  autorenewList.forEach((autorenewSubscription) => {
    mergedList.push(autorenewSubscription);
  });

  productList.forEach((product) => {
    if (!mergedList.some((listItem) => listItem.Product.ProductId === product.ProductId)) {
      const subscription = {
        Product: product,
        StartDateTime: product.ValidityStartDate,
        ExpiryDateTime: product.ValidityEndDate,
      };
      mergedList.push(subscription);
    }
  });

  return mergedList;
};

const TransitPass = ({ fields, t, rendering }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState(false);
  const [showSetAutorenew, setShowSetAutorenew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCancelAutorenew, setShowCancelAutorenew] = useState(false);
  const [cancel12MonthTTCPass, setCancel12MonthTTCPass] = useState(false);
  const [productInstancesList, setProductInstancesList] = useState([]);
  const [mergedSubscriptionList, setMergedSubscriptionList] = useState([]);
  const [autorenewSubscriptionList, setAutorenewSubscriptionList] = useState([]);
  const visibleId = useSelector((s) => s.user.selectedCard.visibleId);
  const userInfo = useSelector((s) => s.user);
  const shoppingCart = useSelector((reduxState) => reduxState.user.shoppingCart);
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const manageAutoRenew = useSelector((reduxState) => reduxState.products.manageAutoRenew);
  const queryParams = getQueryParams(useLocation().search);
  const isSuccess = queryParams.success;
  const error = queryParams.error;
  const action = queryParams.action;
  const parsedCheckoutData = queryParams.serializedData;
  const renderingNew = rendering.placeholders['presto-transit-pass-autorenew-set'];
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const checkoutTransitPage = useSelector((s) => s.user.checkoutTransitPage);
  const autorenewSubscriptionData =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0];
  const pausedState = autorenewSubscriptionData.ContractState == 1;
  const reinstateState = autorenewSubscriptionData.ContractState == 2;
  useEffect(() => {
    const checkoutData = parsedCheckoutData ? JSON.parse(parsedCheckoutData) : {};
    if (
      !state.checkoutData &&
      action &&
      parsedCheckoutData &&
      !checkoutData?.isManageAutoRenew &&
      checkoutData.flow !== 'ttc'
    ) {
      if (action === 'set' && !showSetAutorenew) {
        setShowSetAutorenew(true);
      }
      setState({
        ...state,
        checkoutData,
        confirmationInfo: {
          orderNumber: queryParams.orderNumber,
          transaction: queryParams.transaction,
          message: queryParams.message,
          code: queryParams.code,
          reference: queryParams.reference,
          iso: queryParams.iso,
          transactionDate: queryParams.transactionDate,
        },
        isSuccess: isSuccess === 'true',
        isError: error,
      });
    }
  });
  const callSubscriptionList = () => {
    if (visibleId !== '') {
      setLoading(true);
      if (authenticated) {
        api
          .getAutorenewSubscriptionsForMedia({ visibleId })
          .then((response) => {
            if (response.data.Success && response.data.SubscriptionList) {
              setAutorenewSubscriptionList(response.data.SubscriptionList);
              dispatch(setMDPPausedPass(response.data.SubscriptionList));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }

      api
        .getProductsLoadedInMedia({ VisibleId: visibleId })
        .then((response) => {
          if (response.data.Success && response.data.productInstances) {
            setProductInstancesList(response.data.productInstances);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    callSubscriptionList();
    dispatch(
      setManageAutoRenewMode({
        visibility: false,
        instance: {},
        TCrendering: {},
        checkoutData: {},
      })
    );
  }, []);

  useEffect(() => {
    setMergedSubscriptionList(
      mergeSubscriptionLists(productInstancesList, autorenewSubscriptionList)
    );
  }, [autorenewSubscriptionList, productInstancesList]);

  useEffect(() => {
    const checkoutData = parsedCheckoutData ? JSON.parse(parsedCheckoutData) : {};
    if (checkoutData?.isManageAutoRenew) {
      if (!mergedSubscriptionList.length && !manageAutoRenew?.visibility) {
        callSubscriptionList();
      }
      if (
        mergedSubscriptionList.length &&
        manageAutoRenew.instance &&
        Object.keys(manageAutoRenew.instance).length === 0
      ) {
        let inst = mergedSubscriptionList.filter(
          (el) => el.Product?.ProductId === checkoutData?.productId
        );
        dispatch(
          setManageAutoRenewMode({
            visibility: true,
            TCrendering: rendering?.placeholders['presto-transit-pass-autorenew-set'][0] || {},
            checkoutData: {
              ...checkoutData,
              validityStartDate: inst.length ? inst[0].StartDateTime : checkoutData?.StartDateTime,
              validityEndDate: inst.length ? inst[0].ExpiryDateTime : checkoutData?.ExpiryDateTime,
            },
            instance: inst.length ? inst[0] : {},
          })
        );
      }
    }
  }, [mergedSubscriptionList]);
  let content;

  if (showSetAutorenew) {
    content = (
      <Placeholder
        name="presto-transit-pass-autorenew-set"
        rendering={rendering}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        state={state}
        setState={setState}
        action={action}
        isSuccess={isSuccess}
        error={error}
      />
    );
  }

  if (showCancelAutorenew) {
    let cancelAutoRenewPlaceholder = rendering.placeholders[
      'presto-transit-pass-autorenew-cancel'
    ].filter((el) => el.fields.isTTC.value === false);
    let onecancelPlaceholder;
    if (cancelAutoRenewPlaceholder.length > 1) {
      onecancelPlaceholder = cancelAutoRenewPlaceholder.splice(
        1,
        cancelAutoRenewPlaceholder.length
      );
    } else {
      onecancelPlaceholder = cancelAutoRenewPlaceholder;
    }
    let copyOfRendering = {
      ...rendering,
      placeholders: {
        ...rendering.placeholders,
        'presto-transit-pass-autorenew-cancel': [...onecancelPlaceholder],
      },
    };
    content = (
      <Placeholder
        name="presto-transit-pass-autorenew-cancel"
        rendering={copyOfRendering}
        TTCrendering={rendering}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        state={state}
        setState={setState}
        subscriptionInstance={selectedSubscription}
      />
    );
  }

  if (cancel12MonthTTCPass) {
    let cancel12MonthTTCPassPlaceholder = rendering.placeholders[
      'presto-transit-pass-autorenew-cancel'
    ].filter((el) => el.fields.isTTC.value);
    let onecancelPlaceholder;
    if (cancel12MonthTTCPassPlaceholder.length > 1) {
      onecancelPlaceholder = cancel12MonthTTCPassPlaceholder.splice(
        1,
        cancel12MonthTTCPassPlaceholder.length
      );
    } else {
      onecancelPlaceholder = cancel12MonthTTCPassPlaceholder;
    }
    let copyOfRendering = {
      ...rendering,
      placeholders: {
        ...rendering.placeholders,
        'presto-transit-pass-autorenew-cancel': [...onecancelPlaceholder],
      },
    };
    content = (
      <Placeholder
        name="presto-transit-pass-autorenew-cancel"
        rendering={copyOfRendering}
        TTCrendering={rendering}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        state={state}
        setState={setState}
        subscriptionInstance={selectedSubscription}
      />
    );
  }
  if (content) {
    return content;
  }
  if (manageAutoRenew?.visibility) {
    return null;
  }
  let deadLineDate = autorenewSubscriptionData?.MDPReinstateDeadline;
  deadLineDate = deadLineDate?.split('T');
  const formattedDate = formatDateWithoutUTC(
    deadLineDate && deadLineDate.length > 0 && deadLineDate[0]
  );
  let IsOrderCard = fields?.IsOrderCard?.value;

  return [
    <div className="transitPass-wrapper">
      {hasAnyCard(userInfo) ? (
        <>
          {!checkoutTransitPage && (
            <>
              {fields.IsOrderCard.value ? null : (
                <Header aria-label={fields.headingA11y.value}>
                  <Text field={fields.heading} />
                </Header>
              )}
              <Placeholder name="presto-system-status-message" rendering={rendering} />
              <Placeholder name="presto-my-products-balance" rendering={rendering} />
              {!IsOrderCard ? (
                <TransitPassListing
                  fields={fields}
                  t={t}
                  state={state}
                  setState={setState}
                  goToCancelAutorenew={(subscriptionInstance) => {
                    setSelectedSubscription(subscriptionInstance);
                    setShowCancelAutorenew(true);
                  }}
                  goToManageAutoRenew={(subscriptionInstance) => {
                    dispatch(
                      setManageAutoRenewMode({
                        visibility: true,
                        instance: subscriptionInstance,
                        TCrendering: rendering.placeholders['presto-transit-pass-autorenew-set'][0],
                        checkoutData: {},
                      })
                    );
                  }}
                  mergedSubscriptionList={mergedSubscriptionList}
                  cancel12MonthTTCPass={(subscriptionInstance) => {
                    setSelectedSubscription(subscriptionInstance);
                    setCancel12MonthTTCPass(true);
                  }}
                />
              ) : null}
              {!IsOrderCard && (pausedState || reinstateState) ? (
                <div>
                  {reinstateState ? (
                    <Warning
                      title={fields?.TTCPaymentInProgress}
                      description={
                        <div className="warning-Block">
                          <RichText field={fields.TTCPaymentInProgressDescription} />
                          <div className="pause-deadline">
                            <span className="deadLine-lable" id="content1">
                              <Text field={fields.DeadLineLable} />
                            </span>
                            <span className="payment-Attempts"> {formattedDate}</span>
                          </div>
                          <div className="update-attempts">
                            <span id="content1">
                              <Text field={fields.AttemptsRemaining} />
                            </span>
                            <span className="payment-Attempts">
                              {' '}
                              {autorenewSubscriptionData?.MDPPaymentRetryRemainingCounter}
                            </span>
                          </div>
                          <ButtonBlock className="buttonClass">
                            <Button isDisabled>
                              <Text field={fields.UpdatePaymentCTA} />
                            </Button>
                          </ButtonBlock>
                        </div>
                      }
                      titleA11y={fields?.TTCPaymentInProgressA11y.value}
                      descriptionA11y={fields?.TTCPaymentInProgressA11y.value}
                      custom
                    />
                  ) : (
                    <Error
                      title={fields?.TtcCautionTitle.value}
                      titleA11y={fields?.TtcCautionTitleAlly.value}
                      text={
                        <div>
                          <RichText field={fields.TtcPassUpdateDescription} />
                          <div className="pause-deadline">
                            <span className="deadLine-lable" id="content1">
                              <Text field={fields.DeadLineLable} />
                            </span>
                            <span className="payment-Attempts"> {formattedDate}</span>
                          </div>
                          <div className="update-attempts">
                            <span id="content1">
                              <Text field={fields.AttemptsRemaining} />
                            </span>
                            <span className="payment-Attempts">
                              {' '}
                              {autorenewSubscriptionData?.MDPPaymentRetryRemainingCounter}
                            </span>
                          </div>
                          <ButtonBlock className="buttonClass">
                            <Button
                              isDisabled={reinstateState ? true : false}
                              onClick={() => {
                                dispatch(setCheckoutTransitPage(true));
                              }}
                            >
                              <Text field={fields.UpdatePaymentCTA} />
                            </Button>
                          </ButtonBlock>
                        </div>
                      }
                      custom
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
          <AddTransitPass
            fields={fields}
            rendering={renderingNew}
            t={t}
            state={state}
            setState={setState}
            goToSetAutorenew={() => setShowSetAutorenew(true)}
            autorenewSubscriptionList={autorenewSubscriptionList}
            mergedSubscriptionList={mergedSubscriptionList}
            action={action}
            isSuccess={isSuccess}
            error={error}
            pausedState={pausedState}
            reinstateState={reinstateState}
          />
        </>
      ) : (
        <Placeholder
          name="presto-my-products-overview"
          rendering={rendering}
          linkableHeadingForNoMedia={false}
        />
      )}
      {guestUserInfo?.isGuestLogin ? (
        <AddTransitPass
          fields={fields}
          rendering={renderingNew}
          t={t}
          state={state}
          setState={setState}
          goToSetAutorenew={() => setShowSetAutorenew(true)}
          autorenewSubscriptionList={autorenewSubscriptionList}
          mergedSubscriptionList={mergedSubscriptionList}
          action={action}
          isSuccess={isSuccess}
          error={error}
          pausedState={pausedState}
          reinstateState={reinstateState}
        />
      ) : null}
    </div>,
    loading ? <Loader /> : null,
  ];
};
export default withTranslation()(TransitPass);
