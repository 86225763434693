import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { SkipContentLink } from '../../organisms';
import './contentPageLayout.scss';
import './contactlessDashboard.scss';
import './prestoSideNav.scss';
import './prestoContentPageLayout.scss';
import { scrollToView } from '../../services/utils';
import { setDeactivateCardType } from '../../redux/user/actions';

const sideBarId = 'sidebar';

const ContentPageLayout = ({ rendering, t }) => {
  const pageRef = useRef();
  const dispatch = useDispatch();
  const [showcontent, setShowContent] = useState(false);
  const [cso, setCso] = useState(false);
  const [isDeleteRequestpage, setIsDeleterequestpage] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);
  const [ContentPage, setIsContentPage] = useState("");

  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');

    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
    setIsSkipTranslateHidden(skipTranslateElement && getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none');
    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');
    if (targetElement && !isSkipTranslateHidden) {
      if (!smartBanner) {
        targetElement.style.top = '0px';
      }
      else {
        targetElement.style.top = '80px';
      }
    }

    let contentPageLayoutclass = '';
    if (window.location.href.toLowerCase().includes("about/contactless")) {

      if (smartBanner && targetElement && !isSkipTranslateHidden) {
        contentPageLayoutclass = 'contentPageLayout-header-banner';
      } else if (document.querySelector('html.smartbanner-show')) {
        contentPageLayoutclass = 'contentPageLayout-header-banner';
      } else if (targetElement && !isSkipTranslateHidden) {
        contentPageLayoutclass = 'contentPageLayout-header-contactless';
      } else {
        contentPageLayoutclass = 'contentPageLayout-header';
      }
    }
    else if (smartBanner) {
      contentPageLayoutclass = 'contentPageLayout-header-padding';
    } else {
      contentPageLayoutclass = 'contentPageLayout-header';
    }
    setIsContentPage(contentPageLayoutclass);

    if (window.location.href.toLowerCase().includes("support/faqs/using-presto")) {
      const notificationBanner = document.querySelector(".NotificationBanner-wrapper");
      if (notificationBanner) {
        notificationBanner.style.paddingTop = "56px";
      }
    }


  };



  useEffect(() => {
    // Call the handleDOMChanges function initially
    handleDOMChanges();

    // Set up a recurring check using setInterval
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 500); // Check every 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array to run only once on component mount


  useEffect(() => {
    if (Object.keys(rendering.params).length) {
      if (rendering?.params?.isCso === "true") {
        setCso(true);
        setShouldRender(false);
      }
      if (rendering?.params?.isDeleteReqestPage === "true") {
        setIsDeleterequestpage(true)
      }
    }
    else {
      setShouldRender(true);
    }
  }, [rendering.params]);

  useEffect(() => {
    dispatch(setDeactivateCardType(''));
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setShowContent(true);
      }
    }, 100);
  }, []);

  // useEffect(() => {
  //   pageRef.current.focus();
  //   let lastScrollPos = localStorage.getItem('scrollpos');
  //   if (lastScrollPos && typeof window !== 'undefined') {
  //     window.scroll(0, lastScrollPos);
  //   }
  // }, []);
  if (typeof window !== 'undefined') {
    window.onbeforeunload = function (e) {
      localStorage.setItem('scrollpos', window.scrollY);
    };
  }
  // useEffect(() => {
  //   let timer;
  //   if (window?.location?.hash) {
  //     timer = setTimeout(scrollToView, 500);
  //   }
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    // <>
    //   <SkipContentLink linkText={t('skipMainMenuText')} linkToId={sideBarId} />
    //   <div className="contentPageLayout">
    //     <div className="contentPageLayout-header">
    //       <Placeholder name="presto-header" rendering={rendering} />
    //     </div>
    //     <div className="contentPageLayout-main">
    //       <div id={sideBarId} className="contentPageLayout-sidebar">
    //         <Placeholder name="presto-side-content" rendering={rendering} />
    //       </div>
    //       <div className="contentPageLayout-content">
    //         <Placeholder name="presto-main-content" rendering={rendering} />
    //       </div>
    //     </div>
    //     <div className="contentPageLayout-cta">
    //       <Placeholder name="presto-ctasection" rendering={rendering} />
    //     </div>
    //     <div className="contentPageLayout-footer">
    //       <Placeholder name="presto-footer" rendering={rendering} />
    //     </div>
    //   </div>
    // </>

    <>
      <SkipContentLink linkText={t('skipMainMenuText')} linkToId={sideBarId} />
      <div className={ContentPage}>
        <Placeholder name="presto-header" rendering={rendering} />
      </div>
      <div className={`newMainPageContent ${showcontent ? '' : 'd-none'}`}>
        <section id={sideBarId} className="new-pagesidenav">
          <Placeholder name="presto-side-content" rendering={rendering} />
        </section>
        <section className={cso ? 'cso-height' : isDeleteRequestpage ? "new-mainContent delete-main-content" : "new-mainContent"} >
          <Placeholder name="presto-main-content" rendering={rendering} />
        </section>
      </div>
      {cso &&
        <div className={`newMainPageContent ${cso ? 'map-content' : 'd-none'}`}>
          <Placeholder name="presto-ctasection" rendering={rendering} />
        </div>
      }

      <footer className={`new-pageFooter ${showcontent ? '' : 'd-none'}`}>
        {shouldRender &&
          <div className="contentPageLayout-cta">
            <Placeholder name="presto-ctasection" rendering={rendering} />
          </div>}
        <Placeholder name="presto-footer" rendering={rendering} />
      </footer >
    </>
  );
};

export default withTranslation()(ContentPageLayout);
