import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  convertToDollars,
  formatUtcDateToEasternTime,
  formatUtcTimeFromDateToEasternTime,
  formatDecimalWithCurrency,
  isOneOf,
  getdateTimefromESTdate,
} from '../../../services/utils';
import { Modal } from '../../../molecules';
import transitAgencyIds from '../../../constants/transitAgencyIds';
import CASES from '../../../constants/transactionCases';
import otherLogo from '../../../assets/images/Other.svg';
import './transaction.scss';
import api from '../../../services/api';

const Label = ({ children, ariaLabel }) => <span aria-label={ariaLabel}>{children}</span>;

export default function Transaction({
  data,
  transactionCase,
  isPendingFare,
  textMapping,
  fields,
  unpaid,
  transactionStatus,
}) {
  const [discountTypes, setDiscountTypes] = useState({});

  useEffect(() => {
    async function fetchDiscountTypes() {
      api.getDiscountTypes().then((response) => {
        if (response.status === 200) {
          setDiscountTypes(response.data);
        }
      });
    }
    fetchDiscountTypes();
  }, []);
  //---------------------------------------------------------------------------------
  const isContactlessUserSignedIn = useSelector(
    (state) => state.contactless.isContactlessUserSignedIn
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getTransactionLabel = () => {
    const usageType = data.Usage?.usageType;
    const tapType = data.Usage?.tripInfo?.tapType;
    const tapMode = data.Usage?.tripInfo?.tapMode;
    const transactionType = data.Usage?.tripInfo?.transactionType;

    if (transactionCase === CASES.pendingFare) {
      return (
        <Label ariaLabel={fields.pendingFareLabelA11y?.value}>
          {fields.pendingFareLabel?.value}
        </Label>
      );
    }
    if (data.linkedUsageUuid && usageType !== 'REVERSE_TAP' && usageType !== 'REFUND') {
      return (
        <Label ariaLabel={fields.fareAdjustmentLabelA11y?.value}>
          {fields.fareAdjustmentLabel?.value}
        </Label>
      );
    }
    if (tapType === 'TAP_ON' && transactionType === 16 && usageType === 'DIRECT_TAP') {
      return (
        <Label ariaLabel={fields.transferLabelA11y?.value}>{fields.transferLabel?.value}</Label>
      );
    }
    if (tapMode === 'TAP_ON_ONLY' && usageType === 'DIRECT_TAP' && tapType === 'TAP_ON') {
      return (
        <Label ariaLabel={fields.farePaymentLabel?.value}>{fields.farePaymentLabel?.value}</Label>
      );
    }

    if (data.linkedUsageUuid && usageType === 'REVERSE_TAP') {
      return (
        <Label ariaLabel={fields.fareReversalLabelA11y?.value}>
          {fields.fareReversalLabel?.value}
        </Label>
      );
    }

    if (tapType === 'TAP_ON' && usageType === 'DIRECT_TAP') {
      return <Label ariaLabel={fields.tapOnLabelA11y?.value}>{fields.tapOnLabel?.value}</Label>;
    }

    if (tapType === 'TAP_OFF_AUTOMATIC' && usageType === 'DIRECT_TAP') {
      return (
        <button type="button" className="link" onClick={() => setIsModalOpen(true)}>
          <Label ariaLabel={fields.automaticTapOffLabelA11y?.value}>
            {fields.automaticTapOffLabel?.value}
          </Label>
        </button>
      );
    }

    if (tapType === 'TAP_OFF' && usageType === 'DIRECT_TAP') {
      return <Label ariaLabel={fields.tapOffLabelA11y?.value}>{fields.tapOffLabel?.value}</Label>;
    }

    if (tapType === 'TAP_ON_DEFAULT_TRIP' && usageType === 'DIRECT_TAP') {
      return <Label ariaLabel={fields.defaultTripA11y?.value}>{fields.defaultTrip?.value}</Label>;
    }

    if (usageType === 'REFUND') {
      return (
        <Label ariaLabel={fields.fareRefundLabelA11y?.value}>{fields.fareRefundLabel?.value}</Label>
      );
    }

    if (isOneOf(['INSPECTION_REGULAR_FARE', 'INSPECTION_PENALTY_FARE'], usageType)) {
      return (
        <Label ariaLabel={fields.inspectionFareLabelA11y?.value}>
          {fields.inspectionFareLabel?.value}
        </Label>
      );
    }

    if (
      (tapType === 'UNDEFINED' &&
        usageType === 'DIRECT_TAP' &&
        data.Usage?.tripInfo?.acceptanceStatus === 'REJECTED_HOTLISTED') ||
      data.Usage?.tripInfo?.acceptanceStatus === 'REJECTED_PASSBACK' ||
      'REJECTED_EXCEPTION'
    ) {
      return (
        <Label ariaLabel={fields.tapNotAcceptedLabelA11y?.value}>
          {fields.tapNotAcceptedLabel?.value}
        </Label>
      );
    }

    return (
      <Label ariaLabel={fields.undefinedLabelA11y?.value}>{fields.undefinedLabel?.value}</Label>
    );
  };

  const getStationName = () => {
    const usageType = data.Usage?.usageType;
    const tapType = data.Usage?.tripInfo?.tapType;

    if (
      tapType === 'TAP_OFF_AUTOMATIC' &&
      usageType === 'DIRECT_TAP' &&
      transactionStatus !== 'OPEN'
    ) {
      return '';
    }
    if (Object.keys(textMapping?.locations || {}).length) {
      return textMapping?.locations[data.serviceProviderId][data.Usage?.location?.locationId]?.name;
    }
    return '';
  };

  const getTransitAgencyName = () => {
    const usageType = data.Usage?.usageType;
    const tapType = data.Usage?.tripInfo?.tapType;
    if (
      tapType === 'TAP_OFF_AUTOMATIC' &&
      usageType === 'DIRECT_TAP' &&
      transactionStatus !== 'OPEN'
    ) {
      return '';
    }
    return textMapping.serviceProvider[data.serviceProviderId].name;
  };
  const label = getTransactionLabel();

  const stationName = getStationName();

  const transitagencyName = getTransitAgencyName();
  // const discountTypes = useSelector((state) => state.products?.discounts);
  const Discounts = ({ discountName, discountPrice }) => {
    if (
      discountPrice === 0 ||
      discountTypes === null ||
      transactionCase === CASES.pendingFare ||
      data.Usage?.usageType === 'REVERSE_TAP' ||
      data.Usage?.usageType === 'REFUND' ||
      (data.Usage?.tripInfo?.tapType === 'TAP_ON' &&
        data.Usage?.tripInfo?.transactionType !== 16 &&
        data.Usage?.usageType === 'DIRECT_TAP' &&
        data.Usage?.tripInfo?.tapMode !== 'TAP_ON_ONLY' &&
        data.serviceProviderId === 1)
    ) {
      return '';
    }
    return (
      <div>
        {discountTypes[discountName]} : {formatDecimalWithCurrency(convertToDollars(discountPrice))}
      </div>
    );
  };
  const logo = useSelector(
    (state) => state.products?.logos[transitAgencyIds[data.serviceProviderId]]?.Logo
  );
  if (discountTypes === null) return null;
  return (
    <>
      <div tabIndex="0" className="transaction">
        <div className="transaction-head">
          <div className="transaction-logo-block">
            {logo && logo.Src ? (
              <img alt={logo.Alt} className="checkoutGrid-logo" src={logo.Src} />
            ) : (
              <img
                alt="Logo - GTAFS Central System"
                className="checkoutGrid-logo"
                src={otherLogo}
              />
            )}
          </div>
          <div className="transaction-right">
            <div className="transaction-block">
              <div>
                <div className="transaction-title">{label}</div>
                <div className="transaction-subTitle desktopOnly">
                  {`${
                    isContactlessUserSignedIn
                      ? data.displayTime
                      : formatUtcTimeFromDateToEasternTime(data.displayTime)
                  } ${
                    isContactlessUserSignedIn ? '' : formatUtcDateToEasternTime(data.displayTime)
                  }`}
                </div>
                <div className="transaction-subTitle">
                  {isContactlessUserSignedIn ? '' : stationName}
                </div>
                <div className="transaction-agencyTitle mobileOnly">{transitagencyName}</div>
              </div>
            </div>
            <div className="transaction-value-block">
              <div className="transaction-title">
                {isPendingFare ? '-' : formatDecimalWithCurrency(convertToDollars(data.amount))}
              </div>
              <div className="transaction-subTitle mobileOnly">
                {isContactlessUserSignedIn
                  ? data.displayTime
                  : formatUtcTimeFromDateToEasternTime(data.displayTime)}
                <br />
                {isContactlessUserSignedIn ? '' : formatUtcDateToEasternTime(data.displayTime)}
              </div>
            </div>
          </div>
        </div>
        <div className="transaction-pointer" />
        <div
          className={
            data?.Usage?.tripInfo?.tripDiscountItems?.length &&
            transactionCase !== CASES.pendingFare &&
            data.Usage?.usageType !== 'REVERSE_TAP' &&
            data.Usage?.usageType !== 'REFUND' &&
            (data.Usage?.tripInfo?.tapType !== 'TAP_ON' ||
              data.Usage?.tripInfo?.transactionType === 16 ||
              data.Usage?.usageType !== 'DIRECT_TAP' ||
              data.Usage?.tripInfo?.tapMode === 'TAP_ON_ONLY' ||
              data.serviceProviderId !== 1)
              ? 'transaction-discount'
              : ''
          }
        >
          <div className="transaction-discount-value">
            {data.Usage?.tripInfo?.tripDiscountItems.map((i) => (
              <Discounts discountName={i.discountType} discountPrice={i.discountAmount} />
            ))}
          </div>
        </div>
      </div>
      {!unpaid && (
        <Modal
          title={fields.missedTapOffModalTitle.value}
          text={fields.missedTapOffModalText.value}
          textSubmit={fields.missedTapOffModalCloseButtonText.value}
          onSubmit={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
}
