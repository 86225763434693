import React from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { hideString } from '../../services/utils';
import { Button, ButtonBlock, Loader } from '../../atoms';
import { Error, Header, Success, ValueRowSecond } from '../../molecules';

export default function AccountSecurity0({
  toNextStep,
  fields,
  userInfo,
  state,
  questions,
  answers,
  error,
}) {
  let content = <Loader />;
  let statusContent = null;
  if (questions?.length > 0 && answers?.length > 0) {
    content = (
      <div className="accountSecurity-wrapper">
        <span>
          <RichText field={fields.description} aria-label={fields.descriptionA11y.value} />
        </span>
        <ValueRowSecond left={fields.usernameLabel.value} right={userInfo.userName} />
        <ValueRowSecond left={fields.securityQuestion1Label.value} right={questions[0].label} />
        <ValueRowSecond
          left={fields.securityAnswer1Label.value}
          right={hideString(answers[0].answer)}
        />
        <ValueRowSecond left={fields.securityQuestion2Label.value} right={questions[1].label} />
        <ValueRowSecond
          left={fields.securityAnswer2Label.value}
          right={hideString(answers[1].answer)}
        />
        <ValueRowSecond left={fields.securityQuestion3Label.value} right={questions[2].label} />
        <ValueRowSecond
          left={fields.securityAnswer3Label.value}
          right={hideString(answers[2].answer)}
        />
        <ButtonBlock>
          <ButtonBlock right>
            <Button
              type="button"
              onClick={toNextStep}
              buttonTextA11y={fields.updateButtonTextA11y.value}
            >
              <Text field={fields.updateButtonText} />
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      </div>
    );
  }
  if (error) {
    content = <Error title={error} />;
  }

  if (state.securityQuestionsResult && state.securityQuestionsResult === 'updated') {
    statusContent = (
      <Success
        title={fields.successfulUpdateMessage}
        titleA11y={fields.successfulUpdateMessageA11y.value}
      />
    );
  }
  if (state.securityQuestionsResult && state.securityQuestionsResult === 'notUpdated') {
    statusContent = (
      <Error
        title={fields.unSuccessfulUpdateMessage.value}
        titleA11y={fields.unSuccessfulUpdateMessageA11y.value}
        text={fields.unSuccessfulUpdateBellowMessage.value}
        textA11y={fields.unSuccessfulUpdateBellowMessageA11y.value}
      />
    );
  }

  return (
    <>
      <Header aria-label={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>
      {statusContent}
      {content}
    </>
  );
}
