import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
// import moment from 'moment';

import { formatDate, formatDecimalWithCurrency } from '../../services/utils';
import Transaction from '../TransactionHistoryContactless/Transaction';
import { ButtonBlock, Button } from '../../atoms';
import routes from '../../constants/routes';
import { getLanguageAtsId, getQueryParams, hasAnyCard, localstorageService, redirectToUrl } from '../../services/utils';

import './unpaidTransactions.scss';

const UnpaidTransactions = ({
    data,
    toNextStep,
    fields,
    state,
    setState,
    unpaidFareIndex,
    isButton,
    textMapping,
}) => {
    const totalAmount = data ? data.lines.reduce((acc, val) => acc + val.amount, 0) : 0;
    const isInteracCard = data.mediaSubType === 'OPENP_INTR';

    const handleClick = (index) => {
        setState({
            ...state,
            uuid: data?.uuid || '',
            unpaidFareIndex: index,
        });
        const returnpath = `${routes.unpaidFares}?id=${data?.uuid}`;
        redirectToUrl(`${returnpath}`);

        //toNextStep();
    };

    if (!textMapping) return null;

    return (
        <>
            <div className="unpaidTransaction state-unpaidFare">
                <div className="unpaidTransaction-head">
                    <div>{formatDate(data.createdTime)}</div>
                    <div>
                        <span>
                            {fields?.totalLabel.value}
                            {formatDecimalWithCurrency(parseFloat(totalAmount / 100))}
                        </span>
                    </div>
                </div>
                <div className="unpaidTransaction-more">
                    <div className="unpaidTransaction-transactions">
                        {data.lines.map((item) => (
                            <Transaction unpaid textMapping={textMapping} data={item} fields={fields} />
                        ))}
                    </div>
                    <div className="unpaidTransaction-fare">
                        <Text field={fields.unpaidFareTransactionsLabel} />
                    </div>
                </div>
            </div>
            {isButton && !isInteracCard ? (
                <ButtonBlock>
                    <ButtonBlock right>
                        <Button type="submit" className="button" onClick={() => handleClick(unpaidFareIndex)}>
                            <Text field={fields.payNowButtonText} />
                        </Button>
                    </ButtonBlock>
                </ButtonBlock>
            ) : null}
        </>
    );
};

export default UnpaidTransactions;
