import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { Error } from '../../../molecules';
import { PrestoLink } from '../../../atoms';

const VerifyCardInteracAlert = ({ fields }) => {
  return (
    <>
      <div className="contactlessTransactionHistory-unpaidFare-error">
        <div className={`content-error-block`}>
          <div title={fields?.verifyCardInteracTitle?.value} noMargin>
            <div className="content-error-block-title" noMargin>{fields?.verifyCardInteracTitle?.value}</div>

            <div className="contactless-unpaid-fare--desc">
              <RichText field={fields?.verifyCardInteracDescription} />
            </div>

            <div className="contactlessTransactionHistory-unpaidFare-link">
              <PrestoLink field={fields?.verifyCardInteracLearnMoreLink} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(VerifyCardInteracAlert);
