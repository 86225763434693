import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button, IconButton } from '../../atoms';
import printIcon from '../../assets/images/printer.svg';
import Heading from '../Heading';
import { convertToDollars, formatDate, formatDecimalWithCurrency } from '../../services/utils';

function ContactlessUnpaidFareSuccess({
  fields,
  isSingleUnpaidMode,
  unpaidSaleOrders,
  backToHome,
  processNextUnpaidFare,
  responseData,
  t,
}) {
  return (
    <div className="unpaid-fare-success-container">
      <Heading
        fields={{ Title: fields?.PaymentSuccessTitle, ID: { value: 'unpaid-fare-success-title' } }}
      />
      <div className="unpaid-fare-success-content" role="alert">
        <div className="unpaid-fare-success--title">
          <span id="message">
            {isSingleUnpaidMode
              ? fields?.SuccessMessage?.value
              : fields?.MultipleSalesOrderMessage?.value}
          </span>
        </div>
        {isSingleUnpaidMode ? null : (
          <div className="unpaid-fare-success--subtext">
            <span id="message">{fields?.MultipleSalesOrderBodyText?.value}</span>
          </div>
        )}
        <ul className="unpaid-fare-success--info">
          <li>
            <div className="key">{fields?.AmountTitleText?.value}</div>
            <div className="value">
              {formatDecimalWithCurrency(convertToDollars(responseData?.Amount))}
            </div>
          </li>
          <li>
            <div className="key">{fields?.PaymanetReceivedText?.value}</div>
            <div className="value">{formatDate(responseData?.ReceivedDateTime)}</div>
          </li>
          <li>
            <div className="key">{fields?.ConfirmationNumberTitle?.value}</div>
            <div className="value">#{responseData?.ReferenceNumber}</div>
          </li>
        </ul>
        <div className="unpaid-fare-success--subtext">
          <span id="message">
            {`${fields?.FooterText1?.value} ${t('unpaidFareCardIdentifier')}/${
              responseData?.ReferenceNumber
            }`}
          </span>
        </div>
        <div className="unpaid-fare-success--subtext">
          <span id="message">{fields?.FooterText2?.value}</span>
        </div>
      </div>
      <div className="unpaid-fare-success--footer">
        <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="-1">
          Print
        </IconButton>
        <div className="unpaid-fare--float-right">
          <Button white={isSingleUnpaidMode ? false : true} firstOrder onClick={() => backToHome()}>
            <Text field={fields?.BackToHomeButtonText} />
          </Button>
          {!isSingleUnpaidMode && unpaidSaleOrders.length ? (
            <Button onClick={() => processNextUnpaidFare()}>
              <Text field={fields?.NextUnpaidButtonText} />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ContactlessUnpaidFareSuccess;
