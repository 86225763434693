import React from 'react';
import './iconWithText.scss';

const IconWithText = ({ text, iconSrc, iconAlt = '', isBold = false }) => (
  <div className="iconWithText">
    <img className="iconWithText-icon" src={iconSrc} alt={iconAlt} />
    <span className={`iconWithText-text${isBold ? ' isBold' : ''}`}>{text}</span>
  </div>
);

export default IconWithText;
