import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';

import './transactionFilters.scss';
import { Button, ButtonBlock } from '../../../atoms';
import { DateRange, PrestoDateRange } from '../../../molecules';

const TransactionFilters = ({ filters, setFilters, fields, setIsFilters, clearFilters, t }) => {
  const [datepickerValue, setDatepickerValue] = useState(filters.PeriodId);
  return (
    <div className="transactionFilters">
      <Formik
        enableReinitialize
        initialValues={{
          ...filters,
        }}
        onSubmit={(values) => {
          setFilters({ ...values, PeriodId: datepickerValue });
          setIsFilters(false);
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, setFieldError, resetForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="transactionFilters-block">
                {/* <DateRange
                  values={values}
                  setFieldValue={setFieldValue}
                  name="PeriodId"
                  fromName="FromDate"
                  toName="ToDate"
                  errors={errors}
                  setFieldError={setFieldError}
                /> */}

                <PrestoDateRange
                  fields={fields}
                  values={values}
                  setFieldValue={setFieldValue}
                  name="PeriodId"
                  fromName="FromDate"
                  toName="ToDate"
                  datepickerValue={datepickerValue}
                  setDatepickerValue={setDatepickerValue}
                  errors={errors}
                  setFieldError={setFieldError}
                  t={t}
                  showSelectedDateRange={true}
                />
              </div>
              <ButtonBlock>
                <ButtonBlock right>
                  <Button type="submit" buttonTextA11y={fields.applyButtonTextA11y.value}>
                    <Text field={fields.applyButtonText} />
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      clearFilters();
                    }}
                    white
                    firstOrder
                    buttonTextA11y={fields.clearButtonTextA11y.value}
                  >
                    <Text field={fields.clearButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TransactionFilters;
