import React, { useEffect, useState } from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import api from '../../../services/api';
import { Error } from '../../../molecules';
import mediaTypes from '../../../constants/mediaTypes';
import { ButtonBlock, Button, PrestoLink } from '../../../atoms';
import {
  getLanguageAtsId,
  redirectToUrl,
  formatDecimalWithCurrency,
  convertToDollars,
} from '../../../services/utils';

const UnpaidFaresAlert = ({
  fields,
  isInteracCard,
  startTime,
  endTime,
  reloadUnpaidFare = false,
}) => {
  const cardInfo = useSelector((state) => state.user.selectedCard);
  const [totalUnpaidFares, setTotalUnpaidFares] = useState(0);

  function CalculateTotalUnpaidFares(transactions) {
    let sumOfUnpaidFares = 0;

    if (transactions?.length) {
      for (let i = 0; i < transactions.length; i += 1) {
        for (let j = 0; j < transactions[i].payments.length; j += 1) {
          sumOfUnpaidFares += Number.parseInt(
            transactions[i].payments[j].lines.reduce((acc, val) => acc + val.amount, 0),
            10
          );
        }
      }
    }

    return sumOfUnpaidFares;
  }
  useEffect(() => {
    if (reloadUnpaidFare) {
      callUnpaidFaresApi();
    }
  }, [reloadUnpaidFare]);
  useEffect(() => {
    if (cardInfo.mediaType !== mediaTypes.CRDNC) callUnpaidFaresApi();
  }, []);

  const callUnpaidFaresApi = () => {
    api
      .getContactlessUnpaidFares({
        MediaReferenceId: cardInfo?.mediaId,
        CardType: cardInfo?.type,
        Language: getLanguageAtsId(),
        StartTime: startTime,
        EndTime: endTime,
      })
      .then((response) => {
        if (response.data?.transactions?.length) {
          setTotalUnpaidFares(CalculateTotalUnpaidFares(response.data?.transactions));
        }
      });
  };
  return (
    <>
      {totalUnpaidFares && totalUnpaidFares > 0 ? (
        <div className="contactlessTransactionHistory-unpaidFare-error">
          {/* <Error title={fields?.unpaidFareTitle?.value} noMargin>
            <div className="contactlessTransactionHistory-unpaidFare-total">
              {formatDecimalWithCurrency(convertToDollars(totalUnpaidFares))}
            </div>
            <div className="contactlessTransactionHistory-unpaidFare-text">
              <RichText
                field={isInteracCard ? fields?.unpaidFareInteracText : fields?.unpaidFareText}
              />
              <div className="contactlessTransactionHistory-unpaidFare-link">
                <PrestoLink
                  field={
                    isInteracCard ? fields?.unpaidFareInteracMoreLink : fields?.unpaidFareMoreLink
                  }
                />
              </div>
            </div>
            {!isInteracCard ? (
              <ButtonBlock>
                <ButtonBlock right>
                  <Button
                    buttonTextA11y={fields?.unpaidFareViewDetailsButton?.value}
                    onClick={() => {
                      redirectToUrl(fields?.unpaidFareViewDetailsButtonLink?.value.href, false);
                    }}
                  >
                    <Text field={fields?.unpaidFareViewDetailsButton} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            ) : null}
          </Error> */}
          <div className="content-error-block " role="alert">
            <div className="contactless-unpaid-fare--title">
              {fields?.unpaidFareTitle?.value}
              <div className="content-error-block-title--right">
                {formatDecimalWithCurrency(convertToDollars(totalUnpaidFares))}
              </div>
            </div>
            <div className="contactless-unpaid-fare--desc">
              <RichText
                field={isInteracCard ? fields?.unpaidFareInteracText : fields?.unpaidFareText}
              />
            </div>
            <PrestoLink
              field={isInteracCard ? fields?.unpaidFareInteracMoreLink : fields?.unpaidFareMoreLink}
            />
            <div className="tx-verify-card-footer">
              {!isInteracCard ? (
                <ButtonBlock>
                  <ButtonBlock right>
                    <Button
                      buttonTextA11y={fields?.unpaidFareViewDetailsButton?.value}
                      onClick={() => {
                        redirectToUrl(fields?.unpaidFareViewDetailsButtonLink?.value.href, false);
                      }}
                    >
                      <Text field={fields?.unpaidFareViewDetailsButton} />
                    </Button>
                  </ButtonBlock>
                </ButtonBlock>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withTranslation()(UnpaidFaresAlert);
