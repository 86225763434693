import React from 'react';

import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import './copyright.scss';

const Copyright = (props) => (
  <div className="">
  </div>
);

export default Copyright;
