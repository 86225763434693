import React, { useState, useEffect, useRef } from 'react';

import { Placeholder, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useLocation } from 'react-router-dom';

import { Button, ButtonBlock, Loader } from '../../atoms';
import signInFormFields from '../../constants/signInFormFields';
import ReCaptchaFormField from '../shared/ReCaptchaFormField';
import api from '../../services/api';
import routes from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  getQueryParams,
  redirectToUrl,
  updateRecaptchaToken,
  resetAccessTokenValidity,
} from '../../services/utils';
import handleServerError from '../../services/handleServerError';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleLogOut from '../../services/handleLogOut';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  fetchAvailableSettings,
  setWeakAccount,
  setGuestUserEnabled,
} from '../../redux/user/actions';
import { Error, TextInput, AdvancedModal, MediaImageList } from '../../molecules';
import './registeredAccount.scss';

const RegisteredAccount = (props) => {
  const [error, setError] = useState('');
  const [errorTitleMsg, setErrorTitleMsg] = useState(false);
  const [state, setState] = useState('');
  const [errorTitle] = useState(props.t('registeredAccountSignInTitleErrorMessage'));
  const dispatch = useDispatch();
  const [setIsContentState] = useState(false);
  const [captchaDisable, setCaptchaDisable] = useState(true);
  let usernameInputRef = useRef();
  const userInfo = useSelector((s) => s.user);
  const {
    fetchAllDataSuccess,
    isWeakPasswordAndQaAVisible,
    isWeakPasswordVisible,
    isWeakQaAVisible,
    emailError,
  } = userInfo;
  const configKeys = useSelector((state) => state.settings.configKeys);
  const showContentOnMobiles = (e) => {
    e.preventDefault();
    setIsContentState(true);
  };

  const queryParams = getQueryParams(useLocation().search);
  const returnUrl = queryParams.returnUrl;

  const [settings, setSettings] = useState([]);
  const [startedLogin, setStartedLogin] = useState(false);

  function redirectAfterLogin() {
    const redirectUrl = returnUrl ? returnUrl : routes.dashboard;
    redirectToUrl(redirectUrl);
  }

  function login(values, setFieldError) {
    setError('');
    dispatch(itemsAreLoading(true));
    dispatch(fetchAllDataSuccessfullyLoaded(undefined));
    return api
      .login({
        [signInFormFields.username]: values[signInFormFields.username],
        [signInFormFields.password]: values[signInFormFields.password],
        [signInFormFields.googleToken]: captchaDisable
          ? 'googleCaptchaToken'
          : values[signInFormFields.googleToken],
      })
      .then((res) => {
        if (res.data.Success) {
          setStartedLogin(true);
          localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Refresh_token);
          localstorageService().setItem(
            localStorageKeys.accessTokenLifetime,
            res.data.Data.Expires_in
          );
          resetAccessTokenValidity();
          dispatch(changeUserStatus(true));
          dispatch(fetchAllData());
          dispatch(setGuestUserEnabled({ isGuestLogin: false }));

          if (values[signInFormFields.remember]) {
            localstorageService().setItem(
              localStorageKeys.rememberedUsername,
              values[signInFormFields.username] || ''
            );
          } else {
            localstorageService().setItem(localStorageKeys.rememberedUsername, '');
          }
        } else {
          dispatch(itemsAreLoading(false));

          if (res.data.Message) {
            const data = JSON.parse(res.data.Message);
            if (data.Code === 'AFMS-DAX-FE-0000' || data.Code === 'AFMS-DAX-FE-0005') {
              setError(props.fields.InvalidUsernamePassword.value);
            }
            if (data.Code === 'AFMS-CRT-FE-0032') {
              setError(props.fields.UnVerifiedEmailError.value);
            }
            if (data.Code === 'AFMS-DAX-FE-0008') {
              setErrorTitleMsg(props.fields.PasswordLimitErrorNext.value);
              setError(props.fields.PasswordLimitErrorNext.value);
            }
          }
        }
      })
      .catch((res) => {
        dispatch(itemsAreLoading(false));
        setFieldError(signInFormFields.googleToken, 1);
        handleServerError(undefined, setError)(res);
      });
  }

  useEffect(() => {
    if (!startedLogin || fetchAllDataSuccess === undefined) {
      return;
    }
    setStartedLogin(false);
    dispatch(itemsAreLoading(false));
    if (isWeakPasswordAndQaAVisible || isWeakPasswordVisible || isWeakQaAVisible) {
      dispatch(setWeakAccount(true));
      setState({ showPopup: 'true' });
    } else if (!isWeakPasswordAndQaAVisible || !isWeakPasswordVisible || !isWeakQaAVisible) {
      dispatch(setWeakAccount(false));
      if (!fetchAllDataSuccess && emailError) {
        // handleServerSuccessError(emailError, setError);
        if (emailError) {
          const data = JSON.parse(emailError);
          if (data.Code === 'AFMS-CRT-FE-0032') {
            setError(props.fields.UnVerifiedEmailError.value);
          }
        }
      } else if (fetchAllDataSuccess) {
        redirectAfterLogin();
      } else {
        //console.log('error redirection', fetchAllDataSuccess);
        redirectAfterLogin();
        //handleLogOut(dispatch, '/technical-difficulties');
      }
    }
  }, [startedLogin, fetchAllDataSuccess]);

  useEffect(() => {
    api.getAllBusinessRules().then((res) => {
      if (res.status === 200) {
        setSettings(res.data);
      }
    });
    fetchAvailableSettings(dispatch);
  }, []);

  useEffect(() => {
    const reCaptchaDisable = configKeys
      .filter((item) => item.key === 'GoogleRecaptchaV3.Disable')
      .map((config) => config.setting);
    if (reCaptchaDisable.toString() === 'true') {
      setCaptchaDisable(true);
    } else {
      setCaptchaDisable(false);
    }
  }, [configKeys]);

  useEffect(() => {
    if (error) {
      usernameInputRef.focus();
    }
  }, [error]);
  /*  return (
      <div className="content-wrapper-signin">
        <div className="content">
          <AdvancedModal
            fields={props.fields}
            showPopup={state.showPopup}
            props={props}
            className="security-alerts-modal"
          >
            <Placeholder name="presto-security-alerts" rendering={props.rendering} />
          </AdvancedModal>
          <div
            tabIndex="-1"
            id="prestoaccountsignin"
            className="content-title no-focus-outline"
            aria-label={props.fields.headingA11y.value}
          >
            <Text field={props.fields.heading} />
          </div>
          <div className="media-cards" role="img" aria-label={props.fields.cardsListA11y.value}></div>
          <div className="content-subtext" aria-label={props.fields.descriptionA11y.value}>
            <RichText field={props.fields.description} />
          </div>
          {error ? (
            <Error
              title={errorTitleMsg === error ? props.fields.SignInAttempts.value : errorTitle}
              text={error}
              custom
              noMargin
            />
          ) : null}
          <Formik
            enableReinitialize
            initialValues={{
              [signInFormFields.username]:
                localstorageService().getItem(localStorageKeys.rememberedUsername) || '',
              [signInFormFields.password]: '',
              [signInFormFields.remember]: !!localstorageService().getItem(
                localStorageKeys.rememberedUsername
              ),
              [signInFormFields.googleToken]: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .required(props.t('registeredAccountMissingUsernameErrorMessage'))
                .max(100, props.t('registeredAccountInvalidUsernameEmail'))
                .min(6, props.t('registeredAccountInvalidUsernameEmail'))
                .matches(/^.[a-zA-Z0-9@\-._+]+$/, props.t('registeredAccountInvalidUsernameEmail')),
              password: Yup.string().required(
                props.t('registeredAccountMissingPasswordErrorMessage')
              ),
            })}
            onSubmit={(values, { setFieldError }) => {
              if (captchaDisable) {
                login(values, setFieldError);
              } else {
                updateRecaptchaToken(values, (updatedValues) => login(updatedValues, setFieldError));
              }
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="signin-textfield">
                  <TextInput
                    name={signInFormFields.username}
                    errors={errors}
                    touched={touched}
                    label={props.fields.usernameLabel}
                    length={settings && settings.EmailLength}
                    innerRef={(inputRef) => {
                      usernameInputRef = inputRef;
                    }}
                  />
                </div>
                <div className="signin-textfield">
                  <TextInput
                    name={signInFormFields.password}
                    type="password"
                    errors={errors}
                    touched={touched}
                    label={props.fields.passwordLabel}
                    // length={settings && settings.PasswordLength}
                    length={25}
                    showIconA11y={props.fields.showPasswordA11y?.value}
                    hideIconA11y={props.fields.hidePasswordA11y?.value}
                    withViewIcon
                    disabledAutocomplete
                  />
                </div>
  
                <div className="signIn-forgot" aria-label={props.fields.forgotTextA11y.value}>
                  <RichText field={props.fields.forgotText} />
                </div>
  
                <label className="signIn-remember" aria-label={props.fields.checkboxLabelA11y.value}>
                  <Field name={signInFormFields.remember} type="checkbox" />
                  <Text field={props.fields.checkboxLabel} />
                </label>
  
                {!captchaDisable && <ReCaptchaFormField />}
  
                <ButtonBlock>
                  <ButtonBlock className="button-signIn" right>
                    <Button
                      type="submit"
                      buttonTextA11y={props.fields.signInButtonTextA11y.value}
                      textTransform={true}
                    >
                      <Text field={props.fields.signInButtonText} />
                    </Button>
                  </ButtonBlock>
                </ButtonBlock>
              </form>
            )}
          </Formik>
  
          <div className="signIn-button-policy" aria-label={props.fields.policyA11y.value}>
            <RichText field={props.fields.policy} />
          </div>
        </div>
      </div>
   );*/
  return (
    <Loader />
  );
};

export default withTranslation()(RegisteredAccount);
