import React from 'react';

import { PrestoLink } from '../../atoms';
import mediaSubTypes from '../../constants/mediaSubTypes';
import cardStatuses from '../../constants/cardStatuses';
import mediaTypes from '../../constants/mediaTypes';
import mediaSubType from '../../constants/mediaSubType';

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
const isHiddenByFieldValue = (item, status) => {
  const statusName = getKeyByValue(cardStatuses, status);

  if (statusName && item.fields) {
    const fieldName = `HideFor${statusName}Card`;
    const statusField = item.fields.find((field) => {
      return field.name.replace(/\s/g, '') === fieldName;
    });

    return statusField?.jss.value === true;
  }

  return false;
};
const CardControlledMenuItem = ({ item, selectedCard, wrapperClassName = '', onClickCustom }) => {
  const isPrestoCard = selectedCard?.type === mediaSubTypes.presto;
  const isWalletCard = selectedCard?.mediaType === mediaTypes.CRDNC;
  const isAppleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.APPLE;
  const isGoogleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.GOOGLE;
  const isMobileWalletCard = selectedCard.mediaSubTypeWallet === null || '';
  const cardStatusCode = selectedCard?.cardStateCode;
  const isVisible =
    (isPrestoCard &&
      item.ShowForPrestoCard?.jss.value &&
      !isHiddenByFieldValue(item, cardStatusCode)) ||
    (isWalletCard &&
      ((isGoogleWalletCard && item.ShowForGoogleWalletCard?.jss.value) ||
        (isAppleWalletCard && item.ShowForAppleWalletCard?.jss.value) ||
        (isMobileWalletCard &&
          !item.ShowForAppleWalletCard?.jss.value &&
          !item.ShowForGoogleWalletCard?.jss.value))) ||
    (!isPrestoCard && item.ShowForContactlessCard?.jss.value);
  return (
    <>
      {isVisible && (
        <li className={wrapperClassName ? wrapperClassName : null}>
          <PrestoLink field={item.Link?.jss} onClickCustom={onClickCustom} />
        </li>
      )}
    </>
  );
};
export default CardControlledMenuItem;
