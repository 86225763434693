import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import './notificationBanner.scss';

const NotificationBanner = ({ fields }) => {
  const {
    BackGroundColor,
    NotificationIcon,
    NotificationDescription,
    NotificationDescriptionA11y,
    CloseIcon,
    FontColor,
  } = fields;
  const bannerStyle = {
    backgroundColor: BackGroundColor.value,
    color: FontColor.value,
  };
  const [showNotification, dismissNotification] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('NotificationBanner-wrapper');
    if (elements.length > 0) elements[elements.length - 1].style.marginBottom = 'unset';
  });

  const manageCloseHandler = (e) => {
    if (e.keyCode == 13) {
      dismissNotification(true);
    }
  };

  return !showNotification ? (
    <div className="NotificationBanner-wrapper" style={bannerStyle}>
      <div className="NotificationBanner-container">
        <div className="NotificationBanner" aria-label={NotificationDescriptionA11y.value}>
          <Image field={NotificationIcon} loading="lazy"/>
          <RichText field={NotificationDescription} className="NotificationBanner-content" />
        </div>
        <Image
          tabIndex={0}
          field={CloseIcon}
          onKeyDown={manageCloseHandler}
          onClick={() => dismissNotification(true)}
          loading="lazy"
        />
      </div>
    </div>
  ) : null;
};
export default NotificationBanner;
