import React, { useEffect, useState, useRef } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Header, StepTracker } from '../../molecules';
import { getServerUrl } from '../../services/utils';
import routes from '../../constants/routes';
import api from '../../services/api';
import DeactivateAndTransferNew0 from './DeactivateAndTransferNew/DeactivateAndTransferNew0';
import DeactivateAndTransferNew1 from './DeactivateAndTransferNew/DeactivateAndTransferNew1';
import DeactivateAndTransferNew2 from './DeactivateAndTransferNew/DeactivateAndTransferNew2';
import DeactivateAndTransferNew3 from './DeactivateAndTransferNew/DeactivateAndTransferNew3';
import Deactivate0 from './Deactivate/Deactivate0';
import Deactivate1 from './Deactivate/Deactivate1';
import { setDeactivateCardType } from '../../redux/user/actions';
import './deactivateCard.scss';
import DeactivateAndTransfer0 from './DeactivateAndTransfer/DeactivateAndTransfer0';
import DeactivateAndTransfer1 from './DeactivateAndTransfer/DeactivateAndTransfer1';
import DeactivateAndTransfer2 from './DeactivateAndTransfer/DeactivateAndTransfer2';
import deactivateAndTransferAnother0 from './DeactivateAndTransferAnother/DeactivateAndTransferAnother0';
import deactivateAndTransferAnother1 from './DeactivateAndTransferAnother/DeactivateAndTransferAnother1';
import deactivateAndTransferAnother2 from './DeactivateAndTransferAnother/DeactivateAndTransferAnother2';

const DeactivateCard = ({ fields, t, rendering }, props) => {
  const userInfo = useSelector((s) => s.user);
  const fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  const cartItems = useSelector((reduxState) => reduxState.user.shoppingCart.items);
  const subTotal = cartItems ? cartItems.reduce((acc, val) => acc + val.subTotal, 0) : 0;
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => {
    setStep(0);
    focusDiv.current.focus();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const [state, setState] = useState({});
  const location = useLocation();
  const returnUrl = `${getServerUrl()}${location.pathname}`;
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const [stepItemLists, setStepItemLists] = useState([]);
  const [DeactivateFlow, setDeactivateFlow] = useState('');
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  let deactivateCard = useSelector((reduxState) => reduxState.user.deactivateCardType);
  const [isTransferBalance, setIsTransferBalance] = useState(fields?.IsTransferBalance.value);
  let stepDeactivateNew = [
    DeactivateAndTransferNew0,
    DeactivateAndTransferNew1,
    DeactivateAndTransferNew2,
    DeactivateAndTransferNew3,
  ];
  let stepDeactivateAndTransfer = [
    DeactivateAndTransfer0,
    DeactivateAndTransfer1,
    DeactivateAndTransfer2,
  ];
  let stepDeactivate = [Deactivate0, Deactivate1];
  let stepTransferExisting = [DeactivateAndTransfer1, DeactivateAndTransfer2];
  let stepTransferAnother = [
    deactivateAndTransferAnother0,
    deactivateAndTransferAnother1,
    deactivateAndTransferAnother2,
  ];
  let Stepper = stepDeactivateNew[step];

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      dispatch(setDeactivateCardType(''));
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  useEffect(() => {
    dispatch(setDeactivateCardType(''));
  }, []);

  if (deactivateCard?.deactivateCardType == 0) {
    Stepper = stepDeactivateNew[step];
  } else if (deactivateCard?.deactivateCardType == 1) {
    if (isTransferBalance) {
      Stepper = stepTransferExisting[step];
    } else {
      Stepper = stepDeactivateAndTransfer[step];
    }
  } else if (deactivateCard?.deactivateCardType == 2) {
    Stepper = stepDeactivate[step];
  } else {
    Stepper = stepTransferAnother[step];
  }

  let stepItemList = [];
  useEffect(() => {
    const elementData = document.getElementById('rlc-card');
    if (deactivateCard?.deactivateCardType && elementData) {
      elementData.style.display = 'none';
    }
    setDeactivateFlow(deactivateCard?.deactivateCardType);
    setState({ items: cartItems, fareMedias, total: subTotal });
    const deFlow = deactivateCard?.deactivateCardType;
    // step0 - shown in all flows
    const step0 = {
      text: (
        <Text field={deFlow == 3 ? fields?.SelectTargetCardStep : fields?.ReviewInformationStep} />
      ),
      labelA11y:
        deFlow == 3
          ? fields.SelectTargetCardStepA11y.value
          : fields?.ReviewInformationStepA11y?.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    // step1 - shown in all flows, with title changed for 3rd flow-deactivate
    const step1 = {
      text: (
        <Text
          field={
            deFlow == 0
              ? fields?.ShippingAddressStep
              : deFlow == 1
              ? fields.NewCardInformationStep
              : deFlow == 2
              ? fields?.CardDeactivatedStep
              : fields?.ReviewTransferDetailsStep
          }
        />
      ),
      labelA11y:
        deFlow == 0
          ? fields?.ShippingAddressStepA11y.value
          : deFlow == 1
          ? fields?.NewCardInformationStepA11y.value
          : deFlow == 2
          ? fields?.CardDeactivatedStepA11y.value
          : fields?.ReviewTransferDetailsStepA11y.value,

      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    // step2 - not shown in flow3- deactivate
    const step2 = {
      text: (
        <Text
          field={
            deFlow == 0
              ? fields?.PaymentMethodTitle
              : deFlow == 1
              ? fields?.InformationTransferStep
              : deFlow == 2
              ? fields?.PaymentMethodTitle
              : fields?.TransferCompleteStep
          }
        />
      ),
      labelA11y:
        deFlow == 0
          ? fields?.PaymentMethodTitle.value
          : deFlow == 1
          ? fields?.InformationTransferStepA11y.value
          : deFlow == 2
          ? fields?.PaymentMethodTitle.value
          : fields?.TransferCompleteStepA11y.value,

      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    // step3 - not shown in flow3- deactivate
    const step3 = {
      text: <Text field={fields?.OrderConfirmedStep} />,
      labelA11y: fields?.OrderConfirmedStepA11y.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    const transferStep0 = {
      text: (
        <Text field={deFlow == 3 ? fields.SelectTargetCardStep : fields.NewCardInformationStep} />
      ),
      labelA11y:
        deFlow == 3
          ? fields.SelectTargetCardStepA11y.value
          : fields.ReviewInformationStepA11y.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    const transferStep1 = {
      text: (
        <Text
          field={
            deFlow == 1
              ? fields.InformationTransferStep
              : deFlow == 3
              ? fields.ReviewTransferDetailsStep
              : fields?.ShippingAddressStep
          }
        />
      ),
      labelA11y:
        deFlow == 1
          ? fields.InformationTransferStepA11y.value
          : deFlow == 3
          ? fields.ReviewTransferDetailsStepA11y.value
          : fields?.ShippingAddressStepA11y.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    const transferStep2 = {
      text: <Text field={deFlow == 3 ? fields.TransferCompleteStep : fields.PaymentMethodTitle} />,
      labelA11y:
        deFlow == 3 ? fields.TransferCompleteStepA11y.value : fields.PaymentMethodTitle.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    const transferStep3 = {
      text: <Text field={fields.OrderConfirmedStep} />,
      labelA11y: fields.OrderConfirmedStepA11y.value,
      StepA11y: fields?.StepA11y.value,
      OfA11y: fields?.OfA11y.value,
      CompleteA11y: fields?.CompleteA11y.value,
      NotCompleteA11y: fields?.NotCompleteA11y.value,
      CurrentStepA11y: fields?.CurrentStepA11y.value,
    };

    // step0, step1 visible in all cases
    if (isTransferBalance) {
      stepItemList = [transferStep0, transferStep1];
      if (deactivateCard?.deactivateCardType == 0) {
        stepItemList.push(transferStep2);
        stepItemList.push(transferStep3);
      } else if (deactivateCard?.deactivateCardType == 3) {
        stepItemList.push(transferStep2);
      }
    } else {
      stepItemList = [step0, step1];
      if (deactivateCard?.deactivateCardType == 0) {
        stepItemList.push(step2);
        stepItemList.push(step3);
      } else if (deactivateCard?.deactivateCardType == 1) {
        stepItemList.push(step2);
      } else if (deactivateCard?.deactivateCardType == 3) {
        stepItemList = [step0, step1, step2];
      }
    }
    setStepItemLists(stepItemList);
  }, [deactivateCard?.deactivateCardType]);

  const goBackUrl = routes.reportLostCard;
  const transferHeader =
    DeactivateFlow == 0
      ? fields.TransferToNewCardLabel
      : DeactivateFlow == 1
      ? fields.TransferToNewCardLabel
      : fields.TransferToAnotherCardLabel;
  const transferHeaderA11y =
    DeactivateFlow == 0
      ? fields.TransferToNewCardLabel11y.value
      : DeactivateFlow == 1
      ? fields.TransferToExistingCard11y.value
      : fields.TransferToAnotherCardLabelA11y.value;
  return (
    <>
      {!deactivateCard?.deactivateCardType ? null : (
        <div className="deactivate-parent">
          <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
            {t('StepperChangeSRText')}
          </span>
          <StepTracker activeIndex={step} items={stepItemLists} />
          <div className="transfer-card-header">
            <Header
              centered
              headerTitleA11y={
                isTransferBalance
                  ? transferHeaderA11y
                  : DeactivateFlow == 0
                  ? fields?.DeactivateAndOrderCardTitleA11y.value
                  : DeactivateFlow == 1 || DeactivateFlow == 3
                  ? fields?.DeactivateAndTransferTitleA11y.value
                  : fields?.DeactivateCardTitleA11y.value
              }
            >
              <Text
                field={
                  isTransferBalance
                    ? transferHeader
                    : DeactivateFlow == 0
                    ? fields?.DeactivateAndOrderCardTitle
                    : DeactivateFlow == 1 || DeactivateFlow == 3
                    ? fields?.DeactivateAndTransferTitle
                    : fields?.DeactivateCardTitle
                }
              />
            </Header>
            {fields.DeactivateAndOrderCardSubText ? (
              <p className="header-text-note">
                {DeactivateFlow == 0 && <Text field={fields.DeactivateAndOrderCardSubText} />}
              </p>
            ) : null}
          </div>
          <Stepper
            {...props}
            t={t}
            state={state}
            setState={setState}
            userInfo={userInfo}
            fields={fields}
            toNextStep={toNextStep}
            toFirstStep={toFirstStep}
            toPreviousStep={toPreviousStep}
            returnUrl={returnUrl}
            setIsError={setIsError}
            isError={isError}
            // thirdPartySystemErrorType={thirdPartySystemErrorType}
            rendering={rendering}
            goBackUrl={goBackUrl}
            isTransferBalance={isTransferBalance}
            setIsTransferBalance={setIsTransferBalance}
            step={step}
            callRefreshToken={(callBack) => {
              api.refreshToken().then(() => {
                if (callBack) callBack();
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(DeactivateCard);
