import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import './googleWallet.scss';
import './contentBlock.scss';
import useDidMountEffect from '../../hooks/useDidMountEffect';

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const getHeading = (tag) => {
  // console.log('tag', tag);
  if (tag === 'h2') {
    return 'h2';
  }
  if (tag === 'h3') {
    return 'h3';
  }
  return 'h1';
};

const ContentBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [props]);
  if (!isLoaded) return null;
  return (
    <div id={props.fields?.Id?.value ? props.fields?.Id.value : ''}>
      <div className={props.fields?.CssClass?.value}>
        <Text
          tag={getHeading(props.fields?.SubHeadingTag?.fields?.HeadingTagTitle?.value)}
          className="display-4"
          field={props.fields?.heading}
        />
        <RichText className="contentDescription" field={props?.fields?.content} />
      </div>
    </div>
  );
};

export default withTranslation()(ContentBlock);
