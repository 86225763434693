import React from 'react';
import './header.scss';

export default function Header({ children, centered, smallerFont }) {
  return (
    <h1
      className={`header-title ${centered ? 'centered' : ''} ${smallerFont ? 'smaller-font' : ''}`}
    >
      {children}
    </h1>
  );
}
