import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const useScrollRestoration = () => {
  const { pathname } = useLocation();
  const location = useLocation();

  useEffect(() => {
    // if (!pathname.toLocaleLowerCase().includes('contactless')) {
    //   if (document.body.scrollTop === 0) {
    //     window.scrollTo(0, 0);
    //   } else {
    //     document.body.scrollTop = 0;
    //   }
    // } else {
    //   if (location.hash.substring(1)) {
    //     // const element = document.getElementById(location.hash.substring(1));
    //     // window.scrollTo(0, element.offsetTop - 46);
    //     var hash,
    //       timeout = 0,
    //       poll = window.setInterval(function () {
    //         hash = window.location.hash;

    //         if (hash.length) {
    //           const element = document.getElementById(location.hash.substring(1));
    //           if (element !== null) {
    //             document.body.scrollTop = element.offsetTop;
    //             window.clearInterval(poll);
    //           }
    //         } else if (timeout++ > 100) {
    //           window.clearInterval(poll);
    //         }
    //       }, 100);
    //   }
    // }

    if (location.hash.substring(1)) {
      var hash,
        timeout = 0,
        poll = window.setInterval(function () {
          hash = window.location.hash;

          if (hash.length) {
            const element = document.getElementById(location.hash.substring(1));
            if (element !== null) {
              element.scrollIntoView({ behavior: 'smooth' });
              window.clearInterval(poll);
            }
          } else if (timeout++ > 1500) {
            window.clearInterval(poll);
          }
        }, 1500);
    } else {
      document.body.scrollTop = 0;
    }
  }, [pathname]);

  return null;
};

export default useScrollRestoration;
