import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';

import emailVerificationStatus from '../../constants/emailVerificationStatus';
import { ButtonBlock, Button } from '../../atoms';
import { TextInput, CardBlock, Header } from '../../molecules';
import api from '../../services/api';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../services/utils';

export default function VerifyEmail0({
  fields,
  toNextStep,
  toLastStep,
  userInfo,
  onVerifyEmailAddress,
  setState,
}) {
  const queryParams = getQueryParams(useLocation().search);
  const emailVerified = userInfo.emailVerificationStatus === emailVerificationStatus.verified;
  const emailUpdateSuccess = queryParams.SuccessEmailChange !== undefined ? queryParams.SuccessEmailChange : "";

  return (
    <>
      {/* <Header headerTitleA11y={fields.headingA11y?.value}>
        <Text field={fields.heading} />
      </Header> */}
      <CardBlock
        title={emailVerified ? fields.verifiedHeading.value : fields.pleaseVerifyHeading.value}
        titleA11y={fields.pleaseVerifyHeadingA11y?.value}
        icon={emailVerified ? 'successIcon' : 'errorIcon'}
        tabIndex="0"
      >
        <Text field={emailVerified ? fields.verifiedNote : fields.pleaseVerifyNote} />
        {emailUpdateSuccess === "true" && emailUpdateSuccess !== ""
          ? <div className="updateSuccess"><Text field={fields.UpdateSuccess} /></div>
          : emailUpdateSuccess === "false" && emailUpdateSuccess !== "" ?
            <div className="updateFailure"> <Text field={fields.UpdateFailure} /></div> : ""}
        <Formik
          enableReinitialize
          initialValues={{
            email: userInfo.loginEmail,
          }}
          validateOnChange={false}
          onSubmit={() => {
            setState({
              verifiedEmail: userInfo.loginEmail,
            });
            onVerifyEmailAddress(toLastStep);
          }}
        >
          {({ errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="emailVerification-formGrid">
                <div className="emailVerification-formRow">
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="email"
                      errors={errors}
                      touched={touched}
                      label={fields.emailAddresslabel}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <ButtonBlock>
                <ButtonBlock right>
                  {emailVerified ? (
                    <Button
                      onClick={() => api.b2cChangeEmail()}
                      buttonTextA11y={fields?.UpdateEmailButtonTextA11y?.value}
                    >
                      <Text field={fields.updateEmailButtonText} />
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        buttonTextA11y={fields.resendVerificationButtonText?.value}
                      >
                        <Text field={fields.resendVerificationButtonText} />
                      </Button>
                      <Button
                        white
                        firstOrder
                        onClick={() => api.b2cChangeEmail()}
                        buttonTextA11y={fields.updateEmailButtonText?.value}
                      >
                        <Text field={fields.updateEmailButtonText} />
                      </Button>
                    </>
                  )}
                </ButtonBlock>
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </CardBlock>
    </>
  );
}
