import React from 'react';

import { Link, Image } from '@sitecore-jss/sitecore-jss-react';

import './socialLink.scss';

const SocialLink = ({ field = '', image }) => (
  <Link field={field} className="social-link" aria-label={field.value?.text || ''}>
    {image && <Image media={image} alt={image?.value?.alt || ''} className="social-icon" loading="lazy" />}
    <span className="desktopOnly">{field.value?.text || ''}</span>
  </Link>
);

export default SocialLink;
