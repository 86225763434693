import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import { isJsonString, redirectToUrl } from '../../services/utils';
import { updateUserInfo } from '../../redux/user/actions';
import { Button, ButtonBlock } from '../../atoms';
import { Header, CardBlock, Error, TextInput } from '../../molecules';

const AddContactlessCard4 = (props) => {
  const nickNamesArr = useSelector((reduxState) =>
    reduxState.user.contactlessMedias.filter((item) => !!item.nickName)
  );
  const nickNames = nickNamesArr?.length ? nickNamesArr.map((item) => item.nickName) : [];
  const { fields, state, setState, toNextStep, t } = props;
  const [error, setIsError] = useState('');
  const dispatch = useDispatch();
  const loginEmail = useSelector((state) => state.user.loginEmail);
  // const returnpath = `${routes.addCard}?creditCard=true&nextstep=5`

  let runValidation;

  useEffect(() => {
    if (runValidation) {
      runValidation();
    }
  }, [t]);

  const handleUpdateNickName = (values) => {
    const data = {
      AccountId: state.AccountId,
      MediaId: state.MediaId,
      Nickname: values.NickName,
    };
    api.contactlessUpdateNickname(data).then((response) => {
      if (response.data.Success) {
        setState({
          ...state,
          NickName: values.NickName,
          MediaId: state.MediaId,
          CardProvider: state.CardProvider,
          MaskedPan: state.MaskedPan,
          AccountId: state.AccountId,
        });
        dispatch(updateUserInfo(dispatch));
        toNextStep();
      } else if (isJsonString(response.data.Error)) {
        const errorMessage = JSON.parse(response.data.Error);
        setIsError(errorMessage.Description);
      }
    });
  };

  return (
    <div>
      <Header centered aria-label={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>

      <Formik
        initialValues={{
          NickName: '',
        }}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          NickName: Yup.string()
            .required(t('addContactlessCardValidationNickname'))
            .matches(/^[A-Za-z0-9]{0,25}$/, t('addContactlessCardValidationNicknameFormat'))
            .notOneOf(nickNames, t('addContactlessCardValidationNicknameUnique')),
        })}
        onSubmit={(values) => handleUpdateNickName(values)}
      >
        {({ errors, touched, handleSubmit, validateForm }) => {
          runValidation = validateForm;

          return (
            <form onSubmit={handleSubmit}>
              {error ? <Error title={error} small /> : null}
              <CardBlock>
                <div aria-label={fields.chooseNewNicknameDetailsTextA11y.value}>
                  <RichText field={fields.chooseNewNicknameDetailsText} />
                </div>
                <hr className="addCard-separator" aria-hidden="true" />
                <div className="addCard-formRow">
                  <div className="addCard-formControl addCard-noMargin">
                    <TextInput
                      type="text"
                      name="NickName"
                      errors={errors}
                      touched={touched}
                      label={fields.newNicknameLabel}
                      bellowText={fields.newNicknameInputNoteText}
                    />
                  </div>
                </div>
              </CardBlock>

              <ButtonBlock>
                <ButtonBlock right>
                  <Button
                    type="submit"
                    buttonTextA11y={fields.newNicknameConfirmButtonTextA11y.value}
                  >
                    <Text field={fields.newNicknameConfirmButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddContactlessCard4;
