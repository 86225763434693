import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ButtonBlock, PrintButton, Button, PrestoLink } from '../../../atoms';
import { Error, Success } from '../../../molecules';
import routes from '../../../constants/routes';
import {
  redirectToUrl,
} from '../../../services/utils';
import './cardVerification.scss';

const CardVerification1 = (props) => {
  const { fields, toFirstStep, toPreviosuStep, state, setPaymentsToVerify } = props;
  const { hasSuccess, verificationError } = state;
  const goToTransactionHistory = () => {
    if (hasSuccess) {
      setPaymentsToVerify([]);
    }
    toFirstStep();
  };
  let isRetryBtnVisible;
  let errorMsgText = '';

  if (verificationError) {
    switch (verificationError) {
      case 'Contactless_ConnectionError':
        errorMsgText = fields.verifyCardErrorCVRCTAStep0301?.value;
        isRetryBtnVisible = false;
        break;
      case 'Contactless_FailedPayment':
        errorMsgText = fields.verifyCardErrorCVRCTAStep0302?.value;
        isRetryBtnVisible = true;
        break;
      case 'Contactless_SystemError':
        errorMsgText = fields.verifyCardErrorCVRCTAStep0303?.value;
        isRetryBtnVisible = true;
        break;
      case 'Contactless_TokenTimeout':
        toPreviosuStep();
        break;
      // Reserved for future release
      // case 'BeginCyclePaymentFailure':
      //   errorMsgText = fields.verifyCardErrorCVRCTAStep0304?.value;
      //   isRetryBtnVisible = false;
      //   break;
      default:
        //errorMsgText = `Error: ${verificationError}`;
        //isRetryBtnVisible = false;
        errorMsgText = fields.verifyCardErrorCVRCTAStep0303?.value;
        isRetryBtnVisible = false;
    }
  }

  return (
    <div>
      {hasSuccess ? (
        <Success title={fields.verifyCardSuccessTitle} contextText={fields.verifyCardSuccessText} />
      ) : null}

      {verificationError !== '' ? (
        <div title={fields.verifyCardErrorTitle?.value} className={`content-error-block`} role="alert" >
          <div className="content-error-block-title" noMargin>{fields.verifyCardErrorTitle?.value}</div>
          <div className="content-error-block-text">{errorMsgText}</div>
          <PrestoLink
            field={props.fields.verifyCardErrorLearnMoreLink}
            className="cardVerification-errorLink"
          />
        </div>
      ) : null}

      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button white={isRetryBtnVisible} onClick={() => redirectToUrl(routes.transactionHistory)}>
            <Text field={fields.verifyCardGoToTransactionHistoryButton} />
          </Button>
          {isRetryBtnVisible ? (
            <Button onClick={goToTransactionHistory}>
              <Text field={fields.verifyCardRetryVerificationButton} />
            </Button>
          ) : null}
        </ButtonBlock>
      </ButtonBlock>
    </div>
  );
};

export default CardVerification1;
