import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { StepTracker } from '../../molecules';
import emailVerificationStatus from '../../constants/emailVerificationStatus';
import AddContactlessCard0 from './AddContactlessCard0';
import AddContactlessCard1 from './AddContactlessCard1';
import AddContactlessCard2 from './AddContactlessCard2';
import AddContactlessCard3 from './AddContactlessCard3';
import AddContactlessCard4 from './AddContactlessCard4';
import AddContactlessCard5 from './AddContactlessCard5';
import { getQueryParams, localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';
import handleServerError from '../../services/handleServerError';
import { updateUserInfo } from '../../redux/user/actions';

const stepsMap = [
  AddContactlessCard0,
  AddContactlessCard1,
  AddContactlessCard2,
  AddContactlessCard3,
  AddContactlessCard4,
  AddContactlessCard5,
];

const AddContactlessCard = (props) => {
  const { t } = props;
  const verifyEmailStatus = useSelector((reduxState) => reduxState.user.emailVerificationStatus);
  let defaultStep;
  if (verifyEmailStatus || verifyEmailStatus === 0) {
    switch (verifyEmailStatus) {
      case emailVerificationStatus.isNull:
        defaultStep = 0;
        break;
      case emailVerificationStatus.newPending:
      case emailVerificationStatus.pending:
        defaultStep = 1;
        break;
      case emailVerificationStatus.verified:
      case emailVerificationStatus.verifiedBypass:
        defaultStep = 2;
        break;
      default:
        defaultStep = 0;
    }
  }
  const focusDiv = useRef();
  const [step, setStep] = useState(defaultStep);
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => {
    setStep(2);
    focusDiv.current.focus();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const Stepper = stepsMap[step];
  const [state, setState] = useState({});
  const queryParams = getQueryParams(useLocation().search);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      queryParams.addCardtype !== undefined &&
      (queryParams.addCardtype === 'credit' || queryParams.addCardtype === 'debit')
    ) {
      setState({
        ...state,
        CardProvider: queryParams.param1,
        MaskedPan: queryParams.param2,
        MediaId: queryParams.param3,
        AccountId: queryParams.param4,
      });

      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('addCardtype')) {
        queryParams1.delete('addCardtype');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param1')) {
        queryParams1.delete('param1');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param2')) {
        queryParams1.delete('param2');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param3')) {
        queryParams1.delete('param3');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param4')) {
        queryParams1.delete('param4');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      api
        .getSTSCloudClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );

            dispatch(updateUserInfo(dispatch));
            setStep(4);
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
    if (queryParams.contactLessCardDebit !== undefined) {
      setState({
        ...state,
        ...queryParams.values,
        AccountId: queryParams.accountId,
      });
      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('contactLessCardDebit')) {
        queryParams1.delete('contactLessCardDebit');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('values')) {
        queryParams1.delete('values');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('accountId')) {
        queryParams1.delete('accountId');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('state')) {
        queryParams1.delete('state');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      api
        .getSTSCloudClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(
              localStorageKeys.stsAccessToken,
              res.data.Data.Access_token
            );
            // localstorageService().setItem(
            //   localStorageKeys.refreshToken,
            //   res.data.Data.Access_token
            // );
            // localstorageService().setItem(
            //   localStorageKeys.accessTokenLifetime,
            //   res.data.Data.Expires_in
            // );
            setStep(3);
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, []);
  return (
    <div className="add-card-wrapper ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      {step > 3 ? (
        <StepTracker
          activeIndex={step - 4}
          items={[
            {
              text: <Text field={props.fields.step1Title} />,
              textInProgressA11y: props.fields.step1TitleInProgressA11y.value,
              textCompletedA11y: props.fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={props.fields.step2Title} />,
              textNotCompletedA11y: props.fields.step2TitleNotCompletedA11y.value,
              textCompletedA11y: props.fields.step2TitleCompletedA11y.value,
            },
          ]}
        />
      ) : null}
      <Stepper
        {...props}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
      />
    </div>
  );
};

export default withTranslation()(AddContactlessCard);
