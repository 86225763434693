import React, { useState, useEffect, useRef } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { StepTracker } from '../../molecules';
import './manageAutoRenew.scss';
import routes from '../../constants/routes';
import ManageAutoRenew1 from './manageAutoRenew1';
import ManageAutoRenew2 from './manageAutoRenew2';
import { getQueryParams } from '../../services/utils';
import handleThirdPartyError from '../../services/handleThirdPartyError';

const backToTransitPassesButtonLink = routes.transitPasses;
const stepsMap = [ManageAutoRenew1, ManageAutoRenew2];
const checkoutStep1 = 0;
const checkoutStep2 = 1;

const ManageAutoRenew = ({ fields, t }, props) => {
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');
  const toCheckoutStep1 = () => {
    setStep(checkoutStep1);
    focusDiv.current.focus();
  };
  const toCheckoutStep2 = () => {
    setStep(checkoutStep2);
    focusDiv.current.focus();
  };
  const userInfo = useSelector((s) => s.user);
  const configKeys = useSelector((s) => s.settings.configKeys);
  const manageAutoRenew = useSelector((reduxState) => reduxState.products.manageAutoRenew);
  const { Step1Label, Step2Label } = fields;
  const Stepper = stepsMap[step];
  const queryParams = getQueryParams(useLocation().search);
  const isSuccess = queryParams.success;
  const error = queryParams.error;

  useEffect(() => {
    if (manageAutoRenew?.checkoutData?.isManageAutoRenew) {
      if (isSuccess === 'true') {
        setStep(checkoutStep2);
        focusDiv.current.focus();
      } else {
        let stepToSet = checkoutStep1;

        if (error) {
          handleThirdPartyError(error, (err) => {
            setThirdPartySystemErrorType(err);
            stepToSet = checkoutStep2;
          });
        }
        setStep(stepToSet);
        focusDiv.current.focus();
      }
    }
  }, [manageAutoRenew]);

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  return manageAutoRenew?.visibility ? (
    <div className="manage-autoRenew-container">
      <div className="manage-autoRenew--steps autorenew-steps-pad ">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={Step1Label} />,
              textInProgressA11y: fields.Step1InProgressA11y.value,
              textCompletedA11y: fields.Step1CompletedA11y.value,
            },
            {
              text: <Text field={Step2Label} />,
              textNotCompletedA11y: fields.Step2NotCompletedA11y.value,
              textCompletedA11y: fields.Step2CompletedA11y.value,
            },
          ]}
        />
      </div>
      <Stepper
        {...props}
        t={t}
        fields={fields}
        configKeys={configKeys}
        toCheckoutStep1={toCheckoutStep1}
        toCheckoutStep2={toCheckoutStep2}
        backToTransitPassesButtonLink={backToTransitPassesButtonLink}
        instance={manageAutoRenew?.instance}
        userInfo={userInfo}
        isSuccess={isSuccess}
        error={error}
        queryParams={queryParams}
        TCrendering={manageAutoRenew?.TCrendering}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
      />
    </div>
  ) : null;
};

export default withTranslation()(ManageAutoRenew);
