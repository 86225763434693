import React, { useEffect, useState } from 'react';

import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import api from '../../services/api';
import { getLanguageAtsId, getQueryParams, hasAnyCard, localstorageService, redirectToUrl } from '../../services/utils';
import UnpaidTransactions from './UnpaidTransactions';
import { Notification } from '../../atoms';
import { ConnectionError, Header } from '../../molecules';
import localStorageKeys from '../../constants/localStorageKeys';
import routes from '../../constants/routes';


const getSortedUnpaidFaresFromData = (data) => {
    let payments = [];
    data.transactions.forEach((transaction) => {
        transaction.payments.forEach((payment) => {
            payments.push({
                ...payment,
                mediaInstanceId: transaction.media.mediaInfo.mediaInstanceId,
                amount: payment.lines.reduce((acc, val) => acc + val.amount, 0),
            });
        });
    });
    return payments.sort((firstPayment, secondPayment) => {
        const a = moment(firstPayment.createdTime);
        const b = moment(secondPayment.createdTime);
        // eslint-disable-next-line no-nested-ternary
        return a === b ? 0 : a < b ? 1 : -1;
    });
};

const UnpaidFares0 = (props) => {
    const [unpaidFares, setUnpaidFares] = useState(null);
    const [connectionError, setConnectionError] = useState(false);
    const cardInfo = useSelector((reduxState) => reduxState.user.selectedCard);

    const { state, setState, rendering, fields, toNextStep, t } = props;

    const userInfo = useSelector((s) => s.user);

    useEffect(() => {
        api
            .getContactlessUnpaidFares({
                MediaReferenceId: cardInfo?.mediaId,
                CardType: cardInfo.type,
                Language: getLanguageAtsId(),
            })
            .then((response) => {
                const queryParams = getQueryParams(window.location.search);
                const transactionId = queryParams.id;
                const data = response.data;
                const unpaidFaresData = getSortedUnpaidFaresFromData(data);
                if (transactionId) {
                    const index = unpaidFaresData.findIndex((i) => i.uuid === transactionId);

                    setState({
                        ...state,
                        ...data,
                        unpaidFaresData,
                        uuid: unpaidFaresData[index]?.uuid || '',
                        unpaidFareIndex: index,
                    });
                    // toNextStep();
                    // return;
                    //Sts Cloud Policy Call
                    localstorageService().setItem(localStorageKeys.unpaidFaresData, JSON.stringify(unpaidFaresData));
                    localstorageService().setItem(localStorageKeys.unpaidFaresReposneData, JSON.stringify(response.data));
                    localstorageService().setItem(localStorageKeys.unpaidFaresIndex, index);
                    const returnpath = `${routes.unpaidFares}?dedtRecovery=debtRecovery&param1=${queryParams.id}&param2=${index}`;
                    redirectToUrl(`${routes.b2cRegisteredSignIn}?dedtRecovery=debtRecovery&returnUrl=${returnpath}`);
                }

                setUnpaidFares(unpaidFaresData);

                setState({
                    ...state,
                    ...data,
                    unpaidFaresData,
                });
            })
            .catch(() => {
                setConnectionError(true);
            });
    }, []);

    return (
        <>
            {hasAnyCard(userInfo) ? (
                <>
                    <Header headerTitleA11y={fields.headingA11y.value}>
                        <Text field={fields.heading} />
                    </Header>
                    <Placeholder name="presto-unpaid-fares" rendering={rendering} />

                    {unpaidFares && unpaidFares.length > 0 ? (
                        unpaidFares.map((payment, index) => (
                            <UnpaidTransactions
                                data={payment}
                                toNextStep={toNextStep}
                                state={state}
                                setState={setState}
                                fields={fields}
                                unpaidFareIndex={index}
                                t={t}
                                isButton
                                textMapping={state.textMapping}
                            />
                        ))
                    ) : (
                        <div className="unpaidFares">
                            <Notification text={fields.noUnpaidFareText} />
                        </div>
                    )}

                    {connectionError ? (
                        <div className="unpaidFares-connection-error">
                            <ConnectionError
                                title={t('connectionErrorTitle')}
                                titleA11y={t('connectionErrorTitle')}
                                message={t('connectionErrorTransactionHistoryMessage')}
                                messageA11y={t('connectionErrorTransactionHistoryMessage')}
                                buttonA11y={fields.refreshButtonTextA11y.value}
                                buttonText={fields.refreshButtonText.value}
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <Placeholder
                    name="presto-my-products-overview"
                    rendering={rendering}
                    linkableHeadingForNoMedia={false}
                />
            )}
        </>
    );
};

export default UnpaidFares0;
