import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import './error.scss';

export default function Error({
  title,
  text,
  textA11y,
  small,
  titleA11y,
  children,
  noMargin,
  noBg,
  custom = false,
  showfailedIcon = false,
  gwError = false,
}) {
  //console.log('text', text);
  return (
    <div
      className={`content-error-block ${small ? 'small' : ''} ${noBg ? 'no-bg' : ''} ${
        showfailedIcon ? 'show-failed-icon' : ''
      }`}
      role={!gwError && 'alert'}
    >
      <div className="content-error-block-title" aria-label={titleA11y}>
        {title}
      </div>
      <div
        className={noMargin ? 'content-error-block-text' : 'content-error-block-text-margin'}
        aria-label={textA11y}
      >
        {custom ? <div>{text}</div> : <RichText field={children || text} />}
      </div>
    </div>
  );
}
