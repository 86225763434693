import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Iframe from 'react-iframe';

import {
  getShortLanguageCode,
  isJsonString,
  redirectToUrl,
  localstorageService,
} from '../../services/utils';
import monerisConstants from '../../constants/moneris';
import { ButtonBlock, Button, MediaImage, MediaImageWrapper } from '../../atoms';
import { CardBlock, Error, ConnectionError, Note } from '../../molecules';
import { showScreenHeader } from '../../redux/user/actions';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';
import routes from '../../constants/routes';

const AddContactlessCard3 = (props) => {
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const userInfo = useSelector((reduxState) => reduxState.user);
  const { fields, isInterac, state, setState, toNextStep, toPreviousStep, t } = props;
  const dispatch = useDispatch();
  const customerId = userInfo.customerId;
  const sessionInstanceId = userInfo.sessionInstanceId;
  const accountId = state.AccountId;
  const nicknameOrFirstName = ' ';
  const [token, setToken] = useState('');
  const [error, setIsError] = useState('');
  const [iframeExpired, setIframeExpired] = useState(false);
  const [hideIframe, setHideIframe] = useState(false);
  const iframeHeight = isInterac ? '350px' : '700px';
  //const returnpath = `${routes.addCard}?creditCard=true&nextstep=4`
  const loginEmail = useSelector((state) => state.user.loginEmail);

  function refreshIframe() {
    document.getElementById('iframe').src += '';
    setIframeExpired(false);
    setHideIframe(false);
  }
  function setErrorAndRefreshIframe(errorText) {
    setIsError(errorText);
    refreshIframe();
  }

  const handleIframeTask = (e) => {
    if (!isJsonString(e.data)) {
      return;
    }

    const iframeResponse = JSON.parse(e.data);

    if (iframeResponse.StatusCode === monerisConstants.responses.success) {
      const mediaInstanceId = iframeResponse?.AssociatedMedia?.MediaIdentifiers[0].mediaInstanceId;
      if (mediaInstanceId === null) {
        setErrorAndRefreshIframe(t('monerisErrorTechnicalError'));
      } else {
        const maskedPan = iframeResponse.Media?.MaskedPan;
        const cardType = iframeResponse.Media?.CardType;
        setState({
          ...state,
          MediaId: mediaInstanceId,
          CardProvider: cardType,
          MaskedPan: maskedPan,
          AccountId: state.AccountId,
        });
        dispatch(showScreenHeader(false));
        //toNextStep();
        var crediordebit = isInterac ? 'debit' : 'credit';
        var returnpath = `${routes.addCard}?addCardtype=${crediordebit}&param1= ${cardType}&param2=${maskedPan}&param3=${mediaInstanceId}&param4=${state.AccountId}`;
        redirectToUrl(`${routes.b2cRegisteredSignIn}?email=${loginEmail}&returnUrl=${returnpath}`);
      }
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.unauthorized ||
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorE
    ) {
      setIsError('');
      setIframeExpired(true);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededI ||
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededE
    ) {
      setIsError(t('monerisErrorTooManyAttempts'));
      setIframeExpired(false);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorE
    ) {
      setErrorAndRefreshIframe(t('monerisErrorAuthenticationError'));
    } else if (iframeResponse.StatusCode === monerisConstants.responses.expiredCard) {
      setErrorAndRefreshIframe(t('monerisErrorExpiredCard'));
    } else if (iframeResponse.StatusCode === monerisConstants.responses.confirmationIdError) {
      setErrorAndRefreshIframe(t('monerisErrorconfirmationId'));
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.generalError &&
      iframeResponse.Message !== null
    ) {
      if (iframeResponse.Message === monerisConstants.messages.mediaNotFound) {
        setErrorAndRefreshIframe(t('monerisErrorMediaNotFound'));
      } else if (iframeResponse.Message === monerisConstants.messages.mediaNotRegistered) {
        setErrorAndRefreshIframe(t('monerisErrorUnsuccessfulAdd'));
      } else if (iframeResponse.Message === monerisConstants.messages.mediaRegisteredToAccount) {
        setErrorAndRefreshIframe(t('monerisErrorRegisteredCardSameAccount'));
      } else if (
        iframeResponse.Message === monerisConstants.messages.mediaRegisteredToAnotherAccount
      ) {
        setErrorAndRefreshIframe(t('monerisErrorRegisteredCardAnotherAccount'));
      } else {
        setErrorAndRefreshIframe(t('monerisErrorTechnicalError'));
      }
    } else {
      setErrorAndRefreshIframe(t('monerisErrorTechnicalError'));
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeTask);
    let stsAccessToken = localstorageService().getItem(localStorageKeys.stsAccessToken);
    setToken(stsAccessToken);
    // api
    //   .getAccessToken()
    //   .then((accessToken) => setToken(accessToken))
    //   .catch(() => {
    //     setToken('');
    //   });
    return () => window.removeEventListener('message', handleIframeTask);
  }, []);

  return (
    <>
      <CardBlock
        title={fields.cardDetailsTitle.value}
        titleA11y={fields.cardDetailsTitleA11y.value}
      >
        {error ? <Error title={error} small /> : null}
        <RichText field={fields.cardDetailsDescription} />
        <div role="group" tabIndex="0" aria-label={fields.paymentOptionsA11y.value}>
          <MediaImageWrapper>
            {isInterac ? (
              <MediaImage interacCard />
            ) : (
              <>
                <MediaImage visaCard />
                <MediaImage masterCard />
                <MediaImage amexCard />
              </>
            )}
          </MediaImageWrapper>
        </div>
        <div>
          {configKeys &&
            token &&
            configKeys
              .filter((item) => item.key === 'Ats.GatewayUrl')
              .map((configKey) => (
                <Iframe
                  id="iframe"
                  height={iframeHeight}
                  width="100%"
                  frameBorder="0"
                  position="relative"
                  scrolling="no"
                  display={hideIframe ? 'none' : 'block'}
                  url={`${
                    configKey.setting
                  }/AddToCard/${sessionInstanceId}/${getShortLanguageCode()}/${nicknameOrFirstName}/${isInterac}/${customerId}/${accountId}/${token}`}
                />
              ))}

          {iframeExpired && (
            <ConnectionError
              title={t('connectionErrorTitle')}
              titleA11y={t('connectionErrorTitle')}
              message={t('connectionErrorMonerisExpirationMessage')}
              messageA11y={t('connectionErrorMonerisExpirationMessage')}
              buttonA11y={fields.refreshButtonTextA11y.value}
              buttonText={fields.refreshButtonText.value}
              buttonOnClick={() => {
                refreshIframe();
              }}
            />
          )}
        </div>
      </CardBlock>
      <ButtonBlock>
        <ButtonBlock right>
          <Button onClick={() => toPreviousStep()} white>
            <Text field={fields.cancelButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default AddContactlessCard3;
