import React from 'react';
import './autoloadGridItem.scss';

const AutoloadGridItem = ({ leftLabel, leftAmount, rightLabel, rightAmount }) => {
  return (
    <div className="autoload-grid">
      <div className="section-left">
        <div>{leftLabel}</div>
        <div className="section-amount">{leftAmount}</div>
      </div>
      <div className="section-right">
        <div>{rightLabel}</div>
        <div className="section-amount">{rightAmount}</div>
      </div>
    </div>
  );
};

export default AutoloadGridItem;
