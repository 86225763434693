import React, { useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { formatDecimalWithCurrency, formatDate, redirectToUrl } from '../../services/utils';
import { PrintButton, ButtonBlock, Button, IconButton } from '../../atoms';
import { Success, CheckoutGrid, Header, ThirdPartySystemErrorWarning } from '../../molecules';

import printIcon from '../../assets/images/printer.svg';

import './setAutorenew.scss'; // TODO refactor - mostly copy from Checkout

const SetAutorenew2 = ({
  fields,
  state,
  backToTransitPassesButtonLink,
  userInfo,
  thirdPartySystemErrorType,
}) => {
  const checkoutData = state.checkoutData
    ? state.checkoutData
    : {
        serviceProvider: state.serviceProviderId,
        productId: state.product.ProductId,
        name: state.product.ProductName,
        subTotal: state.product.ProductPrice,
        validityStartDate: state.product.ValidityStartDate,
        validityEndDate: state.product.ValidityEndDate,
        autorenew: {
          ...state.autorenew,
        },
      };

  const checkoutItem = {
    ...checkoutData,
    visibleId: userInfo.selectedCard.visibleId,
    autorenew: {
      ...checkoutData.autorenew,
      endDate:
        checkoutData.autorenew.year && checkoutData.autorenew.month
          ? `${checkoutData.autorenew.year}-${checkoutData.autorenew.month}-${new Date(
              checkoutData.autorenew.year,
              parseInt(checkoutData.autorenew.month, 10),
              0
            ).getDate()} 00:00:00Z`
          : null,
    },
  };

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  return (
    <div className="autorenew">
      <Header centered titleA11y={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>

      {thirdPartySystemErrorType ? (
        <ThirdPartySystemErrorWarning
          {...{
            fields,
            errorCode: thirdPartySystemErrorType,
            referenceNumber: state.confirmationInfo?.reference,
          }}
        />
      ) : (
        <>
          {state.confirmationInfo?.orderNumber ? (
            <div className="header-subTitle-bold">
              <Text field={fields.confirmationNumberLabel} />
              {state.confirmationInfo?.orderNumber}
            </div>
          ) : null}
          <Success
            title={fields.orderConfirmedDescription}
            Content={() => (
              <>
                <CheckoutGrid
                  fields={fields}
                  items={[checkoutItem]}
                  fareMedia={{ visibleId: userInfo.selectedCard.visibleId, autoRenew: 'true' }}
                  success
                  hideRemove={true}
                />
                <div className="autorenew-agreement-success">
                  <div className="autorenew-agreement-title no-padding">
                    <Text field={fields.autorenewAgreementPeriodTitle} />
                  </div>
                  {checkoutItem.autorenew.endDate ? (
                    <div>
                      {`${formatDate(new Date())} - ${formatDate(checkoutItem.autorenew.endDate)}`}
                    </div>
                  ) : null}

                  <div className="autorenew-agreement-note">
                    {fields.autorenewAgreementPeriodDescription.value
                      .replace(
                        '{monthly-payment-amount}',
                        formatDecimalWithCurrency(checkoutItem.subTotal)
                      )
                      .replace('{transit-pass-name}', checkoutItem.name)}
                  </div>
                </div>
                <div className="checkout-total successModifier">
                  <Text field={fields.totalAmountLabel} />
                  <span>{formatDecimalWithCurrency(checkoutItem.subTotal)}</span>
                </div>
              </>
            )}
          />
          {/* <ButtonBlock>
            <PrintButton />
            <ButtonBlock right>
              <Button
                firstOrder
                onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
                buttonTextA11y={fields.toTransitPassesButtonTextA11y.value}
              >
                <Text field={fields.toTransitPassesButtonText} />
              </Button>
            </ButtonBlock>
          </ButtonBlock> */}

          <div className="autorenew2-footer ">
            <div className="autorenew2-transitpass-btn">
              <Button
                firstOrder
                onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
                buttonTextA11y={fields.toTransitPassesButtonTextA11y.value}
              >
                <Text field={fields.toTransitPassesButtonText} />
              </Button>
            </div>
            <div id="autorenew2-printicon">
              <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
                {fields.Print.value}
              </IconButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(SetAutorenew2);
