import React from 'react';

import { useLocation } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  formatDecimalWithCurrency,
  redirectToUrl,
  convertToDollars,
  formatDate,
  localstorageService,
} from '../../services/utils';
import UnpaidTransactions from './UnpaidTransactions';
import { PrintButton, ButtonBlock, Button } from '../../atoms';
import { Error, ValueRow, Success, Header } from '../../molecules';

const UnpaidFares2 = (props) => {
  const { state, toNextStep, toPreviousStep, setState, fields, t } = props;
  const { DebtRecoveryRequestTimestamp } = state.data;
  //const { unpaidFaresData, unpaidFareIndex } = state;
  const unpaidFaresData = JSON.parse(localstorageService().getItem(localStorageKeys.unpaidFaresData));
  const unpaidFareIndex = JSON.parse(localstorageService().getItem(localStorageKeys.unpaidFaresIndex));
  const error = !state.data.Success;
  const location = useLocation();
  const linkToTransactionHistory = fields.linkToTransactionHistory.value?.href;

  const successMessage = `${fields.SuccessMessageBeginningText.value} ${unpaidFareIndex + 1} ${fields.SuccessMessageMiddleText.value
    } ${unpaidFaresData && unpaidFaresData.length} ${fields.SuccessMessageEndText.value}`;
  const confirmationNumber = unpaidFaresData[unpaidFareIndex].visibleSalesOrderId;

  return (
    <>
      <Header centered headerTitleA11y={fields.payUnpaidFareHeadingA11y}>
        <Text field={fields.payUnpaidFareHeading} />
      </Header>

      {error ? (
        <>
          <Error title={fields.failureMessageText.value}>{t('unpaidFaresAttemptDeclined')}</Error>
          {!!unpaidFaresData &&
            unpaidFaresData.map((payment, index) => (
              <UnpaidTransactions
                data={payment}
                toNextStep={toNextStep}
                state={state}
                setState={setState}
                fields={fields}
                unpaidFareIndex={index}
                t={t}
                isButton={false}
              />
            ))}
          <ButtonBlock>
            <PrintButton />
            <ButtonBlock right />
            {linkToTransactionHistory !== '' && (
              <Button
                white
                onClick={() => redirectToUrl(linkToTransactionHistory)}
                buttonTextA11y={fields.goToTransactionHistoryButtonTextA11y.value}
              >
                <Text field={fields.goToTransactionHistoryButtonText} />
              </Button>
            )}
            <Button
              onClick={() => toPreviousStep()}
              buttonTextA11y={fields.retryButtonTextA11y.value}
            >
              <Text field={fields.retryButtonText} />
            </Button>
          </ButtonBlock>
        </>
      ) : (
        <>
          <Success
            title={
              !unpaidFaresData.length
                ? { value: successMessage }
                : fields.completesuccessMessageText
            }
            contextText={!unpaidFaresData.length ? fields.farePaidDescription : { value: '' }}
            Content={() => (
              <div>
                <div>
                  <ValueRow
                    left={<Text field={fields.paymentAmountLabel} />}
                    right={formatDecimalWithCurrency(
                      convertToDollars(unpaidFaresData[unpaidFareIndex].amount)
                    )}
                  />
                  <ValueRow
                    left={<Text field={fields.paymentDateLabel} />}
                    right={formatDate(DebtRecoveryRequestTimestamp)}
                  />
                  <ValueRow
                    left={<Text field={fields.confirmationNumberLabel} />}
                    right={<Text field={{ value: confirmationNumber }} />}
                  />
                  <div className="unpaidFares-success-note">
                    <div>
                      <Text field={fields.recordNoteBeginning} />
                      &nbsp;
                      {t('unpaidFareCardIdentifier')}/{confirmationNumber}
                    </div>
                    <Text field={fields.recordNoteEnd} />
                  </div>
                </div>
              </div>
            )}
          />
          <ButtonBlock>
            <PrintButton />
            <ButtonBlock right>
              {!unpaidFaresData.length ? (
                <>
                  {linkToTransactionHistory !== '' && (
                    <Button
                      white
                      onClick={() => redirectToUrl(linkToTransactionHistory)}
                      buttonTextA11y={fields.goToTransactionHistoryButtonTextA11y.value}
                    >
                      <Text field={fields.goToTransactionHistoryButtonText} />
                    </Button>
                  )}
                  <Button
                    onClick={() => redirectToUrl(location.pathname, false)}
                    buttonTextA11y={fields.goToUnpaidFaresButtonTextA11y.value}
                  >
                    <Text field={fields.goToUnpaidFaresButtonText} />
                  </Button>
                </>
              ) : (
                <>
                  {linkToTransactionHistory !== '' && (
                    <Button
                      onClick={() => redirectToUrl(linkToTransactionHistory)}
                      buttonTextA11y={fields.goToTransactionHistoryButtonTextA11y.value}
                    >
                      <Text field={fields.goToTransactionHistoryButtonText} />
                    </Button>
                  )}
                </>
              )}
            </ButtonBlock>
          </ButtonBlock>
        </>
      )}
    </>
  );
};

export default withTranslation()(UnpaidFares2);
