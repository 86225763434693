import React, { useState } from 'react';
import './Divider.scss';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const Divider = (props) => {
  const dividerClassName = (props && props.params.classname) ? props.params.classname : 'dividerlarge';
  const [isLoaded, setIsLoaded] = useState(false);
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [props]);

  if (!isLoaded) return null;
  return (
    <p aria-hidden="true" className={dividerClassName}>
      <hr />
    </p>
  );
};

export default Divider;
