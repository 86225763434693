export default {
  SET_OVERLAY: 'contactless/SET_OVERLAY',
  IS_IFRAME_ENABLED: 'contactless/IS_IFRAME_ENABLED',
  IS_CONTACTLESS_USER_SIGNED_IN: 'contactless/IS_CONTACTLESS_USER_SIGNED_IN',
  SET_USER_INFO_DATA: 'contactless/SET_USER_INFO_DATA',
  SET_SIGN_OUT_REDIRECT_URL: 'contactless/SET_SIGN_OUT_REDIRECT_URL',
  SET_SETTINGS: 'contactless/SET_SETTINGS',
  SET_REDIRECT_URL: 'contactless/SET_REDIRECT_URL',
  SET_CONTACTLESS_TRANS_ITEMS: 'contactless/SET_CONTACTLESS_TRANS_ITEMS',
  SET_UNPAID_FARES: 'contactless/SET_UNPAID_FARES',
  SET_MULTI_UNPAID_FARES_PROPS: 'contactless/SET_MULTI_UNPAID_FARES_PROPS',
  UPDATE_RETRY_COUNTER: 'contactless/UPDATE_RETRY_COUNTER',
  SET_PAYMENTS_TO_VERIFY: 'contactless/SET_PAYMENTS_TO_VERIFY',
  CONTACTLESS_LOGIN_TIME: 'contactless/CONTACTLESS_LOGIN_TIME',
  CONTACTLESS_LOGOUT_TIME: 'contactless/CONTACTLESS_LOGOUT_TIME',
};
