import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { Button, ButtonBlock } from '../../atoms';
import { CardBlock } from '../../molecules';
import api from '../../services/api';
import { redirectToUrl, getRefreshToken, localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';
import './deleteAccount.scss';

const DeleteAccount = ({ fields, t }) => {
  const focusDiv = useRef();
  const userInfo = useSelector((reduxState) => reduxState.user);
  const [requestDeletion, setRequestdeletion] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isProceedBtnDisabled, setProceedBtnDisable] = useState(true);
  const cwsRedirectUrl = fields.RedirectUrl?.value?.href;
  const gotostep2 = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setRequestdeletion(true);
    focusDiv.current.focus();
  };

  const cancelDeleteAccount = () => {
    redirectToUrl(fields.GoBackButtonUrl?.value?.href);
  };
  const clearReduxHistory = () => {
    localstorageService().removeItem('redux');
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.accessTokenValidity);
    localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.guestToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    localstorageService().removeItem(localStorageKeys.gtmContainerId);
    localstorageService().removeItem(localStorageKeys.unpaidFaresData);
    localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
    localstorageService().removeItem(localStorageKeys.stsAccessToken);
    localstorageService().removeItem(localStorageKeys.unpaidFaresIndex);
    localstorageService().removeItem(localStorageKeys.paymentsToVerifyData);
    api.deleteaccountSignOut(cwsRedirectUrl);
  };

  const deleteAccountSubmitHandler = () => {
    setIsButtonDisabled(true);
    setProceedBtnDisable(false);
    const payload = {
      Category: fields?.Category?.value,
      Comments: fields?.Comment?.value,
      IsRevertBack: true,
      Source: 'CWS',
      CustomerId: userInfo.customerId,
      FirstName: userInfo.firstName,
      LastName: userInfo.lastName,
      Email: userInfo.email,
      PhoneNumber: userInfo.phoneNumber ? userInfo.phoneNumber : '',
    };
    api
      .customerAccountDeletion(payload)
      .then((response) => {
        if (response?.data?.Success) {
          api
            .logOut({ Token: getRefreshToken() })
            .then(() => {
              clearReduxHistory();
            })
            .catch((e) => {
              setIsButtonDisabled(false);
              setProceedBtnDisable(true);
            });
        }
        else {
          setIsButtonDisabled(false);
          setProceedBtnDisable(true);
        }
      })
      .catch((err) => {
        setIsButtonDisabled(false);
        setProceedBtnDisable(true);
      });
  };
  return (
    <div className="delete-account">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      {!requestDeletion ? (
        <CardBlock title={fields?.Step1SubHeading?.value}>
          <RichText field={fields?.Step1Content} />
          <ButtonBlock>
            <ButtonBlock right>
              <Button
                dataTestId="request-btn"
                redBackground
                buttonTextA11y={fields?.RequestButtonA11y?.value}
                onClick={() => gotostep2()}
              >
                <Text field={fields?.RequestButtonText} />
              </Button>
            </ButtonBlock>
          </ButtonBlock>
        </CardBlock>
      ) : (
        <CardBlock>
          <RichText field={fields?.Step2Content} />
          <ButtonBlock className="step2-btn-wrapper">
            <ButtonBlock right>
              <Button
                dataTestId='proceed-btn'
                redBackground={isProceedBtnDisabled}
                buttonTextA11y={fields?.ProceedButtonA11y?.value}
                onClick={() => deleteAccountSubmitHandler()}
                isDisabled={isButtonDisabled}
              >
                <Text field={fields?.ProceedButtonText} />
              </Button>
              <Button
                dataTestId='cancel-btn'
                firstOrder
                onClick={() => cancelDeleteAccount()}
                buttonTextA11y={fields?.GoBackButtonA11y?.value}
              >
                <Text field={fields?.GoBackButtonText} />
              </Button>

            </ButtonBlock>
          </ButtonBlock>
        </CardBlock>
      )}
    </div>
  );
};

export default withTranslation()(DeleteAccount);
