import types from './types';

export function setBusinessRules(data) {
  return {
    type: types.SET_BUSINESS_RULES,
    payload: {
      ...data,
    },
  };
}
