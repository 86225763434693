import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { itemsAreLoading } from '../../redux/user/actions';
import api from '../../services/api';
import envelopeIcon from '../../assets/images/envelope_checked.svg';
import { ButtonBlock, Button } from '../../atoms';
import { ValueRowSecond, CardBlock } from '../../molecules';
import { setUserPersonalInfo } from '../../redux/user/actions';

import './personalInformation.scss';

const PersonalInformation = (props) => {
  const dispatch = useDispatch();
  const updateBtnRef = useRef();
  const [personalData, setPersonalData] = useState({});
  const [isFocusable, setIsFocusable] = useState(false);
  const { firstName, lastName, phoneNumber, currentShippingDetails, managePersonalInfo } = useSelector((reduxState) => reduxState.user);


  useEffect(() => {
    dispatch(
      setUserPersonalInfo({
        updatePersonalInfoEnabled: false,
        userPersonalInfo: {},
        submittedInfo: {},
      })
    );
    getPersonalInformation();
  }, []);

  useEffect(() => {
    if (
      updateBtnRef &&
      updateBtnRef.current &&
      props?.fields?.prestoPromotionalMailNoteAnchorText?.value
    ) {
      updateBtnRef.current.focus();
    }
  }, [props?.fields?.prestoPromotionalMailNoteAnchorText?.value]);

  useEffect(() => {
    const urlId = window.location.hash.substring(1);
    if (isFocusable && urlId === props?.fields?.prestoPromotionalMailNoteAnchorText?.value) {
      updateBtnRef.current.focus();
      window.scrollTo(0, updateBtnRef.current.offsetTop + 5);
    }
  }, [isFocusable]);

  useEffect(() => {
    if (managePersonalInfo?.updatePersonalInfoEnabled === false) {
      if (managePersonalInfo?.submittedInfo?.success) {
        getPersonalInformation();
      }
      document.body.scrollTop = 0;
    }
  }, [managePersonalInfo]);

  const getPersonalInformation = () => {
    api
      .getPersonalData()
      .then(({ data }) => {
        if (data.Success === true) {
          setIsFocusable(true);
          setPersonalData(data.Data);
        } else {
          // TODO: Error hadling on UI
          console.warn('Error while fetching personal user info.');
        }
      })
      .catch((e) => {
        // TODO: Error hadling on UI
        console.warn('Error while fetching personal user info.', e);
      })
      .then(() => {
        dispatch(itemsAreLoading(false));
      });
    dispatch(itemsAreLoading(true));
  };

  const updateInfoHandler = () => {
    dispatch(
      setUserPersonalInfo({
        updatePersonalInfoEnabled: true,
        userPersonalInfo: personalData,
        submittedInfo: {},
      })
    );
  };

  return managePersonalInfo?.updatePersonalInfoEnabled ? null : (
    <div className="personalInformation">
      <h1 className="header-title">
        <Text field={props.fields.heading} />
      </h1>
      {managePersonalInfo?.submittedInfo?.submit ? (
        <>
          {managePersonalInfo?.submittedInfo?.success ? (
            <div className="update-personalinfo-message">
              <div className="success-message" role="alert">
                <div id="message">{props.fields.InfoUpdateSuccess.value}</div>
              </div>
            </div>
          ) : (
            <div className="update-personalinfo-message-error">
              <div className="error-personalinfo-message" role="alert">
                <div id="messageerror">{props.fields.InfoUpdateError.value}</div>
              </div>
              <div className="message-error-description ">
                <p>{props.fields.InfoUpdateErrorDesc.value}</p>
              </div>
            </div>
          )}
        </>
      ) : null}

      {/* Line1 - APartemnt address and Line2 - street address */}

      <CardBlock>
        <div
          className={`personalInformation-table ${
            !props.fields.transactionActivitiesMailNote.value && 'transNoteEmpty-table'
          }`}
        >
          <ValueRowSecond
            left={props.fields.firstName.value}
            right={firstName || personalData.Name?.FirstName || '-'}
            rightBold
            disableTopPadding
          />
          <ValueRowSecond
            left={props.fields.lastName.value}
            right={lastName || personalData.Name?.LastName || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.addressFirstLine.value}
            right={currentShippingDetails?.Line2 || personalData.Address?.Line2 || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.addressSecondLine.value}
            right={currentShippingDetails?.Line1 || personalData.Address?.Line1 || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.city.value}
            right={currentShippingDetails?.City || personalData.Address?.City || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.province.value}
            right={currentShippingDetails?.Area?.Name || personalData.Address?.Area?.Name || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.country.value}
            right={currentShippingDetails?.Country?.Name || personalData.Address?.Country?.Name || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.postalCode.value}
            right={currentShippingDetails?.PostalCode || personalData.Address?.PostalCode || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.primaryPhoneNumber.value}
            right={phoneNumber || personalData?.PhoneNumber || '-'}
            rightBold
          />
          <ValueRowSecond
            left={props.fields.secondaryPhoneNumber.value}
            right={personalData?.PhoneNumber2 || '-'}
            rightBold
          />
          {personalData.MarktOptInOut !== 'on' ? null : (
            <div
              className={`personalInformation-tableRow ${
                !props.fields.transactionActivitiesMailNote.value && 'transNoteEmpty'
              }`}
            >
              <div className="personalInformation-emailNotificationNote">
                {/* Decorative image - alt is intentionaly left empty */}
                <div>
                  <img src={envelopeIcon} alt="" />
                </div>
                <div>
                  <Text field={props.fields.prestoPromotionalMailNote} />
                </div>
              </div>
            </div>
          )}
          {personalData.TraxOptInOut !== 'on' ||
          props.fields.transactionActivitiesMailNote.value === '' ? null : (
            <div className="personalInformation-tableRow">
              <div className="personalInformation-emailNotificationNote">
                {/* Decorative image - alt is intentionaly left empty */}
                <div>
                  <img src={envelopeIcon} alt="" />
                </div>
                <div>
                  <Text field={props.fields.transactionActivitiesMailNote} />
                </div>
              </div>
            </div>
          )}
        </div>
        <ButtonBlock>
          <ButtonBlock right>
            <Button
              id={props?.fields?.prestoPromotionalMailNoteAnchorText?.value}
              customProp={{
                ref: updateBtnRef,
              }}
              onClick={() => updateInfoHandler()}
            >
              <Text field={props.fields.updateButtonLabel} />
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      </CardBlock>
    </div>
  );
};

export default PersonalInformation;
