import React from 'react';
import './button.scss';

import Icon from '../Icon';

export default function Button({
  children,
  type,
  onClick,
  white,
  firstOrder,
  buttonTextA11y,
  isDisabled,
  iconAfter = '',
  iconSize,
  iconColor,
  iconAriaLabel = '',
  textTransform = false,
  customProp = {},
  id = '',
  redBackground = false,
  dataTestId,
}) {
  return (
    <button
      className={`buttonComponent${white ? ' white' : ''}${firstOrder ? ' firstOrder' : ''}${isDisabled ? ' disabled' : ''
        } ${textTransform ? 'textTransform' : ''}${redBackground ? 'redBackground' : ''}`}
      onClick={!isDisabled ? onClick : null}
      type={type || 'button'}
      aria-label={buttonTextA11y}
      aria-disabled={isDisabled}
      tabIndex="0"
      {...customProp}
      id={id}
      data-testid={dataTestId}
    >
      {children}
      {iconAfter && (
        <span className="button-iconAfter">
          <Icon iconName={iconAfter} size={iconSize} color={iconColor} ariaLabel={iconAriaLabel} />
        </span>
      )}
    </button>
  );
}
