import React, { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './termsAndConditions.scss';
import { withTranslation } from 'react-i18next';

import sharedFormConstants from '../../constants/sharedFormConstants';
import { Button, ButtonBlock, Checkbox, PrintButton } from '../../atoms';
import { Success, TextInput } from '../../molecules';
import api from '../../services/api';

const EmailButton = ({ onClick, isExpanded, buttonText, buttonTextA11y }) => (
  <button
    type="button"
    onClick={onClick}
    className="email-button"
    aria-label={buttonTextA11y}
    aria-expanded={isExpanded}
  >
    {buttonText}
  </button>
);

const FocusTrap = require('focus-trap-react');

const formikValidation = (t) => {
  return Yup.object().shape({
    Email: Yup.string()
      .required(t('termsAndConditionsEmailRequired'))
      .email(t('termsAndConditionsEmailRequired')),
  });
};

const formikInitialValues = (email) => {
  return {
    Email: email,
  };
};

const TermsAndConditions = (props) => {
  const email = useSelector((state) => state.user.email);
  const { fields, t } = props;
  const isMobile = useMedia({ maxWidth: '920px' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const modalRef = useRef(null);
  let lastActiveElement = null;

  useEffect(() => {
    if (isModalOpen) {
      lastActiveElement = document.activeElement;
      modalRef.current.focus();
    }
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openEmailCard = () => {
    setIsEmailOpen(true);
  };
  const closeEmailCard = () => {
    setIsEmailOpen(false);
  };
  const emailSent = () => {
    setIsEmailSent(true);
  };
  const emailNotSent = () => {
    setIsEmailSent(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    closeEmailCard();
    emailNotSent();
    lastActiveElement && lastActiveElement.focus();
  };

  const initialValues = formikInitialValues(email);
  const validation = formikValidation(t);
  const handleTermsSendSubmit = (values) => {
    const data = { email: values.Email };
    api.createTermsAndConditionsNotification(data).then((response) => {
      if (response.data.Success) {
        emailSent();
      }
    });
  };
  return (
    <>
      <div>
        <Checkbox name={sharedFormConstants.terms} checkboxA11y={t('termsAndConditionsA11y')}>
          <span className="checkboxComponent-span">{t('termsAndConditionsAgreement')}</span>
          <a
            aria-label={`${t('termsAndConditionsLinkText')}. ${t('opensDialogTextA11y')}`}
            role="button"
            href="#"
            className="link"
            onClick={openModal}
          >
            {redeemStepper?.redeemVoucherStepperBlock
              ? t('termsAndConditionsNewText')
              : t('termsAndConditionsLinkText')}
          </a>
        </Checkbox>
      </div>

      {/* TODO - Refactor - change to modal molecule */}
      {isModalOpen && (
        <>
          <div className="tac-modal-overlay" role="presentation" onClick={closeModal} />
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              returnFocusOnDeactivate: true,
              escapeDeactivates: false,
            }}
          >
            <div className="tac-modal-wrapper">
              <div
                ref={modalRef}
                aria-modal="true"
                id="dialogmodal"
                role="dialog"
                aria-labelledby="dialog_label"
                className="tac-modal"
              >
                <div
                  id="dialog_label"
                  className="tac-modal-content-title"
                  aria-label={fields.headingA11y.value}
                >
                  <Text field={fields.heading} />
                </div>
                <div className="tac-modal-box tac-modal-content-text tac-modal-padright">
                  <RichText field={fields.content} aria-label={fields.contentA11y.value} />
                </div>
                <div className="tac-modal-content">
                  <hr />
                  <ButtonBlock>
                    <div className="tac-modal-content-iconButtons">
                      <PrintButton />
                      {!isMobile && (
                        <div className="tac-modal-content-iconButtons-email">
                          <EmailButton
                            onClick={isEmailOpen ? closeEmailCard : openEmailCard}
                            isExpanded={isEmailOpen}
                            buttonText={fields.emailButtonText.value}
                            buttonTextA11y={fields.emailButtonA11y.value}
                          />
                        </div>
                      )}
                    </div>
                    <ButtonBlock right>
                      <Button
                        type="button"
                        onClick={closeModal}
                        buttonTextA11y={fields.closeButtonTextA11y.value}
                      >
                        <Text field={fields.closeButtonText} />
                      </Button>
                    </ButtonBlock>
                    {isMobile && (
                      <div className="tac-modal-content-iconButtons">
                        <EmailButton
                          onClick={isEmailOpen ? closeEmailCard : openEmailCard}
                          isExpanded={isEmailOpen}
                          buttonText={fields.emailButtonText.value}
                          buttonTextA11y={fields.emailButtonA11y.value}
                        />
                      </div>
                    )}
                  </ButtonBlock>
                  {isEmailOpen && (
                    <div>
                      <div className="tac-modal-content-emailLine">
                        <hr />
                      </div>
                      {isEmailSent && (
                        <div className="tac-modal-content-success">
                          <Success
                            title={fields.emailConfirmationSuccessMessage}
                            titleA11y={fields.emailConfirmationSuccessMessageA11y.value}
                          />
                        </div>
                      )}
                      <div
                        className="tac-modal-content-text"
                        aria-label={fields.emailDescriptionA11y.value}
                      >
                        <Text field={fields.emailDescription} />
                      </div>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleTermsSendSubmit}
                      >
                        {({ errors, touched, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="tac-modal-content-emailSend">
                              <TextInput
                                type="text"
                                name="Email"
                                errors={errors}
                                touched={touched}
                                label={fields.emailInputLabel}
                                length={60}
                              />
                              <ButtonBlock>
                                <ButtonBlock right>
                                  <div className="tac-modal-content-emailSend-button">
                                    <Button
                                      type="submit"
                                      buttonTextA11y={
                                        isEmailSent
                                          ? fields.sentButtonTextA11y.value
                                          : fields.sendButtonTextA11y.value
                                      }
                                    >
                                      <Text
                                        field={
                                          isEmailSent
                                            ? fields.sentButtonText
                                            : fields.sendButtonText
                                        }
                                      />
                                    </Button>
                                  </div>
                                </ButtonBlock>
                              </ButtonBlock>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </FocusTrap>
        </>
      )}
    </>
  );
};

export default withTranslation()(TermsAndConditions);
