import initialState from '../initialState';
import types from './types';

export function products(state = initialState, action = '') {
  switch (action.type) {
    case types.SET_TRANSIT_AGENCY_LOGOS: {
      const logos = action.payload;

      return {
        ...state,
        logos,
      };
    }
    case types.SET_DISCOUNT_TYPES: {
      const discounts = action.payload;

      return {
        ...state,
        discounts,
      };
    }
    case types.SET_TRANSIT_PASSES: {
      const { serviceProvider } = action.payload;

      return {
        ...state,
        transitPasses: serviceProvider.map((item) => ({
          id: item.Id,
          name: item.Name,
          logo: {
            src: item.Products[0]?.ProductAttibutes.Logo.Src,
            alt: item.Products[0]?.ProductAttibutes.Logo.Alt,
          },
        })),
      };
    }
    case types.SET_EDIT_CARD_MODE: {
      return {
        ...state,
        manageMyProduct: {
          ...state.manageMyProduct,
          editCardDetailsEnabled: action.payload.editCardDetailsEnabled,
          selectedCard: action.payload.selectedCard,
        },
      };
    }
    case types.SET_CHANGE_PIN: {
      return {
        ...state,
        manageMyProduct: {
          ...state.manageMyProduct,
          changeCardPinEnabled: action.payload.changeCardPinEnabled,
          selectedCard: action.payload.selectedCard,
        },
      };
    }
    case types.SET_REMOVE_CARD: {
      return {
        ...state,
        manageMyProduct: {
          ...state.manageMyProduct,
          removeCardEnabled: action.payload.removeCardEnabled,
          selectedCard: action.payload.selectedCard,
        },
      };
    }
    case types.SET_ACTIVATE_CARD_MODE: {
      return {
        ...state,
        manageMyProduct: {
          ...state.manageMyProduct,
          activateCardDetailsEnabled: action.payload.activateCardDetailsEnabled,
          selectedCard: action.payload.selectedCard,
          addToActivate: action.payload.addToActivate,
        },
      };
    }
    case types.SET_MANAGE_AUTO_RENEW_MODE: {
      return {
        ...state,
        manageAutoRenew: { ...state.manageAutoRenew, ...action.payload },
      };
    }
    case types.SET_REDEEMVOUCHER_STEPPER_BLOCK: {
      return {
        ...state,
        redeemVoucher: {
          redeemVoucherStepperBlock: action.payload.redeemVoucherStepperBlock,
          redeemVoucherBlock: action.payload.redeemVoucherBlock,
          apiData: action.payload.apiData,
          voucherCode: action.payload.voucherCode,
          submitApiData: action.payload.submitApiData,
        },
      };
    }
    case types.SET_ACTIVATE_CARD_MYPRODUCTS:
      return {
        ...state,
        activateCardfromMyProducts: action.payload,
      };

    default:
      return state;
  }
}
