export default {
  11: 'TTC',
  10: 'YOR',
  3: 'BRA',
  4: 'BUR',
  2: 'DUR',
  1: 'GOT',
  14: 'UPE',
  7: 'OAK',
  5: 'HAM',
  6: 'MIS',
  12: 'OCT',
};
