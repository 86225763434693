import React, { useRef, useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  selectCard,
  getCurrentBalance,
  setGoogleWalletData,
  updateIsCardClickedStatus,
  fetchWalletCardDetails,
} from '../../redux/user/actions';
import {
  formatDecimalWithCurrency,
  formatPrestoCardNumber,
  formatPrestoCardNumberA11y,
  redirectToUrl,
  hasAnyCard,
  convertToDollars,
  isFrench,
} from '../../services/utils';
import routes from '../../constants/routes';
import mediaSubTypes from '../../constants/mediaSubTypes';
import langConstants from '../../constants/lang';
import mediaTypes from '../../constants/mediaTypes';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import { MediaImage } from '../../atoms';
import downIcon from '../../assets/images/down.svg';
import whiteLogo from '../../assets/images/PRESTO_White_logo.svg';
import noCard from '../../assets/images/switch-nocard.svg';
import './cardSwitcher.scss';
import api from '../../services/api';
import mediaSubType from '../../constants/mediaSubType';
import useMediaWidth from '../../hooks/useMediaWidth';

const CardSwitcher = ({ t, props = {}, setLoading }) => {
  const location = useLocation();
  const userState = useSelector((state) => state.user);
  const isMobile = useSelector((state) => state.user.isMobile);
  const { fareMedias, contactlessMedias, selectedCard, isCardClicked } = userState;
  const [currentBalanceRequested, setCurrentBalanceRequested] = useState(false);
  const allCards = fareMedias.concat(contactlessMedias);
  const cardDropdownRef = useRef(null);
  const [isSelectClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false, true);
  const dispatch = useDispatch();
  const onClickSelect = () => {
    dispatch(updateIsCardClickedStatus({ isCardClicked: true }));
    openDropdown(!isSelectClicked);
  };
  const isMobileWallet = selectedCard.mediaType === mediaTypes.CRDNC;
  const isAWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.APPLE;
  const isGWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.GOOGLE;
  const checkMediaType = (array) => {
    if (array.length !== 0) {
      for (let i = 0; i < array.length; i++) {
        if (!array[i].hasOwnProperty('mediaType') || array[i].mediaType !== mediaTypes.CRDNC) {
          return false;
        }
      }
      return true;
    }
  };
  const onlyGWCards = checkMediaType(allCards);
  useEffect(() => {
    let allGWCards = [];
    const googleWallets = contactlessMedias?.filter((card) => card.mediaType === mediaTypes.CRDNC);
    googleWallets.forEach((card, index) => {
      allGWCards.push({ DPanId: card.dpan });
      if (index === googleWallets.length - 1) {
        api.getAllWalletCardDetails(allGWCards).then((res) => {
          if (res.data.status === 'ACCEPTED') {
            res.data.mediaList.map((card) => {
              const data = {
                mediaList: [card],
                displayText: res.data.displayText,
              };
              dispatch(setGoogleWalletData(data));
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isMobile) {
      if (
        props?.fields?.data?.datasource?.CardDetailsLocationPath?.jss?.value &&
        location.pathname.includes(
          props?.fields?.data?.datasource?.CardDetailsLocationPath?.jss?.value
        ) &&
        !isCardClicked
      ) {
        const gWCards = contactlessMedias.filter((card) => card.mediaType === mediaTypes.CRDNC);
        if (isMobileWallet) {
          api
            .getStoredMediaTypeInSession({
              MediaType: selectedCard.mediaSubTypeWallet,
            })
            .then(({ res }) => {
              if (gWCards[0].dpan === selectedCard.dpan) {
                dispatch(fetchWalletCardDetails);
              } else {
                api.getAllWalletCardDetails([{ DPanId: selectedCard.dpan }]).then((res) => {
                  if (res.data.status === 'ACCEPTED') {
                    res.data.mediaList.map((card) => {
                      const data = {
                        mediaList: [card],
                        displayText: res.data.displayText,
                      };
                      dispatch(setGoogleWalletData(data));
                    });
                  }
                });
              }
            });
        } else {
          if (gWCards.length > 0) {
            dispatch(
              selectCard(
                { ...gWCards[0] },
                {
                  url: routes.walletCardDetails,
                  redirected: true,
                }
              )
            );
            dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
          } else {
            dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
            setLoading(true);
            redirectToUrl(routes.dashboard);
          }
        }
        dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
      } else if (
        props?.fields?.data?.datasource?.TransactionHistoryLocationPath?.jss?.value &&
        location.pathname.includes(
          props?.fields?.data?.datasource?.TransactionHistoryLocationPath?.jss?.value
        ) &&
        !isCardClicked &&
        !selectedCard.type
      ) {
        const nonGWCard = allCards?.find((card) => card.mediaType !== mediaTypes.CRDNC);
        const googleWallets = contactlessMedias?.filter(
          (card) => card.mediaType === mediaTypes.CRDNC
        );
        if (googleWallets.length > 0) {
          api
            .getStoredMediaTypeInSession({
              MediaType: googleWallets[0].mediaSubTypeWallet,
            })
            .then((res) => console.log('response'));
        }
        if (!!nonGWCard && Object.keys(nonGWCard).length !== 0) {
          dispatch(
            selectCard(nonGWCard, {
              url: routes.transactionHistory,
              redirected: true,
            })
          );
        } else if (onlyGWCards) {
          dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
          setLoading(true);
          redirectToUrl(routes.dashboard);
        } else {
          dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
        }
      }
      dispatch(updateIsCardClickedStatus({ isCardClicked: false }));
    }
  }, []);
  const activeImageProps = {
    big: true,
    activeGWalletCard: true,
    cardImgSrc:
      props && isAWalletCard
        ? props?.fields?.data?.datasource?.AppleWalletCardActiveImage?.jss?.value?.src // apple wallet props
        : props?.fields?.data?.datasource?.GoogleWalletCardActiveImage?.jss?.value?.src,
    cardImgAlt:
      props && isAWalletCard
        ? props?.fields?.data?.datasource?.AppleWalletCardActiveImage?.jss?.value?.alt //apple wallet props
        : props?.fields?.data?.datasource?.GoogleWalletCardActiveImage?.jss?.value?.alt,
  };
  const inActiveImageProps = {
    big: true,
    inActiveGWalletCard: true,
    cardImgSrc:
      props && props?.fields?.data?.datasource?.GoogleWalletCardInactiveImage?.jss?.value?.src,
    cardImgAlt:
      props && isAWalletCard
        ? t('InactiveAppleWalletCard')
        : isGWalletCard
        ? t('InactiveGoogleWalletCard')
        : props?.fields?.data?.datasource?.GoogleWalletCardInactiveImage?.jss?.value?.alt,
  };
  const cardsA11y = {
    presto: t('prestoCard'),
    amex: t('amexCard'),
    interac: t('interacCard'),
    master: t('masterCard'),
    visa: t('visaCard'),
    CRDNC: t('mobileWalletCard'),
  };

  if (!hasAnyCard(userState)) {
    return <img className="cardSwitcher-noCard" src={noCard} alt="" />;
  }
  const selectedCardSessionHandler = (card) => {
    api.UpdateFareMediaSession({ VisibleId: card.visibleId }).then(({ data }) => {});
  };

  let renderNickName;
  if (selectedCard.nickName !== null && selectedCard.nickName?.length >= 0) {
    renderNickName = <div className="small-text">{selectedCard.nickName}</div>;
    if (selectedCard.nickName?.length <= 13) {
      renderNickName = <div className="big-text">{selectedCard.nickName}</div>;
    }
    if (selectedCard.nickName?.length > 20) {
      renderNickName = (
        <div className="small-text">{`${selectedCard.nickName.substring(0, 19)}...`}</div>
      );
    }
  }
  const onCardSwitch = async (card) => {
    const pathlang =
      card.type === mediaSubTypes.presto
        ? isFrench()
          ? '/fr-CA' + routes.transactionHistory
          : '/en' + routes.transactionHistory
        : routes.transactionHistory;
    const googleWallets = contactlessMedias?.filter((card) => card.mediaType === mediaTypes.CRDNC);
    if (card.mediaId === selectedCard.mediaId) {
      dispatch(
        selectCard(
          { ...card },
          {
            url:
              card.mediaType === mediaTypes.CRDNC
                ? routes.walletCardDetails
                : routes.transactionHistory,
            redirected: false,
          }
        )
      );
      setLoading(true);
    } else if (card.mediaType === mediaTypes.CRDNC) {
      try {
        await api.getStoredMediaTypeInSession({
          MediaType: card.mediaSubTypeWallet,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      dispatch(
        selectCard(
          { ...card },
          {
            url: routes.walletCardDetails,
            redirected: false,
          }
        )
      );
      setLoading(true);
      onClickSelect();
    } else {
      if (googleWallets.length > 0) {
        try {
          await api.getStoredMediaTypeInSession({
            MediaType: googleWallets[0].mediaSubTypeWallet,
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      dispatch(
        selectCard(
          { ...card },
          {
            url: pathlang,
            redirected: false,
          }
        )
      );
      setLoading(true);
      onClickSelect();
    }
  };
  const cardSelectionHandler = (e, card) => {
    if (e.keyCode == 13) {
      onCardSwitch(card);
    }
  };

  return (
    <>
      <div ref={cardDropdownRef} className="selectBox-wrapper">
        {allCards.length > 1 && (
          <button
            aria-expanded={isSelectClicked}
            onClick={onClickSelect}
            className="selectBox"
            aria-label={`${t('sideNavigationSwitchCardA11y').replace('{number}', allCards.length)}`}
          >
            {t('cardSwitcherSwitchCard')}
            <img alt="arrow icon" className={isSelectClicked ? 'up' : ''} src={downIcon} />
          </button>
        )}

        <div className={isSelectClicked ? 'change-card-dropdown' : 'inactive'}>
          {allCards.map((card) => {
            const isGWallet = card.mediaType === mediaTypes.CRDNC;
            const cardType = `${card.type}Card`;
            const mediaImageProps = {
              medium: true,
              [cardType]: true,
            };
            const activeImageListingProps = {
              small: true,
              activeGWalletCard: true,
              cardImgSrc:
                props && card.mediaSubTypeWallet === mediaSubType.APPLE
                  ? props?.fields?.data?.datasource?.AppleWalletCardListingActiveImage?.jss?.value
                      ?.src //apple wallet props
                  : props?.fields?.data?.datasource?.GoogleWalletCardListingActiveImage?.jss?.value
                      ?.src,
              cardImgAlt:
                props && card.mediaSubTypeWallet === mediaSubType.APPLE
                  ? props?.fields?.data?.datasource?.AppleWalletCardListingActiveImage?.jss?.value
                      ?.alt //apple wallet props
                  : props?.fields?.data?.datasource?.GoogleWalletCardListingActiveImage?.jss?.value
                      ?.alt,
            };
            const inActiveImageListingProps = {
              small: true,
              inActiveGWalletCard: true,
              cardImgSrc:
                props &&
                props?.fields?.data?.datasource?.GoogleWalletCardListingInactiveImage?.jss?.value
                  ?.src,
              cardImgAlt:
                props &&
                props?.fields?.data?.datasource?.GoogleWalletCardListingInactiveImage?.jss?.value
                  ?.alt,
            };
            const imageProps =
              card.hasActiveImageGoog || card.hasActiveImageAppl
                ? activeImageListingProps
                : inActiveImageListingProps;

            if (
              card.type === mediaSubTypes.presto &&
              card.currentBalance === null &&
              !currentBalanceRequested
            ) {
              dispatch(getCurrentBalance(card.visibleId));
              setCurrentBalanceRequested(true);
            }

            return (
              <div
                role="button"
                className="oneitem"
                tabIndex="0"
                onClick={() => {
                  onCardSwitch(card);
                }}
                onKeyDown={(e) => cardSelectionHandler(e, card)}
                onKeyPress={() => null}
              >
                {/* <MediaImage {...mediaImageProps} /> */}
                {isGWallet ? <MediaImage {...imageProps} /> : <MediaImage {...mediaImageProps} />}
                <div className="card-information">
                  <div className="card-holder">
                    {card.nickName && card.nickName.length > 20
                      ? `${card.nickName.substring(0, 19)}...`
                      : card.nickName}
                  </div>
                  <div className="card-small-text">
                    {card.type === mediaSubTypes.presto
                      ? formatDecimalWithCurrency(card.currentBalance)
                      : isGWallet
                      ? formatDecimalWithCurrency(convertToDollars(card.currentBalance))
                      : card.mediaId}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {(selectedCard.type !== '' || isMobileWallet) && (
        <>
          {selectedCard.type === mediaSubTypes.presto ||
          selectedCard.type === mediaSubTypes.anonymous ||
          isMobileWallet ? (
            <div className="selected-card">
              <div
                tabIndex="0"
                role="figure"
                aria-roledescription={
                  cardsA11y[isMobileWallet ? selectedCard.mediaType : selectedCard.type]
                }
                className={isMobileWallet ? selectedCard.mediaType : selectedCard.type}
              >
                <div className="selected-card-text">
                  {isMobileWallet ? (
                    <div className={`gw-card-title ${!renderNickName ? 'custom-image-css' : ''}`}>
                      {renderNickName}

                      <span>
                        {selectedCard.hasActiveImageGoog || selectedCard.hasActiveImageAppl ? (
                          <MediaImage {...activeImageProps} />
                        ) : (
                          <MediaImage {...inActiveImageProps} />
                        )}
                      </span>
                    </div>
                  ) : (
                    <>{renderNickName}</>
                  )}

                  <div
                    aria-label={
                      isMobileWallet
                        ? selectedCard.cardNumber
                        : formatPrestoCardNumberA11y(selectedCard.visibleId)
                    }
                    className="small-text-top"
                  >
                    <span aria-hidden="true">
                      {isMobileWallet
                        ? selectedCard.cardNumber
                        : formatPrestoCardNumber(selectedCard.visibleId)}
                    </span>
                  </div>
                </div>
                <div className="card-logo">
                  <img alt="Presto Card Logo" src={whiteLogo} />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="selected-card"
              role="figure"
              tabIndex="0"
              aria-roledescription={cardsA11y[selectedCard.type]}
            >
              <div className={selectedCard.type}>
                {renderNickName}
                <div className="small-text-bottom" />
              </div>
              <div className="contactless">
                <div className="normal-font">{t('cardSwitcherContactlessCardId')}:</div>
                <div className="bold-font">{selectedCard.mediaId}</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(CardSwitcher);
