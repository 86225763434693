import React, { useState, useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './signinsidebar.scss';
import { MediaImageList } from '../../molecules';
import { MediaImage, Button } from '../../atoms';
import ChevronRight from '../../assets/images/ChevronRight.svg';
import useMediaWidth from '../../hooks/useMediaWidth';
import { redirectToUrl, getQueryParams } from '../../services/utils';
import routes from '../../constants/routes';
import api from '../../services/api';
import { useLocation } from 'react-router-dom';

const SigninSidebar = (props) => {
  const isMobile = useMediaWidth();
  const { datasource, contextItem } = props.fields && props.fields.data ? props.fields.data : {};
  const [selectedType, setSelectedType] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const queryParams = getQueryParams(useLocation().search);
  const cwsRedirectUrlHash = queryParams.hash;
  const cwsRedirectUrl = queryParams.redirectUrl;  

  const onClickHandler = (e, type) => {
    setIsClicked(true);
    setSelectedType(type);
    props.onClickCallback(type);
  };

  const keyDownHandler = (e, type) => {
    if (e.keyCode == 13) {
      setIsClicked(true);
      setSelectedType(type);
      props.onClickCallback(type);
    }
  };
     
  const signinCard = (
    type,
    ariaLabel,
    accountType,
    cardImageProps,
    isAnonymousPresto = false,
    isPrestoAccount = false
  ) => (
    <div
      className={`card-container ${isPrestoAccount && 'presto-account'}`}
      role="button"
      tabIndex="0"
      aria-label={ariaLabel}
      // onKeyPress={onClickHandler}
      onKeyDown={(e) => keyDownHandler(e, type)}
      onClick={(e) => onClickHandler(e, type)}
    >
      <div className="card-left-sidebar">
        <div className="card-header-signin">
          <div>{accountType}</div>
        </div>
        <div className="card-image">
          {isAnonymousPresto ? (
            <MediaImage prestoCard {...{ cardImgAlt: '' }} />
          ) : (
            <MediaImageList {...cardImageProps} />
          )}
        </div>
      </div>
      <div className="card-right">
        <img alt={''} src={ChevronRight} />
      </div>
    </div>
  );
  return (
    <>
      <div className="signin-sidebar-container">
        <div className="top-container">
          <div className="signin-header-wrapper">
            <div className="signin-header">
              <p>{datasource.SigninTitle?.jss.value}</p>
            </div>
          </div>
          {cwsRedirectUrl ?
        <div onClick={() => api.b2cRegisteredSignInRedirect(cwsRedirectUrl, cwsRedirectUrlHash)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                api.b2cRegisteredSignInRedirect(cwsRedirectUrl,cwsRedirectUrlHash)
              }
            }}
            className={`${(!isMobile && props.typeSelected === 'PA') ||
              (isMobile && props.isClicked && props.typeSelected === 'PA')
              ? 'card-wrapper-active'
              : 'card-wrapper'
              }`}
          >
            {signinCard(
              'PA',
              datasource.children[2].AccountTitleA11y?.jss.value,
              datasource.children[2].AccountTitle?.jss.value,
              { all: true, showInteracCard: true },
              false,
              true
            )}
          </div> :        
          <div onClick={() => api.b2cRegisteredSignIn()}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                api.b2cRegisteredSignIn()
              }
            }}
            className={`${(!isMobile && props.typeSelected === 'PA') ||
              (isMobile && props.isClicked && props.typeSelected === 'PA')
              ? 'card-wrapper-active'
              : 'card-wrapper'
              }`}
          >
            {signinCard(
              'PA',
              datasource.children[2].AccountTitleA11y?.jss.value,
              datasource.children[2].AccountTitle?.jss.value,
              { all: true, showInteracCard: true },
              false,
              true
            )}
          </div>
          }
          <div className="or-text">
            <h2>{datasource.Ortext?.jss.value}</h2>
            <div className="signin-withoutacc">{datasource.SigninTitleAcc?.jss.value}</div>
          </div>
          { cwsRedirectUrl ? <div onClick={() => api.b2cPrestoCardSignInRedirect(cwsRedirectUrl, cwsRedirectUrlHash)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                api.b2cPrestoCardSignInRedirect(cwsRedirectUrl, cwsRedirectUrlHash)
              }
            }}
            className={`${(!isMobile && props.typeSelected === 'APC') ||
              (isMobile && props.isClicked && props.typeSelected === 'APC')
              ? 'card-wrapper-active'
              : props.typeSelected === 'AC'
                ? 'card-wrapper card-ac-selected'
                : 'card-wrapper'
              }`}
          >
            {signinCard(
              'APC',
              datasource.children[0].AccountTitleA11y?.jss.value,
              datasource.children[0].AccountTitle?.jss.value,
              {},
              true
            )}
          </div> :
          <div onClick={() => api.b2cPrestoCardSignIn()}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                api.b2cPrestoCardSignIn()
              }
            }}
            className={`${(!isMobile && props.typeSelected === 'APC') ||
              (isMobile && props.isClicked && props.typeSelected === 'APC')
              ? 'card-wrapper-active'
              : props.typeSelected === 'AC'
                ? 'card-wrapper card-ac-selected'
                : 'card-wrapper'
              }`}
          >
            {signinCard(
              'APC',
              datasource.children[0].AccountTitleA11y?.jss.value,
              datasource.children[0].AccountTitle?.jss.value,
              {},
              true
            )}
          </div>
          }
          <div
            className={`${(!isMobile && props.typeSelected === 'AC') ||
              (isMobile && props.isClicked && props.typeSelected === 'AC')
              ? 'card-wrapper-active card-ac-wrapper'
              : 'card-wrapper card-ac-wrapper'
              }`}
          >
            {signinCard(
              'AC',
              datasource.children[1].AccountTitleA11y?.jss.value,
              datasource.children[1].AccountTitle?.jss.value,
              {
                showInteracCard: true,
              }
            )}
          </div>
        </div>
        <div className="bottom-container">
          <div className="bottom-button">
            <RichText field={datasource.CreateAccountText?.jss} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninSidebar;
