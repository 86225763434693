import React from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, PrintButton } from '../../atoms';
import { Success, Header } from '../../molecules';

export default function UpdateEmail1({ state, toFirstStep, fields, onVerifyEmailAddress }) {
  return (
    <>
      <Header centered headerTitleA11y={fields.updateHeadingA11y?.value}>
        <Text field={fields.updateHeading} />
      </Header>
      <Success
        title={fields.verificationEmailSend}
        Content={() => (
          <div className="emailVerification-successBlockContent regular-font">
            <Text field={fields.emailSentTo} />
            <div className="emailVerification-newEmail bold-font">
              {state.newEmail || state.verifiedEmail}
            </div>
            <RichText field={fields.emailSentToNote} />
          </div>
        )}
      />
      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={() => onVerifyEmailAddress()}
            buttonTextA11y={fields.resendVerificationButtonTextA11y?.value}
          >
            <Text field={fields.resendVerificationButtonText} />
          </Button>
          <Button
            firstOrder
            white
            buttonTextA11y={fields.backButtonText.value}
            onClick={toFirstStep}
          >
            <Text field={fields.backButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
}
