import React from 'react';
import './GoogleMap.scss';

const GoogleMap = (props) => {
  const googleUrl = props?.fields?.GoogleUrl?.value + props?.fields?.GoogleMapId?.value;

  return (
    <div className="GoogleMap">
      <iframe id="google" width="620" height="320" src={googleUrl} title="Google Map" />
    </div>
  );
};

export default GoogleMap;
