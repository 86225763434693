import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './heroBannerWithButton.scss';
import { setSignInOverlay } from '../../redux/contactless/actions';
import { Text, Image, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
const HeroBannerWithButton = (props) => {
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector((state) => state.contactless.isContactlessUserSignedIn);
  const redirectUrl = useSelector((state) => state.contactless.redirectUrl);
  const goTo = (url) => {
    if (url) {
      window.open(url, '_self');
    } else {
      if (!isUserSignedIn) {
        dispatch(setSignInOverlay(true));
      } else {
        window.open(redirectUrl, '_self');
      }
    }
  };
  return (
    <>
      {!props.fields.IsHomePageBanner.value && (
        <div className={`home-banner ${props.fields.ID?.value}`}>
          <div id={props.fields.ID?.value} className="banner-inner">
            <div className="herobutton-container-fluid">
              <div className="herobutton-slogan">
                <h2>
                  <Text field={props.fields.Title} />
                </h2>
                <div className="row herobuttonimage03">
                  <Image alt="" media={props.fields.BackgroundImage} loading="lazy"/>
                </div>
                <p className="herobutton-prestoLink">
                  <RichText field={props.fields.Description} />
                </p>
                <button
                  className="herobutton-btn"
                  onClick={() => goTo(props.fields.SecondaryLink.value.href)}
                >
                  <span>{props.fields.ButtonText.value}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.fields.IsHomePageBanner.value && (
        <div className={`home-banner-with-button  ${props.fields.ID?.value}`}>
          <div id={props.fields.ID?.value} className="banner-inner">
            <div className="herobutton-container-fluid">
              <div className="herobutton-slogan">
                {props.fields?.Title?.value && (
                  <h2>
                    <RichText field={props.fields.Title} />
                  </h2>
                )}

                <p className="herobutton-prestoLink">
                  <RichText field={props.fields.Description} />
                </p>
                <button
                  className="herobutton-btn"
                  onClick={() => goTo(props.fields.SecondaryLink.value.href)}
                >
                  <span>{props.fields.ButtonText.value}</span>
                </button>
              </div>
              <div className="row herobuttonimage03">
                <Image alt="" media={props.fields.BackgroundImage} loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(HeroBannerWithButton);
