import React from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { PrestoLink } from '../../atoms';
import './Sitemap.scss';

const Sitemap = (props) => {
  const { datasource, contextItem } =
    props?.fields && props?.fields?.data ? props?.fields?.data : {};
  let indexvar;

  return (
    <div className="sitemap-wrapper">
      {datasource?.children?.map((child) => {
        indexvar = datasource?.children?.indexOf(child);
        return (
          <div>
            <h2>
              <Text field={child.Title?.jss} />
            </h2>
            <div data-e2e-id="graphql-integrated">
              <ul
                className={`sitemap-links-list-${indexvar} ${indexvar == 4 ? `extra-bottom-margin` : null
                  }`}
              >
                {child.children.map((subchild) => (
                  <li className={subchild.PageLink?.jss.value.href === contextItem.url}>
                    <PrestoLink field={subchild.PageLink?.jss} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(Sitemap);
