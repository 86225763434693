import axios from 'axios';

import apiconfig from '../temp/config';
import {
  getCookie,
  getRefreshToken,
  redirectToUrl,
  isJsonString,
  localstorageService,
  resetAccessTokenValidity,
  getLocalStorageItem,
} from './utils';
import routes from '../constants/routes';
import localStorageKeys from '../constants/localStorageKeys';

axios.defaults.withCredentials = true;

class API {
  constructor() {
    this.config = {
      baseURL: `${apiconfig.sitecoreApiHost}/apidata`,
      headers: {
        'content-type': `application/json`,
        'unique-session-id': getLocalStorageItem(localStorageKeys.uniqueSessionId),
      },
    };

    this.stalledCalls = [];
    this.isRefreshingToken = false;

    // eslint-disable-next-line new-cap
    this.provider = new axios.create(this.config);

    // eslint-disable-next-line func-names
    this.provider.interceptors.request.use((config) => {
      return this.getConfiguration(config).then((updatedConfig) => {
        return updatedConfig;
      });
    });

    this.provider.interceptors.response.use(
      function (response) {
        const error = response.data?.Error;
        const errorCodesForRedirect = ['400', '401', '403', '500'];
        const statusCodeForTooManyRequests = '429';
        const parsedMessageObject = error && isJsonString(error) && JSON.parse(error);

        if (parsedMessageObject?.Code === statusCodeForTooManyRequests) {
          redirectToUrl(routes.tooManyRequests);
        }

        if (
          parsedMessageObject?.Code &&
          errorCodesForRedirect.indexOf(parsedMessageObject.Code) > -1
        ) {
          // redirectToUrl(routes.technicalDifficulties);
        }

        return response;
      },
      function (error) {
        const statusCodeForTooManyRequests = 429;
        console.error(error);

        if (error.response && error.response.status === statusCodeForTooManyRequests) {
          redirectToUrl(routes.tooManyRequests);
        }

        return Promise.reject(error);
      }
    );
  }

  getAccessToken = () => {
    const getAccessTokenPromise = new Promise((resolve, reject) => {
      const accessToken = localstorageService().getItem(localStorageKeys.accessToken);
      const tokenTimestamp = localstorageService().getItem(localStorageKeys.accessTokenValidity);
      const refreshToken = localstorageService().getItem(localStorageKeys.refreshToken);

      if (!accessToken || !refreshToken) {
        resolve('');
        return;
      }
      let reduxData = localstorageService().getItem('redux');
      reduxData = JSON.parse(reduxData);
      if (reduxData?.contactless?.isContactlessUserSignedIn) {
        resolve(localstorageService().getItem(localStorageKeys.accessToken));
      } else {
        if (tokenTimestamp && tokenTimestamp > Date.now()) {
          resolve(localstorageService().getItem(localStorageKeys.accessToken));
        } else {
          this.stalledCalls.push({ resolve, reject });
          if (!this.isRefreshingToken) {
            this.refreshToken()
              .then(() => {
                this.stalledCalls.forEach((call) =>
                  call.resolve(localstorageService().getItem(localStorageKeys.accessToken))
                );
              })
              .catch(() => {
                this.stalledCalls.forEach((call) => call.reject());
              })
              .then(() => {
                this.stalledCalls = [];
                this.isRefreshingToken = false;
              });
            this.isRefreshingToken = true;
          }
        }
      }
    });

    return getAccessTokenPromise;
  };

  getConfiguration = (config) => {
    let reduxData = localstorageService().getItem('redux');
    reduxData = JSON.parse(reduxData);
    return new Promise((resolve) => {
      if (config.url === '/SignIn/GuestLogin') {
        resolve(config);
      } else if (
        reduxData?.user?.anonymous &&
        (config.url === '/Customer/CustomerRequest' ||
          config.url === 'Customer/UpdateFareCardProfileAnonymous')
      ) {
        config.headers.token = reduxData?.user?.anonymousAccessToken;
        resolve(config);
      } else if (
        config.url === '/Security/GetSecurityQuestions' ||
        config.url === '/Security/GetSecurityQuestionsForExistingUser' ||
        config.url === '/Security/ValidateSecurityQA' ||
        config.url === '/Security/ValidateSecurityQAForgotPassword' ||
        config.url === '/Security/ResetPassword' ||
        //  config.url === '/Product/GetCountryAreas' ||
        // config.url === '/SitecoreItems/GetAllSecurityQuestions' ||
        // config.url === '/Customer/CustomerRequest' ||
        config.url === '/Customer/GetIsUserNameEmailExists' ||
        config.url === '/Security/VerifyEmail'
      ) {
        // eslint-disable-next-line no-param-reassign
        config.headers.token = localstorageService().getItem(localStorageKeys.guestToken);
        resolve(config);
      } else if (config.url === '/SignIn/RefreshToken') {
        // eslint-disable-next-line no-param-reassign
        config.headers.token = localstorageService().getItem(localStorageKeys.accessToken);
        resolve(config);
      } else {
        this.getAccessToken().then((accessToken) => {
          // eslint-disable-next-line no-param-reassign
          config.headers.token = accessToken;
          resolve(config);
        });
      }
    });
  };

  // SignIn
  getUserInfo() {
    return this.provider.post('/SignIn/UserInfo');
  }
  getUserinfoContactless() {
    return this.provider.post('/SignIn/UserinfoContactless');
  }

  UpdateFareMediaSession(data) {
    return this.provider.post('/UserInfo/UpdateFareMediaSession', data);
  }

  login(data) {
    const deviceIdCookie = getCookie('SC_ANALYTICS_GLOBAL_COOKIE');
    return this.provider.post(`/SignIn/Login`, data, {
      headers: {
        'content-type': `application/json`,
        deviceId: deviceIdCookie && deviceIdCookie.split('|')[0],
      },
    });
  }

  guestLogin() {
    const deviceIdCookie = getCookie('SC_ANALYTICS_GLOBAL_COOKIE');

    return this.provider
      .post(`/SignIn/GuestLogin`, null, {
        headers: {
          'content-type': `application/json`,
          deviceId: deviceIdCookie && deviceIdCookie.split('|')[0],
        },
      })
      .then(({ data: { Data, Success } }) => {
        if (Success) {
          localstorageService().setItem(localStorageKeys.guestToken, Data.Access_token);
          localstorageService().setItem(localStorageKeys.accessToken, Data.Access_token);
        }
      });
  }

  async getTransitAgencyLogosforGuestFlow() {
    await this.newguestLogin();
    return this.provider.get(`/SitecoreItems/GetTransitAgencyLogos`);
  }

  async getTransitAgencyServiceProvidersforGuestFlow(data) {
    await this.newguestLogin();
    return this.provider.post('/Product/GetServiceProviders', data);
  }

  newguestLogin() {
    const deviceIdCookie = getCookie('SC_ANALYTICS_GLOBAL_COOKIE');

    return this.provider
      .post(`/SignIn/GuestLogin`, null, {
        headers: {
          'content-type': `application/json`,
          deviceId: deviceIdCookie && deviceIdCookie.split('|')[0],
        },
      })
      .then(({ data: { Data, Success } }) => {
        if (Success) {
          localstorageService().setItem(localStorageKeys.guestToken, Data.Access_token);
          localstorageService().setItem(localStorageKeys.accessToken, Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, Data.Refresh_token);
          localstorageService().setItem(localStorageKeys.accessTokenLifetime, Data.Expires_in);
          resetAccessTokenValidity();
        }
      });
  }

  guestLoginforCreateAccount() {
    const deviceIdCookie = getCookie('SC_ANALYTICS_GLOBAL_COOKIE');

    return this.provider.post(`/SignIn/GuestLogin`, null, {
      headers: {
        'content-type': `application/json`,
        deviceId: deviceIdCookie && deviceIdCookie.split('|')[0],
      },
    });
  }

  refreshToken() {
    const refreshToken = getRefreshToken();
    const data = { Token: refreshToken };
    return this.provider.post('/SignIn/RefreshToken', data).then(({ data: { Data, Success } }) => {
      if (Success) {
        localstorageService().setItem(localStorageKeys.accessToken, Data.Access_token);
        localstorageService().setItem(localStorageKeys.refreshToken, Data.Access_token);
        resetAccessTokenValidity();
      }
    });
  }

  logOut(data) {
    return this.provider.post('/SignIn/LogOut', data);
  }

  anonymousPrestoLogin(data) {
    return this.provider.post('/SignIn/AnonymousPrestoLogin', data);
  }

  updateConsentInput(data) {
    return this.provider.post('/UserInfo/ConfirmDismissCasl', data);
  }

  // B2c
  b2cRegisteredSignIn() {
    redirectToUrl(routes.b2cRegisteredSignIn);
  }

  b2cMfaMaxRetryExceeds() {
    redirectToUrl(routes.b2cMfaMaxRetryExceeds);
  }

  b2cRegisteredSignInRedirect(cwsRedirectUrl = '', cwsRedirectUrlHash = '') {
    if (cwsRedirectUrl != '' && cwsRedirectUrl != null && cwsRedirectUrl != undefined) {
      if (
        cwsRedirectUrlHash != null &&
        cwsRedirectUrlHash != '' &&
        cwsRedirectUrlHash != undefined
      ) {
        redirectToUrl(
          routes.b2cRegisteredSignIn +
          '?redirectUrl=' +
          cwsRedirectUrl +
          '&hash=' +
          cwsRedirectUrlHash
        );
      } else {
        redirectToUrl(routes.b2cRegisteredSignIn + '?redirectUrl=' + cwsRedirectUrl);
      }
    } else {
      redirectToUrl(routes.b2cRegisteredSignIn);
    }
  }

  b2cGuestSignIn() {
    redirectToUrl(routes.b2cGuestSignIn);
  }

  b2cRegisterAccount() {
    redirectToUrl(routes.b2cRegisterAccount);
  }

  b2cAccountExists() {
    redirectToUrl(routes.b2cAccountExists);
  }

  b2cChangeEmail() {
    redirectToUrl(routes.b2cChangeEmail);
  }

  b2cChangePassword() {
    redirectToUrl(routes.b2cChangePassword);
  }

  b2cPrestoCardSignIn() {
    redirectToUrl(routes.b2cPrestoCardSignIn);
  }

  b2cPrestoCardSignInRedirect(cwsRedirectUrl = '', cwsRedirectUrlHash = '') {
    if (cwsRedirectUrl != '' && cwsRedirectUrl != null && cwsRedirectUrl != undefined) {
      if (
        cwsRedirectUrlHash != null &&
        cwsRedirectUrlHash != '' &&
        cwsRedirectUrlHash != undefined
      ) {
        redirectToUrl(
          routes.b2cPrestoCardSignIn +
          '?redirectUrl=' +
          cwsRedirectUrl +
          '&hash=' +
          cwsRedirectUrlHash
        );
      } else {
        redirectToUrl(routes.b2cPrestoCardSignIn + '?redirectUrl=' + cwsRedirectUrl);
      }
    } else {
      redirectToUrl(routes.b2cPrestoCardSignIn);
    }
  }

  b2cSignOut() {
    if (window.CVVA !== undefined && window.CVVA !== null) {
      window.CVVA.flushVaSession();
    }
    redirectToUrl(routes.b2cSignOut);
  }

  deleteaccountSignOut(cwsRedirectUrl = '') {
    if (cwsRedirectUrl != '' && cwsRedirectUrl != null && cwsRedirectUrl != undefined) {
      if (window.CVVA !== undefined && window.CVVA !== null) {
        window.CVVA.flushVaSession();
      }

      redirectToUrl(routes.b2cSignOut + '?returnUrl=' + cwsRedirectUrl + '&deleteState=true');
    }
  }

  b2cEnrollMFA() {
    redirectToUrl(routes.b2cEnrollMFA);
  }

  b2cUpdateMFA() {
    redirectToUrl(routes.b2cUpdateMFA);
  }

  b2cDisableMFA() {
    redirectToUrl(routes.b2cDisableMFA);
  }

  b2cUpdateMFANotification() {
    redirectToUrl(routes.b2cUpdateMFANotification);
  }

  b2cPasswordMFANotification() {
    redirectToUrl(routes.b2cPasswordMFANotification);
  }

  dashboard(options = { queryParams: '' }) {
    let hasQueryParams = options?.queryParams && options.queryParams.length > 0;
    let URL = routes.dashboard;
    if (hasQueryParams) {
      URL += options.queryParams;
    }
    redirectToUrl(URL);
  }

  checkout() {
    redirectToUrl(routes.checkout);
  }

  getClaims() {
    return this.provider.post('/SignIn/B2CClaims');
  }

  getSTSCloudClaims() {
    return this.provider.post('/SignIn/STSCloudB2CClaims');
  }
  // Security

  async getSecurityQuestions(data) {
    const deviceIdCookie = getCookie('SC_ANALYTICS_GLOBAL_COOKIE');
    await this.guestLogin();
    return this.provider.post(`/Security/GetSecurityQuestions`, data, {
      headers: {
        'content-type': `application/json`,
        deviceId: deviceIdCookie && deviceIdCookie.split('|')[0],
      },
    });
  }

  async getSecurityQuestionsForExistingUser(data) {
    await this.guestLogin();
    return this.provider.post(`/Security/GetSecurityQuestionsForExistingUser`, data);
  }

  validateSecurityQA(data) {
    return this.provider.post(`/Security/ValidateSecurityQA`, data);
  }

  validateSecurityQAForgotPassword(data) {
    return this.provider.post(`/Security/ValidateSecurityQAForgotPassword`, data);
  }

  resetPassword(data) {
    return this.provider.post(`/Security/ResetPassword`, data);
  }

  resendVerificationEmail(data) {
    return this.provider.post(`/Security/ResendVerificationEmail`, data);
  }

  async verifyEmail(data) {
    await this.guestLogin();
    return this.provider.post(`/Security/VerifyEmail`, data);
  }

  setWebChatCookie(data) {
    return this.provider.post(`/Security/SetWebChatCookie`, data);
  }

  // Error message

  getErrorTranslation(data) {
    return this.provider.post('/SitecoreItems/GetErrorTranslation', data);
  }

  // User Info
  checkUserNameAlreadyExists(data) {
    return this.provider.post(`/Customer/GetIsUserNameEmailExists`, data);
  }

  getPersonalData() {
    return this.provider.post(`/UserInfo/GetPersonalData`);
  }

  updatePersonalData(data) {
    return this.provider.post(`/UserInfo/UpdatepersonalInformation`, data);
  }

  getSitecoreSessionId() {
    return this.provider.post(`/UserInfo/GetSitecoreSessionId`);
  }

  getSecurityQuestionsWithAnswers(data) {
    return this.provider.post('/UserInfo/GetSecurityQuestionsWithAnswers', data);
  }

  updatePassword(data) {
    return this.provider.post('/UserInfo/UpdatePassword', data);
  }

  updateLoginEmail(data) {
    return this.provider.post('/UserInfo/UpdateLoginEmail', data);
  }

  updateSecurityQuestions(data) {
    return this.provider.post('/UserInfo/UpdateSecurityQuestions', data);
  }

  setSecurityQuestionsWithAnswers(data) {
    return this.provider.post('/UserInfo/SetSecurityQuestionsWithAnswers', data);
  }

  // Sales
  createCachedOrderForReplacement(data) {
    return this.provider.post('/Sales/CreateCachedOrderForReplacement', data);
  }

  createHotlistMediaForReplaceCard(data) {
    return this.provider.post('/Sales/CreateHotlistMediaForReplaceCard', data);
  }

  createHotlistAndTransferCard(data) {
    return this.provider.post('/Sales/CreateHotListAndTransferCard', data);
  }

  getShoppingCart(data) {
    return this.provider.post('/Sales/GetShoppingCart', data);
  }

  getShoppingCartWithLineItemValidation(data) {
    return this.provider.post('/Sales/GetShoppingCartWithLineItemValidation', data);
  }

  UpdateMediaConfigurationDetails(data) {
    return this.provider.post('/Sales/UpdateMediaConfigurationDetails', data);
  }

  SetOrderDelivery(data) {
    return this.provider.post('/Sales/SetOrderDelivery', data);
  }

  addFundToCart(data) {
    return this.provider.post('/Sales/AddFundToCart', data);
  }

  redeemVoucher(data) {
    return this.provider.post('/Sales/RedeemVoucher', data);
  }

  addProductToCart(data) {
    return this.provider.post('/Sales/AddProductToCart', data);
  }

  removeProductFromCart(data) {
    return this.provider.post('/Sales/RemoveProductFromCart', data);
  }

  validateVoucher(data) {
    return this.provider.post('/Sales/ValidateVoucher', data);
  }

  buyAprestoCardCommenceCheckOut(data) {
    return this.provider.post('/Sales/BuyAPrestoCardCommenceCheckout', data);
  }

  transferMediaAllowedToNewCard(data) {
    return this.provider.post('/Sales/TransferMediaAllowedToNewCard', data);
  }

  getAllRegisteredCardsEligibleForTransfer(data) {
    return this.provider.post('/Sales/GetAllRegisteredCardsEligibleForTransfer', data);
  }

  // Product

  getServiceProviders(data) {
    return this.provider.post('/Product/GetServiceProviders', data);
  }

  async getCountryAreas() {
    // await this.guestLogin();
    return this.provider.get('/Product/GetCountryAreas');
  }

  getEligibleFunds(data) {
    return this.provider.post('/Product/GetEligibleFunds', data);
  }

  //CreateAccount

  async getCountryAreasforCreateAccount() {
    return this.provider.get('/Product/GetCountryAreas');
  }

  async saveCreateAccountData(data) {
    return this.provider.post('/Customer/CustomerRequest', data);
  }

  async saveCreateAccountDataForAnonymous(data) {
    return this.provider.post('/Customer/CustomerRequest', data);
  }

  async associateCustomerAddCard(data) {
    return this.provider.post('/Customer/AssociateCustomerAddCard', data);
  }

  // SitecoreItems
  async getAllBusinessRules() {
    return this.provider.get(`/SitecoreItems/GetAllBusinessRules`);
  }

  async getAllSecurityQuestions() {
    //  await this.guestLogin();
    return this.provider.get(`/SitecoreItems/GetAllSecurityQuestions`);
  }

  async getTransitAgencyLogos() {
    return this.provider.get(`/SitecoreItems/GetTransitAgencyLogos`);
  }

  async getFareCardStatusMessage(data) {
    return this.provider.post(`/SitecoreItems/GetFareCardStatusMessage`, data);
  }

  async getDiscountTypes() {
    return this.provider.get(`/SitecoreItems/GetDiscountTypes`);
  }

  async getTicket(data) {
    return this.provider.post(`/Sales/GetTicket`, data);
  }

  async getCSOLocatorLookUpData() {
    return this.provider.get(`/SitecoreItems/GetCSOLocatorLookUpData`);
  }

  // Settings
  getSetting(data) {
    return this.provider.post(`/Settings/GetSetting`, data);
  }

  getAvailableSettings() {
    return this.provider.get(`/Settings/GetAvailableSettings`);
  }

  async getAutorenewCalendar() {
    return this.provider.get(`/SitecoreItems/GetAutorenewCalendar`);
  }

  // PrestoFareMedia

  verifyCard(data) {
    return this.provider.post(`/PrestoFareMedia/VerifyCard`, data);
  }

  validatePin(data) {
    return this.provider.post('/PrestoFareMedia/ValidatePin', data);
  }

  addPrestoCard(data) {
    return this.provider.post('/PrestoFareMedia/AddAnonymousCard', data);
  }

  addPaperFormPrestoCard(data) {
    return this.provider.post('/PrestoFareMedia/AddPaperFormRegisteredCard', data);
  }

  getCurrentBalance(data) {
    return this.provider.post('/PrestoFareMedia/GetEpurseBalance', data);
  }

  prestoCardUpdateNickname(data) {
    return this.provider.post('/PrestoFareMedia/UpdateNickname', data);
  }

  prestoCardActivate(data) {
    return this.provider.post('/PrestoFareMedia/ActivatePrestoCard', data);
  }

  // ContactlessFareMedia

  contactlessCreateAccount(data) {
    return this.provider.post('/ContactlessFareMedia/CreateAccount', data);
  }

  contactlessUpdateNickname(data) {
    return this.provider.post('/ContactlessFareMedia/UpdateNickname', data);
  }

  contactlessRemoveCard(data) {
    return this.provider.post('/ContactlessFareMedia/UnlinkCard', data);
  }

  getDefaultTrip(data) {
    return this.provider.post('/ContactlessFareMedia/GetDefaultTrip', data);
  }

  setOrUpdateDefaultTrip(data) {
    return this.provider.post('/ContactlessFareMedia/SetOrUpdateDefaultTrip', data);
  }

  getStationList(data) {
    return this.provider.post('/Stationlist/getstationlist', data);
  }

  // ContactlessFareMediaHistory

  getContactlessTransactionHistory(data) {
    return this.provider.post('/ContactlessFareMediaHistory/GetTransactionHistory', data);
  }

  getExportContactlessTransactionHistory(data) {
    return this.provider.post('/ContactlessFareMediaHistory/GetExport', data);
  }

  getExportPrestoTransactionHistory(data) {
    return this.provider.post('/PrestoFareMediaHistory/ExportTransactionHistoryCsv', data);
  }

  getContactlessUnpaidFares(data) {
    return this.provider.post('/ContactlessFareMediaHistory/GetUnpaidFares', data);
  }

  getPaymentsToVerify(data) {
    return this.provider.post('/ContactlessFareMediaHistory/GetPaymentsToVerify', data);
  }

  contactlessRecoveryDept(data) {
    return this.provider.post('/ContactlessFareMedia/RecoverDebt', data);
  }

  verifyContactlessCard(data) {
    return this.provider.post('/ContactlessFareMedia/VerifyCard', data);
  }

  processContactlessUnpaidFare(data) {
    return this.provider.post('ContactlessFareMedia/ProcessContactlessUnpaidFare', data);
  }

  processContactlessVerifyCard(data) {
    return this.provider.post('ContactlessFareMedia/ProcessContactlessVerifyCard', data);
  }

  // PrestoFareMediaHistory

  getTransactionHistory(data) {
    return this.provider.post('/PrestoFareMediaHistory/GetTravel', data);
  }

  getProductsLoadedInMedia(data) {
    return this.provider.post('/PrestoFareMediaHistory/GetProductsLoadedInMedia', data);
  }

  getPendingProductsLoadedInMedia(data) {
    return this.provider.post('/PrestoFareMediaHistory/GetPendingProductsLoadedInMedia', data);
  }

  getAllProductsLoadedInMedia(data) {
    return this.provider.post('/PrestoFareMediaHistory/GetAllProductsLoadedInMedia', data);
  }

  getAllWalletCardDetails(data) {
    return this.provider.post('/WalletCardMedia/GetAllWalletCardDetails', data);
  }
  getStoredMediaTypeInSession(data) {
    return this.provider.post('/WalletCardMedia/StoreMediaTypeInSession', data);
  }
  getLoyaltySummary(data) {
    return this.provider.post('/PrestoFareMediaHistory/GetLoyaltySummary', data);
  }

  // Autoload

  isAutoloadAllowedWithBalance(data) {
    return this.provider.post('/Autoload/IsAutoloadAllowedWithBalance', data);
  }

  isAutoloadAllowed(data) {
    return this.provider.post('/Autoload/IsAutoloadAllowed', data);
  }

  getAutoloadSubscriptionForMedia(data) {
    return this.provider.post('/Autoload/GetAutoloadSubscriptionForMedia', data);
  }

  setupAutoloadDetails(data) {
    return this.provider.post('/Autoload/SetupAutoloadDetails', data);
  }

  cancelAutoloadSubscription(data) {
    return this.provider.post('/Autoload/CancelAutoloadSubscription', data);
  }

  createAutoloadSubscriptionWithSavedPaymentMethod(data) {
    return this.provider.post('/Autoload/CreateAutoloadSubscriptionWithSavedPaymentMethod', data);
  }

  modifyAutoloadSubscriptionWithSavedPaymentMethod(data) {
    return this.provider.post('/Autoload/ModifyAutoloadSubscriptionWithSavedPaymentMethod', data);
  }

  createAutoloadSubscription(data) {
    return this.provider.post('/Autoload/CreateAutoloadSubscription', data);
  }

  modifyAutoloadSubscription(data) {
    return this.provider.post('Autoload/ModifyAutoloadSubscription', data);
  }

  // Transit passes and autorenew
  getAutorenewPassDiscounts(data) {
    return this.provider.post('/Autorenew/GetMDPTTCPassDiscounts', data);
  }

  getAutorenewSubscriptionsForMedia(data) {
    return this.provider.post('/Autorenew/GetAutorenewSubscriptionsForMedia', data);
  }

  cancelAutorenewSubscription(data) {
    return this.provider.post('/Autorenew/CancelAutorenewSubscription', data);
  }

  createSavedPaymentAutorenewSubscription(data) {
    return this.provider.post('/Autorenew/CreateSavedPaymentAutorenewSubscription', data);
  }

  createAutorenewSubscription(data) {
    return this.provider.post('/Autorenew/CreateAutorenewSubscription', data);
  }

  manageAutorenewSubscription(data) {
    return this.provider.post('/Autorenew/UpdateSavedPaymentAutorenewSubscription', data);
  }

  updateAutorenewSubscriptionByMoneris(data) {
    return this.provider.post('/Autorenew/UpdateAutorenewSubscriptionByMoneris', data);
  }

  // Order

  createCachedOrder(data) {
    return this.provider.post('/Sales/CreateCachedOrder', data);
  }

  createOrder(data) {
    return this.provider.post('/Sales/CreateOrder', data);
  }

  CompleteOrderWithTicket(data) {
    return this.provider.post('Sales/CompleteOrderWithTicket', data);
  }

  Cancelcachedorderfrompaymentfailure(data) {
    return this.provider.post('Sales/Cancelcachedorderfrompaymentfailure', data);
  }

  createHotListMedia(data) {
    return this.provider.post('/Sales/CreateHotListMedia', data);
  }

  CompleteReplacementOrderwithTicket(data) {
    return this.provider.post('Sales/CompleteReplacementOrderwithTicket', data);
  }

  // Customer

  getRegisterPaymentMeansForCustomer(data) {
    return this.provider.post('/Customer/GetRegisterPaymentMeansForCustomer', data);
  }

  createTermsAndConditionsNotification(data) {
    return this.provider.post('/Customer/CreateTermsAndConditionsNotification', data);
  }

  getTerminatePaymentMeansForCustomer(data) {
    return this.provider.post('/Customer/TerminatePaymentMeansForCustomer', data);
  }

  getSubscriptionsCount(data) {
    return this.provider.post('/Customer/GetSubscriptionsCount', data);
  }

  createRegisteredPaymentMean(data) {
    return this.provider.post('/customer/CreateRegisteredPaymentMean', data);
  }

  terminateRegisterPaymentMean(data) {
    return this.provider.post('/customer/TerminateRegisterPaymentMean', data);
  }

  getTransitUsageReportView(data) {
    return this.provider.post('/Customer/GetTransitUsageReportList', data);
  }

  GetTransitUsageReportPdf(data) {
    return this.provider.post('/Customer/GetTransitUsageReportPdf', data, { responseType: 'blob' });
  }

  async getContactUsFormFeedback(data) {
    let reduxData = localstorageService().getItem('redux');
    reduxData = JSON.parse(reduxData);
    if (!reduxData?.user?.authenticated) {
      await this.newguestLogin();
    }
    return this.provider.post('/Customer/CustomerFeedBack', data);
  }

  customerAccountDeletion(data) {
    return this.provider.post('/Customer/CustomerAccountDeletion', data);
  }

  // Search

  getSearchResults(data) {
    return this.provider.post('/Search/Search', data);
  }

  //change PIN
  saveChangePinDetails(data) {
    return this.provider.post('/PrestoFareMedia/ChangePin', data);
  }

  //B2CAccountCreationController

  B2CAccountCreation(data) {
    return this.provider.post('/Customer/B2CAccountCreationAuthentication', data);
  }

  getAzureData(url) {
    return this.provider.get(url);
  }
}

const api = new API();

export default api;
