import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import handleServerSuccessError from '../../services/handleServerSuccessError';
import { redirectToUrl } from '../../services/utils';
import { Button, ButtonBlock } from '../../atoms';
import { Note, Title, Error } from '../../molecules';
import TransitPassItem from '../TransitPass/TransitPassItem';
import './cancelAutorenew.scss';
import api from '../../services/api';
import '../TransitPass/AddTransitPass/AddTransitPass.scss';

const CancelAutorenew1 = ({
  fields,
  subscriptionInstance,
  backToTransitPassesButtonLink,
  toNextStep,
  TTCrendering,
}) => {
  const [error, setIsError] = useState('');
  const visibleId = useSelector((s) => s.user.selectedCard.visibleId);
  const customerId = useSelector((s) => s.user.customerId);
  const productId = subscriptionInstance.Product.ProductId;
  const isCancel12MonthTTC = fields?.isTTC?.value;
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  const cancelAutorenew = () => {
    const data = {
      visibleId,
      customerId,
      productId,
      ...(isCancel12MonthTTC && { IsTTCSubscription: true }),
    };

    api.cancelAutorenewSubscription(data).then((response) => {
      if (response.data.IsCancelled) {
        toNextStep();
      } else {
        handleServerSuccessError(response.data.Error, setIsError);
      }
    });
  };
  return (
    <div className="transitPass-wrapper">
      <div className="cancelAutorenew">
        <Title centered>
          <Text field={fields.title} />
        </Title>

        {error && <Error title={error} />}
        <TransitPassItem
          subscriptionInstance={subscriptionInstance}
          isCancel12MonthTTCSteps={isCancel12MonthTTC}
          TTCFields={fields}
          fields={TTCrendering.fields}
        />

        <Note withIcon cssClass="note-top-margin" noteA11y={fields.noteA11y.value}>
          <RichText field={fields.note} />
        </Note>

        <ButtonBlock>
          <ButtonBlock right>
            <Button
              type="submit"
              buttonTextA11y={fields.confirmButtonTextA11y.value}
              onClick={() => cancelAutorenew()}
            >
              <Text field={fields.confirmButtonText} />
            </Button>
            <Button
              white
              firstOrder
              onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
              buttonTextA11y={fields.backButtonTextA11y.value}
            >
              <Text field={fields.backButtonText} />
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      </div>
    </div>
  );
};

export default withTranslation()(CancelAutorenew1);
