import React from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, PrintButton, IconButton } from '../../atoms';
import { Success } from '../../molecules';
import TransitPassItem from '../TransitPass/TransitPassItem'; // TODO move to shared
import { redirectToUrl } from '../../services/utils';
import printIcon from '../../assets/images/printer.svg';
import './cancelAutorenew.scss';
import '../TransitPass/AddTransitPass/AddTransitPass.scss';

const CancelAutorenew2 = ({
  fields,
  subscriptionInstance,
  backToTransitPassesButtonLink,
  TTCrendering,
}) => (
  <div className="transitPass-wrapper">
    <div className="cancelAutorenew">
      <Success
        title={fields.successMessage}
        titleA11y={fields.successMessageA11y.value}
        {...(fields?.isTTC?.value && { contextText: fields.successMessageDescription })}
        Content={() => (
          <TransitPassItem
            subscriptionInstance={subscriptionInstance}
            success
            showFinalBillingDate
            isCancel12MonthTTCSteps={fields?.isTTC?.value}
            TTCFields={fields}
            fields={TTCrendering.fields}
          />
        )}
      />

      {/* <ButtonBlock>
      <PrintButton />
      <ButtonBlock right>
        <Button
          onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
          buttonTextA11y={fields.toTransitPassesButtonTextA11y.value}
        >
          <Text field={fields.toTransitPassesButtonText} />
        </Button>
      </ButtonBlock>
    </ButtonBlock> */}

      <div className="cancelautorenew2-footer ">
        <div className="cancelautorenew2-transitpass-btn">
          <Button
            firstOrder
            onClick={() => redirectToUrl(backToTransitPassesButtonLink)}
            buttonTextA11y={fields.toTransitPassesButtonTextA11y.value}
          >
            <Text field={fields.toTransitPassesButtonText} />
          </Button>
        </div>
        <div id="cancelautorenew2-printicon">
          <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
            {fields.PrintLabel.value}
          </IconButton>
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation()(CancelAutorenew2);
