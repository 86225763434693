import React, { useEffect, useState } from 'react';
import { Modal } from '../../molecules';
import { Checkbox } from '../../atoms';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import api from '../../services/api';
import './caslPopup.scss';
import { updateCaslPopupStatus, updateCaslPopupOpenedStatus } from '../../redux/user/actions';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const CaslPopUp = ({ fields }) => {
  const dispatch = useDispatch();
  const { showCaslPopUp, customerId, isPopupOpened } = useSelector((reduxState) => reduxState.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentchecked, setContentChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (showCaslPopUp && !isPopupOpened && isLoaded) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 1000);
    }
  }, [showCaslPopUp, isLoaded]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(updateCaslPopupOpenedStatus());
    }
  }, [isModalOpen]);

  // code change for rendering error
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [fields]);
  if (!isLoaded) return null;

  const confirmationStatus = ({ btnType }) => {
    api
      .updateConsentInput({
        IsConfirm: btnType === 'Confirm',
        IsOptIn: btnType === 'Close' ? false : contentchecked,
        CrmReferenceID: customerId,
      })
      .then((res) => {
        setIsModalOpen(false);
        dispatch(updateCaslPopupStatus());
      });
  };

  const submitHandler = () => {
    confirmationStatus({ btnType: 'Confirm' });
  };
  const closeHandler = () => {
    confirmationStatus({ btnType: 'Close' });
  };

  return (
    <>
      {showCaslPopUp && isLoaded ? (
        <div className="caslPopUp">
          <Modal
            title={fields?.caslPopUpTitle?.value}
            text={
              <>
                <Text field={fields?.caslPopUpText} />
                <div
                  className="caslCheckbox"
                  onClick={() => setContentChecked((contentchecked) => !contentchecked)}
                >
                  <Checkbox
                    className="caslCheckbox-label"
                    checked={contentchecked}
                    onChange={(e) => {
                      setContentChecked(e);
                    }}
                    checkboxA11y={fields?.caslCheckboxTextA11y?.value}
                  >
                    <Text field={fields?.caslCheckboxText} />
                  </Checkbox>
                </div>
              </>
            }
            textCancel={fields?.caslCloseCtaText?.value}
            textSubmit={fields?.caslConfirmCtaText?.value}
            buttonTextCloseA11y={fields?.caslCloseCtaTextA11y?.value}
            buttonTextA11y={fields?.caslConfirmCtaTextA11y?.value}
            onSubmit={submitHandler}
            onClose={closeHandler}
            isCustomClose
            disableBackdropClick
            disableEscapeKeyDown
            withoutFocusTrap={false}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      ) : null}
    </>
  );
};
export default CaslPopUp;
