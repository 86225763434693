import React, { useEffect, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Error, Note } from '../../molecules';
import { Formik } from 'formik';
import { Button, ButtonBlock, RadioInput } from '../../atoms';
import { setDeactivateCardType } from '../../redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import deactivateCardType from '../../constants/deactivateCardType';
import infoImage from '../../assets/images/Info.svg';
import './reportLostCard.scss';
import { getAllMediasFromUserState } from '../../services/utils';

const ReportLostCard = ({ fields }) => {
  const [errorBlock, setErrorBlock] = useState(false);
  const dispatch = useDispatch();
  const deactivateCard = useSelector((reduxState) => reduxState.user.deactivateCardType);
  const isTransferBalance = fields?.IsTransferBalance.value;
  const allCards = getAllMediasFromUserState(useSelector((state) => state.user));
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (allCards && allCards.length < 2) {
      setIsDisabled(true);
    }
  }, []);
  useEffect(() => {
    const elementData = document.getElementById('rlc-card');
    if (!deactivateCard?.deactivateCardType && elementData) {
      elementData.style.display = 'block';
    }
  }, [deactivateCard?.deactivateCardType]);

  return (
    <>
      {!deactivateCard ? (
        <div className="report-lost-card">
          <div className="lost-card-note">
            <Note className="card-note">
              <img src={infoImage} />
              <RichText field={fields.DeactivateCardSubTitle} />
            </Note>
            <Note className="card-note">
              <img src={infoImage} />
              <RichText field={fields.DeactivateCardSubNote} />
            </Note>
          </div>
          <div className="deactivate-card-option">
            <div className="deactivate-card-header">
              <Text field={fields.DeactivateCardTitle} />
            </div>
            <div className="deactivate-card-para">
              <div className="deactivate-card-subPara">
                <RichText field={fields.DeactivateCardNote} />
              </div>
            </div>
            {errorBlock && (
              <div className="error-block">
                <Error title={fields.ErrorMessage.value} />
              </div>
            )}
            <div className="card-radio-list">
              <Formik
                initialValues={{
                  deactivateCardType: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.deactivateCardType) {
                    errors.deactivateCardType = true;
                  }
                  if (Object.keys(errors).length === 0) {
                    setErrorBlock(false);
                  } else {
                    setErrorBlock(true);
                  }
                  return errors;
                }}
                onSubmit={(values) => dispatch(setDeactivateCardType(values))}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <fieldset className="fieldset-hide">
                      <legend aria-hidden={true} className="legend-hide">
                        {fields.DeactivateCardTitle.value}
                      </legend>
                      <div
                        className={`${
                          values.deactivateCardType ==
                          deactivateCardType.deactivateAndTransferAnother
                            ? 'active'
                            : ''
                        }`}
                      >
                        <div
                          className={`radio-card-another ${
                            !isTransferBalance && `radio-card-another-lost`
                          } ${
                            values.deactivateCardType ==
                            deactivateCardType.deactivateAndTransferAnother
                              ? 'active'
                              : ''
                          }  ${isDisabled ? 'disabled-option-rlc' : ''}`}
                        >
                          <RadioInput
                            name="deactivateCardType"
                            value={deactivateCardType.deactivateAndTransferAnother}
                            type="radio"
                            a11y={`${fields.DeactivateTransferToOldCardExistingCard.value} ${fields.DeactivateTransferToOldCardExistingCardA11y.value}`}
                            onChange={handleChange}
                            checked={
                              values.deactivateCardType ==
                              deactivateCardType.deactivateAndTransferAnother
                            }
                            disabled={isDisabled}
                          />
                          <div className="new-card-content">
                            <RichText
                              className="content-title"
                              field={fields.DeactivateTransferToOldCardExistingCard}
                            />
                            <RichText
                              className="content-subtitle"
                              field={fields.DeactivateTransferToOldCardExistingCardA11y}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          values.deactivateCardType == deactivateCardType.deactivateAndTransferExist
                            ? 'active'
                            : ''
                        }`}
                      >
                        <div
                          className={`radio-card-existing ${
                            !isTransferBalance && `radio-card-existing-lost`
                          } ${
                            values.deactivateCardType ==
                            deactivateCardType.deactivateAndTransferExist
                              ? 'active'
                              : ''
                          }`}
                        >
                          <RadioInput
                            name="deactivateCardType"
                            value={deactivateCardType.deactivateAndTransferExist}
                            type="radio"
                            a11y={`${fields.DeactivateTransferOldCardCTALabel.value} ${fields.DeactivateTransferOldCardCTAA11y.value}`}
                            onChange={handleChange}
                            checked={
                              values.deactivateCardType ==
                              deactivateCardType.deactivateAndTransferExist
                            }
                          />
                          <div className="existing-card-content">
                            <RichText
                              className="content-title"
                              field={fields.DeactivateTransferOldCardCTALabel}
                            />
                            <RichText
                              className="content-subtitle"
                              field={fields.DeactivateTransferOldCardCTAA11y}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          values.deactivateCardType == deactivateCardType.deactivateAndTransferNew
                            ? 'active'
                            : ''
                        }`}
                      >
                        <div
                          className={`radio-card-new ${
                            !isTransferBalance ? 'radio-card-new-lost' : 'radio-buy-new-card'
                          } ${
                            values.deactivateCardType == deactivateCardType.deactivateAndTransferNew
                              ? 'active'
                              : ''
                          }`}
                        >
                          <RadioInput
                            name="deactivateCardType"
                            value={deactivateCardType.deactivateAndTransferNew}
                            type="radio"
                            a11y={`${fields.DeactivateTransferNewCardCTALabel.value} ${fields.DeactivateTransferNewCardCTAA11y.value}`}
                            onChange={handleChange}
                            checked={
                              values.deactivateCardType ==
                              deactivateCardType.deactivateAndTransferNew
                            }
                          />
                          <div className="new-card-content">
                            <RichText
                              className="content-title"
                              field={fields.DeactivateTransferNewCardCTALabel}
                            />
                            <RichText
                              className="content-subtitle"
                              field={fields.DeactivateTransferNewCardCTAA11y}
                            />
                          </div>
                        </div>
                      </div>
                      {!isTransferBalance ? (
                        <div
                          className={`${
                            values.deactivateCardType == deactivateCardType.deactivate
                              ? 'active'
                              : ''
                          }`}
                        >
                          <div
                            className={`radio-card-deactive ${
                              !isTransferBalance && `radio-card-deactive-lost`
                            } ${
                              values.deactivateCardType == deactivateCardType.deactivate
                                ? 'active'
                                : ''
                            }`}
                          >
                            <RadioInput
                              name="deactivateCardType"
                              value={deactivateCardType.deactivate}
                              type="radio"
                              a11y={`${fields.DeactivateCardCTALabel.value} ${fields.DeactivateCardCTAA11y.value}`}
                              onChange={handleChange}
                              checked={values.deactivateCardType == deactivateCardType.deactivate}
                            />
                            <div className="deactivate-card-content">
                              <RichText
                                className="content-title"
                                field={fields.DeactivateCardCTALabel}
                              />
                              <RichText
                                className="content-subtitle"
                                field={fields.DeactivateCardCTAA11y}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </fieldset>
                    <ButtonBlock className="buttonClass">
                      <Button type="submit" buttonTextA11y="">
                        <Text field={fields.ContinueCTALabel} />
                      </Button>
                    </ButtonBlock>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ReportLostCard;
