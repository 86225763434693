import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Link, Image } from '@sitecore-jss/sitecore-jss-react';
import './contactlessTopNavigation.scss';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setSignInOverlay, setContactlessUserSignedIn } from '../../redux/contactless/actions';
import { PrestoLink } from '../../atoms';
import {
  localstorageService,
  setLanguage,
  sessionstorageService,
  redirectToUrl,
  check15MinTillLogin,
  togglePageScroll,
} from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';

const ContactlessTopNavigation = (props) => {
  const dispatch = useDispatch();
  const [toggleClose, setToggleClose] = useState(true);
  const { datasource } = props.fields && props.fields.data ? props.fields.data : { children: [] };
  const urlParams = useParams();
  const location = useLocation();
  const [navIndex, setNavIndex] = useState('0');
  // const signOutRedirectLink = useSelector((state) => state.contactless.signOutRedirectUrl);
  const contactlessLoginTime = useSelector((state) => state.contactless.contactlessLogInTime);
  const contactlessLogoutTime = useSelector((state) => state.contactless.contactlessLogoutTime);
  const isContactlessAuthenticatedRedux = useSelector(
    (state) => state.contactless.isContactlessUserSignedIn
  );
  const [isUserSignedIn, setIsContactlessAuthenticated] = useState(false);

  const [topNavClass, setTopNavClass] = useState('');
  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);

  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');

    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
    setIsSkipTranslateHidden(
      skipTranslateElement &&
        getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none'
    );

    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');

    const navmenu = document.querySelector('.nav__wrapper.presto-nav-mobile');

    let newTopNavClass = '';
    if (window.location.href.toLowerCase().includes('about/contactless') && navmenu) {
      if (smartBanner && targetElement && !isSkipTranslateHidden) {
        navmenu.style.top = '174px';
      } else if (document.querySelector('html.smartbanner-show')) {
        navmenu.style.top = '136px';
      } else if (targetElement && !isSkipTranslateHidden) {
        navmenu.style.top = '96px';
      } else {
        navmenu.style.top = '56px'; // Default value if neither is present
      }
    }

    if (smartBanner && targetElement && !isSkipTranslateHidden) {
      newTopNavClass = 'topNav-both';
    } else if (smartBanner) {
      newTopNavClass = 'topNav-smartbanner';
    } else if (targetElement && !isSkipTranslateHidden) {
      newTopNavClass = 'topNav-translate';
    } else {
      newTopNavClass = 'topNav';
    }

    setTopNavClass(newTopNavClass);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 100); // Check every 1 second

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    setIsContactlessAuthenticated(isContactlessAuthenticatedRedux);
  }, []);

  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      const { isAfter15Min, contactlessLoginTimee, contactlessLogoutTimee } = check15MinTillLogin(
        contactlessLoginTime,
        contactlessLogoutTime
      );
      if (isAfter15Min && isContactlessAuthenticatedRedux) {
        signOutUser();
      } else {
        // Session still valid.
      }
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [contactlessLoginTime]);

  useEffect(() => {
    togglePageScroll(!toggleClose);
  }, [toggleClose]);

  const changeLanguage = () => {
    let url = { ...urlParams };
    let searchKeyword = location?.search;
    if (searchKeyword) {
      url = { ...url, sitecoreRoute: `${url?.sitecoreRoute}${searchKeyword}` };
    }
    setLanguage(url);
  };
  const getMobileMenu = () => {
    return (
      <section className={`nav__wrapper presto-nav-mobile ${!toggleClose ? `show-nav` : ``}`}>
        <div className="nav__container">
          {isUserSignedIn ? (
            <ul
              className="nav__section"

              onClick={() => {
                signOutUser();
                setToggleClose(true);
              }}
            >
              <li className="nav__section-title nav__section-title--blue">
                <a href="javascript:void(0)" className="signin__title">
                  {datasource.SignOutButton?.value}
                </a>
              </li>
            </ul>
          ) : (
            <ul
              className="nav__section"
              onClick={() => {
                // dispatch(setSignInOverlay(true));
                setToggleClose(true);
              }}
            >
              <li className="nav__section-title nav__section-title--blue">
                <a
                  onClick={() => {
                    redirectToUrl('/B2CRedirect/B2CRegisteredSignIn');
                  }}
                  href="javascript:void(0)"
                  className="signin__title"
                >
                  {datasource.SignInButton?.value}
                </a>
              </li>
            </ul>
          )}
          <ul className="nav__section">
            <li className="nav__section-title">
              <a
                href={datasource.PrestoCardLink?.jss?.value?.href}
                className="nav__section-title__link"
              >
                {datasource.PrestoCardLink?.jss?.value?.text}
              </a>
            </li>
          </ul>
          {datasource.children.map((columns) => {
            return (
              <>
                <ul className="nav__section">
                  <li className="nav__section-title">{columns.field?.jss?.value}</li>
                </ul>
                <ul className="nav__sub-section">
                  {columns.children.map((a) => {
                    if (!isUserSignedIn && a.Signin?.jss?.value) {
                      return '';
                    }

                    const currentHeadingId = a.MainLink?.jss?.value?.href;
                    const headingHashTag = location.pathname;
                    return (
                      <>
                        <li
                          className={`nav__sub-section-title ${
                            navIndex === '0' &&
                            currentHeadingId.toLowerCase() == headingHashTag.toLowerCase()
                              ? 'nav__sub-section-title--active'
                              : ''
                          }`}
                        >
                          <a
                            href={a.MainLink?.jss?.value?.href}
                            className="nav__sub-section-title__link"
                            onClick={() => {
                              setNavIndex('0');
                            }}
                          >
                            {a.MainLink?.jss?.value?.text}
                          </a>
                        </li>
                        {a.children.map((b, index) => {
                          if (!isUserSignedIn && b.Signin?.jss?.value) {
                            return '';
                          }

                          return (
                            <li
                              className={`nav__link-container ${
                                index + 1 == navIndex &&
                                currentHeadingId.toLowerCase() == headingHashTag.toLowerCase()
                                  ? 'nav__link-section-title--active'
                                  : ''
                              }`}
                            >
                              <a
                                href={`${b.Link?.jss?.value?.href}#${b.Subpagelink?.jss?.value}`}
                                className="nav__link"
                                onClick={() => {
                                  setNavIndex(index + 1);
                                  setToggleClose(true);
                                }}
                              >
                                {b.Link?.jss?.value?.text}
                              </a>
                            </li>
                          );
                        })}
                      </>
                    );
                  })}
                </ul>
              </>
            );
          })}
          <ul className="nav__section">
            <li className="nav__section-language">
              <a onClick={changeLanguage} className="nav__section-title__link">
                {datasource.LanguageSwitchText?.value}
              </a>
            </li>
          </ul>
        </div>
      </section>
    );
  };
  const signOutUser = () => {
    localstorageService().removeItem(localStorageKeys.redux);
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    sessionstorageService().clear();
    // By uncommenting the below line, facing glitch issue while signout
    // dispatch(setContactlessUserSignedIn(false));
    localstorageService().clear();
    redirectToUrl('/');
    // let path = location.pathname.split('/');
    // path = path[path.length - 1];
    // let redirectUrl = signOutRedirectLink.split('/');
    // redirectUrl = redirectUrl[redirectUrl.length - 1];
    // if (path !== redirectUrl) {
    //   window.location.href = signOutRedirectLink;
    // }
  };
  return [
    getMobileMenu(),
    <header className={topNavClass}>
      <section className="topNav__container">
        <div className="topNav__wrapper">
          {/* <PrestoLink
            field={datasource.LogoLink?.jss}
            className="prestologolink"
            image={datasource.PrestoLogo.jss}
            imageClassName="hero__imagr"
          /> */}
          <a
            className="prestologolink"
            href={datasource.LogoLink?.jss.value.href}
            aria-label={datasource.LogoLink?.jss.value.text}
          >
            <Image media={datasource.PrestoLogo.jss.value} className="" loading="lazy" />
          </a>
          <div className="topNav__left">
            <Link field={datasource.PrestoCardLink.jss} className="link topNav__link">
              {datasource.PrestoCardLink.jss?.value?.text}
            </Link>
            <Link field={datasource.ContactlessLink.jss} className="active link topNav__link">
              {datasource.ContactlessLink.jss?.value?.text}
            </Link>
          </div>
          <div className="topNav__right">
            <a
              onClick={changeLanguage}
              href="javascript:void(0)"
              aria-label="switch to french"
              className="link topNav__link "
            >
              {' '}
              {datasource.LanguageSwitchText?.value}{' '}
            </a>
            {isUserSignedIn ? (
              <a
                onClick={() => {
                  signOutUser();
                }}
                href="javascript:void(0);"
                aria-label=""
                className="topNav__link"
              >
                {' '}
                {datasource.SignOutButton?.value}
              </a>
            ) : (
              <a
                onClick={() => {
                  redirectToUrl('/B2CRedirect/B2CRegisteredSignIn');
                }}
                href="javascript:void(0);"
                aria-label=""
                className="topNav__link"
              >
                {' '}
                {datasource.SignInButton?.value}{' '}
              </a>
            )}
          </div>
          <a href="javascript:void(0)" aria-label="Menu" className="topNav__hamburger">
            <div
              className="topNav__hamburger-container"
              onClick={() => setToggleClose(!toggleClose)}
            >
              {toggleClose ? (
                <svg viewBox="0 0 100 80" width="24" height="24" fill="currentColor">
                  <rect width="100" height="13" rx="2" />
                  <rect y="35" width="100" height="13" rx="2" />
                  <rect y="71" width="100" height="13" rx="2" />
                </svg>
              ) : (
                <svg
                  data-v-5a28a85f=""
                  viewBox="0 0 100 80"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <g data-v-5a28a85f="" transform="translate(-46, 18) rotate(45 60 60)">
                    <rect data-v-5a28a85f="" width="115" height="13" />
                  </g>
                  <g data-v-5a28a85f="" transform="translate(28, 16) rotate(-45 60 60)">
                    <rect data-v-5a28a85f="" width="115" height="13" />
                  </g>
                </svg>
              )}
            </div>
          </a>
        </div>
      </section>
    </header>,
  ];
};

export default withTranslation()(ContactlessTopNavigation);
