import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './FourthcolumnLayout.scss';
const FourthcolumnLayout = (props) => {
  //console.log(props, 'foure');
  return (
    <div className="fourcolumn-wrapper">
      <div
        className={`row ${props?.fields?.Cssclass && props.fields.Cssclass.value ? props.fields.Cssclass.value : ''
          }`}
      >
        <Placeholder name="presto-fourcolumnlayout" rendering={props.rendering} />
        <div className="column">
          <Placeholder name="firstColumn" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="second-column" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="thirdColumn" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="fourthColumn" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export default FourthcolumnLayout;
