import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RichText, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import infoImage from '../../assets/images/Info.svg';
import { Note, TextInput, Select, Modal } from '../../molecules';
import { Loader, Button, Checkbox, InlineError } from '../../atoms';
import api from '../../services/api';
import routes from '../../constants/routes';
import {
  redirectToUrl,
  localstorageService,
  resetAccessTokenValidity,
  getAllMediasFromUserState,
} from '../../services/utils';
import sharedFormConstants from '../../constants/sharedFormConstants';
import AccountCreationModal from './AccountCreationModal';
import localStorageKeys from '../../constants/localStorageKeys';
import mediaSubTypes from '../../constants/mediaSubTypes';
import langConstants from '../../constants/lang';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  fetchAvailableSettings,
  setWeakAccount,
  setGuestSignPopupEnabled,
  setUserSignedfromCreateAccount,
  setShoppingCart,
  setGuestUserEnabled,
} from '../../redux/user/actions';
import './createAccount.scss';

const CreateAccount = (props) => {
  const [emailChecked, setEmailChecked] = useState(true);
  const [anonymousStatus, setAnonymousStatus] = useState(false);
  const [promotionalEmailChecked, setPromotionalEmailChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState('');
  const [options, setOptions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isFrench, setIsFrench] = useState(false);
  const [technicalErrorMsg, setTechnicalErrorMsg] = useState(false);

  const [enteredUserName, setenteredUserName] = useState('');
  const [validateUserName, setvalidateUserName] = useState(false);

  const [enteredEmail, setenteredEmail] = useState('');
  const [validateEmail, setvalidateEmail] = useState(false);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const customerId = useSelector((reduxState) => reduxState.user.customerId);
  const guestSCPayload = useSelector((reduxState) => reduxState.user.guestShoppingCartPayload);
  //const [responseData, setResponseData] = useState('');
  const dispatch = useDispatch();
  const [startedLogin, setStartedLogin] = useState(false);
  const Countries = [{ label: 'Canada', value: 'CAN' }];

  const [postalCode, setPostalCode] = useState('');
  const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z ,\.\'-]+$/;
  const userInfo = useSelector((s) => s.user);
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.mediaId === reduxState.user.selectedCard.mediaId)
  );
  const allCards = getAllMediasFromUserState(useSelector((state) => state.user));
  const [isAnonymous, setIsAnonymous] = useState(false);
  const {
    fetchAllDataSuccess,
    isWeakPasswordAndQaAVisible,
    isWeakPasswordVisible,
    isWeakQaAVisible,
    emailError,
  } = userInfo;

  function redirectAfterLogin() {
    if (guestUserInfo?.isGuestLogin) {
      //  redirectToUrl(routes.checkout);
      reAddtheItemstoCart();
    } else {
      const redirectUrl = routes.dashboard;
      redirectToUrl(redirectUrl);
    }
  }

  const reAddtheItemstoCart = () => {
    const data = {
      customerId: customerId !== undefined ? customerId : null,
      visibleId: 'NEWMEDIA',
      productId: guestSCPayload?.productId,
      price: guestSCPayload?.price,
      quantity: 1,
      IsNewCardAdded: false,
      IsOrderCard: true,
    };

    api.addFundToCart(data).then((res) => {
      dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));
      dispatch(setGuestUserEnabled({ isGuestLogin: false }));
      redirectToUrl(routes.checkout);
    });
  };

  useEffect(() => {
    api.guestLoginforCreateAccount().then(({ data: { Data, Success } }) => {
      if (Success) {
        localstorageService().setItem(localStorageKeys.guestToken, Data.Access_token);
        localstorageService().setItem(localStorageKeys.accessToken, Data.Access_token);
        // localstorageService().setItem(localStorageKeys.refreshToken, Data.Refresh_token);
        // localstorageService().setItem(localStorageKeys.accessTokenLifetime, Data.Expires_in);
        resetAccessTokenValidity();
        getCountryData();
        getSecurityQuestions();
      }
    });

    let pathname = window.location.pathname;
    if (pathname.includes(langConstants.longFr)) {
      setIsFrench(true);
    }
  }, []);

  useEffect(() => {
    if (technicalErrorMsg) {
      document.body.scrollTop = 0;
    }
  }, [technicalErrorMsg]);

  useEffect(() => {
    if (!startedLogin || fetchAllDataSuccess === undefined) {
      return;
    }

    setStartedLogin(false);
    dispatch(itemsAreLoading(false));
    if (isWeakPasswordAndQaAVisible || isWeakPasswordVisible || isWeakQaAVisible) {
      dispatch(setWeakAccount(true));
    } else if (!isWeakPasswordAndQaAVisible || !isWeakPasswordVisible || !isWeakQaAVisible) {
      if (!fetchAllDataSuccess) {
        //console.log('error fetchAllDataSuccess');
        redirectAfterLogin();
      } else if (fetchAllDataSuccess) {
        redirectAfterLogin();
      } else {
        //console.log('error redirection', fetchAllDataSuccess);
        redirectAfterLogin();
      }
    }
  }, [startedLogin, fetchAllDataSuccess]);

  useEffect(() => {
    allCards.forEach((card) => {
      if (card.type === 'anonymous') {
        setIsAnonymous(true);
      }
    });
  }, []);

  const onEmailCheckedChange = (e) => {
    if (e) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
    setEmailChecked(e);
  };

  const onPromotionalEmailCheckedChange = (e) => {
    setPromotionalEmailChecked(e);
  };

  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value.toUpperCase());
  };

  const getCountryData = () => {
    setIsLoading(true);
    api
      .getCountryAreasforCreateAccount()
      .then((response) => {
        if (response.status === 200) {
          setCountryData(response?.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getSecurityQuestions = () => {
    api.getAllSecurityQuestions().then((response) => {
      if (response.status === 200) {
        setAllQuestions(
          response.data.map((i) => ({
            label: i.Item2,
            value: i.Item1,
          }))
        );
      }
    });
  };

  const onDashboardHandler = (e) => {
    setIsSuccess(false);
    setStartedLogin(true);
    dispatch(itemsAreLoading(true));
    dispatch(fetchAllDataSuccessfullyLoaded(undefined));
    resetAccessTokenValidity();
    dispatch(changeUserStatus(true));
    dispatch(fetchAllData());
    dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
    dispatch(setUserSignedfromCreateAccount(true));
  };

  useEffect(() => {
    countryData &&
      countryData?.Areas &&
      countryData.Areas.map((data) => {
        let obj = {
          label: data.Name,
          value: data.Id,
        };
        options.push(obj);
      });
  }, [countryData]);

  useEffect(() => {
    const identifier = setTimeout(() => {
      if (enteredEmail.length > 0) {
        api
          .checkUserNameAlreadyExists({ EmailAddress: enteredEmail })
          .then((response) => {
            if (response?.data?.Success) {
              setvalidateEmail(true);
            } else {
              setvalidateEmail(false);
            }
          })
          .catch((err) => {
            setvalidateEmail(false);
          });
      }
    }, 1000);
    return () => {
      clearTimeout(identifier);
    };
  }, [enteredEmail]);

  const userEmailChangeHandler = (e) => {
    setenteredEmail(e.target.value);
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      if (enteredUserName.length > 0) {
        api
          .checkUserNameAlreadyExists({ UserName: enteredUserName })
          .then((response) => {
            if (response?.data?.Success) {
              setvalidateUserName(true);
            } else {
              setvalidateUserName(false);
            }
          })
          .catch((err) => {
            setvalidateUserName(false);
          });
      }
    }, 1000);
    return () => {
      clearTimeout(identifier);
    };
  }, [enteredUserName]);

  const userNameChangeHandler = (e) => {
    setenteredUserName(e.target.value);
  };

  const getResponseUser = (response) => {
    setIsLoading(false);
    if (response.data.Success) {
      setIsSuccess(true);
      //  setResponseData(response.data)
      localstorageService().setItem(localStorageKeys.accessToken, response.data.Access_token);
      localstorageService().setItem(localStorageKeys.refreshToken, response.data.Refresh_token);
      localstorageService().setItem(localStorageKeys.accessTokenLifetime, response.data.Expires_in);
    } else {
      let Error = JSON.parse(response.data.Error);
      if (Error?.Code === 'AFMS-CRT-FE-0028') {
        setErrorMsg(props.fields.AFMSCRTFE28.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0035') {
        setErrorMsg(props.fields.AFMSCRTFE35.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0051') {
        setErrorMsg(props.fields.AFMSCRTFE51.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0052') {
        setErrorMsg(props.fields.AFMSCRTFE52.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0053') {
        setErrorMsg(props.fields.AFMSCRTFE53.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AccountCreatedSignInError') {
        setErrorMsg(props.fields.AccountCreatedSignInError.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'TechnicalError') {
        //  setErrorMsg(props.fields.TechnicalError.value);
        setTechnicalErrorMsg(true);
      } else {
        setErrorMsg(props.fields.AFMSCRTMA.value);

        setIsErrorModalOpen(true);
      }
    }
  };

  const getResponseAnonymous = (response) => {
    setIsLoading(false);
    if (response.data.Success) {
      //  setResponseData(response.data)
      localstorageService().setItem(localStorageKeys.accessToken, response.data.Access_token);
      localstorageService().setItem(localStorageKeys.refreshToken, response.data.Refresh_token);
      localstorageService().setItem(localStorageKeys.accessTokenLifetime, response.data.Expires_in);
      isAnonymous &&
        api
          .associateCustomerAddCard({
            CustomerId: response.data.CustomerId,
            CardId: customerId,
          })
          .then((res) => {
            if (res.data.Success) {
              setAnonymousStatus(true);
              setIsSuccess(true);
            } else {
              setAnonymousStatus(false);
              setIsSuccess(true);
            }
          });
    } else {
      let Error = JSON.parse(response.data.Error);
      if (Error?.Code === 'AFMS-CRT-FE-0028') {
        setErrorMsg(props.fields.AFMSCRTFE28.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0035') {
        setErrorMsg(props.fields.AFMSCRTFE35.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0051') {
        setErrorMsg(props.fields.AFMSCRTFE51.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0052') {
        setErrorMsg(props.fields.AFMSCRTFE52.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AFMS-CRT-FE-0053') {
        setErrorMsg(props.fields.AFMSCRTFE53.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'AccountCreatedSignInError') {
        setErrorMsg(props.fields.AccountCreatedSignInError.value);
        setIsErrorModalOpen(true);
      } else if (Error?.Code === 'TechnicalError') {
        //  setErrorMsg(props.fields.TechnicalError.value);
        setTechnicalErrorMsg(true);
      } else {
        setErrorMsg(props.fields.AFMSCRTMA.value);

        setIsErrorModalOpen(true);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {technicalErrorMsg && (
        <div className="crt-margin error-note">
          <div className="create-account-techincal-error">
            <div className="error--message">
              <div id="messageerror">{props.fields.TechnicalError.value}</div>
            </div>
          </div>
        </div>
      )}
      <div className="crt-margin create-note">
        <Note>
          <img src={infoImage} className="note-img-pad" />
          <RichText field={props.fields.TransferCardInformation} />
        </Note>
      </div>
      <div className="crt-margin sec-margin">
        <div className="sec-padding">
          <div className="create-account-header">
            <Text field={props.fields.UserInformationTitle} />
          </div>
          <p className="header-family p-margin">
            <RichText field={props.fields.UserInformationDesc} />
          </p>
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          FirstName: '',
          LastName: '',
          StreetAdress: '',
          AptSuiteBuilding: '',
          City: '',
          Province:
            options &&
            options.length > 0 &&
            options
              .filter((e) => {
                return e.label === 'Ontario';
              })
              .map((val) => {
                return val.value;
              })[0],
          Country: Countries[0].value,
          PostalCode: '',
          PrimaryPhoneNo: '',
          SecondaryPhoneNo: '',
          UserName: '',
          Email: '',
          ConfirmEmail: '',
          Password: '',
          ConfirmPassword: '',
          Question1Id: '',
          Question2Id: '',
          Question3Id: '',
          Answer1: '',
          Answer2: '',
          Answer3: '',
          [sharedFormConstants.terms]: false,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.FirstName) {
            errors.FirstName = props.fields.FirstNameError.value;
          }
          if (!values.LastName) {
            errors.LastName = props.fields.LastNameError.value;
          }
          if (!values.StreetAdress) {
            errors.StreetAdress = props.fields.StreetAddressError.value;
          }
          if (!values.City) {
            errors.City = props.fields.CityError.value;
          }
          if (!values.Province) {
            errors.Province = props.fields.ProvinceError.value;
          }
          if (!values.PrimaryPhoneNo) {
            errors.PrimaryPhoneNo = props.fields.PrimaryPhoneNumberError.value;
          }
          if (!values.UserName) {
            errors.UserName = props.fields.UsernameError.value;
          }
          if (validateUserName) {
            errors.UserName = props.fields.AFMSCRTFE28.value;
          }
          if (!values.Email) {
            errors.Email = props.fields.EmailAddressError.value;
          }
          if (validateEmail) {
            errors.Email = props.fields.AFMSCRTFE35.value;
          }
          if (!values.ConfirmEmail) {
            errors.ConfirmEmail = props.fields.ConfirmEmailAddressError.value;
          }
          if (!values.Password) {
            errors.Password = props.fields.PasswordError.value;
          }
          if (!values.ConfirmPassword) {
            errors.ConfirmPassword = props.fields.ConfirmPasswordError.value;
          }
          if (!values.Question1Id) {
            errors.Question1Id = props.fields.SelectSecurityQuestion1.value;
          }
          if (!values.Question2Id) {
            errors.Question2Id = props.fields.SelectSecurityQuestion2.value;
          }
          if (!values.Question3Id) {
            errors.Question3Id = props.fields.SelectSecurityQuestion3.value;
          }

          return errors;
        }}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          FirstName: Yup.string()
            .required(props.fields.FirstNameError.value)
            .max(25, props.fields.FirstNameLength.value)
            .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.FirstNameLength.value),
          LastName: Yup.string()
            .required(props.fields.LastNameError.value)
            .max(25, props.fields.LastNameLength.value)
            .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.LastNameLength.value),
          StreetAdress: Yup.string()
            .required(props.fields.StreetAddressError.value)
            .max(50, props.fields.StreetAddressLength.value)
            .matches(/^.[a-zA-Z0-9\\@/#*!^?.=: -]+$/, props.fields.StreetAddressLength.value),
          AptSuiteBuilding: Yup.string()
            .max(50, props.fields.ApartmentLength.value)
            .matches(/^.[a-zA-Z0-9\\@/#*!^?.=: -]+$/, props.fields.ApartmentLength.value),
          City: Yup.string()
            .required(props.fields.CityError.value)
            .max(50, props.fields.CityLength.value)
            .matches(/^[A-Za-z0-9 ]+$/, props.fields.CityLength.value),
          PostalCode: Yup.string()
            .required(props.fields.PostalCodeError.value)
            .matches(
              /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
              props.fields.PostalCodeFormatError.value
            ),
          Province: Yup.string().required(props.fields.ProvinceError.value),
          UserName: Yup.string()
            .required(props.fields.UsernameError.value)
            .min(6, props.fields.UsernameLength.value)
            .max(25, props.fields.UsernameLength.value)
            .matches(/^.[a-zA-Z0-9\-._]+$/, props.fields.UserNameValidationError.value),
          Email: Yup.string()
            .required(props.fields.EmailAddressError.value)
            .matches(
              /^[\w+_-]+(?:\.[\w+_-]+)*@(?:[\w\d-]+\.)+\w{2,6}$/,
              props.fields.InvalidEmailAddress.value
            )
            .max(60, props.fields.EmailLength.value),
          ConfirmEmail: Yup.string()
            .required(props.fields.ConfirmEmailAddressError.value)
            .oneOf([Yup.ref('Email'), null], props.fields.EmailNotMatching.value)
            .max(60, props.fields.EmailLength.value),
          Password: Yup.string()
            .required(props.fields.PasswordError.value)
            .matches(
              /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&\'\"<>()+,])/,
              props.fields.PasswordValidationError.value
            )
            .max(25, props.fields.PasswordLength.value),
          ConfirmPassword: Yup.string()
            .required(props.fields.ConfirmPasswordError.value)
            .oneOf([Yup.ref('Password'), null], props.fields.PasswordNotMatching.value)
            .max(25, props.fields.PasswordLength.value),
          Question1Id: Yup.string().required(props.fields.SelectSecurityQuestion1.value),
          Question2Id: Yup.string().required(props.fields.SelectSecurityQuestion2.value),
          Question3Id: Yup.string().required(props.fields.SelectSecurityQuestion3.value),
          Answer1: Yup.string()
            .required(props.fields.SecurityQuestionAnswerError.value)
            .matches(/^[A-Za-z0-9]{4,50}$/, props.fields.SecurityAnswerLength.value),
          Answer2: Yup.string()
            .required(props.fields.SecurityQuestionAnswerError.value)
            .matches(/^[A-Za-z0-9]{4,50}$/, props.fields.SecurityAnswerLength.value),
          Answer3: Yup.string()
            .required(props.fields.SecurityQuestionAnswerError.value)
            .matches(/^[A-Za-z0-9]{4,50}$/, props.fields.SecurityAnswerLength.value),
          PrimaryPhoneNo: Yup.string()
            .required(props.fields.PrimaryPhoneNumberError.value)
            // .min(10, props.fields.PrimaryPhoneNumberLength.value)
            // .max(10, props.fields.PrimaryPhoneNumberLength.value)
            .matches(
              /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
              props.fields.PrimaryPhoneNumberLength.value
            ),
          SecondaryPhoneNo: Yup.string()
            // .min(10, props.fields.SecondaryPhoneNumberError.value)
            // .max(10, props.fields.SecondaryPhoneNumberError.value)
            .matches(
              /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
              props.fields.SecondaryPhoneNumberError.value
            ),
          [sharedFormConstants.terms]: Yup.boolean().oneOf(
            [true],
            props.fields.TermsandConditonsError.value
          ),
        })}
        onSubmit={(values) => {
          setIsLoading(true);
          if (isAnonymous) {
            api
              .saveCreateAccountDataForAnonymous({
                FirstName: values.FirstName,
                LastName: values.LastName,
                Line1: values.StreetAdress,
                Line2: values.AptSuiteBuilding,
                AreaId: values.Province,
                AreaName: options
                  .filter((e) => {
                    return e.value === values.Province;
                  })
                  .map((val) => {
                    return val.label;
                  })[0],
                AreaCode: values.Province,
                CountryId: 'CAN',
                City: values.City,
                PostalCode: values.PostalCode,
                StreetAddress: values.StreetAdress,
                Apartment: values.AptSuiteBuilding,
                Province: values.Province,
                CountryName: 'Canada',
                PrimaryPhone: values.PrimaryPhoneNo,
                SecondaryPhone: values.SecondaryPhoneNo,
                UserName: values.UserName,
                EmailAddress: values.Email,
                ConfirmEmailAddress: values.ConfirmEmail,
                Password: values.Password,
                ConfirmPassword: values.ConfirmPassword,
                Question1Id: values.Question1Id,
                Answer1: values.Answer1,
                Question2Id: values.Question2Id,
                Answer2: values.Answer2,
                Question3Id: values.Question3Id,
                Answer3: values.Answer3,
                TraxOptInOut: emailChecked ? 'on' : 'off',
                MarktOptInOut: promotionalEmailChecked ? 'on' : 'off',
              })

              .then((response) => {
                getResponseAnonymous(response);
              });
          } else {
            api
              .saveCreateAccountData({
                FirstName: values.FirstName,
                LastName: values.LastName,
                Line1: values.StreetAdress,
                Line2: values.AptSuiteBuilding,
                AreaId: values.Province,
                AreaName: options
                  .filter((e) => {
                    return e.value === values.Province;
                  })
                  .map((val) => {
                    return val.label;
                  })[0],
                AreaCode: values.Province,
                CountryId: 'CAN',
                City: values.City,
                PostalCode: values.PostalCode,
                StreetAddress: values.StreetAdress,
                Apartment: values.AptSuiteBuilding,
                Province: values.Province,
                CountryName: 'Canada',
                PrimaryPhone: values.PrimaryPhoneNo,
                SecondaryPhone: values.SecondaryPhoneNo,
                UserName: values.UserName,
                EmailAddress: values.Email,
                ConfirmEmailAddress: values.ConfirmEmail,
                Password: values.Password,
                ConfirmPassword: values.ConfirmPassword,
                Question1Id: values.Question1Id,
                Answer1: values.Answer1,
                Question2Id: values.Question2Id,
                Answer2: values.Answer2,
                Question3Id: values.Question3Id,
                Answer3: values.Answer3,
                TraxOptInOut: emailChecked ? 'on' : 'off',
                MarktOptInOut: promotionalEmailChecked ? 'on' : 'off',
              })

              .then((response) => {
                getResponseUser(response);
              });
          }
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="crt-margin">
                <div className="sec-form-padding">
                  <div className="textinput-flex">
                    <div className="firstinput-wrapper">
                      <div className="firstname-text firstinput-custom">
                        <TextInput
                          type="text"
                          value={values.FirstName}
                          name="FirstName"
                          errors={errors}
                          touched={touched}
                          required={true}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.FirstNameLabel}
                        />
                      </div>
                    </div>
                    <div className="secondinput-wrapper">
                      <div className="lastname-text">
                        <TextInput
                          type="text"
                          value={values.LastName}
                          name="LastName"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.LastNameLabel}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content-divider"></div>
                  <div className="textinput-flex">
                    <div className="firstinput2-wrapper">
                      <div className="firstname-text">
                        <TextInput
                          type="text"
                          value={values.StreetAdress}
                          name="StreetAdress"
                          required={true}
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.StreetAddressLabel}
                        />
                      </div>
                    </div>
                    <div className="secondinput2-wrapper">
                      <div className={`${isFrench ? 'apt-label' : ''} lastname-text`}>
                        <TextInput
                          type="text"
                          value={values.AptSuiteBuilding}
                          name="AptSuiteBuilding"
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.ApartmentLabel}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="textinput-flex">
                    <div className="firstinput-wrapper">
                      <div className="firstname-text">
                        <TextInput
                          type="text"
                          value={values.City}
                          name="City"
                          required={true}
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.CityLabel}
                        />
                      </div>
                    </div>
                    <div className="secondinput-wrapper">
                      <div className="lastname-text province-margin">
                        <Select
                          fullWidth
                          name="Province"
                          errors={errors}
                          touched={touched}
                          options={options}
                          label={props.fields.ProvinceLabel.value}
                          labelA11y={props.fields.ProvinceLabelA11y.value}
                          defaultValue={
                            options &&
                            options.length > 0 &&
                            options
                              .filter((e) => {
                                return e.value === 'ON';
                              })
                              .map((val) => {
                                return val.value;
                              })
                          }
                          defaultLabel={
                            options &&
                            options.length > 0 &&
                            options
                              .filter((e) => {
                                return e.label === 'Ontario';
                              })
                              .map((val) => {
                                return val.label;
                              })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="textinput-flex">
                    <div className="firstinput-wrapper">
                      <div className="firstname-text province-margin disbaled-country">
                        <Select
                          fullWidth
                          name="Country"
                          options={Countries}
                          isDisabled={true}
                          label={props.fields.CountryLabel.value}
                          labelA11y={props.fields.CountryLabelA11y.value}
                          defaultValue={Countries[0].value}
                          defaultLabel={Countries[0].label}
                        />
                      </div>
                    </div>
                    <div className="secondinput-wrapper">
                      <div className="lastname-text">
                        <TextInput
                          type="text"
                          value={postalCode}
                          name="PostalCode"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                            onPostalCodeChange(e);
                          }}
                          label={props.fields.PostalCodeLabel}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content-divider"></div>
                  <div className="textinput-flex">
                    <div className="firstinput-wrapper">
                      <div className="firstname-text">
                        <TextInput
                          type="text"
                          value={values.PrimaryPhoneNo}
                          name="PrimaryPhoneNo"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.PrimaryPhoneNumber}
                        />
                      </div>
                    </div>
                    <div className="secondinput-wrapper">
                      <div className={`${isFrench ? 'phn-label' : ''} lastname-text`}>
                        <TextInput
                          type="text"
                          value={values.SecondaryPhoneNo}
                          name="SecondaryPhoneNo"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.SecondaryPhoneNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="section-divider"></div>
                  <div className="">
                    <div className="create-account-header account-info">
                      <Text field={props.fields.AccountInformationTitle} />
                    </div>
                    <p className="header-family p-margin">
                      {props.fields.AccountInformationDesc.value}
                    </p>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            type="text"
                            value={values.UserName}
                            name="UserName"
                            required={true}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                              userNameChangeHandler(e);
                            }}
                            label={props.fields.UsernameLabel}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            type="email"
                            value={values.Email}
                            name="Email"
                            required={true}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                              userEmailChangeHandler(e);
                            }}
                            label={props.fields.EmailAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            type="email"
                            required={true}
                            value={values.ConfirmEmail}
                            name="ConfirmEmail"
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.ConfirmEmailAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="content-divider"></div>
                    <div className="textinput-flex">
                      <div className="firstinput-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            type="password"
                            value={values.Password}
                            name="Password"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.PasswordLabel}
                            showIconA11y={props.fields.showPasswordA11y?.value}
                            hideIconA11y={props.fields.hidePasswordA11y?.value}
                            withViewIcon
                            disabledAutocomplete
                          />
                        </div>
                      </div>
                      <div className="secondinput-wrapper">
                        <div className="lastname-text">
                          <TextInput
                            type="password"
                            value={values.ConfirmPassword}
                            name="ConfirmPassword"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={props.fields.ConfirmPasswordLabel}
                            showIconA11y={props.fields.showConfirmPasswordA11y?.value}
                            hideIconA11y={props.fields.hideConfirmPasswordA11y?.value}
                            withViewIcon
                            disabledAutocomplete
                          />
                        </div>
                      </div>
                    </div>
                    <div className="create-note password-padding">
                      <Note>
                        <RichText field={props.fields.AccountInformationEndNote} />
                      </Note>
                    </div>
                  </div>
                  <div className="content-divider2"></div>
                  <div className="">
                    <div className="security-header">
                      <Text field={props.fields.SecurityQuestionLabel} />
                    </div>
                    <div className="textinput-flex ">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <Select
                            label={props.fields.SecurityQuestion1?.value}
                            fullWidth
                            name="Question1Id"
                            showMatAutoComplete={true}
                            errors={errors}
                            touched={touched}
                            options={allQuestions.filter(
                              (i) =>
                                i.value !== values.Question2Id && i.value !== values.Question3Id
                            )}
                            placeholder={props.fields.SelectSecurityQuestion1?.value}
                            labelA11y={props.fields.SecurityQuestion1A11y?.value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            name="Answer1"
                            errors={errors}
                            touched={touched}
                            required={true}
                            label={props.fields.SecurityAnswer1}
                            labelA11y={props.fields.SecurityAnswer1A11y?.value}
                            marginTopSpacing2
                            showIconA11y={props.fields.showAnswer1A11y?.value}
                            hideIconA11y={props.fields.hideAnswer1A11y?.value}
                            withViewIcon
                            disabledAutocomplete
                            bellowText={props.fields.MinCharacterValidation}
                          />
                          {/* <p className="header-family content-topmargin content-leftmargin">
                            {props.fields.MinCharacterValidation.value}
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex account-padding">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <Select
                            label={props.fields.SecurityQuestion2?.value}
                            fullWidth
                            name="Question2Id"
                            errors={errors}
                            touched={touched}
                            showMatAutoComplete={true}
                            options={allQuestions.filter(
                              (i) =>
                                i.value !== values.Question1Id && i.value !== values.Question3Id
                            )}
                            placeholder={props.fields.SelectSecurityQuestion2?.value}
                            labelA11y={props.fields.SecurityQuestion2A11y?.value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            name="Answer2"
                            errors={errors}
                            touched={touched}
                            required={true}
                            label={props.fields.SecurityAnswer2}
                            labelA11y={props.fields.SecurityAnswer2A11y?.value}
                            marginTopSpacing2
                            withViewIcon
                            showIconA11y={props.fields.showAnswer2A11y?.value}
                            hideIconA11y={props.fields.hideAnswer2A11y?.value}
                            disabledAutocomplete
                            bellowText={props.fields.MinCharacterValidation}
                          />
                          {/* <p className="header-family content-topmargin content-leftmargin">
                            {props.fields.MinCharacterValidation.value}
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex account-padding">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <Select
                            label={props.fields.SecurityQuestion3?.value}
                            fullWidth
                            name="Question3Id"
                            errors={errors}
                            showMatAutoComplete={true}
                            touched={touched}
                            options={allQuestions.filter(
                              (i) =>
                                i.value !== values.Question1Id && i.value !== values.Question2Id
                            )}
                            placeholder={props.fields.SelectSecurityQuestion3?.value}
                            labelA11y={props.fields.SecurityQuestion3A11y?.value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput3-wrapper">
                        <div className="firstname-text">
                          <TextInput
                            name="Answer3"
                            errors={errors}
                            touched={touched}
                            required={true}
                            label={props.fields.SecurityAnswer3}
                            labelA11y={props.fields.SecurityAnswer3A11y?.value}
                            marginTopSpacing2
                            showIconA11y={props.fields.showAnswer3A11y?.value}
                            hideIconA11y={props.fields.hideAnswer3A11y?.value}
                            withViewIcon
                            disabledAutocomplete
                            bellowText={props.fields.MinCharacterValidation}
                          />
                          {/* <p className="header-family content-topmargin content-leftmargin">
                            {props.fields.MinCharacterValidation.value}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-divider2"></div>
                  <div className="checkbox-padding">
                    <div className="textinput-flex acc-creation-checkbox">
                      <Checkbox
                        checked={promotionalEmailChecked}
                        onChange={(e) => {
                          onPromotionalEmailCheckedChange(e);
                        }}
                        checkboxA11y={props.fields.PrestoContentCheckboxDesc.value}
                      >
                        <Text field={props.fields.PrestoContentCheckboxDesc} />
                      </Checkbox>
                    </div>
                    <div className="textinput-flex content-padding">
                      <div className="acc-creation-checkbox">
                        <Checkbox
                          checked={emailChecked}
                          onChange={(e) => {
                            onEmailCheckedChange(e);
                          }}
                          checkboxA11y={props.fields.AdditionalCheckboxDesc.value}
                        >
                          <Text field={props.fields.AdditionalCheckboxDesc} />
                        </Checkbox>
                      </div>
                    </div>
                    <div className="create-note content-padding">
                      <Note>
                        <RichText field={props.fields.EndNote} />
                      </Note>
                    </div>
                  </div>
                  <div className="section-divider2"></div>
                  <div className="create-terms-block">
                    <Placeholder name="presto-terms-and-conditions" rendering={props.rendering} />
                    {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                      <InlineError cssClass="checkbox-error">
                        {errors[sharedFormConstants.terms]}
                      </InlineError>
                    )}
                  </div>
                </div>
              </div>

              <div className=" btn-flex">
                <div className="btn-padding">
                  <Button type="submit" buttonTextA11y={props.fields.CreateMyAccountTextA11y.value}>
                    <Text field={props.fields.CreateMyAccountText} />
                  </Button>
                </div>
                <div className="cancel-btn">
                  <Button
                    white
                    firstOrder
                    buttonTextA11y={props.fields.CancelTextA11y.value}
                    onClick={() => redirectToUrl('/')}
                  >
                    <Text field={props.fields.CancelText} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <div className="header-family crt-margin captch-margin">
        <RichText field={props.fields.CaptchaNote} />
      </div>
      {isSuccess && (
        <AccountCreationModal
          fields={props.fields}
          showPopup={isSuccess}
          gotoDashboard={onDashboardHandler}
          anonymousStatus={anonymousStatus}
        />
      )}
      {
        <Modal
          title={props.fields.EmailOptOutTitle.value}
          text={props.fields.EmailOptOutDesc.value}
          textSubmit={props.fields.CloseText.value}
          useRevert
          disableBackdropClick
          disableEscapeKeyDown
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
      {
        <Modal
          hideTitle
          text={errorMsg}
          textSubmit={props.fields.CloseText.value}
          useRevert
          disableBackdropClick
          disableEscapeKeyDown
          isModalOpen={isErrorModalOpen}
          setIsModalOpen={setIsErrorModalOpen}
        />
      }
    </>
  );
};

export default CreateAccount;
