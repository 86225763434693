import React, { useState, useEffect, useRef } from 'react';

import { Placeholder, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useLocation } from 'react-router-dom';
import { Button, ButtonBlock } from '../../atoms';
import signInFormFields from '../../constants/signInFormFields';
import api from '../../services/api';
import routes from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  getQueryParams,
  redirectToUrl,
  resetAccessTokenValidity,
} from '../../services/utils';
import handleServerError from '../../services/handleServerError';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleLogOut from '../../services/handleLogOut';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  fetchAvailableSettings,
  setWeakAccount,
  setGuestUserEnabled,
  anonymousSaveAccessToken,
} from '../../redux/user/actions';
import { Header, Error, TextInput, AdvancedModal, MediaImageList } from '../../molecules';

const Dashboard = (props) => {
  const [error, setError] = useState('');
  const [errorTitleMsg, setErrorTitleMsg] = useState(false);
  const [state, setState] = useState('');
  const [errorTitle] = useState(props.t('registeredAccountSignInTitleErrorMessage'));
  const dispatch = useDispatch();
  const [setIsContentState] = useState(false);
  let usernameInputRef = useRef();
  const userInfo = useSelector((s) => s.user);
  const {
    fetchAllDataSuccess,
    isWeakPasswordAndQaAVisible,
    isWeakPasswordVisible,
    isWeakQaAVisible,
    emailError,
  } = userInfo;

  const showContentOnMobiles = (e) => {
    e.preventDefault();
    setIsContentState(true);
  };

  const queryParams = getQueryParams(useLocation().search);
  const returnUrl = queryParams.returnUrl;

  const [settings, setSettings] = useState([]);
  const [startedLogin, setStartedLogin] = useState(false);

  // useEffect(() => {
  //   getClaimsDetails();
  // }, []);

  // const getClaimsDetails = () => {
  //   setError('');
  //   dispatch(itemsAreLoading(true));
  //   dispatch(fetchAllDataSuccessfullyLoaded(undefined));
  //   api
  //     .getClaims()
  //     .then((res) => {
  //       console.log(res);
  //       if (res) {
  //         setStartedLogin(true);
  //         localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
  //         localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Access_token);
  //         localstorageService().setItem(
  //           localStorageKeys.accessTokenLifetime,
  //           res.data.Data.Expires_in
  //         );
  //         resetAccessTokenValidity();
  //         // if (res.data.Data.Role === "Anonymous") {
  //         //   dispatch(changeUserStatus(false, true));
  //         //   dispatch(anonymousSaveAccessToken(res.data.Data.Access_token));
  //         //   dispatch(fetchAllData());
  //         // }
  //         // else if (res.data.Data.Role === "Registered") {
  //         dispatch(changeUserStatus(true));
  //         dispatch(fetchAllData());
  //         dispatch(setGuestUserEnabled({ isGuestLogin: false }));
  //         //}
  //       }
  //     })
  //     .catch((res) => {
  //       dispatch(itemsAreLoading(false));
  //       //(signInFormFields.googleToken, 1);
  //       handleServerError(undefined, setError)(res);
  //     });
  // }

  // useEffect(() => {
  //   setStartedLogin(false);
  //   dispatch(itemsAreLoading(false));
  // }, [startedLogin, fetchAllDataSuccess]);

  return (
    <div>
      <Header>
        <Text field={props.fields.heading} />
      </Header>

      <AdvancedModal
        fields={props.fields}
        header={props.fields.modalHeading.value}
        subHeader={props.fields.modalSubHeading.value}
        buttonText={props.fields.modalButtonText.value}
        checkBoxText={props.fields.modalCheckBoxText.value}
        // showPopup={
        //   !localstorageService().getItem(localStorageKeys.dismissedWelcomePopup) ||
        //   (localstorageService().getItem(localStorageKeys.dismissedWelcomePopup) &&
        //     localstorageService().getItem(localStorageKeys.dismissedWelcomePopup) === 'false')
        // }
        showPopup={false}
        props={props}
      >
        <Placeholder name="presto-new-presto" rendering={props.rendering} />
      </AdvancedModal>

      <Placeholder name="presto-dashboard" rendering={props.rendering} />
    </div>
  );
};

export default withTranslation()(Dashboard);
