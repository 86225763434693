import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch } from 'react-redux';

import handleLogOut from '../../services/handleLogOut';
import { Success, InlineButtonBlock, Header, Error } from '../../molecules';

export default function Password1({ fields, userInfo, toNextStep, redirectAfterLogin, state }) {
  const dispatch = useDispatch();

  return (
    <div className="security-alerts">
      <Header aria-label={fields.securityUpdateHeadingA11y.value} smallerFont>
        <Text field={fields.securityUpdateHeading} />
      </Header>
      {state.updateResult && state.updateResult === 'notUpdated' ? (
        <>
          <Error
            title={fields.unsuccessfulPasswordUpdateMessage?.value}
            text={fields.unsuccessfulPasswordUpdateBellowMessage?.value}
          />
          <InlineButtonBlock
            onClick={() => handleLogOut(dispatch)}
            buttonText={fields.buttonSignout}
            buttonTextA11y={fields.buttonSignoutA11y.value}
          />
        </>
      ) : (
        <>
          <Success
            title={fields.passwordUpdatedTitle}
            titleA11y={fields.passwordUpdatedTitleA11y.value}
            Content={() => (
              <p className="success-msg-content regular-font">
                <Text field={fields.passwordUpdatedNote} />
              </p>
            )}
          />
          <InlineButtonBlock
            onClick={userInfo.isWeakPasswordAndQaAVisible ? toNextStep : redirectAfterLogin}
            buttonText={fields.buttonClose}
            buttonTextA11y={fields.buttonCloseA11y.value}
          />
        </>
      )}
    </div>
  );
}
