import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { CardBlock } from '../../molecules';
import api from '../../services/api';
import { Button, ButtonBlock } from '../../atoms';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { updateLoginAndSecurityFields, setB2cClaimsResponse } from '../../redux/user/actions';
import { localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';
import handleServerError from '../../services/handleServerError';

const TwoStepVerification = ({ fields }) => {
  const dispatch = useDispatch();
  const b2cClaimsResponse = useSelector((reduxState) => reduxState?.user?.b2cClaimsResponse);
  const loginAndSecurityProps = useSelector((reduxState) => reduxState.user.loginAndSecurity);
  const shortenPhoneNumber = b2cClaimsResponse?.PhoneNumber;
  const [error, setError] = useState('');
  const { search } = useLocation();

  let IsMfAEnabled =
    b2cClaimsResponse && b2cClaimsResponse.hasOwnProperty('IsMfAEnabled')
      ? b2cClaimsResponse.IsMfAEnabled
      : false;

  useEffect(() => {
    if (
      fields?.SetupEnrollSuccessMsg?.value != '' &&
      (loginAndSecurityProps?.SetupEnrollSuccessMsg?.value === '' ||
        loginAndSecurityProps?.SetupEnrollSuccessMsg?.value !== fields?.SetupEnrollSuccessMsg?.value)
    ) {
      dispatch(
        updateLoginAndSecurityFields({
          SetupEnrollSuccessMsg: { value: fields?.SetupEnrollSuccessMsg?.value },
          SetupUpdateSuccessMsg: { value: fields?.SetupUpdateSuccessMsg?.value },
          SetupDisableSuccessMsg: { value: fields?.SetupDisableSuccessMsg?.value },
          SetupMaxEntryOnEnrollErrorMsg: { value: fields?.SetupEnrollMaxEntryErrorMsg?.value },
          SetupMaxEntryOnUpdateErrorMsg: { value: fields?.SetupUpdateMaxEntryErrorMsg?.value },
          SetupMaxEntryOnDisableErrorMsg: { value: fields?.SetupDisableMaxEntryErrorMsg?.value },
          SetupInfoMsg: { value: fields?.SetupInfoMsg?.value },
          SetupInfoUpdateMsg: { value: fields?.SetupInfoUpdateMsg?.value },
          SetupInfoDisableMsg: { value: fields?.SetupInfoDisableMsg?.value },
        })
      );
    }
  }, []);

  useEffect(() => {
    const queryParams1 = new URLSearchParams(search);
    if (
      !queryParams1.has('Update') && !queryParams1.has('Enroll') && !queryParams1.has('Disable')
    ) {
      api
        .getClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate:
                  res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin:
                  String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, []);

  return (
    <div className="two-step-verify-container">
      <CardBlock
        title={fields.VerificationHeading?.value}
        titleA11y={fields.VerificationHeading?.value}
        successIcon={IsMfAEnabled}
        tabIndex="0"
        {...(IsMfAEnabled
          ? {
            subTitle: (
              <div
                className="masked-phone-container"
                aria-label={`${fields.PhoneNumberTitle?.value} ${shortenPhoneNumber}`}
              >
                {fields.PhoneNumberTitle?.value}
                <span>{shortenPhoneNumber}</span>
              </div>
            ),
          }
          : {})}
      >
        <div
          className="verification-status-container"
          aria-label={`${fields.VerificationStatusLabel?.value}${IsMfAEnabled ? fields.StatusOnText?.value : fields.StatusNotSetupText?.value
            }`}
        >
          {fields.VerificationStatusLabel.value}
          <span className="status-message">
            {IsMfAEnabled ? fields.StatusOnText?.value : fields.StatusNotSetupText?.value}
          </span>
        </div>
        <div className="verification-status-message">
          {IsMfAEnabled ? (
            <RichText field={fields.UpdateNumNote} />
          ) : (
            <p>{fields.SelectSetupNote?.value}</p>
          )}
        </div>
        <ButtonBlock>
          <ButtonBlock right>
            {IsMfAEnabled ? (
              [
                <Button
                  type="submit"
                  buttonTextA11y={fields.UpdateButtonA11y?.value}
                  onClick={() => api.b2cUpdateMFA()}
                  datatestid="updatebtn"
                >
                  <Text field={fields.UpdateButton} />
                </Button>,
                <Button
                  white
                  firstOrder
                  buttonTextA11y={fields.DisableButtonA11y?.value}
                  onClick={() => api.b2cDisableMFA()}
                  datatestid="disablebtn"
                >
                  <Text field={fields.DisableButton} />
                </Button>,
              ]
            ) : (
              <Button
                type="submit"
                buttonTextA11y={fields.SetupButtonA11y?.value}
                onClick={() => api.b2cEnrollMFA()}
                datatestid="setupbtn"
              >
                <Text field={fields.SetupButton} />
              </Button>
            )}
          </ButtonBlock>
        </ButtonBlock>
      </CardBlock>
    </div>
  );
};
export default withTranslation()(TwoStepVerification);
