import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import './leftNavigation.scss';
import { useLocation } from 'react-router-dom';

const LeftNavigation = (props) => {
  const { datasource } = props.fields && props.fields.data ? props.fields.data : { children: [] };
  const location = useLocation();
  const [navIndex, setNavIndex] = useState('0');
  const isContactlessAuthenticatedRedux = useSelector((state) => state.contactless.isContactlessUserSignedIn);
  const [isContactlessAuthenticated,setIsContactlessAuthenticated] = useState(false);
  
  useEffect(() => {
    const elementPos = document.getElementById(location.hash.substring(1));
    const offsetValue = elementPos?.offsetTop;
    if (offsetValue && typeof sessionStorage != 'undefined' && sessionStorage.getItem('goToUrl')) {
      window.scroll(0, offsetValue);
    }
    setIsContactlessAuthenticated(isContactlessAuthenticatedRedux);
    sessionStorage.removeItem('goToUrl');
  }, []);
  const goTo = () => {
    if (typeof sessionStorage != 'undefined') {
      sessionStorage.setItem('goToUrl', true);
    }
  };

  return (
    <div className="navigation">
      <div className="navMenu" role="navigation">
        {datasource && (
          <>
            <h2>
              <Text field={datasource.MainHeading?.jss} />
            </h2>
            <div className="nav__sub-section" data-e2e-id="graphql-integrated">
              {datasource.children.map((columns) => {
                var linkHref = columns.MainLink?.jss?.value?.href;
                var headingHashTag = location.pathname;

                if (!isContactlessAuthenticated && columns.Signin?.jss?.value) {
                  return '';
                }
                else {
                  return (
                    <ul>
                      <li
                        className={`${navIndex === '0' &&
                        linkHref.toLowerCase() == headingHashTag.toLowerCase()
                          ? `heading-active`
                          : ``
                          }`}
                      >
                        <a
                          className="nav__sub-section-title__link"
                          href={columns.MainLink?.jss?.value?.href}
                          onClick={() => {
                            goTo();
                            setNavIndex('0');
                          }}
                        >
                          <b>{columns.MainLink?.jss?.value?.text}</b>
                        </a>
                      </li>
                      {columns.children.map((a, index) => {
                        if (!isContactlessAuthenticated && a.Signin?.jss?.value) {
                          return '';
                        }
                        else {
                          return (
                            <li
                              className={`category-link ${index + 1 == navIndex &&
                                linkHref.toLowerCase() == headingHashTag.toLowerCase()
                                ? `active`
                                : ``
                                }`}
                            >
                              <a
                                className="nav__sub-section-title"
                                href={a.Link?.jss?.value?.href + '#' + a.Subpagelink?.jss?.value}
                                onClick={() => {
                                  goTo();
                                  setNavIndex(index + 1);
                                }}
                              >
                                {a.Link?.jss?.value?.text}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  );
                }
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeftNavigation;
