import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const FullScreenLayout = (props) => (
  <Placeholder
    name="presto-fullscreen-content"
    rendering={props.rendering}
    backgroundImage={props.fields.backgroundImage}
  />
);

export default FullScreenLayout;
