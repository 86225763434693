import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './imageWithLink.scss';
import { Button } from '../../atoms';

const ImageParagraphwithButton = (props) => {
  //console.log('fields', props);
  return (
    <div className="main-container">
      <div className="left-block">
        <div className="title">
          <h1>
            <Text field={props.fields.Title} />
          </h1>
        </div>
        <div className="description">
          <p>
            <Text field={props.fields.Description} />
          </p>
        </div>
        <div className="create-my-account">
          <Button
            onClick={() => {
              window.location.href = props?.fields?.Pagelink?.value?.href;
            }}
          >
            {props.fields.Pagelink.value.text}
          </Button>
        </div>
      </div>
      <div className="right-block">
        <img src={props?.fields?.Icon?.value?.src}></img>
      </div>
    </div>
  );
};

export default ImageParagraphwithButton;
