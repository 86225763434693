import React from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import warningIcon from '../../assets/images/fail.svg';

import './warning.scss';

const Warning = ({ title, titleA11y, description, descriptionA11y, custom }) => (
  <div className="warning" role="alert">
    <div className="warning-title" aria-label={titleA11y?.value}>
      <img src={warningIcon} alt="" className="warning-titleImg" />
      <span className="warning-titleText">
        <Text field={title} />
      </span>
    </div>
    <div aria-label={descriptionA11y?.value}>
      {custom ? <div>{description}</div> : <RichText field={description} />}
    </div>
  </div>
);

export default Warning;
