export default {
  invalidCredentials: 'AFMS-DAX-FE-0005',
  accountLocked: 'AFMS-DAX-FE-0008',
  pendingTermination: 'AFMS-CRT-FE-0029',
  pendingLoad: 'AFMS-CRT-FE-0054',
  pfmTechnicalException1: 'AFMS-CRT-TE-0001',
  pfmTechnicalException2: 'AFMS-CRT-TE-0002',
  pfmTechnicalException3: 'AFMS-CRT-TE-0003',
  invalidItemsInShoppingCart: 'AFMS-CRT-FE-0014',
  shoppingCartNotExists: 'AFMS-CRT-FE-0015',
  unverifiedEmail: 'AFMS-CRT-FE-0032',

  weakPassword: 'AFMS-CRT-FE-0051',
  weakQaA: 'AFMS-CRT-FE-0052',
  weakPasswordAndQaA: 'AFMS-CRT-FE-0053',

  securityWrongPassword: 'SetSecurityQuestionsWithAnswers_WrongPassword',
  securityUsernameAlreadyExists: 'SetSecurityQuestionsWithAnswers_UserNameAlreadyExist',
  securityEmailAlreadyExists: 'AFMS-CRT-FE-0035',
  redeemVoucherDOBError: 'AFMS-PV-API-0083',
};
