import React, { useState, useEffect } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import api from '../../services/api';
import routes from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  getQueryParams,
  redirectToUrl,
  resetAccessTokenValidity,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../services/utils';
import handleServerError from '../../services/handleServerError';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  setGuestUserEnabled,
  anonymousSaveAccessToken,
  setGuestShoppingCartPayload,
  setGuestSignPopupEnabled,
  setUserSignedfromGuestFlow,
  setShoppingCart,
  setLoggedInTime,
  setLogOutTime,
  setB2cClaimsResponse,
} from '../../redux/user/actions';
import { Header } from '../../molecules';

const SigninSuccess = (props) => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [setIsContentState] = useState(false);
  const userInfo = useSelector((s) => s.user);
  const { fetchAllDataSuccess, firstName } = userInfo;

  const showContentOnMobiles = (e) => {
    e.preventDefault();
    setIsContentState(true);
  };

  const queryParams = getQueryParams(useLocation().search);
  const { hash } = useLocation();
  const returnUrl = queryParams.redirectUrl;
  const b2cClaimsResponse = useSelector((reduxState) => reduxState.user.b2cClaimsResponse);
  const guestSigninPopup = useSelector((reduxState) => reduxState.user.guestSignPopupEnabled);
  const shoppingCart = useSelector((reduxState) => reduxState.user.shoppingCart);
  const guestSCPayload = useSelector((reduxState) => reduxState.user.guestShoppingCartPayload);
  const lastVisitedSecuredRoute = useSelector(
    (reduxState) => reduxState.user.lastVisitedSecuredRoute
  );
  const authenticated = useSelector((state) => state.user.authenticated);
  const anonymous = useSelector((state) => state.user.anonymous);

  const [startedLogin, setStartedLogin] = useState(false);
  const [isFromGuest, setisFromGuest] = useState(false);

  useEffect(() => {
    let uniqueSessionId = uuidv4().replace(/-/g, '');
    if (!getLocalStorageItem(localStorageKeys.uniqueSessionId)) {
      setLocalStorageItem(localStorageKeys.uniqueSessionId, uniqueSessionId);
    }
  }, []);

  useEffect(() => {
    getClaimsDetails();
  }, []);

  useEffect(() => {
    if (
      fetchAllDataSuccess &&
      !anonymous &&
      authenticated &&
      firstName !== undefined &&
      firstName !== '' &&
      firstName?.trim() !== '' &&
      firstName !== '-' &&
      firstName !== null
    ) {
      setStartedLogin(false);
      dispatch(itemsAreLoading(false));
      if (!isFromGuest) {
        if (returnUrl) {
          let redirectedUrl = returnUrl;
          if (hash) {
            redirectedUrl = redirectedUrl + hash;
          }
          redirectToUrl(redirectedUrl);
        } else if (lastVisitedSecuredRoute) {
          window.location.href = lastVisitedSecuredRoute;
        } else {
          let isRedirectingFromMFAFlow =
            b2cClaimsResponse && b2cClaimsResponse.hasOwnProperty('IsMfAEnabled') &&
            b2cClaimsResponse?.IsMfAEnabled &&
            b2cClaimsResponse.hasOwnProperty('IsFirstTimeLogin') &&
            b2cClaimsResponse?.IsFirstTimeLogin;
          api.dashboard({ ...(isRedirectingFromMFAFlow && { queryParams: '?isMFASuccess=true' }) });
        }
      } else {
        redirectAfterLogin();
      }
    } else if (
      fetchAllDataSuccess &&
      !anonymous &&
      authenticated &&
      (firstName === undefined ||
        firstName === '' ||
        firstName?.trim() === '' ||
        firstName === '-' ||
        firstName === null)
    ) {
      setStartedLogin(false);
      dispatch(itemsAreLoading(false));
      dispatch(changeUserStatus(false));
      redirectToUrl(routes.b2cAccountCreation);
    } else if (anonymous && !authenticated) {
      setStartedLogin(false);
      dispatch(itemsAreLoading(false));
      api.dashboard();
    }
  }, [fetchAllDataSuccess]);

  function redirectAfterLogin() {
    reAddtheItemstoCart();
  }

  const reAddtheItemstoCart = () => {
    let epurse = guestSCPayload.items.filter((x) => x.productFamily === 'Epurse');
    let passes = guestSCPayload.items.filter((x) => x.productFamily === 'CAPP');

    if (epurse.length > 0) {
      let payload = {
        customerId: userInfo.customerId !== undefined ? userInfo.customerId : null,
        visibleId: 'NEWMEDIA',
        productId: epurse[0].id,
        price: epurse[0].subTotal,
        quantity: 1,
        IsNewCardAdded: false,
        IsOrderCard: true,
      };
      api.addFundToCart(payload).then((res) => {
        dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));
        dispatch(setUserSignedfromGuestFlow(true));
        if (guestSigninPopup) {
          const redirectUrl = routes.checkout;
          redirectToUrl(redirectUrl);
        }
      });
    }

    if (passes.length > 0) {
      for (let i = 0; i < passes.length; i++) {
        let payload = {
          customerId: userInfo.customerId !== undefined ? userInfo.customerId : null,
          visibleId: 'NEWMEDIA',
          productId: passes[i].id,
          price: passes[i].subTotal,
          quantity: 1,
          IsNewCardAdded: epurse.length > 0 ? true : false,
          IsOrderCard: true,
        };
        addGuestProducttoCart(payload);
      }
    }
  };

  const addGuestProducttoCart = async (payload) => {
    await api.addProductToCart(payload).then((res) => {
      dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));
      dispatch(setUserSignedfromGuestFlow(true));
      if (guestSigninPopup) {
        const redirectUrl = routes.checkout;
        redirectToUrl(redirectUrl);
      }
    });
  };
  const storeB2CClaimsResponseIn = (res) => {
    localstorageService().setItem(localStorageKeys.isRiskyUser, String(res?.data?.Data?.IsRiskyUser).toLowerCase() === 'true')
    dispatch(
      setB2cClaimsResponse({
        IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
        //IsRiskyUser: String(res?.data?.Data?.IsRiskyUser).toLowerCase() === 'true',
        MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
        PasswordNotificationLastViewedDate: res?.data?.Data?.PasswordNotificationLastViewedDate,
        IsFirstTimeLogin: String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
        PhoneNumber: res?.data?.Data?.PhoneNumber,
      })
    );
  };
  const getClaimsDetails = () => {
    setError('');
    dispatch(itemsAreLoading(true));
    dispatch(fetchAllDataSuccessfullyLoaded(undefined));
    api
      .getClaims()
      .then((res) => {
        // console.log(res);
        if (res) {
          setStartedLogin(true);
          // dispatch(setLoggedInTime(moment.utc().format()));
          dispatch(setLoggedInTime(moment.unix(res.data.Data.StartTime).utc().format()));
          dispatch(setLogOutTime(moment.unix(res.data.Data.ExpTime).utc().format()));
          localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Access_token);
          localstorageService().setItem(
            localStorageKeys.accessTokenLifetime,
            res.data.Data.Expires_in
          );
          resetAccessTokenValidity();
          if (res.data.Data.Role === 'Anonymous') {
            dispatch(changeUserStatus(false, true));
            dispatch(anonymousSaveAccessToken(res.data.Data.Access_token));
            dispatch(fetchAllData());
          } else if (res.data.Data.Role === 'Registered' && res.data.Data.IsGuest === '') {
            storeB2CClaimsResponseIn(res);
            setisFromGuest(false);
            dispatch(changeUserStatus(true));
            dispatch(fetchAllData());
            dispatch(setGuestUserEnabled({ isGuestLogin: false }));
          } else if (res.data.Data.Role === 'Registered' && res.data.Data.IsGuest === 'Guest') {
            storeB2CClaimsResponseIn(res);
            setisFromGuest(true);
            dispatch(setGuestShoppingCartPayload(shoppingCart));
            dispatch(changeUserStatus(true));
            dispatch(fetchAllData());
            dispatch(setGuestShoppingCartPayload(shoppingCart));
            dispatch(setGuestUserEnabled({ isGuestLogin: false }));
            dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
          }
          // api.dashboard();
        }
      })
      .catch((res) => {
        dispatch(itemsAreLoading(false));
        // (signInFormFields.googleToken, 1);
        handleServerError(undefined, setError)(res);
      });
  };

  return (
    <div>
      <Header>
        <Text This is Test page />
      </Header>
    </div>
  );
};

export default withTranslation()(SigninSuccess);
