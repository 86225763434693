import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import api from '../../services/api';
import { itemsAreLoading } from '../../redux/user/actions';
import { StepTracker } from '../../molecules';
import AddEmail0 from './AddEmail0';
import AddEmail1 from './AddEmail1';
import UpdateEmail0 from './UpdateEmail0';
import UpdateEmail1 from './UpdateEmail1';
import VerifyEmail0 from './VerifyEmail0';
import { updateUserInfo, setB2cClaimsResponse } from '../../redux/user/actions';
import './emailVerification.scss';
import { useLocation } from 'react-router-dom';
import localStorageKeys from '../../constants/localStorageKeys';
import { localstorageService, getQueryParams } from '../../services/utils';
import handleServerError from '../../services/handleServerError';

const noEmailFlowMap = [AddEmail0, AddEmail1, UpdateEmail0, UpdateEmail1];
const verifyEmailFlowMap = [VerifyEmail0, UpdateEmail0, UpdateEmail1];

const EmailVerification = ({ t, fields }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const [state, setState] = useState({});
  const [userHasEmail, setUserHasEmail] = useState(null);
  const focusIt = () => {
    if (focusDiv && focusDiv.current) {
      focusDiv.current.focus();
    }
  };
  const toNextStep = () => {
    setStep(step + 1);
    focusIt();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusIt();
  };
  const toFirstStep = () => {
    setStep(0);
    focusIt();
  };
  const toLastStep = () => {
    setStep(userHasEmail ? verifyEmailFlowMap.length - 1 : noEmailFlowMap.length - 1);
    focusIt();
  };
  const dispatch = useDispatch();
  const resendVerificationEmail = (onSuccess = () => { }) => {
    api
      .resendVerificationEmail({
        CustomerId: userInfo.customerId,
      })
      .then((response) => {
        if (response.data.Success) {
          onSuccess();
          dispatch(itemsAreLoading(false));
        }
      })
      .catch(() => dispatch(itemsAreLoading(false)));
    dispatch(itemsAreLoading(true));
  };
  const queryParams = getQueryParams(useLocation().search);
  const [error, setError] = useState('');

  useEffect(() => {
    if (userInfo.loginEmail) {
      if (queryParams.SuccessEmailChange !== undefined) {
        api
          .getClaims()
          .then((res) => {
            //console.log(res);
            if (res) {
              localstorageService().setItem(
                localStorageKeys.accessToken,
                res.data.Data.Access_token
              );
              localstorageService().setItem(
                localStorageKeys.refreshToken,
                res.data.Data.Access_token
              );
              localstorageService().setItem(
                localStorageKeys.accessTokenLifetime,
                res.data.Data.Expires_in
              );
              dispatch(
                setB2cClaimsResponse({
                  IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                  MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                  PasswordNotificationLastViewedDate:
                    res?.data?.Data?.PasswordNotificationLastViewedDate,
                  IsFirstTimeLogin: String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                  PhoneNumber: res?.data?.Data?.PhoneNumber,
                })
              );
              if (userHasEmail !== null) {
                dispatch(updateUserInfo(dispatch));
              }
              setUserHasEmail(true);
            }
          })
          .catch((res) => {
            handleServerError(undefined, setError)(res);
          });
      } else {
        if (userHasEmail !== null) {
          dispatch(updateUserInfo(dispatch, false));
        }
        setUserHasEmail(true);
      }
    } else {
      setUserHasEmail(false);
    }
  }, [userHasEmail]);

  const Stepper = userHasEmail ? verifyEmailFlowMap[step] : noEmailFlowMap[step];

  if (userHasEmail === null) {
    return null;
  }

  return (
    <>
      {userHasEmail && step > 0 ? (
        <div className="no-focus-outline">
          <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
            {t('StepperChangeSRText')}
          </span>
          <StepTracker
            activeIndex={userHasEmail ? step - 1 : step - 2}
            items={[
              {
                text: <Text field={fields.step1Title} />,
                textInProgressA11y: fields.step1TitleInProgressA11y.value,
                textCompletedA11y: fields.step1TitleCompletedA11y.value,
              },
              {
                text: <Text field={fields.step2Title} />,
                textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
                textCompletedA11y: fields.step2TitleCompletedA11y.value,
              },
            ]}
          />
        </div>
      ) : null}
      <Stepper
        setState={setState}
        state={state}
        t={t}
        fields={fields}
        toNextStep={toNextStep}
        toPreviousStep={toPreviousStep}
        toLastStep={toLastStep}
        userInfo={userInfo}
        dispatch={dispatch}
        toFirstStep={toFirstStep}
        onVerifyEmailAddress={resendVerificationEmail}
      />
    </>
  );
};
export default withTranslation()(EmailVerification);
