import { useSelector } from 'react-redux';

import mediaSubTypes from '../constants/mediaSubTypes';

const useSelectedCard = () => {
  const { type, visibleId } = useSelector((state) => state.user.selectedCard);
  return useSelector((state) => {
    if (type === '') {
      return state.user.selectedCard;
    }
    if (type === mediaSubTypes.presto) {
      let faremedias = state.user.fareMedias.find((i) => i.visibleId === visibleId);
      if (faremedias === undefined) {
        return state.user.fareMedias[0];
      }
      return faremedias;
    }
    if (type === mediaSubTypes.anonymous) {
      return state.user.fareMedias.find((i) => i.visibleId === visibleId);
    }
    return state.user.contactlessMedias.find((i) => i.visibleId === visibleId);
  });
};

export default useSelectedCard;
