import { isJsonString } from './utils';
import apiResponses from '../constants/apiResponses';

export default function handleServerSuccessError(
  error,
  setIsError = () => {},
  setIsServerError,
  setInlineError
) {
  const parsedMessageObject = error && isJsonString(error) && JSON.parse(error);
  setIsError('');
  if (!parsedMessageObject || !parsedMessageObject.Description) {
    return;
  }

  if (setIsServerError && parsedMessageObject.Code === 'ValidateSecurityQA_FailedAccountLocked') {
    setIsServerError({ code: parsedMessageObject.Code, text: parsedMessageObject.Description });
    return;
  }
  if (setInlineError) {
    setInlineError('');
    if (
      parsedMessageObject.Code === 'Faremedia_Deactivated' ||
      parsedMessageObject.Code === 'Faremedia_Anonymous' ||
      parsedMessageObject.Code === 'AFMS-DAX-FE-0001' ||
      parsedMessageObject.Code === 'AFMS-DAX-FE-0006' ||
      parsedMessageObject.Code === apiResponses.securityWrongPassword ||
      parsedMessageObject.Code === apiResponses.securityUsernameAlreadyExists
    ) {
      setInlineError(parsedMessageObject.Description);
    } else {
      setIsError(parsedMessageObject.Description);
    }
  } else {
    setIsError(parsedMessageObject.Description);
  }
}
