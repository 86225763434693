import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { Header } from '../../molecules';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import '../../assets/scss/app.scss';
import './addCard.scss';
import { getQueryParams } from '../../services/utils';

const AddCard = (props) => {
  const [isPrestoTabClicked, showPrestoTab] = useState(true);
  const [isContactlessTabClicked, showContactlessTab] = useState(false);
  const [isInteracTabClicked, showInteracTab] = useState(false);
  const { fields } = props;
  const queryParams = getQueryParams(useLocation().search);

  function showTab(presto, contactless, interac) {
    showPrestoTab(presto);
    showContactlessTab(contactless);
    showInteracTab(interac);
  }

  useEffect(() => {
    if (queryParams.addCardtype !== undefined && queryParams.addCardtype === 'credit') {
      showTab(false, true, false);
    }
    if (queryParams.contactLessCardDebit !== undefined && queryParams.contactLessCardDebit === 'false') {
      showTab(false, true, false);
    }
    if (queryParams.addCardtype !== undefined && queryParams.addCardtype === 'debit') {
      showTab(false, false, true);
    }
    if (queryParams.contactLessCardDebit !== undefined && queryParams.contactLessCardDebit === 'true') {
      showTab(false, false, true);
    }
  }, []);

  return (
    <div className="add-card-wrapper addCard">
      {useSelector((state) => state.user.isScreenHeaderVisible) ? (
        <>
          <Header>
            <Text field={props.fields.heading} />
          </Header>
          <div className="addCard-cardSwitcher" role="tablist">
            <a
              className={isPrestoTabClicked ? 'active' : 'inactive'}
              role="tab"
              aria-selected={isPrestoTabClicked}
              aria-controls="prestocard-tab"
              id="addprestocardtab"
              tabIndex="0"
              onClick={() => {
                showTab(true, false, false);
              }}
              onKeyPress={() => {
                showTab(true, false, false);
              }}
            >
              <div className="desktop" aria-label={props.fields.prestoTabTextA11y.value}>
                <Text field={props.fields.prestoTabText} />
              </div>
              <div className="mobile" aria-label={props.fields.prestoTabTextMobileA11y.value}>
                <Text field={props.fields.prestoTabTextMobile} />
              </div>
            </a>
            <a
              className={isContactlessTabClicked ? 'active' : 'inactive'}
              role="tab"
              aria-selected={isContactlessTabClicked}
              aria-controls="contactlesscard-tab"
              id="contactlesscardtab"
              tabIndex="0"
              onClick={() => {
                showTab(false, true, false);
              }}
              onKeyPress={() => {
                showTab(false, true, false);
              }}
            >
              <div className="desktop" aria-label={props.fields.contactlessTabTextA11y.value}>
                <Text field={props.fields.contactlessTabText} />
              </div>
              <div className="mobile" aria-label={props.fields.contactlessTabTextMobileA11y.value}>
                <Text field={props.fields.contactlessTabTextMobile} />
              </div>
            </a>
            {props.fields.showAddInteracCardTab.value ? (
              <a
                className={isInteracTabClicked ? 'active' : 'inactive'}
                role="tab"
                aria-selected={isInteracTabClicked}
                aria-controls="interaccard-tab"
                id="addinteraccardtab"
                tabIndex="0"
                onClick={() => {
                  showTab(false, false, true);
                }}
                onKeyPress={() => {
                  showTab(false, false, true);
                }}
              >
                <div className="desktop" aria-label={props.fields.interacTabTextA11y.value}>
                  <Text field={props.fields.interacTabText} />
                </div>

                <div className="mobile" aria-label={props.fields.interacTabTextMobileA11y.value}>
                  <Text field={props.fields.interacTabTextMobile} />
                </div>
              </a>
            ) : null}
          </div>
          {
            isContactlessTabClicked &&
            <div className="importantNote" aria-label={fields.importantNoteCreditA11y.value}>
              <RichText field={fields.importantNoteCredit} />
            </div>
          }
          {
            isInteracTabClicked &&
            <div className="importantNote" aria-label={fields.importantNoteDebitA11y.value}>
              <RichText field={fields.importantNoteDebit} />
            </div>
          }
        </>
      ) : null}
      {
        // eslint-disable-next-line no-nested-ternary
        isPrestoTabClicked ? (
          <Placeholder name="presto-add-card-presto" rendering={props.rendering} />
        ) : // eslint-disable-next-line no-nested-ternary
          isContactlessTabClicked ? (
            <Placeholder
              name="presto-add-card-contactless"
              rendering={props.rendering}
              isInterac={isInteracTabClicked}
            />
          ) : // eslint-disable-next-line no-nested-ternary
            isInteracTabClicked && props.fields.showAddInteracCardTab.value ? (
              <Placeholder
                name="presto-add-card-interac"
                rendering={props.rendering}
                isInterac={isInteracTabClicked}
              />
            ) : null
      }
    </div>
  );
};
export default AddCard;
