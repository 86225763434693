import React, { useEffect, useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import Debit from '../../assets/images/Debit.png';
import Heading from '../Heading';
import ParagraphContent from '../ParagraphContent';
import { Button, IconButton, Loader, MediaImage } from '../../atoms';
import './contactlessUnpaidFare.scss';
import {
  formatDate,
  formatDecimalWithCurrency,
  getQueryParams,
  redirectToUrl,
} from '../../services/utils';
import { setMultiUnpaidFareProps, updateRetryCounter } from '../../redux/contactless/actions';
import Transaction from '../TransactionHistoryContactless/Transaction';
import MEDIA_SUB_TYPES from '../../constants/mediaSubTypes';
import ContactlessUnpaidFareSuccess from './contactlessUnpaidFareSuccess';
import ContactlessUnpaidFareError from './contactlessUnpaidFareError';
import arrowLeft from '../../assets/images/arrow-left.svg';

const RETRY_LIMIT = 3;
const ContactlessUnpaidFare = ({ fields, t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const userInfoData = useSelector((state) => {
    if (state?.contactless?.userInfoData && state?.contactless?.userInfoData?.CardType) {
      return state.contactless.userInfoData;
    }
  });
  const shortenPan = userInfoData?.MaskedPan?.slice(userInfoData.MaskedPan.length - 4);
  const { transactions, textMapping } = useSelector(
    (reduxState) => reduxState.contactless.unpaidFares
  );
  const multipleUnpaidFareProps = useSelector(
    (reduxState) => reduxState.contactless.multipleUnpaidFareProps
  );
  const retryLimit = useSelector((reduxState) => reduxState.contactless.retryLimit);

  const backToHome = () => {
    redirectToUrl(fields?.BackToHomeLink?.value?.href, false);
  };

  const { currentSaleOrder, totalNoOfSaleOrder, unpaidSaleOrders, isSingleUnpaidMode } =
    useMemo(() => {
      let tempObj = {
        currentSaleOrder: null,
        totalNoOfSaleOrder: 0,
        unpaidSaleOrders: [],
        isSingleUnpaidMode: false,
      };
      // SINGLE UNPAID FARE MODE
      if (queryParams?.id) {
        let tempMultipleUnpaidFareProps = [...multipleUnpaidFareProps];
        let singleSaleOrder = tempMultipleUnpaidFareProps.find(
          (el) => el.id === queryParams.id && !el.isPaid
        );
        if (singleSaleOrder && singleSaleOrder.id) {
          tempObj.currentSaleOrder = transactions[0]?.payments.find(
            (el) => el.uuid === singleSaleOrder.id
          );
          tempObj.isSingleUnpaidMode = true;
        } else {
          //console.log('Single sale order Already Paid');
          backToHome();
        }
      } else {
        // MULTIPLE UNPAID FARE MODE
        let nextSaleOrder = multipleUnpaidFareProps.find((el) => !el.isPaid);
        if (multipleUnpaidFareProps.length > 1) {
          tempObj.totalNoOfSaleOrder = multipleUnpaidFareProps.length;
          tempObj.unpaidSaleOrders = multipleUnpaidFareProps.filter((el) => !el.isPaid);
          tempObj.isSingleUnpaidMode = false;
        } else {
          tempObj.isSingleUnpaidMode = true;
        }
        if (nextSaleOrder && nextSaleOrder.id) {
          tempObj.currentSaleOrder = transactions[0]?.payments.find(
            (el) => el.uuid === nextSaleOrder.id
          );
        } else {
          //console.log('All Sale Orders paid');
          backToHome();
        }
      }
      return tempObj;
    }, [multipleUnpaidFareProps]);

  const processNextUnpaidFare = () => {
    setShowSuccess(false);
    let tempMultipleUnpaidFareProps = [...multipleUnpaidFareProps];
    let currentSaleOrderIndex = tempMultipleUnpaidFareProps.findIndex(
      (el) => el.id === currentSaleOrder.uuid
    );
    tempMultipleUnpaidFareProps[currentSaleOrderIndex].isPaid = true;
    dispatch(setMultiUnpaidFareProps(tempMultipleUnpaidFareProps));
  };
  const payUnpaidFare = () => {
    setIsLoadingData(true);
    let payload = {
      SalesOrderUid: currentSaleOrder?.uuid,
      //   MediaInstanceId: userInfoData?.MediaKeyDetails?.map((el) => el.mediaInstanceId).join('|'),
      MediaInstanceId: transactions[0]?.media?.mediaInfo?.mediaInstanceId,
      MediaType: currentSaleOrder?.mediaType,
      MediaSubType: currentSaleOrder?.mediaSubType,
    };
    api
      .processContactlessUnpaidFare(payload)
      .then((response) => {
        //console.log(response);
        setIsLoadingData(false);
        if (response.status === 200 && response?.data?.Success) {
          setResponseData(response?.data);
          setShowSuccess(true);
        } else {
          dispatch(updateRetryCounter(retryLimit + 1));
          setShowError(true);
        }
      })
      .catch((error) => {
        //console.log(error);
        setIsLoadingData(false);
        setResponseData({});
        dispatch(updateRetryCounter(retryLimit + 1));
        setShowError(true);
      });
  };
  const retryCurrentPaymant = () => {
    setShowError(false);
    payUnpaidFare();
  };
  //console.log(fields, currentSaleOrder);

  const totalAmount = currentSaleOrder
    ? currentSaleOrder?.lines?.reduce((acc, val) => acc + val.amount, 0)
    : 0;
  const getUnpaidFareContent = () => (
    <div className="contactless-unpaid-transaction unpaidTransaction state-unpaidFare">
      <div className="unpaidTransaction-head">
        <div>{formatDate(currentSaleOrder.createdTime)}</div>
        <div>
          <span>
            {fields['Total Text']?.value} {formatDecimalWithCurrency(parseFloat(totalAmount / 100))}
          </span>
        </div>
      </div>
      <div className="unpaidTransaction-more">
        <div className="unpaidTransaction-transactions">
          {currentSaleOrder?.lines?.map((item) => (
            <Transaction unpaid textMapping={textMapping} data={item} fields={fields} />
          ))}
        </div>
      </div>
    </div>
  );
  if (showSuccess) {
    return (
      <ContactlessUnpaidFareSuccess
        fields={fields}
        processNextUnpaidFare={processNextUnpaidFare}
        unpaidSaleOrders={unpaidSaleOrders}
        isSingleUnpaidMode={isSingleUnpaidMode}
        backToHome={backToHome}
        responseData={responseData}
        currentSaleOrder={currentSaleOrder}
        t={t}
      />
    );
  }
  if (showError) {
    return (
      <ContactlessUnpaidFareError
        fields={fields}
        retryCurrentPaymant={retryCurrentPaymant}
        backToHome={backToHome}
        isThresholdReached={retryLimit >= RETRY_LIMIT}
        getUnpaidFareContent={getUnpaidFareContent}
      />
    );
  }
  if (!currentSaleOrder) {
    return null;
  }
  return (
    <div>
      <div className="contactless-unpaid-fare--back">
        <IconButton
          onClick={backToHome}
          icon={arrowLeft}
          iconAlt={fields?.BackToHomeButtonText?.value}
        />
        <a tabIndex="0" role="button" onKeyPress={() => null} onClick={backToHome}>
          <Text field={{ value: fields?.BackToHomeButtonText?.value }} />
        </a>
      </div>
      <div className="contactless-unpaid-fare">
        <div className="contactless-unpaid-fare-header">
          <Heading
            fields={{ Title: { value: 'Unpaid Fare' }, ID: { value: 'unpaid-fare-title' } }}
          />
          {!isSingleUnpaidMode ? (
            <ParagraphContent
              fields={{
                Description: {
                  value: `<p class="unpaid-fare-count">${fields['Multiple Fares Description']?.value
                    .replace('{CurrentSalesOrderCount}', unpaidSaleOrders.length)
                    .replace('{SalesOrderCount}', totalNoOfSaleOrder)}</p>`,
                },
              }}
            />
          ) : null}
        </div>
        {getUnpaidFareContent()}
        <div className="pay-unpaid-fare-container">
          <div className="pay-unpaid-fare">
            <div className="pay-unpaid-fare--title">
              <span id="message">{fields['Card Title'].value}</span>
            </div>
            <ParagraphContent
              fields={{
                CssClass: { value: 'pay-unpaid-fare--desc' },
                Description: fields['Card Body Text'],
              }}
            />
            <div className="pay-unpaid-fare--info">
              {/* <MediaImage cardImgSrc={Debit} sm cardImgAlt="card" /> */}
              <img src={Debit} className="mediaImage-sm" alt="" />
              <div className="masked-card">
                {MEDIA_SUB_TYPES[userInfoData.CardType]}...{shortenPan}
              </div>
            </div>
          </div>
          <div className="pay-unpaid-fare-footer">
            <Button onClick={() => payUnpaidFare()}>
              <Text field={fields['Pay Now Button Text']} />
            </Button>
          </div>
        </div>
      </div>
      {isLoadingData ? <Loader message={fields['Loading Text'].value} /> : null}
    </div>
  );
};

export default withTranslation()(ContactlessUnpaidFare);
