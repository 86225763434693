import React from 'react';

import { useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, PrintButton } from '../../atoms';
import { Header, Success, ValueRow } from '../../molecules';
import { showScreenHeader } from '../../redux/user/actions';
import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import cardTypes from '../../constants/monerisCardTypes';

const AddContactlessCard5 = (props) => {
  const { fields, state, toFirstStep } = props;
  const dispatch = useDispatch();
  const cardType = cardTypes.filter((i) => i.key === state.CardProvider).map((i) => i.value);
  const shortenPan = state.MaskedPan?.slice(state.MaskedPan.length - 4);
  return (
    <div>
      <Header centered aria-label={fields.headingA11y.value}>
        <Text field={fields.heading} />
      </Header>
      <Success
        title={fields.cardAddedSuccessfulMessage}
        Content={() => (
          <div className="autoload-setup autoload-success add-card-success">
            <div className="values-grid">
              <ValueRow
                left={<Text field={fields.reviewCardNumberText} />}
                right={`${cardType} ${shortenPan}`}
              />
              <ValueRow
                noPaddingBottom
                left={<Text field={fields.reviewNickNameText} />}
                right={state.NickName}
              />
            </div>
          </div>
        )}
      />
      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={() => redirectToUrl(routes.manageMyCards)}
            buttonTextA11y={fields.cardAddedBackButtonTextA11y.value}
          >
            <Text field={fields.cardAddedBackButtonText} />
          </Button>
          <Button
            onClick={() => {
              dispatch(showScreenHeader(true));
              toFirstStep();
            }}
            white
            firstOrder
            buttonTextA11y={fields.cardAddedAddCardButtonTextA11y.value}
          >
            <Text field={fields.cardAddedAddCardButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </div>
  );
};

export default AddContactlessCard5;
