import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleServerError from '../../services/handleServerError';
import { Note, Error, InlineButtonBlock, TextInput } from '../../molecules';

export default function ForgotUsername1({ fields, toNextStep, setState, setIsServerError, t }) {
  const [error, setIsError] = useState('');
  const [inlineError, setInlineError] = useState('');

  return (
    <>
      {error ? <Error title={error} /> : null}
      <div className="forgot-content">
        <div className="forgot-step">
          <Note Title={() => <Text field={fields.noteTitle} />}>
            <Text field={fields.headingNote} />
          </Note>
          <div className="forgot-text">
            <Text field={fields.headingDescription} />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              PrestoCardNumber: '',
              PrimaryPhoneNumber: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              PrestoCardNumber: Yup.string()
                .required(fields.enterCardNumber.value)
                .matches(/^[0-9]{17}$/, t('forgotUsernameValidationPrestoCard')),
              PrimaryPhoneNumber: Yup.string()
                .required(fields.enterPhoneNumber.value)
                .matches(/^[0-9]{10}$/, t('forgotUsernameValidationPhoneNumber')),
            })}
            onSubmit={(values) => {
              api.getSecurityQuestions(values).then(({ data }) => {
                if (data.Success) {
                  setState({ ...values, questions: data.securityQuestion });
                  toNextStep();
                } else {
                  handleServerSuccessError(data.Error, setIsError, null, setInlineError);
                }
              }, handleServerError(setIsServerError, setIsError));
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="PrestoCardNumber"
                  errors={errors}
                  touched={touched}
                  label={fields.cardNumberLabel}
                  length={17}
                  serverError={inlineError}
                />

                <TextInput
                  name="PrimaryPhoneNumber"
                  errors={errors}
                  touched={touched}
                  label={fields.phoneNumberLabel}
                  length={17}
                />

                <InlineButtonBlock type="submit" buttonText={fields.buttonContinue} />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
