import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { Loader } from '../../atoms';
import { Header } from '../../molecules';
import { hasAnyCard, getQueryParams } from '../../services/utils';
import '../../assets/scss/app.scss';
import './loadMyCard.scss';

const LoadMyCard = (props) => {
  const isScreenHeaderVisible = useSelector((reduxState) => reduxState.user.isScreenHeaderVisible);
  const [isTabClicked, showTab] = useState(false);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const authenticated = useSelector((state) => state.user && state.user.authenticated);

  const userInfo = useSelector((s) => s.user);

  let screenHeader = <Loader />;

  if (queryParams.success === 'true' || !isScreenHeaderVisible) {
    screenHeader = null;
  } else {
    screenHeader = (
      <div className="loadMyCard-wrapper">
        {hasAnyCard(userInfo) ? (
          <>
            <Header headerTitleA11y={props.fields.headingA11y.value}>
              <Text field={props.fields.heading} />
            </Header>
            <Placeholder name="presto-system-status-message" rendering={props.rendering} />
            <Placeholder name="presto-my-products-balance" rendering={props.rendering} />
            <div className="content-switcher">
              {authenticated ? (
                <>
                  <a
                    className={isTabClicked ? 'inactive' : 'active'}
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      showTab(!isTabClicked);
                    }}
                    onKeyPress={() => {
                      showTab(!isTabClicked);
                    }}
                  >
                    <Text field={props.fields.autoloadTab} />
                  </a>
                  <a
                    className={isTabClicked ? 'active' : 'inactive'}
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      showTab(!isTabClicked);
                    }}
                    onKeyPress={() => {
                      showTab(!isTabClicked);
                    }}
                  >
                    <Text field={props.fields.addFundsTab} />
                  </a>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <>
      {screenHeader}
      {
        // eslint-disable-next-line no-nested-ternary
        hasAnyCard(userInfo) ? (
          authenticated ? (
            !isTabClicked ? (
              <Placeholder name="presto-load-my-card-autoload" rendering={props.rendering} />
            ) : (
              <Placeholder name="presto-load-my-card-addfunds" rendering={props.rendering} />
            )
          ) : (
            <Placeholder name="presto-load-my-card-addfunds" rendering={props.rendering} />
          )
        ) : (
          <Placeholder
            name="presto-my-products-overview"
            rendering={props.rendering}
            linkableHeadingForNoMedia={false}
          />
        )
      }
    </>
  );
};

export default LoadMyCard;
