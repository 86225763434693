import React from 'react';

import { Step } from '../../atoms';
import './stepTracker.scss';

export default function StepTracker({ activeIndex, items, isUnlinkCard }) {
  return (
    <div
      className={isUnlinkCard ? `unlink-card-wrapper-steps${
        // eslint-disable-next-line no-nested-ternary
        items.length === 3 ? ' three-steps' : items.length === 2 ? ' two-steps' : ''
        }` : `wrapper-steps${
      // eslint-disable-next-line no-nested-ternary
      items.length === 3 ? ' three-steps' : items.length === 2 ? ' two-steps' : ''
      }`}
    >
      <ul className="steps print-hidden">
        {items.map(
          (
            {
              text,
              textA11y,
              labelA11y,
              textNotCompletedA11y,
              textInProgressA11y,
              textCompletedA11y,
              hasError = false,
              inProgress = false,
              StepA11y,
              OfA11y,
              CompleteA11y,
              NotCompleteA11y,
              CurrentStepA11y,
            },
            index
          ) => (
            <Step
              last={items.length === index + 1}
              key={text.props.field.value}
              total={items.length}
              index={index}
              activeIndex={activeIndex}
              text={text}
              textA11y={textA11y}
              labelA11y={labelA11y}
              StepA11y={StepA11y}
              OfA11y={OfA11y}
              CompleteA11y={CompleteA11y}
              NotCompleteA11y={NotCompleteA11y}
              CurrentStepA11y={CurrentStepA11y}
              textNotCompletedA11y={textNotCompletedA11y}
              textInProgressA11y={textInProgressA11y}
              textCompletedA11y={textCompletedA11y}
              hasError={hasError}
              inProgress={inProgress}
            />
          )
        )}
      </ul>
    </div>
  );
}
