import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Button, ButtonBlock, MediaImage } from '../../atoms';
import { Header, CardBlock, MediaImageList } from '../../molecules';
import {
  redirectToUrl,
  redirectToUrlInNewWindow,
  getAllMediasFromUserState,
} from '../../services/utils';
import { getCurrentBalance } from '../../redux/user/actions';
import {
  setEditCardMode,
  setActivateCardMode,
  setChangeCardPinMode,
  setRemoveCardMode
} from '../../redux/products/actions';
import MediaListItem from './mediaListItem';

import './manageMyProducts.scss';

const ManageMyProducts = ({ t, fields }) => {
  const isFirstCard = true;
  const dispatch = useDispatch();
  const allCards = getAllMediasFromUserState(useSelector((state) => state.user));
  const manageMyProduct = useSelector((reduxState) => reduxState.products.manageMyProduct);
  const activateCardMyProduct = useSelector(
    (reduxState) => reduxState.products.activateCardfromMyProducts
  );
  const [isAnonymous, setIsAnonymous] = useState(false);
  const NoCardMsg = () => (
    <CardBlock title={fields.noCardTitle?.value}>
      <Text field={fields.noCardText} />
      <ButtonBlock>
        <ButtonBlock right>
          <Button
            iconAfter="externalLinkIcon"
            iconSize="sm"
            iconColor="white"
            iconAriaLabel={t('opensNewWindowA11y')}
            onClick={() => redirectToUrlInNewWindow(fields.orderNewCardButton?.value.href)}
          >
            {fields.orderNewCardButton?.value.title}
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </CardBlock>
  );

  const CardsListing = () => (
    <>
      {allCards.map((card, index) => (
        index == "0" ? <MediaListItem {...{ card, fields, isFirstCard }} key={card.mediaId} /> : <MediaListItem {...{ card, fields }} key={card.mediaId} />
      ))}
    </>
  );

  useEffect(() => {
    allCards.forEach((card) => {
      if (card.type === 'presto' && card.currentBalance === null && card.visibleId) {
        dispatch(getCurrentBalance(card.visibleId));
      }
      if (card.type === 'anonymous') {
        setIsAnonymous(true);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(
      setEditCardMode({
        editCardDetailsEnabled: false,
        selectedCard: {},
      })
    );
    dispatch(
      setRemoveCardMode({
        removeCardEnabled: false,
        selectedCard: {},
      }),
    );
    dispatch(
      setChangeCardPinMode({
        changeCardPinEnabled: false,
        selectedCard: {},
      })
    );
    dispatch(
      setActivateCardMode({
        activateCardDetailsEnabled: false,
        selectedCard: {},
      })
    );
    if (manageMyProduct.addToActivate) {
      dispatch(
        setActivateCardMode({
          activateCardDetailsEnabled: true,
          selectedCard: {},
        })
      );
    }
  }, []);

  useEffect(() => {
    if (activateCardMyProduct) {
      dispatch(
        setActivateCardMode({
          activateCardDetailsEnabled: true,
          selectedCard: {},
        })
      );
    }
  }, [activateCardMyProduct]);

  const activateCardDetails = () => {
    dispatch(
      setActivateCardMode({
        activateCardDetailsEnabled: true,
        selectedCard: {},
      })
    );
  };
  return manageMyProduct?.removeCardEnabled || manageMyProduct?.editCardDetailsEnabled ||
    manageMyProduct?.changeCardPinEnabled ||
    manageMyProduct.activateCardDetailsEnabled ? null : (
    <div className="manageMyCards">
      <Header>
        <Text field={fields.heading} />
      </Header>
      <div>{allCards.length ? <CardsListing /> : <NoCardMsg />}</div>
      {!isAnonymous && (
        <>
          <CardBlock title={fields.addCardTitle?.value}>
            <Text field={fields.addCardText} />
            <MediaImageList
              all
              prestoCardAlt={fields.addCardPrestoCardAlt?.value}
              amexCardAlt={fields.addCardAmexCardAlt?.value}
              visaCardAlt={fields.addCardVisaCardAlt?.value}
              masterCardAlt={fields.addCardMasterCardAlt?.value}
            // interacCardAlt={fields.addCardInteracCardAlt?.value}
            />
            <ButtonBlock>
              <ButtonBlock right>
                <Button onClick={() => redirectToUrl(fields.addCardButton?.value.href)}>
                  {fields.addCardButton?.value.title}
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </CardBlock>
          <CardBlock title={fields.activateCardTitle?.value}>
            <div className="manageMyCards-mediaWithText">
              <div className="manageMyCards-mediaWrapper">
                <MediaImage big prestoCard />
              </div>
              <div>
                <Text field={fields.activateCardText} />
              </div>
            </div>

            <ButtonBlock>
              <ButtonBlock right>
                <Button
                  // iconAfter="externalLinkIcon"
                  iconSize="sm"
                  iconColor="white"
                  iconAriaLabel={t('opensNewWindowA11y')}
                  onClick={() => activateCardDetails()}
                >
                  {fields.activateCardButton?.value.title}
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </CardBlock>
        </>
      )}
    </div>
  );
};

export default withTranslation()(ManageMyProducts);
