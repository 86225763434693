import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import TransactionGridItem from './TransactionGridItem';
import TransactionFilters from './TransactionFilters';
import CardVerification from './CardVerification';
import UnpaidFaresAlert from './UnpaidFaresAlert';
import VerifyCardInteracAlert from './VerifyCardInteracAlert';
import filterIcon from '../../assets/images/Filter.svg';
import expandIcon from '../../assets/images/down.svg';
import calendarIcon from '../../assets/images/Calendar.svg';
import creditCardIcon from '../../assets/images/credit_card.svg';
import searchIcon from '../../assets/images/search_empty.svg';
import api from '../../services/api';
import {
  shallowObjEqual,
  getLanguageAtsId,
  isOneOf,
  redirectToUrl,
  isFrench,
  subtractDays,
  getQueryParams,
  localstorageService,
  downloadCsvFile,
} from '../../services/utils';
import routes from '../../constants/routes';
import mediaSubTypes from '../../constants/mediaSubTypes';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import {
  IconButton,
  ButtonBlock,
  Button,
  IconWithText,
  ExportButton,
  PrintButton,
  PrestoLink,
} from '../../atoms';
import { Error } from '../../molecules';

import './contactlessTransactionHistory.scss';
import localStorageKeys from '../../constants/localStorageKeys';
import handleServerError from '../../services/handleServerError';
import mediaTypes from '../../constants/mediaTypes';
import { selectCard } from '../../redux/user/actions';

const PERIOD_IDS = {
  lastTwoYears: '1',
  currentMont: '2',
  lastMonth: '3',
  monthBeforeLastMonth: '4',
  specificDateRange: '5',
};
const ITEMS_PER_PAGE = 10;

const TransactionHistoryContactless = ({ fields, t }) => {
  const [defaultFiltersState, setDefaultFiltersState] = useState({
    FromDate: subtractDays(31),
    ToDate: new Date(),
    PeriodId: PERIOD_IDS.currentMont,
  });
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isFilters, setIsFilters] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [items, setItems] = useState({ payments: [] });
  const [isVerifyCardFlowVisible, setIsVerifyCardFlowVisible] = useState(false);
  const [isInteracVerifyCardFlowVisible, setIsInteracVerifyCardFlowVisible] = useState(false);
  const [paymentsToVerify, setPaymentsToVerify] = useState([]);
  const [sorting, setSorting] = useState('DESC');
  const [filters, setFilters] = useState(defaultFiltersState);
  const [finalFilters, setFinalFilters] = useState();
  const [updatingHistory, setUpdatingHistory] = useState(false);
  const [showingMoreItems, setShowingMoreItems] = useState(false);
  const selectedCard = useSelector((state) => state.user.selectedCard);
  const [textMapping, setTextMapping] = useState({ textMapping: [] });
  const [isCardBlocked, setIsCardBlocked] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const isInteracCard = selectedCard.type === mediaSubTypes.OPENP_INTR;
  const [csvHeader, setCsvHeader] = useState('');
  const configKeys = useSelector((state) => state.settings.configKeys);
  const langCode = isFrench();
  const [error, setError] = useState('');
  const queryParams = getQueryParams(useLocation().search);
  const dispatch = useDispatch();
  function clearFilters() {
    setFilters(defaultFiltersState);
  }
  useEffect(() => {
    if (langCode) {
      const frenchheader = configKeys
        .filter((item) => item.key === 'ContactlessTxHistoryFrenchHeaders')
        .map((config) => config.setting);
      setCsvHeader(frenchheader);
    } else {
      const englishheader = configKeys
        .filter((item) => item.key === 'ContactlessTxHistoryEnglishHeaders')
        .map((config) => config.setting);
      setCsvHeader(englishheader);
    }
  }, []);
  function getCopyOfFilters() {
    const copyFilters = { ...filters };

    if (copyFilters.PeriodId && copyFilters.PeriodId !== PERIOD_IDS.specificDateRange) {
      if (copyFilters.PeriodId === PERIOD_IDS.lastTwoYears) {
        copyFilters.FromDate = moment()
          .utc()
          .clone()
          .subtract(t('travelHistoryDays'), t('travelHistoryType'));
        copyFilters.ToDate = moment().utc();
      } else if (copyFilters.PeriodId === PERIOD_IDS.currentMont) {
        copyFilters.FromDate = moment().utc().clone().startOf('month');
        copyFilters.ToDate = moment().utc();
      } else if (copyFilters.PeriodId === PERIOD_IDS.lastMonth) {
        copyFilters.FromDate = moment().utc().clone().subtract(1, 'month').startOf('month');
        copyFilters.ToDate = moment().utc().clone().subtract(1, 'month').endOf('month');
      } else if (copyFilters.PeriodId === PERIOD_IDS.monthBeforeLastMonth) {
        copyFilters.FromDate = moment().utc().clone().subtract(2, 'month').startOf('month');
        copyFilters.ToDate = moment().utc().clone().subtract(2, 'month').endOf('month');
      }
    }

    return copyFilters;
  }

  function getDate(type) {
    let copyFilters;

    if (!finalFilters) {
      copyFilters = getCopyOfFilters();
      setFinalFilters(copyFilters);
    } else {
      copyFilters = finalFilters;
    }
    if (type === 'START') {
      return showingMoreItems
        ? copyFilters.FromDate
        : moment(copyFilters.FromDate).startOf('day').toDate().toISOString();
    }
    return showingMoreItems
      ? copyFilters.ToDate
      : moment(copyFilters.ToDate).endOf('day').toDate().toISOString();
  }
  function fetchPaymentsToVerify() {
    api
      .getPaymentsToVerify({
        MediaReferenceId: selectedCard.mediaId,
        CardType: selectedCard.type,
        Language: getLanguageAtsId(),
        //StartTime: getDate('START'),
        //EndTime: getDate('END'),
      })
      .then((res) => {
        if (res.data.PaymentsToVerify?.length) {
          setPaymentsToVerify(res.data.PaymentsToVerify);
          localstorageService().setItem(
            localStorageKeys.paymentsToVerifyData,
            JSON.stringify(res.data.PaymentsToVerify)
          );
          if (isInteracCard) {
            setIsInteracVerifyCardFlowVisible(true);
          }
        }
      })
      .catch(() => {
        setHasConnectionError(true);
      });
  }

  function getTransactionCasesInterac(data) {
    if (
      isOneOf(
        ['DEBT_BELOW_THRESHOLD', 'DEBT_ABOVE_THRESHOLD', 'DEBT_WRITTEN_OFF', 'DEBT_INCURRED'],
        data.debtStatus
      ) &&
      data.accountType !== 'CUSTOMER_INITIATED_DEBT_RECOVERY'
    ) {
      return true;
    }

    return false;
  }

  function calculateTotalUnpaidFaresForInterac(transactions) {
    let sumOfUnpaidFares = 0;
    if (transactions && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i += 1) {
        for (let j = 0; j < transactions[i].payments.length; j += 1) {
          sumOfUnpaidFares += Number.parseInt(
            transactions[i].payments[j].lines.reduce((acc, val) => acc + val.amount, 0),
            10
          );
        }
      }
    }
    return sumOfUnpaidFares;
  }

  function verifyCardInterac(transactions) {
    if (transactions && transactions.length > 0) {
      let unpaidTransactions = [];
      for (let i = 0; i < transactions.length; i += 1) {
        for (let j = 0; j < transactions[i].payments.length; j += 1) {
          if (getTransactionCasesInterac(transactions[i].payments[j])) {
            unpaidTransactions.push(transactions[i]);
          }
        }
      }
      if (unpaidTransactions && unpaidTransactions.length > 0) {
        let sumOfUnpaidFares = calculateTotalUnpaidFaresForInterac(unpaidTransactions);
        setIsInteracVerifyCardFlowVisible(sumOfUnpaidFares === 0);
      }
    }
  }

  function updateHistory() {
    let copyFilters;

    if (!finalFilters) {
      copyFilters = getCopyOfFilters();
      setFinalFilters(copyFilters);
    } else {
      copyFilters = finalFilters;
    }

    setIsLoadingData(true);

    const checkIsCardBlocked = (data) => {
      const mediaInfo = data.media.mediaInfo;

      return (
        isOneOf(
          ['FAILED_TOO_MANY_INSPECTIONS', 'CHARGEBACK_FRAUD', 'BACK_OFFICE_OVERRIDE', 'SUSPECTED_COUNTERFEIT'],
          mediaInfo.statusReason
        ) && mediaInfo.mediaStatus === 'HOTLISTED'
      );
    };

    api
      .getContactlessTransactionHistory({
        MediaReferenceId: selectedCard.mediaId,
        CardType: selectedCard.type,
        Records: ITEMS_PER_PAGE,
        SortDescending: sorting === 'DESC',
        Language: getLanguageAtsId(),
      }).then((res) => {
        setIsLoadingData(false);
        const transactionItems = res.data.transactions[0];
        const transactionItemsnew = res.data.textMapping;
        if (isInteracCard && transactionItems) {
          verifyCardInterac(res.data.transactions);
        }

        if (transactionItems) {
          if (checkIsCardBlocked(transactionItems)) {
            setIsCardBlocked(true);
            selectedCard.status = 'blocked';
            dispatch(selectCard(selectedCard));
          }
        }
      })
      .catch(() => {
        setHasConnectionError(true);
        setIsLoadingData(false);
      });

    api
      .getContactlessTransactionHistory({
        MediaReferenceId: selectedCard.mediaId,
        CardType: selectedCard.type,
        StartTime: showingMoreItems
          ? copyFilters.FromDate
          : moment(copyFilters.FromDate).startOf('day').toDate().toISOString(),
        EndTime: showingMoreItems
          ? copyFilters.ToDate
          : moment(copyFilters.ToDate).endOf('day').toDate().toISOString(),
        Records: ITEMS_PER_PAGE,
        SortDescending: sorting === 'DESC',
        Language: getLanguageAtsId(),
      })
      .then((res) => {
        setIsLoadingData(false);

        setHasMoreItems(res.data.HasMoreItems);

        if (res.data && !res.data.transactions) {
          setHasConnectionError(true);
          return;
        }

        const transactionItems = res.data.transactions[0];
        const transactionItemsnew = res.data.textMapping;
        if (isInteracCard && transactionItems) {
          verifyCardInterac(res.data.transactions);
        }

        if (transactionItems) {
          /*if (checkIsCardBlocked(transactionItems)) {
            setIsCardBlocked(true);
            selectedCard.status = 'blocked';
            dispatch(selectCard(selectedCard));
          }*/

          // setTextMapping(res.data.textMapping);
          if (showingMoreItems) {
            const mergedListOfItems = { ...items };
            const mergedtextmapping = { ...textMapping };
            const newItemsmapping = { ...transactionItemsnew };
            const newItems = [...transactionItems.payments];
            const newServicemapping = { ...transactionItemsnew.serviceProvider };

            if (newItems.length > 10) {
              if (sorting === 'DESC') {
                newItems.pop();
                newItemsmapping.pop();
              } else {
                newItems.shift();
                newItemsmapping.shift();
              }
            }
            const _ = require('lodash');

            mergedtextmapping.locations = _.merge(
              mergedtextmapping.locations,
              newItemsmapping.locations
            );

            mergedListOfItems.payments = [...mergedListOfItems.payments, ...newItems];

            mergedtextmapping.serviceProvider = {
              ...mergedtextmapping.serviceProvider,
              ...newServicemapping,
            };

            setItems(mergedListOfItems);
            setTextMapping(mergedtextmapping);
          } else {
            setItems(transactionItems);
            setTextMapping(transactionItemsnew);
          }
        } else {
          setItems({ payments: [] });
          setTextMapping({ textMapping: [] });
        }
      })
      .catch(() => {
        setHasConnectionError(true);
        setIsLoadingData(false);
      });
  }
  const exportCsv = (customerData, fileName) => {
    if (customerData && customerData.length) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      let headers = customerData[0];
      csvContent += `${headers}\r\n`;
      let rowData = customerData.slice(1, customerData.length - 1);
      csvContent += rowData.join('\n');
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('id', 'csv_download_link');
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      let elem = document.getElementById('csv_download_link');
      elem.parentNode.removeChild(elem);
    }
  };
  const onExport = () => {
    let copyFilters;

    if (!finalFilters) {
      copyFilters = getCopyOfFilters();
      setFinalFilters(copyFilters);
    } else {
      copyFilters = finalFilters;
    }
    let payload = {
      cardType: selectedCard?.type,
      StartTime: showingMoreItems
        ? copyFilters.FromDate
        : moment(copyFilters.FromDate).startOf('day').toDate().toISOString(),
      EndTime: showingMoreItems
        ? copyFilters.ToDate
        : moment(copyFilters.ToDate).endOf('day').toDate().toISOString(),
      Records: ITEMS_PER_PAGE,
      SortDescending: sorting === 'DESC',
      Language: getLanguageAtsId(),
      MediaReferenceId: selectedCard?.mediaId,
    };
    api
      .getExportContactlessTransactionHistory(payload)
      .then((response) => {
        // exportCsv(response?.data?.split('\r\n'), `tx_history_${selectedCard?.mediaId}.csv`);
        downloadCsvFile(csvHeader, response?.data, `tx_history_${selectedCard?.mediaId}.csv`);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  function loadMoreItems() {
    const copyFilters = getCopyOfFilters();

    const lastItem = items.payments[items.payments.length - 1];

    if (sorting === 'DESC') {
      copyFilters.ToDate = lastItem.createdTime;
    } else {
      copyFilters.FromDate = lastItem.createdTime;
    }

    setShowingMoreItems(true);
    setFinalFilters(copyFilters);
    setUpdatingHistory(true);
  }

  useEffect(() => {
    setFinalFilters(undefined);
    setUpdatingHistory(true);
    setShowingMoreItems(false);
  }, [sorting]);

  useDidMountEffect(() => {
    setFinalFilters(undefined);
    setHasFiltersApplied(!shallowObjEqual(defaultFiltersState, filters));
    setUpdatingHistory(true);
    setShowingMoreItems(false);
  }, [filters]);

  useEffect(() => {
    if (!updatingHistory) {
      return;
    }
    setUpdatingHistory(false);
    if (selectedCard.mediaType !== mediaTypes.CRDNC) updateHistory();
  }, [updatingHistory, showingMoreItems]);

  useEffect(() => {
    if (
      !isVerifyCardFlowVisible &&
      paymentsToVerify.length === 0 &&
      selectedCard.mediaType !== mediaTypes.CRDNC
    ) {
      fetchPaymentsToVerify();
    }
  }, [isVerifyCardFlowVisible]);

  useEffect(() => {
    //console.log('inside useeffect, Card Verf:' + queryParams.cardVerification + " isVerifyCardFlow: " + isVerifyCardFlowVisible + " isInteracCard: " + isInteracCard);
    if (queryParams.cardVerification === undefined && isVerifyCardFlowVisible && !isInteracCard) {
      setIsVerifyCardFlowVisible(false);
      const returnpath = `${routes.transactionHistory}?cardVerification=cardVerification`;
      redirectToUrl(
        `${routes.b2cRegisteredSignIn}?cardVerification=cardVerification&returnUrl=${returnpath}`
      );
    } else if (
      queryParams.cardVerification !== undefined &&
      queryParams.cardVerification === 'cardVerification'
    ) {
      api
        .getSTSCloudClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(
              localStorageKeys.stsAccessToken,
              res.data.Data.Access_token
            );
            // localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Access_token);
            // localstorageService().setItem(
            //   localStorageKeys.accessTokenLifetime,
            //   res.data.Data.Expires_in
            // );
            setIsVerifyCardFlowVisible(true);
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, [isVerifyCardFlowVisible]);

  if (isVerifyCardFlowVisible && !isInteracCard) {
    return (
      <CardVerification
        cardVerificationRequiredItems={paymentsToVerify}
        fields={fields}
        toggleVerificationFlow={setIsVerifyCardFlowVisible}
        setPaymentsToVerify={setPaymentsToVerify}
      />
    );
  }

  return (
    <>
      {isInteracVerifyCardFlowVisible ? (
        <VerifyCardInteracAlert fields={fields} />
      ) : (
        <UnpaidFaresAlert
          fields={fields}
          isInteracCard={isInteracCard}
          reloadUnpaidFare={hasFiltersApplied}
          // startTime={getDate('START')}
          // endTime={getDate('END')}
        />
      )}

      {isCardBlocked ? (
        <div className="content-error-block " role="alert">
          <div className="contactlessTransactionHistory-unpaidFare-error">
            <div title={fields.verifyCardAlertTitle?.value} className="content-error-block-title" noMargin>
              <RichText field={fields.blockedCardErrorTitle} />
            </div>
            <div className="content-error-block-text">
              {fields.blockedCardErrorText.value}&nbsp;
              <PrestoLink field={fields.blockedCardErrorTextMoreLink} />
            </div>
          </div>
        </div>
      ) : null}

      <div className="contactlessTransactionHistory">
        {!isInteracCard && paymentsToVerify?.length ? (
          <div className={`content-error-block`} role="alert">
            <div
              title={fields.verifyCardAlertTitle?.value}
              className="content-error-block-title"
              noMargin
            >
              {fields.verifyCardAlertTitle?.value}
            </div>
            <div className="content-error-block-text">
              <RichText field={fields.verifyCardAlertText} />
            </div>
            <IconWithText text={selectedCard?.nickName} iconSrc={creditCardIcon} isBold />
            <ButtonBlock>
              <ButtonBlock right>
                <Button
                  onClick={() => {
                    setIsVerifyCardFlowVisible(true);
                    //const returnpath = (`${routes.transactionHistory}?cardVerification=cardVerification`);
                    //redirectToUrl(`${routes.b2cRegisteredSignIn}?returnUrl=${returnpath}`);
                  }}
                >
                  <Text field={fields.verifyCardAlertButton} />
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </div>
        ) : null}
        <div className="contactlessTransactionHistory-buttons">
          <IconButton
            onClick={() => setSorting(sorting === 'ASC' ? 'DESC' : 'ASC')}
            className="contactlessTransactionHistory-buttons-sorting desktopOnly"
            icon={calendarIcon}
          >
            {sorting === 'ASC' ? (
              <Text field={fields.sortOldestLabel} />
            ) : (
              <Text field={fields.sortLatestLabel} />
            )}
          </IconButton>
          <IconButton
            onClick={() => setIsFilters(!isFilters)}
            className="contactlessTransactionHistory-buttons-filters"
            icon={filterIcon}
            ariaExpanded={isFilters}
          >
            <Text field={fields.filterLabel} />
          </IconButton>
          <IconButton
            onClick={() => setIsExpand(!isExpand)}
            className="contactlessTransactionHistory-buttons-expand"
            icon={expandIcon}
            ariaLabel={isExpand ? fields.collapseLabelA11y.value : fields.expandLabelA11y.value}
            ariaExpanded={isExpand}
          >
            {isExpand ? <Text field={fields.collapseLabel} /> : <Text field={fields.expandLabel} />}
          </IconButton>
        </div>
        {isFilters ? (
          <TransactionFilters
            setIsFilters={setIsFilters}
            clearFilters={clearFilters}
            filters={filters}
            setFilters={setFilters}
            fields={fields}
            t={t}
          />
        ) : null}
        {hasFiltersApplied ? (
          <div className="contactlessTransactionHistory-activeFilters">
            <p className="contactlessTransactionHistory-activeFilters-text">
              <Text field={fields.filterAppliedLabel} />
            </p>
            <button
              className="contactlessTransactionHistory-activeFilters-clear"
              onClick={clearFilters}
            >
              <Text field={fields.clearFiltersLabel} />
            </button>
          </div>
        ) : null}
        <div className="contactlessTransactionHistory-grid">
          {items.payments.map((i) => (
            <TransactionGridItem
              key={i.acquirerSalesOrderId}
              fields={fields}
              textMapping={textMapping}
              data={i}
              expand={isExpand}
              isInteracCard={isInteracCard}
            />
          ))}
        </div>
        {items.payments.length ? (
          <ButtonBlock>
            <ExportButton onClick={onExport} />
            <PrintButton onClick={() => window.print()} />
            <ButtonBlock right>
              {hasMoreItems ? (
                <Button
                  type="submit"
                  buttonTextA11y={fields.viewMoreButtonTextA11y?.value}
                  onClick={() => loadMoreItems()}
                >
                  <Text field={fields.viewMoreButtonText} />
                </Button>
              ) : null}
            </ButtonBlock>
          </ButtonBlock>
        ) : null}
        {items.payments && !items.payments.length && !isLoadingData && !hasConnectionError ? (
          <div className="contactlessTransactionHistory-grid no-transactions">
            <img src={searchIcon} alt="" />
            <p aria-label={fields.noTransactionHistoryTextA11y?.value}>
              <Text field={fields.noTransactionHistoryText} />
            </p>
          </div>
        ) : null}
        {hasConnectionError ? (
          <div className="contactlessTransactionHistory-grid no-connection">
            <div className="connection-title">
              <p aria-label={fields.connectionErrorTitleA11y} className="title">
                <Text field={fields.connectionErrorTitle} />
              </p>
              <p aria-label={fields.connectionErrorTextA11y}>
                <Text field={fields.connectionErrorText} />
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withTranslation()(TransactionHistoryContactless);
