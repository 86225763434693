export default {
  responses: {
    success: 'STS_I_1001',
    connectionErrorI: 'STS_I_1002',
    connectionErrorE: 'STS_E_1002',
    authenticationErrorI: 'STS_I_1003',
    authenticationErrorE: 'STS_E_1003',
    retryAttemptsExceededI: 'STS_I_1005',
    retryAttemptsExceededE: 'STS_E_1005',
    technicalError: 'STS_I_1010',
    noAssociatedMediasFound: 'STS_I_1004',
    associatedMediasEmpty: 'STS_E_1004',
    expiredCard: 'STS_E_1006',
    generalError: 'STS_E_1010',
    confirmationIdError: 'STS_E_1020',
    unauthorized: 'STS_E_401',
    forbidden: 'STS_E_403',
  },
  messages: {
    mediaNotFound: 'REJECTED_MEDIA_NOT_FOUND',
    mediaNotRegistered: 'REJECTED_MEDIA_NOT_REGISTERED_TO_ACCOUNT',
    mediaRegisteredToAccount: 'REJECTED_MEDIA_REGISTERED_TO_ACCOUNT',
    mediaRegisteredToAnotherAccount: 'REJECTED_MEDIA_REGISTERED_TO_ANOTHER_ACCOUNT',
  },
};
