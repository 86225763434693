import React, { useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import apiResponses from '../../constants/apiResponses';
import api from '../../services/api';
import { hasLongInputLabel } from '../../services/utils';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleServerError from '../../services/handleServerError';
import { CardBlock, InlineButtonBlock, TextInput, Error, Note, Header } from '../../molecules';
import { SubHeader } from '../../atoms';

export default function Password0({
  fields,
  toNextStep,
  t,
  state,
  setIsServerError,
  userInfo,
  setState,
}) {
  const [error, setIsError] = useState('');
  const [inlineError, setInlineError] = useState('');

  return (
    <div className="security-alerts">
      <div>
        <Header aria-label={fields.securityUpdateHeadingA11y.value} smallerFont>
          <Text field={fields.securityUpdateHeading} />
        </Header>
        <SubHeader aria-label={fields.securityUpdateHeadingPasswordA11y.value}>
          <RichText field={fields.securityUpdateHeadingPassword} />
        </SubHeader>
      </div>
      <CardBlock
        titleA11y={fields.passwordUpdateHeadingA11y?.value}
        title={fields.passwordUpdateHeading?.value}
      >
        {error ? <Error title={error} /> : null}

        <div className="password-info-title">
          <Text field={fields.passwordInfoTitle} />
        </div>
        <div className="password-info">
          <RichText field={fields.passwordInfo} />
        </div>
        <Note
          Title={() => (
            <span>
              <Text field={fields.noteTitle} />
              <br />
              <Text field={fields.noteNewPassword} />
              <br />
            </span>
          )}
        />

        <Formik
          enableReinitialize
          initialValues={{
            CurrentPassword: '',
            NewPassword: '',
            ConfirmNewPassword: '',
          }}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            CurrentPassword: Yup.string().required(fields.enterCurrentPassword?.value),
            NewPassword: Yup.string()
              .required(fields.enterNewPassword?.value)
              .min(8, t('forgotPasswordNewPasswordValidation'))
              .matches(
                /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])((?=.*\d)|(?=.*[@$!%*#?&\'\"<>()+,”]))[A-Za-z\d@$!%*#?&\'\"<>()+,”]{8,25}$/,
                t('forgotPasswordNewPasswordValidation')
              ),
            ConfirmNewPassword: Yup.string()
              .required(fields.enterConfirmPassword?.value)
              .oneOf([Yup.ref('NewPassword'), null], t('forgotPasswordPaswordsNotMatched')),
          })}
          onSubmit={(values) => {
            api
              .updatePassword({
                Password: values.CurrentPassword,
                NewPassword: values.NewPassword,
                CustomerId: userInfo.customerId,
              })
              .then(({ data }) => {
                if (data.Success) {
                  toNextStep();
                } else if (data.Error) {
                  setState({
                    ...state,
                    updateResult: 'notUpdated',
                  });
                } else {
                  handleServerSuccessError(data.Error, setInlineError);
                }
              }, handleServerError(setIsServerError, setIsError));
          }}
        >
          {({ errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="CurrentPassword"
                errors={errors}
                touched={touched}
                label={fields.currentPasswordLabel}
                labelA11y={fields.currentPasswordLabelA11y?.value}
                marginTopSpacing1
                withViewIcon
                showIconA11y={fields.showCurrentPasswordA11y.value}
                hideIconA11y={fields.hideCurrentPasswordA11y.value}
                serverError={
                  state.errorField?.Code === apiResponses.securityWrongPassword ? inlineError : null
                }
              />
              <TextInput
                name="NewPassword"
                errors={errors}
                touched={touched}
                label={fields.newPasswordLabel}
                labelA11y={fields.newPasswordLabelA11y.value}
                hasLongLabel={hasLongInputLabel(
                  fields.newPasswordLabel,
                  fields.confirmPasswordLabel
                )}
                length={25}
                withViewIcon
                showIconA11y={fields.showNewPasswordA11y.value}
                hideIconA11y={fields.hideNewPasswordA11y.value}
                className="new-password"
              />
              <TextInput
                name="ConfirmNewPassword"
                errors={errors}
                touched={touched}
                label={fields.confirmPasswordLabel}
                labelA11y={fields.confirmPasswordLabelA11y.value}
                hasLongLabel={hasLongInputLabel(
                  fields.newPasswordLabel,
                  fields.confirmPasswordLabel
                )}
                length={25}
                withViewIcon
                showIconA11y={fields.showConfirmNewPasswordA11y.value}
                hideIconA11y={fields.hideConfirmNewPasswordA11y.value}
                className="confirm-new-password"
              />
              <InlineButtonBlock
                type="submit"
                buttonTextA11y={fields.buttonSubmitA11y.value}
                buttonText={fields.buttonSubmit}
              />
            </form>
          )}
        </Formik>
      </CardBlock>
    </div>
  );
}
