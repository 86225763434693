import React from 'react';

import { useLocation } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { redirectToUrl } from '../../services/utils';
import { PrintButton, ButtonBlock, Button } from '../../atoms';
import { Success, Header, Error } from '../../molecules';

import './savedPaymentMethod.scss';

const RemoveMethodStep2 = ({ fields, state }) => {
  const isSuccess = state.isPaymentMethodRemoved;

  const location = useLocation();

  return (
    <>
      <Header centered>
        <Text field={fields.removeStepPageHeading} />
      </Header>

      {isSuccess ? (
        <div className="remove-saved-payment-success">
          <Success
            title={fields.paymentMethodRemovedSuccess}
            titleA11y={fields.paymentMethodRemovedSuccessA11y}
            contextText={fields.paymentMethodNote}
          />
        </div>
      ) : (
        <Error
          title={fields.paymentMethodRemovedError?.value}
          titleA11y={fields.paymentMethodRemovedErrorA11y?.value}
          text={
            state.errorDescription
              ? state.errorDescription
              : fields.paymentMethodRemovedErrorContent?.value
          }
          textA11y={
            state.errorDescription
              ? state.errorDescription
              : fields.paymentMethodRemovedErrorContentA11y?.value
          }
        />
      )}

      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            type="button"
            buttonTextA11y={fields.backButtonTextA11y.value}
            onClick={() => redirectToUrl(location.pathname, false)}
          >
            <Text field={fields.backButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default RemoveMethodStep2;
