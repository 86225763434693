import types from './types';

export function setTransitPasses(data) {
  return {
    type: types.SET_TRANSIT_PASSES,
    payload: {
      ...data,
    },
  };
}

export function setTransitAgencyLogos(data) {
  return {
    type: types.SET_TRANSIT_AGENCY_LOGOS,
    payload: {
      ...data,
    },
  };
}

export function setDiscountTypes(data) {
  return {
    type: types.SET_DISCOUNT_TYPES,
    payload: {
      ...data,
    },
  };
}

export function setEditCardMode(data) {
  return {
    type: types.SET_EDIT_CARD_MODE,
    payload: { ...data },
  };
}

export function setChangeCardPinMode(data) {
  return {
    type: types.SET_CHANGE_PIN,
    payload: { ...data },
  };
}

export function setRemoveCardMode(data) {
  return {
    type: types.SET_REMOVE_CARD,
    payload: { ...data },
  };
}

export function setActivateCardMode(data) {
  return {
    type: types.SET_ACTIVATE_CARD_MODE,
    payload: { ...data },
  };
}

export function setManageAutoRenewMode(data) {
  return {
    type: types.SET_MANAGE_AUTO_RENEW_MODE,
    payload: { ...data },
  };
}

export function setRedeemVoucherStepperBlock(data) {
  return {
    type: types.SET_REDEEMVOUCHER_STEPPER_BLOCK,
    payload: { ...data },
  };
}

export function setActivateCardMyProducts(payload) {
  return {
    type: types.SET_ACTIVATE_CARD_MYPRODUCTS,
    payload,
  };
}
