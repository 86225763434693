import React from 'react';
import './cardBlock.scss';

export default function CardBlock({
  children,
  title,
  cssClass = '',
  successIcon = false,
  errorIcon = false,
  icon,
  titleSectionRight,
  redeemVoucherTitle = false,
  subTitle = null,
  tabIndex,
}) {
  return (
    <div
      className={`cardBlockComponent ${cssClass} ${titleSectionRight ? 'cardBlockComponent-titleSection-right' : ''
        }`}
    >
      {title && (
        <h2
          className={`cardBlockComponent-title ${icon} ${successIcon && 'successIcon'} ${errorIcon && 'errorIcon'
            }`}
            {...(tabIndex !== undefined ? { tabIndex } : {})}
        >
          {title}
        </h2>
      )}
      {subTitle && <>{subTitle}</>}
      {redeemVoucherTitle && <div className="redeemvoucher-title">{redeemVoucherTitle}</div>}
      <div className="cardBlockComponent-titleSection-right-text">{titleSectionRight}</div>
      <div className="cardBlockComponent-content">{children}</div>
    </div>
  );
}
