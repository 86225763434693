import React from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Link as RouterLink } from 'react-router-dom';

import Icon from '../Icon';
import './prestoLink.scss';

const PrestoLink = ({
  field = '',
  className = '',
  image,
  imageClassName = '',
  children,
  onClickCustom,
  iconColor = '',
  siteName = '',
}) => {
  const isExternal = !field.value?.href.startsWith('/');
  const isContactless = siteName === 'contactless';
  return (
    <>
      {isExternal ? (
        <Link
          onClick={onClickCustom}
          field={field}
          className={className}
          aria-label={field.value?.text || ''}
        >
          {image && <Image media={image} className={imageClassName} loading="lazy" />}
          {children}
          {field.value?.text || ''}
          {!isContactless ? (
            <span className="external-image">
              <Icon
                iconName="externalLinkIcon"
                color={iconColor}
                size="xs"
                ariaLabel={field.value?.title || ''}
              />
            </span>
          ) : (
            ''
          )}
        </Link>
      ) : (
        <RouterLink
          onClick={onClickCustom}
          to={field.value?.href}
          className={className}
          aria-label={field.value?.title || field.value?.text || ''}
          target="_self"
        >
          {image && <Image media={image} className={imageClassName} loading="lazy" />}
          {children}
          {field.value?.text || ''}
        </RouterLink>
      )}
    </>
  );
};

export default PrestoLink;
