import React, { useRef, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Select, CardBlock, TextInput, TextArea } from '../../molecules';
import { Formik } from 'formik';
import { Checkbox, Button, Loader } from '../../atoms';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import api from '../../services/api';
import downIcon from '../../assets/images/down.svg';
import { useSelector } from 'react-redux';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import ReCaptchaFormField from '../shared/ReCaptchaFormField';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  updateRecaptchaToken,
} from '../../services/utils';
import './contactUsForm.scss';

const ContactUsForm = ({ fields, t }) => {
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const [captchaDisable, setCaptchaDisable] = useState(true);
  const user = useSelector((reduxState) => reduxState.user);
  const [isRevertBack, setRevertBack] = useState(false);
  const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
  const [loading, setLoading] = useState(false);
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);
  const onClickSelect = () => openDropdown(!isArrowIconClicked);
  const iconText = isArrowIconClicked ? t('expandA11y') : t('collapseA11y');
  const { datasource, contextItem } = fields && fields.data ? fields.data : {};
  let subjectOptions = [];
  const id = t('CreateFeedbackForm');
  let custId = user?.customerId;
  const comments = datasource.Comments.jss.value.split('{0}');
  const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/;


  datasource.children.map((data) => {
    let obj = { label: data.Key.jss.value, value: data.Value.jss.value };
    subjectOptions.push(obj);
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    const reCaptchaDisable = configKeys
      .filter((item) => item.key === 'GoogleRecaptchaV3.Disable')
      .map((config) => config.setting);
    if (reCaptchaDisable.toString() === 'true') {
      setCaptchaDisable(true);
    } else {
      setCaptchaDisable(false);
    }
  }, [configKeys]);

  const apiContactUs = (values) => {
    const payload = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.EmailAddress,
      Subject: values.subjectDropdown,
      Comments: values.CommentsQuestions,
      CustomerId: custId,
      IsRevertback: isRevertBack,
      FormId: id,
      GoogleToken: values.googleToken,
    };
    //console.log("recaptchaToken:  " + values.googleToken);
    api
      .getContactUsFormFeedback(payload)
      .then((response) => {
        if (response?.data?.Success) {
          setApiStatus({ submit: true });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <div className="contact-us-form-accordion">
      <button
        onClick={onClickSelect}
        aria-expanded={isArrowIconClicked}
        className="accordion-heading"
      >
        <p>
          <Text field={datasource.Title.jss} />
        </p>
        <img alt={iconText} className={isArrowIconClicked ? 'up' : ''} src={downIcon} />
      </button>
      {isArrowIconClicked && (
        <div className="accordion-content">
          {!apiStatus.submit ? (
            <CardBlock>
              <RichText field={datasource.Description.jss} />
              <Formik
                initialValues={{
                  FirstName: '',
                  LastName: '',
                  EmailAddress: '',
                  CommentsQuestions: '',
                  subjectDropdown: subjectOptions.length ? subjectOptions[0].value : '',
                  googleToken: '',
                }}
                validateOnBlur={true}
                validate={(values) => {
                  const errors = {};
                  if (!values.FirstName || values.FirstName.length > 25 ||
                    !ALPHANUMERIC_REGEX.test(values.FirstName)) {
                    errors.FirstName = datasource.FirstNameError.jss.value;
                  }
                  if (!values.LastName || values.LastName.length > 25 ||
                    !ALPHANUMERIC_REGEX.test(values.LastName)) {
                    errors.LastName = datasource.LastNameError.jss.value;
                  }
                  if (!values.EmailAddress) {
                    //EmailAddressErrorMessage
                    errors.EmailAddress = datasource.EmailAddressErrorMessage.jss.value;
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.EmailAddress)
                  ) {
                    errors.EmailAddress = t('updateEmailCheck');
                  }
                  if (!values.CommentsQuestions) {
                    errors.CommentsQuestions = datasource.CommentsErrorMessage.jss.value;
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  if (captchaDisable) {
                    apiContactUs(values);
                  } else {
                    updateRecaptchaToken(values, configKeys, (updatedValues) => apiContactUs(updatedValues));
                  }

                }}
              >
                {({ errors, values, touched, handleChange, handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id={id}>
                      <div className="contact-us-form">
                        <div className="parent-wrapper">
                          <div className="column-one">
                            <TextInput
                              type="text"
                              value={values.FirstName}
                              name="FirstName"
                              required={true}
                              errors={errors}
                              touched={touched}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              label={datasource.FirstName.jss}
                            />
                          </div>
                          <div className="column-two">
                            <TextInput
                              type="text"
                              value={values.LastName}
                              required={true}
                              name="LastName"
                              errors={errors}
                              touched={touched}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              label={datasource.LastName.jss}
                            />
                          </div>
                        </div>
                        <div className="parent-wrapper extra-margin">
                          <div className="column-one">
                            <TextInput
                              type="text"
                              value={values.EmailAddress}
                              name="EmailAddress"
                              required={true}
                              errors={errors}
                              touched={touched}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              label={datasource.EmailAddress.jss}
                            />
                          </div>
                          <div className="column-two">
                            <Select
                              label={datasource.Subject.jss.value}
                              name="subjectDropdown"
                              errors={errors}
                              touched={touched}
                              options={subjectOptions || []}
                              labelA11y={datasource.Subject.jss.value}
                            />
                          </div>
                        </div>
                        <div className="comments-questions">
                          <div className="comments-bold">{comments[0]}</div>
                          <div className="comments-not-bold">{comments[1]}</div>
                          {/* <TextInput
                            type="text"
                            value={values.CommentsQuestions}
                            name="CommentsQuestions"
                            required={true}
                            errors={errors}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            length={200}
                            label={comments[0]}
                          /> */}

                          <TextArea
                            name="CommentsQuestions"
                            errors={errors}
                            inputA11y={comments[0]}
                            touched={touched}
                            required={true}
                            // length={200}
                            value={values.CommentsQuestions}
                          />
                        </div>
                        <div className="checkbox-contactus-form">
                          <Checkbox
                            onChange={() => {
                              setRevertBack(!isRevertBack);
                            }}
                            checkboxA11y={datasource.CheckBoxText.jss.value}
                            checked={isRevertBack}
                          >
                            <Text field={datasource.CheckBoxText.jss} />
                          </Checkbox>
                        </div>
                        {!captchaDisable && <ReCaptchaFormField />}
                        <Button type="submit">
                          <Text field={datasource.SubmitCTA.jss} />
                        </Button>
                        <div className="contact-us-form-policy">
                          <RichText field={datasource.TermsAndCondition.jss} />
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </CardBlock>
          ) : (
            <CardBlock>{t('SuccessMessage')}</CardBlock>
          )}
        </div>
      )}
    </div>
  );
};
export default withTranslation()(ContactUsForm);