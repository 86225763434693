import types from './types';

export function setSettings(data) {
  return {
    type: types.SET_SETTINGS,
    payload: {
      ...data,
    },
  };
}
