import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CardListItem from './cardListItem';
import {
  redirectToUrl,
  redirectToUrlInNewWindow,
  getAllMediasFromUserState,
  sortItems,
} from '../../services/utils';
import AddCardImg from '../../assets/images/addCard.svg';
import { PrestoLink, ButtonBlock, Button, MediaImage } from '../../atoms';
import { MediaImageList, CardBlock } from '../../molecules';
import {
  getCurrentBalance,
  getCurrentPasses,
  setMediaCurrentPasses,
  setGoogleWalletData,
  fetchWalletCardDetails,
} from '../../redux/user/actions';
import api from '../../services/api';
import './myProductsOverview.scss';
import mediaTypes from '../../constants/mediaTypes';
import mediaSubType from '../../constants/mediaSubType';
const NO_OF_CARDS_TO_LOAD = 5;
const MyProductsOverview = ({ linkableHeadingForNoMedia = true, ...props }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const { selectedCard, contactlessMedias } = userInfo;
  const [startIndexOfCurrentSet, setStartIndexOfCurrentSet] = useState(0);
  const [endIndexOfCurrentSet, setEndIndexOfCurrentSet] = useState(NO_OF_CARDS_TO_LOAD);
  const allCards = getAllMediasFromUserState(useSelector((state) => state.user));
  const currentSetOfCards = allCards.slice(startIndexOfCurrentSet, endIndexOfCurrentSet);
  const totalViewableCards = allCards.slice(0, endIndexOfCurrentSet);
  const hasMoreCards = totalViewableCards.length < allCards.length;
  const hasAnyCard = allCards.length > 0 && allCards[0].mediaId !== '';
  const isLoading = allCards.length > 0 && allCards[0].mediaId === '';
  const isMobileWallet = selectedCard.mediaType === mediaTypes.CRDNC;
  const checkMediaType = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].hasOwnProperty('mediaType') || array[i].mediaType !== mediaTypes.CRDNC) {
        return false;
      }
    }
    return true;
  };
  const onlyGWCards = checkMediaType(allCards);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const handleMyProductsAPIClick = () => {
    if (isMobileWallet) {
      if (contactlessMedias[0].dpan === selectedCard.dpan && !selectedCard.cardNumber) {
        dispatch(fetchWalletCardDetails);
      } else {
        api
          .getStoredMediaTypeInSession({
            MediaType: selectedCard.mediaSubTypeWallet,
          })
          .then(({ res }) => {
            console.log('response', res);
          });
      }
    }
  };
  const CardsListing = () => (
    <>
      <div className="myProductsOverview-header">
        <h2 className="h2-title">
          {isMobileWallet || onlyGWCards ? (
            <PrestoLink
              field={props.fields.WalletHeadingLink}
              showTextWithChildren
              onClickCustom={handleMyProductsAPIClick}
            />
          ) : (
            <PrestoLink field={props.fields.headingLink} showTextWithChildren />
          )}
        </h2>
        <div className="myProductsOverview-headerIconsWrapper desktopOnly">
          <PrestoLink
            field={props.fields.primaryLink}
            className="myProductsOverview-headerIconLink"
            showTextWithChildren
          >
            <div className="prestoIcon">
              <MediaImage prestoCard small />
            </div>
          </PrestoLink>
          <PrestoLink
            field={props.fields.secondaryLink}
            className="myProductsOverview-headerIconLink"
            showTextWithChildren
          >
            <MediaImage cardImgSrc={AddCardImg} cardImgAlt="Add a Card icon" small />
          </PrestoLink>
        </div>
      </div>
      <ul className="myProductsOverview-list">
        {totalViewableCards.map((card) => {
          if (card.mediaType === mediaTypes.CRDNC) {
            return (
              <CardListItem
                card={card}
                {...props}
                activePassesForGoogleWallet={card.activePasses}
              />
            );
          }
          let activePasses = [];
          let pendingPasses = [];
          if (card?.passes?.length > 0) {
            let copy = [
              ...card.passes.map((el) => ({
                ...el,
                uniqueName: el.ProductServiceProvider.Name,
              })),
            ];
            let sortedpasses = copy.sort((a, b) =>
              a.uniqueName > b.uniqueName ? 1 : b.uniqueName > a.uniqueName ? -1 : 0
            );
            activePasses = sortedpasses.filter((el) => el.ActivationStatus === 0) || [];
            //  activePasses = active.reverse();
            pendingPasses = sortedpasses.filter((el) => el.ActivationStatus === 2) || [];
            //  pendingPasses = pending.reverse();
          }
          return (
            <CardListItem
              card={card}
              activePasses={activePasses}
              pendingPasses={pendingPasses}
              {...props}
              showTransitPass
            />
          );
        })}
      </ul>
      <ButtonBlock>
        <ButtonBlock right>
          {hasMoreCards ? (
            <Button
              onClick={() => {
                setStartIndexOfCurrentSet(startIndexOfCurrentSet + NO_OF_CARDS_TO_LOAD);
                setEndIndexOfCurrentSet(endIndexOfCurrentSet + NO_OF_CARDS_TO_LOAD);
              }}
            >
              <Text field={props.fields.viewAll} />
            </Button>
          ) : null}
          <div className="mobileOnly">
            <hr className="myProductsOverview-buttonsSeparator" />
            <Button white onClick={() => redirectToUrl(props.fields.secondaryLink?.value.href, false, true)}>
              {props.fields.secondaryLink?.value.text}
            </Button>
            <Button
              white
              iconAfter="externalLinkIcon"
              iconSize="sm"
              iconAriaLabel={props.t('opensNewWindowA11y')}
              onClick={() => redirectToUrlInNewWindow(props.fields.primaryLink?.value.href)}
            >
              {props.fields.primaryLink?.value.text}
            </Button>
          </div>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
  const NoCardsMsg = () => (
    <>
      <div className="myProductsOverview-header">
        <h2 className="h2-title">
          {linkableHeadingForNoMedia ? (
            <PrestoLink field={props.fields.headingLink} showTextWithChildren />
          ) : (
            props.fields.headingLink.value?.text
          )}
        </h2>
      </div>
      <CardBlock cssClass="text-center">
        <MediaImageList all showInteracCard />
        <div className="myProductsOverview-notificationText">
          <Text field={props.fields.noProductsMessage} />
        </div>
        <ButtonBlock>
          <ButtonBlock center>
            <Button onClick={() => redirectToUrl(props.fields.secondaryLink?.value.href, false, true)}>
              {props.fields.secondaryLink?.value.text}
            </Button>
            <Button
              firstOrder
              iconAfter="externalLinkIcon"
              iconSize="sm"
              iconColor="white"
              iconAriaLabel={props.t('opensNewWindowA11y')}
              onClick={() => redirectToUrlInNewWindow(props.fields.primaryLink?.value.href)}
            >
              {props.fields.primaryLink?.value.text}
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      </CardBlock>
    </>
  );
  useEffect(() => {
    let allpromises = [];
    currentSetOfCards.forEach((card, index) => {
      // if (card.type === 'presto' && card.currentBalance === null && card.visibleId) {
      //   dispatch(getCurrentBalance(card.visibleId));
      // }
      if (card.type === 'presto') {
        // dispatch(getCurrentPasses(card.visibleId));
        let promise = api.getAllProductsLoadedInMedia({ visibleId: card.visibleId });
        allpromises.push(promise);
      }
      if (card.type === 'anonymous') {
        // dispatch(getCurrentBalance(card.visibleId));
        setIsAnonymous(true);
      }
      if (index === currentSetOfCards.length - 1) {
        Promise.all(allpromises).then((responses) => {
          return Promise.all(
            responses.map((response, index) => {
              if (response.data.Success) {
                dispatch(
                  setMediaCurrentPasses({
                    visibleId: currentSetOfCards[index].visibleId,
                    passes: response.data.productInstances,
                  })
                );
              }
            })
          );
        });
      }
    });
  }, [endIndexOfCurrentSet]);

  // useEffect(() => {
  //   totalViewableCards.forEach((card) => {
  //     if (card.type === 'presto' && card.currentBalance === null && card.visibleId) {
  //       dispatch(getCurrentBalance(card.visibleId));
  //     }
  //   });
  // }, [numOfVisibleCards]);
  return (
    !isAnonymous && (
      <div className="myProductsOverview">
        {isLoading ? null : <>{hasAnyCard ? <CardsListing /> : <NoCardsMsg />}</>}
      </div>
    )
  );
};
export default withTranslation()(MyProductsOverview);
