import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { isFrench } from '../../services/utils';
import AccountSecurity0 from './AccountSecurity0';
import AccountSecurity1 from './AccountSecurity1';

import './accountSecurity.scss';

const stepsAccountSecurityMap = [AccountSecurity0, AccountSecurity1];

const AccountSecurity = ({ t, fields }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [step, setStep] = useState(0);
  const [state, setState] = useState({});
  const toNextStep = () => setStep(step + 1);
  const toPreviousStep = () => setStep(step - 1);
  const Stepper = stepsAccountSecurityMap[step];
  const [error, setIsError] = useState('');
  const language = isFrench();
  useEffect(() => {
    api.getSecurityQuestionsWithAnswers().then((response) => {
      if (response.data.Success) {
        setQuestions(
          response.data.Data.map((i) => ({
            label: i.Item1.Text,
            value: i.Item1.Id,
          }))
        );
        setAnswers(
          response.data.Data.map((i) => ({
            answer: i.Item2,
          }))
        );
      } else {
        handleServerSuccessError(response.data.Error, setIsError);
      }
    });
  }, [userInfo.userName, language]);

  return (
    <Stepper
      setState={setState}
      state={state}
      t={t}
      fields={fields}
      toNextStep={toNextStep}
      toPreviousStep={toPreviousStep}
      userInfo={userInfo}
      questions={questions}
      answers={answers}
      setQuestions={setQuestions}
      setAnswers={setAnswers}
      error={error}
      language={language}
    />
  );
};
export default withTranslation()(AccountSecurity);
