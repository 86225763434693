import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InlineError } from '../../atoms';
import './textArea.scss';

export default function TextArea({
  errors,
  touched,
  label,
  name,
  value,
  length,
  inputA11y,
  isDisabled = false,
  required = false,
}) {
  const error = touched && touched[name] && errors && errors[name];
  const valueObject = value ? { value } : {};

  return (
    <div className={`textareabox ${error ? 'textareabox-error' : ''}`}>
      {label ? (
        <label className={`text-area-label`} htmlFor={name}>
          <Text field={label} />
        </label>
      ) : null}

      <Field
        {...valueObject}
        className="textarea-field"
        component="textarea"
        name={name}
        maxLength={length}
        disabled={isDisabled}
        aria-label={inputA11y}
        aria-required={required}
      ></Field>

      {error ? <InlineError>{error}</InlineError> : null}
    </div>
  );
}
