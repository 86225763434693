import React, { useEffect } from 'react';

import api from '../../services/api';
import { localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';

const ReCaptchaFormField = () => {
  function AppendReCaptchaJavaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${localstorageService().getItem(
      localStorageKeys.reCaptchaKey
    )}`;
    document.body.appendChild(script);
  }

  useEffect(() => {
    if (!localstorageService() || !localstorageService().getItem(localStorageKeys.reCaptchaKey)) {
      api.getSetting({ settingName: 'GoogleRecaptchaV3.SiteKey' }).then((res) => {
        if (res.status === 200) {
          localstorageService().setItem(localStorageKeys.reCaptchaKey, res.data);
          AppendReCaptchaJavaScript();
        }
      });
    } else {
      AppendReCaptchaJavaScript();
    }
  }, []);

  return <div className="g-recaptcha" data-sitekey="reCaptchaKey" data-size="invisible" />;
};

export default ReCaptchaFormField;
