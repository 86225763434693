import React, { useRef, useEffect, useState } from 'react';

import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import useMedia from 'use-media';

import {
  formatDate,
  convertToDollars,
  formattoLocalDate,
  formatDecimalWithCurrency,
  indexOfSelectedCard,
  getStationNameFromParam,
  formatPrestoCardNumberA11y,
  formatPrestoCardNumber,
  redirectToUrl,
} from '../../services/utils';
import { MediaImage, PrestoLink, Loader } from '../../atoms';
import { Modal, Error } from '../../molecules';
import downIcon from '../../assets/images/down.svg';
import api from '../../services/api';
import mediaTypes from '../../constants/mediaTypes';
import mediaSubType from '../../constants/mediaSubType';
import { fetchPendingProducts, selectCard, setBalanceAutoLoadData } from '../../redux/user/actions';
import autorenewActivationStatus from '../../constants/autorenewActivationStatus';
import GoTransitAgencyImg from '../../assets/images/GO.svg';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import './balanceBlock.scss';
import routes from '../../constants/routes';

const maxVisiblePasses = 6;

const BalanceBlock = (props) => {
  const userInfo = useSelector((state) => state.user);
  const location = useLocation();
  const history = useHistory();
  const { fareMedias, contactlessMedias, selectedCard } = userInfo;
  const allCards = fareMedias.concat(contactlessMedias);
  const selectedCardIndex = indexOfSelectedCard(userInfo.fareMedias, selectedCard.visibleId);
  const [product, setProduct] = useState({ list: {} });
  const pendingProducts = useSelector((state) => state.user.pendingProducts) || {
    list: [],
    orders: [],
  };
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, false);
  const onClickSelect = () => openDropdown(!isArrowIconClicked);
  const [balance, setBalanceAutoload] = useState({ autoload: {} });
  const [autoloadStatusField, setAutoloadStatusField] = useState({ autoload: {} });
  const [isPendingFundsModalOpen, setIsPendingFundsModalOpen] = useState(false);
  const [isPendingPassesModalOpen, setIsPendingPassesModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logos = useSelector((state) => state.products.logos);
  const dispatch = useDispatch();
  const isMobile = useMedia({ maxWidth: '920px' });
  let deactivateCard = useSelector((reduxState) => reduxState.user.deactivateCardType);
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  let content;
  const isMobileWallet = selectedCard.mediaType === mediaTypes.CRDNC;
  const isAppleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.APPLE;
  const isGoogleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.GOOGLE;
  const checkMediaType = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].hasOwnProperty('mediaType') || array[i].mediaType !== mediaTypes.CRDNC) {
        return false;
      }
    }
    return true;
  };
  const onlyGWCards = checkMediaType(allCards);
  useEffect(() => {
    if (isMobileWallet) {
      const gWalletCard = contactlessMedias?.find(
        (card) => card.mediaType === mediaTypes.CRDNC && card.dpan === selectedCard.dpan
      );
      dispatch(selectCard({ ...gWalletCard }));
    }
  }, [isMobileWallet, contactlessMedias]);

  useEffect(() => {
    if (
      props?.fields?.TargetPath &&
      props?.fields?.TargetPath.some((item) => {
        const href = item?.fields['Target Link']?.value?.href;
        if (href) {
          const hrefLanguageMatch = href.match(/\/[a-z-]+/i);
          const hrefLanguagePrefix = hrefLanguageMatch ? hrefLanguageMatch[0] : '';
          const hrefWithoutLanguage = href.replace(hrefLanguagePrefix, '');
          const formattedPathname = location.pathname.replace(/%20/g, '-').toLowerCase();
          const finalPathname = formattedPathname.replace(/ /g, '-');
          return finalPathname.includes(hrefWithoutLanguage.toLowerCase());
        }
        return false;
      }) &&
      isMobileWallet
    ) {
      setIsLoading(true);
      redirectToUrl(routes.walletCardDetails);
    }
  }, [location.pathname, selectedCard]);

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      if (newLocation.pathname.includes(routes.walletCardDetails)) {
        setIsLoading(false);
      }
    });

    return () => {
      unlisten(); // Cleanup the listener to avoid memory leaks
    };
  }, [history]);

  useEffect(() => {
    let isGoogleCardNotSelected = selectedCard.mediaType !== mediaTypes.CRDNC;
    if (isGoogleCardNotSelected) {
      api.getProductsLoadedInMedia({ VisibleId: selectedCard.visibleId }).then((res) => {
        if (res.data.Success) {
          setProduct({
            list: res.data.productInstances,
          });
        }
      });

      dispatch(fetchPendingProducts(selectedCard.visibleId));
    }

    if (authenticated && isGoogleCardNotSelected) {
      api.getAutoloadSubscriptionForMedia({ VisibleId: selectedCard.visibleId }).then((res) => {
        if (res.data.Success) {
          setBalanceAutoload({
            autoload: res.data.SubscriptionList,
          });
          dispatch(setBalanceAutoLoadData(res.data.SubscriptionList));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!balance.autoload?.length) {
      setAutoloadStatusField(props.fields.autoloadSetUpLabel);
      return;
    }

    if (
      balance.autoload.some(
        (subscriptionItem) => subscriptionItem.ActivationStatus === autorenewActivationStatus.sent
      )
    ) {
      setAutoloadStatusField(props.fields.autoloadPendingLink);
    } else {
      setAutoloadStatusField(props.fields.autoloadOnLabel);
    }
  }, [balance]);

  const getActivationDate = (id) => {
    const activationDate =
      selectedCard.passes.hasOwnProperty(id) && selectedCard.passes[id].length > 0
        ? selectedCard.passes[id][0].activationDate
        : null;
    return activationDate;
  };
  const getExpirationDate = (id) => {
    const expirationDate =
      selectedCard.passes.hasOwnProperty(id) && selectedCard.passes[id].length > 0
        ? selectedCard.passes[id][0].expirationDate
        : null;
    return expirationDate;
  };

  const getLogoName = (id) => {
    const productId =
      selectedCard.passes.hasOwnProperty(id) && selectedCard.passes[id].length > 0
        ? selectedCard.passes[id][0].productId
        : null;
    const commutePlan = `COMMUTEPLAN${id}_${productId}`;
    return selectedCard.displayText[commutePlan];
  };
  function getLogoImageSrc(item) {
    // if (!logos) {
    //   return null;
    // }
    let logo = logos && logos[item.ProductServiceProvider.Id]?.Logo.Src;

    return logo ? logo : null;
  }
  const getTransitPassLogoForGoogleWallet = (id) => {
    const logoObj = Object.values(logos).find((obj) => obj.ServiceProviderId == id);
    return logoObj ? logoObj.Logo.Src : null;
  };
  if (isMobileWallet) {
    if (selectedCard.activePasses?.length === 1) {
      content = selectedCard.activePasses?.map((item) => {
        return (
          <div className="product">
            <div className="product-image">
              {getTransitPassLogoForGoogleWallet(item) ? (
                <img src={getTransitPassLogoForGoogleWallet(item)} alt={getLogoName(item)} />
              ) : (
                <Image media={props.fields.logo} loading="lazy" />
              )}
            </div>
            <div className="product-info">
              <div className="product-title">{getLogoName(item)}</div>
              <div className="product-description">
                {`${formattoLocalDate(getActivationDate(item), 'D MMM')} - ${formattoLocalDate(
                  getExpirationDate(item)
                )}`}
              </div>
            </div>
          </div>
        );
      });
    } else if (selectedCard.activePasses?.length === 2) {
      content = selectedCard.activePasses?.map((item) => {
        return (
          <div className="products">
            <div
              className={`product-image product-image-count-${
                selectedCard.activePasses?.length < 6 ? selectedCard.activePasses?.length : 6
              }`}
            >
              {getTransitPassLogoForGoogleWallet(item) ? (
                <img src={getTransitPassLogoForGoogleWallet(item)} alt={getLogoName(item)} />
              ) : (
                <Image media={props.fields.logo} loading="lazy" />
              )}
            </div>
            <div className="product-info">
              <div className="product-description">
                {`${formattoLocalDate(getActivationDate(item), 'D MMM')} - ${formattoLocalDate(
                  getExpirationDate(item)
                )}`}
              </div>
            </div>
          </div>
        );
      });
    } else if (selectedCard.activePasses?.length > 2) {
      content = selectedCard.activePasses?.slice(0, maxVisiblePasses).map((item) => {
        return (
          <div
            className={`product-image product-image-count-${
              selectedCard.activePasses?.length < 6 ? selectedCard.activePasses?.length : 6
            }`}
          >
            {getTransitPassLogoForGoogleWallet(item) ? (
              <img src={getTransitPassLogoForGoogleWallet(item)} alt={getLogoName(item)} />
            ) : (
              <Image media={props.fields.logo} loading="lazy" />
            )}
          </div>
        );
      });
    } else {
      content = (
        <div className="products-empty">
          <div className="balanceBlock-description">
            <RichText field={props.fields.transitPassesDescription} />
          </div>
        </div>
      );
    }
  } else if (product.list?.length === 1) {
    content = product.list.map((item) => {
      return (
        <div className="product">
          <div className="product-image">
            {getLogoImageSrc(item) ? (
              <img src={getLogoImageSrc(item)} alt={item.ProductName} />
            ) : (
              <Image media={props.fields.logo} loading="lazy" />
            )}
          </div>
          <div className="product-info">
            <div className="product-title">{item.ProductName}</div>
            <div className="product-description">
              {`${formatDate(item.ValidityStartDate, 'D MMM')} - ${formatDate(
                item.ValidityEndDate
              )}`}
            </div>
          </div>
        </div>
      );
    });
  } else if (product.list?.length === 2) {
    content = product.list.map((item) => {
      return (
        <div className="products">
          <div
            className={`product-image product-image-count-${
              product.list.length < 6 ? product.list.length : 6
            }`}
          >
            {getLogoImageSrc(item) ? (
              <img src={getLogoImageSrc(item)} alt={item.ProductName} />
            ) : (
              <Image media={props.fields.logo} loading="lazy" />
            )}
          </div>
          <div className="product-info">
            <div className="product-description">
              {`${formatDate(item.ValidityStartDate, 'D MMM')} - ${formatDate(
                item.ValidityEndDate
              )}`}
            </div>
          </div>
        </div>
      );
    });
  } else if (product.list?.length > 2) {
    content = product.list.slice(0, maxVisiblePasses).map((item) => {
      return (
        <div
          className={`product-image product-image-count-${
            product.list.length < 6 ? product.list.length : 6
          }`}
        >
          {getLogoImageSrc(item) ? (
            <img src={getLogoImageSrc(item)} alt={item.ProductName} />
          ) : (
            <Image media={props.fields.logo} loading="lazy" />
          )}
        </div>
      );
    });
  } else {
    content = (
      <div className="products-empty">
        <div className="balanceBlock-description">
          <RichText field={props.fields.transitPassesDescription} />
        </div>
        <PrestoLink field={props.fields.explorePassesLink} className="balanceBlock-link" />
      </div>
    );
  }
  const BalanceContentBlock = () => (
    <div className={`balanceBlock-component ${deactivateCard?.deactivateCardType ? 'hide' : ''}`}>
      <div className="balanceBlock">
        <div className="balanceBlock-left">
          <div className="balanceBlock-title">
            <Text field={props.fields.titleBalance} />
          </div>
          <div className="balanceBlock-wrapper">
            <div className="balanceBlock-balance">
              {isMobileWallet
                ? formatDecimalWithCurrency(convertToDollars(selectedCard.currentBalance))
                : formatDecimalWithCurrency(userInfo.fareMedias[selectedCardIndex]?.currentBalance)}
            </div>
            {isMobileWallet && (
              <>
                <div className="balanceBlock-mw-autoload">
                  {selectedCard.autoload
                    ? props.fields.autoloadOnLabel.value.text
                    : props.fields.walletCardAutoLoadOffLabel.value}
                </div>
                <div className="balanceBlock-mw-fareType">
                  {props.fields.walletCardFareTypeLabel.value} {selectedCard.fareType}
                </div>
                <div className="balanceBlock-mw-expiry">
                  {props.fields.walletCardFareTypeExpiryLabel.value}{' '}
                  {selectedCard.expiryDate === 'N/A'
                    ? selectedCard.expiryDate
                    : formattoLocalDate(selectedCard.expiryDate)}
                </div>
                {selectedCard.isOtherServiceProviderIdExists && (
                  <div className="balanceBlock-mw-note">
                    <RichText field={props.fields.walletCardFareTypeNotification} />
                  </div>
                )}
              </>
            )}
            {!isMobileWallet && authenticated && (
              <PrestoLink field={autoloadStatusField} className="balanceBlock-link" />
            )}
            {!isMobileWallet && pendingProducts.orders?.length > 0 ? (
              <div className="pending-link">
                <a
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => null}
                  onClick={() => setIsPendingFundsModalOpen(true)}
                  className="balanceBlock-link"
                >
                  <Text field={props.fields.pending} />
                  {formatDecimalWithCurrency(
                    pendingProducts.orders.reduce(
                      (sum, pendingOrder) => sum + pendingOrder.SubTotal,
                      0
                    )
                  )}
                </a>
              </div>
            ) : undefined}
          </div>
        </div>
        <div className="balanceBlock-right">
          <div className="balanceBlock-title">
            <div className="title">
              <Text field={props.fields.titleTransitPasses} />
            </div>
            {product.list?.length > 1 ? (
              <PrestoLink field={props.fields.viewLink} className="balanceBlock-link" />
            ) : undefined}
          </div>
          <div className="product-lists">{content}</div>
          {!isMobileWallet &&
            pendingProducts?.list?.length > 0 &&
            pendingProducts.list.slice(0, maxVisiblePasses).map((pendingProduct) => (
              <div className="pending-progress">
                <div className="pending-description balanceBlock-link">
                  <a
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => null}
                    onClick={() => setIsPendingPassesModalOpen(true)}
                    className="balanceBlock-link pending-link"
                  >
                    <Text field={props.fields.pending} /> {pendingProduct.ProductName}
                  </a>
                </div>
                <div className="pending-date product-description">
                  {`${formatDate(pendingProduct.ValidityStartDate, 'D MMM')} - ${formatDate(
                    pendingProduct.ValidityEndDate
                  )}`}
                </div>
              </div>
            ))}
        </div>
      </div>
      {userInfo.fareMedias[selectedCardIndex]?.tripDetails ? (
        <div className="balanceBlock-transit">
          <div className="transit-image">
            <MediaImage cardImgSrc={GoTransitAgencyImg} cardImgAlt="Go Transit Agency icon" />
          </div>
          <div className="transit-info">
            <div className="product-title">
              <Text field={props.fields.transitTrip} />
            </div>
            <div className="product-description">
              {`${getStationNameFromParam(
                userInfo.fareMedias[selectedCardIndex].tripDetails?.SourceStation
              )} - ${getStationNameFromParam(
                userInfo.fareMedias[selectedCardIndex].tripDetails?.DestinationStation
              )}`}
            </div>
          </div>
          <div className="transit-link">
            <PrestoLink field={props.fields.learnMoreLink} className="balanceBlock-link" />
          </div>
        </div>
      ) : null}
      {selectedCard?.defaultTripsData?.showDefaultTrips ? (
        <div className="balanceBlock-transit">
          <div className="transit-image">
            <MediaImage cardImgSrc={GoTransitAgencyImg} cardImgAlt="Go Transit Agency icon" />
          </div>
          <div className="transit-info">
            <div className="product-title">
              <Text field={props.fields.transitTrip} />
            </div>
            <div className="product-description">
              {`${getStationNameFromParam(
                selectedCard?.defaultTripsData?.fromLocationId
              )} - ${getStationNameFromParam(selectedCard?.defaultTripsData?.toLocationId)}`}
            </div>
          </div>
          <div className="transit-link">
            <PrestoLink field={props.fields.learnMoreLink} className="balanceBlock-link" />
          </div>
        </div>
      ) : null}
    </div>
  );
  let renderNickName;
  if (selectedCard.nickName !== null && selectedCard.nickName?.length >= 0) {
    renderNickName = <div className="small-text">{selectedCard.nickName}</div>;
    if (selectedCard.nickName?.length <= 13) {
      renderNickName = <div className="big-text">{selectedCard.nickName}</div>;
    }
    if (selectedCard.nickName?.length > 20) {
      renderNickName = (
        <div className="small-text">{`${selectedCard.nickName.substring(0, 19)}...`}</div>
      );
    }
  }
  const BalanceBlockErrorForGWallet = () => {
    if (
      isMobileWallet &&
      ((isGoogleWalletCard && !selectedCard.hasActiveImageGoog) ||
        (isAppleWalletCard && !selectedCard.hasActiveImageAppl) ||
        selectedCard.mediaSubTypeWallet === null ||
        '')
    ) {
      return (
        <Error
          gwError
          title={props.fields.walletCardIssueInWalletMessageHeading.value}
          titleA11y={props.fields.walletCardIssueInWalletMessageHeading.value}
          text={props.fields.walletCardIssueInWalletMessage}
        />
      );
    }
    return <></>;
  };
  return redeemStepper?.redeemVoucherBlock ? (
    <>
      {isLoading && <Loader />}
      <BalanceBlockErrorForGWallet />
      <Modal
        title={props.fields.modalPendingFunds?.value}
        text={<RichText field={props.fields.modalPendingFundsMessage} />}
        textSubmit={props.fields.modalPendingFundsCloseButton?.value}
        useRevert
        isModalOpen={isPendingFundsModalOpen}
        setIsModalOpen={setIsPendingFundsModalOpen}
      />
      <Modal
        title={props.fields.modalPendingPasses?.value}
        text={<RichText field={props.fields.modalPendingPassesMessage} />}
        textSubmit={props.fields.modalPendingPassesCloseButton?.value}
        useRevert
        isModalOpen={isPendingPassesModalOpen}
        setIsModalOpen={setIsPendingPassesModalOpen}
      />
      {isMobile && !deactivateCard?.deactivateCardType ? (
        <div className="balance-card--accordion-section">
          <button
            onClick={onClickSelect}
            aria-expanded={isArrowIconClicked}
            className="balance-card--accordion-heading"
          >
            <div className="selected-card-text">
              {renderNickName}
              {!isMobileWallet ? (
                <div
                  aria-label={formatPrestoCardNumberA11y(selectedCard.visibleId)}
                  className="small-text-top"
                >
                  <span aria-hidden="true">{formatPrestoCardNumber(selectedCard.visibleId)}</span>
                </div>
              ) : (
                <div
                  aria-label={formatPrestoCardNumberA11y(selectedCard.cardNumber)}
                  className="small-text-top"
                >
                  <span aria-hidden="true">{formatPrestoCardNumber(selectedCard.cardNumber)}</span>
                </div>
              )}
            </div>
            <img alt="iconText" className={isArrowIconClicked ? 'up' : ''} src={downIcon} />
          </button>
          {isArrowIconClicked && (
            <div className="balance-card--accordion-wrapper">
              <BalanceContentBlock />
            </div>
          )}
        </div>
      ) : (
        <BalanceContentBlock />
      )}
    </>
  ) : null;
};

export default BalanceBlock;
