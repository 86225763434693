import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';

import { formatDecimalWithCurrency, redirectToUrl, getQueryParams } from '../../services/utils';
import { ButtonBlock, Button, Loader } from '../../atoms';
import { Title, CheckoutGrid, Error, Modal } from '../../molecules';
import './checkout.scss';
import routes from '../../constants/routes';
import api from '../../services/api';
import apiResponses from '../../constants/apiResponses';
import {
  setShoppingCart,
  setShoppingCartId,
  setShoppingCartShippingDetails,
  setGuestSignPopupEnabled,
  setGuestUserCartCheckout,
  setAddressType,
  addShoppingCartMessage,
} from '../../redux/user/actions';

const CheckoutStep0 = ({ fields, state, t, userInfo, toNextStep, goBackUrl, setState }) => {
  const [invalidShoppingCartItemError, setInvalidSpoppingCartItemError] = useState(false);
  const [displayShoppingCartItems, setDisplayShoppingCartItems] = useState(true);
  const cartItems = useSelector((reduxState) => reduxState.user.shoppingCart.items);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const [userDetails, setUserDetails] = useState('');
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEnable, setModalEnable] = useState(false);
  const customerId = userInfo.customerId;
  const visibleId = userInfo.selectedCard.visibleId;
  const mediaId = userInfo.selectedCard.mediaId;
  const [isOrderCard, setIsOrderCard] = useState(false);
  const guestuserCheckout = useSelector((reduxState) => reduxState.user.guestuserCheckoutEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const guestSCPayload = useSelector((reduxState) => reduxState.user.guestShoppingCartPayload);
  const isCardRemoved = useSelector((reduxState) => reduxState.user.isCardRemoved);
  const queryParams = getQueryParams(useLocation().search);
  const location = useLocation();
  const history = useHistory();
  const anonymous = useSelector((state) => state.user && state.user.anonymous);

  const userSignedfromGuestPage = useSelector(
    (reduxState) => reduxState.user.userSignedFromGuestFlow
  );
  const userSignedfromCreateAccPage = useSelector(
    (reduxState) => reduxState.user.userSignedFromCreateAccount
  );
  const orderLineId =
    cartItems &&
    cartItems.filter((item) => {
      return item.name === 'Single Farecard';
    });
  const dispatch = useDispatch();

  // const removeItemfromCart = () => {
  //   const data = {
  //     customerId,
  //     mediaId: visibleId,
  //     orderLineId: orderLineId[0].orderLineId,
  //   };
  //   dispatch(removeFromShoppingCart(data));
  //   dispatch(addShoppingCartMessage(''));
  // };

  // const reAddtheItemstoCart = () => {
  //   let epurse = guestSCPayload.items.filter((x) => x.productFamily === 'Epurse');
  //   let passes = guestSCPayload.items.filter((x) => x.productFamily === 'CAPP');

  //   if (epurse.length > 0) {
  //     let payload = {
  //       customerId: customerId !== undefined ? customerId : null,
  //       visibleId: 'NEWMEDIA',
  //       productId: epurse[0].id,
  //       price: epurse[0].subTotal,
  //       quantity: 1,
  //       IsNewCardAdded: false,
  //       IsOrderCard: true,
  //     };
  //     api.addFundToCart(payload).then((res) => {
  //       dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));
  //       setIsLoading(false);
  //       setIsModalOpen(true);
  //     });
  //   }

  //   if (passes.length > 0) {
  //     for (let i = 0; i < passes.length; i++) {
  //       let payload = {
  //         customerId: customerId !== undefined ? customerId : null,
  //         visibleId: 'NEWMEDIA',
  //         productId: passes[i].id,
  //         price: passes[i].subTotal,
  //         quantity: 1,
  //         IsNewCardAdded: epurse.length > 0 ? true : false,
  //         IsOrderCard: true,
  //       };
  //       addGuestProducttoCart(payload);
  //     }
  //   }
  // };

  // const addGuestProducttoCart = async (payload) => {
  //   await api.addProductToCart(payload).then((res) => {
  //     dispatch(setShoppingCart(res.data.ShoppingCart.Order, true));
  //     setIsLoading(false);
  //     setIsModalOpen(true);
  //   });
  // };

  const checkoutsubmitHandler = () => {
    let button = document.getElementById('checkout-submit-button');
    button.click();
  };

  useEffect(() => {
    dispatch(setAddressType('current'));
    document.body.scrollTop = 0;
    if (cartItems && cartItems.length > 0 && cartItems[0].visibleId === 'NEWMEDIA') {
      setIsOrderCard(true);
    }
    api
      .getShoppingCartWithLineItemValidation({
        CustomerId: customerId,
        VisibleId:
          cartItems && cartItems.length > 0 && cartItems[0].visibleId === 'NEWMEDIA'
            ? 'NEWMEDIA'
            : visibleId,
        MediaId: mediaId,
        Sct: guestUserInfo?.isGuestLogin ? guestSCT?.sct : guestSCT?.sisct,
        Cct: guestUserInfo?.isGuestLogin ? guestSCT?.cct : guestSCT?.sicct,
        IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
        IsAnonymousUser: anonymous ? true : false,
      })
      .then((response) => {
        if (response.status !== 200) {
        } else if (response.data.Error) {
          const error = JSON.parse(response.data.Error);
          if (error.Code === apiResponses.invalidItemsInShoppingCart) {
            setInvalidSpoppingCartItemError(true);
            const order = response.data.shoppingCart.Order;
            const isNewItem = false;
            dispatch(setShoppingCart(order, isNewItem));
            setDisplayShoppingCartItems(true);
          }
        } else if (!response.data.shoppingCart) {
        } else {
          if (
            cartItems.length > 0 &&
            cartItems.length !== response.data.shoppingCart.Order.OrderLines.length
          ) {
            setInvalidSpoppingCartItemError(true);
          }
          const order = response.data.shoppingCart.Order;
          const isNewItem = false;
          const newstate = {
            ...state,
            orderId: order.Id,
          };
          setState(newstate);
          dispatch(setShoppingCartId(response?.data?.shoppingCart?.ShoppingCartId));
          dispatch(setShoppingCart(order, isNewItem));
          dispatch(
            setShoppingCartShippingDetails(response?.data?.shoppingCart?.Order?.ShippingAddress)
          );
          setDisplayShoppingCartItems(true);
        }
      })
      .catch(() => { });

    setTimeout(() => {
      setModalEnable(true);
    }, 1000);
  }, []);
  const handleCheckoutData = (data) => {
    setState({
      ...state,
      nickNameValue: data?.values?.userName,
      cardLanguage:
        data?.values?.cardLanguage.toUpperCase() === 'EN'
          ? fields?.EnglishLanguageText?.value
          : fields?.FrenchLanguageText?.value,
    });
    setUserDetails(data.values);
    setIsError(data.isError);
  };
  const saveCheckoutData = () => {
    api
      .UpdateMediaConfigurationDetails({
        CustomerId: customerId,
        NickName: userDetails.userName,
        LanguageId: userDetails.cardLanguage,
        PIN: userDetails.pinNumber,
        LineItemId: orderLineId && orderLineId.length > 0 && orderLineId[0].orderLineId,
        VisibleId: isOrderCard ? 'NEWMEDIA' : visibleId,
      })
      .then((response) => {
        //console.log('');
      })
      .catch(() => {
        //console.log('error');
      });
  };

  const GuestCheckoutHandler = () => {
    api.b2cGuestSignIn();
    // dispatch(setGuestSignPopupEnabled({ enablePopup: true }));
  };

  // useEffect(() => {
  //   if (isCardRemoved) {
  //     setIsModalOpen(true);
  //   }
  // }, [isCardRemoved]);

  useEffect(() => {
    if (queryParams.continueasquest !== undefined) {
      dispatch(
        setGuestUserCartCheckout({
          continueCheckout: true,
        })
      );
      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('continueasquest')) {
        queryParams1.delete('continueasquest');
        history.replace({
          search: queryParams1.toString(),
        });
      }
    }
    if (guestuserCheckout?.continueCheckout) {
      toNextStep();
      dispatch(setGuestUserCartCheckout({ continueCheckout: false }));
      dispatch(setGuestSignPopupEnabled({ enablePopup: false }));
    }
  }, [guestuserCheckout]);

  useEffect(() => {
    if (userSignedfromGuestPage) {
      // if (cartItems && cartItems.length > 0) {
      //   removeItemfromCart();
      // }
      setIsOrderCard(true);
      setIsModalOpen(true);
      // setIsLoading(true);
      // reAddtheItemstoCart();
    }
  }, [userSignedfromGuestPage]);

  useEffect(() => {
    if (userSignedfromCreateAccPage) {
      setIsOrderCard(true);
    }
  }, [userSignedfromCreateAccPage]);

  return (
    <>
      {invalidShoppingCartItemError ? <Error title={t('invalidShoppingCartItemError')} /> : null}
      <Title>
        <Text field={isOrderCard ? fields?.Buyaprestodescription : fields.description} />
      </Title>

      <div className="checkout-step1-grid">
        {isOrderCard
          ? displayShoppingCartItems &&
          state.fareMedias &&
          state.fareMedias.map((fareMedia) => {
            if (fareMedia.visibleId === visibleId) {
              return (
                <CheckoutGrid
                  fields={fields}
                  items={state.items}
                  fareMedia={fareMedia}
                  totalAmount={state.total}
                  handleCheckoutData={handleCheckoutData}
                  isOrderCard={isOrderCard}
                  additionalpadding
                  t={t}
                />
              );
            }
          })
          : displayShoppingCartItems &&
          state.fareMedias &&
          state.fareMedias.map((fareMedia) => (
            <CheckoutGrid
              fields={fields}
              items={state.items}
              fareMedia={fareMedia}
              totalAmount={state.total}
              handleCheckoutData={handleCheckoutData}
              additionalpadding
              t={t}
            />
          ))}
        {isOrderCard && (userSignedfromCreateAccPage || state?.fareMedias?.length === 0) ? (
          <CheckoutGrid
            fields={fields}
            items={state.items}
            fareMedia={{ mediaId: '', visibleId: '' }}
            totalAmount={state.total}
            isOrderCard={isOrderCard}
            handleCheckoutData={handleCheckoutData}
            additionalpadding
            t={t}
          />
        ) : null}
        {!isOrderCard && displayShoppingCartItems ? (
          <div className="checkout-total">
            <span>
              <Text field={fields.orderTotalLabel} />
            </span>
            {` ${formatDecimalWithCurrency(state.total)}`}
          </div>
        ) : null}
      </div>
      {displayShoppingCartItems && state.items ? (
        <ButtonBlock>
          {state.items.length > 0 ? (
            <ButtonBlock right className="button-order-revere">
              {!guestUserInfo?.isGuestLogin
                ? isOrderCard
                  ? isError || Object.keys(userDetails).length === 0
                    ? [
                      <Button
                        type="submit"
                        buttonTextA11y={fields.nextButtonTextA11y.value}
                        onClick={() => {
                          checkoutsubmitHandler();
                        }}
                      >
                        <Text field={fields.nextButtonText} />
                      </Button>,
                      <Button
                        onClick={() => redirectToUrl(goBackUrl)}
                        white
                        buttonTextA11y={fields.backButtonTextA11y.value}
                      >
                        <Text field={fields.backButtonText} />
                      </Button>,
                    ]
                    : [
                      <Button
                        onClick={() => {
                          toNextStep();
                          saveCheckoutData();
                        }}
                        buttonTextA11y={fields.nextButtonTextA11y.value}
                      >
                        <Text field={fields.nextButtonText} />
                      </Button>,
                      <Button
                        onClick={() => redirectToUrl(goBackUrl)}
                        white
                        buttonTextA11y={fields.backButtonTextA11y.value}
                      >
                        <Text field={fields.backButtonText} />
                      </Button>,
                    ]
                  : [
                    <Button
                      onClick={() => toNextStep()}
                      buttonTextA11y={fields.nextButtonTextA11y.value}
                    >
                      <Text field={fields.nextButtonText} />
                    </Button>,
                    <Button
                      onClick={() => redirectToUrl(goBackUrl)}
                      white
                      buttonTextA11y={fields.backButtonTextA11y.value}
                    >
                      <Text field={fields.backButtonText} />
                    </Button>,
                  ]
                : [
                  <Button
                    onClick={() => GuestCheckoutHandler()}
                    buttonTextA11y={fields.nextButtonTextA11y.value}
                  >
                    <Text field={fields.nextButtonText} />
                  </Button>,
                  <Button
                    onClick={() => redirectToUrl(routes.orderNewCard)}
                    white
                    buttonTextA11y={fields.backButtonTextA11y.value}
                  >
                    <Text field={fields.backButtonText} />
                  </Button>,
                ]}
            </ButtonBlock>
          ) : (
            <ButtonBlock right>
              <Button
                onClick={() => redirectToUrl(goBackUrl)}
                firstOrder
                buttonTextA11y={fields.backButtonTextA11y.value}
              >
                <Text field={fields.backButtonText} />
              </Button>
            </ButtonBlock>
          )}
        </ButtonBlock>
      ) : null}

      {modalEnable && (
        <Modal
          title={fields.Titleofpopup.value}
          text={fields.descofguestuser.value}
          textSubmit={fields.Closetextguestuser.value}
          useRevert
          disableBackdropClick
          disableEscapeKeyDown
          withoutFocusTrap={false}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default CheckoutStep0;
