import React from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';

import api from '../../services/api';
import apiResponses from '../../constants/apiResponses';
import { isJsonString } from '../../services/utils';
import { Button, ButtonBlock } from '../../atoms';
import { Header, CardBlock } from '../../molecules';

import './savedPaymentMethod.scss';

const RemoveMethodStep1 = ({ fields, state, setState, component, toFirstStep, toNextStep }) => {
  const userInfo = useSelector((s) => s.user);

  const cancelPaymentMethod = async () => {
    api
      .terminateRegisterPaymentMean({
        customerId: userInfo.customerId,
        regFormOfPaymRecId: component.paymenMethodsList[0].RegFormOfPaymRecId,
      })
      .then((response) => {
        if (response.data.Success && response.data.Result) {
          setState({
            ...state,
            isPaymentMethodRemoved: true,
          });
          toNextStep();
        } else {
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setState({
            ...state,
            isPaymentMethodRemoved: false,
            errorDescription:
              parsedError?.Code === apiResponses.pendingLoad ? parsedError?.Description : '',
          });
          toNextStep();
        }
      })
      .catch(() => {
        setState({
          ...state,
          isPaymentMethodRemoved: false,
        });
        toNextStep();
      });
  };

  return (
    <>
      <Header centered>
        <Text field={fields.removeStepPageHeading} />
      </Header>

      <CardBlock>
        <RichText field={fields.removeStepPageDescription} />
      </CardBlock>
      <ButtonBlock>
        <ButtonBlock right>
          <Button
            type="submit"
            onClick={() => cancelPaymentMethod()}
            buttonTextA11y={fields.savedPaymentConfirmButtonA11y?.value}
          >
            <Text field={fields.savedPaymentConfirmButton} />
          </Button>
          <Button
            firstOrder
            type="button"
            white
            onClick={() => toFirstStep()}
            buttonTextA11y={fields.savedPaymentBackButtonA11y?.value}
          >
            <Text field={fields.savedPaymentBackButton} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </>
  );
};

export default RemoveMethodStep1;
