import React from 'react';

import { withTranslation } from 'react-i18next';

import { Image } from '@sitecore-jss/sitecore-jss-react';

import { Link } from 'react-router-dom';

import { Button } from '../../atoms';

import './ImageCTALinkable.scss'

//  <a href= {`${path}`} target="_blank"></a>

const ImageCTALinkable = (props) => {

 

return (
  <div id={props.fields.ButtonId.value}>
  <div className="image-cta-linkable">

   

<a href={props.fields.ButtonLink.value.href} target="_blank">

      <Image media={props.fields.Icon} loading="lazy"/>

    </a>
</div>
  </div>

);

}



export default withTranslation()(ImageCTALinkable);