import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { TextInput, CardBlock, Note, Error } from '../../molecules';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Loader } from '../../atoms';
import api from '../../services/api';
import { setRedeemVoucherStepperBlock } from '../../redux/products/actions';
import './redeemVoucher.scss';

const RedeemVoucher = (props) => {
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState('');
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.mediaId === reduxState.user.selectedCard.mediaId)
  );
  const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
  const [errorMsg, setErrorMsg] = useState({ error: '' });
  const [loading, setLoading] = useState(false);
  const user = useSelector((reduxState) => reduxState.user);
  const [check, setCheck] = useState(false);
  useEffect(() => {
    dispatch(
      setRedeemVoucherStepperBlock({
        redeemVoucherStepperBlock: false,
        redeemVoucherBlock: true,
        voucherCode: '',
      })
    );
  }, []);
  const toRedeemStepper = () => {
    setLoading(true);
    const voucher = voucherCode.replace(/\s+/g, '');
    const payload = {
      VisibleId: fareMedia?.visibleId,
      VoucherCode: voucher,
      MediaId: fareMedia?.mediaId,
      CustomerId: user?.customerId,
      EnableStub: true,
      EnableErrorMessage: true,
    };
    api
      .validateVoucher(payload)
      .then((response) => {
        setLoading(false);
        if (response?.data?.Sucess) {
          //   setApiStatus({ submit: true, state: 'SUCCESS' });
          //   dispatch(fetchAllData());
          dispatch(
            setRedeemVoucherStepperBlock({
              redeemVoucherStepperBlock: true,
              redeemVoucherBlock: false,
              apiData: response.data,
              voucherCode: voucher,
            })
          );
        } else {
          if (response?.data?.ErroMessage === 'AFMS-PV-API-0108') {
            setErrorMsg({ error: props.fields.VouchercodeError.value });
          } else if (response?.data?.ErroMessage === 'AFMS-PV-API-0084') {
            setErrorMsg({ error: props.fields.SpecialCharacterError.value });
          } else if (response?.data?.ErroMessage === 'AFMS-PV-API-0146') {
            setErrorMsg({ error: props.fields.SpecialCharacterError.value });
          } else if (response?.data?.ErroMessage === ('AFMS-PV-API-0149' || 'AFMS-PV-API-0152')) {
            setErrorMsg({ error: props.fields.EpurseErrorMessage.value });
          } else {
            setErrorMsg({ error: props.t('VoucherGenericError') });
          }
        }
      })
      .catch((err) => {
        setApiStatus({ submit: true, state: 'ERROR' });
      });
  };

  const resetErrorMessage = () => {
    setErrorMsg({ error: '' });
  };

  const putVoucherCode = (e) => {
    setVoucherCode(e.target.value);
  };
  return redeemStepper?.redeemVoucherBlock ? (
    <>
      {' '}
      {loading && <Loader />}
      <div className="redeem-Voucher-wrapper">
        <div className="redeem-voucher">
          <CardBlock title={props.fields.RedeemVoucherTitle.value}>
            <RichText field={props.fields.RedeemVoucherDescription} />
            <Formik
              initialValues={{
                PrestoVoucherCode: voucherCode,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.PrestoVoucherCode) {
                  errors.PrestoVoucherCode = props.fields.ContinuetoRedeemError.value;
                }
                if (errors.PrestoVoucherCode) {
                  setCheck(true);
                } else {
                  setCheck(false);
                }
                return errors;
              }}
              validateOnChange={true}
              validationSchema={Yup.object().shape({
                PrestoVoucherCode: Yup.string()
                  .required(props.fields.ContinuetoRedeemError.value)
                  .matches(/^[A-Za-z0-9 ]+$/, props.fields.SpecialCharacterError.value),
              })}
              onSubmit={(values) => {
                toRedeemStepper();
              }}
            >
              {({ errors, values, touched, handleChange, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`voucher-text ${errorMsg.error ? 'disable-border' : false}`}>
                      <TextInput
                        type="text"
                        value={voucherCode}
                        name="PrestoVoucherCode"
                        errors={errors}
                        touched={touched}
                        onChange={(e) => {
                          handleChange(e);
                          putVoucherCode(e);
                          resetErrorMessage();
                        }}
                        label={props.fields.PrestovoucherCodeTitle}
                      />
                      {errorMsg.error ? (
                        <div className="error-message-redeem">
                          <Error small title={errorMsg.error} />
                        </div>
                      ) : null}
                    </div>
                    <div className="redeem-button">
                      <Button type="submit" isDisabled={check}>
                        <Text field={props.fields.ContinuetoRedeemButtontText} />
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </CardBlock>
        </div>
        <Note>
          <RichText field={props.fields.RedeemVoucherEndtitle} />
        </Note>
      </div>
    </>
  ) : null;
};
export default withTranslation()(RedeemVoucher);
