import React, { useMemo, useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr-ca';
import { useField } from 'formik';
import { getTimePeriodTx } from '../../services/utils';
// import Select from '../Select';
import './prestoDateRange.scss';
import Modal from '../Modal';
import { DatePickerInput } from '../../atoms';
import { getLongLanguageCode, isFrench } from '../../services/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const MIN_DATE = moment().subtract(2, 'year').toDate();
const MAX_DATE = new Date();
let dateRange = '';
const PrestoDateRange = ({
  name,
  fromName,
  toName,
  setFieldValue,
  errors,
  values,
  setFieldError,
  datepickerValue,
  setDatepickerValue,
  t,
  dateFormat = 'MM/DD/YYYY',
  showSelectedDateRange = false,
}) => {
  const [field, , helpers] = useField(name);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState(values.FromDate);
  const [endDate, setEndDate] = useState(values.ToDate);
  const langCode = getLongLanguageCode();
  const [dateoptions, setDateOptions] = useState([]);

  const updatePopupVisibility = () => {
    if (field.value === '5' && !isPopupOpen) {
      setIsPopupOpen(true);
    }
  };

  const onSelectClose = updatePopupVisibility;
  useDidMountEffect(() => updatePopupVisibility(), [field.value]);
  useEffect(() => {
    const today = moment().locale(langCode);
    const optionsDateFormat = 'MMMM YYYY';
    let optionsTemp = [
      {
        label: today.clone().format(optionsDateFormat),
        value: '2',
      },
      {
        label: today.clone().subtract(1, 'month').format(optionsDateFormat),
        value: '3',
      },
      {
        label: today.clone().subtract(2, 'month').format(optionsDateFormat),
        value: '4',
      },
      {
        label: t('datepickerDefault'),
        value: '5',
      },
    ];
    if (datepickerValue.toString() === '6') {
      optionsTemp = [
        ...optionsTemp,
        {
          label: getTimePeriodTx(startDate, endDate),
          value: '6',
        },
      ];
    }

    setDateOptions(optionsTemp);
  }, []);

  const options = useMemo(() => {
    const today = moment().locale(langCode);
    const optionsDateFormat = 'MMMM YYYY';
    let optionsTemp = [
      {
        label: today.clone().format(optionsDateFormat),
        value: '2',
      },
      {
        label: today.clone().subtract(1, 'month').format(optionsDateFormat),
        value: '3',
      },
      {
        label: today.clone().subtract(2, 'month').format(optionsDateFormat),
        value: '4',
      },
      {
        label: t('datepickerDefault'),
        value: '5',
      },
    ];
    if (showSelectedDateRange && field.value == 5) {
      optionsTemp = [
        ...optionsTemp,
        {
          label: getTimePeriodTx(startDate, endDate),
          value: '6',
        },
      ];
    }
    return optionsTemp;
  }, [langCode, startDate, endDate]);

  const clearFormikRangeFields = () => {
    setFieldValue(fromName, undefined);
    setFieldValue(toName, undefined);
    setStartDate(new Date());
    setEndDate(moment().subtract(2, 'year').toDate());
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    clearFormikRangeFields();
    helpers.setValue('2');
    setDatepickerValue(2);
  };

  const popupOnSubmit = () => {
    if (!moment.isDate(values[fromName]) && !moment.isDate(values[toName])) {
      setFieldError(fromName, t('transactionHistoryFromDateRequired'));
      setFieldError(toName, t('transactionHistoryToDateRequired'));
      return;
    }

    if (!moment.isDate(values[fromName])) {
      setFieldError(fromName, t('transactionHistoryFromDateRequired'));
      return;
    }
    if (!moment.isDate(values[toName])) {
      setFieldError(toName, t('transactionHistoryToDateRequired'));
      return;
    }

    // if (moment(values[toName]).isBefore(values[fromName])) {
    //   setFieldError(toName, t('transactionHistoryToDateInvalid'));
    //   return;
    // }

    let filteredOptions = dateoptions.filter((x) => x.value !== '6');
    let optionsTemp = [
      ...filteredOptions,
      {
        label: getTimePeriodTx(startDate, endDate),
        value: '6',
      },
    ];

    setDateOptions(optionsTemp);
    setDatepickerValue(6);
    setIsPopupOpen(false);
  };

  const optionChangeHandler = (event) => {
    setDatepickerValue(event.target.value);
    // values[PeriodId] = event.target.value;
    if (event.target.value === '5') {
      setIsPopupOpen(true);
    }
  };

  return (
    <div className="dateRange presto-datepicker-mat">
      {/* <Select onClose={onSelectClose} label={t('dateRangeLabel')} name={name} options={options} /> */}
      <Select onChange={optionChangeHandler} value={datepickerValue}>
        {dateoptions.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {' '}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Modal
        onSubmit={popupOnSubmit}
        setIsModalOpen={closePopup}
        isModalOpen={isPopupOpen}
        withoutFocusTrap={false}
        disableBackdropClick
        disableEscapeKeyDown
        customClass
        textCancel={t('datepickerCancelButtonText')}
        textSubmit={t('datepickerSelectButtonText')}
        title={t('dateRangeTitle')}
        text={
          <>
            <div className="dateRange-datepicker-tx">
              <div>
                <DatePickerInput
                  label={t('fromDateLabel')}
                  aria-label={t('fromDateLabelA11y')}
                  name={fromName}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  selectsStart
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  useShortMonthInDropdown
                  maxDate={MAX_DATE}
                  minDate={MIN_DATE}
                  startDate={startDate}
                  endDate={endDate}
                  errors={errors}
                  belowText={isFrench() ? 'MM/JJ/AAAA' : dateFormat}
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>

              <div>
                <DatePickerInput
                  label={t('toDateLabel')}
                  aria-label={t('toDateLabelA11y')}
                  name={toName}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  useShortMonthInDropdown
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={MAX_DATE}
                  minDate={values[fromName] || MIN_DATE}
                  errors={errors}
                  belowText={isFrench() ? 'MM/JJ/AAAA' : dateFormat}
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
            <button
              aria-label={t('clearDatesButtonTextA11y')}
              type="button"
              onClick={clearFormikRangeFields}
              className="dateRange-clear"
            >
              {t('clearDatesButtonText')}
            </button>
          </>
        }
      />
    </div>
  );
};

export default withTranslation()(PrestoDateRange);
