import initialState from '../initialState';
import types from './types';

export function settings(state = initialState, action = '') {
  if (action.type === types.SET_SETTINGS) {
    const { Data } = action.payload;

    return {
      ...state,
      configKeys: Data.map((item) => ({
        key: item.Key,
        setting: item.Value,
      })),
    };
  }

  return state;
}
