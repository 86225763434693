import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import moment from 'moment';

import api from '../../services/api';
import { Button, IconButton, Loader } from '../../atoms';
import { setTransitPasses } from '../../redux/products/actions';
import { Select } from '../../molecules';
import { isFrench } from '../../services/utils';
import { LOYALTY_SUMMARY_MONTHS_PERIOD } from '../../constants/form.constants';
import API_STATUS from '../../constants/apiStatus';
import printIcon from '../../assets/images/printer.svg';

import './loyaltySummary.scss';

const CURRENT_MONTH = 2;
const PREVIOUS_MONTH = 3;
const REM_MONTH = 4;
const TOTAL_MONTH = 12;

moment.locale(isFrench() ? 'fr' : 'en');
const fromMonth = moment().subtract(LOYALTY_SUMMARY_MONTHS_PERIOD, 'months');
const toMonth = moment();

const LoyaltySummary = (props) => {
  const [serviceOptions, setServiceOptions] = useState([]);
  const [apiState, setApiState] = useState(API_STATUS.INITIAL);
  const [disableAgencyDropdown, setDisableAgencyDropdown] = useState(true);
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.user);
  const [formError, setFormError] = useState(false);
  const [searchResponse, setSearchResponse] = useState({});
  const getMonthsBetweenDates = (startDate, endDate, returnFormat) => {
    let now = startDate.clone();
    let dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push({ label: now.format(returnFormat), value: now.format('M') });
      now.add(1, 'months');
    }
    return dates;
  };

  const getMonthValue = (selected, current) => {
    let periodid;
    if (selected === 0) {
      periodid = 1;
    } else {
      let diff = current - selected;
      if (diff < 0) {
        diff += TOTAL_MONTH;
      }
      switch (diff) {
        case 0:
          periodid = CURRENT_MONTH;
          break;
        case 1:
          periodid = PREVIOUS_MONTH;
          break;
        default:
          periodid = REM_MONTH;
      }
    }
    return periodid;
  };

  useEffect(() => {
    if (!userInfo.fareMedias || !userInfo.selectedCard) return;
    api
      .getServiceProviders({
        visibleId: userInfo?.selectedCard?.visibleId,
        disableSitecoreFilter: true,
      })
      .then(({ data }) => {
        if (!data.Success) return;

        setServiceOptions(
          data.serviceProvider.map((i) => ({
            label: i.Name,
            value: i.Id,
            products: i.Products,
          }))
        );
        setDisableAgencyDropdown(!data.serviceProvider.length);
        dispatch(setTransitPasses(data));
      });
  }, []);

  const getLoyaltySummary = (formValues) => {
    let periodId = getMonthValue(Number(formValues?.month), Number(moment().format('M')));
    setApiState(API_STATUS.LOADING);
    let payload = {
      visibleId: userInfo?.selectedCard?.visibleId,
      periodId,
      providerId: formValues?.transitAgency,
      mediaId: userInfo?.selectedCard?.mediaId,
    };
    api
      .getLoyaltySummary(payload)
      .then((res) => {
        if (res?.data?.Success) {
          setApiState(API_STATUS.SUCCESS);
          setSearchResponse(res.data);
        } else {
          setApiState(API_STATUS.ERROR);
        }
      })
      .catch((err) => {
        setApiState(API_STATUS.ERROR);
      });
  };

  const showResultContent = () => {
    const {
      TotalFarePaidLabel,
      TotalDiscountReceived,
      NumberOfLoyaltyLabel,
      CurrentStep,
      LoyaltyCurrentStepText,
    } = props.fields;
    return (
      <ul className="loyalty-summary--info">
        <li>
          <div className="key">{TotalFarePaidLabel.value}</div>
          <div className="value">{searchResponse.TotalFarePaid}</div>
        </li>
        <li>
          <div className="key">{TotalDiscountReceived.value}</div>
          <div className="value">{searchResponse.TotalDiscountReceived}</div>
        </li>
        <li>
          <div className="key">{NumberOfLoyaltyLabel.value}</div>
          <div className="value">{searchResponse.NumberofLoyaltyTrips}</div>
        </li>
        <li>
          <div className="key">{CurrentStep.value}</div>
          <div className="value">{searchResponse.CurrentStep}</div>
        </li>
        <li>
          <div className="key">{LoyaltyCurrentStepText.value}</div>
          <div className="value">{searchResponse.NumberofLoyaltyApplicableTripsUntilNextStep}</div>
        </li>
      </ul>
    );
  };

  const {
    LoyaltyDescriptionText,
    TransistAgencyLabel,
    MonthLabel,
    NoRecordstext,
    ConnectErrorheading,
    ConnectionErrordescription,
    TransitAgencyErrordescription,
    Print,
  } = props.fields;
  let partDescriptionText = LoyaltyDescriptionText?.value?.split('{0}');
  const arrayOfMonths = getMonthsBetweenDates(fromMonth, toMonth, 'MMMM YYYY').reverse();
  let isDataAvailable =
    Object.prototype.hasOwnProperty.call(searchResponse, 'TotalFarePaid') &&
    searchResponse.TotalFarePaid;

  return (
    <div className="loyalty-summary-container">
      {partDescriptionText?.length
        ? [
            <p className="loyalty-summary-container--description">
              <RichText field={props.fields.LoyaltyDescriptionText} />
            </p>,
            <div className="loyalty-summary-container--form">
              <Formik
                initialValues={{
                  transitAgency: '',
                  month: arrayOfMonths.length ? arrayOfMonths[0].value : '',
                }}
                onSubmit={(values) => {
                  getLoyaltySummary(values);
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.transitAgency) {
                    errors.transitAgency = TransitAgencyErrordescription.value;
                  }
                  if (
                    Object.prototype.hasOwnProperty.call(errors, 'transitAgency') ||
                    Object.prototype.hasOwnProperty.call(errors, 'month')
                  ) {
                    setFormError(true);
                  } else {
                    setFormError(false);
                  }
                  return errors;
                }}
              >
                {({ errors, touched, handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Select
                        label={TransistAgencyLabel.value}
                        name="transitAgency"
                        errors={errors}
                        touched={touched}
                        options={serviceOptions}
                        isDisabled={disableAgencyDropdown}
                        labelA11y={TransistAgencyLabel.value}
                        showPlaceholder
                      />
                      <Select
                        label={MonthLabel.value}
                        name="month"
                        errors={errors}
                        touched={touched}
                        options={arrayOfMonths || []}
                        labelA11y={MonthLabel.value}
                        showPlaceholder={false}
                      />
                      <Button type="submit" isDisabled={formError}>
                        <Text field={props.fields.SearchCTA} />
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            </div>,
            apiState !== API_STATUS.INITIAL ? (
              <>
                <div className="loyalty-summary-container--output">
                  {apiState === API_STATUS.SUCCESS && !isDataAvailable ? (
                    <div className="loyalty-summary-container--output-noRecord">
                      {NoRecordstext.value}
                    </div>
                  ) : null}
                  {apiState === API_STATUS.SUCCESS && isDataAvailable ? (
                    <div className="loyalty-summary-container--output-data">
                      {showResultContent()}
                    </div>
                  ) : null}
                  {apiState === API_STATUS.ERROR ? (
                    <div className="loyalty-summary-container--output-error">
                      <h4>{ConnectErrorheading.value}</h4>
                      {ConnectionErrordescription.value}
                    </div>
                  ) : null}
                </div>
                {apiState === API_STATUS.SUCCESS && isDataAvailable ? (
                  <div className="loyalty-summary-container--footer">
                    <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
                      {Print.value}
                    </IconButton>
                  </div>
                ) : null}
              </>
            ) : null,
            apiState === API_STATUS.LOADING ? <Loader /> : null,
          ]
        : null}
    </div>
  );
};

export default LoyaltySummary;
