import React, { useState, useEffect } from 'react';

import './CSOLocator.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import downIcon from '../../assets/images/down.svg';
import useMediaWidth from '../../hooks/useMediaWidth';
import { is } from '../../services/utils';

export default function FilterStations({
  props,
  selectedFilters,
  setSelectedFilters,
  CSOLocatorLookUpData,
  handleFilterChange,
  csoFilterCounter,
  asFilterCounter,
  tfFilterCounter,
  setTfFilterCounter,
  setAsFilterCounter,
  setCsoFilterCounter,
  newVisibleMarkers,
  setCsoFilter,
  setAvailableFilter,
  setFacilityFilter,
  isMapLoaded,
}) {
  const [expandedFilterIndex, setExpandedFilterIndex] = useState(-1);
  const [onSkipMap, setOnSkipMap] = useState(false);
  const isMobile = useMediaWidth();

  const handleCounter = () => {
    setSelectedFilters([]);
    setTfFilterCounter(0);
    setAsFilterCounter(0);
    setCsoFilterCounter(0);
    setCsoFilter([]);
    setAvailableFilter([]);
    setFacilityFilter([]);
  };
  const filterData = [
    {
      label: props?.fields?.CustomerServiceOutLetLabel?.value,
      counter: 0,
      options: CSOLocatorLookUpData?.CsoLocators?.map(({ Id, ServiceOutletName }) => ({
        id: Id,
        name: ServiceOutletName,
        filterType: 'csoLocators',
      })),
    },
    {
      label: props?.fields?.TypeofFacilityLabel?.value,
      counter: 0,
      options: CSOLocatorLookUpData?.TypeofFacilities?.map(({ Id, FacilityName }) => ({
        id: Id,
        name: FacilityName,
        filterType: 'typeofFacilities',
      })),
    },
    {
      label: props?.fields?.AvailableServicesLabel?.value,
      counter: 0,
      options: CSOLocatorLookUpData?.AvailableServices?.map(({ Id, ServiceName }) => ({
        id: Id,
        name: ServiceName,
        filterType: 'availableServices',
      })),
    },
  ];
  const moveFocus = (id) => {
    let goFocus = document?.getElementById(id).focus();
  };
  const findAccessibleEleBeforeClearAll = () => {
    let openedTabPanel = document?.getElementsByClassName('tab-panel current');
    if (openedTabPanel.length) {
      let lastCheckbox = openedTabPanel[0].lastElementChild;
      if (lastCheckbox && lastCheckbox.firstElementChild) {
        return lastCheckbox.firstElementChild;
      }
    }
    let lastTab = document.querySelector('[aria-controls="tabpanel-0"]');
    if (lastTab) return lastTab;
  };
  const modifyAccessibility = (event, { before = false, on = false, after = false }) => {
    let flag = false;
    let isSkipMapEmpty = !is(props?.fields?.SkipMap?.value);
    let isClearAllEmpty = !is(props?.fields?.ClearSearchFilter?.value);
    if (!isMobile) {
      // only for desktop
      if (before) {
        if (!event.shiftKey && event.key === 'Tab') {
          // Tab press on last checkbox of opened tab
          flag = true;
          if (!isClearAllEmpty) {
            moveFocus('clear-all-btn-id');
          } else if (!isSkipMapEmpty) {
            moveFocus('skip-map-btn-id');
          }
        }
      } else if (on) {
        if (event.shiftKey && event.keyCode == 9) {
          // Shift + Tab on clear all filter
          flag = true;
          let el = findAccessibleEleBeforeClearAll();
          if (el) el.focus();
        } else if (event.key === 'Tab' && !isSkipMapEmpty) {
          // Tab press on clear all filter
          flag = true;
          moveFocus('skip-map-btn-id');
        }
      } else if (after) {
        // shift + tab on first map accessible element
        flag = true;
        if (!isSkipMapEmpty) {
          moveFocus('skip-map-btn-id');
        } else if (!isClearAllEmpty) {
          moveFocus('clear-all-btn-id');
        }
      }
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (isMapLoaded && document && !isMobile) {
      // Assign keydown event for google map's first accessible element
      let firstAccMapElement = document?.querySelector('[aria-label="Keyboard shortcuts"]');
      let keyShortDivs = document?.querySelectorAll('[aria-label="Keyboard shortcuts"]');
      if (keyShortDivs && keyShortDivs.length) {
        for (let i = 0; i < keyShortDivs.length; ++i) {
          if (keyShortDivs[i].innerText === '') {
            keyShortDivs[i].innerText = keyShortDivs[i].title;
            keyShortDivs[i].style.color = 'transparent';
          }
        }
      }
      if (firstAccMapElement) {
        firstAccMapElement.addEventListener('keydown', (event) => {
          if (event.isComposing || event.keyCode === 229) {
            return null;
          }
          if (event.shiftKey && event.keyCode == 9) {
            // Shift + Tab on first Map icon
            modifyAccessibility(event, { after: true });
          }
        });
      }
    }
  }, [isMapLoaded]);

  const selectTab = (index) => {
    setExpandedFilterIndex(index === expandedFilterIndex ? -1 : index);
  };
  const goBack = (index) => {
    let nextIndex = index;
    if (index === 0) {
      nextIndex = filterData?.length - 1;
    } else {
      nextIndex = index - 1;
    }
    moveFocus(`tab-${nextIndex}`);
  };
  const goNext = (index) => {
    let nextIndex = index;
    if (index === filterData?.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = index + 1;
    }
    moveFocus(`tab-${nextIndex}`);
  };
  const onKeyDownTab = (event, index) => {
    let flag = false;

    switch (true) {
      case event.key === 'ArrowLeft' && !isMobile:
        goBack(index);
        flag = true;
        break;

      case event.key === 'ArrowRight' && !isMobile:
        goNext(index);
        flag = true;
        break;
      case event.key === 'ArrowUp' && isMobile:
        goBack(index);
        flag = true;
        break;

      case event.key === 'ArrowDown' && isMobile:
        goNext(index);
        flag = true;
        break;

      case event.key === 'Home':
        moveFocus(0);
        flag = true;
        break;

      case event.key === 'End':
        moveFocus(filterData?.length - 1);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  let filterCounter = 0;
  const filterHeader = (filter, index) => {
    let isExpanded = expandedFilterIndex === index;
    if (index == 0 && csoFilterCounter > 0) {
      filter.counter = csoFilterCounter;
    }
    if (index == 2 && asFilterCounter > 0) {
      filter.counter = asFilterCounter;
    }
    if (index == 1 && tfFilterCounter > 0) {
      filter.counter = tfFilterCounter;
    }
    return is(filter?.label) ? (
      <button
        aria-controls={`tabpanel-${index}`}
        aria-selected={isExpanded}
        aria-expanded={isExpanded}
        id={`tab-${index}`}
        role="tab"
        type="button"
        className={`tab ${isExpanded ? `selected` : ``}`}
        onClick={() => selectTab(index)}
        onKeyDown={(event) => onKeyDownTab(event, index)}
        tabIndex={isExpanded || (expandedFilterIndex === -1 && index === 0) ? `0` : `-1`}
      >
        {filter.counter > 0 ? (
          <label>
            {filter?.label} ({filter?.counter})
          </label>
        ) : (
          <label>{filter?.label}</label>
        )}
        <img alt="" className={isExpanded ? 'up' : ''} src={downIcon} />
      </button>
    ) : null;
  };
  const filterContent = (filter, index) => {
    let isExpanded = expandedFilterIndex === index;
    let filters = filter?.options?.map(({ id, name, filterType }, indexJ) =>
      is(name) ? (
        <div className="filter-item">
          <input
            className={`filter-checkbox ${filterType}`}
            type="checkbox"
            checked={selectedFilters.includes(id)}
            value={id}
            onChange={handleFilterChange}
            id={`filter-${id}`}
            aria-labelledby={`filter-${id}-label`}
            tabIndex={onSkipMap ? `-1` : `0`}
            onKeyDown={(event) =>
              isMobile
                ? null
                : modifyAccessibility(event, { before: filter?.options?.length - 1 === indexJ })
            }
          />
          <label className="filter-label" htmlFor={`filter-${id}`} id={`filter-${id}-label`}>
            {name}
          </label>
        </div>
      ) : null
    );
    return (
      <div
        aria-labelledby={`tab-${index}`}
        className={`tab-panel ${isExpanded ? `current` : `hidden`}`}
        role="tabpanel"
        id={`tabpanel-${index}`}
        aria-hidden={!isExpanded}
      >
        {filters}
      </div>
    );
  };
  const skipMap = (focus = true) => {
    if (newVisibleMarkers.length) {
      let resultAccContainer = document.getElementsByClassName('accordion-container');
      if (resultAccContainer && resultAccContainer.length) {
        let stationContainer = resultAccContainer[0].firstElementChild;
        if (stationContainer && stationContainer.firstElementChild) {
          if (focus) {
            stationContainer.firstElementChild.focus();
          } else {
            stationContainer.firstElementChild.blur();
          }
        }
      }
    } else {
      let accContainer = document.getElementsByClassName('Accordion-customer');
      if (accContainer && accContainer.length) {
        let firstAgencyContainer = accContainer[0].firstElementChild;
        if (firstAgencyContainer) {
          if (focus) {
            firstAgencyContainer.focus();
          } else {
            firstAgencyContainer.blur();
          }
        }
      }
    }
  };
  const skipMapButton = () => {
    let isEmpty = !is(props?.fields?.SkipMap?.value);
    let isClearAllEmpty = !is(props?.fields?.ClearSearchFilter?.value);
    return (
      <button
        className={`skip-map-button ${isEmpty ? `visibility-hidden` : ``}`}
        id="skip-map-btn-id"
        onClick={() => skipMap(true)}
        onFocus={() => {
          skipMap(false);
          setOnSkipMap(true);
        }}
        onBlur={() => setOnSkipMap(false)}
        onKeyDown={(event) => {
          if (!isMobile) {
            if (event.shiftKey && event.keyCode == 9) {
              if (isClearAllEmpty) {
                modifyAccessibility(event, { on: true });
              } else {
                moveFocus('clear-all-btn-id');
              }
              event.preventDefault();
            }
          }
        }}
        tabIndex={expandedFilterIndex < 0 || isMobile ? `0` : `-1`}
      >
        {isEmpty ? '' : <Text field={props?.fields?.SkipMap} />}
      </button>
    );
  };
  const clearFilter = () => {
    let isEmpty = !is(props?.fields?.ClearSearchFilter?.value);
    return (
      <div className="cso-filter-action-container">
        <button
          className={`clear-all-button ${selectedFilters?.length ? `enable-btn` : ``} ${
            isEmpty ? `visible-hidden` : ``
          }`}
          id="clear-all-btn-id"
          onClick={() => handleCounter()}
          onKeyDown={(event) => (isMobile ? null : modifyAccessibility(event, { on: true }))}
          tabIndex={expandedFilterIndex < 0 || isMobile ? `0` : `-1`}
        >
          <div className="cso-clearall-close" />
          {isEmpty ? '' : <Text field={props?.fields?.ClearSearchFilter} />}
        </button>

        {isMobile ? null : skipMapButton()}
      </div>
    );
  };
  return (
    <div className="filter-container">
      <div className="tabbed-content">
        {isMobile ? (
          <>
            {clearFilter()}
            <div
              className="tabs-list mobile"
              role="tablist"
              aria-orientation="vertical"
              aria-labelledby="tablist-1"
            >
              {filterData?.map((filter, index) => {
                return [
                  filterHeader(filter, index),
                  filterContent(filter, index),
                  <div className="tab-divider" />,
                ];
              })}
            </div>
            <div>{skipMapButton()}</div>
          </>
        ) : (
          <>
            <div
              className="tabs-list"
              role="tablist"
              aria-orientation="horizontal"
              aria-labelledby="tablist-1"
            >
              {[...filterData?.map((filter, index) => filterHeader(filter, index)), clearFilter()]}
            </div>
            {filterData?.map((filter, index) => filterContent(filter, index))}
          </>
        )}
      </div>
    </div>
  );
}
