import React from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, SubHeader } from '../../atoms';
import { Header } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import './errorPage.scss';

const ErrorPage = (props) => (
  <div className="errorPage">
    <Header centered>
      <Text field={props.fields.heading} />
    </Header>
    <SubHeader centered>
      <RichText field={props.fields.description} />
    </SubHeader>
  </div>
);

export default ErrorPage;
