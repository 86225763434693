import React, { useEffect, useState } from 'react';

import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';

import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import { Title } from '../../molecules';
import { Button } from '../../atoms';
import './orderaCard.scss';
import api from '../../services/api';
import { setTransitAgencyLogos } from '../../redux/products/actions';

const OrderaCard = (props) => {
  const shoppingCart = useSelector((reduxState) => reduxState.user.shoppingCart);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const [isCAPP, setIsCAPP] = useState(false);
  const checkAnyNonNewmediaItemInCart = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter((el) => el.visibleId !== 'NEWMEDIA');
      return check.length ? true : false;
    }
    return false;
  };
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (guestUserInfo?.isGuestLogin === true && index === 0) {
      api
        .getTransitAgencyLogosforGuestFlow()
        .then(({ data }) => {
          dispatch(setTransitAgencyLogos(data));
        })
        .catch(() => { });
      setIndex(1);
    }
  }, [guestUserInfo]);

  useEffect(() => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let TransitPass = shoppingCart.items.filter(
        (el) => el.visibleId !== 'NEWMEDIA' && el.productFamily === 'CAPP'
      );
      if (TransitPass.length > 0) {
        setIsCAPP(true);
      }
    }
  }, []);

  return (
    <div className="orderacard-wrapper">
      <Title>
        <Text field={props.fields.Title} />
      </Title>
      <div>
        {checkAnyNonNewmediaItemInCart() ? (
          <div className="orderacard-warning-note">
            <RichText field={props?.fields?.PreviousCheckoutWarningNote} />
            <div className="orderacard-warning-buttons">
              <div className="orderacard-return">
                <Button
                  white
                  firstOrder
                  onClick={() =>
                    isCAPP ? redirectToUrl(routes.transitPasses) : redirectToUrl(routes.loadMyCard)
                  }
                >
                  <Text field={props.fields.ReturnToCurrentOrderButton} />
                </Button>
              </div>
              {props.fields.CheckoutButton ? (
                <span className="orderacard-checkout">
                  <Button onClick={() => redirectToUrl(routes.checkout)}>
                    <Text field={props.fields.CheckoutButton} />
                  </Button>
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <div className="orderacard-purchasenote">
        <RichText field={props?.fields?.InPersonPurchaseNote} />
      </div>
      <RichText className="orderacard-note" field={props?.fields?.Note} />
      <div className="orderacard-newprestoinfo">
        <span className="orderacard-subtitle">
          <Text field={props.fields.NewPrestoCardTitle} />
        </span>
        <div className="orderacard-description">
          <div className="orderacard-newcardinfo">
            <div className="orderacard-leftinfo">
              <Image className="prestoimage" media={props.fields.PrestoCardIcon} loading="lazy" />
              <div className="newpresto-subtitle">
                <Text field={props.fields.PrestocardName} />
              </div>
            </div>
            <div className="orderacard-rightinfo">
              <div className="orderacard-priceValue">
                <Text field={props.fields.PriceValue} />
              </div>
              <div>
                <Text field={props.fields.PriceSubTag} />
              </div>
            </div>
          </div>
          <RichText
            className="orderacard-newcard-desc"
            field={props?.fields?.NewPrestoCardDescription}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderaCard;
