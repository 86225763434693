import { subtractDays } from '../services/utils';

export default {
  defaultPrestoTxFiltersState: {
    PeriodId: '2',
    FromDate: subtractDays(31),
    ToDate: new Date(),
    ServiceProviderId: '-1',
    TransactionCategoryId: '1,2,3,4',
  },
};
