import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import apiResponses from '../../constants/apiResponses';
import { showScreenHeader } from '../../redux/user/actions';
import routes from '../../constants/routes';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { Loader } from '../../atoms';
import { Error } from '../../molecules';
import { getQueryParams } from '../../services/utils';

import './autoload.scss';
import './autoloadPayment.scss';
import './autoloadSuccess.scss';

const Autoload = (props) => {
  const selectedCard = useSelector((reduxState) => reduxState.user.selectedCard);
  let content;
  const [component, setComponent] = useState([]);
  const [cancel, setCancel] = useState('');
  const [error, setIsError] = useState('');
  const backToMyCardButtonLink = routes.loadMyCard;
  const [firstLoad, setIsFirstLoad] = useState(true);
  const dispatch = useDispatch();
  if (firstLoad) {
    dispatch(showScreenHeader(true));
    setIsFirstLoad(false);
  }
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  content = <Loader />;

  useEffect(() => {
    if (!selectedCard) return;
    if (queryParams.success !== 'true') {
      api
        .isAutoloadAllowed({
          visibleId: selectedCard.visibleId,
        })
        .then((res) => {
          if (!res.data.Success && res.data.Error) {
            const resError = JSON.parse(res.data.Error);
            if (resError.Code === apiResponses.pendingTermination) {
              setCancel({
                autoloadCancelled: true,
              });
            } else {
              handleServerSuccessError(res.data.Error, setIsError);
            }
          } else {
            api
              .getAutoloadSubscriptionForMedia({
                visibleId: selectedCard.visibleId,
              })
              .then((response) => {
                if (response.data.Success) {
                  if (!response.data.SubscriptionList.length) {
                    setComponent({
                      subscriptionInstanceId: 'notSet',
                    });
                  } else {
                    setComponent({
                      subscriptionInstanceId:
                        response.data.SubscriptionList[0].SubscriptionInstanceId,
                      product: response.data.SubscriptionList[0].Product,
                      activationStatus: response.data.SubscriptionList[0].ActivationStatus,
                    });
                  }
                } else {
                  handleServerSuccessError(response.data.Error, setIsError);
                }
              });
          }
        });
    }
  }, [selectedCard]);

  if (
    queryParams.action === 'update' ||
    component.subscriptionInstanceId === 0 ||
    cancel.autoloadCancelled
  ) {
    content = (
      <Placeholder
        name="presto-load-my-card-manage-autoload"
        rendering={props.rendering}
        product={component.product ? component.product : null}
        backToMyCardButtonLink={backToMyCardButtonLink}
        displayActivationNote={component.activationStatus === 4}
        displayCancellationNote={cancel.autoloadCancelled}
      />
    );
  }
  if (queryParams.action === 'create' || component.subscriptionInstanceId === 'notSet') {
    content = (
      <Placeholder
        name="presto-load-my-card-set-autoload"
        rendering={props.rendering}
        backToMyCardButtonLink={backToMyCardButtonLink}
      />
    );
  }
  if (error) {
    content = <Error title={error} />;
  }
  return <>{content}</>;
};
export default Autoload;
