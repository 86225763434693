import React from 'react';
import './loader.scss';
import { withTranslation } from 'react-i18next';
const Loader = ({ isFromPaymentModal, message, t }) => (
  <div className={isFromPaymentModal ? 'payment-modal-bg' : "bg"}>
    <div className="spinner-wrapper">
      <div className="spinner-main">
        <div className="spinner-icon">
          <svg
            className="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
        <div className="spinner-text">{message ? message : (t('loadingText'))}</div>
      </div>
    </div>
  </div>
);

export default withTranslation()(Loader);
