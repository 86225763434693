import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import {
  getServerUrl,
  getLongLanguageCode,
  formatDecimalWithCurrency,
  isJsonString,
  isFrench,
} from '../../services/utils';
import sharedFormConstants from '../../constants/sharedFormConstants';
import { ButtonBlock, Button, InlineError, RadioInput, Loader } from '../../atoms';
import { CardBlock, TextInput, Error, PaymentModal } from '../../molecules';

import './checkout.scss';
import { setNewCardDetails } from '../../redux/user/actions';

const CheckoutStep1 = (props) => {
  const customerId = useSelector((reduxState) => reduxState.user.customerId);
  const email = useSelector((reduxState) => reduxState.user.email);
  const userName = useSelector((reduxState) => reduxState.user.userName);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const { mediaId, visibleId } = useSelector((reduxState) => reduxState.user.selectedCard);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const [isLoading, setIsloading] = useState(false);
  const [ticket, setTicket] = useState('');
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);

  const {
    fields,
    state,
    toPreviousStep,
    toNextStep,
    setState,
    t,
    returnUrl,
    setIsError,
    isError,
    rendering,
  } = props;

  const [encCorelationId, setEncCorelationId] = useState('');
  const [encMonerisOrder, setEncMonerisOrder] = useState('');
  const [encChargeTotal, setEncChargeTotal] = useState('');
  const [encOrderId, setEncOrderId] = useState('');
  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [hasPADInSPM, setHasPADInSPM] = useState(false);
  const [isSavedPaymentSetForCustomerSelected, setSavedPaymentSetForCustomerSelected] = useState(
    false
  );
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [isDebitError, setIsDebitError] = useState('');
  const totalAmount = state.total;
  const orderNumber = state.orderId;
  const [regFormOfPaymRecId, setRegFormOfPaymRecId] = useState('');
  const cartItems = useSelector((reduxState) => reduxState.user.shoppingCart.items);
  const [isOrderCard, setIsOrderCard] = useState(false);
  const addressType = useSelector((reduxState) => reduxState.user.addressType);
  const currentAddress = useSelector((reduxState) => reduxState.user.currentShippingDetails);
  const newShippingDetails = useSelector((redux) => redux.user.newShippingDetails);
  const userInfo = useSelector((reduxState) => reduxState.user);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const dispatch = useDispatch();
  const langCode = isFrench();
  const [checkedCard,setCheckedCard] = useState("");
  const [processdone,setProcessdone] = useState(false);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (cartItems && cartItems.length > 0 && cartItems[0].visibleId === 'NEWMEDIA') {
      setIsOrderCard(true);
    }
    if (!guestUserInfo?.isGuestLogin && !anonymous) {
      setIsloading(true);
      api
        .getRegisterPaymentMeansForCustomer({
          CustomerId: customerId,
          VisibleId: visibleId,
        })
        .then((response) => {
          if (response.data?.Success) {
            let registerPaymentMeansList = response.data.RegisterPaymentMeansList;
            let padInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 0);
            let cardInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 1);
            setShowCard({
              paymenMethodsList: registerPaymentMeansList,
            });
            setIsloading(false);
            setHasPADInSPM(padInSPM.length > 0);
            // To pre-select radio button on pageload
            if (padInSPM.length > 0) {
              let id = fields?.authorizedLabel?.value;
              setCheckedCard(id);
              document.getElementById(id).checked = true;
              setState({
                ...state,
                selectedCard: sharedFormConstants.cardOptionPreAuth,
                regFormOfPaymRecId: padInSPM[0].RegFormOfPaymRecId,
              });
              
            } else if (cardInSPM.length > 0) {
              setSavedPaymentSetForCustomer(true);
              let id = `${fields?.debitCardOptionLabel?.value} ${fields?.savedCardOptionLabel?.value}`;
              setCheckedCard(id);
              document.getElementById(id).checked = true;
              setState({ ...state, selectedCard: sharedFormConstants.cardOptionDebit });
            } else {
              let id = `${fields?.debitCardOptionLabel.value} `;
              setCheckedCard(id);
              document.getElementById(id).checked = true;
              setState({ ...state, selectedCard: sharedFormConstants.cardOptionDebit });
            }
            setSavedPaymentSetForCustomerSelected(true);
          }
          setIsloading(false);
        })
        .catch(() => {
          setIsloading(false);
        });
    }
    else if (guestUserInfo?.isGuestLogin || anonymous) {
      let id = `${fields?.debitCardOptionLabel.value} `;
      setCheckedCard(id);
      document.getElementById(id).checked = true;
      setState({ ...state, selectedCard: sharedFormConstants.cardOptionDebit });
      setIsloading(false);
    }

  }, []);

  useEffect(() => {
    if(processdone)
    setCheckboxState(checkedCard);
    
},  [processdone]);

const setCheckboxState = (id) => {
    const element = document.getElementById(id);
    if (id && element) {
        element.checked = true;
    }
};

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const postForm = () => {
    document.getElementById('frmPayment').submit();
  };

  const createOrder = (formEmail) => {
    api
      .createOrder({
        PaymentMeanId: state.regFormOfPaymRecId,
        CustomerId: customerId,
        VisibleId: isOrderCard ? 'NEWMEDIA' : visibleId,
        MediaId: mediaId,
        Email: formEmail,
        IsAnonymousUser: anonymous ? true : false,
        IsNewAddress: addressType === 'new',
        DeliveryAddress: isOrderCard ? addressType === 'new' ? {
          ...newShippingDetails,
          PhoneNumber: userInfo?.phoneNumber
          
        } : {
          FirstName: userInfo.firstName,
          LastName: userInfo.lastName,
          Line1: currentAddress?.Line2,
          Line2: currentAddress?.Line1,
          City: currentAddress?.City,
          PostalCode: currentAddress?.PostalCode,
          ShippingEmail: userInfo.loginEmail,
          ShoppingstrCartId: userInfo.shoppingCartId,
          CustomerId: customerId,
          VisibleId: 'NEWMEDIA',
          MediaId: mediaId,
          PhoneNumber: userInfo?.phoneNumber,
          CountryId: currentAddress?.Country?.Id,
          CountryName: currentAddress?.Country?.Name,
          AreaName: currentAddress?.Area?.Name,
          Province: currentAddress?.Area?.Id,
          AreaCode: currentAddress?.Area?.Id,
          AreaId: currentAddress?.Area?.Id,
          Sct: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
          Cct: guestUserInfo?.isGuestLogin ? guestSCT.cct : '',
          IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,

        } : null,
      })
      .then((response) => {
        if (response.data.Success) {
          const newState = {
            ...state,
            orderid: response.data.Order.AFMSSalesId,
            savedPaymentMethodUsed: true,
          };
          setState(newState);
          setIsError(false);
          setIsloading(false);
          toNextStep();
        } else {
          setIsError(true);
          setIsloading(false);
        }
      });
  };
  /* Commented below because calling createCachedOrder api in handleCheckoutModal function */
  // const createCachedOrder = (formEmail) => {
  //   setIsloading(true);
  //   api
  //     .createCachedOrder({
  //       CustomerId: customerId,
  //       VisibleId: visibleId,
  //       MediaId: mediaId,
  //       Email: formEmail,
  //     })
  //     .then((response) => {
  //       if (response.status !== 200) {
  //         setIsloading(false);
  //         throw Error(response.statusText);
  //       }
  //       if (response.data) {
  //         setEncCorelationId(response.data.Order.EncCorrelationId);
  //         setEncMonerisOrder(response.data.EncMonerisOrder);
  //         setEncChargeTotal(response.data.Order.EncOrderAmount);
  //         setEncOrderId(response.data.Order.EncOrderId);
  //         setState({
  //           ...state,
  //           savedPaymentMethodUsed: false
  //         });
  //         setIsloading(false);
  //       }
  //     })
  //     .then(() => {
  //       setIsError(false)
  //       setIsloading(false);
  //       postForm();
  //     })
  // };

  function handleCloseModal() {
    api
      .Cancelcachedorderfrompaymentfailure({
        Ticket: state.TicketResponse,
        Amount: totalAmount,
        AFMSSalesId: state.MonerisAfmsSalesId,
        CustomerId: customerId,
        VisibleId: visibleId,
        userName,
        EncMonerisOrder: state.encMonerisOrder,
        EncCorelationId: state.encCorelationId,
      })
      .then((response) => {
        if (response.data) {
          setIsloading(false);
        }
      });
    setIsModalOpen(false);
  }

  const handleCheckoutModal = (formEmail) => {
    setIsloading(true);
    api
      .createCachedOrder({
        CustomerId: customerId,
        VisibleId: isOrderCard ? 'NEWMEDIA' : visibleId,
        MediaId: mediaId,
        Email: formEmail,
        Sct: guestUserInfo?.isGuestLogin ? guestSCT?.sct : guestSCT?.sisct,
        Cct: guestUserInfo?.isGuestLogin ? guestSCT?.cct : guestSCT?.sicct,
        IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
        IsAnonymousUser: anonymous ? true : false,
        IsNewAddress: addressType === 'new',
        DeliveryAddress: isOrderCard ? addressType === 'new' ? {
          ...newShippingDetails,
          PhoneNumber: userInfo?.phoneNumber,
        } : {
          FirstName: userInfo.firstName,
          LastName: userInfo.lastName,
          Line1: currentAddress?.Line2,
          Line2: currentAddress?.Line1,
          City: currentAddress?.City,
          PostalCode: currentAddress?.PostalCode,
          PhoneNumber: userInfo?.phoneNumber,
          ShippingEmail: userInfo.loginEmail,
          ShoppingstrCartId: userInfo.shoppingCartId,
          CustomerId: customerId,
          VisibleId: 'NEWMEDIA',
          MediaId: mediaId,
          CountryId: currentAddress?.Country?.Id,
          CountryName: currentAddress?.Country?.Name,
          AreaName: currentAddress?.Area?.Name,
          Province: currentAddress?.Area?.Id,
          AreaCode: currentAddress?.Area?.Id,
          AreaId: currentAddress?.Area?.Id,
          Sct: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
          Cct: guestUserInfo?.isGuestLogin ? guestSCT.cct : '',
          IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,

        } : null,
      })
      .then((response) => {
        if (response.data.Success === true) {
          dispatch(setNewCardDetails(response?.data?.Order));
          setEncCorelationId(response.data.Order.EncCorrelationId);
          setEncMonerisOrder(response.data.EncMonerisOrder);
          setEncChargeTotal(response.data.Order.EncOrderAmount);
          setEncOrderId(response.data.Order.EncOrderId);
          const monerisAfmsSalesId = response.data.Order.AFMSSalesId;
          const EncMonerisOrder = response.data.EncMonerisOrder;
          const EncCorelationId = response.data.Order.EncCorrelationId;
          setState({
            ...state,
            savedPaymentMethodUsed: false,
          });
          setIsGetTicketFetching(true);
          api
            .getTicket({
              PaymentProfileType: 'PURCHASE',
              OrderNumber: monerisAfmsSalesId,
              CustomerId: customerId,
              Amount: totalAmount,
              VisibleId: visibleId,
              userName,
            })
            .then((response) => {
              if (response.data.Success === true) {
                setIsloading(false);
                setIsModalOpen(true);
                let myCheckout = new window.monerisCheckout();
                myCheckout.setMode(monerisEnvironment[0]);
                myCheckout.setCheckoutDiv('monerisCheckout');
                myCheckout.setCallback('page_loaded', myPageLoad);
                myCheckout.setCallback('cancel_transaction', myCancelTransaction);
                myCheckout.setCallback('error_event', myErrorEvent);
                myCheckout.setCallback('payment_receipt', myPaymentReceipt);
                myCheckout.setCallback('payment_complete', myPaymentComplete);
                myCheckout.startCheckout(response.data.TicketResponse.ticket);
                const ticketResponse = response.data.TicketResponse.ticket;
                setState({
                  ...state,
                  TicketResponse: ticketResponse,
                  MonerisAfmsSalesId: monerisAfmsSalesId,
                  encMonerisOrder: EncMonerisOrder,
                  encCorelationId: EncCorelationId,
                });

                function myPageLoad() {
                  setIsGetTicketFetching(false);
                }

                function myCancelTransaction() {
                  handleCloseModal();
                  if(checkedCard && document.getElementById(checkedCard)){
                    document.getElementById(checkedCard).checked = true;
                  }
                }

                function myPaymentComplete(args) {
                  document.getElementById('monerisCheckout').innerHTML = '';
                  document.getElementById('monerisCheckout').style = '';
                  const parsedData = isJsonString(args) ? JSON.parse(args) : '';
                  if (parsedData?.response_code !== '001') {
                    setIsModalOpen(false);
                    setIsDebitError(t('monerisPaymentError'));
                  } else {
                    setIsModalOpen(false);
                    setIsloading(true);
                    api
                      .CompleteOrderWithTicket({
                        Ticket: ticketResponse,
                        Amount: totalAmount,
                        AFMSSalesId: monerisAfmsSalesId,
                        CustomerId: customerId,
                        VisibleId: isOrderCard ? 'NEWMEDIA' : visibleId,
                        userName,
                        EncMonerisOrder,
                        EncCorelationId,
                      })
                      .then((response) => {
                        if (response.data.Success === true) {
                          const transactionDateTime =
                            response.data.Order.Payment.Details.TransactionDateTime;
                          const transactionType = TransactionCodeValue(
                            response.data.Order.Payment.Details.TransactionCode
                          );
                          const isoCode = response.data.Order.Payment.Details.ISOCode;
                          const responseCode = response.data.Order.Payment.Details.ResponseCode;
                          const acceptanceStatus =
                            response.data.Order.Payment.Details.AcceptanceStatus;
                          const transactionNumber =
                            response.data.Order.Payment.Details.TransactionNumber;
                          setIsloading(false);
                          setIsModalOpen(false);
                          setIsGetTicketFetching(false);
                          setState({
                            ...state,
                            AFMSSalesId: monerisAfmsSalesId,
                            TransactionDateTime: transactionDateTime,
                            TransactionType: transactionType,
                            ISOCode: isoCode,
                            ResponseCode: responseCode,
                            AcceptanceStatus: acceptanceStatus,
                            TransactionNumber: transactionNumber,
                          });
                          toNextStep();
                        } else {
                          setIsloading(false);
                          setIsModalOpen(false);
                          const parsedError = isJsonString(response?.data?.Error)
                            ? JSON.parse(response.data.Error)
                            : '';
                          setIsDebitError(parsedError?.Description);
                          if(checkedCard && document.getElementById(checkedCard)){
                            document.getElementById(checkedCard).checked = true;
                          }
                        }
                      }).catch((error) => {
                      })
                      .finally(() => {
                          setIsloading(false);
                          setProcessdone(true);
                      });
                  }
                }

                function myPaymentReceipt(args) {
                  myPaymentComplete(args);
                }

                function myErrorEvent() {
                  api
                    .Cancelcachedorderfrompaymentfailure({
                      Ticket: ticketResponse,
                      Amount: totalAmount,
                      AFMSSalesId: monerisAfmsSalesId,
                      CustomerId: customerId,
                      VisibleId: visibleId,
                      userName,
                      EncMonerisOrder,
                      EncCorelationId,
                    })
                    .then((response) => {
                      if (response.data) {
                        setIsloading(false);
                      }
                    });
                  setIsModalOpen(false);
                  const parsedError = isJsonString(response?.data?.Error)
                    ? JSON.parse(response.data.Error)
                    : '';
                  setIsDebitError(parsedError?.Description);
                }
              } else {
                setIsloading(false);
                setIsGetTicketFetching(false);
                setIsModalOpen(false);
                const parsedError = isJsonString(response?.data?.Error)
                  ? JSON.parse(response.data.Error)
                  : '';
                setIsGetTicketFetchError(parsedError?.Description);
              }
            });
        } else {
          setIsloading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsDebitError(parsedError?.Description);
        }
      }).catch((error) => {})
      .finally(() => {
          setIsloading(false);
          setProcessdone(true);
      });
  };

  const TransactionCodeValue = (transactionCode) => {
    let transactionType;
    switch (transactionCode) {
      case '0':
      case '00':
        transactionType = 'Purchase';
        break;
      case '1':
      case '01':
        transactionType = 'Pre-Authorization';
        break;
      case '6':
      case '06':
        transactionType = 'Card Verification';
        break;
      default:
        transactionType = transactionCode;
    }
    return transactionType;
  };

  const addressToShow = addressType === 'current' && currentAddress;
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <PaymentModal
            title={fields?.paymentDetailsTitle?.value}
            cautionMessage={t('monerisPaymentCautionMessage')}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isGetTicketFetching={isGetTicketFetching}
            disableBackdropClick
            disableEscapeKeyDown
            handleCloseModal={handleCloseModal}
          />
          {isOrderCard && (currentAddress || newShippingDetails) && (
            <div className="shipping-add-details">
              <div className="shipping-header">
                <Text field={fields.ShippingstepTitle} />
              </div>
              <div>
                <div className="shipping-block">
                  <RichText field={fields.Shippingnewaddressnote} />
                </div>
                {addressType === 'current' ? (
                  <div className="shipping-street">
                    <p>
                      <b>
                        {currentAddress?.Line1} {currentAddress?.Line2}
                      </b>
                    </p>
                    <p>
                      {currentAddress?.City}
                      {currentAddress?.City && currentAddress?.Area?.Code ? `, ` : ''}
                      {currentAddress?.Area?.Code}
                    </p>
                    <p>{currentAddress?.Country?.Id}</p>
                    <p>{currentAddress?.PostalCode}</p>
                  </div>
                ) : (
                  <div className="shipping-street">
                    <p>
                      <b>
                        {newShippingDetails?.Line1} {newShippingDetails?.Line2}
                      </b>
                    </p>
                    <p>
                      {newShippingDetails?.City}
                      {newShippingDetails?.City && newShippingDetails?.AreaCode ? `, ` : ''}
                      {newShippingDetails?.AreaCode}
                    </p>
                    <p>{newShippingDetails?.CountryId}</p>
                    <p>{newShippingDetails?.PostalCode}</p>
                  </div>
                )}
              </div>
            </div>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              [sharedFormConstants.terms]: false,
              [sharedFormConstants.cardOption]: isSavedPaymentSetForCustomer
                ? sharedFormConstants.cardOptionSaved
                : sharedFormConstants.cardOptionDebit,
              terms: false,
              username: email,
            }}
            validationSchema={Yup.object().shape({
              [sharedFormConstants.terms]: Yup.boolean().oneOf(
                [true],
                t('termsAndConditionsRequired')
              ),
              username: Yup.string()
                .min(6, t('checkoutInvalidEmail'))
                .matches(
                  /^[\w+_-]+(?:\.[\w+_-]+)*@(?:[\w\d-]+\.)+\w{2,6}$/,
                  t('checkoutInvalidEmail')
                ),
            })}
            onSubmit={(values) => {
              setProcessdone(false);
              const newState = { ...state };
              if (newState.selectedCard === sharedFormConstants.cardOptionPreAuth) {
                setIsloading(true);
                createOrder(values.username);
              } else {
                handleCheckoutModal(values.username);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values }) => {
              const checkOption =
                values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionDebit;

              return (
                <form className="emailaddress-checkout" onSubmit={handleSubmit}>
                  <CardBlock title={fields.emailAddressTitle.value}>
                    <RichText field={fields.emailAddressDescription} />
                    <div className="checkout-textInput">
                      <TextInput
                        errors={errors}
                        touched={touched}
                        name="username"
                        label={fields.emailAddressTextboxLabel}
                        inputA11y={fields.emailAddressTextboxA11y.value}
                      />
                    </div>
                    {errors[sharedFormConstants.username] &&
                      touched[sharedFormConstants.username] && (
                        <InlineError cssClass="checkbox-error">
                          {errors[sharedFormConstants.username]}
                        </InlineError>
                      )}
                    <div className="checkout-text">
                      <Text field={fields.emailAddressTextboxAnnotation} />
                    </div>
                  </CardBlock>
                  {isError ? (
                    <Error
                      small
                      title={
                        isSavedPaymentSetForCustomerSelected
                          ? t('savedPaymentCouldNotBeProcessed')
                          : t('directPaymentCouldNotBeProcessed')
                      }
                    />
                  ) : null}
                  {isDebitError ? <Error small title={isDebitError} /> : null}
                  <CardBlock title={fields.paymentMethodTitle.value}>
                    <div
                      className="payment-text"
                      id="radio-group"
                      role="application"
                      aria-label={
                        isOrderCard
                          ? fields?.BuyaprestopaymentMethodDescription.value
                          : fields.paymentMethodDescriptionA11y.value
                      }
                    >
                      <Text
                        field={
                          isOrderCard
                            ? fields?.BuyaprestopaymentMethodDescription
                            : fields.paymentMethodDescription
                        }
                      />
                    </div>
                    <div className={langCode ? 'payment-radio-fr' : 'payment-radio'}>
                      <RadioInput
                        label={`${fields.debitCardOptionLabel.value} ${isSavedPaymentSetForCustomer ? fields?.savedCardOptionLabel?.value : ''
                          }`}
                        a11y={fields.debitCardOptionLabel.value}
                        name={sharedFormConstants.cardOption}
                        value={sharedFormConstants.cardOptionDebit}
                        onChange={(e) => {
                          setState({ ...state, selectedCard: e.target.value });
                          setCheckedCard(e.target.id);
                        }}
                      />
                    </div>
                    {hasPADInSPM && (
                      <div className={langCode ? 'payment-radio-fr' : 'payment-radio'}>
                        {showCard &&
                          showCard.paymenMethodsList &&
                          showCard.paymenMethodsList.map((card, index) => {
                            if (card.AccountType === 0) {
                              return (
                                <RadioInput
                                  label={fields.authorizedLabel.value}
                                  a11y={fields.authorizedLabelA11y.value}
                                  name={sharedFormConstants.cardOption}
                                  value={sharedFormConstants.cardOptionPreAuth}
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      selectedCard: e.target.value,
                                      regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                    });
                                    setCheckedCard(e.target.id);
                                  }}
                                />
                              );
                            }
                          })}
                      </div>
                    )}
                    <div className="checkout-terms-block">
                      <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                      {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                        <InlineError cssClass="checkbox-error">
                          {errors[sharedFormConstants.terms]}
                        </InlineError>
                      )}
                    </div>
                    <div className="checkout-payment-total">
                      <Text field={fields.totalAmountLabel} />
                      <span>{` ${formatDecimalWithCurrency(state.total)}`}</span>
                    </div>
                  </CardBlock>
                  {state.selectedCard !== sharedFormConstants.cardOptionPreAuth ? (
                    <div className="checkout-subtext">
                      <p>
                        <Text field={fields.monerisNote} />
                      </p>
                    </div>
                  ) : null}
                  <div className="ticket-error">
                    {isGetTicketFetchError ? (
                      <Error small title={isGetTicketFetchError} noBg />
                    ) : null}
                  </div>
                  <ButtonBlock>
                    <ButtonBlock right className="button-order-revere">
                      {state.selectedCard === sharedFormConstants.cardOptionPreAuth ? (
                        <Button
                          type="submit"
                          buttonTextA11y={fields.paymentDetailsConfirmButtonTextA11y.value}
                        >
                          <Text field={fields.paymentDetailsConfirmButtonText} />
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          buttonTextA11y={fields.paymentDetailsNextButtonTextA11y.value}
                        >
                          <Text field={fields.paymentDetailsConfirmButtonText} />
                        </Button>
                      )}
                      <Button
                        onClick={() => toPreviousStep()}
                        white
                        buttonTextA11y={fields.paymentDetailsBackButtonTextA11y.value}
                      >
                        <Text field={fields.paymentDetailsBackButtonText} />
                      </Button>
                    </ButtonBlock>
                  </ButtonBlock>
                </form>
              );
            }}
          </Formik>

          <div>
            {configKeys &&
              configKeys
                .filter((item) => item.key === 'Cpg.Url')
                .map((configKey) => (
                  <form
                    method="POST"
                    id="frmPayment"
                    name="frmPayment"
                    style={{ display: 'none' }}
                    action={configKey.setting}
                  >
                    <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
                    <input
                      type="hidden"
                      name="LANGUAGE"
                      id="LANGUAGE"
                      value={getLongLanguageCode()}
                    />
                    <input
                      type="hidden"
                      name="enc_charge_total"
                      id="enc_charge_total"
                      value={encChargeTotal}
                    />
                    <input type="hidden" name="enc_order_id" id="enc_order_id" value={encOrderId} />
                    <input type="hidden" name="rvarIsAx" value="true" />
                    <input
                      type="hidden"
                      name="responseURL"
                      id="responseURL"
                      value={`${getServerUrl()}/apidata/Sales/CreateMonerisOrder?encCorrelationId=${encCorelationId}&encMonerisOrder=${encMonerisOrder}&returnUrl=${returnUrl}`}
                    />
                    <input
                      type="hidden"
                      name="enc_correlation_id"
                      id="enc_correlation_id"
                      value={encCorelationId}
                    />
                  </form>
                ))}
          </div>
        </>
      )}
    </>
  );
};

export default withTranslation()(CheckoutStep1);
