import React, { useEffect, useState } from 'react';

import './inComplete.scss';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const InCompleteBlock = ({ title, titleA11y, Content, contextText, richText }) => {
  return (
    <div className="incomplete-block">
      <div
        className={`incomplete-block-title ${contextText ? '' : ' without-margin-bottom'}`}
        aria-label={titleA11y}
        role="alert"
      >
        <p>
          <Text field={title} />
        </p>
      </div>
      {contextText && (
        <div className="incomplete-context-text">
          {richText ? <RichText field={contextText} /> : <Text field={contextText} />}
        </div>
      )}
      <div className="incomplete-block-text">{Content ? <Content /> : null}</div>
    </div>
  );
};

export default function InComplete({ title, titleA11y, Content, contextText, richText }) {
  const [inCompleteContent, setInCompleteContent] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setInCompleteContent(
        <InCompleteBlock
          Content={Content}
          title={title}
          titleA11y={titleA11y}
          contextText={contextText}
          richText={richText}
        />
      );
    }, 100);
  }, []);

  return (
    <div aria-live="assertive" aria-atomic="true">
      {inCompleteContent}
    </div>
  );
}
