import React from 'react';

import './checkboxComponent.scss';
import { Field } from 'formik';

export default function Checkbox({
  children,
  onChange,
  name,
  checkboxA11y,
  value,
  checked,
  regularFont,
  className='',
}) {
  return (
    <div className={`checkboxComponent ${className}`}>
      <label aria-label={checkboxA11y}>
        {name ? (
          <Field value={value} name={name} type="checkbox" />
        ) : (
          <input
            checked={checked}
            onChange={(e) => onChange && onChange(e.target.checked)}
            type="checkbox"
          />
        )}
      </label>
      <div className={`checkboxComponent-label ${regularFont ? 'regular-font' : undefined}`}>
        {children}
      </div>
    </div>
  );
}
