export default {
  Invalid: 0,
  RegisteredNotActive: 1,
  RegisteredActivationPending: 2,
  RegisteredActivationExpired: 3,
  // ActiveUnregistered: 4, //For future release
  ActiveRegistrationPending: 5,
  ActiveRegistrationExpired: 6,
  ActiveRegistered: 7,
  BlockedRegistered: 8,
  BlockedReplacementPending: 9,
  BlockedUnregistered: 10,
  BlockedForReplacement: 11,
  BlockedForPendingTransfer: 12,
  BlockedForTransfer: 13,
  Error: 14,
  Expired: 15,
};
