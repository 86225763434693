import React from 'react';

import { withTranslation } from 'react-i18next';
import './exportButton.scss';

const ExportButton = ({ t, onClick }) => (
  <button type="button" onClick={onClick} className="export-button" aria-label="export">
    {t('exportButtonLabel')}
  </button>
);

export default withTranslation()(ExportButton);
