import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '../../atoms';
import './createAccount.scss';

const AccountCreationModal = ({ fields, showPopup, gotoDashboard, anonymousStatus }) => {
  const [open, setOpen] = useState(false);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);

  useEffect(() => {
    if (showPopup) {
      setOpen(true);
    }
  }, [showPopup]);

  const handleClose = () => {
    // setOpen(false);
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleClose}
      aria-labelledby="modal-header"
      aria-describedby="modal-subHeader"
      open={open}
    >
      <DialogContent>
        <div className="acc-create-success-header">
          {anonymous ? (
            anonymousStatus ? (
              <h2 id="modal-header" className="acc-create-success-header-text">
                {fields.AccCreationSuccessAssociationSuccess.value}
              </h2>
            ) : (
              <h2 id="modal-header" className="acc-create-success-header-text">
                {fields.AccCreationSuccessAssociationFailed.value}
              </h2>
            )
          ) : (
            <h2 id="modal-header" className="acc-create-success-header-text">
              {fields.AccountCreationSuccess.value}
            </h2>
          )}
          {anonymous ? (
            anonymousStatus ? (
              <div id="modal-subHeader" className="acc-create-success-header-subText">
                {fields.AccCreationSuccessAssociationSuccessDesc.value}
              </div>
            ) : (
              <div id="modal-subHeader" className="acc-create-success-header-subText">
                {fields.AccCreationSuccessAssociationFailedDesc.value}
              </div>
            )
          ) : (
            <div id="modal-subHeader" className="acc-create-success-header-subText">
              {fields.AccountCreationSuccessDesc.value}
            </div>
          )}
          <div className="account-padding btn-created-flex">
            <div className="btn-padding">
              <Button buttonTextA11y={fields.CloseTextA11y.value} onClick={gotoDashboard}>
                <Text field={fields.CloseText} />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AccountCreationModal;
