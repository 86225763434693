import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { dismissNotification, updateNotification } from '../../redux/user/actions';
import { ButtonBlock, Button } from '../../atoms';
import localStorageKeys from '../../constants/localStorageKeys';
import { formatUtcDate, localstorageService, redirectToUrl } from '../../services/utils';
import chevronDown from '../../assets/images/down.svg';
import chevronUp from '../../assets/images/up.svg';
import mediaSubTypes from '../../constants/mediaSubTypes';
import api from '../../services/api';

const CollapsibleNotification = ({
  notification,
  fields,
  index,
  isExpanded = false,
  onToggle,
  t,
  notificationTypes,
  verificationSuccessNotificationExists,
  IsRiskyUser = false,
  IsRiskyUserDismissed = false,
}) => {
  const dispatch = useDispatch();
  let fareType = '';
  const userEmail = useSelector((state) => state.user.email || state.user.loginEmail);

  const hasCta =
    fields[notification?.ctaField]?.value?.text && fields[notification?.ctaField]?.value?.href;
  let contentField;

  if (notification?.isFareTypeExpiry) {
    const isConcessionStudent = t(notification?.fareType) === t('Student');
    const isAnonymous = notification?.type === mediaSubTypes.anonymous;
    const isPrestoCard = notification?.type === mediaSubTypes.presto;
    fareType =
      isPrestoCard || isAnonymous
        ? `${isConcessionStudent ? t('Youth') : t(notification.fareType)}${notification.specificFare ? `${''} ${notification.specificFare}` : ''
        } `
        : t('Adult');
    const expiryValue = notification?.expiryDate?.split('T');
    const formattedExpiryDate = expiryValue ? formatUtcDate(expiryValue[0]) : null;
    contentField = {
      ...fields[notification?.contentField],
      value: fields[notification?.contentField]?.value
        .replace('{fare_type}', fareType)
        .replace('{card_nickname}', notification.nickName)
        .replace('{expiration_date}', formattedExpiryDate),
    };
  } else {
    contentField = {
      ...fields[notification?.contentField],
      value: fields[notification?.contentField]?.value.replace(
        '{email}',
        `< span class= "notifications-userEmail" > ${userEmail}</span> `
      ),
    };
  }
  return (
    <>
      {!notification?.isDismissed && (
        <div
          className={`notifications-collapsibleNotification ${notification.type === notificationTypes.verificationSuccessNotification &&
            verificationSuccessNotificationExists
            ? 'green-left-border'
            : ''
            }`}
          tabIndex="-1"
        >
          <div
            role="button"
            id={`collapsible-tab-${index}`}
            onClick={() => onToggle(notification?.orderIndex)}
            onKeyPress={() => onToggle(notification?.orderIndex)}
            aria-expanded={isExpanded}
            aria-controls={`collapsible-panel-${index}`}
            tabIndex="0"
          >
            <div className="notifications-collapsibleNotification-header">
              <Text
                field={{
                  value: fields[notification?.titleField]?.value.replace('{fare_type}', fareType),
                }}
              />
              <img
                className="notifications-headerToggleBtn"
                src={isExpanded ? chevronUp : chevronDown}
                alt={isExpanded ? `${t('expandA11y')} ` : `${t('collapseA11y')} `}
              />
            </div>
          </div>
          {isExpanded && (
            <div
              className="notifications-collapsibleContent"
              id={`collapsible-panel-${index}`}
              aria-labelledby={`collapsible-tab-${index}`}
            >
              <RichText field={contentField} />
              <ButtonBlock>
                <ButtonBlock right>
                  {hasCta && (
                    <Button
                      onClick={() => redirectToUrl(fields[notification?.ctaField]?.value.href)}
                    >
                      {fields[notification?.ctaField]?.value.text}
                    </Button>
                  )}
                  <Button
                    firstOrder
                    onClick={() => {
                      onToggle(notification?.orderIndex);
                      if (IsRiskyUser && notification.type === notificationTypes.mfaLoginNotification) {
                        dispatch(
                          updateNotification('type', notification?.type, {
                            isDismissed: true,
                          })
                        );
                        localstorageService().setItem(localStorageKeys.isRiskyUserDismissed, 'true')
                      }
                      else if (notification.type === notificationTypes.mfaLoginNotification) {
                        dispatch(
                          updateNotification('type', notification?.type, {
                            isDismissed: true,
                          })
                        );
                        api.b2cUpdateMFANotification();
                      } else if (
                        notification.type === notificationTypes.passwordExpiryNotification
                      ) {
                        dispatch(
                          updateNotification('type', notification?.type, {
                            isDismissed: true,
                          })
                        );
                        api.b2cPasswordMFANotification();
                      } else if (
                        notification.type === notificationTypes.verificationSuccessNotification
                      ) {
                        dispatch(
                          updateNotification('type', notification?.type, {
                            isDismissed: true,
                          })
                        );
                      } else {
                        dispatch(
                          updateNotification('visibleId', notification?.visibleId, {
                            isDismissed: true,
                          })
                        );
                      }

                    }}
                    white={hasCta}
                    tabIndex="1"
                  >
                    {fields.dismiss.value}
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withTranslation()(CollapsibleNotification);
