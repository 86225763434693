import { combineReducers } from 'redux';

import { user } from './user/reducers';
import { products } from './products/reducers';
import { settings } from './settings/reducers';
import { businessRules } from './businessRules/reducers';
import { contactless } from './contactless/reducers';

export default combineReducers({
  user,
  products,
  settings,
  businessRules,
  contactless,
});
