import React, { useState, useRef, useEffect } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import routes from '../../constants/routes';
import printIcon from '../../assets/images/printer.svg';
import { IconButton, Button, Loader } from '../../atoms';
import { StepTracker, TextInput, Note } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import api from '../../services/api';
import { setChangeCardPinMode } from '../../redux/products/actions';
import './changepin.scss';

const ChangePIN = (props) => {
  const { t } = props;
  const manageMyProduct = useSelector((reduxState) => reduxState.products.manageMyProduct);
  const [step, setStep] = useState(0);
  const toNextStep = () => setStep(step + 1);
  const [loading, setLoading] = useState(false);
  const [checkError, setCheckError] = useState(true);
  const [newPin, updateNewPin] = useState('');
  const [oldPin, updateOldPin] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [confirmNewPin, updateConfirmNewPin] = useState('');
  const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
  const user = useSelector((reduxState) => reduxState.user);
  const fareMedia = useSelector((reduxState) => {
    let presto = reduxState.user.fareMedias.find(
      (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
    );
    let contactless = reduxState.user.contactlessMedias.find(
      (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
    );
    if (presto) return presto;
    if (contactless) return contactless;
    return {};
  });
  const PIN_REGEX = /^[0-9]{4}$/;
  const dispatch = useDispatch();
  const focusDiv = useRef();

  const onNewPinChange = (e) => {
    updateNewPin(e.target.value);
  };
  const onConfirmNewPinChange = (e) => {
    updateConfirmNewPin(e.target.value);
  };
  const changePinSubmitHandler = () => {
    if (checkError) {
      let button = document.getElementById('changepin-submit-button');
      button.click();
      return;
    }

    let card = userInfo?.Data?.Customer?.FareMedias.find(
      (el) => el.VisibleId === fareMedia?.visibleId
    );
    setLoading(true);
    let payload = {
      CustomerId: user?.customerId,
      VisibleId: fareMedia?.visibleId,
      MediaId:fareMedia?.mediaId,
      NewPIN: newPin,
      OldPIN: card?.PIN,
      Subject: user?.subject,
    };
    api
      .saveChangePinDetails(payload)
      .then((response) => {
        if (response?.data.Success) {
          setApiStatus({ submit: true, state: 'SUCCESS' });
          toNextStep();
        } else {
          setApiStatus({ submit: true, state: 'Error' });
          toNextStep();
        }
        setLoading(false);
        updateNewPin('');
        updateConfirmNewPin('');
        setCheckError(true);
      })
      .catch((err) => {
        setApiStatus({ submit: true, state: 'Error' });
        toNextStep();
        setLoading(false);
      });
  };
  useEffect(() => {
    if (apiStatus.submit) {
      focusDiv.current.focus();
      document.body.scrollTop = 0;
    }
  }, [apiStatus]);
  useEffect(() => {
    if (manageMyProduct?.changeCardPinEnabled) {
      document.body.scrollTop = 0;
      focusDiv.current.focus();
      getUserInfo();
    }
  }, [manageMyProduct]);

  const getUserInfo = () => {
    setLoading(true);
    api
      .getUserInfo()
      .then((response) => {
        setUserInfo(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const showPostSubmitMessage = () => {
    return (
      <div>
        {apiStatus.state === 'SUCCESS' ? (
          <div
            className="change-card-pin-message"
            style={{ color: 'green', backgroundColor: 'white' }}
          >
            <div className="success-message" role="alert">
              <div id="message">{props.fields.Succesmessage.value}</div>
            </div>
            <div className="message-pin-description">
              <p>
                {props.fields.Pinchangedtext.value} : <b>{fareMedia?.nickName}</b>
              </p>
              <p className="pin-note-pad">{props.fields.Pinchangedendnote.value}</p>
            </div>
          </div>
        ) : (
          <div className="change-card-pin-message-error">
            <div className="error-pin-message" role="alert">
              <div id="messageerror">{props.fields.FailureErrorlabel.value}</div>
            </div>
            <div className="message-pin-description">
              <p>{props.fields.FailureErrorContent.value}</p>
            </div>
          </div>
        )}
        <div className="changepin-footer ">
          <div className="mangage-btn">
            <Button
              onClick={() => {
                setStep(0);
                setApiStatus({ submit: false, state: 'INITIAL' });
                dispatch(
                  setChangeCardPinMode({
                    changeCardPinEnabled: false,
                    selectedCard: {},
                  })
                );
              }}
            >
              <Text field={props.fields.ManagemyproductsLabel} />
            </Button>
          </div>
          <div id="printiconchangepin">
            <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
              {props.fields.Printtextlabel.value}
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return [
    manageMyProduct?.changeCardPinEnabled ? (
      <div className="change-card-pin">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <div className="steps">
          <StepTracker
            activeIndex={step}
            items={[
              {
                text: <Text field={props.fields.Stepper1text} />,
                // textA11y: props.fields.Stepper1textA11y.value,
                textInProgressA11y: props.fields.Step1InProgressA11y.value,
                textCompletedA11y: props.fields.Step1CompletedA11y.value,
              },
              {
                text: <Text field={props.fields.Stepper2text} />,
                // textA11y: props.fields.Stepper2textA11y.value,
                textNotCompletedA11y: props.fields.Step2NotCompleteA11y.value,
                textCompletedA11y: props.fields.Step2CompletedA11y.value,
                hasError: apiStatus.state === 'Error' ? true : false,
              },
            ]}
          />
        </div>
        <div className="change-pin-title">
          <Text field={props.fields.Changepintitle} />
        </div>
        {!apiStatus.submit ? (
          <>
            <div className="changepin-wrapper">
              <div className="createpin-title">
                <div>
                  <span>{props.fields.Newpintitle.value}</span>
                </div>
                <div>
                  <span>
                    <b>{fareMedia?.nickName}</b>
                  </span>
                </div>
              </div>
              <div className="pincontent-divider"></div>
              <div className="pinnote">
                <Note>
                  <RichText field={props.fields.Informationtext} />
                </Note>
              </div>
              <Formik
                initialValues={{
                  NewPin: '',
                  ConfirmNewPin: '',
                }}
                enableReinitialize={true}
                validate={(values) => {
                  const errors = {};
                  if (!values.NewPin) {
                    errors.NewPin = props.fields.PinErrorlabel.value;
                  } else if (!PIN_REGEX.test(values.NewPin)) {
                    errors.NewPin = props.fields.NewpinLabelErr.value;
                  }
                  if (!values.ConfirmNewPin) {
                    errors.ConfirmNewPin = props.fields.ConfirmPinErr.value;
                  } else if (values.NewPin !== values.ConfirmNewPin) {
                    errors.ConfirmNewPin = props.fields.MatchingpinErr.value;
                  }

                  if (
                    Object.prototype.hasOwnProperty.call(errors, 'NewPin') ||
                    Object.prototype.hasOwnProperty.call(errors, 'ConfirmNewPin')
                  ) {
                    setCheckError(true);
                  } else {
                    setCheckError(false);
                  }

                  return errors;
                }}
                validateOnChange={true}
                // validationSchema={Yup.object().shape({
                //   NewPin: Yup.string()
                //     .required(props.fields.PinErrorlabel.value)
                //     .matches(/^[0-9]{4}$/, props.fields.NewpinLabelErr.value),
                //   ConfirmNewPin: Yup.string()
                //     .required(props.fields.ConfirmPinErr.value)
                //     .matches(/^[0-9]{4}$/, props.fields.NewpinLabelErr.value)
                //     .oneOf([Yup.ref('NewPin'), null], props.fields.MatchingpinErr.value),
                // })}
                // onSubmit={(values) => {
                //   console.log(values);
                //   setApiStatus({ submit: true, state: 'SUCCESS' });
                //   toNextStep();
                // }}
              >
                {({ values, errors, touched, handleSubmit, handleChange }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="textinput-flex">
                        <div className="firstinput-wrapper">
                          <TextInput
                            type="password"
                            value={values.NewPin}
                            name="NewPin"
                            errors={errors}
                            touched={touched}
                            required={true}
                            onChange={(e) => {
                              handleChange(e);
                              onNewPinChange(e);
                            }}
                            label={props.fields.NewpinLabel}
                            showIconA11y={props.fields.showPinA11y?.value}
                            hideIconA11y={props.fields.hidePinA11y?.value}
                            withViewIcon
                            disabledAutocomplete
                            bellowText={props.fields.Maxdigitlabel}
                          />
                          {/* <p className="pin-min-length">{props.fields.Maxdigitlabel.value}</p> */}
                        </div>
                        <div className="secondinput-wrapper">
                          <TextInput
                            type="password"
                            value={values.ConfirmNewPin}
                            name="ConfirmNewPin"
                            errors={errors}
                            required={true}
                            touched={touched}
                            onChange={(e) => {
                              handleChange(e);
                              onConfirmNewPinChange(e);
                            }}
                            label={props.fields.Confirmpinlabel}
                            showIconA11y={props.fields.showConfirmPinA11y?.value}
                            hideIconA11y={props.fields.hideConfirmPinA11y?.value}
                            withViewIcon
                            disabledAutocomplete
                            bellowText={props.fields.Maxdigitlabel}
                          />
                          {/* <p className="pin-min-length">{props.fields.Maxdigitlabel.value}</p> */}
                        </div>
                      </div>
                      <button className="d-none" id="changepin-submit-button" type="submit">
                        submit
                      </button>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="changepin-buttons">
              <span className="confirm-btn ">
                <Button
                  // isDisabled={checkError || newPin.trim() === '' || confirmNewPin.trim() === ''}
                  onClick={() => {
                    changePinSubmitHandler();
                  }}
                >
                  <Text field={props.fields.ConfirmButton} />
                </Button>
              </span>
              <div className="">
                <Button white firstOrder onClick={() => redirectToUrl(routes.manageMyCards)}>
                  <Text field={props.fields.CancelButton} />
                </Button>
              </div>
            </div>
          </>
        ) : (
          showPostSubmitMessage()
        )}
      </div>
    ) : null,
    loading ? <Loader /> : null,
  ];
};

export default withTranslation()(ChangePIN);
