import moment from 'moment';

import 'moment-timezone';
import 'moment/locale/fr-ca';
import SitecoreContextFactory from '../lib/SitecoreContextFactory';
import langConstants from '../constants/lang';
import localStorageKeys from '../constants/localStorageKeys';
import apiconfig from '../temp/config';
import TA from '../constants/transitAgency.constants';
import TA_TYPES from '../constants/transactionType.constants';
import api from './api';

export const toggleClass = (value, cssClass) => (value ? cssClass : '');
export const localstorageService = () =>
  typeof localStorage !== 'undefined'
    ? localStorage
    : {
      clear: () => undefined,
      getItem: () => undefined,
      key: () => null,
      removeItem: () => undefined,
      setItem: () => undefined,
    };
export const sessionstorageService = () =>
  typeof sessionStorage !== 'undefined'
    ? sessionStorage
    : {
      clear: () => undefined,
      getItem: () => undefined,
      key: () => undefined,
      removeItem: () => undefined,
      setItem: () => undefined,
    };
export const windowService = () => (typeof window !== 'undefined' ? window : {});
export const documentService = () => (typeof document !== 'undefined' ? document : {});

export const getCookie = (name) => {
  let cookie = documentService && documentService().cookie;
  if (cookie === undefined) return undefined;

  /* eslint no-useless-escape: "off" */
  let matches = documentService().cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const isFrench = () => {
  const location = windowService().location;
  const langCookie = getCookie('presto-ui#lang');
  return langCookie
    ? langCookie === langConstants.longFr
    : location &&
    location.pathname &&
    location.pathname.indexOf(`/${langConstants.longFr}/`) !== -1;
};

export const getErrorTranslation = (errorCode, callback = null) => {
  let language = 'en';
  if (isFrench()) {
    language = 'fr-CA';
  }
  let data = {
    errorcode: errorCode,
    language,
  };
  api
    .getErrorTranslation(data)
    .then((response) => {
      if (callback) callback(response);
      return response;
    })
    .catch(() => {
      // console.log('error');
    });
};

export const getShortLanguageCode = () => (isFrench() ? langConstants.shortFr : langConstants.en);

export const getLongLanguageCode = () => (isFrench() ? langConstants.longFr : langConstants.en);

export const getLanguageLcid = () => (isFrench() ? langConstants.lcidFr : langConstants.lcidEn);

export const getLanguageAtsId = () => (isFrench() ? langConstants.atsFr : langConstants.atsEn);

export const redirectToUrl = (url, includeLanguage = true, isInternalLink = true) => {
  setTimeout(() => {
    if (isInternalLink) {
      window.location.assign(includeLanguage ? `/${getLongLanguageCode()}${url}` : url);
    } else {
      window.location.href = url;
    }
  }, 1000);
};

export const redirectToUrlinCaseofFailure = (
  url,
  includeLanguage = true,
  isInternalLink = true
) => {
  localstorageService().removeItem('redux');
  localstorageService().removeItem(localStorageKeys.isRiskyUser);
  localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
  if (isInternalLink) {
    window.location.assign(includeLanguage ? `/${getLongLanguageCode()}${url}` : url);
  } else {
    window.location.href = url;
  }
};

export const redirectToUrlInNewWindow = (url) => window.open(url);

export const getParentUrl = (url) => {
  let urlSlashIndex = url.lastIndexOf('/');
  let urlParentLink = url.substring(0, urlSlashIndex + 1);
  return urlParentLink;
};

export const setLanguage = ({ sitecoreRoute }) => {
  let langCode = isFrench() ? langConstants.en : langConstants.longFr;
  if (sitecoreRoute === undefined) sitecoreRoute = '';
  const url = `/${langCode}/${sitecoreRoute}`;
  window.location.href = url;

  let context = SitecoreContextFactory.getSitecoreContext();
  SitecoreContextFactory.setSitecoreContext({
    ...context,
    language: langCode,
    refresh: true,
  });
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const formatDateWithComma = (date) =>
  date && moment(date).locale(getLongLanguageCode()).utcOffset(0).format('MMMM D, YYYY');

export const formatDateWithoutUTC = (date) =>
  date && moment(date).locale(getLongLanguageCode()).format('MMMM D, YYYY');

export const formatDate = (date, format = 'D MMM YYYY') =>
  date && moment(date).locale(getLongLanguageCode()).utcOffset(0).format(format);

export const formattoLocalDate = (date, format = 'D MMM YYYY') => {
  let diff = new Date().getTimezoneOffset();
  let offset = diff / -60;
  return date && moment(date).locale(getLongLanguageCode()).utc(offset).format(format);
};

export const checkTokenExpiry = (loggedInTime, loggedOutTime) => {
  let diffBwNowAndLogin = moment.utc().diff(loggedInTime, 'minutes');
  let diffBwNowAndLogout = moment.utc().diff(loggedOutTime, 'minutes');
  let isAfter1Hr = diffBwNowAndLogin >= 60 && diffBwNowAndLogout >= 0;

  return { isAfter1Hr };
};

{
  /*export const check1hrTillLogin = (loggedInTime) => {
  //let diffbwlogoutandlogin = moment(logOutTime).utc().diff(moment(loggedInTime), 'minutes');
  let diffBwNowAndLogin = moment.utc().diff(loggedInTime, 'minutes');
  //console.log("diffbwlogoutandlogin: " + diffbwlogoutandlogin + " minutes");

  // isAfter1Hr2 becomes true if the diff b/w login time and current time is more than 60 minutes.
  //let isAfter1Hr = diffBwNowAndLogin > 1 ? true : false;
  //console.log("isAfter1Hr2: " + isAfter1Hr2 + " " + diffBwNowAndLogin);

  // isAfter1Hr becomes true if the diff b/w login and logout time is more than 60 minutes.
  //let isAfter1Hr = diffbwlogoutandlogin > 60 ? true : false;
  //let diffBwNowAndLogout = moment.utc().diff(logOutTime, 'seconds');
  let isAfter1Hr = diffBwNowAndLogin > 60 ? true : false;
  //console.log("timeFromLogout: " + timeFromLogout + ' seconds');

  // isBetween1minBeforeLogout becomes true if the current time is less than 1 minute from logout time.
  //let isBetween1minBeforeLogout = (timeFromLogout > -60 && timeFromLogout < 0) ? true : false;

  return { isAfter1Hr };
};*/
}

export const check15MinTillLogin = (contactlessLoginTime, contactlessLogoutTime) => {
  let diffbwlogoutandlogin = moment.utc().diff(moment(contactlessLoginTime), 'minutes');
  // isAfter15Min becomes true if the diff b/w login and current time is more than 15 minutes.
  let isAfter15Min = diffbwlogoutandlogin > 15 ? true : false;
  return { isAfter15Min, contactlessLoginTime, contactlessLogoutTime };
};
export const formatDateTx = (date) =>
  date && moment(date).locale(getLongLanguageCode()).utcOffset(0).format('MM/DD/YYYY');

export const formatMonthYear = (date) =>
  moment(date).locale(getLongLanguageCode()).utcOffset(0).format('MMM YYYY');

export const formatUtcDate = (date, utcOffset = 0) =>
  moment
    .utc(date, 'YYYY-MM-DDTHH:mm:ss')
    .utcOffset(utcOffset)
    .locale(getLongLanguageCode())
    .format('D MMM YYYY');

export const formatUtcTimeFromDate = (date, utcOffset = 0) =>
  moment.utc(date, 'YYYY-MM-DDTHH:mm:ss').utcOffset(utcOffset).format('h:mm A');

export const formatUtcTimeFromDate2 = (date, utcOffset = 0) =>
  moment.utc(date, 'YYYY-MM-DD HH:mm:ss').utcOffset(utcOffset).format('D MMM YYYY h:mm A');

export const formatDateTime = (date) => moment(date).format('D MMM YYYY h:mm A');

export const formatUtcDateToEasternTime = (date) =>
  moment
    .utc(date, 'YYYY-MM-DDTHH:mm:ss')
    .locale(getLongLanguageCode())
    .tz('America/Toronto')
    .format('D MMM YYYY');
export const getdateTimefromESTdate = (date, isTime, utcOffset = 0) => {
  if (isTime) {
    return moment(date, 'h:mm A D MMM YYYY').locale(getLongLanguageCode()).format('h:mm A');
  } else {
    return moment(date, 'h:mm A D MMM YYYY').locale(getLongLanguageCode()).format('D MMM YYYY');
  }
};
export const formatUtcTimeFromDateToEasternTime = (date) =>
  moment.utc(date, 'YYYY-MM-DDTHH:mm:ss').tz('America/Toronto').format('h:mm A');

export const getServerUrl = () => {
  const location = windowService().location;
  return location && location.protocol && location.hostname
    ? `${location.protocol}//${location.hostname}`
    : `${apiconfig.sitecoreApiHost}`;
};

export const formatDecimal = (value) => {
  if (value) {
    return isFrench()
      ? Number.parseFloat(value).toFixed(2).replace('.', ',')
      : Number.parseFloat(value).toFixed(2);
  }
  return isFrench() ? '0,00' : '0.00';
};

export const formatDecimalWithCurrency = (value) => {
  if (isFrench()) {
    return `${value < 0 ? '-' : ''}${formatDecimal(Math.abs(value))}$`;
  }
  return `${value < 0 ? '-' : ''}$${formatDecimal(Math.abs(value))}`;
};

export const formatPrestoCardNumber = (cardNumber) => {
  return cardNumber?.replace(/(\d{6})(\d{4})(\d{4})(\d{3})/, '$1-$2-$3-$4');
};

export const formatPrestoCardNumberA11y = (cardNumber) =>
  cardNumber && cardNumber.split('').join(' ');

export const getQueryParam = (receivedData) =>
  receivedData.split('&').map((results) => results.split('=').splice(1, 1));

// url can be in any form (e.g. 'http://example.com/?a=A&b=B#xyz' or '?a=A&b=B' or 'a=A&b=B')
// it returns query parameters as object (e.g. { a: A, b: B })
export const getQueryParams = (url) => {
  if (!url) {
    return {};
  }

  const urlParts = url.split('#')[0].split('?');
  const queryString = urlParts.length >= 2 ? urlParts[1] : urlParts[0];
  const params = queryString.split('&');

  let result = {};
  params.forEach((param) => {
    const keyValuePair = param.split('=');
    result[keyValuePair[0]] = (keyValuePair[1] && decodeURIComponent(keyValuePair[1])) || '';
  });
  return result;
};

export const getStationNameFromParam = (station) => {
  if (station === undefined) return '';
  return station.replace(/^\d+\s?:\s?/, '');
};

export const getDateFromParam = (dateFromParam) =>
  dateFromParam && dateFromParam[0] && dateFromParam[0].replace(/%20/g, ' ');

export const shallowObjEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => obj1[key] === obj2[key]);
};

export const sortItems = (fieldName, ignoreCase = false) => (a, b) => {
  let field1 = ignoreCase ? a[fieldName].toUpperCase() : a[fieldName];
  let field2 = ignoreCase ? b[fieldName].toUpperCase() : b[fieldName];
  const notEqual = field1 < field2 ? -1 : 1;
  return field1 === field2 ? 0 : notEqual;
};

export const hideString = (value) => value.replace(/[a-zA-Z0-9 ]/g, '•');

export const isOneOf = (items, value) => items.indexOf(value) !== -1;

export const convertToDollars = (value) => value / 100;

export const getRefreshToken = () => {
  return localstorageService().getItem(localStorageKeys.refreshToken);
};
export const getLocalStorageItem = (key) => {
  return localstorageService().getItem(key);
};
export const setLocalStorageItem = (key, value) => {
  localstorageService().setItem(key, value);
};
export const replaceDate = (message, date) => message.replace('{date}', formatDate(date));
export const replaceDatewithOutFormate = (message, date) => message.replace('{date}', date);
export const updateRecaptchaToken = (values, configKeys, callBack) => {
  const GoogleRecaptchaV3SiteKey =
    configKeys &&
    configKeys
      .filter((item) => item.key === 'GoogleRecaptchaV3.SiteKey')
      .map((config) => config.setting);
  // console.log(GoogleRecaptchaV3SiteKey[0]);

  let valuesToUpdate = values;
  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(GoogleRecaptchaV3SiteKey[0], { action: 'contactus' })
      .then((token) => {
        // console.log("token from util method: " + token);
        valuesToUpdate.googleToken = token;
        // console.log(valuesToUpdate);
        callBack(valuesToUpdate);
      });
  });
};

export const indexOfSelectedCard = (faremedias, visibleId) => {
  let i = 0;
  for (i; i < faremedias.length; i += 1) if (faremedias[i].visibleId === visibleId) break;
  return i;
};

export const getAllMediasFromUserState = (userState) => {
  return userState.fareMedias.concat(userState.contactlessMedias);
};

export const hasAnyCard = (userState) => {
  let allMedias = getAllMediasFromUserState(userState);
  return allMedias.length > 0 && allMedias[0].mediaId !== '';
};

export const togglePageScroll = (disableScroll) => {
  const document = documentService();
  const isAndroid = /Android/i.test(navigator.userAgent);
  if (document.body) {
    if (disableScroll) {
      document.body.classList.add('disable-scroll');
      if (isAndroid) {
        document.body.style.overflow = 'auto !important';
        document.documentElement.style.overflow = 'auto !important';
      }
    } else {
      document.body.classList.remove('disable-scroll');
      if (isAndroid) {
        document.body.style.overflow = 'visible';
        document.documentElement.style.overflow = 'visible';
      }
    }
  }
};

export const hasLongInputLabel = (field1, field2) => {
  return field1?.value.length > 25 || field2?.value.length > 25;
};

export const resetAccessTokenValidity = () => {
  const lifetime =
    (localstorageService().getItem(localStorageKeys.accessTokenLifetime) - 5 * 60) * 1000;
  localstorageService().setItem(localStorageKeys.accessTokenValidity, Date.now() + lifetime);
};

export const getArrayBetweenDates = (startDate, endDate, step = 'months', returnFormat = 'M') => {
  let now = startDate.clone();
  let dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(returnFormat));
    now.add(1, step);
  }
  return dates;
};

export const subtractDays = (days) => {
  let date = new Date();
  date.setDate(date.getDate() - days);
  return date;
};

export const getTimePeriod = (startDate, endDate) => {
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const getTimePeriodTx = (startDate, endDate) => {
  return `${formatDateTx(startDate)} - ${formatDateTx(endDate)}`;
};

export const getParameterCaseInsensitive = (object, key, defaultValue = '') => {
  const asLowercase = key.toLowerCase();
  let value = object[Object.keys(object).find((k) => k.toLowerCase() === asLowercase)];
  return value ? value : defaultValue;
};

export const checkUPorGO = (ServiceProviderID) =>
  [TA.GO_TRANSIT, TA.UP_EXPRESS].includes(ServiceProviderID);

export const getCardTransactionTypes = (transData, fields) => {
  const loadTypes = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
  };
  const locationTypes = {
    MOBILE_APP: 'Mobile App',
  };
  const { TrxTypeId, LoadType, TapType, Amount, ServiceProviderID, Type, Location } = transData;
  let finalObj = {};
  let nullType;
  let tapOn;
  let tapOff;
  let defaultTrip;
  let farePayment;
  let fareAdjTapOffNotDetected;
  let tapOffNotDetected;
  let loadAmount;
  let autoLoadAmount;
  let transitPassTap;
  let freeTransfer;
  let inPerson;
  let webOrAppLoad;
  let transitPassAutoRenew;
  let defaultType;
  let cardSettingChange;
  let cardActivation;
  let paymentByCredit;
  let paymentbyCardBalance;
  let paymentbyCash;
  if (
    TapType != null &&
    (ServiceProviderID === TA.GO_TRANSIT || ServiceProviderID === TA.UP_EXPRESS)
  ) {
    switch (TapType) {
      case 0:
        nullType = true;
        break;
      case 1:
        // BUG - 23831
        if (checkUPorGO(ServiceProviderID)) {
          tapOn = true;
        } else {
          farePayment = true;
        }
        break;
      case 2:
        // BUG - 23831
        if (checkUPorGO(ServiceProviderID)) {
          tapOff = true;
        }
        break;
      case 3:
        if (ServiceProviderID === TA.GO_TRANSIT) {
          defaultTrip = true;
        }
        break;
      default:
        break;
    }
  } else if (TrxTypeId === 15 || Type === fields?.transactionTypeTransitPassPayment?.value) {
    transitPassTap = true;
  } else if (TrxTypeId === 16 && Amount === 0) {
    freeTransfer = true;
  } else if (TrxTypeId === 2 && (LoadType === loadTypes[1] || LoadType === loadTypes[2])) {
    inPerson = true;
  } else if (TrxTypeId === 2 && (LoadType === loadTypes[4] || LoadType === loadTypes[6])) {
    webOrAppLoad = true;
  } else if (TrxTypeId === 2 && (LoadType === loadTypes[5] || LoadType === loadTypes[7])) {
    transitPassAutoRenew = true;
  } else if (TrxTypeId === 1 && LoadType === loadTypes[3]) {
    autoLoadAmount = true;
  } else if (TrxTypeId === 1 && (LoadType === loadTypes[5] || LoadType === loadTypes[6])) {
    if (ServiceProviderID && ServiceProviderID === TA.GO_TRANSIT) {
      fareAdjTapOffNotDetected = true;
    } else if (ServiceProviderID && ServiceProviderID === TA.UP_EXPRESS) {
      tapOffNotDetected = true;
    }
  } else if (
    TrxTypeId === 1 &&
    (LoadType === loadTypes[2] ||
      LoadType === loadTypes[4] ||
      (Location === locationTypes.MOBILE_APP && LoadType === loadTypes[8]))
  ) {
    loadAmount = true;
  } else if (TrxTypeId === 11) {
    cardSettingChange = true;
  } else if (TrxTypeId === 12) {
    cardActivation = true;
  } else if (TrxTypeId === 23) {
    paymentByCredit = true;
  } else if (TrxTypeId === 22) {
    paymentbyCardBalance = true;
  } else if (TrxTypeId === 4) {
    paymentbyCash = true;
  } else if (Type !== null) {
    defaultType = true;
  }
  finalObj = {
    nullType,
    tapOn,
    tapOff,
    defaultTrip,
    farePayment,
    fareAdjTapOffNotDetected,
    tapOffNotDetected,
    loadAmount,
    autoLoadAmount,
    transitPassTap,
    freeTransfer,
    inPerson,
    webOrAppLoad,
    transitPassAutoRenew,
    defaultType,
    cardSettingChange,
    cardActivation,
    paymentByCredit,
    paymentbyCardBalance,
    paymentbyCash,
  };
  return finalObj;
};

export const blockTransactionHistoryEntry = (transData) => {
  const { ServiceProviderID } = transData;
  const { paymentByCredit, tapOff, paymentbyCardBalance, paymentbyCash } = getCardTransactionTypes(
    transData
  );
  if (paymentByCredit) {
    return true;
  }
  if (paymentbyCardBalance) {
    return true;
  }
  if (paymentbyCash) {
    return true;
  }
  if (tapOff && !checkUPorGO(ServiceProviderID)) {
    return true;
  }
  return false;
};
export const scrollToView = () => {
  let currentLocation = window.location.href;
  const hasAnchor = currentLocation.includes('#');
  if (hasAnchor) {
    const anchorId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`;
    const anchorEl = document.getElementById(anchorId);
    if (anchorEl) {
      anchorEl.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export const TransactionCodeValue = (transactionCode) => {
  let transactionType;
  switch (transactionCode) {
    case '0':
    case '00':
    case 0:
      transactionType = 'Purchase';
      break;
    case '1':
    case '01':
    case 1:
      transactionType = 'Pre-Authorization';
      break;
    case '6':
    case '06':
    case 6:
      transactionType = 'Card Verification';
      break;
    default:
      transactionType = transactionCode;
  }
  return transactionType;
};

export const downloadCsvFile = (
  csvHeader,
  customerData,
  fileName = 'TX history.csv',
  id = 'csv_download_link'
) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  let headers = csvHeader.toString();
  csvContent += `${headers}\r\n`;
  let rowData = customerData.reduce((previous, next) => {
    let options = [];
    options.push(
      ...[
        next.TransactionDateTime,
        next.TransitAgency,
        next.Location,
        next.TapType,
        next.Amount,
        next.ConfirmationID,
        formatDate(next.ChargedDate),
        next.ChargeStatus,
        next.LoyaltyDiscount,
        next.TransferDiscount,
        next.DailyCAPDiscount,
      ]
    );
    previous.push(options);

    return previous;
  }, []);
  csvContent += rowData.map((e) => e.join(',')).join('\n');

  let encodedUri = encodeURI(csvContent);
  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('id', id);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  let elem = document.getElementById(id);
  elem.parentNode.removeChild(elem);
};

export const downloadContactlessCsvFile = (
  csvHeader,
  customerData,
  fileName = 'TX history.csv',
  id = 'csv_download_link'
) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  let headers = csvHeader.toString();
  csvContent += `${headers}\r\n`;
  let rowData = customerData.reduce((previous, next) => {
    let options = [];
    options.push(
      ...[
        next.TransactionDateTime,
        next.TransitAgency,
        next.TapType,
        next.Amount,
        next.ConfirmationID,
        next.ChargedDate,
        next.ChargeStatus,
        next.LoyaltyDiscount,
        next.TransferDiscount,
        next.DailyCAPDiscount,
      ]
    );
    previous.push(options);

    return previous;
  }, []);
  csvContent += rowData.map((e) => e.join(',')).join('\n');

  let encodedUri = encodeURI(csvContent);
  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('id', id);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  let elem = document.getElementById(id);
  elem.parentNode.removeChild(elem);
};
export function is(valueToCheck) {
  let isArr = Array.isArray(valueToCheck);
  function check(val) {
    if (val && val.trim() !== '') {
      return true;
    }
    return false;
  }
  if (isArr) {
    return valueToCheck.some((val) => check(val));
  }
  return check(valueToCheck);
}
export function sortNotifications(notifications) {
  const notificationTypes = {
    noEmailVerificationNotification: 'noEmailVerificationNotification',
    unverifiedEmailVerificationNotification: 'unverifiedEmailVerificationNotification',
    mfaLoginNotification: 'mfaLoginNotification',
    passwordExpiryNotification: 'passwordExpiryNotification',
    verificationSuccessNotification: 'verificationSuccessNotification',
  };
  let filterNotifications = [...notifications];
  let updatedNotifications = [];
  if (filterNotifications?.length) {
    const index = filterNotifications.findIndex(
      (obj) => obj.type === notificationTypes.verificationSuccessNotification
    );
    if (index !== -1 && index !== 0) {
      const notification = filterNotifications.splice(index, 1)[0];
      filterNotifications.unshift(notification);
    }

    // Order of notifications
    const notificationOrder = [
      'verificationSuccessNotification',
      'presto',
      'passwordExpiryNotification',
      'mfaLoginNotification',
    ];

    // Sorting the array of notifications
    updatedNotifications = filterNotifications.sort((a, b) => {
      const typeA = notificationOrder.indexOf(a.type || 'presto');
      const typeB = notificationOrder.indexOf(b.type || 'presto');
      return typeA - typeB;
    });
  }
  let notificationsWithOrder = updatedNotifications.map((el, index) => ({
    ...el,
    orderIndex: index + 1,
  }));
  return notificationsWithOrder;
}
