import React, { useEffect } from 'react';

import { redirectToUrl } from '../../services/utils';

const Redirection = (props) => (
  <div>
    {props.fields.redirectionMessage.value.replace(
      '{waitTimeInSeconds}',
      props.fields.waitTimeInSeconds.value
    )}
    {useEffect(() => {
      setTimeout(() => {
        redirectToUrl(props.fields.redirectionLink?.value.href);
      }, props.fields.waitTimeInSeconds.value * 1000);
    }, [])}
  </div>
);

export default Redirection;
