import React, { useState, useRef, useEffect } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { StepTracker } from '../../molecules';
import UnpaidFares0 from './UnpaidFares0';
import UnpaidFares1 from './UnpaidFares1';
import UnpaidFares2 from './UnpaidFares2';
import './unpaidFares.scss';
import { useLocation, useHistory } from 'react-router-dom';
import { getQueryParams, localstorageService } from '../../services/utils';
import localStorageKeys from '../../constants/localStorageKeys';
import handleServerError from '../../services/handleServerError';
import api from '../../services/api';

const stepsMap = [UnpaidFares0, UnpaidFares1, UnpaidFares2];

const UnpaidFares = (props) => {
    const { t } = props;
    const [step, setStep] = useState(0);
    const focusDiv = useRef();
    const toNextStep = () => {
        setStep(step + 1);
        focusDiv.current.focus();
    };
    const toFirstStep = () => {
        setStep(0);
        focusDiv.current.focus();
    };
    const toPreviousStep = () => {
        setStep(step - 1);
        focusDiv.current.focus();
    };
    const Stepper = stepsMap[step];
    const [state, setState] = useState({});
    const location = useLocation();
    const history = useHistory();
    const queryParams = getQueryParams(useLocation().search);
    const [error, setError] = useState('');

    useEffect(() => {

        if (queryParams.dedtRecovery !== undefined && queryParams.dedtRecovery === 'debtRecovery') {
            const unpaidFaresData = localstorageService().getItem(localStorageKeys.unpaidFaresData);
            const data = localstorageService().getItem(localStorageKeys.unpaidFaresReposneData);
            setState({
                ...state,
                data: data,
                unpaidFaresData: unpaidFaresData,
                uuid: queryParams.param1,
                unpaidFareIndex: queryParams.param2,
            });
            const queryParams1 = new URLSearchParams(location.search);
            if (queryParams1.has('dedtRecovery')) {
                queryParams1.delete('dedtRecovery');
                history.replace({
                    search: queryParams1.toString(),
                });
            }
            if (queryParams1.has('param1')) {
                queryParams1.delete('param1');
                history.replace({
                    search: queryParams1.toString(),
                });
            }
            if (queryParams1.has('param2')) {
                queryParams1.delete('param2');
                history.replace({
                    search: queryParams1.toString(),
                });
            }
            api
                .getSTSCloudClaims()
                .then((res) => {
                    if (res) {
                        localstorageService().setItem(
                            localStorageKeys.stsAccessToken,
                            res.data.Data.Access_token
                        );
                        // localstorageService().setItem(
                        //   localStorageKeys.refreshToken,
                        //   res.data.Data.Access_token
                        // );
                        // localstorageService().setItem(
                        //   localStorageKeys.accessTokenLifetime,
                        //   res.data.Data.Expires_in
                        // );

                    }
                    setStep(1);
                })
                .catch((res) => {
                    handleServerError(undefined, setError)(res);
                });
        }
    }, []);

    return (
        <div className="no-focus-outline">
            <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
                {t('StepperChangeSRText')}
            </span>
            {step ? (
                <StepTracker
                    activeIndex={step - 1}
                    items={[
                        {
                            text: <Text field={props.fields.step1Title} />,
                            textInProgressA11y: props.fields.step1TitleInProgressA11y.value,
                            textCompletedA11y: props.fields.step1TitleCompletedA11y.value,
                        },
                        {
                            text: <Text field={props.fields.step2Title} />,
                            textNotCompletedA11y: props.fields.step2TitleNotCompletedA11y.value,
                            textCompletedA11y: props.fields.step2TitleCompletedA11y.value,
                            hasError: !(state.data && state.data.Success),
                        },
                    ]}
                />
            ) : null}

            <Stepper
                {...props}
                state={state}
                setState={setState}
                toNextStep={toNextStep}
                toFirstStep={toFirstStep}
                toPreviousStep={toPreviousStep}
                rendering={props.rendering}
                fields={props.fields}
            />
        </div>
    );
};

export default withTranslation()(UnpaidFares);
