import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { setActivateCardMode } from '../../redux/products/actions';
import api from '../../services/api';
import { showScreenHeader } from '../../redux/user/actions';
import { isJsonString, redirectToUrlInNewWindow } from '../../services/utils';
import { Button, ButtonBlock } from '../../atoms';
import { Error, InlineButtonBlock, TextInput } from '../../molecules';

const AdditionalFunctions = ({ fields, t }) => (
  <div className="add-card inside-buttons">
    <div className="add-card-title" aria-label={fields.transferBalanceTitleA11y?.value}>
      <Text field={fields.transferBalanceTitle} />
    </div>
    <div className="add-card-content">
      <div
        className="add-card-content-text"
        aria-label={fields.transferbalanceDescriptionA11y?.value}
      >
        <RichText field={fields.transferbalanceDescription} />
      </div>
      <ButtonBlock>
        <ButtonBlock right>
          <Button
            iconAfter="externalLinkIcon"
            iconSize="sm"
            iconColor="white"
            iconAriaLabel={t('opensNewWindowA11y')}
            onClick={() => redirectToUrlInNewWindow(fields.transferBalanceButtonLink?.value?.href)}
          >
            {fields.transferBalanceButtonLink?.value?.text}
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </div>
  </div>
);

const ActivateCard = ({ fields, t }) => {
  const dispatch = useDispatch();
  return (
    <div className="add-card inside-buttons">
      <div className="add-card-title" aria-label={fields.activateNewCardTitleA11y.value}>
        <Text field={fields.activateNewCardTitle} />
      </div>
      <div className="add-card-content">
        <div
          className="add-card-content-text"
          aria-label={fields.activateNewCardDescriptionA11y.value}
        >
          <div className="add-card-content-logo">
            <Image media={fields.newCardLogoImage} loading="lazy"/>
          </div>
          <RichText field={fields.activateNewCardDescription} />
        </div>
        <ButtonBlock>
          <ButtonBlock right>
            <Button
              iconAfter="externalLinkIcon"
              iconSize="sm"
              iconColor="white"
              iconAriaLabel={t('opensNewWindowA11y')}
              onClick={() => {
                redirectToUrlInNewWindow(fields.activateNewCardButtonLink?.value?.href);
                dispatch(
                  setActivateCardMode({
                    activateCardDetailsEnabled: false,
                    addToActivate: true,
                    selectedCard: {},
                  })
                );
              }}
            >
              {fields.activateNewCardButtonLink?.value?.text}
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      </div>
    </div>
  );
};

const AddPrestoCard0 = (props) => {
  const PAPER_FORM_REGISTERED_CARD_STATUS = 1;
  const userInfo = useSelector((reduxState) => reduxState.user);
  const customerId = useSelector((reduxState) => reduxState.user.customerId);
  const { fields, state, setState, toNextStep, t } = props;
  const [error, setIsError] = useState('');

  let runValidation;
  const dispatch = useDispatch();
  const [firstLoad, setIsFirstLoad] = useState(true);
  if (firstLoad) {
    dispatch(showScreenHeader(true));
    setIsFirstLoad(false);
  }
  useEffect(() => {
    if (runValidation) {
      runValidation();
    }
  }, [t]);

  return (
    <>
      <div className="add-card inside-buttons">
        <div className="add-card-title" aria-label={fields.enterDetailsTextA11y.value}>
          <Text field={fields.enterDetailsText} />
        </div>
        <div className="add-card-content">
          <div
            className="add-card-content-text"
            aria-label={fields.credentialsDescriptionA11y.value}
          >
            <RichText field={fields.credentialsDescription} />
          </div>
          <div className="add-card-content-image">
            <Image media={fields.credentialsImage} loading="lazy"/>
          </div>
          {error ? <Error small title={error} /> : null}
          <div className="add-card-content-form">
            <Formik
              enableReinitialize
              initialValues={{
                MediaId: '',
                VerificationNumber: '',
              }}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                MediaId: Yup.string()
                  .required(t('addCardValidationRequiredCardNumber'))
                  .matches(/^[0-9]{17}$/, t('addCardValidationPrestoCard')),
                VerificationNumber: Yup.string()
                  .required(t('addCardValidationRequiredVerificationNumber'))
                  .matches(/^[0-9]{3}$/, t('addCardValidationVerificationNumber')),
              })}
              onSubmit={(values) => {
                api
                  .verifyCard({
                    MediaId: values.MediaId,
                    CVN: values.VerificationNumber,
                    CustomerId: customerId,
                  })
                  .then((response) => {
                    if (response.data.Success) {
                      if (
                        response.data.CardStatus === PAPER_FORM_REGISTERED_CARD_STATUS &&
                        userInfo.fareMedias.length
                      ) {
                        setIsError(fields.paperFormRegisteredCardNotFirstCardErrorMessage.value);
                      } else {
                        const { CardStatus, ChildCustomerId, VerificationHash } = response.data;
                        setState({
                          ...state,
                          MediaId: values.MediaId,
                          CardStatus,
                          CVN: values.VerificationNumber,
                          CustomerId: customerId,
                          ChildCustomerId,
                          VerificationHash,
                        });
                        dispatch(showScreenHeader(false));
                        toNextStep();
                      }
                    } else if (isJsonString(response.data.Error)) {
                      const errorMessage = JSON.parse(response.data.Error);
                      setIsError(errorMessage.Description);
                    }
                  });
              }}
            >
              {({ errors, touched, handleSubmit, validateForm }) => {
                runValidation = validateForm;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="add-card-form-wrapper">
                      <div
                        className="add-card-input add-card-spacing"
                        aria-label={fields.cardNumberTextA11y.value}
                      >
                        <TextInput
                          name="MediaId"
                          errors={errors}
                          touched={touched}
                          label={fields.cardNumberText}
                          length={17}
                        />
                      </div>
                      <div
                        className="add-card-input add-card-spacing"
                        aria-label={fields.verificationNumberTextA11y.value}
                      >
                        <TextInput
                          name="VerificationNumber"
                          errors={errors}
                          touched={touched}
                          label={fields.verificationNumberText}
                          length={3}
                        />
                      </div>
                      <InlineButtonBlock
                        type="submit"
                        buttonText={fields.nextButtonText}
                        buttonTextA11y={fields.nextButtonTextA11y.value}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div className="add-card-additional" aria-label={fields.additionalFunctionsTextA11y.value}>
        <Text field={fields.additionalFunctionsText} />
      </div>
      <AdditionalFunctions fields={fields} t={t} />
      <ActivateCard fields={fields} t={t} />
    </>
  );
};

export default AddPrestoCard0;
