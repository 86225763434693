import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonBlock, PrintButton, Loader } from '../../../../atoms';
import routes from '../../../../constants/routes';
import { Note, Success, Modal } from '../../../../molecules';
import api from '../../../../services/api';
import {
  formatDate,
  formatDecimalWithCurrency,
  formatDateWithComma,
  formatDateTime,
} from '../../../../services/utils';
import './TCCCheckout1.scss';

const TTCCheckout1 = ({
  ttcFields,
  state,
  product,
  pauseAndReinstate,
  autorenewSubscriptionList,
  transitPassfields,
  userInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const visibleId = userInfo.selectedCard.visibleId;
  const nickname = userInfo.selectedCard.nickName;
  const [discountResponse, setDiscountResponse] = useState('');
  const [isTTCModalOpen, setIsTTCModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  function getTimePeriod(startDate, endDate) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  const logo = useSelector(
    (state) => state.products?.logos[product?.ProductServiceProvider?.Id]?.Logo
  );
  const todaydate = new Date();

  const viewPaymentPlan = (productId) => {
    setIsLoading(true);
    api
      .getAutorenewPassDiscounts({
        productId,
        IsStubEnabled: false,
        IsErrorFlowEnabled: true,
      })
      .then((response) => {
        setIsLoading(false);
        setDiscountResponse(response?.data?.MDPDetails);
        setIsTTCModalOpen(true);
        setIsPaymentModalOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('error', error);
      });
  };
  const paymentClickHandler = (e) => {
    if (e.keyCode == 13) {
      viewPaymentPlan(product.ProductId);
    }
  };

  let startDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.StartDateTime;
  startDate = startDate && startDate.split('T');
  const formattedStartDate = formatDateWithComma(startDate && startDate.length > 0 && startDate[0]);

  let endDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.ExpiryDateTime;
  endDate = endDate && endDate.split('T');
  const formattedEndDate = formatDateWithComma(endDate && endDate.length > 0 && endDate[0]);
  useEffect(() => {
    if (isPaymentModalOpen) {
      document.body.scrollTop = 0;
    }
  }, [isPaymentModalOpen]);
  const successData = {
    value: 'Success Data',
  };
  return (
    <>
      {isLoading && <Loader />}
      {isPaymentModalOpen ? (
        <div className="payment-plan-modal">
          <Modal
            title={transitPassfields.TTC12MonthMDPTitle?.value}
            text={
              <>
                <RichText field={transitPassfields.TTC12MonthMDPDescription} />
                <div className="payment-plan-wrapper">
                  <div className="payment-plan-header">
                    <Text field={transitPassfields.TTC12MonthMDPSubTitle} />
                  </div>
                  <div className="payment-plan-content">
                    <div className="payment-plan-subtitle">
                      <span className="ttcTerm">
                        <Text field={transitPassfields.TermMonth} />
                      </span>
                      <span className="ttcDiscount">
                        <Text field={transitPassfields.TTCPassDiscount} />
                      </span>
                      <span className="ttcPrice">
                        <Text field={transitPassfields.DiscountPrice} />
                      </span>
                    </div>
                    {discountResponse &&
                      discountResponse.length > 0 &&
                      discountResponse.map((data) => {
                        return (
                          <div className="payment-plan-data">
                            <span className="ttcTerm">{data.Term}</span>
                            <span className="ttcDiscount">{data.Discount}%</span>
                            <span className="ttcPrice">
                              {formatDecimalWithCurrency(data.DiscountedPrice)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="payment-plan-button">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsTTCModalOpen(false);
                      setIsPaymentModalOpen(false);
                    }}
                  >
                    {transitPassfields.CloseCTA?.value}
                  </Button>
                </div>
              </>
            }
            textSubmit={transitPassfields.CloseCTA?.value}
            isModalOpen={isTTCModalOpen}
            onSubmit={() => {
              setIsTTCModalOpen(false);
              setIsPaymentModalOpen(false);
            }}
            setIsModalOpen={setIsTTCModalOpen}
            hideButtons
            withoutFocusTrap={true}
          />
        </div>
      ) : null}
      {product?.ProductName ? (
        <Success
          title={
            !pauseAndReinstate ? ttcFields?.PurchaseSuccessMessage : ttcFields?.UpdateSuccessMessage
          }
          contextText={pauseAndReinstate ? ttcFields?.UpdateSuccessMessageDescription : ''}
          Content={() => (
            <>
              {!pauseAndReinstate ? (
                <>
                  <div className="ttc-pass-nickname">
                    <span>{nickname} - </span>
                    <span className="ttc-pass-visibleId">{visibleId}</span>
                  </div>
                  <div className="succeess-agencyOption-wrapper">
                    <div className="success-agencyOption-header">
                      <span>
                        <Text field={ttcFields?.itemLabel} />
                      </span>
                      <span>
                        <Text field={ttcFields?.priceLabel} />
                      </span>
                    </div>
                    <div className="success-agencyOption-content">
                      <div className="success-agencyOption-title-logo-block">
                        {logo && logo.Src ? (
                          <img
                            alt={logo.Alt}
                            className="success-agencyOption-title-logo"
                            src={logo.Src}
                          />
                        ) : null}
                      </div>
                      <div className="agencyOption-title-block">
                        <div>
                          <div className="agencyOption-title" aria-label={product.ProductName}>
                            {product.ProductName}
                          </div>
                          <div className="agencyOption-date">
                            {getTimePeriod(product.ValidityStartDate, product.ValidityEndDate)}
                          </div>
                          <div className="update-note-ttc">
                            <Text field={ttcFields?.TTC12MonthPassNote} />
                          </div>
                        </div>
                      </div>
                      <div className="agencyOption-value-block">
                        {product?.ProductFamily === 'MDP' ? (
                          <div className="agencyOption-value">
                            {formatDecimalWithCurrency(state?.discountedProduct?.DiscountedPrice)}
                          </div>
                        ) : (
                          <div className="agencyOption-value">
                            {formatDecimalWithCurrency(product?.ProductPrice)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="payment-amount">
                      <div className="payment-label">
                        <Text field={ttcFields?.MonthlyPaymentFare} />
                      </div>
                      <div>
                        {product?.ProductFamily === 'MDP'
                          ? `${formatDecimalWithCurrency(
                            state?.discountedProduct?.DiscountedPrice
                          )}`
                          : `${formatDecimalWithCurrency(product?.ProductPrice)}`}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        />
      ) : null}
      {pauseAndReinstate ? (
        <div className="agencyOption-wrapper">
          <div className="agencyOption-header">
            <span>
              <Text field={ttcFields?.Item} />
            </span>
            {/* {!pauseAndReinstate && (
            <span>
              <Text field={ttcFields?.priceLabel} />
            </span>
          )} */}
          </div>

          {pauseAndReinstate ? (
            <>
              <div className="agencyOption-Pause-content">
                <div className="agencyOption-title-logo-block">
                  {logo && logo.Src ? (
                    <img alt={logo.Alt} className="agencyOption-title-logo" src={logo.Src} />
                  ) : null}
                </div>
                <div className="agencyOption-title-block">
                  <div>
                    <div className="agencyOption-title" aria-label={product.ProductName}>
                      {autorenewSubscriptionList &&
                        autorenewSubscriptionList.length > 0 &&
                        autorenewSubscriptionList[0]?.Product.ProductName}
                    </div>
                    <div className="agencyOption-details">
                      <div className="agencyOption-startdate">
                        <span>
                          <Text field={ttcFields?.StartDate} />{' '}
                        </span>
                        <span>{formattedStartDate}</span>
                      </div>
                      <div className="agencyOption-enddate">
                        <span>
                          <Text field={ttcFields?.EndDate} />{' '}
                        </span>
                        <span>{formattedEndDate}</span>
                      </div>
                      <div className="agencyOption-discount">
                        <span>
                          <Text field={ttcFields?.AccumulatedDiscount} />{' '}
                        </span>
                        <span>
                          {autorenewSubscriptionList &&
                            autorenewSubscriptionList.length > 0 &&
                            autorenewSubscriptionList[0]?.MDPAccumulatedDiscount}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div>View Discounted Plan</div> */}
                  <a
                    href="javascript:void(0)"
                    className="pause-Link"
                    onClick={(e) => {
                      e.preventDefault();
                      viewPaymentPlan(product.ProductId);
                    }}
                    onKeyDown={paymentClickHandler}
                  >
                    <Text field={ttcFields?.ViewDiscountPlan} />
                  </a>
                </div>
              </div>
              <div className="update-note">
                <Text field={ttcFields?.TTC12MonthPassNote} />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {state?.showConfirmation && (
        <div className="success-block-details">
          {state?.TransactionType && (
            <div className="transaction-type">
              <span>
                <Text field={ttcFields?.transactionTypeLabel} />{' '}
              </span>
              <span className="bold-content">{state?.TransactionType}</span>
            </div>
          )}

          {state?.ResponseCode && (
            <div className="response-code">
              <span>
                {' '}
                <Text field={ttcFields?.responseCodeMessageLabel} />{' '}
              </span>
              <span className="bold-content">
                {state?.ResponseCode} - {state?.AcceptanceStatus}
              </span>
            </div>
          )}

          {state?.ISOCode && (
            <div className="iso-code">
              <span>
                <Text field={ttcFields?.isoCodeLabel} />{' '}
              </span>
              <span className="bold-content">{state?.ISOCode}</span>
            </div>
          )}

          <div className="date-time">
            <span>
              <Text field={ttcFields?.dateTimeLabel} />
            </span>
            <span className="bold-content">{formatDateTime(todaydate)}</span>
          </div>

          {state?.TransactionNumber && (
            <div className="reference-number">
              <span>
                <Text field={ttcFields?.referenceNumberLabel} />
              </span>
              <span className="bold-content">{state?.TransactionNumber}</span>
            </div>
          )}
        </div>
      )}

      <ButtonBlock className="print-hidden">
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={() => {
              window.location.href = routes.transitPasses;
            }}
          >
            <Text field={ttcFields?.GoToTransferPassCTA} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>

      <Note className="checkout-note">
        <RichText field={ttcFields?.UpdateSuccessNote} />
      </Note>
    </>
  );
};

export default TTCCheckout1;
