import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleServerError from '../../services/handleServerError';
import { InlineButtonBlock, TextInput, Error } from '../../molecules';

export default function ForgotPassword1({ fields, toNextStep, t, setState, setIsServerError }) {
  const [error, setIsError] = useState('');

  return (
    <>
      {error ? <Error title={error} /> : null}
      <div className="forgot-content">
        <div className="forgot-step">
          <div className="forgot-text">
            <Text field={fields.headingDescription} />
          </div>
          <div id="api"></div>
          {/* <Formik
            enableReinitialize
            initialValues={{
              UserName: '',
              PostalCode: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              UserName: Yup.string()
                .required(fields.enterEmailAddressUserName.value)
                .max(100, t('forgotPasswordUserNameNotValid'))
                .min(6, t('forgotPasswordUserNameNotValid'))
                .matches(/^.[a-zA-Z0-9-_.@+]+$/, t('forgotPasswordUserNameNotValid')),
              PostalCode: Yup.string()
                .required(fields.enterPostalCode.value)
                .max(7, t('forgotPasswordPostalCodeNotValid'))
                .matches(/^\w\d\w\s?\d\w\d$/, t('forgotPasswordPostalCodeNotValid')),
            })}
            onSubmit={(values) => {
              api
                .getSecurityQuestionsForExistingUser({
                  PostalCode: values.PostalCode.toUpperCase(),
                  UserName: values.UserName,
                })
                .then(({ data }) => {
                  if (data.Success) {
                    setState({ ...values, questions: data.securityQuestion });
                    toNextStep();
                  } else {
                    handleServerSuccessError(data.Error, setIsError);
                  }
                }, handleServerError(setIsServerError, setIsError));
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="UserName"
                  errors={errors}
                  touched={touched}
                  label={fields.emailAddressUserNameLabel}
                  length={101}
                />

                <TextInput
                  name="PostalCode"
                  errors={errors}
                  touched={touched}
                  label={fields.postalCodeLabel}
                  length={7}
                />

                <InlineButtonBlock type="submit" buttonText={fields.buttonContinue} />
              </form>
            )}
          </Formik> */}
        </div>
      </div>
    </>
  );
}
