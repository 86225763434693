import React, { useState, useRef } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import ForgotUsername1 from './ForgotUsername1';
import SecurityQuestionStep from '../shared/SecurityQuestionStep';
import ForgotUsername3 from './ForgotUsername3';
import './forgot.scss';
import { Header, Error, StepTracker } from '../../molecules';

const stepsMap = [ForgotUsername1, SecurityQuestionStep, ForgotUsername3];

function ForgotUsername({ fields, t }) {
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const [isServerError, setIsServerError] = useState(false);
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const Stepper = stepsMap[step];
  const [state, setState] = useState({
    questions: [],
  });

  return (
    <div className="forgot no-focus-outline">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={fields.step1Title} />,
            textInProgressA11y: fields.step1TitleA11y.value,
            textCompletedA11y: fields.Step1CompletedA11y.value,
          },
          {
            text: <Text field={fields.step2Title} />,
            textNotCompletedA11y: fields.Step2NotCompletedA11y.value,
            textInProgressA11y: fields.step2TitleA11y.value,
            textCompletedA11y: fields.Step2CompletedA11y.value,
          },
          {
            text: <Text field={fields.step3Title} />,
            textNotCompletedA11y: fields.step3TitleA11y.value,
            textCompletedA11y: fields.Step3CompletedA11y.value,
          },
        ]}
      />
      <Header centered>
        <Text field={fields.heading} />
      </Header>
      <div className="forgot-content-wrapper">
        {isServerError ? (
          <Error title={fields.errorUnableToProccess.value} />
        ) : (
          <Stepper
            t={t}
            setIsServerError={setIsServerError}
            state={state}
            setState={setState}
            toNextStep={toNextStep}
            fields={fields}
            securityQuestionSubmitsForm
          />
        )}
        <div className="forgot-policy">
          <RichText field={fields.policy} />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ForgotUsername);
