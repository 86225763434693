import React, { useRef } from 'react';

import { withTranslation } from 'react-i18next';

import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import { IconButton } from '../../atoms';
import searchIcon from '../../assets/images/Search.svg';
import './headerSearchMobile.scss';

const HeaderSearchMobile = ({ t }) => {
  const searchInputRef = useRef(null);

  return (
    <li className="header-search-mobile">
      <input
        ref={searchInputRef}
        className="search-input-mobile"
        type="text"
        id="search-mobile"
        name="search"
        placeholder={t('topNavigationSearchPlaceholder')}
        onKeyUp={(event) => {
          if (event.key === 'Enter' && searchInputRef.current.value) {
            redirectToUrl(routes.searchResults + searchInputRef.current.value);
          }
        }}
      />
      <IconButton
        className="search"
        icon={searchIcon}
        iconAlt={t('topNavigationSearchIcon')}
        onClick={() => {
          if (searchInputRef.current.value) {
            redirectToUrl(routes.searchResults + searchInputRef.current.value);
          }
        }}
      />
    </li>
  );
};

export default withTranslation()(HeaderSearchMobile);
