import React from 'react';

import { Button, ButtonBlock } from '../../atoms';
import './connectionError.scss';

export default function ConnectionError({
  title,
  titleA11y,
  message,
  messageA11y,
  buttonText,
  buttonA11y,
  buttonOnClick,
}) {
  return (
    <div className="connectionErrorWrapper">
      <div className="connection-title">
        <p className="title" aria-label={titleA11y} role="alert">
          {title}
        </p>
        <p aria-label={messageA11y} role="alert">
          {message}
        </p>
        {buttonText && (
          <ButtonBlock>
            <ButtonBlock center>
              <Button buttonTextA11y={buttonA11y} onClick={buttonOnClick}>
                {buttonText}
              </Button>
            </ButtonBlock>
          </ButtonBlock>
        )}
      </div>
    </div>
  );
}
