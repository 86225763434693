import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import api from '../../services/api';
import {
  redirectToUrl,
  redirectToUrlInNewWindow,
  formatDecimalWithCurrency,
  indexOfSelectedCard,
} from '../../services/utils';
import { Button, ButtonBlock } from '../../atoms';
import useSelectedCard from '../../hooks/useSelectedCard';

import { setActivateCardMyProducts } from '../../redux/products/actions';

import './systemStatusMessage.scss';
import { useSelector } from 'react-redux';

const SystemStatusMessage = (props) => {
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState('');
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const selectedCard = userInfo.selectedCard;
  const selectedCardIndex = indexOfSelectedCard(userInfo.fareMedias, selectedCard.visibleId);

  const { language } = props.fields;
  const { cardStateCode, currentBalance } = useSelectedCard();

  // const currentBalance = useSelector(
  //   (state) => state.user.fareMedias[selectedCardIndex].currentBalance
  // );

  useEffect(() => {
    api
      .getFareCardStatusMessage({ cardStatusCode: cardStateCode })
      .then((response) => {
        setMessage(response.data);
        setColor(response.data.SystemColor.toLowerCase());
      })
      .catch(() => {});
  }, [language]);

  const handleButtonClick = (url, isLinkInternal) => {
    if (url.includes('/account/manage-my-cards')) {
      dispatch(setActivateCardMyProducts(true));
    }
    isLinkInternal ? redirectToUrl(url, false, isLinkInternal) : redirectToUrlInNewWindow(url);
  };

  return (
    <>
      {color && (
        <div className={`system-message ${color}`}>
          <div className="system-message-heading">
            <div className="system-message-title">{message.Header}</div>
            <div className="system-message-balance">
              {props.fields.Balanceheading.value}: {formatDecimalWithCurrency(currentBalance)}
            </div>
          </div>
          <div className="system-message-text">{message.Description}</div>
          <ButtonBlock>
            <ButtonBlock right>
              {message.Cta1Link.Text && message.Cta1Link.Url && (
                <Button
                  iconAfter={!message.Cta1Link.InternalLink && 'externalLinkIcon'}
                  iconColor="white"
                  buttonTextA11y={`${message.Cta1Link.Text} ${
                    message.Cta1Link.InternalLink ? '' : props.t('opensNewWindowA11y')
                  }`}
                  iconSize="sm"
                  iconAriaLabel={props.t('opensNewWindowA11y')}
                  type="button"
                  onClick={() =>
                    handleButtonClick(message.Cta1Link.Url, message.Cta1Link.InternalLink)
                  }
                >
                  {message.Cta1Link.Text}
                </Button>
              )}
              {message.Cta2Link.Text && message.Cta2Link.Url && (
                <Button
                  iconAfter={!message.Cta1Link.InternalLink && 'externalLinkIcon'}
                  iconSize="sm"
                  buttonTextA11y={`${message.Cta1Link.Text} ${
                    message.Cta1Link.InternalLink ? '' : props.t('opensNewWindowA11y')
                  }`}
                  iconAriaLabel={props.t('opensNewWindowA11y')}
                  type="button"
                  onClick={() =>
                    handleButtonClick(message.Cta1Link.Url, message.Cta1Link.InternalLink)
                  }
                  firstOrder
                  white
                >
                  {message.Cta2Link.Text}
                </Button>
              )}
            </ButtonBlock>
          </ButtonBlock>
        </div>
      )}
    </>
  );
};

export default withTranslation()(SystemStatusMessage);
