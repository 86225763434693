import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Timer from 'react-compound-timer';
import handleLogOut from '../../../services/handleLogOut';
import { Modal } from '../../../molecules';
import './sessionTimeoutModal.scss';

export default function SessionTimeoutModal({ t, isModalOpen, setIsModalOpen }) {
  const dispatch = useDispatch();
  const businessRules = useSelector((state) => state.businessRules?.rules) || [];
  const countdown = businessRules?.IdleCountdown ? businessRules?.IdleCountdown : 60;
  const [timeOut, setTimeOut] = useState('');

  useEffect(() => {
    if (timeOut === 'timeOutReached') {
      //console.log("inside timeOut reached useeffect");
      handleLogOut(dispatch);
    }
  }, [timeOut]);

  const text = (
    <div className="timeoutContent">
      <p className="timeoutContent-text" role="alert">
        {t('sessionTimeoutCountdownTitle')}
      </p>
      <div role="timer" tabIndex="0">
        <span className="timeoutContent-countdown">
          <Timer
            initialTime={1000 * countdown}
            crossTab={true}
            direction="backward"
            lastUnit="s"
          >
            {({ getTime }) => (
              <>
                {getTime() < 1000 && timeOut !== 'timeOutReached' && setTimeOut('timeOutReached')}
                <Timer.Seconds />
              </>
            )}
          </Timer>
        </span>
        <p className="timeoutContent-text">{t('sessionTimeoutCountdownUnits')}</p>
      </div>
    </div>
  );
  const onSubmit = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('sessionTimeoutHeading')}
      text={text}
      textSubmit={t('sessionTimeoutConfirmButtonText')}
      onSubmit={onSubmit}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      disableBackdropClick
      disableEscapeKeyDown
      buttonTextA11y={t('sessionTimeoutConfirmButtonTextA11y')}
      alert
    />
  );
}
