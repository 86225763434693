import React from 'react';

import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import { redirectToUrlInNewWindow } from '../../services/utils';
import { Button, ButtonBlock } from '../../atoms';

import './carouselSlide.scss';

const maxDescriptionLength = 120;
const maxHeadingLength = 30;
const maxCtaLength = 24;

const CarouselSlide = (props) => {
  const { fields, prestoCarousel, rendering, currentStep } = props;

  const isActive = prestoCarousel.findIndex((item) => item.uid === rendering.uid) === currentStep;
  const descriptionLength = fields.description?.value.length || 0;
  const headingLength = fields.heading?.value.length || 0;
  const ctaLength =
    fields.ctaLink?.value && fields.ctaLink.value.text ? fields.ctaLink.value.text.length : 0;
  const isImageHidden =
    descriptionLength > maxDescriptionLength ||
    headingLength > maxHeadingLength ||
    ctaLength > maxCtaLength;

  return (
    <div
      className={`carousel-slide ${fields.backgroundColour.fields.cssClass.value}${
        isActive ? ' show-current-step' : ''
      }${!isImageHidden ? ' has-image' : ''}`}
    >
      <div className={`carousel-slide-textblock${isImageHidden ? ' full-width-text' : ''}`}>
        <div className="carousel-slide-textblock-heading">
          <Text field={fields.heading} />
        </div>
        <div className="carousel-slide-textblock-description">
          <RichText field={fields.description} />
        </div>
        {fields.ctaLink?.value?.href && fields.ctaLink.value?.text ? (
          <ButtonBlock>
            <Button
              iconAfter="externalLinkIcon"
              iconSize="sm"
              iconColor="white"
              iconAriaLabel={props.t('opensNewWindowA11y')}
              onClick={() => redirectToUrlInNewWindow(fields.ctaLink.value.href)}
            >
              {fields.ctaLink.value.text}
            </Button>
          </ButtonBlock>
        ) : null}
      </div>
      {!isImageHidden && (
        <div aria-hidden="true" className="carousel-slide-image">
          <Image media={fields.slideImage.value} loading="lazy"/>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(CarouselSlide);
