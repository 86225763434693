import React, { useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr-ca';
import { useField } from 'formik';

import Select from '../Select';
import './dateRange.scss';
import Modal from '../Modal';
import { DatePickerInput } from '../../atoms';
import { getLongLanguageCode } from '../../services/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const DateRange = ({ name, fromName, toName, setFieldValue, errors, values, setFieldError, t }) => {
  const transactionHistoryDays = t('travelHistoryDays');
  const transactionHistoryType = t('travelHistoryType');
  const MIN_DATE = moment().subtract(transactionHistoryDays, transactionHistoryType).toDate();
  const MAX_DATE = new Date();
  const [field, , helpers] = useField(name);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    moment().subtract(transactionHistoryDays, transactionHistoryType).toDate()
  );
  const langCode = getLongLanguageCode();

  const updatePopupVisibility = () => {
    if (field.value === '5' && !isPopupOpen) {
      setIsPopupOpen(true);
    }
  };

  const onSelectClose = updatePopupVisibility;
  useDidMountEffect(() => updatePopupVisibility(), [field.value]);

  const options = useMemo(() => {
    const today = moment().locale(langCode);
    const dateFormat = 'MMMM YYYY';

    return [
      {
        label: today.clone().format(dateFormat),
        value: '2',
      },
      {
        label: today.clone().subtract(1, 'month').format(dateFormat),
        value: '3',
      },
      {
        label: today.clone().subtract(2, 'month').format(dateFormat),
        value: '4',
      },
      {
        label: t('datepickerDefault'),
        value: '5',
      },
    ];
  }, [langCode]);

  const clearFormikRangeFields = () => {
    setFieldValue(fromName, undefined);
    setFieldValue(toName, undefined);
    setStartDate(new Date());
    setEndDate(moment().subtract(transactionHistoryDays, transactionHistoryType).toDate());
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    clearFormikRangeFields();
    helpers.setValue('2');
  };

  const popupOnSubmit = () => {
    if (!moment.isDate(values[fromName]) && !moment.isDate(values[toName])) {
      setFieldError(fromName, t('transactionHistoryFromDateRequired'));
      setFieldError(toName, t('transactionHistoryToDateRequired'));
      return;
    }

    if (!moment.isDate(values[fromName])) {
      setFieldError(fromName, t('transactionHistoryFromDateRequired'));
      return;
    }
    if (!moment.isDate(values[toName])) {
      setFieldError(toName, t('transactionHistoryToDateRequired'));
      return;
    }

    if (moment(values[toName]).isBefore(values[fromName])) {
      setFieldError(toName, t('transactionHistoryToDateInvalid'));
      return;
    }

    setIsPopupOpen(false);
  };

  return (
    <div className="dateRange">
      <Select onClose={onSelectClose} label={t('dateRangeLabel')} name={name} options={options} />
      <Modal
        onSubmit={popupOnSubmit}
        setIsModalOpen={closePopup}
        isModalOpen={isPopupOpen}
        textCancel={t('datepickerCancelButtonText')}
        textSubmit={t('datepickerSelectButtonText')}
        title={t('dateRangeTitle')}
        text={
          <>
            <DatePickerInput
              label={t('fromDateLabel')}
              aria-label={t('fromDateLabelA11y')}
              name={fromName}
              onChange={(date) => {
                setStartDate(date);
              }}
              selectsStart
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              startDate={startDate}
              endDate={endDate}
              errors={errors}
            />
            <DatePickerInput
              label={t('toDateLabel')}
              aria-label={t('toDateLabelA11y')}
              name={toName}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={values[fromName] || MIN_DATE}
              maxDate={MAX_DATE}
              errors={errors}
            />
            <button
              aria-label={t('clearDatesButtonTextA11y')}
              type="button"
              onClick={clearFormikRangeFields}
              className="dateRange-clear"
            >
              {t('clearDatesButtonText')}
            </button>
          </>
        }
      />
    </div>
  );
};

export default withTranslation()(DateRange);
