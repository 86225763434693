import types from './types';

export function setSignInOverlay(payload) {
  return {
    type: types.SET_OVERLAY,
    payload,
  };
}

export function setIframeEnabled(payload) {
  return {
    type: types.IS_IFRAME_ENABLED,
    payload,
  };
}

export function setContactlessUserSignedIn(payload) {
  return {
    type: types.IS_CONTACTLESS_USER_SIGNED_IN,
    payload,
  };
}

export function setUserInfoData(payload) {
  return {
    type: types.SET_USER_INFO_DATA,
    payload,
  };
}

export function setSignOutRedirectUrl(payload) {
  return {
    type: types.SET_SIGN_OUT_REDIRECT_URL,
    payload,
  };
}

export function setSettings(data) {
  return {
    type: types.SET_SETTINGS,
    payload: {
      ...data,
    },
  };
}

export function setContactlessLogInTime(contactlessLogInTime) {
  return {
    type: types.CONTACTLESS_LOGIN_TIME,
    payload: {
      contactlessLogInTime,
    },
  };
}

export function setContactlessLogoutTime(contactlessLogoutTime) {
  return {
    type: types.CONTACTLESS_LOGOUT_TIME,
    payload: {
      contactlessLogoutTime,
    },
  };
}

export function setRedirectUrl(payload) {
  return {
    type: types.SET_REDIRECT_URL,
    payload,
  };
}

export function setContactlessTransItems(payload) {
  return {
    type: types.SET_CONTACTLESS_TRANS_ITEMS,
    payload,
  };
}

export function setUnpaidFares(payload) {
  return {
    type: types.SET_UNPAID_FARES,
    payload,
  };
}

export function setMultiUnpaidFareProps(payload) {
  return {
    type: types.SET_MULTI_UNPAID_FARES_PROPS,
    payload,
  };
}

export function updateRetryCounter(payload) {
  return {
    type: types.UPDATE_RETRY_COUNTER,
    payload,
  };
}

export function setPaymentsToVerify(payload) {
  return {
    type: types.SET_PAYMENTS_TO_VERIFY,
    payload,
  };
}
