import React from 'react';
import './buttonBlock.scss';

const ButtonBlock = ({ children, center, right, className = '' }) => (
  <div className={`button-wrapper${center ? '-center' : ''}${right ? '-right' : ''} ${className}`}>
    {children}
  </div>
);

export default ButtonBlock;
