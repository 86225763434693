import React from 'react';

import { withTranslation } from 'react-i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';

import { PrestoLink, SocialLink } from '../../atoms';
import './footer.scss';

const Footer = (props) => {
  const { datasource } = props.fields.data ? props.fields.data : { children: [] };

  return (
    <div className="footer print-hidden" role="contentinfo">
      <div className="footer-center">
        {datasource && (
          <>
            <Image media={datasource.Logo.jss} loading="lazy"/>
            <div className="footer-links">
              {datasource.children.map((columns) =>
                columns.Title && columns.Title.value !== null ? (
                  <div>
                    <h2 className="footer-column-title">{columns.Title.value}</h2>
                    <ul aria-label={columns.Title.value}>
                      {columns.children.map((a) => (
                        <li className="category-link">
                          <PrestoLink field={a.Link?.jss} />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="social-networks">
                    {columns.children.map((social) => (
                      <div className="network">
                        <SocialLink field={social.Link?.jss} image={social.SocialIcon?.jss} />
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
