import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBlock, Loader, RadioInput } from '../../atoms';
import { Note, Select, TextInput } from '../../molecules';
import {
  setAddressType,
  setCurrentShippingDetails,
  setNewShippingDetails,
} from '../../redux/user/actions';
import api from '../../services/api';
import './checkoutStep01.scss';
import langConstants from '../../constants/lang';

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (
      (values.firstName &&
        values.lastName &&
        values.streetAddress &&
        values.city &&
        values.postalCode) ||
      values.addressType
    ) {
      submitForm();
    }
  }, [values]);
  return null;
};

const POSTAL_REGEX = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;
const ALPHANUMERIC_REGEXFirstlastname = /^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/;
const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z ]+$/;
const SPL_CHAR_REGEX = /^.[a-zA-Z0-9\\@/#*!^?.=: -]+$/;

const CheckoutStep01 = ({ toNextStep, userInfo, toPreviousStep, fields, t }) => {
  const [addressType, setAddresType] = useState('current');
  const [shippingRadioBlock, setshippingRadioBlock] = useState(true);
  const [isError, setIsError] = useState(false);
  const [shippingData, setShippingData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const customerId = userInfo.customerId;
  const mediaId = userInfo.selectedCard.mediaId;
  const currentAddress = useSelector((reduxData) => reduxData.user.currentShippingDetails);
  const [options, setOptions] = useState([]);
  const shoppingCartId = useSelector((reduxData) => reduxData.user.shoppingCartId);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const [isFrench, setIsFrench] = useState(false);
  const dispatch = useDispatch();
  const focusDiv = useRef();
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    let pathname = window.location.pathname;
    if (pathname.includes(langConstants.longFr)) {
      setIsFrench(true);
    }
    setIsLoading(true);
    Promise.all([api.getPersonalData(), api.getCountryAreas()])
      .then((responses) => {
        setIsLoading(false);
        return Promise.all(
          responses.map((response, index) => {
            if (index == 0) {
              dispatch(setCurrentShippingDetails(response?.data?.Data?.Address));
            } else {
              setCountryData(response?.data);
            }
          })
        );
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('error', error);
      });
  }, []);

  useEffect(() => {
    if (!shippingRadioBlock) {
      if (!guestUserInfo?.isGuestLogin) {
        focusDiv.current.focus();
      }
      document.body.scrollTop = 0;
    }
  }, [shippingRadioBlock]);
  useEffect(() => {
    if (guestUserInfo?.isGuestLogin) {
      setshippingRadioBlock(false);
      dispatch(setAddressType('new'));
    }
  }, [guestUserInfo]);

  useEffect(() => {
    countryData &&
      countryData?.Areas &&
      countryData.Areas.map((data) => {
        let obj = {
          label: data.Name,
          value: data.Id,
        };
        options.push(obj);
      });
  }, [countryData]);

  const setShippingApi = () => {
    const area =
      options &&
      options.filter((d) => {
        if (typeof shippingData.province === 'object') {
          return d.value === shippingData.province[0];
        } else {
          return d.value === shippingData.province;
        }
      });
    const payload = {
      FirstName: shippingData.firstName,
      LastName: shippingData.lastName,
      Line1: shippingData.streetAddress,
      Line2: shippingData.apartmentAddress,
      City: shippingData.city,
      PostalCode: shippingData.postalCode,
      ShippingEmail: userInfo.loginEmail,
      ShoppingstrCartId: shoppingCartId,
      CustomerId: customerId,
      VisibleId: 'NEWMEDIA',
      MediaId: mediaId,
      CountryId: shippingData.country,
      CountryName: shippingData.countryName,
      AreaName: area && area.length > 0 && area[0].label,
      Province: shippingData.province,
      AreaCode: shippingData.province,
      AreaId: shippingData.province,
      Sct: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
      Cct: guestUserInfo?.isGuestLogin ? guestSCT.cct : '',
      IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
    };
    dispatch(setNewShippingDetails(payload));
    // api
    //   .SetOrderDelivery(payload)
    //   .then((response) => {
    //     dispatch(setNewShippingDetails(payload));
    //   })
    //   .catch(() => {
    //     dispatch(setNewShippingDetails(payload));
    //     console.log('error');
    //   });
    if (
      shippingData.firstName &&
      shippingData.lastName &&
      shippingData.streetAddress &&
      shippingData.city &&
      shippingData.province &&
      shippingData.country &&
      shippingData.postalCode
    ) {
      toNextStep();
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="shipping-addr-block">
        {shippingRadioBlock ? (
          <>
            <div className="shipping-header">
              <Text field={fields.ShippingstepTitle} />
            </div>
            <div className="shipping-content">
              <Formik
                initialValues={{
                  addressType: 'current',
                }}
                onSubmit={(values) => setAddresType(values)}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="formContent">
                    <div className="shipping-curr-addr">
                      <RadioInput
                        name="addressType"
                        value="current"
                        label={fields.CurrentAddressLabel.value}
                        a11y={fields.CurrentAddressLabel.value}
                        type="radio"
                        onChange={handleChange}
                        checked={values.addressType === 'current'}
                      />
                      <hr className={`addressLine ${isFrench ? 'addressLineFR' : ''}`} />
                      <div className="shipping-curr-details">
                        <p>
                          <b>
                            {currentAddress?.Line1} {currentAddress?.Line2}
                          </b>
                        </p>
                        <p>
                          {currentAddress?.City}, {currentAddress?.Area?.Code}{' '}
                        </p>
                        <p>{currentAddress?.Country?.Id}</p>
                        <p>{currentAddress?.PostalCode}</p>
                      </div>
                    </div>
                    <div className="shipping-diff-addr">
                      <RadioInput
                        name="addressType"
                        value="new"
                        label={fields.NewAddressLabelA11y.value}
                        a11y={fields.NewAddressLabelA11y.value}
                        type="radio"
                        onChange={handleChange}
                        checked={values.addressType === 'new'}
                      />
                      <hr className="addressLine" />
                      {values?.addressType === 'new' ? (
                        <div className="shipping-diff-details">
                          <RichText field={fields?.NewAddressNote} />
                        </div>
                      ) : null}
                    </div>
                    <AutoSubmitToken />
                  </form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div tabIndex="-1" ref={focusDiv} className="shipping-details-header no-focus-outline">
              <Text field={fields.NewShippingAddressTitle} />
            </div>
            <div className="shipping-title">
              <RichText field={fields.NewShippingAddressSubText} />
            </div>
            <div className="required">
              <Text field={fields.RequredText} />
            </div>
          </>
        )}
      </div>
      {!shippingRadioBlock && (
        <div className="shipping-details-content">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              streetAddress: '',
              apartmentAddress: '',
              city: '',
              province: 'ON',
              country: 'CAN',
              postalCode: '',
              countryName: 'Canada',
            }}
            validate={(values) => {
              const errors = {};
              if (
                !values.firstName ||
                values.firstName.length > 25 ||
                !ALPHANUMERIC_REGEXFirstlastname.test(values.firstName)
              ) {
                errors.firstName = fields.FirstNameError.value;
              }
              if (
                !values.lastName ||
                values.lastName.length > 25 ||
                !ALPHANUMERIC_REGEXFirstlastname.test(values.lastName)
              ) {
                errors.lastName = fields.LastNameError.value;
              }
              if (
                !values.streetAddress ||
                values.streetAddress.length > 25 ||
                !ALPHANUMERIC_REGEX.test(values.streetAddress)
              ) {
                errors.streetAddress = fields.StreetAddressError.value;
              }
              // if (
              //   values.apartmentAddress.length > 50 ||
              //   !ALPHANUMERIC_REGEX.test(values.apartmentAddress)
              // ) {
              //   errors.apartmentAddress = t('ApartmentLabelErrorMessage');
              // }
              if (
                !values.city ||
                values.city.length > 25 ||
                !ALPHANUMERIC_REGEX.test(values.city)
              ) {
                errors.city = fields.CityError.value;
              }
              if (!values.postalCode || !POSTAL_REGEX.test(values.postalCode)) {
                errors.postalCode = fields.PostalCodeError.value;
              }
              if (Object.keys(errors).length === 0) {
                setIsError(false);
              } else {
                setIsError(true);
              }
              return errors;
            }}
            // validateOnChange={true}
            validationSchema={Yup.object().shape({
              apartmentAddress: Yup.string()
                .max(50, t('ApartmentLabelErrorMessage'))
                .matches(/^[0-9a-zA-Z ]+$/, t('ApartmentLabelErrorMessage')),
            })}
            onSubmit={(values) => {
              setShippingData(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="form-detail-Content">
                <div className="shipping-details-form">
                  <div className="shipping-username">
                    <div className="first-name">
                      <div className="first-name-label">
                        <Text field={fields.FirstNameLabel} />
                      </div>
                      <div className="first-name-field">
                        <TextInput
                          type="text"
                          value={values.firstName}
                          name="firstName"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.FirstNameLabelA11y.value}
                        />
                      </div>
                    </div>
                    <div className="last-name">
                      <div className="last-name-label">
                        <Text field={fields.LastNameLabel} />
                      </div>
                      <div className="last-name-field">
                        <TextInput
                          type="text"
                          value={values.lastName}
                          name="lastName"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.LastNameLabelA11y.value}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="shipping-address">
                    <div className="street-add">
                      <div className="street-add-label">
                        <Text field={fields.StreetAddressLabel} />
                      </div>
                      <div className="street-add-field">
                        <TextInput
                          type="text"
                          value={values.streetAddress}
                          name="streetAddress"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.StreetAddressLabelA11y.value}
                        />
                      </div>
                    </div>

                    <div className="apartment">
                      <div className="apartment-label">
                        <Text field={fields.ApartmentLabel} />
                      </div>
                      <div className="apartment-field">
                        <TextInput
                          type="text"
                          value={values.apartmentAddress}
                          name="apartmentAddress"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.ApartmentLabelA11y.value}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="shipping-province">
                    <div className="city">
                      <div className="city-label">
                        <Text field={fields.CityLabel} />
                      </div>
                      <div className="city-field">
                        <TextInput
                          type="text"
                          value={values.city}
                          name="city"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.CityLabelA11y.value}
                        />
                      </div>
                    </div>
                    <div className="province">
                      <div className="province-label">
                        <Text field={fields.ProvinceLabel} />
                      </div>
                      <div className="province-field">
                        <Select
                          fullWidth
                          name="province"
                          errors={errors}
                          touched={touched}
                          options={options}
                          defaultValue="ON"
                          defaultLabel="Ontario"
                          labelA11y={fields.ProvinceLabelA11y.value}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="shipping-country-code">
                    <div className="country">
                      <div className="country-label">
                        <Text field={fields.CountryLabel} />
                      </div>
                      <div className="country-field">
                        <TextInput
                          type="text"
                          value="Canada"
                          name="country"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          disabled
                          inputA11y={fields.CountryLabelA11y.value}
                        />
                      </div>
                    </div>
                    <div className="postal-code">
                      <div className="postal-code-label">
                        <Text field={fields.PostalCodeLabel} />
                      </div>
                      <div className="postal-code-field">
                        <TextInput
                          type="text"
                          value={values.postalCode}
                          name="postalCode"
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          inputA11y={fields.PostalCodeLabelA11y.value}
                          bellowText={fields.PostalCodeNote}
                        />
                      </div>
                      {/* <div className="PostalCodeNote">
                          <Text field={fields.PostalCodeNote} />
                        </div> */}
                    </div>
                  </div>
                </div>
                <AutoSubmitToken />
                {!guestUserInfo?.isGuestLogin ? (
                  <ButtonBlock>
                    <ButtonBlock right className="Deactivated-Button button-order-revere">
                      {!isError ? (
                        shippingRadioBlock ? (
                          addressType.addressType === 'current' ? (
                            <Button
                              type="submit"
                              buttonTextA11y={fields.nextButtonTextA11y.value}
                              onClick={() => {
                                dispatch(setAddressType('current'));
                                toNextStep();
                              }}
                            >
                              <Text field={fields.nextButtonText} />
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              buttonTextA11y={fields.nextButtonTextA11y.value}
                              onClick={() => {
                                setshippingRadioBlock(false);
                                dispatch(setAddressType('new'));
                              }}
                            >
                              <Text field={fields.nextButtonText} />
                            </Button>
                          )
                        ) : (
                          <Button
                            type="submit"
                            buttonTextA11y={fields.nextButtonTextA11y.value}
                            onClick={() => {
                              setShippingApi();
                            }}
                          >
                            <Text field={fields.nextButtonText} />
                          </Button>
                        )
                      ) : (
                        <Button type="submit" buttonTextA11y={fields.nextButtonTextA11y.value}>
                          <Text field={fields.nextButtonText} />
                        </Button>
                      )}
                      {shippingRadioBlock ? (
                        <Button white onClick={() => toPreviousStep()}>
                          <Text field={fields.backButtonText} />
                        </Button>
                      ) : (
                        <Button
                          white
                          buttonTextA11y={fields.backButtonTextA11y.value}
                          onClick={() => {
                            setshippingRadioBlock(true);
                            setIsError(false);
                          }}
                        >
                          <Text field={fields.backButtonText} />
                        </Button>
                      )}
                    </ButtonBlock>
                  </ButtonBlock>
                ) : null}

                {guestUserInfo?.isGuestLogin ? (
                  <ButtonBlock>
                    <ButtonBlock right className="Deactivated-Button button-order-revere">
                      <>
                        <Button
                          type="submit"
                          buttonTextA11y={fields.nextButtonTextA11y.value}
                          onClick={() => {
                            setShippingApi();
                          }}
                        >
                          <Text field={fields.nextButtonText} />
                        </Button>
                        <Button white buttonTextA11y="" onClick={() => toPreviousStep()}>
                          <Text field={fields.backButtonText} />
                        </Button>
                      </>
                    </ButtonBlock>
                  </ButtonBlock>
                ) : null}
              </form>
            )}
          </Formik>
        </div>
      )}

      {!guestUserInfo?.isGuestLogin && shippingRadioBlock ? (
        <ButtonBlock>
          <ButtonBlock right className="Deactivated-Button button-order-revere">
            {addressType.addressType === 'current' ? (
              <Button
                type="submit"
                buttonTextA11y={fields.nextButtonTextA11y.value}
                onClick={() => {
                  dispatch(setAddressType('current'));
                  toNextStep();
                }}
              >
                <Text field={fields.nextButtonText} />
              </Button>
            ) : (
              <Button
                type="submit"
                buttonTextA11y={fields.nextButtonTextA11y.value}
                onClick={() => {
                  setshippingRadioBlock(false);
                  dispatch(setAddressType('new'));
                }}
              >
                <Text field={fields.nextButtonText} />
              </Button>
            )}
            <Button white onClick={() => toPreviousStep()}>
              <Text field={fields.backButtonText} />
            </Button>
          </ButtonBlock>
        </ButtonBlock>
      ) : null}

      {shippingRadioBlock ? (
        ''
      ) : (
        <div className="checkout-note">
          <Note>
            <RichText field={fields.ShippingAddressNote} />
          </Note>
        </div>
      )}
    </>
  );
};

export default CheckoutStep01;
