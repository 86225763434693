import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Iframe from 'react-iframe';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import routes from '../../../constants/routes';
import { getShortLanguageCode, localstorageService } from '../../../services/utils';
import { itemsAreLoading } from '../../../redux/user/actions';
import monerisConstants from '../../../constants/moneris';
import mediaSubTypes from '../../../constants/mediaSubTypes';
import api from '../../../services/api';
import creditCardIcon from '../../../assets/images/credit_card.svg';
import { ButtonBlock, Button, IconWithText } from '../../../atoms';
import { Error, ConnectionError, CardBlock } from '../../../molecules';
import localStorageKeys from '../../../constants/localStorageKeys';
import {
  redirectToUrl,
} from '../../../services/utils';
import './cardVerification.scss';

const CardVerification0 = (props) => {
  const dispatch = useDispatch();
  const { fields, toNextStep, toFirstStep, setState, state } = props;
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const userInfo = useSelector((reduxState) => reduxState.user);
  const customerId = userInfo?.customerId || '';
  const accountId = userInfo?.accountId || '';
  const sessionInstanceId = userInfo.sessionInstanceId || '';
  const [hideIframe, setHideIframe] = useState(false);
  const [error, setError] = useState('');
  const [hasConnectionError, setHasConnectionError] = useState('');
  const [token, setToken] = useState('');

  function refreshIframe() {
    document.getElementById('iframe').src += '';
    setHideIframe(false);
    setHasConnectionError(false);
  }

  function setErrorAndRefreshIframe(errorText) {
    setError(errorText);
    refreshIframe();
  }

  function verifyCard(monerisToken, associatedMedia, cardVerificationRequiredItems, selectedCard) {
    const mediaInstanceIds = selectedCard.visibleId.split('|');
    const selectedCardMediaSubType = Object.keys(mediaSubTypes).find(
      (key) => mediaSubTypes[key] === selectedCard.type
    );

    if (
      associatedMedia.MediaReferenceId === selectedCard.mediaId &&
      associatedMedia.MediaIdentifiers.length === mediaInstanceIds.length &&
      associatedMedia.MediaIdentifiers.every(
        (item) =>
          mediaInstanceIds.indexOf(item.mediaInstanceId) > -1 &&
          item.mediaSubType === selectedCardMediaSubType &&
          item.mediaType === 'OPENP'
      )
    ) {
      dispatch(itemsAreLoading(true));
      api
        .verifyContactlessCard({
          MonerisToken: monerisToken,
          VerifyItem:
            JSON.parse(localstorageService().getItem(localStorageKeys.paymentsToVerifyData)),
          //VerifyItem: cardVerificationRequiredItems,
        })
        .then((res) => {
          if (res.data.Success) {
            setState({
              ...state,
              hasSuccess: true,
              verificationError: '',
            });
            toNextStep();
          } else {
            const resError = JSON.parse(res.data.Error);

            setState({
              ...state,
              hasSuccess: false,
              verificationError: resError.Code || '',
            });
            toNextStep();
          }
        })
        .catch((err) => {
          console.warn(err);
          setState({
            ...state,
            hasSuccess: false,
            verificationError: 'Contactless_ConnectionError',
          });
          toNextStep();
        })
        .then(() => {
          dispatch(itemsAreLoading(false));
        });
    } else {
      setErrorAndRefreshIframe(fields.verifyCardErrorCVRCTAStep0205?.value);
    }
  }

  function processResponse(iframeResponse) {
    if (iframeResponse.StatusCode === monerisConstants.responses.success) {
      verifyCard(
        iframeResponse.DataKey,
        iframeResponse.AssociatedMedia,
        props.cardVerificationRequiredItems,
        userInfo.selectedCard
      );
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.unauthorized ||
      iframeResponse.StatusCode === monerisConstants.responses.connectionErrorE
    ) {
      setError('');
      setHasConnectionError(true);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorI ||
      iframeResponse.StatusCode === monerisConstants.responses.authenticationErrorE
    ) {
      setErrorAndRefreshIframe(fields.verifyCardErrorCVRCTAStep0203?.value);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededI ||
      iframeResponse.StatusCode === monerisConstants.responses.retryAttemptsExceededE
    ) {
      setError(fields.verifyCardErrorCVRCTAStep0202?.value);
      setHasConnectionError(false);
      setHideIframe(true);
    } else if (
      iframeResponse.StatusCode === monerisConstants.responses.technicalError ||
      iframeResponse.StatusCode === monerisConstants.responses.generalError ||
      iframeResponse.StatusCode === monerisConstants.responses.noAssociatedMediasFound ||
      iframeResponse.StatusCode === monerisConstants.responses.associatedMediasEmpty ||
      iframeResponse.StatusCode === monerisConstants.responses.forbidden
    ) {
      setErrorAndRefreshIframe(fields.verifyCardErrorCVRCTAStep0204?.value);
    } else {
      //setErrorAndRefreshIframe(fields.verifyCardErrorCVRCTAStep0204?.value);
    }
  }

  const handleIframeTask = (res) => {
    try {
      const iframeResponse = JSON.parse(res.data);
      processResponse(iframeResponse);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    let stsAccessToken = localstorageService().getItem(localStorageKeys.stsAccessToken);
    setToken(stsAccessToken);
    window.addEventListener('message', handleIframeTask);
    return () => window.removeEventListener('message', handleIframeTask);
  }, []);

  return (
    <div>
      <CardBlock title={fields.verifyCardStep1Subtitle?.value}>
        {error ? <Error title={error} small /> : null}
        <div>
          <Text field={fields.verifyCardStep1Text} />
        </div>
        <IconWithText text={userInfo.selectedCard?.nickName} iconSrc={creditCardIcon} isBold />
        {hasConnectionError ? (
          <ConnectionError
            title={fields.verifyCardErrorCVRCTAStep0201?.value}
            titleA11y={fields.verifyCardErrorCVRCTAStep0201?.value}
            message={fields.verifyCardErrorCVRCTAStep0201Description?.value}
            messageA11y={fields.verifyCardErrorCVRCTAStep0201Description?.value}
            buttonA11y={fields.verifyCardRefreshButton?.value}
            buttonText={fields.verifyCardRefreshButton?.value}
            buttonOnClick={() => {
              refreshIframe();
            }}
          />
        ) : null}
        <>
          {configKeys &&
            token &&
            configKeys
              .filter((item) => item.key === 'Ats.GatewayUrl')
              .map((configKey) => (
                <Iframe
                  id="iframe"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  position="relative"
                  scrolling="no"
                  display={hideIframe ? 'none' : 'block'}
                  url={`${configKey.setting
                    }/Verification/${sessionInstanceId}/${getShortLanguageCode()}/${customerId}/${accountId}/${token}`}
                />
              ))}
        </>
      </CardBlock>
      <ButtonBlock>
        <ButtonBlock right>
          <Button white onClick={() => redirectToUrl(routes.transactionHistory)}>
            <Text field={fields.verifyCardCancelButton} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </div>
  );
};

export default CardVerification0;
