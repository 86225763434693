import React, { useEffect, useState } from 'react';
import { Button, ButtonBlock, InlineError, Loader } from '../../../atoms';
import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import sharedFormConstants from '../../../constants/sharedFormConstants';
import { Error } from '../../../molecules';
import api from '../../../services/api';
import './DeactivateAndTransferAnother1.scss';
import { formatDecimalWithCurrency } from '../../../services/utils';
import { setHotlistApiSuccess } from '../../../redux/user/actions';

const DeactivateAndTransferAnother1 = ({
  toNextStep,
  fields,
  toPreviousStep,
  rendering,
  t,
  userInfo,
  isTransferBalance,
  callRefreshToken,
}) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [fareMedias, setFareMedias] = useState('');
  const [oldCvn, setOldCvn] = useState('');
  const [newCvn, setNewCvn] = useState('');
  const customerId = userInfo.customerId;
  const oldVisibleId = userInfo?.selectedCard?.visibleId;
  const oldMediaId = userInfo?.selectedCard?.mediaId;
  const selectedCardData = useSelector((redux) => redux.user.selectedCardData);
  let finalTransitpasses =
    selectedCardData?.transitPass?.length > 0 &&
    selectedCardData?.transitPass.filter((x) => x.ProductName !== null);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setIsLoading(true);
    api
      .getUserInfo()
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.Success) {
          setFareMedias(response?.data?.Data?.Customer?.FareMedias);
        } else {
          //console.log('error');
        }
      })
      .catch(() => {
        setIsLoading(false);
        //console.log('error');
      });
  }, []);
  useEffect(() => {
    const oldCvn =
      fareMedias &&
      fareMedias.length > 0 &&
      fareMedias.find((data) => {
        return data.VisibleId == oldVisibleId;
      });
    const newCvn =
      fareMedias &&
      fareMedias.length > 0 &&
      fareMedias.find((data) => {
        return data.VisibleId == selectedCardData?.cardNumber;
      });
    setOldCvn(oldCvn?.VerificationNumber);
    setNewCvn(newCvn?.VerificationNumber);
  }, [fareMedias]);

  const handleSubmit = () => {
    setIsLoading(true);
    api
      .createHotlistAndTransferCard({
        CustomerId: customerId,
        NewCVN: newCvn,
        NewMediaId: selectedCardData.mediaId,
        NewVisibleId: selectedCardData.cardNumber,
        OldMediaId: oldMediaId,
        OldVisibleId: oldVisibleId,
        OldCVN: oldCvn,
        IsStubEnabled: 'true',
        IsErrorFlowEnabled: 'false',
        IsTransferBalance: isTransferBalance,
        IsAnonymousFlow: false,
      })
      .then((response) => {
        dispatch(setHotlistApiSuccess(response.data));
        if (response?.data?.Success) {
          setIsLoading(false);
          setIsError(false);
          callRefreshToken(() => {
            toNextStep();
          });
        } else {
          toNextStep();
        }
      })
      .catch(() => {
        api.UpdateFareMediaSession({ VisibleId: oldVisibleId }).then(({ data }) => { });
        setIsLoading(false);
        //console.log('error');
        setIsError(true);
      });
  };
  let formattedBalance = formatDecimalWithCurrency(selectedCardData?.balance);
  return (
    <>
      {isLoading && <Loader />}
      {isError && (
        <Error
          title={fields.ErrorMessage.value}
          titleA11y={fields.ErrorMessage11y.value}
          text={fields.SubmitErrorMessage}
        />
      )}
      <div className="transfer-card-block">
        <div className="transfer-card-details-header">
          <Text field={fields?.PostTransferTitle} />
        </div>
        <div className="transfer-card-details-descr">
          <RichText field={fields.PostTransferDescription} />
        </div>
        <div className="transfer-card-details">
          <div className="card-nickname">
            <span>
              <Text field={fields.CardNickName} />
            </span>
            <span className="bold-content">{selectedCardData?.nickName}</span>
          </div>
          <div className="card-number">
            <span>
              <Text field={fields.PrestoCardNumber} />
            </span>
            <span className="bold-content">{selectedCardData?.cardNumber}</span>
          </div>
          {selectedCardData?.balance && (
            <div className="card-balance">
              <span>
                <Text field={fields.UpdatedBalance} />
              </span>
              <span className="bold-content">{formattedBalance}</span>
            </div>
          )}
          <div className="card-autoload">
            <span>
              <Text field={fields.AutoLoadContract} />
            </span>
            {selectedCardData?.autoloadContract && selectedCardData?.autoloadContract?.Threshold ? (
              <span className="bold-content">
                {fields?.ThresholdLabel?.value}{' '}
                {formatDecimalWithCurrency(selectedCardData?.autoloadContract?.Threshold)};{' '}
                {fields?.LoadLabel?.value}{' '}
                {formatDecimalWithCurrency(selectedCardData?.autoloadContract?.Amount)}
              </span>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>
          <div className="card-autorenew">
            <span>
              <Text field={fields.AutoRenewContract} />
            </span>
            {selectedCardData?.autorenewContract &&
              selectedCardData?.autorenewContract?.length > 0 ? (
              <div>
                {selectedCardData?.autorenewContract.map((pass) => {
                  if (pass?.Product?.ProductName !== null) {
                    return <p className="transitpass-list-RLC">{pass?.Product?.ProductName}</p>;
                  }
                })}
              </div>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>

          <div className="card-transitpass">
            <span>
              <Text field={fields?.TransitPass} />
            </span>
            {finalTransitpasses && finalTransitpasses?.length > 0 ? (
              <div>
                {selectedCardData.transitPass.map((pass) => {
                  if (pass.ProductName !== null) {
                    return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                  }
                })}
              </div>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>

          <div className="card-faretype">
            <span>
              <Text field={fields.FareType} />
            </span>
            <span className="bold-content">{selectedCardData.fareType}</span>
          </div>
        </div>
        <Formik
          initialValues={{
            [sharedFormConstants.terms]: false,
            terms: false,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.terms) {
              errors[sharedFormConstants.terms] = t('termsAndConditionsRequired');
            }

            return errors;
          }}
          onSubmit={() => {
            handleSubmit();
          }}
        >
          {({ errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="checkout-terms-block">
                <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                  <InlineError cssClass="checkbox-error">
                    {errors[sharedFormConstants.terms]}
                  </InlineError>
                )}
              </div>
              <ButtonBlock className="Deactivated-Button">
                <Button type="submit">
                  <Text field={fields.ConfirmCTALabel} />
                </Button>
                <Button onClick={() => toPreviousStep()}>
                  <Text field={fields.GoBackCTALabel} />
                </Button>
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DeactivateAndTransferAnother1;
