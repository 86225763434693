import React, { useState, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { StepTracker } from '../../molecules';
import { showScreenHeader } from '../../redux/user/actions';
import { getServerUrl, getQueryParams } from '../../services/utils';
import handleAVSValidationError from '../../services/handleAVSValidationError';
import ModifyAutoload1 from './ModifyAutoload1';
import ModifyAutoload2 from './ModifyAutoload2';
import ModifyAutoload3 from './ModifyAutoload3';

import '../Autoload/autoload.scss';
import '../Autoload/autoloadPayment.scss';
import '../Autoload/autoloadSuccess.scss';

const stepsModifyAutoloadMap = [ModifyAutoload1, ModifyAutoload2, ModifyAutoload3];

const ModifyAutoload = ({ fields, t, product, backToMyCardButtonLink, rendering }) => {
  const userInfo = useSelector((reduxState) => reduxState.user);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => window.location.reload();
  const [state, setState] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = getQueryParams(location.search);
  const errorDisplay = handleAVSValidationError(queryParams, t);

  useEffect(() => {
    if (queryParams.success === 'true') {
      const { loadAmount, thresholdAmount, transactionDate } = queryParams;
      dispatch(showScreenHeader(false));
      setState({
        ...state,
        loadAmount,
        thresholdAmount,
        transactionDate,
      });
      setStep(2);
      focusDiv.current.focus();
    }
    if (queryParams.error !== undefined || queryParams.cancel !== undefined) {
      const { loadAmount, thresholdAmount } = queryParams;
      setState({
        ...state,
        loadAmount,
        thresholdAmount,
      });
      setStep(1);
      focusDiv.current.focus();
    }
  }, []);

  const monerisIframeUrl =
    configKeys &&
    configKeys
      .filter((item) => item.key === 'Moneris.IframeJs')
      .map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] != "") {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  const Stepper = stepsModifyAutoloadMap[step];

  const returnUrl = `${getServerUrl()}${location.pathname}`;

  return (
    <>
      <div className="no-focus-outline">
        <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
          {t('StepperChangeSRText')}
        </span>
        <StepTracker
          activeIndex={step}
          items={[
            {
              text: <Text field={fields.step1Title} />,
              textInProgressA11y: fields.step1TitleInProgressA11y.value,
              textCompletedA11y: fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step2Title} />,
              textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
              textInProgressA11y: fields.step2TitleInProgressA11y.value,
              textCompletedA11y: fields.step2TitleCompletedA11y.value,
            },
            {
              text: <Text field={fields.step3Title} />,
              textNotCompletedA11y: fields.step3TitleNotCompletedA11y.value,
              textCompletedA11y: fields.step3TitleCompletedA11y.value,
            },
          ]}
        />
      </div>

      <Stepper
        fields={fields}
        t={t}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        userInfo={userInfo}
        configKeys={configKeys}
        locationSearch={location.search}
        toFirstStep={toFirstStep}
        product={product}
        backToMyCardButtonLink={backToMyCardButtonLink}
        returnUrl={returnUrl}
        isError={queryParams.error !== undefined}
        errorContent={errorDisplay}
        rendering={rendering}
      />
    </>
  );
};
export default withTranslation()(ModifyAutoload);
