import React, { useEffect, useState, useRef } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Header, StepTracker } from '../../../../molecules';
import TTCCheckout1 from './TCCCheckout1';
import TTCCheckout0 from './TTCCheckout0';
import { getQueryParams, getServerUrl } from '../../../../services/utils';
import handleThirdPartyError from '../../../../services/handleThirdPartyError';
import { setCheckoutTransitPage } from '../../../../redux/user/actions';
import './TTCCheckout.scss';

const stepTTCCheckout = [TTCCheckout0, TTCCheckout1];

const TTCCheckout = (
  {
    t,
    rendering,
    TTCProduct,
    set12MonthPass,
    action,
    isSuccess,
    error,
    state,
    setState,
    setIsModalOpen,
    pausedState,
    autorenewSubscriptionList,
    reinstateState,
    serviceOptions,
    transitPassfields,
  },
  props
) => {
  const configKeys = useSelector((s) => s.settings.configKeys);
  const userInfo = useSelector((s) => s.user);
  const manageAutoRenew = useSelector((reduxState) => reduxState.products.manageAutoRenew);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => {
    setStep(0);
    focusDiv.current.focus();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const [isError, setIsError] = useState(false);
  const [currentTTCProd, setCurrentTTCProd] = useState({});
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');
  const Stepper = stepTTCCheckout[step];
  const location = useLocation();
  const returnUrl = `${getServerUrl()}${location.pathname}`;
  const queryParams = getQueryParams(location.search);
  const dispatch = useDispatch();
  const ttcFields = rendering[0]?.fields;
  const serializedData = queryParams?.serializedData && JSON.parse(queryParams.serializedData);
  useEffect(() => {
    if (action === 'set' && serializedData?.flow === 'ttc') {
      set12MonthPass(true);
      dispatch(setCheckoutTransitPage(true));
      let choosenOpt = serviceOptions.length
        ? serviceOptions.filter((el) => el.value === serializedData.serviceProvider)[0]
        : {};
      let ttcProd = choosenOpt.products
        ? choosenOpt.products.find((el) => el.ProductId === serializedData.productId)
        : {};
      setCurrentTTCProd(ttcProd);
      if (isSuccess === 'true') {
        setStep(1);
        focusDiv.current.focus();
      } else {
        let stepToSet = 0;

        if (error) {
          handleThirdPartyError(error, (err) => {
            setThirdPartySystemErrorType(err);
            stepToSet = 1;
          });
        }
        setStep(stepToSet);
        focusDiv.current.focus();
      }
    }
  }, [serviceOptions]);

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] !== '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  return (
    <div className="ttc-checkout ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={ttcFields?.UpdateTTCPassStep1Title} />,

            labelA11y: ttcFields?.UpdateTTCPassStep1TitleA11y.value,
            StepA11y: ttcFields?.StepA11y.value,
            OfA11y: ttcFields?.OfA11y.value,
            CompleteA11y: ttcFields?.CompleteA11y.value,
            NotCompleteA11y: ttcFields?.NotCompleteA11y.value,
            CurrentStepA11y: ttcFields?.CurrentStepA11y.value,
          },
          {
            text: (
              <Text
                field={pausedState ? ttcFields?.TTCStep2Title : ttcFields?.TTCAdultConfirmedStep}
              />
            ),

            labelA11y: pausedState
              ? ttcFields?.TTCStep2Title.value
              : ttcFields?.TTCAdultConfirmedStepA11y.value,
            StepA11y: ttcFields?.StepA11y.value,
            OfA11y: ttcFields?.OfA11y.value,
            CompleteA11y: ttcFields?.CompleteA11y.value,
            NotCompleteA11y: ttcFields?.NotCompleteA11y.value,
            CurrentStepA11y: ttcFields?.CurrentStepA11y.value,
          },
        ]}
      />
      <Header centered headerTitleA11y={ttcFields?.CheckoutTitleA11y.value}>
        <Text
          field={pausedState ? ttcFields?.UpdateTTCPassPaymentTitle : ttcFields?.CheckoutTitle}
        />
      </Header>
      {step === 1 ? (
        <div className="confirmation-block">
          {state?.confirmationNo && (
            <>
              <span>
                <Text field={ttcFields.ConfirmationNumber} />
              </span>
              &nbsp;
              <span>{state?.confirmationNo}</span>
            </>
          )}
        </div>
      ) : pausedState || reinstateState ? (
        ''
      ) : (
        <div className="description">
          <RichText field={ttcFields?.ReviewTTCLabel} />
        </div>
      )}
      <Stepper
        {...props}
        t={t}
        state={state}
        setState={setState}
        ttcFields={ttcFields}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
        rendering={rendering}
        product={serializedData?.flow === 'ttc' ? currentTTCProd : TTCProduct}
        userInfo={userInfo}
        instance={manageAutoRenew?.instance}
        set12MonthPass={set12MonthPass}
        pauseAndReinstate={pausedState}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
        setIsError={setIsError}
        isError={isError}
        returnUrl={returnUrl}
        action={serializedData?.flow === 'ttc' ? queryParams.action : action}
        isSuccess={isSuccess}
        error={error}
        setIsModalOpen={setIsModalOpen}
        autorenewSubscriptionList={autorenewSubscriptionList}
        transitPassfields={transitPassfields}
      />
    </div>
  );
};

export default withTranslation()(TTCCheckout);
