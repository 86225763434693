import React, { useState, useEffect } from 'react';
import { Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

import CloseSideNavImage from '../../assets/images/HamburgerMenuClose.svg';
import { setSignInOverlay, setIframeEnabled } from '../../redux/contactless/actions';
import '../../assets/scss/app.scss';
import './signin.scss';
import SigninSidebar from '../SigninSidebar';
import { concat } from 'lodash';
import { getLocalStorageItem, redirectToUrl, setLocalStorageItem } from '../../services/utils';
import useMediaWidth from '../../hooks/useMediaWidth';
import HeaderLogo from '../../assets/images/PRESTO-Header-Logo.svg';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';

const Signin = (props) => {
  const isMobile = useMediaWidth();
  const dispatch = useDispatch();
  const [isContentState] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  dispatch(setIframeEnabled(true));

  const location = useLocation();
  let pathName = location.pathname;

  const onClickCallback = (type) => {
    setSelectedType(type);
    setIsClicked(true);
    if (type === 'AC') {
      dispatch(setSignInOverlay(true));
    }
    setTimeout(() => {
      if (type === 'PA') {
        const element = document.getElementById('prestoaccountsignin');
        element.focus();
      }
      if (type === 'APC') {
        const element = document.getElementById('anonymousaccountsignin');
        element.focus();
      }
      if (type === 'AC') {
        const element = document.getElementById('contactlessaccountsignin');
        element.focus();
      }
    }, 300);
  };
  const onClickCallbackMobile = (type) => {
    setSelectedType(type);
    setIsClicked(true);
    if (type === 'AC') {
      dispatch(setSignInOverlay(true));
    }
  };

  useEffect(() => {
    isMobile && !selectedType && setSelectedType('');
    pathName.includes("/sign-in") && !isMobile && !selectedType && api.b2cRegisteredSignIn();
    //pathName.includes("/ContactlessSignin") && !isMobile
    //console.log(selectedType);
    if (pathName.includes("/ContactlessSignin") && !isMobile) {
      setSelectedType('AC');
      setIsClicked(true);
      dispatch(setSignInOverlay(true));
    }
  }, [isMobile, pathName]);

  const backToSigninSidebar = () => {
    setSelectedType('');
    setIsClicked(false);
  };

  useEffect(() => {
    let uniqueSessionId = uuidv4().replace(/-/g, '');
    if (!getLocalStorageItem(localStorageKeys.uniqueSessionId)) {
      setLocalStorageItem(localStorageKeys.uniqueSessionId, uniqueSessionId);
    }
  }, []);

  return (
    <>
      <div
        className="wrapper"
        style={{ backgroundImage: `url(${props.backgroundImage.value.src})` }}
      >
        <div className={`main ${isContentState ? 'contentState' : ''}`}>
          <div className={`main-header ${isMobile && isClicked ? 'mobile-selected-header' : ''}`}>
            {!isMobile && (
              <>
                <a
                  className="signin-header-logo"
                  onClick={() => {
                    redirectToUrl('/');
                  }}
                  href="javascript:void(0)"
                >
                  <img alt="PRESTO logo" src={HeaderLogo} />
                </a>
              </>
            )}
            {isMobile &&
              (!isClicked ? (
                <>
                  <a
                    className="signin-header-logo"
                    onClick={() => {
                      redirectToUrl('/');
                    }}
                    href="javascript:void(0)"
                  >
                    <img alt="PRESTO logo" src={HeaderLogo} />
                  </a>
                </>
              ) : (
                <div className="card-left">
                  <div className="card-left-arrow" onClick={backToSigninSidebar}>
                    <img src={props?.fields?.backToOptionImg?.value?.src}></img>
                  </div>
                  <div className="card-header-text">{props.fields.backToOption.value}</div>
                </div>
              ))}
          </div>
          <div className="main-wrapper">
            {!isMobile && (
              <>
                <Placeholder
                  name="presto-signinsidebar-section"
                  rendering={props.rendering}
                  onClickCallback={onClickCallback}
                  typeSelected={selectedType}
                />
                {selectedType === 'PA' ? (
                  <Placeholder name="presto-signin-section" rendering={props.rendering} />
                ) : selectedType === 'APC' ? (
                  <Placeholder name="presto-anonsignin-section" rendering={props.rendering} />
                ) : selectedType === 'AC' ? (
                  <Placeholder
                    name="presto-contactlesssignin-section"
                    rendering={props.rendering}
                  />
                ) : null}
              </>
            )}
            {isMobile && (
              <>
                {!isClicked ? (
                  <Placeholder
                    name="presto-signinsidebar-section"
                    rendering={props.rendering}
                    onClickCallback={onClickCallback}
                    typeSelected={selectedType}
                    isClicked={isClicked}
                  />
                ) : selectedType === 'PA' ? (
                  <Placeholder name="presto-signin-section" rendering={props.rendering} />
                ) : selectedType === 'APC' ? (
                  <Placeholder name="presto-anonsignin-section" rendering={props.rendering} />
                ) : selectedType === 'AC' ? (
                  <Placeholder
                    name="presto-contactlesssignin-section"
                    rendering={props.rendering}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <Placeholder name="presto-footer" rendering={props.rendering} />
    </>
  );
};

export default Signin;