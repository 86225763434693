import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { isJsonString, hasLongInputLabel } from '../../services/utils';
import apiResponses from '../../constants/apiResponses';
import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { updateUserInfo } from '../../redux/user/actions';
import { ButtonBlock, Button } from '../../atoms';
import { Error, TextInput, CardBlock, Header } from '../../molecules';

export default function UpdateEmail0({
  fields,
  toNextStep,
  setState,
  toFirstStep,
  t,
  userInfo,
  dispatch,
}) {
  const [inlineErrorEmail, setInlineErrorEmail] = useState();
  const [inlineErrorPassword, setInlineErrorPassword] = useState();
  const [error, setIsError] = useState();

  return (
    <>
      <Header centered headerTitleA11y={fields.updateHeadingA11y?.value}>
        <Text field={fields.updateHeading} />
      </Header>
      <CardBlock title={fields.updateNote.value} titleA11y={fields.updateNoteA11y?.value}>
        {error && <Error title={t('updateEmailCouldNotBeProcessed')} />}

        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            confirmEmail: '',
            password: '',
          }}
          validate={(values) => {
            const errors = {};
            if (values?.email === userInfo?.loginEmail) {
              errors.email = t('updateEmailNotAvaliable');
            }
            return errors;
          }}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required(t('updateEmailNotValidEmail'))
              .matches(
                /^[\w+_-]+(?:\.[\w+_-]+)*@(?:[\w\d-]+\.)+\w{2,6}$/,
                t('updateEmailNotAvaliable')
              ),
            confirmEmail: Yup.string()
              .required(t('updateEmailNotValidEmail'))
              .oneOf([Yup.ref('email'), null], t('updateEmailNotMatching')),
            password: Yup.string().required(t('updateEmailBlankPassword')),
          })}
          onSubmit={(values) => {
            api
              .updateLoginEmail({
                CustomerId: userInfo.customerId,
                LoginEmail: values.email,
                Password: values.password,
              })
              .then((response) => {
                setInlineErrorPassword('');
                setInlineErrorEmail('');
                setIsError('');

                if (response.data.Success) {
                  setState({
                    newEmail: values.email,
                  });
                  dispatch(updateUserInfo(dispatch));
                  toNextStep();
                } else {
                  const parsedMessageObject =
                    response.data.Error &&
                    isJsonString(response.data.Error) &&
                    JSON.parse(response.data.Error);
                  if (parsedMessageObject.Code === apiResponses.securityWrongPassword) {
                    setInlineErrorPassword(t('updateEmailWrongPassword'));
                  } else if (parsedMessageObject.Code === apiResponses.securityEmailAlreadyExists) {
                    setInlineErrorEmail(t('updateEmailAlreadyUsed'));
                  } else {
                    handleServerSuccessError(response.data.Error, setIsError);
                  }
                }
              });
          }}
        >
          {({ errors, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="emailVerification-formGrid">
                <div className="emailVerification-formRow">
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="email"
                      errors={errors}
                      touched={touched}
                      label={fields.newEmailLabel}
                      hasLongLabel={hasLongInputLabel(
                        fields.newEmailLabel,
                        fields.confirmNewEmailLabel
                      )}
                      noMarginTop
                      serverError={inlineErrorEmail}
                    />
                  </div>
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="confirmEmail"
                      errors={errors}
                      touched={touched}
                      label={fields.confirmNewEmailLabel}
                      hasLongLabel={hasLongInputLabel(
                        fields.newEmailLabel,
                        fields.confirmNewEmailLabel
                      )}
                      noMarginTopDesktopOnly
                    />
                  </div>
                </div>
                <div className="emailVerification-formRow">
                  <div className="emailVerification-formCol">
                    <TextInput
                      name="password"
                      errors={errors}
                      touched={touched}
                      label={fields.passwordLabel}
                      type="password"
                      serverError={inlineErrorPassword}
                      withViewIcon
                      showIconA11y={fields?.showPasswordA11y?.value}
                      hideIconA11y={fields?.hidePasswordA11y?.value}
                    />
                  </div>
                </div>
              </div>

              <ButtonBlock>
                <ButtonBlock right>
                  <Button type="submit" buttonTextA11y={fields.confirmButtonText?.value}>
                    <Text field={fields.confirmButtonText} />
                  </Button>
                  <Button
                    white
                    firstOrder
                    type="button"
                    buttonTextA11y={fields.cancelButtonText?.value}
                    onClick={toFirstStep}
                  >
                    <Text field={fields.cancelButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </CardBlock>
    </>
  );
}
