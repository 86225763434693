export default {
    Success: 'Success',
    Update: 'Update',
    Enroll: 'Enroll',
    Disable: 'Disable',
    EnrollCancel: 'EnrollCancel',
    UpdateCancel: 'UpdateCancel',
    DisableCancel: 'DisableCancel',
    IsMaxTryExceedsOnEnroll: 'IsMaxTryExceedsOnEnroll',
    IsMaxTryExceedsOnUpdate: 'IsMaxTryExceedsOnUpdate',
    IsMaxTryExceedsOnDisable:'IsMaxTryExceedsOnDisable',
};
