import React, { useEffect } from 'react';
import CloseSideNavImage from '../../assets/images/HamburgerMenuClose.svg';
import './paymentModal.scss';
import { Loader } from '../../atoms';
import FocusLock from 'react-focus-lock';
 
const PaymentModal = ({
  isModalOpen,
  setIsModalOpen,
  disableBackdropClick,
  disableEscapeKeyDown,
  isGetTicketFetching,
  title,
  cautionMessage,
  handleCloseModal,
}) => {
  useEffect(() => {
    if (isModalOpen) {
      document.documentElement.style.overflow = 'hidden';
    }
    return () => (document.documentElement.style.overflow = 'auto');
  }, [isModalOpen]);
 
  const closeModal = () => {
    setIsModalOpen(false);
  };
 
  return (
    isModalOpen && (
      <>
        <div className="payment-modal">
          <div
            className="modal-overlay"
            tabIndex="-1"
            onClick={disableBackdropClick ? () => null : closeModal}
            onKeyDown={disableEscapeKeyDown ? () => null : closeModal}
          />
          <FocusLock
            disabled={!isModalOpen}
            returnFocus={true}
            autoFocus={true}
          >
            <div className="modal-wrapper" role="dialog" tabIndex="0" aria-label={title}>
              <div className="modal-header">
                <div className="modal-title" tabIndex="-1">
                  {title}
                </div>
                <div
                  className="modal-title-message"
                  style={{ color: '#ffA500', fontSize: '14px', paddingRight: '10%' }}
                  tabIndex="0"
                >
                  {cautionMessage}
                </div>
              </div>
              {isGetTicketFetching && <Loader isFromPaymentModal={true} />}
              <div id="outerDiv" className="modal-outerDiv">
                <div id="monerisCheckout" className="modal-checkout" />
              </div>
            </div>
          </FocusLock>
        </div>
      </>
    )
  );
};
 
export default PaymentModal;