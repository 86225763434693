import React from 'react';
import './paragraphContent.scss';
import './termsAndConditions.scss';

import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const ParagraphContent = (props) => (
  <div className={props?.fields?.CssClass?.value}>
    <RichText field={props?.fields?.Description} />
  </div>
);

export default withTranslation()(ParagraphContent);
