import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import handleThirdPartyError from '../../services/handleThirdPartyError';
import handleAVSValidationErrorSPM from '../../services/handleAVSValidationErrorSPM';
import { Loader } from '../../atoms';
import { getQueryParams } from '../../services/utils';
import { StepTracker } from '../../molecules';
import SavedPaymentMethod0 from './SavedPaymentMethod0';
import RemoveMethodStep1 from './RemoveMethodStep1';
import RemoveMethodStep2 from './RemoveMethodStep2';
import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';

import './savedPaymentMethod.scss';

const stepsMap = [SavedPaymentMethod0, RemoveMethodStep1, RemoveMethodStep2];

const SavedPaymentMethod = ({ t, fields }, props) => {
  const [error, setIsError] = useState('');
  const [thirdPartySystemErrorType, setThirdPartySystemErrorType] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const userInfo = useSelector((s) => s.user);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const focusDiv = useRef();
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => window.location.reload();
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const Stepper = stepsMap[step];
  const [component, setComponent] = useState({ paymenMethodsList: [] });
  const [state, setState] = useState({});
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [isQueryParams, setIsQueryParams] = useState(false);
  const errorDisplay = handleAVSValidationErrorSPM(queryParams, t);
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const [maxPaymentThresholdReached, setMaxPaymentThresholdReached] = useState(false);

  useEffect(() => {
    if (queryParams && queryParams.success) {
      setIsSuccess(JSON.parse(queryParams.success));
      setIsQueryParams(true);
    }
    if (queryParams.error) {
      handleThirdPartyError(queryParams.error, setThirdPartySystemErrorType);
    }
  }, []);

  useEffect(() => {
    if (!userInfo.selectedCard.visibleId) {
      return;
    }
    setIsLoading(true);
    api
      .getRegisterPaymentMeansForCustomer({
        VisibleId: userInfo.selectedCard.visibleId,
        CustomerId: userInfo.customerId,
      })
      .then((response) => {
        if (response) {
          if (response.data.Success === false) {
            handleServerSuccessError(response.data.Error, setIsError);
            setIsLoading(false);
          } else if (response.data.Success) {
            setComponent({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            if (
              response.data.RegisterPaymentMeansList.filter((key) => key.AccountType == 1).length >=
              3
            ) {
              setMaxPaymentThresholdReached(true);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
            setIsLoading(false);
          }
        }
      });
  }, [userInfo.selectedCard]);

  const monerisIframeUrl =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.IframeJs').map((config) => config.setting);

  const monerisApplePayJs =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.ApplepayJs').map((config) => config.setting);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = monerisIframeUrl[0];
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const applePayScript = document.createElement('script');
    applePayScript.src = monerisApplePayJs[0];
    if (monerisApplePayJs[0] != null && monerisApplePayJs[0] != '') {
      applePayScript.src = monerisApplePayJs[0];
    }
    applePayScript.async = true;
    document.body.appendChild(applePayScript);
    return () => {
      document.body.removeChild(applePayScript);
    };
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {step !== 0 ? (
        <div className="no-focus-outline">
          <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
            {t('StepperChangeSRText')}
          </span>
          <StepTracker
            activeIndex={step - 1}
            items={[
              {
                text: <Text field={fields.step1Title} />,
                textInProgressA11y: fields.step1TitleInProgressA11y.value,
                textCompletedA11y: fields.step1TitleCompletedA11y.value,
              },
              {
                text: <Text field={fields.step2Title} />,
                textNotCompletedA11y: fields.step2TitleNotCompletedA11y.value,
                textCompletedA11y: fields.step2TitleCompletedA11y.value,
                hasError: thirdPartySystemErrorType !== '' || !state?.isPaymentMethodRemoved,
              },
            ]}
          />
        </div>
      ) : null}

      <Stepper
        {...props}
        t={t}
        fields={fields}
        state={state}
        setState={setState}
        component={component}
        maxPaymentThresholdReached={maxPaymentThresholdReached}
        setMaxPaymentThresholdReached={setMaxPaymentThresholdReached}
        setComponent={setComponent}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
        error={error}
        setIsError={setIsError}
        errorContent={errorDisplay}
        isSuccess={isSuccess}
        isQueryParams={isQueryParams}
        thirdPartySystemErrorType={thirdPartySystemErrorType}
      />
    </>
  );
};

export default withTranslation()(SavedPaymentMethod);
