import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaImage, Loader } from '../../atoms';
import routes from '../../constants/routes';
import {
  redirectToUrl,
  formatDecimalWithCurrency,
  convertToDollars,
  sortItems,
  getLongLanguageCode,
  getShortLanguageCode,
} from '../../services/utils';
import { selectCard } from '../../redux/user/actions';
import otherLogo from '../../assets/images/Other.svg';
import api from '../../services/api';
import mediaSubType from '../../constants/mediaSubType';
import mediaTypes from '../../constants/mediaTypes';
import langConstants from '../../constants/lang';
import { isFrench } from '../../services/utils';

const CardListItem = (props) => {
  const dispatch = useDispatch();
  const contactlessMedias = useSelector((state) => state.user.contactlessMedias);
  const [activePasses, setActivePasses] = useState([]);
  const [pendingPasses, setPendingPasses] = useState([]);
  const logo = useSelector((state) => state.products?.logos);
  const {
    nickName = '',
    type = '',
    currentBalance,
    mediaType = '',
    mediaSubTypeWallet = '',
  } = props.card;
  const cardType = `${type}Card`;
  const isMobileWallet = mediaType === mediaTypes.CRDNC;
  const isAppleWalletCard = mediaSubTypeWallet === mediaSubType.APPLE;
  const mediaImageProps = {
    big: true,
    [cardType]: true,
  };
  const activeImageProps = {
    big: true,
    ['activeGWalletCard']: true,
    cardImgSrc: isAppleWalletCard
      ? props.fields.AppleWalletImageDesktop.value.src //props for apple wallet
      : props.fields.ActiveWalletImageDesktop.value.src,
    cardImgAlt: isAppleWalletCard
      ? props.fields.AppleWalletImageDesktop.value.alt //props for apple wallet
      : props.fields.ActiveWalletImageDesktop.value.alt,
  };
  const inActiveImageProps = {
    big: true,
    ['inActiveGWalletCard']: true,
    cardImgSrc: props.fields.InactiveWalletImageDesktop.value.src,
    cardImgAlt: props.fields.InactiveWalletImageDesktop.value.alt,
  };
  const selectedCard = useSelector((state) => state.user.selectedCard);
  const handleClick = async (card, selectedCard) => {
    let url = isMobileWallet
      ? props?.fields?.WalletHeadingLink?.value.href
      : props?.fields?.headingLink?.value.href;
    const isLangaugeIncluded = [getShortLanguageCode(), getLongLanguageCode()].some((lang) => {
      return url.includes(lang);
    });
    const isSameCard = card.visibleId === selectedCard.visibleId;
    const googleWallets = contactlessMedias?.filter((card) => card.mediaType === mediaTypes.CRDNC);
    if (isSameCard) {
      redirectToUrl(url, !isLangaugeIncluded, true);
    }
    if (card.mediaType === mediaTypes.CRDNC) {
      try {
        await api.getStoredMediaTypeInSession({
          MediaType: card.mediaSubTypeWallet,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      if (googleWallets.length > 0) {
        try {
          await api.getStoredMediaTypeInSession({
            MediaType: googleWallets[0].mediaSubTypeWallet,
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
    dispatch(
      selectCard(card, {
        url: url,
        includeLanguage: false,
        redirected: isSameCard,
      })
    );
  };
  const getTransitPasses = (visibleId, passes) => {
    // api.getAllProductsLoadedInMedia({ VisibleId: visibleId }).then((response) => {
    if (passes?.length > 0) {
      let copy = [
        ...passes.map((el) => ({
          ...el,
          uniqueName: el.ProductServiceProvider.Name,
        })),
      ];
      copy.sort(sortItems('uniqueName', true));
      let active = copy.filter((el) => el.ActivationStatus === 0) || [];
      setActivePasses(active.reverse());
      let pending = copy.filter((el) => el.ActivationStatus === 2) || [];
      setPendingPasses(pending.reverse());
    }
    //  });
  };
  useEffect(() => {
    if (props.showTransitPass) {
      // getTransitPasses(props?.card?.visibleId, props?.card?.passes);
    }
  }, []);
  const getTransitPassLogo = (transitPass) => {
    let transitLogo = logo && logo[transitPass.ProductServiceProvider?.Id]?.Logo;
    return transitLogo ? (
      <img alt={transitLogo?.Alt} className="myProductsOverview-logo" src={transitLogo?.Src} />
    ) : // <img alt="Logo - GTAFS Central System" className="checkoutGrid-logo" src={otherLogo} />
    null;
  };
  const getTransitPassLogoForGoogleWallet = (id) => {
    const logoObj = Object.values(logo).find((obj) => {
      return obj.ServiceProviderId == id;
    });
    let transitLogo = logoObj ? logoObj.Logo : null;
    return transitLogo ? (
      <img alt={transitLogo?.Alt} className="myProductsOverview-logo" src={transitLogo?.Src} />
    ) : null;
  };
  return (
    <>
      <li className="myProductsOverview-cardListItem">
        {isMobileWallet ? ( // apple + google
          props.card.hasActiveImageGoog || props.card.hasActiveImageAppl ? (
            <MediaImage {...activeImageProps} /> //active gwallet
          ) : (
            <MediaImage {...inActiveImageProps} /> //inactive gwallet
          )
        ) : (
          <MediaImage {...mediaImageProps} />
        )}
        <div className="myProductsOverview-content">
          <div className="myProductsOverview-textWrapper">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                handleClick(props.card, selectedCard);
              }}
            >
              {nickName}
            </a>
            {type === 'presto' || isMobileWallet ? (
              <div className="myProductsOverview-cardBalance">
                {currentBalance !== null
                  ? isMobileWallet
                    ? formatDecimalWithCurrency(convertToDollars(currentBalance))
                    : formatDecimalWithCurrency(currentBalance)
                  : ''}
              </div>
            ) : null}
          </div>
          <div className="transit-passes-container">
            {props?.activePasses?.length && props.showTransitPass
              ? [
                  <div className="hr_line hr_line--1px" />,
                  <div className="myProductsOverview-transitPasses">
                    <p>{props?.fields?.TransitLabel?.value}</p>
                    <div className="myProductsOverview-transitPasses--icons">
                      {props.activePasses.map((transitPass) => getTransitPassLogo(transitPass))}
                    </div>
                  </div>,
                ]
              : null}
            {props?.pendingPasses?.length && props.showTransitPass
              ? [
                  <div className="hr_line hr_line--1px" />,
                  <div className="myProductsOverview-transitPasses">
                    <p>{props?.fields?.PendingPasslabel?.value}</p>
                    <div className="myProductsOverview-transitPasses--icons">
                      {props.pendingPasses.map((transitPass) => getTransitPassLogo(transitPass))}
                    </div>
                  </div>,
                ]
              : null}
            {props?.activePassesForGoogleWallet?.length
              ? [
                  <div className="hr_line hr_line--1px" />,
                  <div className="myProductsOverview-transitPasses">
                    <p>{props?.fields?.TransitLabel?.value}</p>
                    <div className="myProductsOverview-transitPasses--icons">
                      {props.activePassesForGoogleWallet.map((id) =>
                        getTransitPassLogoForGoogleWallet(id)
                      )}
                    </div>
                  </div>,
                ]
              : null}
          </div>
        </div>
      </li>
    </>
  );
};

export default CardListItem;
