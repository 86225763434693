import React from 'react';

import { useSelector } from 'react-redux';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TopNavigation = (props) => {
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const isContactlessAuthenticated = useSelector((state) => state.contactless.isContactlessUserSignedIn);
  return (
    <>
      {(authenticated || isContactlessAuthenticated) ? (
        <Placeholder name="presto-top-navigation-authenticated" rendering={props.rendering} />
      ) : anonymous ? (
        <Placeholder name="presto-top-navigation-anonymous" rendering={props.rendering} />
      ) : (
        <Placeholder name="presto-top-navigation-unauthenticated" rendering={props.rendering} />
      )}
    </>
  );
};

export default TopNavigation;
