import React, { useState, useRef, useEffect } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ButtonBlock, Button, Loader, MediaImage } from '../../atoms';
import routes from '../../constants/routes';
import { StepTracker, CardBlock } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import api from '../../services/api';
import cardLogos from '../../constants/unlinkCard';
import { fetchUserInfo } from '../../redux/user/actions';
import CreditCardImg from '../../assets/images/credit_card.png';
import AmericanExpress from '../../assets/images/card_amex.svg';
import Interac from '../../assets/images/card_interac.svg';
import MasterCard from '../../assets/images/card_masterCard.svg';
import VisaCardImg from '../../assets/images/card_visa.svg';
import Sears from '../../assets/images/Sears.png';
import MonerisVault from '../../assets/images/MonerisVault.png';
import MonerisToken from '../../assets/images/MonerisToken.png';
import Jcb from '../../assets/images/Jcb.png';
import Discover from '../../assets/images/Discover.png';
import Unipay from '../../assets/images/Unipay.png';
import './UnlinkCard.scss';

const UnlinkCard = (props) => {
    const { t } = props;
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const manageMyProduct = useSelector((reduxState) => reduxState.products.manageMyProduct);
    const toNextStep = () => setStep(step + 1);
    const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
    const user = useSelector((reduxState) => reduxState.user)

    const fareMedia = useSelector((reduxState) => {
        let presto = reduxState.user.fareMedias.find(
            (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
        );
        let contactless = reduxState.user.contactlessMedias.find(
            (el) => el.mediaId === manageMyProduct.selectedCard.mediaId
        );
        if (presto) return presto;
        if (contactless) return contactless;
        return {};
    });

    const nickName = manageMyProduct.selectedCard.nickName;
    const cardId = manageMyProduct.selectedCard.mediaId;
    const constactlessCardImage = manageMyProduct.selectedCard.type;
    const dispatch = useDispatch();
    const focusDiv = useRef();

    const contactlessCardRemoved = () => {
        setLoading(true);
        api
            .contactlessRemoveCard({
                AccountId: user?.accountId,
                MediaInstanceId: fareMedia?.visibleId?.split('|')[0],
            })
            .then((response) => {
                if (response.data.Success === true) {
                    setApiStatus({ submit: true, state: 'SUCCESS' });
                    toNextStep();
                    fetchUserInfo(dispatch);
                    setLoading(false);
                }
                else {
                    setApiStatus({ submit: true, state: 'ERROR' });
                    toNextStep();
                    fetchUserInfo(dispatch);
                    setCheckError(true);
                    setLoading(false);
                }
            })

    }

    useEffect(() => {
        if (manageMyProduct?.removeCardEnabled) {
            focusDiv.current.focus();
            document.body.scrollTop = 0;
        }
    }, [manageMyProduct]);

    useEffect(() => {
        if (apiStatus.submit) {
            focusDiv.current.focus();
            document.body.scrollTop = 0;
        }
    }, [apiStatus]);

    const getCardImage = (Type) => {
        let cardImage;
        let cardAlt;
        switch (Type.toLowerCase()) {
            case 0:
            case 'v':
            case 'visa':
                cardImage = VisaCardImg;
                cardAlt = t('visaCardLogo');
                break;
            case 1:
            case 'm':
            case 'mastercard':
                cardImage = MasterCard;
                cardAlt = t('masterCardLogo');
                break;
            case 2:
            case 'americanexpress':
                cardImage = AmericanExpress;
                cardAlt = t('amexCardLogo');
                break;
            case 3:
            case 'no':
            case 'discover':
                cardImage = Discover;
                cardAlt = cardLogos.discoverlogo;
                break;
            case 4:
            case 'monerisvault':
                cardImage = MonerisVault;
                cardAlt = cardLogos.monerisVaultLogo;
                break;
            case 5:
            case 'moneristoken':
                cardImage = MonerisToken;
                cardAlt = cardLogos.monerisTokenLogo;
                break;
            case 6:
            case 'interac':
                cardImage = Interac;
                cardAlt = t('interacCardLogo');
                break;
            case 7:
            case 'master':
                cardImage = MasterCard;
                cardAlt = t('masterCardLogo');
                break;
            case 8:
            case 'ax':
            case 'amex':
                cardImage = AmericanExpress;
                cardAlt = t('amexCardLogo');
                break;
            case 9:
            case 'unknown':
                cardImage = CreditCardImg;
                cardAlt = cardLogos.creditCardLogo;
                break;
            case 10:
                cardImage = CreditCardImg;
                cardAlt = cardLogos.creditCardLogo;
                break;
            case 11:
            case 'c1':
            case 'jcb':
                cardImage = Jcb;
                cardAlt = cardLogos.jcbLogo;
                break;
            case 12:
            case 'd':
            case 'debit':
                cardImage = Interac;
                cardAlt = cardLogos.unionPayLogo;
                break;
            case 13:
            case 'se':
            case 'sears':
                cardImage = Sears;
                cardAlt = cardLogos.searsLogo;
                break;
            case 14:
            case 'up':
            case 'unionpay':
                cardImage = Unipay;
                cardAlt = cardLogos.unionPayLogo;
                break;
            default:
                cardImage = CreditCardImg;
                cardAlt = cardLogos.creditCardLogo;
        }
        return { cardImage, cardAlt };
    };

    const showMessage = () => {
        if (checkError) {

            return (
                <>
                    {loading && <Loader />}
                    <div className='error-block'>
                        <div className='content-error-block-title'>
                            <Text field={props.fields.errorMessage} />
                        </div>
                        <div className='error-text'>
                            <RichText field={props.fields.unSuccessMessage} />
                        </div>
                    </div>
                    <div className="manage-button">
                        <ButtonBlock>
                            <ButtonBlock right>
                                <Button type="submit"
                                    onClick={() => redirectToUrl(routes.manageMyCards)}
                                    buttonTextA11y={props.fields.backToManageMyCardA11y.value} >
                                    <Text field={props.fields.backToManageMyCard} />
                                </Button>
                            </ButtonBlock>
                        </ButtonBlock>
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    {loading && <Loader />}
                    <div clasName="remove-card-step2-container">
                        <div className="remove-card-step2">
                            <div className='success-cardId'>
                                <Text field={props.fields.contactlessCardId} /> : {cardId}
                            </div>
                            <div className="success-message" role="alert">
                                <div id="message">
                                    <Text field={props.fields.successMessage} />
                                </div>
                            </div>
                        </div>
                        <div className="manage-button">
                            <ButtonBlock>
                                <ButtonBlock right>
                                    <Button
                                        onClick={() => redirectToUrl(routes.manageMyCards)}
                                        buttonTextA11y={props.fields.backToManageMyCardA11y.value} >
                                        <Text field={props.fields.backToManageMyCard} />
                                    </Button>
                                </ButtonBlock>
                            </ButtonBlock>
                        </div>
                    </div>
                </>
            );
        }
    };

    return [

        manageMyProduct?.removeCardEnabled ? (
            <div className="remove-card-container">
                <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
                    {t('StepperChangeSRText')}
                </span>
                <div className="remove-card-steps">
                    <StepTracker
                        activeIndex={step}
                        isUnlinkCard
                        items={[
                            {
                                text: <Text field={props.fields.step1} />,
                                textInProgressA11y: props.fields.step1A11y.value,
                                textNotCompletedA11y: props.fields.step2NotCompletedA11y.value,
                                textCompletedA11y: props.fields.step1CompletedA11y.value,
                            },
                            {
                                text: <Text field={props.fields.step2} />,
                                textInProgressA11y: props.fields.step2A11y.value,
                                textNotCompletedA11y: props.fields.step2NotCompletedA11y.value,
                                textCompletedA11y: props.fields.step2CompletedA11y.value,
                                hasError: apiStatus.state === 'ERROR' ? true : false,

                            },
                        ]}
                    />
                </div>
                <div>
                    <div className="remove-contactless-title">
                        <Text field={props.fields.title} />
                    </div>
                    {!apiStatus.submit ? (
                        <div className='remove-contactless-details'>
                            <CardBlock cssClass='remove-contactless'>
                                <div className='remove-contactless-header'>
                                    <div className='remove-contactless-image'>
                                        <MediaImage
                                            cardImgSrc={getCardImage(constactlessCardImage)?.cardImage}
                                            cardImgAlt={getCardImage(constactlessCardImage)?.cardAlt}
                                            big
                                        />
                                    </div>
                                    <div className='remove-contactless-details'>
                                        <div className='name'>
                                            <p>{nickName}</p>
                                        </div>
                                        <div className='card-id'>
                                            <Text field={props.fields.contactlessCardId} />
                                        </div>
                                        <div className='card-details-contactless'>
                                            <p>{cardId}</p>
                                        </div>
                                    </div>
                                    <div className='text-contactless'>
                                        <RichText field={props.fields.confirmationMessage} />
                                    </div>
                                </div>
                            </CardBlock>
                            <div className='button-block'>
                                <ButtonBlock>
                                    <ButtonBlock right>
                                        <Button type="submit"
                                            onClick={() => contactlessCardRemoved()}
                                            buttonTextA11y={props.fields.confirmA11y.value}>
                                            <Text field={props.fields.confirm} />
                                        </Button>
                                        <Button
                                            firstOrder
                                            white
                                            buttonTextA11y={props.fields.backA11y.value}
                                            onClick={() => redirectToUrl(routes.manageMyCards)}>
                                            <Text field={props.fields.back} />
                                        </Button>
                                    </ButtonBlock>
                                </ButtonBlock>
                            </div>
                        </div>
                    ) : (
                        showMessage()
                    )}
                </div>
            </div>
        ) : null,
        loading ? <Loader /> : null,
    ];
};

export default withTranslation()(UnlinkCard);
