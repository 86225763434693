import React from 'react';

import { useHistory } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { InlineButtonBlock, Success } from '../../molecules';

export default function ForgotUsername3({ fields, state }) {
  const history = useHistory();
  return (
    <Success
      title={fields.yourUsernameRetrieved}
      Content={() => (
        <>
          <div className="username-success">
            <Text field={fields.yourUsername} /> <span>{state.username}</span>
          </div>
          <InlineButtonBlock onClick={() => history.push('/')} buttonText={fields.buttonSignIn} />
        </>
      )}
    />
  );
}
