import React from 'react';

import { useField, Field } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Icon } from '@material-ui/core';
import useMedia from 'use-media';
import { withTranslation } from 'react-i18next';

import { InlineError } from '../../atoms';
import downIcon from '../../assets/images/down.svg';
import downgreyIcon from '../../assets/images/down_grey.svg';
import './select.scss';

let isMobileSelectOpen = false;

const Select = ({
  errors,
  isDisabled,
  isLoading,
  options,
  name,
  label,
  placeholder,
  labelA11y,
  selectA11y,
  selectablePlaceholder,
  fullWidth,
  t,
  onChange,
  onClose,
  touched,
  defaultValue,
  defaultLabel,
  showPlaceholder,
  showMatAutoComplete = false,
  showOption0 = false,
  showFormikSelect = false,
}) => {
  const [field, , helpers] = useField(name);
  const isWide = useMedia({ maxWidth: '920px' });
  const showWide = showFormikSelect ? true : isWide;

  const error = touched && touched[name] && errors && errors[name];
  const placeholderToArray = [{ label: placeholder, value: null }];

  const defaultOption = defaultValue ? defaultValue : '';
  const defaultOptionLabel = defaultLabel ? defaultLabel : '';

  return (
    <div className={`selectbox ${error ? 'selectbox-error' : ''}`}>
      {label ? (
        <div className="label" aria-label={labelA11y}>
          {label}
        </div>
      ) : null}
      {showWide && !showMatAutoComplete ? (
        <Field
          className="mobileSelect"
          component="select"
          name={name}
          id="selectdropdown"
          disabled={isDisabled}
          aria-label={labelA11y}
          onClick={() => {
            if (isMobileSelectOpen) {
              isMobileSelectOpen = false;
              if (onClose) onClose();
            } else {
              isMobileSelectOpen = true;
            }
          }}
        >
          {showPlaceholder ? (
            <option value="">{placeholder || t('selectPlaceholder')}</option>
          ) : null}
          {options.map((option) => (
            <option
              value={option.value}
              key={option.value}
              className={option.value === '0' ? 'option-disabled' : ''}
              aria-disabled={option.value === '0' ? true : false}
            >
              {option.label}
            </option>
          ))}
        </Field>
      ) : (
        <Autocomplete
          disabled={isDisabled}
          disablePortal
          disableClearable
          openOnFocus
          loading={isLoading}
          options={selectablePlaceholder ? placeholderToArray.concat(options) : options}
          value={
            options.find((i) => i.value === field.value) || {
              value: defaultOption,
              label: defaultOptionLabel,
            }
          }
          getOptionLabel={(option) => option.label.toString()}
          getOptionDisabled={(option) => !showOption0 && option.value === '0'}
          closeIcon={null}
          popupIcon={
            <Icon>
              <img alt="arrow icon" src={isDisabled ? downgreyIcon : downIcon} />
            </Icon>
          }
          onClose={onClose}
          onChange={(_, value) => {
            helpers.setValue(value?.value || '');
            if (onChange) {
              onChange(value);
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          id={name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder || t('selectPlaceholder')}
              inputProps={{
                ...params.inputProps,
                'aria-label': labelA11y,
                'aria-autocomplete': 'list',
              }}
              style={{ width: fullWidth ? '100%' : undefined }}
              className="textField"
            />
          )}
        />
      )}
      {error ? <InlineError>{error}</InlineError> : null}
    </div>
  );
};

export default withTranslation()(Select);
