import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { hasAnyCard } from '../../services/utils';
import { showScreenHeader } from '../../redux/user/actions';
import mediaSubTypes from '../../constants/mediaSubTypes';
import { Header } from '../../molecules';

const TransactionHistory = (props) => {
  const dispatch = useDispatch();
  const { selectedCard, isScreenHeaderVisible } = useSelector((state) => state.user);
  let content;

  // eslint-disable-next-line no-nested-ternary
  content = hasAnyCard(useSelector((state) => state.user)) ? (
    <>
      {isScreenHeaderVisible ? (
        <Header headerTitleA11y={props.fields.headingA11y.value}>
          <Text field={props.fields.heading} />
        </Header>
      ) : null}
      {selectedCard.type === mediaSubTypes.presto ? (
        <Placeholder name="presto-transaction-history-presto" rendering={props.rendering} />
      ) : (
        <Placeholder name="presto-transaction-history-contactless" rendering={props.rendering} />
      )}
    </>
  ) : (
    <Placeholder
      name="presto-my-products-overview"
      rendering={props.rendering}
      linkableHeadingForNoMedia={false}
    />
  );

  useEffect(() => {
    dispatch(showScreenHeader(true));
  }, []);

  return <>{content}</>;
};
export default TransactionHistory;
