import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';

import { Checkbox, ButtonBlock, Button, RadioInput } from '../../../atoms';
import { PrestoDateRange } from '../../../molecules';

import './transactionFilters.scss';

const SELECTED_ALL = ['1', '2', '3', '4'];

const TransactionFilters = ({ filters, setFilters, fields, setIsFilters, clearFilters, t }) => {
  const { logos } = useSelector((state) => state.products) || [];
  const [datepickerValue, setDatepickerValue] = useState(filters.PeriodId);
  const allServiceProviders = Object.entries(logos).map((x) => {
    return { id: x[0], value: x[1] };
  });
  const [transAgencySort, setTransAgencySort] = useState([]);
  const [isAll, setIsAll] = useState(
    filters.TransactionCategoryId.length && filters.TransactionCategoryId[3]
  );
  useEffect(() => {
    let allTAs = Object.entries(logos).map((x) => {
      return { id: x[0], value: x[1] };
    });
    let firstHalf = allTAs.slice(0, Math.floor(allTAs.length / 2));
    let secondHalf = allTAs.slice(Math.floor(allTAs.length / 2), allTAs.length);
    let sortedArr = secondHalf.reduce((previousValue, currentValue, currentIndex, array) => {
      previousValue.push(currentValue);
      if (firstHalf[currentIndex]) previousValue.push(firstHalf[currentIndex]);
      return previousValue;
    }, []);
    setTransAgencySort(sortedArr || []);
  }, [logos]);

  return (
    <div className="transactionFilters">
      <Formik
        enableReinitialize
        initialValues={{
          ...filters,
          TransactionCategoryId:
            filters.TransactionCategoryId === '0'
              ? SELECTED_ALL
              : filters.TransactionCategoryId.split(','),
        }}
        onSubmit={(values) => {
          setFilters({
            ...values,
            TransactionCategoryId: values.TransactionCategoryId.join(','),
            PeriodId: datepickerValue,
          });
          setIsFilters(false);
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, setFieldError, resetForm }) => {
          if (
            values.TransactionCategoryId.length &&
            values.TransactionCategoryId.length < 4 &&
            isAll
          ) {
            setIsAll(false);
          }
          if (
            values.TransactionCategoryId.length &&
            values.TransactionCategoryId.length === 4 &&
            !isAll
          ) {
            setIsAll(true);
          }
          return (
            <form onSubmit={handleSubmit}>
              <div className="transactionFilters-block">
                <label className="transactionFilters-label">
                  <Text field={fields.agencyRadiobuttonLabel} />
                </label>
                <div className="transactionFilters-options">
                  <RadioInput
                    regularFont
                    label={fields.agencyRadiobuttonAllLabel.value}
                    name="ServiceProviderId"
                    value="-1"
                    checked={values.ServiceProviderId === '-1'}
                    a11y={fields.agencyRadiobuttonAllLabelA11y.value}
                  />
                  {transAgencySort.map((item) => {
                    return (
                      <RadioInput
                        regularFont
                        label={item.value.Name}
                        name="ServiceProviderId"
                        value={item.value.HistorySearchId}
                        a11y={item.value.Name}
                        checked={values.ServiceProviderId === item.value.HistorySearchId}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="transactionFilters-block">
                <span className="dateRange-label">
                  <Text field={fields.dateRangeLabel} />
                </span>
                <PrestoDateRange
                  fields={fields}
                  values={values}
                  setFieldValue={setFieldValue}
                  name="PeriodId"
                  fromName="FromDate"
                  toName="ToDate"
                  datepickerValue={datepickerValue}
                  setDatepickerValue={setDatepickerValue}
                  errors={errors}
                  setFieldError={setFieldError}
                  t={t}
                  showSelectedDateRange={true}
                />
              </div>
              <div className="transactionFilters-block">
                <label className="transactionFilters-label">
                  <Text field={fields.transactionTypeLabel} />
                </label>
                <div className="transactionFilters-options">
                  <Checkbox
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('TransactionCategoryId', SELECTED_ALL);
                      } else {
                        setFieldValue('TransactionCategoryId', []);
                      }
                      setIsAll(value);
                    }}
                    checked={isAll}
                    regularFont
                    checkboxA11y={fields?.transactionTypeAll?.value}
                  >
                    <Text field={fields?.transactionTypeAll} />
                  </Checkbox>
                  <Checkbox
                    checkboxA11y={fields?.LoadsLabel?.value}
                    regularFont
                    value="1"
                    name="TransactionCategoryId"
                  >
                    <Text field={fields?.LoadsLabel} />
                  </Checkbox>
                  <Checkbox
                    checkboxA11y={fields?.TransitPassLoadsLabel?.value}
                    regularFont
                    value="2"
                    name="TransactionCategoryId"
                  >
                    <Text field={fields?.TransitPassLoadsLabel} />
                  </Checkbox>
                  <Checkbox
                    checkboxA11y={fields?.FarePaymentLabel?.value}
                    regularFont
                    value="3"
                    name="TransactionCategoryId"
                  >
                    <Text field={fields?.FarePaymentLabel} />
                  </Checkbox>
                  <Checkbox
                    checkboxA11y={fields?.TransitPassPaymentLabel?.value}
                    regularFont
                    value="4"
                    name="TransactionCategoryId"
                  >
                    <Text field={fields?.TransitPassPaymentLabel} />
                  </Checkbox>
                </div>
              </div>
              <ButtonBlock>
                <ButtonBlock right>
                  <Button type="submit" buttonTextA11y={fields.applyButtonTextA11y.value}>
                    <Text field={fields.applyButtonText} />
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      clearFilters();
                    }}
                    white
                    firstOrder
                    buttonTextA11y={fields.clearButtonTextA11y.value}
                  >
                    <Text field={fields.clearButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TransactionFilters;
