import React, { useState, useEffect } from 'react';
import { RichText, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Header, StepTracker, CardBlock, TextInput, Note, Error } from '../../molecules';
import { Formik } from 'formik';
import routes from '../../constants/routes';
import { redirectToUrl } from '../../services/utils';
import { Button, InlineError } from '../../atoms';
import sharedFormConstants from '../../constants/sharedFormConstants';
import * as Yup from 'yup';
import api from '../../services/api';
import './B2CAccountCreation.scss';

const B2CCreateAccount = (props) => {
  const [step, setStep] = useState(0);
  const toNextStep = () => {
    setStep(step + 1);
    // focusDiv.current.focus();
    document.body.scrollTop = 0;
  };
  const [errorMsg, setErrorMsg] = useState(false);
  let customerType;
  let partialObj;

  const liveCheck = (data) => {
    api
      .B2CAccountCreation({
        userEmail: data.emailAddress,
      })
      .then((response) => {
        let apiResponse = JSON.parse(response.data.CustomerType);
        partialObj = apiResponse.value[0] !== null ? apiResponse.value[0] : '';
        if (partialObj === undefined) {
          redirectToUrl(
            `${routes.b2cRegisterAccount}?email=${encodeURIComponent(data.emailAddress)}`
          );
        } else {
          customerType = apiResponse.value.length > 0 ? Object.values(partialObj) : '';
          if (customerType[0].toLowerCase() === 'partner') {
            redirectToUrl(
              `${routes.b2cAccountExists}?email=${encodeURIComponent(data.emailAddress)}`
            );
          }
          if (
            customerType[0].toLowerCase() === 'customer' ||
            customerType[0].toLowerCase() === 'both'
          ) {
            //redirectToUrl(routes.b2cRegisteredSignIn);
            setErrorMsg(true);
          }
        }
      })
      .catch((err) => { });
  };
  return (
    <div class="B2CCreateAccount">
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={props.fields.EmailIDStep} />,
            textInProgressA11y: props.fields.Step1InProgressA11y.value,
            textCompletedA11y: props.fields.Step1CompletedA11y.value,
          },
          {
            text: <Text field={props.fields.PasswordStep} />,
            textNotCompletedA11y: props.fields.Step2NotCompletedA11y.value,
            textInProgressA11y: props.fields.Step2InProgressA11y.value,
            textCompletedA11y: props.fields.Step2CompletedA11y.value,
          },
          {
            text: <Text field={props.fields.UserInformationStep} />,
            textNotCompletedA11y: props.fields.Step3NotCompletedA11y.value,
            textCompletedA11y: props.fields.Step3CompletedA11y.value,
          },
        ]}
      />
      <Header centered aria-label={props.fields.AccountInformationTitleA11y.value}>
        <Text field={props.fields.AccountInformationTitle} />
      </Header>
      <div className="account-info-description">
        <Text field={props.fields.AccountInformationDescription} />
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          emailAddress: '',
          confirmEmailAddress: '',
          [sharedFormConstants.terms]: false,
        }}
        validate={(values) => {
          setErrorMsg(false);
          const errors = {};
          if (!values.emailAddress) {
            errors.emailAddress = props.fields.EmailAddressError.value;
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
            errors.emailAddress = props.fields.InvalidEmailAddress.value;
          }
          if (!values.confirmEmailAddress) {
            errors.confirmEmailAddress = props.fields.ConfirmEmailAddressError.value;
          } else if (values.emailAddress !== values.confirmEmailAddress) {
            errors.confirmEmailAddress = props.fields.EmailNotMatching.value;
          }
          return errors;
        }}
        validationSchema={Yup.object().shape({
          [sharedFormConstants.terms]: Yup.boolean().oneOf(
            [true],
            props.fields.TermsandConditonsError.value
          ),
        })}
        onSubmit={(values) => {
          //console.log(values);
          liveCheck(values);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="account-info-block">
                <CardBlock>
                  <div className="account-info-textinputs">
                    <TextInput
                      type="text"
                      value={values.emailAddress}
                      name="emailAddress"
                      errors={errors}
                      topPlacementofError={true}
                      touched={touched}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      customerTypeErrorforAccountCreation={
                        errorMsg ? props.fields?.EmailValidationMsg?.value : null
                      }
                      label={props.fields.EmailAddress}
                    />
                    {/* {errorMsg ? (
                      <div className="customer-type-error">
                        <Error title={props.fields?.EmailValidationMsg?.value} />{' '}
                      </div>
                    ) : null} */}
                    <TextInput
                      type="text"
                      value={values.confirmEmailAddress}
                      name="confirmEmailAddress"
                      errors={errors}
                      topPlacementofError={true}
                      touched={touched}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      label={props.fields.ConfirmEmailAddress}
                    />
                  </div>
                  <Note>
                    <RichText field={props.fields.EndNote} />
                  </Note>
                  <div className="account-info-terms-and-conditions">
                    <Placeholder name="presto-terms-and-conditions" rendering={props.rendering} />
                    {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                      <InlineError cssClass="checkbox-error">
                        {errors[sharedFormConstants.terms]}
                      </InlineError>
                    )}
                  </div>
                  <div className="next-button">
                    <Button type="submit">
                      <Text field={props.fields.NextButton} />
                    </Button>
                  </div>
                </CardBlock>
              </div>
            </form>
          );
        }}
      </Formik>
      {/* <div className="account-info-captcha">
<RichText field={props.fields.CaptchaNote} />
</div> */}
    </div>
  );
};

export default B2CCreateAccount;
