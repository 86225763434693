import React, { useState, useEffect } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import routes from '../../constants/routes';
import { redirectToUrl, getShortLanguageCode, getLanguageAtsId } from '../../services/utils';
import api from '../../services/api';
import { useSelector } from 'react-redux';
import { ButtonBlock, Button, Loader } from '../../atoms';
import RetrieveDefaultTrip from './RetrieveDefaultTrip';
import goDefaultTripConstants from '../../constants/goDefaultTrip';
import handleLogOut from '../../services/handleLogOut';
import SetDefaultTrip from './SetDefaultTrip';
import './goDefaultTrip.scss';
import moment from 'moment';
import { selectCard } from '../../redux/user/actions';
import { useDispatch } from 'react-redux';
const DAYS_TO_SUBRACT = 7;
const ITEMS_PER_PAGE = 10;

const SetGoDefaultTrip = (props) => {
    const dispatch = useDispatch();
    const languageCode = getShortLanguageCode();
    const [loading, setLoading] = useState(true);
    const [isDefaultTrip, setIsDefaultTrip] = useState(false);
    const [isCardBlocked, setIsCardBlocked] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
    const [locationId, setLocationId] = useState();
    const [goStation, setGoStation] = useState([]);
    const [isLessTransactionHistory, setIsLessTransactionHistory] = useState(false);
    const userInfoRegisteredUser = useSelector((reduxState) => reduxState.user.selectedCard);
    const userInfoData = useSelector((state) => {
        if (state?.contactless?.userInfoData && state?.contactless?.userInfoData?.CardType) {
            return state.contactless.userInfoData;
        }
    });
    const isContactlessUserSignedIn = useSelector((state) => state.contactless.isContactlessUserSignedIn);

    const getStationList = () => {
        setLoading(true);
        api
            .getStationList()
            .then((response) => {
                if (response.data.Success === true) {
                    setGoStation(
                        response.data.Data.ListOfStops.Stops.map((i) => ({
                            label: i.StopFullName,
                            value: i.StopId
                        }))
                    )
                }
                else {
                    redirectToUrl(routes.technicalDifficulties);
                }
                setLoading(false)
            }).catch(() => {
                redirectToUrl(routes.technicalDifficulties);
                setLoading(false);
            });
    }

    const getDefaultTrip = () => {
        api
            .getDefaultTrip({
                LanguageCode: languageCode === 'en' ? goDefaultTripConstants.english : goDefaultTripConstants.french,
                MediaInstanceId: isContactlessUserSignedIn ? userInfoData?.MediaKeyDetails[0]?.mediaInstanceId : userInfoRegisteredUser?.visibleId.split('|')[0],
                CardType: isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type
            })
            .then((response) => {
                if (response?.data?.Success === true) {
                    if (response.data.DefaultTrips.length > 0) {
                        setLocationId(response.data.DefaultTrips[0]);
                        setIsDefaultTrip(true);
                    }
                    else {
                        if (response.data?.Stops != null) {
                            setGoStation(
                                response.data?.Stops.map((i) => ({
                                    label: i.StopFullName,
                                    value: i.StopId
                                }))
                            );
                        }
                        else {
                            redirectToUrl(routes.technicalDifficulties);
                        }
                    }
                }
                else {
                    redirectToUrl(routes.technicalDifficulties);
                }
                setLoading(false);
            }).catch(() => {
                redirectToUrl(routes.technicalDifficulties);
                setLoading(false);
            });
    }

    const getTransactionHistory = () => {
        api
            .getContactlessTransactionHistory({
                MediaReferenceId: userInfoData?.MediaReferenceId,
                cardType: userInfoData?.CardType,
                StartTime: moment().subtract(DAYS_TO_SUBRACT, 'days').toDate().toISOString(),
                EndTime: moment().toDate().toISOString()
            })
            .then((response) => {
                if (response?.data) {
                    if (response.data?.transactions?.length > 0) {
                        if (checkIsCardBlocked(response.data.transactions[0])) {
                            setIsCardBlocked(true);
                            setLoading(false);
                        }
                        else {
                            getDefaultTrip();
                        }
                    }
                    else {
                        setIsLessTransactionHistory(true);
                        setLoading(false);
                    }
                };
            }).catch(() => {
                redirectToUrl(routes.technicalDifficulties);
                setLoading(false);
            });
    }

    const getTransactionHistoryRegisteredUser = () => {
        api
            .getContactlessTransactionHistory({
                MediaReferenceId: userInfoRegisteredUser?.mediaId,
                CardType: userInfoRegisteredUser.type,
                StartTime: moment().utc().clone().startOf('month').toDate().toISOString(),
                EndTime: moment().utc().toDate().toISOString(),
                Records: ITEMS_PER_PAGE,
                SortDescending: true,
                Language: getLanguageAtsId(),
            })
            .then((response) => {
                if (response?.data?.transactions[0] !== undefined && checkIsCardBlocked(response.data.transactions[0])) {
                    userInfoRegisteredUser.status = 'blocked';
                    dispatch(selectCard(userInfoRegisteredUser));
                    setIsCardBlocked(true);
                    setLoading(false);
                }
                else {
                    getDefaultTrip();
                }
            }).catch(() => {
                redirectToUrl(routes.technicalDifficulties);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!isContactlessUserSignedIn && userInfoRegisteredUser?.status === 'blocked') {
            setIsCardBlocked(true);
            setLoading(false);
        }
        else if (!isContactlessUserSignedIn && userInfoRegisteredUser?.status === undefined) {
            getTransactionHistoryRegisteredUser();
        }
        else {
            isContactlessUserSignedIn ? (
                getTransactionHistory()
            ) : (
                getDefaultTrip()
            )
        }
    }, [])

    const checkIsCardBlocked = (data) => {
        const mediaInfo = data.media.mediaInfo;
        return (mediaInfo.mediaStatus === 'HOTLISTED');
    };

    return [
        <h1 className="go-default-trip-title">
            <Text field={props.fields.heading} />
        </h1>,
        isContactlessUserSignedIn ? (
            isLessTransactionHistory ? (
                <div className='less-transaction'>
                    <RichText field={props.fields.noTransactionHistory} />
                    <ButtonBlock>
                        <ButtonBlock right>
                            <Button type="submit"
                                onClick={() => handleLogOut(dispatch)}
                                buttonTextA11y={props.fields.signoutA11y.value}
                            >
                                <Text field={props.fields.signOut} />
                            </Button>
                        </ButtonBlock>
                    </ButtonBlock>
                </div>
            ) : (
                isCardBlocked ? (
                    <>
                        <div className='go-default-error-block'>
                            <div className='error-block-title'>
                                <Text field={props.fields.blockCardTitle} />
                            </div>
                            <div className='error-blocked'>
                                <RichText field={props.fields.blockCardMessage} />
                            </div>
                        </div>
                        <ButtonBlock>
                            <ButtonBlock right>
                                <Button type="submit"
                                    onClick={() => redirectToUrl(routes.contactlessTransactionHistory)}
                                    buttonTextA11y={props.fields.viewHistoryA11y.value} >
                                    <Text field={props.fields.viewHistory} />
                                </Button>
                            </ButtonBlock>
                        </ButtonBlock>
                    </>
                ) : (
                    isDefaultTrip ? (
                        <div>
                            <RetrieveDefaultTrip
                                key={isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type}
                                props={props}
                                checkError={checkError}
                                setCheckError={setCheckError}
                                apiStatus={apiStatus}
                                setApiStatus={setApiStatus}
                                isDefaultTrip={isDefaultTrip}
                                setIsDefaultTrip={setIsDefaultTrip}
                                userInfoData={userInfoData}
                                isContactlessUserSignedIn={isContactlessUserSignedIn}
                                locationId={locationId}
                                getStationList={getStationList}
                            />
                        </div>
                    ) : (
                        <div>
                            <SetDefaultTrip
                                key={isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type}
                                props={props}
                                checkError={checkError}
                                setCheckError={setCheckError}
                                apiStatus={apiStatus}
                                setApiStatus={setApiStatus}
                                goStation={goStation}
                                userInfoData={userInfoData}
                                isContactlessUserSignedIn={isContactlessUserSignedIn}
                                locationId={locationId}
                            />
                        </div>
                    )
                )
            )
        ) : (
            isCardBlocked ? (
                <>
                    <div className='go-default-error-block'>
                        <div className='error-block-title'>
                            <Text field={props.fields.blockCardTitle} />
                        </div>
                        <div className='error-blocked'>
                            <RichText field={props.fields.blockCardMessage} />
                        </div>
                    </div>
                    <ButtonBlock>
                        <ButtonBlock right>
                            <Button type="submit"
                                onClick={() => redirectToUrl(routes.transactionHistory)}
                                buttonTextA11y={props.fields.viewHistoryA11y.value} >
                                <Text field={props.fields.viewHistory} />
                            </Button>
                        </ButtonBlock>
                    </ButtonBlock>
                </>
            ) : (
                isDefaultTrip ? (
                    <div>
                        <RetrieveDefaultTrip
                            key={isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type}
                            props={props}
                            checkError={checkError}
                            setCheckError={setCheckError}
                            apiStatus={apiStatus}
                            setApiStatus={setApiStatus}
                            isDefaultTrip={isDefaultTrip}
                            setIsDefaultTrip={setIsDefaultTrip}
                            userInfoRegisteredUser={userInfoRegisteredUser}
                            locationId={locationId}
                            getStationList={getStationList}
                        />
                    </div>
                ) : (
                    <div>
                        <SetDefaultTrip
                            key={isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type}
                            props={props}
                            checkError={checkError}
                            setCheckError={setCheckError}
                            apiStatus={apiStatus}
                            setApiStatus={setApiStatus}
                            goStation={goStation}
                            userInfoRegisteredUser={userInfoRegisteredUser}
                            locationId={locationId}
                        />
                    </div>
                )
            )
        ),
        loading ? <Loader /> : null,
    ];
}
export default withTranslation()(SetGoDefaultTrip);