import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { StepTracker } from '../../molecules';
import AddPrestoCard0 from './AddPrestoCard0';
import AddPrestoCard1 from './AddPrestoCard1';
import AddPrestoCard2 from './AddPrestoCard2';
import AddPrestoCard3 from './AddPrestoCard3';
import { getQueryParams, localstorageService } from '../../services/utils';
import '../../assets/scss/app.scss';
import './addPrestoCard.scss';
import { refreshUserDataAfterPrestoCardAdded, setB2cClaimsResponse } from '../../redux/user/actions';
import localStorageKeys from '../../constants/localStorageKeys';
import api from '../../services/api';
import handleServerError from '../../services/handleServerError';

const stepsMap = [AddPrestoCard0, AddPrestoCard1, AddPrestoCard2, AddPrestoCard3];

const AddPrestoCard = (props) => {
  const { t } = props;
  const userInfo = useSelector((s) => s.user);
  const focusDiv = useRef();
  const [step, setStep] = useState(0);
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
  };
  const toFirstStep = () => {
    setStep(0);
    focusDiv.current.focus();
  };
  const toPreviousStep = () => {
    setStep(step - 1);
    focusDiv.current.focus();
  };
  const Stepper = stepsMap[step];
  const [state, setState] = useState({});
  const queryParams = getQueryParams(useLocation().search);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (queryParams.paperForm !== undefined) {
      if (
        !userInfo.fareMedias.filter((fareMedia) => fareMedia.visibleId === queryParams.visibleId)
          .length
      ) {
        return;
      }

      setStep(3);
      setState({
        ...state,
        MediaId: queryParams.visibleId,
      });
    } else if (queryParams.addCardtype !== undefined && queryParams.addCardtype == 'presto') {
      setState({
        ...state,
        MediaId: queryParams.param1,
        CVN: queryParams.param2,
        CustomerId: queryParams.param3,
      });
      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('addCardtype')) {
        queryParams1.delete('addCardtype');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param1')) {
        queryParams1.delete('param1');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param2')) {
        queryParams1.delete('param2');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param3')) {
        queryParams1.delete('param3');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      if (queryParams1.has('param4')) {
        queryParams1.delete('param4');
        history.replace({
          search: queryParams1.toString(),
        });
      }
      api
        .getClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.refreshToken,
              res.data.Data.Access_token
            );
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(refreshUserDataAfterPrestoCardAdded(true));
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate: res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin: String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
            setStep(3);
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
    else if (queryParams.addCardtype !== undefined && queryParams.addCardtype == 'presto') {
      setState({
        ...state,
        MediaId: queryParams.param1,
        CVN: queryParams.param2,
        CustomerId: queryParams.param3,
      });
      const queryParams1 = new URLSearchParams(location.search);
      if (queryParams1.has('addCardtype')) {
        queryParams1.delete('addCardtype')
        history.replace({
          search: queryParams1.toString(),
        })
      }
      if (queryParams1.has('param1')) {
        queryParams1.delete('param1')
        history.replace({
          search: queryParams1.toString(),
        })
      }
      if (queryParams1.has('param2')) {
        queryParams1.delete('param2')
        history.replace({
          search: queryParams1.toString(),
        })
      }
      if (queryParams1.has('param3')) {
        queryParams1.delete('param3')
        history.replace({
          search: queryParams1.toString(),
        })
      }
      if (queryParams1.has('param4')) {
        queryParams1.delete('param4')
        history.replace({
          search: queryParams1.toString(),
        })
      }
      api
        .getClaims()
        .then((res) => {
          //console.log(res);
          if (res) {
            localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
            localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Access_token);
            localstorageService().setItem(
              localStorageKeys.accessTokenLifetime,
              res.data.Data.Expires_in
            );
            dispatch(refreshUserDataAfterPrestoCardAdded());
            dispatch(
              setB2cClaimsResponse({
                IsMfAEnabled: String(res?.data?.Data?.IsMfAEnabled).toLowerCase() === 'true',
                MFANotificationLastViewedDate: res?.data?.Data?.MFANotificationLastViewedDate,
                PasswordNotificationLastViewedDate:
                  res?.data?.Data?.PasswordNotificationLastViewedDate,
                IsFirstTimeLogin: String(res?.data?.Data?.IsFirstTimeLogin).toLowerCase() === 'true',
                PhoneNumber: res?.data?.Data?.PhoneNumber,
              })
            );
            setStep(3);
          }
        })
        .catch((res) => {
          handleServerError(undefined, setError)(res);
        });
    }
  }, []);

  return (
    <div className="add-card-wrapper ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      {step !== 0 ? (
        <StepTracker
          activeIndex={step - 1}
          items={[
            {
              text: <Text field={props.fields.step1Title} />,
              textInProgressA11y: props.fields.step1TitleInProgressA11y.value,
              textCompletedA11y: props.fields.step1TitleCompletedA11y.value,
            },
            {
              text: <Text field={props.fields.step2Title} />,
              textNotCompletedA11y: props.fields.step2TitleNotCompletedA11y.value,
              textInProgressA11y: props.fields.step2TitleInProgressA11y.value,
              textCompletedA11y: props.fields.step2TitleCompletedA11y.value,
            },
            {
              text: <Text field={props.fields.step3Title} />,
              textNotCompletedA11y: props.fields.step3TitleNotCompletedA11y.value,
              textCompletedA11y: props.fields.step3TitleCompletedA11y.value,
            },
          ]}
        />
      ) : null}
      <Stepper
        {...props}
        state={state}
        setState={setState}
        toNextStep={toNextStep}
        toFirstStep={toFirstStep}
        toPreviousStep={toPreviousStep}
      />
    </div>
  );
};

export default withTranslation()(AddPrestoCard);
