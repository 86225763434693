import React, { useEffect } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';

import printIcon from '../../assets/images/printer.svg';
import failIcon from '../../assets/images/Failed.svg';
import routes from '../../constants/routes';
import { Button, IconButton } from '../../atoms';
import { CardBlock, Note, Success } from '../../molecules';
import './redeemVoucherStepperBlock.scss';
import { setRedeemVoucherStepperBlock } from '../../redux/products/actions';
import {
  redirectToUrl,
  getTimePeriod,
  formatDateTx,
  formatDecimalWithCurrency,
  isFrench,
} from '../../services/utils';

const RedeemVoucherStepperBlock3 = ({
  fields,
  toNextStep,
  toFirstStep,
  setState,
  state,
  t,
  userInfo,
  product,
  rendering,
}) => {
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const program = redeemStepper?.apiData?.VoucherProducts?.program;
  const include = program?.ProgramProducts.filter(
    (ProgramProduct) =>
      ProgramProduct.ProductType === 1 ||
      ProgramProduct.ProductType === 4 ||
      ProgramProduct.ProductType === 5
  );
  const ProductTypeID_5 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 5
  );
  const ProductTypeID_1 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 1
  );
  const ProductTypeID_4 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 4
  );
  const ProductTypeID_3 = program?.ProgramProducts.filter(
    (ProgramProduct) => ProgramProduct.ProductType === 3
  );
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.mediaId === reduxState.user.selectedCard.mediaId)
  );
  const logo = useSelector((state) => state.products.logos);
  const dispatch = useDispatch();
  // const vn = { value: <RichText field={fields.VoucherSuccessNote} /> };
  const voucherNote = !state?.ReedeemVoucherResponse?.OrderNumber
    ? fields?.ConcessionVoucherSuccessNote?.value?.toString()
    : fields?.VoucherSuccessNote?.value?.toString();
  const voucherNote1 = () => {
    // const note1 = voucherNote[0]?.toString();
    const note2 = redeemStepper?.voucherCode?.toString();
    // const note3 = voucherNote[1]?.toString();
    const note4 = fareMedia?.nickName?.toString();
    const note5 = fareMedia?.visibleId?.toString();
    // const note6 = voucherNote[2]?.toString();
    const note7 = state?.ReedeemVoucherResponse?.OrderNumber?.toString();
    // const note8 = voucherNote[3]?.toString();

    const voucher1 = voucherNote?.replace('{0}', redeemStepper?.voucherCode?.toString());
    const voucher2 = voucher1?.replace('{1}', `${note4} (${note5})`);
    const voucher3 = !state?.ReedeemVoucherResponse?.OrderNumber
      ? voucher2
      : voucher2?.replace('{2}', `${note7}`);

    const voucherSuccessNote = {
      value: voucher3,
    };

    return (
      <div className="redeemvoucher-success-content">
        <RichText field={voucherSuccessNote} />
        {/* <b>{note1}</b>
        <b>{note2}</b>
        {note3}
        <b>{note4}</b>
        {'('}
        <b>{note5}</b>
        {')'}
        {note6}
        <b>{note7}</b>
        {note8} */}
      </div>
    );
  };

  const errorContent = fields.RedeemVoucherError.value.toString().split('{0}');
  const errorContent1 = `${errorContent[0]} (${redeemStepper.voucherCode}) ${errorContent[1]}`;

  const errorScreen = () => {
    return (
      <>
        <div className="error-card-block">
          <CardBlock>
            <div className="Error-block-redeem">
              <div className="error-note-redeem">
                <div className="redeem-error">
                  <img
                    //src="https://contactless.prestocard.prestopreprod.net/~/media/open%20payment/assets/icons/failed.ashx"
                    src={failIcon}
                    alt="cross"
                    className="warning-titleImg activate"
                  />
                  <div className="error-occured">
                    <Text field={fields.ErrorOccurred} />
                  </div>
                </div>
                <div className="redeem-error-content">{errorContent1.toString()}</div>
              </div>
            </div>
          </CardBlock>
        </div>
        <div className="print-screen-error">
          <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
            {fields.PrintText.value}
          </IconButton>
          <div>
            <Button
              buttonTextA11y={fields.GotoDashboardTextA11y.value}
              onClick={() => redirectToUrl(routes.dashboard)}
            >
              <Text field={fields.GotoDashboardText} />
            </Button>
          </div>
        </div>
      </>
    );
  };
  const getItem = (text1, text2) => (
    <div className="item-wrapper">
      <div className="item">{text1}</div>
      <div className="item">
        <b>{text2}</b>
      </div>
    </div>
  );

  const getItemWithImage = (text1, text2, image, ProgramProduct) => (
    <div className="item-image-wrapper">
      <div className="item-image">
        <img alt={logo[image]?.Logo?.Alt} src={logo[image]?.Logo?.Src} />
      </div>
      <div className="item-wrapper">
        <div className="item">
          <b>{text1}</b>
          <br />
          <div
            className="agencyOption-date"
            aria-label={getTimePeriod(
              ProgramProduct?.PeriodPassStartDate,
              ProgramProduct?.PeriodPassEndDate
            )}
          >
            {getTimePeriod(ProgramProduct?.PeriodPassStartDate, ProgramProduct?.PeriodPassEndDate)}
          </div>
        </div>
        <div className="item">
          <b>{text2}</b>
        </div>
      </div>
    </div>
  );

  const checkoutButton = () => {
    dispatch(
      setRedeemVoucherStepperBlock({
        redeemVoucherStepperBlock: false,
        redeemVoucherBlock: true,
        voucherCode: '',
      })
    );
    redirectToUrl(routes.checkout);
  };

  const GoToDashBoardHandler = () => {
    dispatch(
      setRedeemVoucherStepperBlock({
        redeemVoucherStepperBlock: false,
        redeemVoucherBlock: true,
        voucherCode: '',
      })
    );
    redirectToUrl(routes.dashboard);
  };

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  return state?.Sucess ? (
    <>
      <h1 className="redeemvoucher-header">
        <Text field={fields.RedeemVoucherMainTitle} />
      </h1>
      <div className="redeemvoucher-whole-wrapper">
        <div className="redeemvoucher-wrapper">
          <div className="redeemvoucher-tab block3">
            {/* {state.ReedeemVoucherResponse.OrderNumber} */}
            <div className="redeem-success">
              <Success
                title={{ value: fields.RedeemSuccessNote.value }}
                Content={() => voucherNote1()}
              />
            </div>
          </div>
        </div>
        {include.length > 0 && (
          <div className="redeemvoucher-wrapper">
            <div className="redeemvoucher-tab note-step3">
              <CardBlock title={fields.TapText.value}>
                <Note withIcon>
                  <RichText field={fields.ImportantNoteSection} />
                </Note>
                {ProductTypeID_5?.map((ProgramProduct) => {
                  if (ProgramProduct.ProductType === 5) {
                    let productName = '';
                    if (isFrench()) {
                      if (
                        fields?.FareTypeStudent ===
                        ProgramProduct?.Concession?.ConcessionShortName_FR
                      ) {
                        productName = fields?.FareTypeYouth;
                      } else {
                        productName = ProgramProduct?.Concession?.ConcessionShortName_FR;
                      }
                    } else if (
                      fields?.FareTypeStudent === ProgramProduct?.Concession?.ConcessionShortName_EN
                    ) {
                      productName = fields?.FareTypeYouth;
                    } else {
                      productName = ProgramProduct?.Concession?.ConcessionShortName_EN;
                    }
                    return [
                      getItem(fields.FareTypeUpdate.value, productName?.toString()),
                      ProgramProduct?.ConcessionEndDate !== null &&
                        ProgramProduct?.Concession?.IsEndDateRequired
                        ? getItem(
                          fields.ExpiryTitle.value,
                          formatDateTx(ProgramProduct?.ConcessionEndDate)
                        )
                        : null,
                    ];
                  }
                })}

                {ProductTypeID_1?.map((ProgramProduct) => {
                  return getItem(
                    fields.VouchercreditTitle.value,
                    '$' + ProgramProduct?.ProductValue?.toString()
                  );
                })}

                {ProductTypeID_4?.map((ProgramProduct) => {
                  let productName = '';
                  if (isFrench()) {
                    productName = ProgramProduct?.ProductNameFR;
                  } else {
                    productName = ProgramProduct?.ProductNameEN;
                  }
                  return getItemWithImage(
                    productName ? productName : ProgramProduct?.ProductName,
                    fields?.PassFare?.value?.toString(),
                    ProgramProduct?.ProductOwner,
                    ProgramProduct
                  );
                })}
              </CardBlock>
            </div>
          </div>
        )}
        <div>
          {ProductTypeID_3.length > 0 && (
            <div className="redeemvoucher-wrapper">
              <div className="redeemvoucher-tab note-step3 step3">
                <CardBlock title={fields.NextStepTitle.value}>
                  <Text field={fields.NextStepCheckoutNote} />
                  <Note withIcon>
                    <RichText field={fields.NextStepNote} />
                  </Note>

                  <div className="item-price">
                    <div className="item-note">
                      <Text field={fields.ItemNote} />
                    </div>
                    <div className="price-note">
                      <Text field={fields.PriceNote} />
                    </div>
                  </div>

                  <div className="item-price-discounted">
                    {ProductTypeID_3.map((ProgramProduct) => {
                      let productName = '';
                      if (isFrench()) {
                        productName = ProgramProduct.ProductNameFR;
                      } else {
                        productName = ProgramProduct.ProductNameEN;
                      }
                      return getItemWithImage(
                        productName ? productName : ProgramProduct?.ProductName,
                        formatDecimalWithCurrency(ProgramProduct?.ProductValue?.toString()),
                        ProgramProduct?.ProductOwner,
                        ProgramProduct
                      );
                    })}
                  </div>
                </CardBlock>
              </div>
            </div>
          )}
          <div className="redeemvoucher-wrapper">
            <div className="redeem-footer">
              <div className="redeem-confirm-button">
                <div className="confirmation-buttons">
                  {ProductTypeID_3.length > 0 && (
                    <div className="checkout-btn">
                      <Button
                        onClick={() => checkoutButton()}
                        buttonTextA11y={fields.CheckoutTextA11y.value}
                      >
                        <Text field={fields.CheckoutText} />
                      </Button>
                    </div>
                  )}
                  <Button
                    firstOrder
                    white
                    buttonTextA11y={fields.GotoDashboardTextA11y.value}
                    onClick={() => GoToDashBoardHandler()}
                  >
                    <Text field={fields.GotoDashboardText} />
                  </Button>
                </div>
              </div>
              <div className="redeem-print">
                <IconButton onClick={() => window.print()} icon={printIcon} tabIndex="0">
                  {fields.PrintText.value}
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    errorScreen()
  );
};
export default RedeemVoucherStepperBlock3;
