import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import './contactlessLeftNavigationFAQ.scss';

const ContactlessLeftNavigationFAQ = (props) => {
  const { datasource } = props.fields && props.fields.data ? props.fields.data : { children: [] };
  return (
    <div className="contactless-trans-faq-nav">
      <div className="side-menu" role="navigation">
        {datasource && (
          <>
            <h4>
              <Text field={datasource.Title} />
            </h4>
            <div className="side-menu-items">
              <ul>
                {datasource.children.map((columns) => (
                  <li>
                    <a href={columns.Link?.jss?.value?.href}>{columns.Link?.jss?.value?.text}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sidenav-footer-link">
              <a href={datasource?.SeeAllLink?.jss?.value?.href}>
                {datasource?.SeeAllLink?.jss?.value?.text}
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactlessLeftNavigationFAQ;
