import React from 'react';
import { withTranslation } from 'react-i18next';
import './heading.scss';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const Heading = (props, fields) => {
  const redeemStepper = useSelector((reduxState) => reduxState.products.redeemVoucher);
  const divid = props.fields && props.fields.ID?.value ? props.fields.ID.value : 'rlc-card';
  const idname = divid === 'rlc' || divid === 'rlc-card' ? 'rlc-card' : props.fields.ID.value;

  const getHeading = (tag) => {
    if (tag === 'h2') {
      return 'h2';
    } else if (tag === 'h3') {
      return 'h3';
    } else {
      return 'h1';
    }
  };
  const authenticated = useSelector((state) => state.user.authenticated);
  const anonymous = useSelector((state) => state.user.anonymous);
  return redeemStepper?.redeemVoucherStepperBlock || (authenticated && !anonymous && divid === 'rlc') ? null : (
    <div id={idname}>
      {/* <h1
        className={`headline ${
          props?.fields?.CssClass && props.fields.CssClass.value
            ? props.fields.CssClass.value
            : 'bodyText__headline'
        }`}
      > */}
      <span>
        <Text
          tag={getHeading(props.fields?.SubHeadingTag?.fields?.HeadingTagTitle?.value)}
          className={`headline ${props?.fields?.CssClass && props.fields.CssClass.value
            ? props.fields.CssClass.value
            : 'bodyText__headline'
            }`}
          field={props?.fields?.Title}
        />
      </span>
    </div>
  );
};

export default withTranslation()(Heading);
