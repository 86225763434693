/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { showScreenHeader } from '../../redux/user/actions';
import { Loader, AutoloadGridItem, ButtonBlock, Button } from '../../atoms';
import { Note, CardBlock } from '../../molecules';
import { formatDecimalWithCurrency, getQueryParams } from '../../services/utils';

import '../Autoload/autoload.scss';
import '../Autoload/autoloadPayment.scss';
import '../Autoload/autoloadSuccess.scss';

const ManageAutoload = ({
  fields,
  product,
  rendering,
  backToMyCardButtonLink,
  displayActivationNote,
  displayCancellationNote,
}) => {
  const [isUpdateClicked, showUpdate] = useState(false);
  const [isCancelClicked, showCancel] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  let content;
  content = <Loader />;
  if (displayCancellationNote || product !== null) {
    content = (
      <>
        <div className="autoload-tab">
          <CardBlock
            title={
              displayActivationNote
                ? fields.activationHeading.value
                : displayCancellationNote
                ? fields.cancelHeading.value
                : fields.heading.value
            }
            titleA11y={
              displayActivationNote
                ? fields.activationHeadingA11y.value
                : displayCancellationNote
                ? fields.cancelHeadingA11y.value
                : fields.headingA11y.value
            }
          >
            {displayActivationNote ? (
              <Note
                withIcon
                Title={() => (
                  <span>
                    <RichText field={fields.activationNote} />
                  </span>
                )}
                titleA11y={fields.activationNote.value}
              />
            ) : null}
            {displayCancellationNote ? (
              <Note
                withIcon
                Title={() => (
                  <span>
                    <RichText field={fields.cancelNote} />
                  </span>
                )}
                titleA11y={fields.cancelNote.value}
              />
            ) : null}
            {displayCancellationNote ? null : (
              <AutoloadGridItem
                leftLabel={fields.loadLabel.value}
                leftAmount={formatDecimalWithCurrency(product.Amount)}
                rightLabel={fields.dropLabel.value}
                rightAmount={formatDecimalWithCurrency(product.Threshold)}
              />
            )}
          </CardBlock>

          {displayCancellationNote ? null : (
            <ButtonBlock>
              <ButtonBlock right>
                <Button
                  type="button"
                  firstOrder
                  white
                  buttonTextA11y={fields.cancelButtonTextA11y.value}
                  onClick={() => {
                    dispatch(showScreenHeader(false));
                    showCancel(!isCancelClicked);
                  }}
                >
                  <Text field={fields.cancelButtonText} />
                </Button>
                <Button
                  type="button"
                  buttonTextA11y={fields.updateButtonTextA11y.value}
                  onClick={() => {
                    dispatch(showScreenHeader(false));
                    showUpdate(!isUpdateClicked);
                  }}
                >
                  <Text field={fields.updateButtonText} />
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          )}
        </div>
        <div className="learn-note">
          <Placeholder name="presto-autoload-dds" rendering={rendering} />
        </div>
      </>
    );
  }
  if (isCancelClicked) {
    content = (
      <Placeholder
        name="presto-load-my-card-cancel-autoload"
        rendering={rendering}
        product={product}
        backToMyCardButtonLink={backToMyCardButtonLink}
      />
    );
  }
  if (
    isUpdateClicked ||
    queryParams.action === 'update' ||
    queryParams.error !== undefined ||
    queryParams.cancel !== undefined
  ) {
    content = (
      <Placeholder
        name="presto-load-my-card-modify-autoload"
        rendering={rendering}
        product={product}
        backToMyCardButtonLink={backToMyCardButtonLink}
      />
    );
  }

  return (
    <div className="loadMyCard-wrapper">
      {content}
      {queryParams.action !== 'update' ||
        !isCancelClicked ||
        !isUpdateClicked(
          <div className="learn-note">
            <Placeholder name="presto-autoload-dds" rendering={rendering} />
          </div>
        )}
    </div>
  );
};

export default withTranslation()(ManageAutoload);
