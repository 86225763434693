import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { redirectToUrl } from '../../services/utils';
import { CardBlock, TextInput, InlineButtonBlock } from '../../molecules';

const AddContactlessCard1 = (props) => {
  const email = useSelector((reduxState) => reduxState.user.loginEmail);
  const { fields, state, setState, toNextStep } = props;

  return (
    <CardBlock title={fields.verifyEmailTitle.value} titleA11y={fields.verifyEmailTitle.value}>
      <div aria-label={fields.addEmailNextStepsNoticeDescriptionA11y.value}>
        <RichText field={fields.addEmailNextStepsNoticeDescription} />
      </div>
      <Formik
        initialValues={{
          Email: email,
        }}
        onSubmit={(values) => {
          setState({
            ...state,
            Email: values.Email,
          });
          toNextStep();
        }}
      >
        {({ errors, touched }) => {
          return (
            <form>
              <div className="addCard-formRow">
                <div className="addCard-formControl">
                  <TextInput
                    type="email"
                    name="Email"
                    id="email"
                    errors={errors}
                    touched={touched}
                    label={fields.emailAddressLabel}
                    disabled
                  />
                </div>
              </div>
              <InlineButtonBlock
                buttonText={fields.verifyButtonText}
                buttonTextA11y={fields.verifyButtonTextA11y.value}
                onClick={() => redirectToUrl(fields.linkToEmailVerification.value?.href)}
              />
            </form>
          );
        }}
      </Formik>
    </CardBlock>
  );
};

export default AddContactlessCard1;
