import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonBlock, InlineError, Loader } from '../../../atoms';
import sharedFormConstants from '../../../constants/sharedFormConstants';
import { TextInput } from '../../../molecules';
import {
  setHotlistApiSuccess,
  setNewCardDetails,
  setDeactivateCardType,
} from '../../../redux/user/actions';
import api from '../../../services/api';
import { withTranslation } from 'react-i18next';
import './DeactivateAndTransfer1.scss';

const DIGIT_REGEX = /^[0-9]*$/;

const DeactivateAndTransfer1 = ({
  toNextStep,
  userInfo,
  toPreviousStep,
  rendering,
  t,
  fields,
  isTransferBalance,
  callRefreshToken,
}) => {
  const customerId = userInfo.customerId;
  const visibleId = userInfo?.selectedCard?.visibleId;
  const mediaId = userInfo?.selectedCard?.mediaId;
  const oldVisibleId = userInfo?.selectedCard?.visibleId;
  const [fareMedias, setFareMedias] = useState('');
  const [oldCvn, setOldCvn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setIsLoading(true);
    api
      .getUserInfo()
      .then((response) => {
        setIsLoading(false);
        if (response?.Success) {
          setFareMedias(response?.data?.Data?.Customer?.FareMedias);
        } else {
          //console.log('error');
        }
      })
      .catch(() => {
        setIsLoading(false);
        //console.log('error');
      });
  }, []);

  useEffect(() => {
    const oldCvn =
      fareMedias &&
      fareMedias.length > 0 &&
      fareMedias.find((data) => {
        return data.VisibleId == oldVisibleId;
      });
    setOldCvn(oldCvn?.VerificationNumber);
  }, [fareMedias]);

  const handleSubmitData = (data) => {
    setIsLoading(true);
    dispatch(setNewCardDetails(data));
    let payload = {
      CustomerId: customerId,
      NewCVN: data.cardVerificationNumber,
      NewVisibleId: data.newCardNumber,
      IsStubEnabled: 'true',
      IsErrorFlowEnabled: 'false',
      OldMediaId: mediaId,
      OldVisibleId: visibleId,
      OldCVN: oldCvn,
      NewMediaId: null,
      IsTransferBalance: isTransferBalance,
      IsAnonymousFlow: true
    };
    // if (isTransferBalance) {
    //   payload = {
    //     CustomerId: customerId,
    //     CVN: data.cardVerificationNumber,
    //     NewVisibleId: data.newCardNumber,
    //     IsStubEnabled: 'true',
    //     IsErrorFlowEnabled: 'false',
    //     OldMediaId: mediaId,
    //     OldVisibleId: visibleId,
    //     IsTransferBalance: true,
    //   };
    // }
    api
      .createHotlistAndTransferCard(payload)
      .then((response) => {
        dispatch(setHotlistApiSuccess(response.data));
        if (response.data.Success) {
          setIsLoading(false);
          callRefreshToken(() => {
            toNextStep();
          });
        } else {
          //console.log('error');
          toNextStep();
        }
      })
      .catch((error) => {
        api.UpdateFareMediaSession({ VisibleId: visibleId }).then(({ data }) => { });
        dispatch(setHotlistApiSuccess({ Success: false }));
        //console.log('error', error);
        setIsLoading(false);
        toNextStep();
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="transfer-card-with-Button">
        <Formik
          initialValues={{
            newCardNumber: '',
            cardVerificationNumber: '',
            [sharedFormConstants.terms]: false,
            terms: false,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.newCardNumber) {
              errors.newCardNumber = fields.CardNumberMissing.value;
            } else if (!DIGIT_REGEX.test(values.newCardNumber)) {
              errors.newCardNumber = fields.CardNumberInputError.value;
            } else if (values.newCardNumber.length !== 17) {
              errors.newCardNumber = fields.CardNumberIncorrectDigits.value;
            }
            if (!values.cardVerificationNumber) {
              errors.cardVerificationNumber = fields.CVNError.value;
            } else if (!DIGIT_REGEX.test(values.cardVerificationNumber)) {
              errors.cardVerificationNumber = fields.CVNInvalid.value;
            } else if (values.cardVerificationNumber.length !== 3) {
              errors.cardVerificationNumber = fields.CVNInvalid.value;
            }
            if (!values.terms) {
              errors[sharedFormConstants.terms] = t('termsAndConditionsRequired');
            }
            return errors;
          }}
          onSubmit={(values) => {
            handleSubmitData(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="transfer-card-Form">
                <div className="transfer-card-header">
                  <Text field={fields.NewCardDetailTitle} />
                </div>
                <div className="transfer-input-block">
                  <div className="presto-number">
                    <div className="input-block-label">
                      <RichText field={fields.NewPrestoCardLabel} />
                    </div>
                    <div className="input-block-field">
                      <TextInput
                        type="text"
                        name="newCardNumber"
                        onChange={handleChange}
                        value={values.newCardNumber}
                        errors={errors}
                        touched={touched}
                        inputA11y={fields.NewPrestoCardLabel.value}
                      />
                      {errors.newCardNumber && touched.newCardNumber}
                      <RichText className="input-lavel" field={fields.NewPrestoCardSubTag} />
                    </div>
                  </div>
                  <div className="verification-number">
                    <div className="input-block-label">
                      <RichText field={fields.CardVerificationNumber} />
                    </div>
                    <div className="input-block-field">
                      <TextInput
                        type="text"
                        name="cardVerificationNumber"
                        onChange={handleChange}
                        value={values.cardVerificationNumber}
                        errors={errors}
                        touched={touched}
                        inputA11y={fields.CardVerificationNumber.value}
                      />
                      {errors.cardVerificationNumber && touched.cardVerificationNumber}
                    </div>
                  </div>
                </div>
                <div className="checkout-terms-block">
                  <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                  {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                    <InlineError cssClass="checkbox-error">
                      {errors[sharedFormConstants.terms]}
                    </InlineError>
                  )}
                </div>
              </div>
              <ButtonBlock className="transfer-Button">
                <div>
                  <Button type="submit">
                    <Text field={fields.ConfirmCTALabel} />
                  </Button>
                </div>
                {isTransferBalance ? (
                  <div>
                    <Button onClick={() => dispatch(setDeactivateCardType(''))}>
                      <Text field={fields.GoBackCTALabel} />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button onClick={() => toPreviousStep()}>
                      <Text field={fields.GoBackCTALabel} />
                    </Button>
                  </div>
                )}
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withTranslation()(DeactivateAndTransfer1);
