import React, { useState, useRef, useEffect } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { ButtonBlock, Button, Loader } from '../../atoms';
import { Select } from '../../molecules';
import routes from '../../constants/routes';
import api from '../../services/api';
import { redirectToUrl } from '../../services/utils';
import goDefaultTripConstants from '../../constants/goDefaultTrip';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GOTransitAgencyImg from '../../assets/images/GO.svg';
import './goDefaultTrip.scss';

export default function SetDefaultTrip({
  props,
  checkError,
  apiStatus,
  setApiStatus,
  goStation,
  userInfoRegisteredUser,
  userInfoData,
  isContactlessUserSignedIn,
  locationId,
}) {
  const [loading, setLoading] = useState(false);
  const focusDiv = useRef();
  const focusElement = useRef();

  const setOrUpdateDefaultTrip = (values) => {
    setLoading(true);
    api
      .setOrUpdateDefaultTrip({
        CardType: isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type,
        FromLocationId: parseInt(values.SelectStation1),
        ToLocationId: parseInt(values.SelectStation2),
        ActionType: goDefaultTripConstants.setDefaultTrip,
        MediaInstanceId: isContactlessUserSignedIn
          ? userInfoData?.MediaKeyDetails[0]?.mediaInstanceId
          : userInfoRegisteredUser?.visibleId.split('|')[0],
      })
      .then((response) => {
        if (response.data.Success === true) {
          setApiStatus({ submit: true, state: 'SUCCESS' });
        } else {
          // setApiStatus({ submit: true, state: 'ERROR' });
          // setCheckError(true)
          redirectToUrl(routes.technicalDifficulties);
        }
        setLoading(false);
      })
      .catch(() => {
        redirectToUrl(routes.technicalDifficulties);
        setLoading(false);
      });
  };
  useEffect(() => {
    focusElement.current.focus();
    document.body.scrollTop = 0;
  }, []);
  useEffect(() => {
    if (apiStatus.submit) {
      focusDiv.current.focus();
      document.body.scrollTop = 0;
    }
  }, [apiStatus]);

  const showMessage = () => {
    if (checkError) {
      return (
        <>
          <div className="go-default-error-block">
            <div className="error-block-title">
              {/* To Do: Map error message if not routing to Technical Difficulties page */}
            </div>
          </div>
          <ButtonBlock>
            <ButtonBlock right>
              <Button
                type="submit"
                onClick={() => redirectToUrl(routes.goDefaultTrip)}
                buttonTextA11y={props.fields.returnA11y.value}
              >
                <Text field={props.fields.return} />
              </Button>
            </ButtonBlock>
          </ButtonBlock>
        </>
      );
    } else {
      return (
        <div clasName="set-trip-step2-container">
          <div className="set-trip-step2">
            <div className="success-message" role="alert" tabIndex="-1" ref={focusDiv}>
              <div id="message">
                <Text field={props.fields.successTitle} />
              </div>
            </div>
            <div className="success-description">
              <Text field={props.fields.successText} />
            </div>
            <div className="success-sub-description">
              <RichText field={props.fields.successTextMessage} />
            </div>
          </div>
          <div id="manage-button">
            <ButtonBlock>
              <ButtonBlock right>
                <Button
                  onClick={() =>
                    userInfoRegisteredUser
                      ? redirectToUrl(routes.transactionHistory)
                      : redirectToUrl(routes.contactlessTransactionHistory)
                  }
                  buttonTextA11y={props.fields.returnA11y.value}
                >
                  <Text field={props.fields.return} />
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </div>
        </div>
      );
    }
  };

  return [
    !apiStatus.submit ? (
      <>
        <div className="trip-message">
          <RichText field={props.fields.tripMessage} />
        </div>
        <div className="set-block-container">
          <div className="station-title">
            <div className="icon">
              <img src={GOTransitAgencyImg} alt="" />
            </div>
            <h2 tabindex={-1} ref={focusElement} className="station-sub-title">
              <Text field={props.fields.selectionText} />
            </h2>
          </div>
          <Formik
            initialValues={{
              SelectStation1: locationId ? locationId?.FromLocationId.toString() : '',
              SelectStation2: locationId ? locationId?.ToLocationId.toString() : '',
            }}
            validateOnChange={true}
            validationSchema={Yup.object().shape({
              SelectStation1: Yup.string().required(props.fields.errorMessage?.value),
              SelectStation2: Yup.string().required(props.fields.errorMessage?.value),
            })}
            onSubmit={(values, { setFieldError }) => {
              let fromStation = locationId?.FromLocationId.toString();
              let toStation = locationId?.ToLocationId.toString();
              if (values.SelectStation1 === fromStation && values.SelectStation2 === toStation) {
                setFieldError('SelectStation1', props.fields.changeDropDownMessage?.value);
                setFieldError('SelectStation2', props.fields.changeDropDownMessage?.value);
              } else {
                setOrUpdateDefaultTrip(values);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form">
                    <div className="station">
                      <div className="select-station1">
                        <Select
                          label={props.fields.station1?.value}
                          labelA11y={props.fields.station1A11y?.value}
                          name="SelectStation1"
                          placeholder={props.fields.selectStation?.value}
                          errors={errors}
                          touched={touched}
                          options={goStation.filter((i) => i.value !== values.SelectStation2)}
                          defaultValue={values.SelectStation1}
                          defaultLabel={
                            goStation &&
                            goStation.length > 0 &&
                            goStation
                              .filter((e) => {
                                return e.value === values.SelectStation1;
                              })
                              .map((val) => {
                                return val.label;
                              })
                          }
                          fullWidth
                          showPlaceholder
                        />
                      </div>
                      <div className="select-station2">
                        <Select
                          label={props.fields.station2?.value}
                          labelA11y={props.fields.station2A11y?.value}
                          name="SelectStation2"
                          placeholder={props.fields.selectStation?.value}
                          errors={errors}
                          touched={touched}
                          options={goStation.filter((i) => i.value !== values.SelectStation1)}
                          defaultValue={values.SelectStation2}
                          defaultLabel={
                            goStation &&
                            goStation.length > 0 &&
                            goStation
                              .filter((e) => {
                                return e.value === values.SelectStation2;
                              })
                              .map((val) => {
                                return val.label;
                              })
                          }
                          fullWidth
                          showPlaceholder
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn">
                    <ButtonBlock>
                      <ButtonBlock right>
                        <Button
                          type="submit"
                          buttonTextA11y={props.fields.setDefaultTripA11y.value}
                        >
                          <Text field={props.fields.setDefaultTrip} />
                        </Button>
                      </ButtonBlock>
                    </ButtonBlock>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    ) : (
      showMessage()
    ),
    loading ? <Loader /> : null,
  ];
}
