import React from 'react';
import './note.scss';

export default function Note({
  children,
  Title,
  cssClass,
  withIcon,
  noteA11y,
  titleA11y,
  tabIndex = '0',
}) {
  return (
    <div
      aria-label={titleA11y}
      className={`note ${cssClass ? cssClass : ''} ${withIcon ? 'withIcon' : ''}`}
    >
      {Title ? <Title /> : null}
      <span aria-label={noteA11y}>{children}</span>
    </div>
  );
}
