import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { PrestoLink } from '../../atoms';
import './transitAgencyList.scss';

const TransitAgencyList = (props) => {
  const { datasource, contextItem } = props.fields && props.fields.data ? props.fields.data : {};
  const [transitClass, setTransitClass] = useState(false);
  useEffect(() => {
    if (datasource.Cssclass.jss.value === 'Transit-class') {
      setTransitClass(true);
    }
  }, [datasource]);
  return (
    <div className={`${transitClass ? 'transit-agency-list-main-wrapper' : ''}`}>
      <div className={datasource.Cssclass.jss.value ? datasource.Cssclass.jss.value : ''}>
        <div className="transit-agency-list-wrapper">
          {datasource.Title.jss.value && <h2>{datasource.Title.jss.value}</h2>}
          {datasource.children.map((child) => {
            return (
              <div className="transit-list">
                <Image field={child.TransitIcon?.jss} loading="lazy"/>
                <div className="title-description">
                  {child.Title?.jss.value && (
                    <div className="title">
                      <h3>{child.Title?.jss.value}</h3>
                    </div>
                  )}
                  {child.Description?.jss.value && (
                    <div className="description">
                      <RichText field={child.Description?.jss} />
                      <br />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(TransitAgencyList);
