import React, { useState, useEffect, useRef } from 'react';

import { Placeholder, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useLocation } from 'react-router-dom';

import { Button, ButtonBlock, Loader } from '../../atoms';
import signInFormFields from '../../constants/signInFormFields';
import api from '../../services/api';
import routes from '../../constants/routes';
import localStorageKeys from '../../constants/localStorageKeys';
import {
  localstorageService,
  getQueryParams,
  redirectToUrl,
  resetAccessTokenValidity,
} from '../../services/utils';
import handleServerError from '../../services/handleServerError';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleLogOut from '../../services/handleLogOut';
import {
  changeUserStatus,
  itemsAreLoading,
  fetchAllData,
  fetchAllDataSuccessfullyLoaded,
  anonymousSaveAccessToken,
  fetchAvailableSettings,
  setWeakAccount,
} from '../../redux/user/actions';
import { Error, TextInput, AdvancedModal, MediaImageList } from '../../molecules';
import '../RegisteredAccount/registeredAccount.scss';
import './prestocardsignin.scss';

const PrestocardSignin = (props) => {
  const [error, setError] = useState('');
  const [state, setState] = useState('');
  const [errorTitle] = useState(props.t('registeredAccountSignInTitleErrorMessage'));
  const dispatch = useDispatch();
  const [setIsContentState] = useState(false);
  let usernameInputRef = useRef();

  const userInfo = useSelector((s) => s.user);
  const { fetchAllDataSuccess } = userInfo;

  const queryParams = getQueryParams(useLocation().search);
  const returnUrl = queryParams.returnUrl;

  const [settings, setSettings] = useState([]);
  const [startedLogin, setStartedLogin] = useState(false);

  function redirectAfterLogin() {
    const redirectUrl = returnUrl ? returnUrl : routes.dashboard;
    redirectToUrl(redirectUrl);
  }

  useEffect(() => {
    if (error) {
      usernameInputRef.focus();
    }
  }, [error]);

  const handleSignin = (values) => {
    setError('');
    dispatch(itemsAreLoading(true));
    dispatch(fetchAllDataSuccessfullyLoaded(undefined));
    api
      .anonymousPrestoLogin({
        Username: values[signInFormFields.prestoCardNumber],
        VerificationNumber: values[signInFormFields.verificationNumber],
      })
      .then((res) => {
        if (res.data.Success) {
          setStartedLogin(true);
          localstorageService().setItem(localStorageKeys.accessToken, res.data.Data.Access_token);
          localstorageService().setItem(localStorageKeys.refreshToken, res.data.Data.Refresh_token);
          localstorageService().setItem(
            localStorageKeys.accessTokenLifetime,
            res.data.Data.Expires_in
          );
          resetAccessTokenValidity();
          dispatch(changeUserStatus(false, true));
          dispatch(anonymousSaveAccessToken(res.data.Data.Access_token));
          dispatch(fetchAllData());
        } else {
          dispatch(itemsAreLoading(false));
          const data = JSON.parse(res?.data?.Message);

          if (data.Code === 'AFMS-EX-0010' || data.Code === 'AFMS-DAX-FE-0007') {
            setError(props.fields.CWSAP4.value);
          }
          else if (data.Code === 'AFMS-CRT-FE-0013') {
            setError(props.fields.CWSAP7.value);
          }
          else if (data.Code === 'AFMS-DAX-FE-0041') {
            setError(props.fields.CWSAP9.value);
          }
          else if (data.Code === 'AFMS-CRT-MA-0010') {
            setError(props.fields.CWSAP3.value);
          }
          else if (data.Code === 'AFMS-CRT-FE-0050') {
            setError(props.fields.CWSAP8.value);
          }
          else if (data.Code === 'AFMS-DAX-FE-0001') {
            setError(props.fields.Verifyerrormessage.value);
          }
          else {
            setError(data?.Description);
          }

        }
      })
      .catch((res) => {
        dispatch(itemsAreLoading(false));
        const data = JSON.parse(res.response.data.Message);
        const data1 = JSON.parse(data.Message);
        if (data1.Code === 'AFMS-EX-0010' || data1.Code === 'AFMS-DAX-FE-0007') {
          setError(props.fields.CWSAP4.value);
        }
        if (data1.Code === 'AFMS-CRT-FE-0013') {
          setError(props.fields.CWSAP7.value);
        }
        if (data1.Code === 'AFMS-DAX-FE-0041') {
          setError(props.fields.CWSAP9.value);
        }
        if (data1.Code === 'AFMS-CRT-MA-0010') {
          setError(props.fields.CWSAP3.value);
        }
        if (data1.Code === 'AFMS-CRT-FE-0050') {
          setError(props.fields.CWSAP8.value);
        }
        if (data1.Code === 'AFMS-DAX-FE-0001') {
          setError(props.fields.Verifyerrormessage.value);
        }
      });
  };

  useEffect(() => {
    if (!startedLogin || fetchAllDataSuccess === undefined) {
      return;
    }

    setStartedLogin(false);
    dispatch(itemsAreLoading(false));
    if (fetchAllDataSuccess) {
      redirectAfterLogin();
    } else {
      redirectAfterLogin();
      //handleLogOut(dispatch, '/technical-difficulties');
    }
  }, [startedLogin, fetchAllDataSuccess]);
  return (
    <Loader />
  );
  /*return (
  <div className="content-container-card">
    <div
      tabIndex="-1"
      id="anonymousaccountsignin"
      className="content-title no-focus-outline"
      aria-label={props.fields.AccountSigninTitleA11y.value}
    >
      <Text field={props.fields.AccountSigninTitle} />
    </div>
    <div className="content-subtext">
      <RichText field={props.fields.AccountSigninDesc} />
    </div>
    {error ? <Error small title={error} text={typeof error === 'string' ? error : ''} /> : null}
    <div id="api" ></div>
    {/* <Formik
      enableReinitialize
      initialValues={{
        [signInFormFields.prestoCardNumber]: '',
        [signInFormFields.verificationNumber]: '',
      }}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        prestoCardNumber: Yup.string()
          .required(props.fields.CWSAP1.value)
          .max(17, props.fields.CWSAP2.value)
          .min(17, props.fields.CWSAP2.value)
          .matches(/^-?\d+\.?\d*$/, props.fields.CWSAP2.value),
        verificationNumber: Yup.string()
          .required(props.fields.CWSAP5.value)
          .max(3, props.fields.CWSAP6.value)
          .min(3, props.fields.CWSAP6.value)
          .matches(/^-?\d+\.?\d*$/, props.fields.CWSAP6.value),
      })}
      onSubmit={(values) => {
        handleSignin(values);
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            name={signInFormFields.prestoCardNumber}
            errors={errors}
            touched={touched}
            className="prestocard-text-field"
            label={props.fields.CardNumberLabel}
            innerRef={(inputRef) => {
              usernameInputRef = inputRef;
            }}
          />
          <TextInput
            name={signInFormFields.verificationNumber}
            errors={errors}
            touched={touched}
            label={props.fields.VerificationNumberLabel}
          />
          <ButtonBlock>
            <ButtonBlock className="presto-card-button" right>
              <Button
                type="submit"
                buttonTextA11y={props.fields.SigninTextA11y.value}
                textTransform={true}
              >
                <Text field={props.fields.SigninText} />
              </Button>
            </ButtonBlock>
          </ButtonBlock>
          <div className="presto-card-image">
            <img
              src={props?.fields?.SigninImage?.value?.src}
              alt={props?.fields?.SigninImage?.value?.alt}
            ></img>
          </div>
        </form>
      )}
      </Formik> }
  </div>
);*/
};

export default withTranslation()(PrestocardSignin);
