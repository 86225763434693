import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBlock, Loader, RadioInput } from '../../../atoms';
import { Note, Select, TextInput } from '../../../molecules';
import {
  setAddressType,
  setCurrentShippingDetails,
  setNewShippingDetails,
} from '../../../redux/user/actions';
import api from '../../../services/api';
import './DeactivateAndTransferNew1.scss';

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (
      (values.firstName &&
        values.lastName &&
        values.streetAddress &&
        values.city &&
        values.postalCode) ||
      values.addressType
    ) {
      submitForm();
    }
  }, [values]);
  return null;
};

const POSTAL_REGEX = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;
const ALPHANUMERIC_REGEXFirstlastname = /^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/;
const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z ]+$/;

const DeactivateAndTransferNew1 = ({ toNextStep, userInfo, toPreviousStep, fields }) => {
  const [addressType, setAddresType] = useState('current');
  const [shippingRadioBlock, setshippingRadioBlock] = useState(true);
  const [isError, setIsError] = useState(false);
  const [shippingData, setShippingData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const customerId = userInfo.customerId;
  const mediaId = userInfo.selectedCard.mediaId;
  const currentAddress = useSelector((reduxData) => reduxData.user.currentShippingDetails);
  const [options, setOptions] = useState([]);
  const shoppingCartId = useSelector((reduxData) => reduxData.user.shoppingCartId);
  const visibleId = userInfo.selectedCard.visibleId;
  const dispatch = useDispatch();
  const focusDiv = useRef();
  useEffect(() => {
    document.body.scrollTop = 0;
    setIsLoading(true);
    Promise.all([api.getPersonalData(), api.getCountryAreas()])
      .then((responses) => {
        setIsLoading(false);
        return Promise.all(
          responses.map((response, index) => {
            if (index == 0) {
              dispatch(setCurrentShippingDetails(response?.data?.Data?.Address));
            } else {
              setCountryData(response?.data);
            }
          })
        );
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('error', error);
      });
  }, []);

  useEffect(() => {
    countryData &&
      countryData?.Areas &&
      countryData.Areas.map((data) => {
        let obj = {
          label: data.Name,
          value: data.Id,
        };
        options.push(obj);
      });
  }, [countryData]);

  const setShippingApi = () => {
    const area =
      options &&
      options.filter((d) => {
        if (typeof shippingData.province === 'object') {
          return d.value === shippingData.province[0];
        } else {
          return d.value === shippingData.province;
        }
      });
    const payload = {
      FirstName: shippingData.firstName,
      LastName: shippingData.lastName,
      Line1: shippingData.streetAddress,
      Line2: shippingData.apartmentAddress,
      City: shippingData.city,
      PostalCode: shippingData.postalCode,
      ShippingEmail: userInfo.loginEmail,
      ShoppingstrCartId: 'test',
      CustomerId: customerId,
      VisibleId: visibleId,
      MediaId: mediaId,
      CountryId: shippingData.country,
      CountryName: shippingData.countryName,
      AreaName: area && area.length > 0 && area[0].label,
      AreaCode: Array.isArray(shippingData?.province) ? shippingData?.province[0] : shippingData?.province,
      AreaId: Array.isArray(shippingData?.province) ? shippingData?.province[0] : shippingData?.province,
    };
    if (addressType?.addressType == 'new') {
      dispatch(setNewShippingDetails(payload));
    }
    toNextStep();
  };
  useEffect(() => {
    if (focusDiv.current && !shippingRadioBlock) {
      focusDiv.current.focus();
      window.scrollTo(0, 0);
    }
  }, [shippingRadioBlock]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="deactivate-new-shipping-addr-block">
        {shippingRadioBlock ? (
          <>
            <div className="deactivate-new-shipping-header">
              <Text field={fields.ShippingAddressTitle} />
            </div>
            <div className="shipping-content">
              <Formik
                initialValues={{
                  addressType: 'current',
                }}
                onSubmit={(values) => setAddresType(values)}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="formContent">
                    <div className="shipping-curr-addr">
                      <RadioInput
                        name="addressType"
                        value="current"
                        label={fields.OldShippingAddressBtnTxt.value}
                        type="radio"
                        onChange={handleChange}
                        checked={values.addressType === 'current'}
                        a11y={fields.OldShippingAddressBtnTxt.value}
                      />
                      <hr className="addressLine"></hr>
                      <div className="shipping-curr-details">
                        <p>
                          <b>
                            {currentAddress?.Line1} {currentAddress?.Line2}
                          </b>
                        </p>
                        <p>
                          {currentAddress?.City}, {currentAddress?.Area?.Name}
                        </p>
                        <p>{currentAddress?.Country?.Id}</p>
                        <p>{currentAddress?.PostalCode}</p>
                      </div>
                    </div>
                    <div className="shipping-diff-addr">
                      <RadioInput
                        name="addressType"
                        value="new"
                        label={fields.NewShippingAddressBtnTxt.value}
                        type="radio"
                        onChange={handleChange}
                        checked={values.addressType === 'new'}
                        a11y={fields.NewShippingAddressBtnTxt.value}
                      />
                      <hr className="addressLine"></hr>
                      {values.addressType === 'new' ? (
                        <div className="shipping-diff-details">
                          <RichText field={fields.NewShippingAddressBtnSubTxt} />
                        </div>
                      ) : null}
                    </div>
                    <AutoSubmitToken />
                  </form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div ref={focusDiv} tabIndex="-1" className="shipping-details-header no-focus-outline">
              <Text field={fields.ShippingAddressTitle} />
            </div>
            <div className="shipping-title">
              <RichText field={fields.NewShippingAddressSubTitle} />
            </div>
            <div className="required">
              <Text field={fields.Required} />
            </div>
            <div className="shipping-details-content">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  streetAddress: '',
                  apartmentAddress: '',
                  city: '',
                  province:
                    options &&
                    options.length > 0 &&
                    options
                      .filter((e) => {
                        return e.label === 'Ontario';
                      })
                      .map((val) => {
                        return val.value;
                      }),
                  country: 'CAN',
                  postalCode: '',
                  countryName: 'Canada',
                }}
                validate={(values) => {
                  const errors = {};
                  if (
                    !values.firstName ||
                    values.firstName.length > 25 ||
                    !ALPHANUMERIC_REGEXFirstlastname.test(values.firstName)
                  ) {
                    errors.firstName = fields.FirstNameError.value;
                  }
                  if (
                    !values.lastName ||
                    values.lastName.length > 25 ||
                    !ALPHANUMERIC_REGEXFirstlastname.test(values.lastName)
                  ) {
                    errors.lastName = fields.LastNameError.value;
                  }
                  if (
                    !values.streetAddress ||
                    values.streetAddress.length > 25 ||
                    !ALPHANUMERIC_REGEX.test(values.streetAddress)
                  ) {
                    errors.streetAddress = fields.StreetAddressError.value;
                  }
                  if (
                    !values.city ||
                    values.city.length > 25 ||
                    !ALPHANUMERIC_REGEX.test(values.city)
                  ) {
                    errors.city = fields.CityProvinceError.value;
                  }
                  if (!values.postalCode || !POSTAL_REGEX.test(values.postalCode)) {
                    errors.postalCode = fields.PostalCodeError.value;
                  }
                  if (Object.keys(errors).length === 0) {
                    setIsError(false);
                  } else {
                    setIsError(true);
                  }
                  return errors;
                }}
                onSubmit={(values) => setShippingData(values)}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="form-detail-Content">
                    <div className="shipping-username">
                      <div className="first-name">
                        <div className="first-name-label">
                          <Text field={fields.FirstName} />
                        </div>
                        <div className="first-name-field">
                          <TextInput
                            type="text"
                            value={values.firstName}
                            name="firstName"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.FirstNameA11y.value}
                          />
                        </div>
                      </div>
                      <div className="last-name">
                        <div className="last-name-label">
                          <Text field={fields.LastName} />
                        </div>
                        <div className="last-name-field">
                          <TextInput
                            type="text"
                            value={values.lastName}
                            name="lastName"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.LastNameA11y.value}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="shipping-address">
                      <div className="street-add">
                        <div className="street-add-label">
                          <Text field={fields.StreetAddress} />
                        </div>
                        <div className="street-add-field">
                          <TextInput
                            type="text"
                            value={values.streetAddress}
                            name="streetAddress"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.StreetAddressA11y.value}
                          />
                        </div>
                      </div>

                      <div className="apartment">
                        <div className="apartment-label">
                          <Text field={fields.AptNum} />
                        </div>
                        <div className="apartment-field">
                          <TextInput
                            type="text"
                            value={values.apartmentAddress}
                            name="apartmentAddress"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.AptNumA11y.value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="shipping-province">
                      <div className="city">
                        <div className="city-label">
                          <Text field={fields.CityProvince} />
                        </div>
                        <div className="city-field">
                          <TextInput
                            type="text"
                            value={values.city}
                            name="city"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.CityProvinceA11y.value}
                          />
                        </div>
                      </div>
                      <div className="province">
                        <div className="province-label">
                          <Text field={fields.Province} />
                        </div>
                        <div className="province-field">
                          <Select
                            fullWidth
                            name="province"
                            errors={errors}
                            touched={touched}
                            options={options}
                            defaultValue="ON"
                            defaultLabel="Ontario"
                            labelA11y={fields.Province.value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="shipping-country-code">
                      <div className="country">
                        <div className="country-label">
                          <Text field={fields.Country} />
                        </div>
                        <div className="country-field">
                          <TextInput
                            type="text"
                            value="Canada"
                            name="country"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            disabled={true}
                            inputA11y={fields.CountryA11y.value}
                          />
                        </div>
                      </div>
                      <div className="postal-code">
                        <div className="postal-code-label">
                          <Text field={fields.PostalCode} />
                        </div>
                        <div className="postal-code-field">
                          <TextInput
                            type="text"
                            value={values.postalCode}
                            name="postalCode"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            inputA11y={fields.PostalCodeA11y.value}
                          />
                        </div>
                        <div className="PostalCodeNote">
                          <Text field={fields.PostalHint} />
                        </div>
                      </div>
                    </div>
                    <AutoSubmitToken />
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
      <ButtonBlock>
        <ButtonBlock right className="Deactivated-Button">
          {!isError ? (
            shippingRadioBlock ? (
              addressType.addressType === 'current' ? (
                <Button
                  type="submit"
                  buttonTextA11y=""
                  onClick={() => {
                    dispatch(setAddressType('current'));
                    toNextStep();
                  }}
                >
                  <Text field={fields.NextCTALabel} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  buttonTextA11y=""
                  onClick={() => {
                    setshippingRadioBlock(false);
                    dispatch(setAddressType('new'));
                  }}
                >
                  <Text field={fields.NextCTALabel} />
                </Button>
              )
            ) : (
              <Button
                type="submit"
                buttonTextA11y=""
                onClick={() => {
                  setShippingApi();
                }}
              >
                <Text field={fields.NextCTALabel} />
              </Button>
            )
          ) : (
            <Button type="submit" buttonTextA11y="">
              <Text field={fields.NextCTALabel} />
            </Button>
          )}
          {shippingRadioBlock ? (
            <Button white buttonTextA11y="" onClick={() => toPreviousStep()}>
              <Text field={fields.GoBackCTALabel} />
            </Button>
          ) : (
            <Button
              white
              buttonTextA11y=""
              onClick={() => {
                setshippingRadioBlock(true);
                setIsError(false);
              }}
            >
              <Text field={fields.GoBackCTALabel} />
            </Button>
          )}
        </ButtonBlock>
      </ButtonBlock>
      {shippingRadioBlock ? (
        ''
      ) : (
        <Note className="deactivate-new-note">
          <RichText field={fields.shippingAddressNote} />
        </Note>
      )}
    </>
  );
};

export default DeactivateAndTransferNew1;
