import React from 'react';

import { MediaImage } from '../../atoms';
import './titleWithIcon.scss';

export default function TitleWithIcon({
  title,
  MediaImageSrc,
  className = '',
  isFromSPM = false,
  cardAltText = '',
}) {
  return (
    <div className={`titleWithIcon ${className}`}>
      <MediaImage
        cardImgSrc={MediaImageSrc}
        cardAltText={cardAltText}
        cardImgAlt={isFromSPM ? '' : 'Media Icon'}
        sm
      />
      <div className="titleWithIcon-title">{title}</div>
    </div>
  );
}
