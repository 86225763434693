/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import {
  formatUtcDateToEasternTime,
  formatDecimalWithCurrency,
  isOneOf,
  convertToDollars,
  redirectToUrl,
  replaceDate,
  replaceDatewithOutFormate,
} from '../../../services/utils';
import Transaction from '../Transaction';
import CASES from '../../../constants/transactionCases';
import ROUTES from '../../../constants/routes';
import errorIcon from '../../../assets/images/error.svg';
import downIcon from '../../../assets/images/down.svg';
import upIcon from '../../../assets/images/up.svg';

import './contactlessTransactionGridItem.scss';

const getTransactionCase = (data, sum) => {
  if (
    data.status === 'OPEN' &&
    isOneOf(
      ['PENDING_PREAUTHORIZED', 'PREAUTHORIZED', 'PENDING_AUTHORIZED'],
      data.acquirerStatus
    ) &&
    isOneOf(
      [
        'NO_DEBT',
        'DEBT_EMV_EXPIRED',
        'DEBT_CLEARED_BY_AUTO_RECOVERY',
        'DEBT_CLEARED_BY_CUSTOMER',
        'DEBT_CLEARED_BY_NEW_TAP',
        'DEBT_PENDING_TAP_OFF',
      ],
      data.debtStatus
    )
  ) {
    return CASES.pendingFare;
  }

  if (
    sum !== 0 &&
    isOneOf(['OPEN', 'CLOSED'], data.status) &&
    isOneOf(
      ['DEBT_BELOW_THRESHOLD', 'DEBT_ABOVE_THRESHOLD', 'DEBT_WRITTEN_OFF', 'DEBT_INCURRED'],
      data.debtStatus
    ) &&
    data.accountType !== 'CUSTOMER_INITIATED_DEBT_RECOVERY'
  ) {
    return CASES.unpaidFare;
  }

  if (data.status === 'CLOSED' && data.accountType === 'INSPECTION_FARES') {
    return CASES.inspectionFare;
  }

  if (
    data.status === 'CANCELLED' &&
    data.debtStatus !== 'DEBT_CLEARED_BY_DIFFERENT_CARD' &&
    sum === 0
  ) {
    return CASES.cancelled;
  }

  if (
    data.status === 'OPEN' &&
    (data.debtStatus === 'DEBT_CLEARED_BY_PAYMENT_PROTECTION' ||
      isOneOf(['DEBT_ABOVE_THRESHOLD', 'DEBT_INCURRED', 'DEBT_WRITTEN_OFF AND'], data.debtStatus))
  ) {
    return CASES.verifyCard;
  }

  if (
    data.status === 'CLOSED' &&
    data.debtStatus === 'DEBT_CLEARED_BY_CUSTOMER' &&
    data.accountType === 'CUSTOMER_INITIATED_DEBT_RECOVERY'
  ) {
    return CASES.debtClearedByCustomer;
  }

  if (
    data.status === 'CLOSED' &&
    isOneOf(['DEBT_CLEARED_BY_CUSTOMER', 'DEBT_CLEARED_BY_NEW_TAP'], data.debtStatus)
  ) {
    return CASES.settledUnpaidFares;
  }

  if (
    sum < 0 &&
    data.status === 'CLOSED' &&
    data.debtStatus !== 'DEBT_CLEARED_BY_DIFFERENT_CARD' &&
    data.accountType !== 'CUSTOMER_INITIATED_DEBT_RECOVERY'
  ) {
    return CASES.negativeSalesOrder;
  }

  if (
    data.status === 'CLOSED' &&
    data.lines.find(
      (i) =>
        i.linkedUsageUuid && i.Usage?.usageType !== 'REVERSE_TAP' && i.Usage?.usageType !== 'REFUND'
    ) &&
    data.debtStatus !== 'DEBT_CLEARED_BY_DIFFERENT_CARD' &&
    data.accountType !== 'CUSTOMER_INITIATED_DEBT_RECOVERY'
  ) {
    return CASES.fareAdjustmentExistsInSalesOrder;
  }

  if (
    data.status === 'CLOSED' &&
    data.lines.find((i) => i.Usage?.tripInfo?.tapType === 'TAP_OFF_AUTOMATIC') &&
    data.lines.find((i) => i.Usage?.usageType === 'DIRECT_TAP') &&
    data.debtStatus !== 'DEBT_CLEARED_BY_DIFFERENT_CARD' &&
    data.accountType !== 'CUSTOMER_INITIATED_DEBT_RECOVERY'
  ) {
    return CASES.automaticTapOffExistsInSalesOrder;
  }

  if (data.status === 'CLOSED') {
    return CASES.allOtherScenarios;
  }

  return '';
};

const Message = ({ messageArray, date, isContactless }) => {
  if (!messageArray) return null;
  return (
    <div
      className="contactlessTransactionGridItem-more-message"
      aria-label={
        isContactless
          ? replaceDatewithOutFormate(messageArray[1], date)
          : replaceDate(messageArray[1], date)
      }
      tabIndex="0"
    >
      {isContactless
        ? replaceDatewithOutFormate(messageArray[0], date)
        : replaceDate(messageArray[0], date)}
    </div>
  );
};

const TransactionGridItem = ({
  data,
  expand,
  textMapping,
  fields,
  isInteracCard,
  t,
  processUnpaidFare = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sum = data.lines.map((i) => i.amount).reduce((a, b) => a + b);
  const transactionCase = getTransactionCase(data, sum);
  const isPendingFare = transactionCase === CASES.pendingFare;
  const [isunPaidFare, setIsunPaidFare] = useState(false);

  function getcardIdentifier(transactioncase) {
    let cardIdentifier = '';
    if (transactioncase) {
      if (isInteracCard) {
        cardIdentifier = t('interacCardIdentifier');
      } else if (transactioncase === CASES.inspectionFare) {
        cardIdentifier = t('inspectionFareCardIdentifier');
      } else if (transactioncase === CASES.debtClearedByCustomer) {
        cardIdentifier = t('unpaidFareCardIdentifier');
      } else {
        cardIdentifier = t('prestoFareCardIdentifier');
      }
    }
    return cardIdentifier;
  }

  useEffect(() => {
    let unPaidFare = transactionCase === CASES.unpaidFare;
    if (unPaidFare) {
      setIsunPaidFare(true);
    }
  }, [transactionCase]);

  const MESSAGES = {
    [CASES.pendingFare]: [
      fields.pendingFareDescription?.value,
      fields.pendingFareDescriptionA11y?.value,
    ],
    [CASES.unpaidFare]: [
      fields.unpaidFareDescription?.value,
      fields.unpaidFareDescriptionA11y?.value,
    ],
    [CASES.cancelled]: [
      fields.cancelledFareDescription?.value,
      fields.cancelledFareDescriptionA11y?.value,
    ],
    [CASES.verifyCard]: [
      fields.verifyCardFareDescription?.value,
      fields.verifyCardFareDescriptionA11y?.value,
    ],
    [CASES.settledUnpaidFares]: [
      fields.settledUnpaidFareDescription?.value,
      fields.settledUnpaidFareDescriptionA11y?.value,
    ],
    [CASES.debtClearedByCustomer]: [
      fields.settledUnpaidFareDescription?.value,
      fields.settledUnpaidFareDescriptionA11y?.value,
    ],
    [CASES.negativeSalesOrder]: [
      fields.negativeSalesOrderFareDescription?.value,
      fields.negativeSalesOrderFareDescriptionA11y?.value,
    ],
    [CASES.fareAdjustmentExistsInSalesOrder]: [
      fields.fareAdjustmentExistsInSalesOrderDescription?.value,
      fields.fareAdjustmentExistsInSalesOrderDescriptionA11y?.value,
    ],
    [CASES.automaticTapOffExistsInSalesOrder]: [
      fields.automaticTapOffExistsInSalesOrderDescription?.value,
      fields.automaticTapOffExistsInSalesOrderDescriptionA11y?.value,
    ],
  };

  const messageArray = MESSAGES[transactionCase];

  useEffect(() => {
    setIsOpen(expand);
  }, [expand]);
  const isContactlessUserSignedIn = useSelector(
    (state) => state.contactless.isContactlessUserSignedIn
  );
  return (
    <div className={`contactlessTransactionGridItem ${isunPaidFare ? 'state-unpaidFare' : ''}`}>
      <div className="contactlessTransactionGridItem-unpaidFare-header">
        <img
          className="contactlessTransactionGridItem-unpaidFare-header-icon"
          alt=""
          src={errorIcon}
        />
        <Text field={fields.unpaidFareHeading} />
        {!isInteracCard ? (
          <button
            onClick={() => {
              if (processUnpaidFare) {
                processUnpaidFare(data);
              } else {
                redirectToUrl(`${ROUTES.unpaidFares}?id=${data.uuid}`);
              }
            }}
            className="contactlessTransactionGridItem-unpaidFare-header-link link"
          >
            <Text field={fields.payNowButton} />
          </button>
        ) : null}
      </div>
      <div
        className="contactlessTransactionGridItem-head"
        role="button"
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={() => setIsOpen(!isOpen)}
        tabIndex="0"
        aria-expanded={isOpen}
      >
        <div className="contactlessTransactionGridItem-right">
          <div className="contactlessTransactionGridItem-block">
            <div>
              <div className="contactlessTransactionGridItem-title">
                {isContactlessUserSignedIn
                  ? data.createdTime
                  : formatUtcDateToEasternTime(data.createdTime)}
              </div>
            </div>
          </div>
          <div className="contactlessTransactionGridItem-value-block">
            <div>
              <div className="contactlessTransactionGridItem-title contactlessTransactionGridItem-total">
                <span className="desktopOnly">
                  <Text field={fields.totalLabel} />{' '}
                </span>
                {isPendingFare ? '-' : formatDecimalWithCurrency(convertToDollars(sum))}
              </div>
              <div className="contactlessTransactionGridItem-subTitle">
                {data.lines.length}{' '}
                {data.lines.length === 1
                  ? fields.oneItemNumberLabel?.value
                  : fields.itemNumberLabel?.value}
              </div>
            </div>
          </div>
        </div>
        <div
          className="contactlessTransactionGridItem-control"
          tabIndex="0"
          aria-expanded={isOpen}
          aria-label={
            isOpen
              ? fields.collapseTransactionButtonA11y.value
              : fields.expandTransactionButtonA11y.value
          }
        >
          {isOpen ? (
            <img alt={fields.collapseTransactionButtonA11y.value} src={upIcon} />
          ) : (
            <img alt={fields.expandTransactionButtonA11y.value} src={downIcon} />
          )}
        </div>
      </div>
      {isOpen ? (
        <div className="contactlessTransactionGridItem-more">
          <Message
            messageArray={messageArray}
            date={data.modifiedTime}
            isContactless={isContactlessUserSignedIn}
          />
          <div className="contactlessTransactionGridItem-transactions">
            {data.lines.map((i) => (
              <Transaction
                fields={fields}
                textMapping={textMapping}
                isPendingFare={isPendingFare}
                transactionCase={transactionCase}
                data={i}
                transactionStatus={data.status}
              />
            ))}
          </div>
          {isPendingFare ||
          transactionCase === CASES.unpaidFare ||
          transactionCase === CASES.cancelled ? (
            <>
              {transactionCase === CASES.cancelled ? null : (
                <div className="contactlessTransactionGridItem-pendingfare">
                  {transactionCase === CASES.unpaidFare ? (
                    <Text field={fields.unpaidFareHeading} />
                  ) : (
                    <Text field={fields.pendingFareLabel} />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="contactlessTransactionGridItem-fare">
              {getcardIdentifier(transactionCase)}/{data.visibleSalesOrderId}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(TransactionGridItem);
