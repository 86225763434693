import React, { useEffect, useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonBlock, Loader } from '../../../atoms';
import { Select } from '../../../molecules';
import { setDeactivateCardType, setSelectedCardData } from '../../../redux/user/actions';
import api from '../../../services/api';
import './DeactivateAndTransferAnother0.scss';
import { formatDecimalWithCurrency } from '../../../services/utils';

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (values.cardName) {
      submitForm();
    }
  }, [values]);
  return null;
};

const DeactivateAndTransferAnother0 = ({ toNextStep, fields }) => {
  const dispatch = useDispatch();
  const [cardNameDetail, setCardNameDetail] = useState('');
  const selectedCard = useSelector((redux) => redux.user.selectedCard);
  const fareMedias = useSelector((redux) => redux.user.fareMedias);
  const [options, setOptions] = useState([]);
  const [transferToCard, setTransferToCard] = useState([]);
  const [transferToNickName, setTransferToNickName] = useState('');
  const [targetCardAutoload, setTargetCardAutoload] = useState([]);
  const [targetCardAutorenew, setTargetCardAutorenew] = useState([]);
  const [sourceCardAutorenew, setSourceCardAutorenew] = useState([]);
  const [sourceCardTransitPass, setSourceCardTransitPass] = useState([]);
  const [targetCardTransitPass, setTargetCardTransitPass] = useState([]);
  const autoLoadData = useSelector((redux) => redux.user.autoLoadData);
  const selectedCardAutoLoad = autoLoadData && autoLoadData?.length > 0 && autoLoadData[0].Product;
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferableCardPresent, setIsTransferableCardPresent] = useState(false);
  const selectedCardDetails =
    fareMedias &&
    fareMedias.length > 0 &&
    fareMedias.filter((fareMedia) => {
      return fareMedia.visibleId === selectedCard.visibleId;
    });
  useEffect(() => {
    document.body.scrollTop = 0;
    setIsLoading(true);
    api
      .getAutorenewSubscriptionsForMedia({ visibleId: selectedCard.visibleId })
      .then((response) => {
        if (response.data.Success) {
          if (response.data.SubscriptionList.length > 0) {
            setSourceCardAutorenew(response.data.SubscriptionList);
          } else {
            setSourceCardAutorenew([]);
          }
        } else {
          setSourceCardAutorenew([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        //console.log('error', err);
      });
    api
      .getAllRegisteredCardsEligibleForTransfer({
        VisibleId: selectedCard.visibleId,
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.Success) {
          const { optionsArr, elgibleCards } = response.data.FareMedias.reduce(
            (result, card) => {
              const VisibleId = card.VisibleId;
              const NickName = card.NickName;
              let obj = {
                label: `${NickName}'s ${fields?.CardLabel?.value} - **${VisibleId.slice(-4)}`,
                value: VisibleId,
              };
              let transferToObj = {
                visibleId: VisibleId,
                nickName: NickName,
              };
              result.optionsArr.push(obj);
              result.elgibleCards.push(transferToObj);
              return result;
            },
            { optionsArr: [], elgibleCards: [] }
          );
          const defaultValue = {
            label: fields.SelectCardLabel.value,
            value: '',
          };
          optionsArr.unshift(defaultValue);
          let transfermedias = fareMedias?.filter((x) => x.visibleId !== selectedCard.visibleId);
          for (let i = 0; i < transfermedias?.length; i++) {
            let card = elgibleCards.find((x) => x.visibleId === transfermedias[i].visibleId);
            if (card === undefined) {
              let obj = {
                label: `${transfermedias[i].nickName}'s ${fields?.CardLabel?.value} - ${fields?.NotEligibleLabel?.value}`,
                value: '0',
              };
              optionsArr.push(obj);
            }
          }
          setOptions(optionsArr);
          setTransferToCard(elgibleCards);
          if (response.data?.FareMedias?.length > 0) {
            setIsTransferableCardPresent(true);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        //console.log('error', err);
      });
    api
      .getAllProductsLoadedInMedia({
        visibleId: selectedCard.visibleId,
      })
      .then((response) => {
        if (response.data.Success) {
          if (response.data.productInstances.length > 0) {
            setSourceCardTransitPass(response.data.productInstances);
          } else {
            setSourceCardTransitPass([]);
          }
        } else {
          setSourceCardTransitPass([]);
        }
      });
  }, []);
  const selectCardChangeHandler = () => {
    setTimeout(() => {
      document.getElementById('selectdropdown').value = '';
    }, 500);
  };
  //console.log(options);
  const selectCard = (value) => {
    setIsLoading(true);
    Promise.all([
      api.getAutoloadSubscriptionForMedia({ visibleId: value }),
      api.getAutorenewSubscriptionsForMedia({ visibleId: value }),
      api.getAllProductsLoadedInMedia({ visibleId: value }),
    ])
      .then((responses) => {
        setIsLoading(false);
        return Promise.all(
          responses.map((response, index) => {
            if (index == 0) {
              if (response.data.Success) {
                if (response.data.SubscriptionList.length > 0) {
                  setTargetCardAutoload(response.data.SubscriptionList[0].Product);
                } else {
                  setTargetCardAutoload([]);
                }
              } else {
                setTargetCardAutoload([]);
              }
            } else if (index == 1) {
              if (response.data.Success) {
                if (response.data.SubscriptionList.length > 0) {
                  setTargetCardAutorenew(response.data.SubscriptionList);
                } else {
                  setTargetCardAutorenew([]);
                }
              } else {
                setTargetCardAutorenew([]);
              }
            } else if (response.data.Success) {
              if (response.data.productInstances.length > 0) {
                setTargetCardTransitPass(response.data.productInstances);
              } else {
                setTargetCardTransitPass([]);
              }
            } else {
              setTargetCardTransitPass([]);
            }
          })
        );
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('error', error);
      });

    const cardNameDetails =
      fareMedias &&
      fareMedias.length > 0 &&
      fareMedias.filter((fareMedia) => {
        return fareMedia.visibleId == value;
      });
    setCardNameDetail(cardNameDetails && cardNameDetails.length > 0 && cardNameDetails[0]);

    const transferToNickNames =
      transferToCard &&
      transferToCard.length > 0 &&
      transferToCard.filter((transferToCardEl) => {
        return transferToCardEl.visibleId == value;
      });
    setTransferToNickName(
      transferToNickNames && transferToNickNames.length > 0 && transferToNickNames[0]
    );
  };
  function GetSortOrder(arrayItem) {
    return function (a, b) {
      if (a[arrayItem] !== '' && b[arrayItem] !== '') {
        if (a[arrayItem] < b[arrayItem]) {
          return 1;
        }
        if (a[arrayItem] > b[arrayItem]) {
          return -1;
        }
      }
      return 0;
    };
  }
  const submitData = () => {
    const selectedCardTemp = {
      nickName: selectedCardDetails[0]?.nickName,
      transferToNickName: transferToNickName.nickName,
      cardNumber: cardNameDetail.visibleId,
      balance: cardNameDetail.currentBalance + selectedCardDetails[0].currentBalance,
      autoloadContract: selectedCardAutoLoad ? selectedCardAutoLoad : targetCardAutoload,
      autorenewContract: sourceCardAutorenew.concat(targetCardAutorenew),
      transitPass: sourceCardTransitPass.concat(targetCardTransitPass),
      fareType: selectedCardDetails[0].fareType,
      mediaId: cardNameDetail.mediaId,
    };
    dispatch(setSelectedCardData(selectedCardTemp));
    toNextStep();
  };
  let formattedBalance = formatDecimalWithCurrency(selectedCardDetails[0]?.currentBalance);
  return (
    <>
      {isLoading && <Loader />}
      <div className="lost-card-details">
        <div className="card-details-header">
          <Text field={fields.TransferCardTitle} />
        </div>
        <div className="card-details-content">
          <div className="card-nickname">
            <span>
              <Text field={fields?.CardNickName} />
            </span>
            <span className="bold-content">{selectedCardDetails[0]?.nickName}</span>
          </div>
          <div className="card-number">
            <span>
              <Text field={fields?.PrestoCardNumber} />
            </span>
            <span className="bold-content">{selectedCardDetails[0]?.visibleId}</span>
          </div>
          <div className="card-balance">
            <span>
              <Text field={fields?.Balance} />
            </span>
            <span className="bold-content">{formattedBalance}</span>
          </div>
          <div className="card-autoload">
            <span>
              <Text field={fields?.AutoLoadContract} />
            </span>
            {selectedCardAutoLoad ? (
              <span className="bold-content">
                {fields?.ThresholdLabel?.value}{' '}
                {formatDecimalWithCurrency(selectedCardAutoLoad.Threshold)};{' '}
                {fields?.LoadLabel?.value} {formatDecimalWithCurrency(selectedCardAutoLoad.Amount)}
              </span>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>
          <div className="card-autorenew">
            <span>
              <Text field={fields?.AutoRenewContract} />
            </span>
            {sourceCardAutorenew && sourceCardAutorenew.length > 0 ? (
              <div>
                {sourceCardAutorenew.map((pass) => {
                  if (pass?.Product?.ProductName !== null) {
                    return <p className="transitpass-list-RLC">{pass?.Product?.ProductName}</p>;
                  }
                })}
              </div>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>

          <div className="card-transitpass">
            <span>
              <Text field={fields?.TransitPass} />
            </span>
            {sourceCardTransitPass && sourceCardTransitPass.length > 0 ? (
              <div>
                {sourceCardTransitPass.map((pass) => {
                  if (pass.ProductName !== null) {
                    return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                  }
                })}
              </div>
            ) : (
              <span className="bold-content">-</span>
            )}
          </div>

          <div className="card-faretype">
            <span>
              <Text field={fields?.FareType} />
            </span>
            <span className="bold-content">{selectedCardDetails[0].fareType}</span>
          </div>
        </div>
      </div>
      <div className="lost-card-details-block">
        <div className="card-details-header">
          <Text field={fields.TransferToDropLinkLabel} />
        </div>
        <div className="card-details-descr">
          <RichText field={fields.TransferCardDescription} />
        </div>
        <div className="card-select-block">
          <Formik
            initialValues={{
              cardName: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.cardName || values.cardName === '0') {
                selectCardChangeHandler();
                errors.cardName = fields.SelectCardLabelError.value;
                setCardNameDetail('');
              }

              return errors;
            }}
            validationSchema={Yup.object().shape({
              cardName: Yup.string().required(fields?.SelectCardLabelError?.value),
            })}
            onSubmit={(values) => selectCard(values.cardName)}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="card-details-dropdown">
                  <div className="card-detail">
                    <div className="card-detail-label">
                      {/* <Text field={fields?.TransferToDropLinkLabel} /> */}
                    </div>
                    <div className="card-details-field">
                      <Select
                        name="cardName"
                        errors={errors}
                        touched={touched}
                        showFormikSelect
                        options={options.sort(GetSortOrder('value'))}
                        labelA11y={fields?.SelectCardLabel.value}
                        showPlaceholder={false}
                        isDisabled={!isTransferableCardPresent}
                      />
                      {errors.cardName && touched.cardName}
                      {!isTransferableCardPresent && (
                        <div className="inlineError">{fields.NoCardsAvailable.value}</div>
                      )}
                    </div>
                  </div>
                </div>
                {cardNameDetail && (
                  <div className="selected-card-detail">
                    <div className="presto-card">
                      <span>
                        <Text field={fields?.PrestoCardNumber} />
                      </span>
                      <span className="bold-content">{cardNameDetail.visibleId}</span>
                    </div>
                    {cardNameDetail.currentBalance && (
                      <div className="card-balance">
                        <span>
                          <Text field={fields?.Balance} />
                        </span>
                        <span className="bold-content">
                          {formatDecimalWithCurrency(cardNameDetail.currentBalance)}
                        </span>
                      </div>
                    )}
                    <div className="card-autoload">
                      <span>
                        <Text field={fields?.AutoLoadContract} />
                      </span>
                      {targetCardAutoload && targetCardAutoload?.Threshold ? (
                        <span className="bold-content">
                          {fields?.ThresholdLabel?.value}{' '}
                          {formatDecimalWithCurrency(targetCardAutoload.Threshold)};{' '}
                          {fields?.LoadLabel?.value}{' '}
                          {formatDecimalWithCurrency(targetCardAutoload.Amount)}
                        </span>
                      ) : (
                        <span className="bold-content">-</span>
                      )}
                    </div>
                    <div className="card-autorenew">
                      <span>
                        <Text field={fields?.AutoRenewContract} />
                      </span>
                      {targetCardAutorenew && targetCardAutorenew.length > 0 ? (
                        <div>
                          {targetCardAutorenew.map((pass) => {
                            if (pass?.Product?.ProductName !== null) {
                              return (
                                <p className="transitpass-list-RLC">{pass?.Product?.ProductName}</p>
                              );
                            }
                          })}
                        </div>
                      ) : (
                        <span className="bold-content">-</span>
                      )}
                    </div>

                    <div className="transit-pass">
                      <span>
                        <Text field={fields?.TransitPass} />
                      </span>
                      {targetCardTransitPass && targetCardTransitPass.length > 0 ? (
                        <div>
                          {targetCardTransitPass.map((pass) => {
                            if (pass.ProductName !== null) {
                              return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                            }
                          })}
                        </div>
                      ) : (
                        <span className="bold-content">-</span>
                      )}
                    </div>
                    <div className="card-fareType">
                      <span>
                        <Text field={fields?.FareType} />
                      </span>
                      <span className="bold-content">{cardNameDetail.fareType}</span>
                    </div>
                  </div>
                )}
                <AutoSubmitToken />
                <ButtonBlock className="Deactivated-Button">
                  <Button
                    isDisabled={!isTransferableCardPresent}
                    type="submit"
                    onClick={() => (cardNameDetail ? submitData() : '')}
                  >
                    <Text field={fields.NextCTALabel} />
                  </Button>
                  <Button onClick={() => dispatch(setDeactivateCardType(''))}>
                    <Text field={fields.GoBackCTALabel} />
                  </Button>
                </ButtonBlock>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default DeactivateAndTransferAnother0;
