import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Button, ButtonBlock, PrintButton, PrestoLink } from '../../atoms';
import { Success, ValueRow } from '../../molecules';
import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import fundIcon from '../../assets/images/AddCard/Funds.svg';
import autoloadIcon from '../../assets/images/AddCard/Autoload.svg';
import autorenewIcon from '../../assets/images/AddCard/Autorenew.svg';
import { selectCard } from '../../redux/user/actions';

import './addPrestoCard3.scss';

export default function AddPrestoCard3(props) {
  const { fields, state, toFirstStep } = props;
  const dispatch = useDispatch();
  let allfareMedias = useSelector((reduxState) => reduxState.user.fareMedias);

  useEffect(() => {
    //console.log('allfareMedias', allfareMedias);
    let targetCard = allfareMedias.find((data) => data.visibleId === state?.MediaId);
    //console.log(targetCard, 'targetCard');
    if (targetCard !== undefined) {
      dispatch(selectCard(targetCard));
    }
  }, [allfareMedias]);

  return (
    <div className="addCard-success">
      <div className="autoload-success-wrapper">
        <div className="autoload-header" aria-label={fields.headingA11y.value}>
          <Text field={fields.heading} />
        </div>
        <Success
          title={fields.cardAddedSuccessfulMessage}
          contextText={fields.cardAddedDescription}
          Content={() => (
            <div className="autoload-setup autoload-success">
              <div className="values-grid">
                <ValueRow
                  left={<Text field={fields.reviewCardNumberText} />}
                  right={state.MediaId}
                />
                {state.CVN ? (
                  <ValueRow
                    left={<Text field={fields.reviewVerificationNumberText} />}
                    right={state.CVN}
                  />
                ) : null}
                {state.NickName ? (
                  <ValueRow left={<Text field={fields.nicknameText} />} right={state.NickName} />
                ) : null}
              </div>

              <div className="sub-title" aria-label={fields.cardAddedGetStartedA11y.value}>
                <Text field={fields.cardAddedGetStarted} />
              </div>
              <div className="getStarted">
                <div className="getStarted-block">
                  <div className="getStarted-text-wrapper">
                    <div className="getStarted-img-block">
                      <img alt="fund icon" src={fundIcon} className="getStarted-block-img" />
                    </div>
                    <div
                      className="getStarted-block-text"
                      aria-label={fields.cardAddedAddFundsDescriptionA11y.value}
                    >
                      <Text field={fields.cardAddedAddFundsDescription} />
                    </div>
                  </div>
                  <div className="getStarted-block-link">
                    <PrestoLink field={fields.cardAddedLoadMyCardLink} />
                  </div>
                </div>

                <div className="getStarted-block">
                  <div className="getStarted-text-wrapper">
                    <div className="getStarted-img-block">
                      <img alt="fund icon" src={autoloadIcon} className="getStarted-block-img" />
                    </div>

                    <div
                      className="getStarted-block-text"
                      aria-label={fields.cardAddedAutoloadDescriptionA11y.value}
                    >
                      <Text field={fields.cardAddedAutoloadDescription} />
                    </div>
                  </div>
                  <div className="getStarted-block-link">
                    <PrestoLink field={fields.cardAddedSetAutoloadLink} />
                  </div>
                </div>

                <div className="getStarted-block">
                  <div className="getStarted-text-wrapper">
                    <div className="getStarted-img-block">
                      <img alt="fund icon" src={autorenewIcon} className="getStarted-block-img" />
                    </div>

                    <div
                      className="getStarted-block-text"
                      aria-label={fields.cardAddedAutorenewDescriptionA11y.value}
                    >
                      <Text field={fields.cardAddedAutorenewDescription} />
                    </div>
                  </div>
                  <div className="getStarted-block-link">
                    <PrestoLink field={fields.cardAddedSetAutorenewLink} />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </div>
      <ButtonBlock>
        <PrintButton />
        <ButtonBlock right>
          <Button
            onClick={() => redirectToUrl(routes.manageMyCards)}
            buttonTextA11y={fields.cardAddedBackButtonTextA11y.value}
          >
            <Text field={fields.cardAddedBackButtonText} />
          </Button>
          <Button
            onClick={() => toFirstStep()}
            white
            firstOrder
            buttonTextA11y={fields.cardAddedAddCardButtonTextA11y.value}
          >
            <Text field={fields.cardAddedAddCardButtonText} />
          </Button>
        </ButtonBlock>
      </ButtonBlock>
    </div>
  );
}
