import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import mediaSubTypes from '../../constants/mediaSubTypes';
import useSelectedCard from '../../hooks/useSelectedCard';
import { Loader, PrestoLink } from '../../atoms';
import { CardSwitcher, CardControlledMenuItem } from '../../molecules';
import accountAvatarImg from '../../assets/images/account_avatar.svg';
import './sideNavigation.scss';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import routes from '../../constants/routes';
import { redirectToUrl } from '../../services/utils';

const SideNavigation = (props) => {
    const { datasource, contextItem } = props.fields && props.fields.data ? props.fields.data : {};
    // const authenticated = useSelector((state) => state.user.authenticated);
    // const anonymous = useSelector((state) => state.user.anonymous);
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCard = useSelectedCard();
    const userInfo = useSelector((state) => state.user);
    const isLoading = useSelector((state) => state.user.isLoading);
    const firstNameUpdatePersonalInfo = useSelector((reduxState) => reduxState.user.firstNameUpdatePersonalInfo);
    let [showSideNav, setShowSideNav] = useState(true);
    let [loading, setLoading] = useState(false);

    let showCardSwitcher = false;

    function enableCardSwitcher(enable) {
        showCardSwitcher = enable;
    }

    function showElement(showForPrestoCard, showForContactlessCard) {
        return (
            !showCardSwitcher ||
            (showCardSwitcher && showForPrestoCard && selectedCard?.type === mediaSubTypes.presto) ||
            (showCardSwitcher && showForContactlessCard && selectedCard?.type !== mediaSubTypes.presto)
        );
    }
    useDidMountEffect(() => {
        setIsLoaded(true);
    }, [props]);
    useEffect(() => {
        let hasUserInfo = userInfo.customerId ? true : false;
        if (document.getElementsByClassName('order_new_card_image_para').length > 0 && !hasUserInfo) {
            setShowSideNav(false);
        } else if (
            document.getElementsByClassName('order_new_card_image_para').length > 0 &&
            hasUserInfo
        ) {
            setShowSideNav(true);
        } else if (!document.getElementsByClassName('order_new_card_image_para').length > 0) {
            setShowSideNav(true);
        }
    }, [isLoaded]);

    /* useEffect(() => {
      if (anonymous && !authenticated) {
        datasource.children.splice(2, datasource.children.length);
      }
    }, []); */

    return showSideNav
        ? [
            loading ? <Loader /> : null,
            <div className="side-wrapper ">
                <div className="side-menu" role="navigation">
                    {datasource &&
                        (datasource.WithCardSwitcher?.jss.value === true && selectedCard?.type === '' ? (
                            <>
                                {datasource.children.some(
                                    (child) => child.ShowCardSwitcher?.jss.value === true
                                ) && <CardSwitcher props={props} loading={loading} setLoading={setLoading} />}
                            </>
                        ) : (
                            <>
                                {datasource.ShowHeading?.jss.value === true && (
                                    <h2>
                                        <Text field={datasource.Heading?.jss} />
                                    </h2>
                                )}
                                <div data-e2e-id="graphql-integrated">
                                    <ul>
                                        {datasource.children.map((child) => (
                                            <>
                                                {child.ShowCardSwitcher?.jss.value === true && (
                                                    <CardSwitcher props={props} loading={loading} setLoading={setLoading} />
                                                )}
                                                {child.ShowCardSwitcher?.jss.value === true && enableCardSwitcher(true)}
                                                {child.Link && (
                                                    <>
                                                        {showCardSwitcher ? (
                                                            <CardControlledMenuItem
                                                                item={child}
                                                                selectedCard={selectedCard}
                                                                wrapperClassName={
                                                                    child.Link?.jss.value.href === contextItem.url ? 'active' : ''
                                                                }
                                                            />
                                                        ) : (
                                                            <li
                                                                className={
                                                                    child.Link?.jss.value.href === contextItem.url ? 'active' : ((child.Link.jss.value.href.includes(routes.loginAndSecurityRefresh))
                                                                        && (contextItem.url.includes(routes.loginAndSecurity) ||
                                                                            contextItem.url.includes(routes.deleteAccount))) ? 'active' : ''
                                                                }
                                                            >
                                                                {child.Link.jss.value.href.includes(routes.loginAndSecurityRefresh) ?
                                                                    (
                                                                        <PrestoLink field={child.Link?.jss} onClickCustom={() => redirectToUrl(routes.loginAndSecurityRefresh)} />
                                                                    ) :
                                                                    (
                                                                        <PrestoLink field={child.Link?.jss} />
                                                                    )
                                                                }
                                                            </li>
                                                        )}
                                                    </>
                                                )}
                                                {child.WelcomeMessage && (
                                                    <li className="welcome-msg">
                                                        <img src={accountAvatarImg} alt="Account Avatar" />
                                                        <h2 style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                                                            {child.WelcomeMessage?.jss.value}
                                                            {userInfo.firstName?.length ? (
                                                                <div className={userInfo.firstName.length > 12 ? 'isSmall' : ''}>
                                                                    {firstNameUpdatePersonalInfo ? firstNameUpdatePersonalInfo : userInfo.firstName}!
                                                                </div>
                                                            ) : null}
                                                        </h2>
                                                    </li>
                                                )}
                                                {child.ShowDivider?.jss.value === true &&
                                                    showElement(
                                                        child.ShowForPrestoCard?.jss.value === true,
                                                        child.ShowForContactlessCard?.jss.value === true
                                                    ) ? (
                                                    <li aria-hidden="true" className="divider">
                                                        <hr />
                                                    </li>
                                                ) : null}
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ))}
                </div>
            </div>,
        ]
        : null;
};

export default withTranslation()(SideNavigation);
