export default {
  dashboard: '/account-dashboard',
  checkout: '/my-products/checkout',
  loadMyCard: '/my-products/load-my-card',
  transitPasses: '/my-products/transit-passes',
  transactionHistory: '/my-products/transaction-history',
  unpaidFares: '/my-products/unpaid-fares',
  contactlessUnpaidFares: '/contactless-dashboard/ContactlessUnpaidFare',
  contactlessTransactionHistory: '/Contactless-dashboard',
  addCard: '/account/add-card',
  searchResults: '/search-results?keywords=',
  manageMyCards: '/account/manage-my-cards',
  workInProgress: '/support/modernizing-presto/work-in-progress',
  tooManyRequests: '/too-many-requests',
  technicalDifficulties: '/technical-difficulties',
  contactlessHomePage: '/Contactless-Home-page',
  orderNewCard: '/order-a-new-card',
  createAccount: '/create-an-account',
  signIn: '/B2CRedirect/B2CRegisteredSignIn',
  loginAndSecurityRefresh: '/B2CRedirect/B2CRegisteredSignIn?isLoginAndSecurity=true',
  accountExistPassword: '/B2CAccountCreation/AccountExistPassword',
  accountVerification: '/B2CAccountCreation/AccountVerification',
  b2cAccountCreation: '/B2CAccountCreation/personal-information',
  b2cRegisteredSignIn: '/B2CRedirect/B2CRegisteredSignIn',
  b2cRegisterAccount: '/B2CRedirect/B2CRegisterAccount',
  b2cAccountExists: '/B2CRedirect/B2CAccountExists',
  b2cChangeEmail: '/B2CRedirect/B2CChangeEmail',
  b2cChangePassword: '/B2CRedirect/B2CChangePassword',
  b2cPrestoCardSignIn: '/B2CRedirect/B2CPrestoCardSignIn',
  b2cSignOut: '/B2CRedirect/B2CSignOut',
  b2cGuestSignIn: '/B2CRedirect/B2CGuestSignIn',
  reportLostCard: '/my-products/report-lost-card',
  goDefaultTrip: '/go-default-trip',
  walletCardDetails: '/my-products/card-details',
  b2cEnrollMFA: '/B2CRedirect/MFA/B2CMFAEnroll',
  b2cUpdateMFA: '/B2CRedirect/MFA/B2CMFAUpdate',
  b2cDisableMFA: '/B2CRedirect/MFA/B2CMFADisable',
  b2cUpdateMFANotification: '/B2CRedirect/MFA/B2CMFANotification',
  b2cPasswordMFANotification: '/B2CRedirect/MFA/B2CPasswordNotification',
  b2cMfaMaxRetryExceeds: '/B2CRedirect/B2CRegisteredSignIn?IsMaxTryExceeds=true',
  loginAndSecurity: '/account/Login-and-Security',
  deleteAccount: '/account/delete-account',
};
