export default function handleAVSValidationError(queryParams, t) {
  if (!queryParams) {
    return;
  }
  if (queryParams.AVSResponse !== undefined && queryParams.AVSResponse.toUpperCase() === 'FALSE') {
    if (
      queryParams.CVVResponse !== undefined &&
      queryParams.CVVResponse.toUpperCase() === 'FALSE'
    ) {
      // eslint-disable-next-line consistent-return
      return t('directPaymentCouldNotBeProcessed');
    }

    // eslint-disable-next-line consistent-return
    return t('transactionDeclinedAVS');
  }

  if (queryParams.CVVResponse !== undefined && queryParams.CVVResponse.toUpperCase() === 'FALSE') {
    // eslint-disable-next-line consistent-return
    return t('directPaymentCouldNotBeProcessed');
  }
  if (
    queryParams.ResponseCode !== undefined &&
    queryParams.ResponseCode.toUpperCase() === 'FALSE'
  ) {
    // eslint-disable-next-line consistent-return
    return t('directPaymentCouldNotBeProcessed');
  }

  // eslint-disable-next-line consistent-return
  return t('directPaymentCouldNotBeProcessed');
}
