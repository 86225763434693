import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatDecimalWithCurrency, redirectToUrl, sortItems } from '../../services/utils';
import routes from '../../constants/routes';
import cardInfo from '../../constants/cardInfo';
import {
  removeFromShoppingCart,
  addShoppingCartMessage,
  setGuestShoppingCartToken,
  setGoBackURL,
} from '../../redux/user/actions';
import Success from '../Success';
import { Button } from '../../atoms';
import './shoppingcart.scss';
import api from '../../services/api';

const ShoppingCart = ({ forwardRef, isOpenShoppingCart, closeShoppingCart, t }) => {
  const visibleId = useSelector((state) => state.user.selectedCard.visibleId);

  const fareMedias = useSelector((state) => state.user.fareMedias);
  const fareMediaVibleIdList = fareMedias.map((fareMedia) => fareMedia.visibleId);
  const cartItems = useSelector((state) => state.user.shoppingCart.items);
  const cartItemsWithoutMedia = cartItems.filter(
    (item) => !fareMediaVibleIdList.includes(item.visibleId)
  );
  const messages = useSelector((state) => state.user.shoppingCart.addMessage);
  const subTotal = cartItems ? cartItems.reduce((acc, val) => acc + val.subTotal, 0) : 0;
  const customerId = useSelector((state) => state.user.customerId);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const removeItem = (orderLineId) => {
    const data = {
      customerId,
      mediaId: visibleId,
      orderLineId,
      Sct: guestUserInfo?.isGuestLogin ? guestSCT?.sct : guestSCT?.sisct,
      Cct: guestUserInfo?.isGuestLogin ? guestSCT?.cct : '',
      IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
      IsAnonymousUser: anonymous ? true : false,
    };
    dispatch(removeFromShoppingCart(data));
    dispatch(addShoppingCartMessage(''));
  };

  useEffect(() => {
    let arr = pathname.split('/');
    let firstel = arr.shift();
    let secondel = arr.shift();
    let actualpath = '/' + arr.join('/');

    if (actualpath !== routes.checkout) {
      dispatch(setGoBackURL(actualpath));
    }
  }, []);

  const checkoutItemsHandler = () => {
    let payload = {
      customerId,
      VisibleId: 'NEWMEDIA',
      mediaId: visibleId,
      SctToken: guestSCT?.sct,
      SisctToken: guestSCT?.sisct,
      CctToken: '',
      IsGuestFlow: guestUserInfo?.isGuestLogin ? true : false,
    };

    // api
    //   .buyAprestoCardCommenceCheckOut(payload)
    //   .then((res) => {
    //     dispatch(setGuestShoppingCartToken(res.data.Cookies));
    //     redirectToUrl(routes.checkout);
    //   })
    //   .catch(() => {});
    redirectToUrl(routes.checkout);
  };
  const CartItem = ({ item, isEmpty }) => (
    <div className="shoppingcart-item--wrapper">
      <div className="shoppingcart-item">
        <div className={`shoppingcart-product${isEmpty ? ' regular-font' : ''}`}>
          {isEmpty ? t('shoppingCartCartEmpty') : item.name}
        </div>
        <div className="shoppingcart-quantity">{isEmpty ? '-' : item.quantity}</div>
        <div className="shoppingcart-price">
          {isEmpty ? '-' : `${formatDecimalWithCurrency(item.subTotal)}`}
        </div>
      </div>
      {!isEmpty && (
        <div className="shoppingcart-remove">
          <div
            role="link"
            tabIndex="0"
            aria-label={`${t('shoppingCartRemove')} ${item?.name}`}
            onClick={() => removeItem(item.orderLineId)}
            onKeyPress={() => null}
          >
            {t('shoppingCartRemove')}
          </div>
        </div>
      )}
    </div>
  );

  const CartHeader = ({ nickName, isEmpty }) => (
    <>
      {!isEmpty && <div className="shoppingcart-nickname">{nickName}</div>}
      <div className="shoppingcart-title">
        <div className="shoppingcart-product">{t('shoppingCartItem')}</div>
        <div className="shoppingcart-quantity">{t('shoppingCartQuantity')}</div>
        <div className="shoppingcart-price">{t('shoppingCartPrice')}</div>
      </div>
    </>
  );
  let isAllSameGroup = cartItemsWithoutMedia.every((item) => item.visibleId === cardInfo.NEWMEDIA);
  cartItems.sort(sortItems('name'));
  return (
    <>
      {isOpenShoppingCart && (
        <>
          <div
            className="shoppingcart-background-layer"
            role="presentation"
            onClick={closeShoppingCart}
            onKeyDown={closeShoppingCart}
          />
          <div ref={forwardRef} className="shoppingcart-wrapper">
            <div className="shoppingcart">
              {messages && messages.map((msg) => (msg ? <Success title={{ value: msg }} /> : null))}
              <div className="shopping-cart-content">
                {!cartItems.length ? (
                  <>
                    <CartHeader isEmpty />
                    <div className="shoppingcart-items">
                      <CartItem isEmpty />
                    </div>
                  </>
                ) : (
                  <>
                    {cartItemsWithoutMedia &&
                      cartItemsWithoutMedia.length > 0 &&
                      cartItemsWithoutMedia.sort(sortItems('nickName')).map((item, index) => {
                        return (
                          <>
                            {isAllSameGroup && index === 0 ? (
                              <CartHeader
                                nickName={item.nickName ? item.nickName : t('FareMediaNoNickname')}
                              />
                            ) : null}
                            <div className="shoppingcart-items">
                              <CartItem item={item} />
                            </div>
                          </>
                        );
                      })}
                    {fareMedias &&
                      fareMedias.sort(sortItems('nickName', true)).map((fareMedia) => (
                        <>
                          {cartItems.filter((i) => i.visibleId === fareMedia.visibleId).length ? (
                            <>
                              <CartHeader nickName={fareMedia.nickName} />
                              <div className="shoppingcart-items">
                                {cartItems
                                  .filter((items) => items.visibleId === fareMedia.visibleId)
                                  .sort(sortItems('name'))
                                  .map((item) => (
                                    <CartItem item={item} />
                                  ))}
                              </div>
                            </>
                          ) : null}
                        </>
                      ))}
                  </>
                )}
              </div>
              <div className="shoppingcart-subtotal">
                {t('shoppingCartSubtotal')} {formatDecimalWithCurrency(subTotal)}
              </div>
              <div className="shoppingcart-checkout">
                <Button
                  isDisabled={!cartItems.length}
                  onClick={() => checkoutItemsHandler()}
                  onKeyPress={() => null}
                >
                  {t('shoppingCartCheckout')}
                </Button>
              </div>
              <div className="shoppingcart-close">
                <Button white onClick={closeShoppingCart}>
                  {t('shoppingCartClose')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShoppingCart;
