import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { showScreenHeader } from '../../redux/user/actions';
import { ButtonBlock, PrintButton } from '../../atoms';
import { Success, InlineButtonBlock } from '../../molecules';
import { formatDecimalWithCurrency, redirectToUrl, formatDate } from '../../services/utils';

export default function ModifyAutoload3({ fields, state, backToMyCardButtonLink }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showScreenHeader(false));
  }, []);
  return (
    <div className="autoload-success-wrapper">
      <h1 tabIndex="0" className="autoload-header">
        <Text field={fields.heading} />
      </h1>
      <Success
        Content={() => (
          <>
            <div
              className="success-block-title"
              role="main"
              aria-label={fields.successMessageA11y.value}
            >
              <Text field={fields.successMessage} />
            </div>
            <div className="autoload-setup autoload-success">
              <div
                className="autoload-description"
                role="main"
                aria-label={fields.successMessageTextA11y.value}
              >
                <RichText field={fields.successMessageText} />
              </div>
              <div className="setup-section">
                <div className="section-details-amount no-top-border">
                  <div role="main" aria-label={fields.amountLabelA11y.value}>
                    <Text field={fields.amountLabel} />
                  </div>
                  <div className="amount">{formatDecimalWithCurrency(state.loadAmount)}</div>
                </div>
                <div className="section-details-amount no-top-border">
                  <div role="main" aria-label={fields.oldDropLabelA11y.value}>
                    <Text field={fields.oldDropLabel} />
                  </div>
                  <div className="amount">{formatDecimalWithCurrency(state.thresholdAmount)}</div>
                </div>
                <div className="section-details-amount no-top-border">
                  <div role="main" aria-label={fields.setupDateLabelA11y.value}>
                    <Text field={fields.setupDateLabel} />
                  </div>
                  <div className="amount">{state.transactionDate || formatDate(Date.now())}</div>
                </div>
              </div>
              <div
                role="main"
                className="autoload-description"
                aria-label={fields.emailAnnotationA11y.value}
              >
                <Text field={fields.emailAnnotation} />
              </div>
              <InlineButtonBlock
                onClick={() => {
                  //dispatch(showScreenHeader(true));
                  redirectToUrl(backToMyCardButtonLink);
                }}
                buttonText={fields.backToCardButtonText}
                buttonTextA11y={fields.backToCardButtonTextA11y.value}
              />
            </div>
          </>
        )}
      />
      <ButtonBlock>
        <PrintButton />
      </ButtonBlock>
    </div>
  );
}