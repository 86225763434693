import React, { useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import handleServerError from '../../services/handleServerError';
import { InlineButtonBlock, TextInput, Error, Note } from '../../molecules';

export default function ForgotPassword3({ fields, toNextStep, t, state, setIsServerError }) {
  const [error, setIsError] = useState('');

  return (
    <>
      {error ? <Error title={error} /> : null}
      <div className="forgot-content">
        <div className="forgot-step">
          <div className="password-info-title">
            <Text field={fields.passwordInfoTitle} />
          </div>
          <div className="password-info">
            <RichText field={fields.passwordInfo} />
          </div>
          <Note
            Title={() => (
              <span>
                <Text field={fields.noteTitle} />
                <br />
              </span>
            )}
          />

          <Formik
            enableReinitialize
            initialValues={{
              Password: '',
              ConfirmNewPassword: '',
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              Password: Yup.string()
                .required(fields.enterNewPassword.value)
                .min(8, t('forgotPasswordNewPasswordValidation'))
                .matches(
                  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])((?=.*\d)|(?=.*[@$!%*#?&\'\"<>()+,”]))[A-Za-z\d@$!%*#?&\'\"<>()+,”]{8,25}$/,
                  t('forgotPasswordNewPasswordValidation')
                ),
              ConfirmNewPassword: Yup.string()
                .required(fields.enterConfirmPassword.value)
                .oneOf([Yup.ref('Password'), null], t('forgotPasswordPaswordsNotMatched')),
            })}
            onSubmit={(values) => {
              api
                .resetPassword({
                  Password: values.Password,
                  encryptedSecurityKeyToken: state.encryptedSecurityKeyToken,
                })
                .then(({ data }) => {
                  if (data.Success) {
                    toNextStep();
                  } else {
                    handleServerSuccessError(data.Error, setIsError);
                  }
                }, handleServerError(setIsServerError, setIsError));
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="Password"
                  type="password"
                  errors={errors}
                  touched={touched}
                  label={fields.newPasswordLabel}
                  length={25}
                />

                <TextInput
                  name="ConfirmNewPassword"
                  type="password"
                  errors={errors}
                  touched={touched}
                  label={fields.confirmPasswordLabel}
                  length={25}
                />

                <InlineButtonBlock type="submit" buttonText={fields.buttonSubmit} />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
