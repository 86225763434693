import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './imageWithParagraph.scss';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

import {
  setGuestUserEnabled,
  setGuestSignPopupEnabled,
  setGuestSignPopupEnabledfromOrdercard,
} from '../../redux/user/actions';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { is } from '../../services/utils';
import useMediaWidth from '../../hooks/useMediaWidth';

const ImageWithParagraph = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const [isLoaded, setIsLoaded] = useState(false);
  let [showImage, setShowImage] = useState(true);
  const isMobile = useMediaWidth();
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);

  useEffect(() => {
    let hasUserInfo = userInfo.customerId ? true : false;
    if (document.getElementsByClassName('order_new_card_image_para').length > 0 && !hasUserInfo) {
      // guestuser
      setShowImage(true);
      dispatch(
        setGuestUserEnabled({
          isGuestLogin: true,
        })
      );
    } else if (
      document.getElementsByClassName('order_new_card_image_para').length > 0 &&
      hasUserInfo
    ) {
      // registereduser
      setShowImage(false);
    } else if (!document.getElementsByClassName('order_new_card_image_para').length > 0) {
      // staticpages
      setShowImage(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (guestUserInfo?.isGuestLogin) {
      const element = document.getElementById('orderacardguestsignin');
      if (element !== null) {
        element.addEventListener('click', function () {
          dispatch(setGuestSignPopupEnabled({ enablePopup: true }));
          dispatch(setGuestSignPopupEnabledfromOrdercard(true));
        });

        element.addEventListener('keydown', function (e) {
          if (e.code === 'Enter') {
            dispatch(setGuestSignPopupEnabled({ enablePopup: true }));
            dispatch(setGuestSignPopupEnabledfromOrdercard(true));
          }
        });
      }
    }
  }, [guestUserInfo]);

  const getHeading = (tag) => {
    if (tag === 'h2') {
      return 'h2';
    }
    if (tag === 'h3') {
      return 'h3';
    }
    return 'h1';
  };
  const hasTitle = props?.fields?.Title.value.length > 0;
  const hasDescription = props?.fields?.Description.value.length > 0;
  const setImageAtBottom = useMemo(() => {
    if (is(props?.params?.SetImageAtBottom)) {
      return Number(props.params.SetImageAtBottom) === 1;
    }
    return false;
  }, [props.params]);
  useDidMountEffect(() => {
    setIsLoaded(true);
  }, [props]);
  const imageContainer = () => [
    <div className="row instructionimage instructionimage-md">
      <Image field={props?.fields?.Image} loading="lazy" />
    </div>,
    <div className="instructionimage-sm">
      <Image
        field={
          props?.fields?.MobileImage !== '' ? props?.fields?.MobileImage : props?.fields?.Image
        }
        loading="lazy"
      />
    </div>,
  ];
  if (!isLoaded) return null;
  return !showImage ? null : (
    <div
      className={
        props?.fields?.isWhiteBackground?.value ? 'image-with-paragraph-white-background' : ''
      }
    >
      <div
        className={
          props?.fields?.WrapperCssClass && props?.fields?.WrapperCssClass?.value
            ? props?.fields?.WrapperCssClass?.value
            : ''
        }
      >
        <div
          className={
            props?.fields?.CssClass && props?.fields?.CssClass?.value === 'vertical'
              ? `instruction_Container_Vertical`
              : props?.fields?.CssClass?.value
              ? props?.fields?.CssClass?.value
              : `instruction__container`
          }
        >
          {!isMobile && !setImageAtBottom ? imageContainer() : null}
          {(hasTitle || hasDescription) && (
            <div className="text-container">
              {hasTitle && (
                <div className="row titleinstruction">
                  <Text
                    tag={getHeading(props?.fields?.SubHeadingTag?.fields?.HeadingTagTitle?.value)}
                    className="display-4"
                    field={props?.fields?.Title}
                  />
                </div>
              )}
              {hasDescription && (
                <div className="row instructionContainer">
                  <RichText field={props?.fields?.Description} />
                </div>
              )}
            </div>
          )}
          {isMobile || setImageAtBottom ? imageContainer() : null}
        </div>
        <Text field={props?.fields?.heading} />
      </div>
    </div>
  );
};

export default withTranslation()(ImageWithParagraph);
