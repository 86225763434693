import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';

import { Select, CardBlock, Error, Note } from '../../molecules';
import { isFrench } from '../../services/utils';
import { Button, Loader, IconButton } from '../../atoms';
import './transitUsageReport.scss';
import api from '../../services/api';
import exportIcon from '../../assets/images/Terms-And-Conditions.svg';

const TransitUsageReport = (props) => {
  const user = useSelector((reduxState) => reduxState.user);
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.visibleId === reduxState.user.selectedCard.visibleId)
  );
  const [loading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [flag, setFlag] = useState({ exportCsv: false, errorMsg: false });
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [fileName, setFileName] = useState('');
  const [pdfName, setPdfName] = useState('');
  const all = props.fields.AllNote.value;
  const transitPasses = props.fields.TransitPassNote.value;
  const trips = props.fields.TripsNote.value;
  const configKeys = useSelector((state) => state.settings.configKeys);
  const optionsYear = Array.from({ length: 8 }, (_, i) => moment().year() - 7 + i);
  if (moment().year() !== 2023) optionsYear.unshift(moment().year() - 1);
  optionsYear.shift();
  optionsYear.sort((a, b) => b - a);
  const optionsTransitUsage = [
    { label: all, value: '0' },
    { label: trips, value: '1' },
    { label: transitPasses, value: '2' },
  ];
  const langCode = isFrench();
  const id = user.customerId.toString();

  const [customerData, setCustomerData] = useState([]);
  let [turPdf, setTurPdf] = useState({});
  const before2017 = Array.from({ length: 4 },(_, i) => Math.min(...optionsYear) - i - 1);
  const [countCsv, setCountCsv] = useState(0);
  let [turPdfbefore2017, setTurPdfbefore2017] = useState([]);
  const title = 'Transit Usage Report';

  let [csvHeader, setCsvHeader] = useState('');

  useEffect(() => {
    if (langCode) {
      const frenchheader = configKeys
        .filter((item) => item.key === 'TurHeaders.French')
        .map((config) => config.setting);
      setCsvHeader(frenchheader);
    } else {
      const englishheader = configKeys
        .filter((item) => item.key === 'TurHeaders.English')
        .map((config) => config.setting);
      setCsvHeader(englishheader);
    }
  }, []);
  useEffect(() => {
    let test1 = [];
    let count = 0;
    let countcsv = 0;
    before2017.map((eachYear) => {
      countcsv++;
      api
        .getTransitUsageReportView({
          CustomerId: user?.customerId,
          Year: eachYear,
          PeriodID: 0,
          LanguageCode: 1033,
          ChildCustomerID: fareMedia?.ChildCustomerID,
        })
        .then((response) => {
          if (response?.data?.Success && response.data.transitUsageReportList.length !== 0) {
            count++;
            api
              .GetTransitUsageReportPdf({
                CustomerId: user?.customerId,
                Year: eachYear,
                TransactionCategoryID: 0,
                LanguageID: 1033,
                CustomerName: `${user?.firstName || ``} ${user?.lastName || ``}`,
              })
              .then((res) => {
                let path = { value: res.data.TURPDFPath, year: eachYear };
                test1.push(path);
                if (test1.length === count) {
                  test1.sort((a, b) => (a.eachYear > b.eachYear ? -1 : 1));
                  setTurPdfbefore2017(test1);
                }
              });
          }
        });
    });
    setCountCsv(countcsv);
  }, []);

  function selectFewerProps(show) {
    const {
      TransactionDateTime,
      TransaitAgency,
      TransactionLocation,
      TransactionType,
      Amount,
    } = show;
    return { TransactionDateTime, TransaitAgency, TransactionLocation, TransactionType, Amount };
  }

  const exportCsv = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    let headers = csvHeader.toString();
    csvContent += `${headers}\r\n`;

    let rowData = customerData.reduce((previous, next) => {
      let options = [];

      let TransactionDateTime = next.TransactionDateTime;
      let TransaitAgency = next.TransaitAgency;
      let TransactionLocation = next.TransactionLocation;
      let TransactionType = next.TransactionType;
      let Amount;
      if (next.Amount > 0) {
        Amount = `$${next.Amount.toFixed(2)}`;
      } else {
        Amount = `-$${Math.abs(next.Amount).toFixed(2)}`;
      }

      options.push(
        ...[TransactionDateTime, TransaitAgency, TransactionLocation, TransactionType, Amount]
      );
      previous.push(options);

      return previous;
    }, []);

    csvContent += rowData.map((e) => e.join(',')).join('\n');

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('id', 'csv_download_link');
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    let elem = document.getElementById('csv_download_link');
    elem.parentNode.removeChild(elem);
  };

  const exportPDF = () => {
    let data = new Blob([turPdf], { type: 'application/octet-stream' });
    let pdfURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = pdfURL;
    tempLink.setAttribute('download', pdfName);
    tempLink.click();
  };

  const getFullName = (fn, ln) => { let fullName = ''; if (fn) fullName += `${fn} `; if (ln) fullName += `${ln} `; return fullName.slice(0, -1); };

  const getApiDetails = (formValues) => {
    setIsLoading(true);
    let payload = {
      CustomerId: user?.customerId,
      Year: formValues?.selectYear,
      PeriodID: formValues?.transitUsage,
      LanguageCode: 1033,
      ChildCustomerID: fareMedia?.ChildCustomerID,
    };
    let initialfileName = `TUR_${formValues?.selectYear.toString()}_${id}.csv`;
    setFileName(initialfileName);
    api
      .getTransitUsageReportView(payload)
      .then((response) => {
        if (response.status === 200 && response?.data?.transitUsageReportList?.length > 0) {
          setCustomerData(response.data.transitUsageReportList);
          setFlag({ exportCsv: true, errorMsg: false });
        } else {
          setFlag({ exportCsv: false, errorMsg: true });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
    api
      .GetTransitUsageReportPdf({
        CustomerId: user?.customerId,
        Year: formValues?.selectYear,
        TransactionCategoryID: formValues?.transitUsage,
        LanguageID: 1033,
        CustomerName: getFullName(user?.firstName, user?.lastName),
        ChildCustomerID: fareMedia?.ChildCustomerID,
      })
      .then((response) => {
        if (response.status === 200) {
          let blob = response.data;
          let filename = response.headers['content-disposition'].split('=')[1];
          setPdfName(filename);
          setTurPdf(blob);
          setDownloadPdf(true);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return [
    <div className={langCode ? 'transitusagewrapper-fr' : 'transitusagereport-wrapper'}>
      {flag.errorMsg && <Error small title={props?.fields?.ErrYearSelectLabel?.value} />}
      <div className="tur-note">
        <Note withIcon>
          <RichText field={props.fields.TransitUsageNote} />
        </Note>
      </div>
      <div className="after-note">
        <CardBlock>
          <div className="getreports-section">
            <div className="transitusage-subnote">
              <RichText field={props?.fields?.TransitUsageSubNote} />
            </div>
            <Formik
              initialValues={{
                selectYear: '',
                transitUsage: optionsTransitUsage.length ? optionsTransitUsage[0].value : '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.selectYear) {
                  errors.selectYear = props.fields.SelectyearErrLabel.value;
                }
                if (Object.prototype.hasOwnProperty.call(errors, 'selectYear')) {
                  setFormError(true);
                } else {
                  setFormError(false);
                }
                return errors;
              }}
              onSubmit={(values) => {
                getApiDetails(values);
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="reports-selection">
                      <span className="selectdropdown1">
                        <div className="select-year">
                          <Select
                            label={props.fields.SelectYearLabel.value}
                            name="selectYear"
                            placeholder={props.fields.SelectYearLabel.value}
                            errors={errors}
                            touched={touched}
                            options={optionsYear. map(year => ({ label: year.toString(), value: year.toString() }))}
                            labelA11y={props.fields.SelectYearLabelA11y.value}
                            showPlaceholder
                          />
                        </div>
                      </span>
                      <span className="selectdropdown2">
                        <Select
                          label={props.fields.TransitUsagetext.value}
                          name="transitUsage"
                          errors={errors}
                          touched={touched}
                          options={optionsTransitUsage}
                          showOption0
                          labelA11y={props.fields.TransitUsagetextA11y.value}
                        />
                      </span>
                      <div className="get-report-button">
                        <Button type="submit" isDisabled={formError}>
                          <Text field={props?.fields?.GetButtonLabel} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {flag.exportCsv ? (
            <div>
              <div id="reportavailable-label">
                <Text field={props?.fields?.ReportAvailableLabel} />
                <div className="button-label">
                  {downloadPdf && (
                    <div className="download-pdf">
                      {/* <button onClick={exportPDF}>{props?.fields?.DownloadPDFlabel.value}</button> */}
                      <IconButton onClick={exportPDF} icon={exportIcon} tabIndex="0">
                        {props?.fields?.DownloadPDFlabel.value}
                      </IconButton>
                    </div>
                  )}
                  {flag.exportCsv && (
                    <div className="export-csv">
                      {/* <button className="export-csv-label" onClick={exportCsv}>
                        <Text field={props?.fields?.ExportcsvLabel} />
                      </button> */}
                      <IconButton
                        className="export-csv-label"
                        onClick={exportCsv}
                        icon={exportIcon}
                        tabIndex="0"
                      >
                        <Text field={props?.fields?.ExportcsvLabel} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </CardBlock>
      </div>
      <div>
        {countCsv === 4 && turPdfbefore2017.length !== 0 ? (
          <CardBlock title={props.fields.AvailableReportsNote.value}>
            {turPdfbefore2017.map((eachYear, index) => {
              return (
                <>
                  <div
                    className={`downloadpdf-wrapper ${index == turPdfbefore2017.length - 1 ? 'disable-border' : ''
                      }`}
                  >
                    <div className="tur-title">
                      {title} {eachYear.year}
                    </div>
                    <div className="download-label">
                      <a className="download-text" href={eachYear.value} target="_blank">
                        {props?.fields?.DownloadPDFlabel.value}
                      </a>
                    </div>
                  </div>
                </>
              );
            })}
          </CardBlock>
        ) : null}
      </div>
      <div className="availablereports-section">
        <div className="transitusage-endnote">
          <RichText field={props?.fields?.TransitUsageEndnote} />
        </div>
      </div>
    </div>,
    loading ? <Loader /> : null,
  ];
};

export default TransitUsageReport;
