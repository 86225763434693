import React from 'react';
import './valueRowSecond.scss';

export default function ValueRowSecond({
  left,
  right,
  rightBold = false,
  leftBold = false,
  disableTopPadding = false,
  addBottomBorder = false,
}) {
  let cssClasses = 'value-row';
  cssClasses += addBottomBorder ? ' addBottomBorder' : '';
  cssClasses += disableTopPadding ? ' disableTopPadding' : '';

  return (
    <div className={cssClasses}>
      <div className={`left-cell${leftBold ? ' isBold' : ''}`}>{left}</div>
      <div className={`right-cell${rightBold ? ' isBold' : ''}`}>{right}</div>
    </div>
  );
}
