import React, { useRef, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setLanguage, togglePageScroll, isFrench, redirectToUrl, checkTokenExpiry, getRefreshToken, localstorageService } from '../../services/utils';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useMediaWidth from '../../hooks/useMediaWidth';
import accountIcon from '../../assets/images/Account.svg';
import OpenSideNavImage from '../../assets/images/HamburgerMenu.svg';
import CloseSideNavImage from '../../assets/images/HamburgerMenuClose.svg';
import accountIconActive from '../../assets/images/Account_Active.svg';
import { useIdleTimer } from 'react-idle-timer';
import mediaSubTypes from '../../constants/mediaSubTypes';
import { PrestoLink, NavLink, IconButton, Loader } from '../../atoms';
import langConstants from '../../constants/lang';
import {
  HeaderSearch,
  HeaderSearchMobile,
  ShoppingCart,
  CardControlledMenuItem,
} from '../../molecules';
import TopNavigationCategoryItem from '../TopNavigation/topNavigationCategoryItem';
import downIcon from '../../assets/images/down.svg';
import SessionTimeoutModal from '../TopNavigationAuthenticated/SessionTimeoutModal';
import '../TopNavigationAuthenticated/topNavigationAuthenticated.scss';
import './topNavigationAnonymous.scss';
import {
  addShoppingCartMessage,
  emptyShoppingCartMessage,
  removeNewItemFlag,
  selectCard,
} from '../../redux/user/actions';
import usePrevious from '../../hooks/usePrevious';
import shoppingCartIcon from '../../assets/images/ShoppingCart.svg';
import handleLogOut from '../../services/handleLogOut';
import api from '../../services/api';
import localStorageKeys from '../../constants/localStorageKeys';
import routes from '../../constants/routes';

const TopNavigationAnonymous = (props) => {
  const loggedInTime = useSelector((state) => state.user.loggedInTime);
  const loggedOutTime = useSelector((state) => state.user.logOutTime);

  const isAnonymousAuthenticated = useSelector((state) => state.user.anonymous);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const PathParams = useLocation().pathname.toLowerCase();
  const location = useLocation();

  const { fareMedias, contactlessMedias } = useSelector((state) => state.user);
  const isMobile = useMediaWidth();
  const { datasource, contextItem } = props.fields.data ? props.fields.data : {};
  const allCards = fareMedias.concat(contactlessMedias);
  const dropdownRef = useRef(null);
  const [active, setActive] = useDetectOutsideClick(dropdownRef, false);
  const secondLevelDropdownRef = useRef(null);
  const [secondLevelactive, setsecondLevelActive] = useDetectOutsideClick(
    secondLevelDropdownRef,
    false
  );
  const [activeNavItem, setActiveNavItem] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const urlParams = useParams();

  const mobileMenuRef = useRef(null);
  const [isMenuClicked, setMenuActiv] = useState(false);
  const showMobileMenu = () => setMenuActiv(!isMenuClicked);
  const [langCode, setLangCode] = useState('');
  const dispatch = useDispatch();
  const cartQuantity = useSelector((state) => state.user.shoppingCartQuantity);
  const isNewItem = useSelector((state) => state.user.shoppingCart.isNewItem);
  const shoppingCartRef = useRef(null);
  const userDropdownRef = useRef(null);
  const [isUserActive, setUserActive] = useDetectOutsideClick(userDropdownRef, false);
  const onClickUser = () => setUserActive(!isUserActive);
  const cardSwitcherDropdownRef = useRef(null);
  const [isCardSwitcherActive, setCardSwitcherActive] = useState(false);
  const [isActiveThirdLevelMenu, setIsActiveThirdLevelMenu] = useState(false);
  const [currentLevel, setCurrentLevel] = useState('');
  const [isSmartBannerPresent, setIsSmartBannerPresent] = useState(false);
  const [isSkipTranslateHidden, setIsSkipTranslateHidden] = useState(false);

  const [activeCardIndex, setActiveCardIndex] = useState(null);

  const [isOpenShoppingCart, setOpenShoppingCart] = useDetectOutsideClick(
    shoppingCartRef,
    false,
    true,
    () => {
      dispatch(emptyShoppingCartMessage());
    }
  );

  const clearReduxHistory = () => {
    localstorageService().removeItem('redux');
    localstorageService().removeItem(localStorageKeys.accessToken);
    localstorageService().removeItem(localStorageKeys.accessTokenValidity);
    localstorageService().removeItem(localStorageKeys.accessTokenLifetime);
    localstorageService().removeItem(localStorageKeys.refreshToken);
    localstorageService().removeItem(localStorageKeys.guestToken);
    localstorageService().removeItem(localStorageKeys.uniqueSessionId);
    localstorageService().removeItem(localStorageKeys.gtmContainerId);
    localstorageService().removeItem(localStorageKeys.unpaidFaresData);
    localstorageService().removeItem(localStorageKeys.unpaidFaresReposneData);
    localstorageService().removeItem(localStorageKeys.stsAccessToken);
    localstorageService().removeItem(localStorageKeys.unpaidFaresIndex);
    localstorageService().removeItem(localStorageKeys.paymentsToVerifyData);
    localstorageService().removeItem(localStorageKeys.isRiskyUser);
    localstorageService().removeItem(localStorageKeys.isRiskyUserDismissed);
    api.b2cSignOut();
  };

  const businessRules = useSelector((state) => state.businessRules?.rules) || [];
  const idleTime = businessRules?.IdleTime ? businessRules?.IdleTime : 15;

  useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    events: [
      // 'mousemove',
      'keydown',
      // 'wheel',
      // 'DOMMouseScroll',
      // 'mousewheel',
      'mousedown',
      'touchstart',
      // 'touchmove',
      'MSPointerDown',
      // 'MSPointerMove',
      // 'visibilitychange',
    ],
    onIdle: () => setIsModalOpen(true),
    debounce: 500,
  });
  const handleDOMChanges = () => {
    const skipTranslateElement = document.querySelector('.skiptranslate');
    const smartBanner = document.querySelector('html.smartbanner-show');
    const mobileMenuWrapper = document.querySelector('.mobile-menu-wrapper');


    setIsSmartBannerPresent(!!smartBanner); // Set state based on smartBanner presence
    setIsSkipTranslateHidden(skipTranslateElement && getComputedStyle(skipTranslateElement).getPropertyValue('display') === 'none');
    const targetElement = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate');
    if (mobileMenuWrapper) {
      if (smartBanner && targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '176px';
      } else if (document.querySelector('html.smartbanner-show')) {
        mobileMenuWrapper.style.top = '136px';
      } else if (targetElement && !isSkipTranslateHidden) {
        mobileMenuWrapper.style.top = '96px';
      } else {
        mobileMenuWrapper.style.top = '56px'; // Default value if neither is present
      }
    }

  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleDOMChanges();
    }, 100); // Check every 1 second

    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    const isValidSession = (() => {
      const { isAfter1Hr } = checkTokenExpiry(loggedInTime, loggedOutTime);
      if (isAfter1Hr) {
        // Logging out the user since its been more than 1 hour since they logged in.
        handleLogoutHandler();
      } else {
        // Session is still valid, and user is not idle i.e. no action needed.
      }
    });
    isValidSession();
    const timer = setInterval(isValidSession, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isAnonymousAuthenticated && (PathParams == '/' || PathParams == '/en/' || PathParams == '/fr-CA/' || PathParams == '/en' || PathParams == '/fr-CA')) {
      redirectToUrl(routes.dashboard);
    } else {
      //console.log('not true');
    }
  }, [isAnonymousAuthenticated])

  const handleLogoutHandler = () => {
    setUserActive(!isUserActive);
    // handleLogOut(dispatch);
    setIsLoading(true);
    api
      .logOut({ Token: getRefreshToken() })
      .then(() => {
        clearReduxHistory();
      })
      .catch((e) => {
        setIsLoading(false);
        clearReduxHistory();
        console.warn(e);
      });
  };

  const thirdLevelHandle = (newCurrentLevel) => {
    setCurrentLevel(newCurrentLevel);
    setIsActiveThirdLevelMenu((current) => !current);
  };

  const prevValue = usePrevious(isOpenShoppingCart);
  const isNavigationItemActive = (routeName) => contextItem.url.indexOf(`/${routeName}/`) !== -1;
  useEffect(() => {
    if (prevValue) {
      dispatch(emptyShoppingCartMessage());
    }
  }, [isOpenShoppingCart]);

  useEffect(() => {
    if (isNewItem) {
      setOpenShoppingCart(true);
      setTimeout(() => {
        setOpenShoppingCart(false);
        dispatch(removeNewItemFlag());
        dispatch(addShoppingCartMessage(''));
      }, 3000);
    }
  }, [isNewItem]);

  useEffect(() => {
    togglePageScroll(isMenuClicked);
  }, [isMenuClicked]);

  useEffect(() => {
    setIsLoaded(true);
    let language = isFrench() ? langConstants.longFr : langConstants.en;
    setLangCode(language);
  }, []);

  const changeLanguage = () => {
    let url = { ...urlParams };
    let searchKeyword = location?.search;
    if (searchKeyword) {
      url = { ...url, sitecoreRoute: `${url?.sitecoreRoute}${searchKeyword}` };
    }
    setLanguage(url);
  };

  function redirectMyproductsMobile(card, linkItem) {
    dispatch(
      selectCard(card, {
        url: linkItem.Link?.jss.value.href,
        includeLanguage: false,
        redirected: false,
      })
    );
  }

  return (
    isLoaded && (
      <div data-e2e-id="top-nav-unauth" role="navigation" className="top-nav-anonymous">
        {datasource && (
          <>{isLoading && <Loader />}
            <SessionTimeoutModal
              t={props.t}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
            {!isMobile && (
              <div className="header">
                <div className="header-nav-links">
                  <li role="banner">
                    <PrestoLink
                      field={datasource.LogoLink?.jss}
                      image={datasource.parent.Logo.jss}
                      imageClassName="logo-header"
                    />
                  </li>
                  {datasource.children.map((child) => (
                    <>
                      {!child?.WithCardSwitcher?.jss.value && (
                        <TopNavigationCategoryItem
                          navigationItem={child}
                          contextItem={contextItem}
                        />
                      )}
                    </>
                  ))}
                </div>
                <div>
                  <HeaderSearch />
                  <li>
                    <a
                      role="button"
                      aria-label={props.t('topNavigationLanguageSwitcherA11y')}
                      tabIndex="0"
                      onClick={changeLanguage}
                      onKeyPress={changeLanguage}
                    >
                      {props.t('topNavigationLanguageSwitcher', { lng: langCode })}
                    </a>
                  </li>
                  <li>
                    <div
                      className="cart"
                      role="button"
                      onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                      onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                      tabIndex="0"
                      aria-expanded={isOpenShoppingCart}
                      aria-label={`${props
                        .t('shoppingCartItemsInCartA11y')
                        .replace('{number}', cartQuantity)}`}
                    >
                      <img
                        src={shoppingCartIcon}
                        alt={`${props
                          .t('shoppingCartItemsInCartA11y')
                          .replace('{number}', cartQuantity)}`}
                      />
                      {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                    </div>
                    <ShoppingCart
                      forwardRef={shoppingCartRef}
                      cartQuantity={cartQuantity}
                      isOpenShoppingCart={isOpenShoppingCart}
                      closeShoppingCart={() => setOpenShoppingCart(false)}
                      t={props.t}
                    />
                  </li>
                  <li className="user-icon">
                    <a
                      role="button"
                      aria-expanded={isUserActive}
                      tabIndex="0"
                      aria-label={props.t('topNavigationMyProfileA11y')}
                      onClick={onClickUser}
                      onKeyPress={onClickUser}
                      className={`user 
                  ${isUserActive ? 'dropdown' : ''} 
                  ${isNavigationItemActive('account') ? 'active' : ''}
                  `}
                    >
                      <img
                        src={`${isNavigationItemActive('account') ? accountIconActive : accountIcon
                          }`}
                        alt={props.t('topNavigationMyProfileA11y')}
                      />
                    </a>
                    <ul ref={userDropdownRef} className="dropdown-menu">
                      <li>
                        <PrestoLink field={datasource.AccountLink?.jss} />
                      </li>
                      <li>
                        <a
                          tabIndex="0"
                          role="button"
                          onClick={() => handleLogOut(dispatch)}
                          onKeyPress={() => handleLogOut(dispatch)}
                        >
                          {props.t('topNavigationSignOut', { lng: langCode })}
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              </div>
            )}

            {isMobile && (
              <ul className="header-mobile">
                <PrestoLink
                  field={datasource.LogoLink?.jss}
                  image={datasource.parent.Logo.jss}
                  imageClassName="logo-header-mobile"
                />
                <div className="mobile-menu-right">
                  <div
                    className="cart"
                    role="button"
                    tabIndex="0"
                    aria-expanded={isOpenShoppingCart}
                    aria-label={`${props
                      .t('shoppingCartItemsInCartA11y')
                      .replace('{number}', cartQuantity)}`}
                    onClick={() => setOpenShoppingCart(!isOpenShoppingCart)}
                    onKeyPress={() => setOpenShoppingCart(!isOpenShoppingCart)}
                  >
                    <img
                      src={shoppingCartIcon}
                      alt={`${props
                        .t('shoppingCartItemsInCartA11y')
                        .replace('{number}', cartQuantity)}`}
                    />
                    {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                  </div>
                  <ShoppingCart
                    forwardRef={shoppingCartRef}
                    cartQuantity={cartQuantity}
                    isOpenShoppingCart={isOpenShoppingCart}
                    closeShoppingCart={() => setOpenShoppingCart(false)}
                    t={props.t}
                  />
                  <div className="hamburger">
                    <button
                      type="button"
                      onClick={() => {
                        showMobileMenu();
                      }}
                    >
                      <img
                        alt={
                          !isMenuClicked
                            ? props.t('topNavigationOpenMenuA11y')
                            : props.t('topNavigationCloseMenuA11y')
                        }
                        src={!isMenuClicked ? OpenSideNavImage : CloseSideNavImage}
                      />
                    </button>
                  </div>
                </div>

                <div className={`mobile-menu-wrapper ${isMenuClicked ? 'active' : 'inactive'}`}>
                  <ul
                    ref={mobileMenuRef}
                    className={`dropdown-menu-mobile ${isMenuClicked ? 'active' : 'inactive'}`}
                  >
                    <div
                      className="nav-border"
                      onClick={() => {
                        showMobileMenu();
                      }}
                    />
                    <HeaderSearchMobile />
                    {datasource.children.map((child) => (
                      <ul className="dropdownmenu-mobile-anonymous">
                        {child.WithCardSwitcher?.jss.value && (
                          <>
                            {allCards?.map((card, index) => (
                              <>
                                {/* eslint-disable */}
                                <li
                                  tabIndex="0"
                                  role="button"
                                  className="anonymous-card"
                                  aria-expanded={isCardSwitcherActive && activeCardIndex === index}
                                  onClick={() => {
                                    setActiveCardIndex(index);
                                    setCardSwitcherActive((prevState) =>
                                      activeCardIndex === index ? !prevState : true
                                    );
                                  }}
                                  onKeyPress={() => {
                                    if (activeCardIndex !== index) {
                                      setActiveCardIndex(index);
                                      setCardSwitcherActive(true);
                                    } else {
                                      setActiveCardIndex(null);
                                      setCardSwitcherActive(false);
                                    }
                                  }}
                                >
                                  {/* eslint-enable */}
                                  {/* <div className="card-name">{card.nickName}</div> */}
                                  <div className="card-id">{card.visibleId}</div>
                                  <div>
                                    <IconButton
                                      tabIndex="-1"
                                      icon={downIcon}
                                      iconAlt={
                                        isCardSwitcherActive && activeCardIndex === index
                                          ? props.t('expandA11y')
                                          : props.t('collapseA11y')
                                      }
                                      className={
                                        isCardSwitcherActive && activeCardIndex === index
                                          ? 'up'
                                          : ''
                                      }
                                    />
                                  </div>
                                </li>
                                {isCardSwitcherActive && activeCardIndex === index ? (
                                  <ul
                                    ref={cardSwitcherDropdownRef}
                                    className="dropdown-third-level"
                                  >
                                    {child.children?.map((grandchild) => (
                                      <>
                                        {grandchild.Link && (
                                          <CardControlledMenuItem
                                            item={grandchild}
                                            selectedCard={card}
                                            wrapperClassName="third-level-link"
                                            onClickCustom={() => {
                                              redirectMyproductsMobile(card, grandchild);
                                            }}
                                          />
                                        )}
                                      </>
                                    ))}
                                  </ul>
                                ) : null}
                              </>
                            ))}
                          </>
                        )}
                      </ul>
                    ))}
                    {datasource.children.map((child) => (
                      <li>
                        {child.Link && child.children.length === 0 && (
                          <PrestoLink field={child.Link?.jss} />
                        )}
                        {(child.Title || (child.Link && child.children.length > 0)) && (
                          <>
                            {child.Title && !child.WithCardSwitcher && (
                              <NavLink
                                title={child.Title?.value}
                                onClick={(title, isActive) => {
                                  if (isActive) {
                                    setActiveNavItem('');
                                  } else {
                                    setActiveNavItem(title);
                                  }
                                }}
                                isActive={activeNavItem === child.Title?.value}
                                isDropdown={activeNavItem === child.Title?.value}
                                mobile
                              />
                            )}
                            {child.Link && !child.WithCardSwitcher && child.children.length > 0 && (
                              <NavLink
                                title={child.Link?.jss?.value?.text}
                                onClick={(title, isActive) => {
                                  if (isActive) {
                                    setActiveNavItem('');
                                  } else {
                                    setActiveNavItem(title);
                                  }
                                }}
                                isActive={activeNavItem === child.Link?.jss?.value?.text}
                                isDropdown={activeNavItem === child.Link?.jss?.value?.text}
                                mobile
                              />
                            )}
                            <ul ref={dropdownRef} className="dropdown-secondlevelmenu-mobile">
                              {child.WithCardSwitcher === null &&
                                child.children?.map((grandchild) => (
                                  <li>
                                    {grandchild.Link && grandchild.children.length === 0 && (
                                      <PrestoLink field={grandchild.Link?.jss} />
                                    )}
                                    {(grandchild.Title ||
                                      (grandchild.Link && grandchild.children.length > 0)) && (
                                        <>
                                          {grandchild.Title && (
                                            <NavLink
                                              title={grandchild.Title?.value}
                                              onClick={setsecondLevelActive}
                                              isDropdown={
                                                secondLevelactive === grandchild.Title?.value
                                              }
                                              mobile
                                            />
                                          )}
                                          {grandchild.Link && grandchild.children.length > 0 && (
                                            <NavLink
                                              title={grandchild.Link?.jss?.value?.text}
                                              onClick={(newCurrentLevel) =>
                                                thirdLevelHandle(newCurrentLevel)
                                              }
                                              isDropdown={
                                                secondLevelactive ===
                                                grandchild.Link?.jss?.value?.text
                                              }
                                              mobile
                                            />
                                          )}
                                          {grandchild.Link?.jss?.value?.text === currentLevel ? (
                                            <ul
                                              ref={secondLevelDropdownRef}
                                              className="dropdown-thirdlevelmenu-mobile"
                                            >
                                              {isActiveThirdLevelMenu &&
                                                grandchild.children?.map((grandgrandchild) => (
                                                  <li>
                                                    <PrestoLink field={grandgrandchild.Link?.jss} />
                                                  </li>
                                                ))}
                                            </ul>
                                          ) : null}
                                        </>
                                      )}
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                      </li>
                    ))}
                    <li>
                      <div className="sign-out-link">
                        <a
                          tabIndex="0"
                          role="button"
                          onClick={() => handleLogOut(dispatch)}
                          onKeyPress={() => handleLogOut(dispatch)}
                        >
                          {props.t('topNavigationSignOut', { lng: langCode })}
                        </a>
                      </div>
                    </li>
                    <li>
                      <a
                        role="button"
                        tabIndex="0"
                        onClick={changeLanguage}
                        onKeyPress={changeLanguage}
                      >
                        {props.t('topNavigationLanguageSwitcherMobile', { lng: langCode })}
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            )}
          </>
        )}
      </div>
    )
  );
};

export default withTranslation()(TopNavigationAnonymous);
