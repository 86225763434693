import React, { useState, useRef, useEffect } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { StepTracker, TextInput, CardBlock, Success, Title, Error } from '../../molecules';
import { Formik } from 'formik';
import { Button, IconButton } from '../../atoms';
import printIcon from '../../assets/images/printer.svg';
import failIcon from '../../assets/images/Failed.svg';
import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../services/api';
import { setActivateCardMode, setActivateCardMyProducts } from '../../redux/products/actions';
import { fetchAllData } from '../../redux/user/actions';
import { withTranslation } from 'react-i18next';
import './activateCard.scss';

const ActivateCard = ({ fields, t }) => {
  const [cardNumber, activateCardNumber] = useState('');
  const focusDiv = useRef();
  const [pin, activatePin] = useState('');
  const [step, setStep] = useState(0);
  const toNextStep = () => {
    setStep(step + 1);
    focusDiv.current.focus();
    document.body.scrollTop = 0;
  };
  const [apiStatus, setApiStatus] = useState({ submit: false, state: 'INITIAL' });
  const manageMyProduct = useSelector((reduxState) => reduxState.products.manageMyProduct);
  const user = useSelector((reduxState) => reduxState.user);
  const fareMedia = useSelector((reduxState) =>
    reduxState.user.fareMedias.find((el) => el.mediaId === reduxState.user.selectedCard.mediaId)
  );
  const dispatch = useDispatch();
  const cardNumberAndPin_REGEX = /^[0-9]+$/;
  const [checkErrorCard, setCheckErrorCard] = useState(false);
  const [checkErrorPin, setCheckErrorPin] = useState(false);

  const [errorMsg, setErrorMsg] = useState({
    eM1: false,
    eM9: false,
    eM8: false,
    eM6: false,
    eM37: false,
  });
  const handleActivateCard = () => {
    const payload = {
      CustomerId: user?.customerId,
      CardNumber: cardNumber,
      Pin: pin,
      VisibleId: fareMedia?.visibleId,
      MediaId: fareMedia?.mediaId,
    };
    setApiStatus({ submit: true, state: 'LOADING' });
    api
      .prestoCardActivate(payload)
      .then((response) => {
        if (response?.data?.Success) {
          setApiStatus({ submit: true, state: 'SUCCESS' });
          dispatch(fetchAllData(false));
          toNextStep();
        } else if (response?.data?.errorMessage === 'AFMS-CRT-MA-0001') {
          setErrorMsg({ eM1: true, eM9: false, eM8: false, eM6: false, eM37: false });
          setApiStatus({ submit: true });
          setStep(0);
        } else if (response?.data?.errorMessage === 'AFMS-CRT-FE-0009') {
          setErrorMsg({ eM1: false, eM9: true, eM8: false, eM6: false, eM37: false });
          setStep(0);
        } else if (response?.data?.errorMessage === 'AFMS-CRT-FE-0008') {
          setErrorMsg({ eM1: false, eM9: false, eM8: true, eM6: false, eM37: false });
          setStep(0);
        } else if (response?.data?.errorMessage === 'AFMS-DAX-FE-0006') {
          setErrorMsg({ eM1: false, eM9: false, eM8: false, eM6: true, eM37: false });
          setStep(0);
        } else if (response?.data?.errorMessage === 'AFMS-CRT-FE-0037') {
          setErrorMsg({ eM1: false, eM9: false, eM8: false, eM6: false, eM37: true });
          setStep(0);
        } else {
          setApiStatus({ submit: true, state: 'ERROR' });
          toNextStep();
        }
      })
      .catch((err) => {
        setApiStatus({ submit: true, state: 'ERROR' });
      });
  };
  const putCardNumber = (e) => {
    activateCardNumber(e.target.value);
  };
  const putPinNumber = (e) => {
    activatePin(e.target.value);
  };

  useEffect(() => {
    if (manageMyProduct?.activateCardDetailsEnabled) {
      dispatch(setActivateCardMyProducts(false));
      document.body.scrollTop = 0;
    }
  }, [manageMyProduct]);

  const postApi = () => {
    if (apiStatus.state === 'SUCCESS') {
      return (
        <div className="activation-success">
          <Success
            title={{ value: fields.SuccessMessageLabel.value }}
            Content={() => (
              <div className="activatecard-success-content">
                <Text field={fields.SuccessMessageLabelA11y} />
              </div>
            )}
          />
          <div className="activatecard-print">
            <div className="manage-button">
              <Button
                onClick={() => {
                  setStep(0);
                  activateCardNumber('');
                  activatePin('');
                  dispatch(
                    setActivateCardMode({
                      activateCardDetailsEnabled: false,
                      selectedCard: {},
                    })
                  );
                }}
              >
                <Text field={fields.BackToManageCTAText} />
              </Button>
            </div>
            <IconButton
              className="print-icon"
              onClick={() => window.print()}
              icon={printIcon}
              tabIndex="0"
            >
              {fields.PrintLabel.value}
            </IconButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="show-error">
          {/* <Error title="error" /> */}
          <div className="Error-block">
            <div className="error-note">
              <div className="activatecard-error">
                <img
                  //src="https://contactless.prestocard.prestopreprod.net/~/media/open%20payment/assets/icons/failed.ashx"
                  src={failIcon}
                  alt="cross"
                  className="warning-titleImg activate"
                />
                <div className="card-not-found">
                  <Text field={fields.CardNotFoundErrorMessage} />
                </div>
              </div>
              <div className="activatecard-content">
                <Text field={fields.ErrorMessage} />
              </div>
            </div>
          </div>
          <div className="activatecard-print">
            <div className="manage-button">
              <Button
                onClick={() => {
                  setStep(0);
                  activateCardNumber('');
                  activatePin('');
                  dispatch(
                    setActivateCardMode({
                      activateCardDetailsEnabled: false,
                      selectedCard: {},
                    })
                  );
                }}
              >
                <Text field={fields.BackToManageCTAText} />
              </Button>
            </div>
            <IconButton
              className="error-print-icon"
              onClick={() => window.print()}
              icon={printIcon}
              tabIndex="0"
            >
              {fields.PrintLabel.value}
            </IconButton>
          </div>
        </div>
      );
    }
  };

  return manageMyProduct?.activateCardDetailsEnabled ? (
    <div className="activatecard-whole-wrapper ">
      <span tabIndex="-1" ref={focusDiv} className="stepper-sr-only">
        {t('StepperChangeSRText')}
      </span>
      <StepTracker
        activeIndex={step}
        items={[
          {
            text: <Text field={fields.CardDetailStep} />,
            textInProgressA11y: fields.Step1InProgressA11y.value,
            textNotCompletedA11y: fields.Step2NotCompletedA11y.value,
            textCompletedA11y: fields.Step1CompletedA11y.value,
          },
          {
            text: <Text field={fields.CardActivateStep} />,
            textInProgressA11y: fields.Step2InProgressA11y.value,
            textNotCompletedA11y: fields.Step2NotCompletedA11y.value,
            textCompletedA11y: fields.Step2CompletedA11y.value,
            hasError: apiStatus.state === 'ERROR' ? true : false,
          },
        ]}
      />
      <Title>
        <div className="activatecard-title">
          <Text field={fields.Title} />
        </div>
      </Title>
      {step === 0 ? (
        <div className="activatecard-step1 ">
          <div className="activatecard-description">
            <RichText field={fields.Description} />
          </div>
          {errorMsg.eM1 ? <Error small title={fields.AFMSCRTMA1?.value} /> : null}
          {errorMsg.eM9 ? <Error small title={fields.AFMSCRTFE9?.value} /> : null}
          {errorMsg.eM8 ? (
            <div className="new-error-block">
              <div className="new-error-block-title">
                <div className="content-hyperlink">
                  <RichText field={fields.AFMSCRTFE8} />
                </div>
              </div>
            </div>
          ) : null}
          {errorMsg.eM6 ? <Error small title={fields.AFMSDAXFE6?.value} /> : null}
          {errorMsg.eM37 ? <Error small title={fields.AFMSCRTFE37?.value} /> : null}
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                CardNumber: cardNumber,
                Pin: pin,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.CardNumber) {
                  errors.CardNumber = fields.CardNumberErrorMessage.value;
                } else if (
                  !cardNumberAndPin_REGEX.test(values.CardNumber) ||
                  values.CardNumber.length !== 17
                ) {
                  errors.CardNumber = fields.InvalidCardNumberError.value;
                }
                if (!values.Pin) {
                  errors.Pin = fields.PinNumberErrorMessage.value;
                } else if (!cardNumberAndPin_REGEX.test(values.Pin) || values.Pin.length !== 4) {
                  errors.Pin = fields.PINInvalidFormatError.value;
                }
                if (errors.hasOwnProperty('CardNumber')) {
                  setCheckErrorCard(true);
                } else {
                  setCheckErrorCard(false);
                }
                if (errors.hasOwnProperty('Pin')) {
                  setCheckErrorPin(true);
                } else {
                  setCheckErrorPin(false);
                }
                return errors;
              }}
              onSubmit={(values) => {
                //console.log(values);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <CardBlock>
                      <div className="activatecard-note-wrapper">
                        <div className="activatecard-note">
                          <RichText field={fields.Note} />
                        </div>
                      </div>
                      <div className="activate-card-textinput">
                        <div className="activate-cardtext-wrapper">
                          <div className="activatecard-card-text">
                            <TextInput
                              type="text"
                              value={values.CardNumber}
                              name="CardNumber"
                              errors={errors}
                              touched={touched}
                              onChange={(e) => {
                                handleChange(e);
                                putCardNumber(e);
                              }}
                              label={fields.CardNumberLabel}
                              bellowText={fields.CardNumberSubText}
                            />
                          </div>
                        </div>
                        <div className="activatecard-pin-text">
                          <div className="activatecard-pin-field">
                            <TextInput
                              type="text"
                              value={values.Pin}
                              name="Pin"
                              errors={errors}
                              touched={touched}
                              label={fields.PINNumberLabel}
                              bellowText={fields.PINNumberSubText}
                              onChange={(e) => {
                                handleChange(e);
                                putPinNumber(e);
                              }}
                            />
                            {errors.Pin ? errors.Pin.value : null}
                          </div>
                        </div>
                      </div>
                    </CardBlock>
                    <div className="activatecard-buttons">
                      <div id="activatecard-activatebutton">
                        <Button
                          type="submit"
                          isDisabled={checkErrorCard || checkErrorPin}
                          onClick={() => {
                            handleActivateCard();
                          }}
                        >
                          <Text field={fields.ActivateCTAText} />
                        </Button>
                      </div>
                      <div id="activatecard-cancelbutton">
                        <Button
                          white
                          firstOrder
                          onClick={() => redirectToUrl(routes.manageMyCards)}
                        >
                          <Text field={fields.CancelCTAText} />
                        </Button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <div className="activatecard-termsandconditions-text">
              <RichText field={fields.TermsCondtionText} />
            </div>
          </div>
        </div>
      ) : (
        postApi()
      )}
    </div>
  ) : null;
};

export default withTranslation()(ActivateCard);
