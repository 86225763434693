import React, { useState, useEffect, useRef } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { ButtonBlock, Button, Loader } from '../../atoms';
import routes from '../../constants/routes';
import api from '../../services/api';
import goDefaultTripConstants from '../../constants/goDefaultTrip';
import { redirectToUrl } from '../../services/utils';
import GOTransitAgencyImg from '../../assets/images/GO.svg';
import './goDefaultTrip.scss';

export default function RetrieveDefaultTrip({ props,
    checkError,
    apiStatus,
    setApiStatus,
    setIsDefaultTrip,
    userInfoRegisteredUser,
    userInfoData,
    isContactlessUserSignedIn,
    locationId,
    getStationList
}) {
    const [loading, setLoading] = useState(false);
    const focusDiv = useRef();

    const removeDefaultTrip = () => {
        setLoading(true);
        api
            .setOrUpdateDefaultTrip({
                CardType: isContactlessUserSignedIn ? userInfoData?.CardType : userInfoRegisteredUser?.type,
                FromLocationId: locationId?.FromLocationId,
                ToLocationId: locationId?.ToLocationId,
                ActionType: goDefaultTripConstants.removeDefaultTrip,
                MediaInstanceId: isContactlessUserSignedIn ? userInfoData?.MediaKeyDetails[0]?.mediaInstanceId : userInfoRegisteredUser?.visibleId.split('|')[0]
            })
            .then((response) => {
                if (response.data.Success === true) {
                    setApiStatus({ submit: true, state: 'SUCCESS' });
                }
                else {
                    // setApiStatus({ submit: true, state: 'ERROR' });
                    // setCheckError(true)
                    redirectToUrl(routes.technicalDifficulties);
                }
                setLoading(false);
            }).catch(() => {
                redirectToUrl(routes.technicalDifficulties);
                setLoading(false);
            });

    };

    useEffect(() => {
        if (apiStatus.submit) {
            focusDiv.current.focus();
            document.body.scrollTop = 0;
        }
    }, [apiStatus]);

    const changeDefaultTrip = () => {
        setIsDefaultTrip(false);
        getStationList();
    };

    const showMessage = () => {
        if (checkError) {
            return (
                <>
                    <div className='go-default-error-block'>
                        <div className='error-block-title'>
                            {/* To Do: Map error message if not routing to Technical Difficulties page */}
                        </div>
                    </div>
                    <ButtonBlock>
                        <ButtonBlock right>
                            <Button type="submit"
                                onClick={() => redirectToUrl(routes.goDefaultTrip)}
                                buttonTextA11y={props.fields.returnA11y.value} >
                                <Text field={props.fields.return} />
                            </Button>
                        </ButtonBlock>
                    </ButtonBlock>
                </>
            )
        }
        else {
            return (
                <div clasName="set-trip-step2-container">
                    <div className="set-trip-step2">
                        <div className="success-message" role="alert" tabIndex="-1" ref={focusDiv}>
                            <div id="message">
                                <Text field={props.fields.removeMessage} />
                            </div>
                        </div>
                    </div>
                    <div id="manage-button">
                        <ButtonBlock>
                            <ButtonBlock right>
                                <Button
                                    onClick={() => userInfoRegisteredUser ? redirectToUrl(routes.transactionHistory) : redirectToUrl(routes.contactlessTransactionHistory)}
                                    buttonTextA11y={props.fields.returnA11y.value} >
                                    <Text field={props.fields.return} />
                                </Button>
                            </ButtonBlock>
                        </ButtonBlock>
                    </div>
                </div>
            );
        }
    };

    return [
        !apiStatus.submit ? (
            <>
                <div className='trip-message'>
                    <RichText field={props.fields.tripMessage} />
                </div>
                <div className='set-block-container'>
                    <div className='station-title'>
                        <div className='icon'>
                            <img src={GOTransitAgencyImg} alt='' />
                        </div>
                        <h2 className='station-sub-title'>
                            <Text field={props.fields.settingsText} />
                        </h2>
                    </div>
                    <div className='form'>
                        <div className='retrieve-station'>
                            <div className='station1'>
                                <span className='get-station1'>
                                    <RichText field={props.fields.station1} />
                                </span>
                                <span className='station-name1'>
                                    {locationId?.FromLocationName}
                                </span>
                            </div>
                            <div className='station2'>
                                <span className='get-station2'>
                                    <RichText field={props.fields.station2} />
                                </span>
                                <span className='station-name2'>
                                    {locationId?.ToLocationName}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className='btn'>
                        <ButtonBlock>
                            <ButtonBlock right>
                                <Button type="submit"
                                    onClick={() => changeDefaultTrip()}
                                    buttonTextA11y={props.fields.changeDefaultTripA11y.value}
                                >
                                    <Text field={props.fields.changeDefaultTrip} />
                                </Button>
                                <Button type="submit"
                                    firstOrder
                                    white
                                    onClick={() => removeDefaultTrip()}
                                    buttonTextA11y={props.fields.removeDefaultTripA11y.value}
                                >
                                    <Text field={props.fields.removeDefaultTrip} />
                                </Button>
                            </ButtonBlock>
                        </ButtonBlock>
                    </div>
                </div>
            </>) : showMessage(),
        loading ? <Loader /> : null,
    ];
}