import React from 'react';

import { ReactComponent as ExternalLinkIcon } from '../../assets/images/ExternalLink.svg';
import { ReactComponent as IconLeft } from '../../assets/images/LeftArrow.svg';
import { ReactComponent as IconRight } from '../../assets/images/Right.svg';
import { ReactComponent as IconRightEnd } from '../../assets/images/RightEnd.svg';
import { ReactComponent as IconLeftEnd } from '../../assets/images/LeftEnd.svg';

const icons = {
  externalLinkIcon: ExternalLinkIcon,
  iconLeft: IconLeft,
  iconRight: IconRight,
  iconRightEnd: IconRightEnd,
  iconLeftEnd: IconLeftEnd,
};

const iconSizes = {
  xs: '12px',
  sm: '16px',
  md: '24px',
  lg: '32px',
};

const iconColors = {
  white: '#ffffff',
  black: '#191919',
  disabled: '#6e6e6e',
};

const Icon = ({ iconName = '', size = 'sm', color = 'black', ariaLabel = '' }) => {
  const Component = icons[iconName];
  const iconSize = iconSizes[size];
  const iconColor = iconColors[color];

  return (
    !!Component && (
      <Component fill={iconColor} width={iconSize} height={iconSize} aria-label={ariaLabel} />
    )
  );
};

export default Icon;
