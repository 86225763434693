import React, { useEffect, useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { CheckoutGridItem, RadioInput } from '../../atoms';
import './checkoutGrid.scss';
import { formatDecimalWithCurrency } from '../../services/utils';
import { Note } from '../../molecules';
import { Formik, useFormikContext } from 'formik';
import TextInput from '../TextInput';
import { useSelector } from 'react-redux';

const CARD_NICKNAME_REGEX = /^[a-zA-Z0-9_.-]*$/;
const PIN_REGEX = /^[0-9]*$/;

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (values.userName && values.pinNumber && values.confirmPinNumber && values.cardLanguage) {
      submitForm();
    }
  }, [values]);
  return null;
};

const CheckoutGrid = ({
  fields,
  items,
  fareMedia,
  success,
  totalAmount,
  handleCheckoutData,
  isOrderCard,
  deactivate,
  deactivateFlow,
  hideRemove,
  orderCardResponse,
  paymentApiResponse,
  t,
  lastStep,
  state,
  step,
  fundsSelected,
  additionalpadding,
  currentBalance,
}) => {
  const [isError, setIsError] = useState(false);
  //console.log('fields', fields);
  let filterItem =
    !isOrderCard &&
    items &&
    items.length > 0 &&
    items.filter((i) => i.visibleId === fareMedia.visibleId);
  if (items && items.length > 0 && items[0].visibleId === 'NEWMEDIA') {
    filterItem = items;
  }
  const fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);

  let enableReportLostCard = deactivate;
  if (fundsSelected) {
    enableReportLostCard = true;
  }

  return filterItem?.length || (items.length && (step == 0 || step == 3)) ? (
    <div className={`checkoutGrid-wrapper ${success ? 'successModifier' : ''} `}>
      {!deactivate && !fareMedia?.autoRenew && fareMedia?.autoRenew !== 'true' && (
        <div className={`checkoutGrid-card ${success ? 'successNickname' : ''}`}>
          {isOrderCard || fundsSelected
            ? 'New Card'
            : deactivateFlow
              ? filterItem[0]?.nickName
              : fareMedia?.nickName}
          {success && !isOrderCard && !fundsSelected && (
            <>
              <span>{deactivateFlow ? t('FareMediaNoNickname') : ' - ' + fareMedia?.visibleId}</span>
            </>
          )}
        </div>
      )}
      <div className="checkoutGrid">
        {!deactivateFlow && fields.NewCardTitle ? (
          <div className="checkoutGrid-newCard-header">
            <Text field={fields.NewCardTitle} />
          </div>
        ) : null}
        <div
          className={`checkoutGrid-info-wrapper ${additionalpadding ? 'grid-additional-pad' : ''}`}
        >
          <div className="checkoutGrid-header">
            <div className="checkoutGrid-header-item">
              <Text field={enableReportLostCard ? fields.Item : fields.itemLabel} />
            </div>
            <div className="checkoutGrid-header-item">
              <span className="mobileOnly print-hidden">
                <Text field={enableReportLostCard ? fields.Quantity : fields.quantityMobileLabel} />
              </span>
              <span className="desktopOnly">
                <Text field={enableReportLostCard ? fields.Quantity : fields.quantityLabel} />
              </span>
            </div>
            <div className="checkoutGrid-header-item">
              <Text field={enableReportLostCard ? fields.Price : fields.priceLabel} />
            </div>
          </div>
          {step == 0 || step == 3
            ? items &&
            items.length > 0 &&
            items.map((item, index) => (
              <CheckoutGridItem
                fields={fields}
                item={item}
                deactivateFlow={deactivateFlow}
                orderCardResponse={orderCardResponse}
                paymentApiResponse={paymentApiResponse}
                lastStep={lastStep}
                filterItemLength={items.length}
                index={index}
                hideRemove={hideRemove}
                step={step}
                disableBorderBottom={items.length == 1 ? true : false}
                currentBalance={currentBalance}
              />
            ))
            : filterItem.map((item, index) => (
              <CheckoutGridItem
                fields={fields}
                item={item}
                deactivateFlow={deactivateFlow}
                orderCardResponse={orderCardResponse}
                lastStep={lastStep}
                filterItemLength={filterItem.length}
                index={index}
                hideRemove={hideRemove}
                disableBorderBottom={items.length == 1 ? true : false}
                currentBalance={currentBalance}
              />
            ))}
        </div>
        {/* {step == 0 || step == 3
          ? items &&
            items.length > 0 &&
            items.map((item, index) => (
              <CheckoutGridItem
                fields={fields}
                item={item}
                deactivateFlow={deactivateFlow}
                orderCardResponse={orderCardResponse}
                lastStep={lastStep}
                filterItemLength={items.length}
                index={index}
                hideRemove={hideRemove}
                step={step}
              />
            ))
          : filterItem.map((item, index) => (
              <CheckoutGridItem
                fields={fields}
                item={item}
                deactivateFlow={deactivateFlow}
                orderCardResponse={orderCardResponse}
                lastStep={lastStep}
                filterItemLength={filterItem.length}
                index={index}
                hideRemove={hideRemove}
              />
          ))} */}
        {isOrderCard && !lastStep && !state?.savedPaymentMethodUsed ? (
          <>
            <>
              {' '}
              {!guestUserInfo?.isGuestLogin ? (
                <Formik
                  initialValues={{
                    userName: '',
                    pinNumber: '',
                    confirmPinNumber: '',
                    cardLanguage: 'en',
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.userName) {
                      errors.userName = fields.MissingNickNameError.value;
                    } else if (
                      !CARD_NICKNAME_REGEX.test(values.userName) ||
                      values.userName.length > 25
                    ) {
                      errors.userName = fields.InvalidNickName.value;
                    } else if (
                      fareMedias.find((el) => {
                        if (el.nickName === values.userName) {
                          return true;
                        }
                      })
                    ) {
                      errors.userName = fields.NickNameAlreadyExist.value;
                    }
                    if (!values.pinNumber) {
                      errors.pinNumber = fields.MissingNewPinError.value;
                    } else if (
                      !PIN_REGEX.test(values.pinNumber) ||
                      values.pinNumber.length > 4 ||
                      values.pinNumber.length < 4
                    ) {
                      errors.pinNumber = fields.InvalidNewPinError.value;
                    }
                    if (!values.confirmPinNumber) {
                      errors.confirmPinNumber = fields.MissingConfirmPinError.value;
                    } else if (values.pinNumber !== values.confirmPinNumber) {
                      errors.confirmPinNumber = fields.InvalidConfirmPinError.value;
                    }
                    if (Object.keys(errors).length === 0) {
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    handleCheckoutData({ values, isError });
                  }}
                >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <form
                      className={`checkout-input-form ${additionalpadding ? 'grid-additional-pad' : ''
                        } `}
                      onSubmit={handleSubmit}
                    >
                      <div className="checkout-input-block">
                        <div className="nickname-Note">
                          <RichText field={fields.NicknameNote} />
                        </div>
                        <div className="checkout-nickName">
                          <div className="nickname-label">
                            <Text field={fields.NickNameLabel} />
                          </div>
                          <div className="nickname-field">
                            <TextInput
                              type="text"
                              name="userName"
                              onChange={handleChange}
                              value={values.userName}
                              errors={errors}
                              touched={touched}
                              inputA11y={fields.NickNameLabelA11y.value}
                            />
                            {errors.userName && touched.userName}
                          </div>
                        </div>
                        <div className="checkout-pin">
                          <div className="pinNumber">
                            <div className="pin-label">
                              <Text field={fields.PinNumber} />
                            </div>
                            <div className="pin-field">
                              <TextInput
                                type="text"
                                name="pinNumber"
                                onChange={handleChange}
                                value={values.pinNumber}
                                errors={errors}
                                touched={touched}
                                inputA11y={fields.PinNumberA11y.value}
                                bellowText={fields.PinNumberNote}
                              />
                              {errors.pinNumber && touched.pinNumber}
                            </div>
                            {/* <div className="PinNumberNote">
                            <Text field={fields.PinNumberNote} />
                          </div> */}
                          </div>
                          <div className="confirmPinNumber">
                            <div className="confirmPin-label">
                              <Text field={fields.confirmPinNumberA11y} />
                            </div>
                            <div className="confirmPin-field">
                              <TextInput
                                type="text"
                                name="confirmPinNumber"
                                onChange={handleChange}
                                value={values.confirmPinNumber}
                                errors={errors}
                                touched={touched}
                                inputA11y={fields.confirmPinNumberA11y.value}
                                bellowText={fields.PinNumberNote}
                              />
                              {errors.confirmPinNumber && touched.confirmPinNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-language">
                        <div className="language-title">
                          <Text field={fields.SelectLanguageLabel} />
                        </div>

                        <div className="language-content">
                          <div className="card-language-english">
                            <RadioInput
                              name="cardLanguage"
                              value="en"
                              label={fields.EnglishLanguageText.value}
                              type="radio"
                              a11y={fields.EnglishLanguageTextA11.value}
                              onChange={handleChange}
                              checked={values.cardLanguage === 'en'}
                            />
                          </div>
                          <div className="card-language-french">
                            <RadioInput
                              name="cardLanguage"
                              value="fr"
                              label={fields.FrenchLanguageText.value}
                              type="radio"
                              a11y={fields.FrenchLanguageTextA11y.value}
                              onChange={handleChange}
                              checked={values.cardLanguage === 'fr'}
                            />
                          </div>
                        </div>
                      </div>
                      {!isError && <AutoSubmitToken />}
                      <button className="d-none" id="checkout-submit-button" type="submit">
                        submit
                      </button>
                    </form>
                  )}
                </Formik>
              ) : (
                <Formik
                  initialValues={{
                    cardLanguage: 'en',
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (Object.keys(errors).length === 0) {
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    handleCheckoutData({ values, isError });
                  }}
                >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <form
                      className="checkout-input-form grid-additional-pad"
                      onSubmit={handleSubmit}
                    >
                      <div className="card-language">
                        <div className="language-title">
                          <Text field={fields.SelectLanguageLabel} />
                        </div>

                        <div className="language-content">
                          <div className="card-language-english">
                            <RadioInput
                              name="cardLanguage"
                              value="en"
                              label={fields.EnglishLanguageText.value}
                              type="radio"
                              a11y={fields.EnglishLanguageTextA11.value}
                              onChange={handleChange}
                              checked={values.cardLanguage === 'en'}
                            />
                          </div>
                          <div className="card-language-french">
                            <RadioInput
                              name="cardLanguage"
                              value="fr"
                              label={fields.FrenchLanguageText.value}
                              type="radio"
                              a11y={fields.FrenchLanguageTextA11y.value}
                              onChange={handleChange}
                              checked={values.cardLanguage === 'fr'}
                            />
                          </div>
                        </div>
                      </div>
                      {!isError && <AutoSubmitToken />}
                      <button className="d-none" id="checkout-submit-button" type="submit">
                        submit
                      </button>
                    </form>
                  )}
                </Formik>
              )}
            </>
            <div className="checkout-total">
              <span>
                <Text field={fields.orderTotalLabel} />
              </span>
              {` ${formatDecimalWithCurrency(totalAmount)}`}
            </div>
            <Note>
              <div className="checkout-note">
                <RichText field={fields.CheckoutNote1} />
              </div>
            </Note>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  ) : null;
};

export default CheckoutGrid;
