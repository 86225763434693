import React, { useRef, useEffect } from 'react';

import { withTranslation } from 'react-i18next';

import { redirectToUrl } from '../../services/utils';
import routes from '../../constants/routes';
import { IconButton } from '../../atoms';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import CloseSearchImage from '../../assets/images/search_close.svg';
import searchIcon from '../../assets/images/SearchIcon.svg';
import './headerSearch.scss';

const HeaderSearch = ({ t }) => {
  const ref = useRef(null);
  const searchInputRef = useRef(null);
  const [isSearchClicked, showSearch] = useDetectOutsideClick(ref, false, true);

  useEffect(() => {
    if (isSearchClicked) {
      searchInputRef.current.focus();
      searchInputRef.current.placeholder = t('topNavigationSearchPlaceholder');
    } else {
      searchInputRef.current.value = '';
      searchInputRef.current.placeholder = '';
    }
  }, [isSearchClicked]);

  return (
    <>
      <li ref={ref} className={`header-search ${isSearchClicked ? 'active' : 'inactive'}`}>
        <input
          ref={searchInputRef}
          className="search-input"
          type="text"
          id="search"
          name="search"
          onKeyUp={(event) => {
            if (event.key === 'Enter' && searchInputRef.current.value) {
              redirectToUrl(routes.searchResults + searchInputRef.current.value);
            }
          }}
        />
        <IconButton
          onClick={() => {
            showSearch(!isSearchClicked);
          }}
          icon={CloseSearchImage}
          ariaLabel={t('topNavigationExitSearch')}
          iconAlt={t('topNavigationExitSearch')}
          className="search-close-button"
        />
      </li>
      <li className="nav-french-align">
        <a
          role="button"
          aria-label={t('topNavigationSearchPlaceholder')}
          tabIndex="0"
          onClick={() => {
            showSearch(!isSearchClicked);
          }}
          onKeyPress={() => {
            showSearch(!isSearchClicked);
          }}
          className={`search-icon ${isSearchClicked ? 'inactive' : ''}`}
        >
          <img
            src={searchIcon}
            className="search-icon-desktop"
            alt={t('topNavigationSearchIcon')}
          />
        </a>
      </li>
    </>
  );
};

export default withTranslation()(HeaderSearch);
