import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBlock } from '../../../atoms';
import { setDeactivateCardType } from '../../../redux/user/actions';
import { formatDecimalWithCurrency } from '../../../services/utils';
import './DeactivateAndTransfer0.scss';

const DeactivateAndTransfer0 = ({ toNextStep, fields, userInfo }) => {
  const visibleId = userInfo?.selectedCard?.visibleId;
  const nickname = userInfo?.selectedCard?.nickName;
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias = fareMedias.find((data) => data.visibleId === visibleId);
  const currentBalance = fareMedias?.currentBalance;
  const transitPasses =
    fareMedias && fareMedias?.passes && fareMedias?.passes.length > 0 && fareMedias?.passes;
  // const transitPasses = useSelector((reduxState) => reduxState.user.selectedCard.products);
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  let formattedBalance = formatDecimalWithCurrency(currentBalance);
  return (
    <>
      <div className="transfer-card-withButton">
        <div className="deactivate-card-section">
          <div className="card-header">
            <Text field={fields.CardToDeactivate} />
          </div>
          <div className="card-content">
            <div className="card-Number">
              <RichText field={fields.PrestoCardNumberLable} />
              <span>
                <b>{visibleId}</b>
              </span>
            </div>
            <div className="card-nick-name">
              <RichText field={fields.CardNickNameLable} />
              <span>
                <b>{nickname}</b>
              </span>
            </div>
            <div className="card-balance">
              <RichText field={fields.BalanceLabel} />
              <span>
                <b>{formattedBalance}</b>
              </span>
            </div>

            <div className="card-transitPass">
              <RichText field={fields.TransitPassLabel} />
              {transitPasses && transitPasses.length > 0 ? (
                <div>
                  {transitPasses.map((pass) => {
                    if (pass.ProductName !== null) {
                      return <p className="transitpass-list-RLC">{pass.ProductName}</p>;
                    }
                  })}
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <ButtonBlock className="Deactivated-Button">
          <Button onClick={() => toNextStep()}>
            <Text field={fields.NextCTALabel} />
          </Button>
          <Button onClick={() => dispatch(setDeactivateCardType(''))}>
            <Text field={fields.GoBackCTALabel} />
          </Button>
        </ButtonBlock>
      </div>
    </>
  );
};

export default DeactivateAndTransfer0;
