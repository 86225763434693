import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import api from '../../../services/api';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '../../../atoms';

import {
  formatDate,
  formatDecimalWithCurrency,
  formatMonthYear,
  formatUtcDate,
} from '../../../services/utils';
import './transitPassItem.scss';
import { Modal } from '../../../molecules';
import '../AddTransitPass/AddTransitPass.scss';

const TransitPassItem = ({
  t,
  subscriptionInstance,
  goToCancelAutorenew,
  success,
  goToManageAutoRenew,
  cancel12MonthTTCPass,
  isCancel12MonthTTCSteps,
  TTCFields,
  showFinalBillingDate,
  fields,
}) => {
  const product = subscriptionInstance?.Product;
  const logo = useSelector(
    (state) => state.products?.logos[product?.ProductServiceProvider.Id]?.Logo
  );
  const [discountResponse, setDiscountResponse] = useState('');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  function getTimePeriod(startDate, endDate) {
    return `${formatDate(startDate)} - ${endDate >= '1901' ? formatDate(endDate) : t('noEndDateText')
      }`;
  }

  useEffect(() => {
    if (isPaymentModalOpen) {
      document.body.scrollTop = 0;
    }
  }, [isPaymentModalOpen]);

  if (!product) {
    return null;
  }

  const manageClickHandler = (e) => {
    if (e.keyCode == 13) {
      goToManageAutoRenew(subscriptionInstance);
    }
  };
  const cancelClickHandler = (e) => {
    if (e.keyCode == 13) {
      goToCancelAutorenew(subscriptionInstance);
    }
  };
  const viewPaymentPlan = (productId) => {
    setLoading(true);
    api
      .getAutorenewPassDiscounts({
        productId,
        IsStubEnabled: false,
        IsErrorFlowEnabled: true,
      })
      .then((response) => {
        setLoading(false);
        setDiscountResponse(response?.data?.MDPDetails);
        setIsModalOpen(true);
        setIsPaymentModalOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        //console.log('error', error);
      });
  };

  return (
    <>
      {isPaymentModalOpen && (
        <div className="payment-plan-modal">
          <Modal
            title={fields.TTC12MonthMDPTitle?.value}
            text={
              <>
                <RichText field={fields.TTC12MonthMDPDescription} />
                <div className="payment-plan-wrapper">
                  <div className="payment-plan-header">
                    <Text field={fields.TTC12MonthMDPSubTitle} />
                  </div>
                  <div className="payment-plan-content">
                    <div className="payment-plan-subtitle">
                      <span className="ttcTerm">
                        <Text field={fields.TermMonth} />
                      </span>
                      <span className="ttcDiscount">
                        <Text field={fields.TTCPassDiscount} />
                      </span>
                      <span className="ttcPrice">
                        <Text field={fields.DiscountPrice} />
                      </span>
                    </div>
                    {discountResponse &&
                      discountResponse.length > 0 &&
                      discountResponse.map((data) => {
                        return (
                          <div className="payment-plan-data">
                            <span className="ttcTerm">{data.Term}</span>
                            <span className="ttcDiscount">{data.Discount}%</span>
                            <span className="ttcPrice">
                              {formatDecimalWithCurrency(data.DiscountedPrice)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="payment-plan-button">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsPaymentModalOpen(false);
                    }}
                  >
                    {fields.CloseCTA?.value}
                  </Button>
                </div>
              </>
            }
            textSubmit={fields.CloseCTA?.value}
            isModalOpen={isModalOpen}
            onSubmit={() => {
              setIsModalOpen(false);
              setIsPaymentModalOpen(false);
            }}
            setIsModalOpen={setIsModalOpen}
            hideButtons
            withoutFocusTrap={true}
          />
        </div>
      )}
      <div className={`transitPassItem ${success ? 'success' : ''}`}>
        <div className="transitPassItem-wrapper">
          <div
            className={`transitPassItem-logo-block ${isCancel12MonthTTCSteps ? 'hide-ttc-logo' : ''
              }`}
          >
            {logo && logo.Src ? (
              <img alt={logo.Alt} className="transitPassItem-title-logo" src={logo.Src} />
            ) : null}
          </div>
          <div className="transitPassItem-texts-block">
            <div className="transitPassItem-texts-block-wrapper">
              <div className="transitPassItem-middle">
                <div className="transitPassItem-title" aria-label={product.ProductName}>
                  {product.ProductName}
                </div>
                <div
                  className="transitPassItem-date"
                  aria-label={getTimePeriod(
                    subscriptionInstance.StartDateTime,
                    subscriptionInstance.ExpiryDateTime
                  )}
                >
                  {getTimePeriod(
                    subscriptionInstance.StartDateTime,
                    subscriptionInstance.ExpiryDateTime
                  )}
                </div>
              </div>
              <div className="transitPassItem-right">
                <div className="transitPassItem-value">
                  {isCancel12MonthTTCSteps || cancel12MonthTTCPass
                    ? null
                    : formatDecimalWithCurrency(product.ProductPrice || product.Balance)}

                  {goToCancelAutorenew ? (
                    <>
                      {authenticated && (
                        <>
                          <div className="transitPassItem-action">
                            <a
                              className="pass-managenew-btn"
                              role="button"
                              onClick={() => goToManageAutoRenew(subscriptionInstance)}
                              onKeyDown={manageClickHandler}
                              tabIndex="0"
                            >
                              {t('autorenewManageAutorenew')}
                            </a>
                          </div>
                          <div className="transitPassItem-action">
                            <a
                              className="pass-managenew-btn"
                              role="button"
                              onClick={() => {
                                return (
                                  goToCancelAutorenew && goToCancelAutorenew(subscriptionInstance)
                                );
                              }}
                              onKeyDown={cancelClickHandler}
                              tabIndex="0"
                            >
                              {t('autorenewCancelAutorenew')}
                            </a>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  {cancel12MonthTTCPass ? (
                    <>
                      <div className="transitPassItem-action">
                        <a
                          className="pass-managenew-btn"
                          role="button"
                          onClick={(e) => {
                            e.preventDefault();
                            viewPaymentPlan(product.ProductId);
                          }}
                          onKeyDown={cancelClickHandler}
                          tabIndex="0"
                        >
                          {t('autorenewViewPaymentPlan')}
                        </a>
                      </div>
                      <div className="transitPassItem-action">
                        <a
                          className="pass-managenew-btn"
                          role="button"
                          onClick={() => {
                            return (
                              cancel12MonthTTCPass && cancel12MonthTTCPass(subscriptionInstance)
                            );
                          }}
                          onKeyDown={cancelClickHandler}
                          tabIndex="0"
                        >
                          {t('autorenewCancelTTC12MonthPass')}
                        </a>
                      </div>
                    </>
                  ) : null}
                  {isCancel12MonthTTCSteps ? (
                    <div className="transitPassItem-action">
                      <a
                        className="pass-managenew-btn"
                        role="button"
                        onClick={(e) => {
                          e.preventDefault();
                          viewPaymentPlan(product.ProductId);
                        }}
                        onKeyDown={cancelClickHandler}
                        tabIndex="0"
                      >
                        {t('autorenewViewPaymentPlan')}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {subscriptionInstance.ExpiryDateTime >= '1901' &&
          !cancel12MonthTTCPass &&
          !isCancel12MonthTTCSteps ? (
          <div className="transitPassItem-wrapper">
            <div className="transitPassItem-bottom">
              <div className="transitPassItem-middle">
                <div className="transitPassItem-label">{t('autorenewEndDate')}</div>
              </div>
              <div className="transitPassItem-right">
                <div className="transitPassItem-value">
                  {formatMonthYear(subscriptionInstance.ExpiryDateTime)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {isCancel12MonthTTCSteps
          ? [
            <div className="TTCCancelPassItem-wrapper">
              <div className="transitPassItem-bottom">
                <div className="transitPassItem-middle">
                  <div className="transitPassItem-label">{TTCFields?.startDate?.value}</div>
                </div>
                <div className="transitPassItem-right">
                  <div className="transitPassItem-value">
                    {formatUtcDate(subscriptionInstance?.StartDateTime)}
                  </div>
                </div>
              </div>
            </div>,
            <div className="TTCCancelPassItem-wrapper">
              <div className="transitPassItem-bottom">
                <div className="transitPassItem-middle">
                  <div className="transitPassItem-label">{TTCFields?.endDate?.value}</div>
                </div>
                <div className="transitPassItem-right">
                  <div className="transitPassItem-value">
                    {formatUtcDate(subscriptionInstance?.ExpiryDateTime)}
                  </div>
                </div>
              </div>
            </div>,
            showFinalBillingDate ? (
              <div className="TTCCancelPassItem-wrapper">
                <div className="transitPassItem-bottom">
                  <div className="transitPassItem-middle">
                    <div className="transitPassItem-label">
                      {TTCFields?.finalBillingDate?.value}
                    </div>
                  </div>
                  <div className="transitPassItem-right">
                    <div className="transitPassItem-value">
                      {formatUtcDate(subscriptionInstance?.ExpiryDateTime)}
                    </div>
                  </div>
                </div>
              </div>
            ) : null,
            <div className="TTCCancelPassItem-wrapper">
              <div className="transitPassItem-bottom">
                <div className="transitPassItem-middle">
                  <div className="transitPassItem-label">
                    {TTCFields?.accumulatedDiscount?.value}
                  </div>
                </div>
                <div className="transitPassItem-right">
                  <div className="transitPassItem-value">
                    {formatDecimalWithCurrency(subscriptionInstance?.MDPAccumulatedDiscount)}
                  </div>
                </div>
              </div>
            </div>,
          ]
          : null}
      </div>
    </>
  );
};

export default withTranslation()(TransitPassItem);
