import React from 'react';

import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import TransitPassItem from '../TransitPassItem';
import { Title } from '../../../molecules';

import '../transitpass.scss';

const TransitPassListing = ({
  fields,
  goToCancelAutorenew,
  mergedSubscriptionList,
  goToManageAutoRenew,
  cancel12MonthTTCPass,
}) => {
  if (!mergedSubscriptionList?.length) {
    return null;
  }
  let IsOrderCard = fields?.IsOrderCard?.value;
  return !IsOrderCard ? (
    <>
      <Title>
        <Text field={fields.myTransitPassesTitle} />
      </Title>

      {mergedSubscriptionList
        .sort((firstProduct, secondProduct) => {
          let a = firstProduct.Product.ProductServiceProvider.Name.toUpperCase();
          let b = secondProduct.Product.ProductServiceProvider.Name.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return a === b ? 0 : a > b ? 1 : -1;
        })
        .map((subscriptionItem) => {
          return (
            <TransitPassItem
              subscriptionInstance={subscriptionItem}
              fields={fields}
              goToCancelAutorenew={
                subscriptionItem.SubscriptionType === 2 ? goToCancelAutorenew : undefined
              }
              goToManageAutoRenew={goToManageAutoRenew}
              t
              cancel12MonthTTCPass={
                subscriptionItem.SubscriptionType === 5 ? cancel12MonthTTCPass : undefined
              }
            />
          );
        })}
    </>
  ) : null;
};

export default withTranslation()(TransitPassListing);
