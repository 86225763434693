import React, { useEffect, useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, ButtonBlock, MediaImage } from '../../atoms';
import { CardBlock, ValueRowSecond, Modal } from '../../molecules';
import { formatDecimalWithCurrency, redirectToUrl, formatUtcDate } from '../../services/utils';
import cardStatuses from '../../constants/cardStatuses';
import routes from '../../constants/routes';
import mediaSubTypes from '../../constants/mediaSubTypes';
import chevronUp from '../../assets/images/up.svg';
import chevronDown from '../../assets/images/down.svg';
import tick from '../../assets/images/TickBlack.svg';
import {
  setEditCardMode,
  setActivateCardMode,
  setChangeCardPinMode,
  setRemoveCardMode,
} from '../../redux/products/actions';
import './manageMyProducts.scss';

const MediaListItem = ({ card, fields, t, isExpandedByDefault = false, isFirstCard }) => {
  const dispatch = useDispatch();
  const isAnonymous = card?.type === mediaSubTypes.anonymous;
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const [isConsessionStudent, setIsConsessionStudent] = useState(false);
  const collapsibleContentStyle = { display: isExpanded || isAnonymous ? 'block' : 'none' };
  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const cardType = `${isAnonymous ? 'prestoCard' : `${card.type}Card`}`;
  const mediaImageProps = {
    big: true,
    [cardType]: true,
  };
  const isPrestoCard = card?.type === mediaSubTypes.presto;

  const showChangePinBtn = isPrestoCard && card.cardStateCode === cardStatuses.ActiveRegistered;
  const showActivationBtn = isPrestoCard && card.cardStateCode === cardStatuses.RegisteredNotActive;
  const fareTypeExpirationStudentChild =
    ((isPrestoCard || isAnonymous) && card.fareType === 'Student') ||
    ((isPrestoCard || isAnonymous) && card.fareType === 'Child');
  const fareTypeExpirationPostSecondary =
    (isPrestoCard || isAnonymous) && card.fareType.toUpperCase() === 'PS1';
  const fareTypeExpirationSpecificFare = (isPrestoCard || isAnonymous) && card.specificFare != '';

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (t(card.fareType) === t('Student')) {
      setIsConsessionStudent(true);
    }
    if (isFirstCard) {
      toggle();
    }
  }, []);

  if (!card.type) {
    return null;
  }
  const expiryValue = card?.endDate?.split('T');
  const date = expiryValue ? formatUtcDate(expiryValue[0]) : null;

  const prepareEditDetails = () => {
    dispatch(
      setEditCardMode({
        editCardDetailsEnabled: true,
        selectedCard: { ...card },
      })
    );
  };

  const prepareChangeCardPin = () => {
    dispatch(
      setChangeCardPinMode({
        changeCardPinEnabled: true,
        selectedCard: { ...card },
      })
    );
  };
  const activateSelectedCardDetails = () => {
    dispatch(
      setActivateCardMode({
        activateCardDetailsEnabled: true,
        selectedCard: { ...card },
      })
    );
  };

  const removeCard = () => {
    dispatch(
      setRemoveCardMode({
        removeCardEnabled: true,
        selectedCard: { ...card },
      })
    );
  };

  const anonymousCardHandler = () => {
    redirectToUrl(routes.loadMyCard);
  };

  const anonymousCardChangeHandler = (e) => {
    if (e.keyCode == 13) {
      redirectToUrl(routes.loadMyCard);
    }
  };

  const description = {
    value: fields.Description.value.replace('{fareTypeExpiry}', date),
  };

  const status = (cardsStatus) => {
    let cardStatus;
    switch (cardsStatus) {
      case 0:
        cardStatus = fields.InitializedAndNotActivated?.value;
        break;
      case 1:
        cardStatus = fields.Active?.value;
        break;
      case 2:
        cardStatus = fields.Hotlisted?.value;
        break;
      case 3:
        cardStatus = fields.Blocked?.value;
        break;
      case 4:
        cardStatus = fields.NotActivated?.value;
        break;
      case 5:
        cardStatus = fields.Inactive?.value;
        break;
      case 6:
        cardStatus = fields.Disposed?.value;
        break;
      case 7:
        cardStatus = fields.Rejected?.value;
        break;

      default:
        cardStatus = '';
    }

    return `${cardStatus}, ${fields.CardStatusNotRegistered?.value}`;
  };

  return (
    <>
      <Modal
        title={fields.FareTypeExpirationTitle?.value}
        text={<RichText field={description} />}
        textSubmit={fields.CloseCTA?.value}
        buttonTextA11y={fields.CloseCTAA11y?.value}
        useRevert
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <CardBlock cssClass={`manageMyCards-mediaItem ${!isPrestoCard ? 'isContactless' : ''}`}>
        <div
          role={!isAnonymous ? 'button' : ''}
          tabIndex={!isAnonymous ? 0 : ''}
          onClick={!isAnonymous && toggle}
          onKeyDown={() => null}
          aria-expanded={isExpanded}
          className="manageMyCards-mediaItem-header"
        >
          <div className="manageMyCards-mediaItem-mediaWrapper">
            <MediaImage {...mediaImageProps} />
          </div>
          <div className="manageMyCards-mediaItem-header-cardInfo">
            <div>
              {!isAnonymous && (
                <div className="manageMyCards-mediaItem-header-cardInfoName">
                  {card.nickName || '-'}
                </div>
              )}
              {card.type === mediaSubTypes.anonymous ? (
                <div
                  onClick={anonymousCardHandler}
                  onKeyDown={anonymousCardChangeHandler}
                  tabIndex="0"
                  className="manageMyCards-mediaItem-header-cardInfoName"
                >
                  {card.visibleId}
                </div>
              ) : null}
              {card.type === mediaSubTypes.presto ? (
                <div className="manageMyCards-mediaItem-header-cardInfoNumber">
                  {card.visibleId}
                </div>
              ) : null}
            </div>
            {card.type === mediaSubTypes.presto || card.type === mediaSubTypes.anonymous ? (
              <div className="manageMyCards-mediaItem-header-cardBalance">
                <div>
                  <strong>{formatDecimalWithCurrency(card.currentBalance)}</strong>
                </div>
                {card.isPrimary ? (
                  <div className="manageMyCards-mediaItem-header-cardInfoPrimaryFlag">
                    <Text field={fields.primaryCard} />
                    <span className="manageMyCards-mediaItem-header-cropAndScaleImg">
                      <img src={tick} alt="" />
                    </span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {!isAnonymous && (
            <div className="manageMyCards-mediaItem-header-toggleBtnWrapper">
              <img
                src={isExpanded ? chevronUp : chevronDown}
                alt={isExpanded ? t('collapseA11y') : t('expandA11y')}
              />
            </div>
          )}
        </div>
        <div className="manageMyCards-mediaItem-collapsibleContent" style={collapsibleContentStyle}>
          {isAnonymous && (
            <ValueRowSecond
              left={fields.cardStatus?.value}
              right={isAnonymous ? status(card.status) : ''}
              rightBold
            />
          )}
          <ValueRowSecond
            left={fields.fareType?.value}
            right={
              isPrestoCard || isAnonymous
                ? `${isConsessionStudent ? t('Youth') : t(card.fareType)}${
                    card.specificFare ? `, ${card.specificFare}` : ''
                  } `
                : t('Adult')
            }
            rightBold
          />

          {fareTypeExpirationStudentChild ||
          (fareTypeExpirationPostSecondary &&
            expiryValue[0] != '0001-01-01' &&
            expiryValue[0] != '1900-01-01') ||
          fareTypeExpirationSpecificFare ? (
            <div className="fare-type-expiration">
              <div className="value-row">
                <div className="left-cell" aria-label={fields.FareTypeExpirationTitleA11y?.value}>
                  {fields.FareTypeExpirationLabel?.value}
                </div>
                <button
                  className="expiration-button"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  aria-label={date}
                >
                  <div className="right-cell isBold">{date}</div>
                </button>
              </div>
            </div>
          ) : null}

          {isPrestoCard ? (
            <>
              <ValueRowSecond left={fields.language?.value} right={card.language} rightBold />
              <ValueRowSecond
                left={fields.setAsPrimaryCard?.value}
                right={card.isPrimary ? t('yes') : t('no')}
                rightBold
                addBottomBorder
              />
            </>
          ) : isAnonymous ? (
            <ValueRowSecond left={fields.language?.value} right={card.language} rightBold />
          ) : (
            <ValueRowSecond
              left={fields.contactlessId?.value}
              right={card.mediaId}
              rightBold
              addBottomBorder
            />
          )}
          {!isAnonymous && (
            <ButtonBlock>
              <ButtonBlock right>
                {showChangePinBtn && (
                  <Button white onClick={() => prepareChangeCardPin()}>
                    <Text field={fields.changePinButtonText} />
                  </Button>
                )}
                {!isPrestoCard ? (
                  <Button white onClick={() => removeCard()}>
                    <Text field={fields.remove} />
                  </Button>
                ) : null}
                <Button white onClick={() => prepareEditDetails()}>
                  <Text field={fields.editDetailsButtonText} />
                </Button>
                {showActivationBtn && (
                  <Button onClick={() => activateSelectedCardDetails()}>
                    {fields.activateThisCardButton?.value?.text}
                  </Button>
                )}
              </ButtonBlock>
            </ButtonBlock>
          )}
        </div>
      </CardBlock>
    </>
  );
};

export default withTranslation()(MediaListItem);
