import React, { useState, useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './ThirdcolumnLayout.scss';
const ThirdcolumnLayout = (props) => {
  const [threeColumnbg, setThreeColumnbg] = useState(false);
  useEffect(() => {
    if (props?.fields?.WhiteBackGround.value) {
      setThreeColumnbg(true);
    }
  }, [props]);

  return (
    <div className={`${threeColumnbg ? 'threecolumn-wrapper' : ''}`}>
      <div
        className={`row ${
          props?.fields?.Cssclass && props.fields.Cssclass.value ? props.fields.Cssclass.value : ''
        }`}
      >
        <Placeholder name="presto-threecolumnlayout" rendering={props.rendering} />
        <div className="column">
          <Placeholder name="firstColumn" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="second-column" rendering={props.rendering} />
        </div>
        <div className="column">
          <Placeholder name="thirdColumn" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export default ThirdcolumnLayout;
