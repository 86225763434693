import React, { useState, useEffect } from 'react';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { Button, ButtonBlock, Loader, SubHeader } from '../../atoms';
import { CardBlock, Select, TextInput, Header } from '../../molecules';

export default function Questions0({ state, toNextStep, fields, t, setState, userInfo }) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [secuQuesLoading, setSecuQuesLoading] = useState(false);
  const [secuQuesWithAnsLoading, setSecuQuesWithAnsLoading] = useState(false);

  useEffect(() => {
    setState({
      securityQuestionsResult: null,
    });
    setSecuQuesLoading(true);
    api
      .getAllSecurityQuestions()
      .then((response) => {
        setSecuQuesLoading(false);
        if (response.status === 200) {
          setAllQuestions(
            response.data.map((i) => ({
              label: i.Item2,
              value: i.Item1,
            }))
          );
        }
      })
      .catch((err) => setSecuQuesLoading(false));
  }, []);

  useEffect(() => {
    setSecuQuesWithAnsLoading(true);
    api
      .getSecurityQuestionsWithAnswers()
      .then((response) => {
        setSecuQuesWithAnsLoading(false);
        if (response.data.Success) {
          setQuestions(
            response.data.Data.map((i) => ({
              label: i.Item1.Text,
              value: i.Item1.Id,
            }))
          );

          setAnswers(
            response.data.Data.map((i) => ({
              answer: i.Item2,
            }))
          );

          setState({
            ...state,
            response,
          });
        } else {
          handleServerSuccessError(response.data.Error, {});
        }
      })
      .catch((err) => setSecuQuesWithAnsLoading(false));
  }, []);

  if (secuQuesLoading || secuQuesWithAnsLoading) return <Loader />;
  if (allQuestions.length === 0 && !questions[0] && !answers[0]) return null;

  return (
    <div className="editSecurity-wrapper security-alerts">
      <div>
        <Header aria-label={fields.securityUpdateHeadingA11y.value} smallerFont>
          <Text field={fields.securityUpdateHeading} />
        </Header>
        <SubHeader>
          <RichText field={fields.securityUpdateHeadingQaA} />
        </SubHeader>
      </div>
      <CardBlock
        title={fields.qaaUpdateHeading?.value}
        titleA11y={fields.qaaUpdateHeadingA11y?.value}
      >
        <RichText
          field={fields.qaaUpdateNote}
          aria-label={fields.qaaUpdateNoteA11y?.value}
          tabIndex="0"
        />

        <Formik
          enableReinitialize
          initialValues={{
            UserName: userInfo.userName,
            Question1Id: !questions[0] ? '' : questions[0].value,
            Question2Id: !questions[1] ? '' : questions[1].value,
            Question3Id: !questions[2] ? '' : questions[2].value,
            Answer1: !answers[0] ? '' : answers[0].answer,
            Answer2: !answers[1] ? '' : answers[1].answer,
            Answer3: !answers[2] ? '' : answers[2].answer,
          }}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            Question1Id: Yup.string().required(t('accountSecuritySelectQuestion')),
            Question2Id: Yup.string().required(t('accountSecuritySelectQuestion')),
            Question3Id: Yup.string().required(t('accountSecuritySelectQuestion')),
            Answer1: Yup.string()
              .required(t('accountSecurityEnterAnswer'))
              .matches(/^[A-Za-z0-9 ]{4,50}$/, t('securityPopupAnswerValidation')),
            Answer2: Yup.string()
              .required(t('accountSecurityEnterAnswer'))
              .matches(/^[A-Za-z0-9 ]{4,50}$/, t('securityPopupAnswerValidation')),
            Answer3: Yup.string()
              .required(t('accountSecurityEnterAnswer'))
              .matches(/^[A-Za-z0-9 ]{4,50}$/, t('securityPopupAnswerValidation')),
          })}
          onSubmit={(values) => {
            api
              .updateSecurityQuestions({
                CustomerId: userInfo.customerId,
                Question1Id: values.Question1Id,
                Question2Id: values.Question2Id,
                Question3Id: values.Question3Id,
                Answer1: values?.Answer1?.trim(),
                Answer2: values?.Answer2?.trim(),
                Answer3: values?.Answer3?.trim(),
              })
              .then((response) => {
                if (response.data.Success) {
                  toNextStep();
                } else if (!response.data.Success && response.data.Error) {
                  setState({
                    errorField: JSON.parse(response.data.Error),
                  });
                  handleServerSuccessError(response.data.Error, {}, null, {});
                } else {
                  setState({
                    ...state,
                    qaaUpdatedResult: 'notUpdated',
                  });
                  toNextStep();
                }
              });
          }}
        >
          {({ errors, touched, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="security-item">
                <Select
                  label={fields.editSecurityQuestion1Label?.value}
                  fullWidth
                  name="Question1Id"
                  errors={errors}
                  touched={touched}
                  options={allQuestions.filter(
                    (i) => i.value !== values.Question2Id && i.value !== values.Question3Id
                  )}
                  placeholder={fields.editSecurityQuestion1Placeholder?.value}
                  labelA11y={fields.editSecurityQuestion1LabelA11y?.value}
                  selectablePlaceholder
                />
                <TextInput
                  name="Answer1"
                  errors={errors}
                  touched={touched}
                  label={fields.editSecurityAnswer1Label}
                  labelA11y={fields.editSecurityAnswer1LabelA11y?.value}
                  marginTopSpacing2
                  withViewIcon
                  showIconA11y={fields.showAnswer1A11y?.value}
                  hideIconA11y={fields.hideAnswer1A11y?.value}
                  bellowText={fields.qaaBellowText}
                />
              </div>
              <div className="security-item">
                <Select
                  label={fields.editSecurityQuestion2Label?.value}
                  name="Question2Id"
                  fullWidth
                  errors={errors}
                  touched={touched}
                  options={allQuestions.filter(
                    (i) => i.value !== values.Question1Id && i.value !== values.Question3Id
                  )}
                  placeholder={fields.editSecurityQuestion2Placeholder?.value}
                  labelA11y={fields.editSecurityQuestion2LabelA11y?.value}
                  selectablePlaceholder
                />
                <TextInput
                  name="Answer2"
                  errors={errors}
                  touched={touched}
                  label={fields.editSecurityAnswer2Label}
                  labelA11y={fields.editSecurityAnswer2LabelA11y?.value}
                  marginTopSpacing2
                  withViewIcon
                  showIconA11y={fields.showAnswer2A11y?.value}
                  hideIconA11y={fields.hideAnswer2A11y?.value}
                  bellowText={fields.qaaBellowText}
                />
              </div>
              <div className="security-item">
                <Select
                  label={fields.editSecurityQuestion3Label?.value}
                  name="Question3Id"
                  fullWidth
                  errors={errors}
                  touched={touched}
                  options={allQuestions.filter(
                    (i) => i.value !== values.Question1Id && i.value !== values.Question2Id
                  )}
                  placeholder={fields.editSecurityQuestion3Placeholder?.value}
                  labelA11y={fields.editSecurityQuestion3LabelA11y?.value}
                  selectablePlaceholder
                />
                <TextInput
                  name="Answer3"
                  errors={errors}
                  touched={touched}
                  label={fields.editSecurityAnswer3Label}
                  labelA11y={fields.editSecurityAnswer3LabelA11y?.value}
                  marginTopSpacing2
                  withViewIcon
                  showIconA11y={fields.showAnswer3A11y?.value}
                  hideIconA11y={fields.hideAnswer3A11y?.value}
                  bellowText={fields.qaaBellowText}
                />
              </div>
              <ButtonBlock>
                <ButtonBlock right>
                  <Button type="submit" buttonTextA11y={fields.editSaveButtonTextA11y?.value}>
                    <Text field={fields.editSaveButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          )}
        </Formik>
      </CardBlock>
    </div>
  );
}
