import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Field } from 'formik';

import viewIcon from '../../assets/images/View.svg';
import hideIcon from '../../assets/images/Hide.svg';
import { IconButton, InlineError } from '../../atoms';
import './textInput.scss';

export default function TextInput({
  errors,
  touched,
  label,
  hasLongLabel,
  name,
  type,
  bellowText,
  placeholder,
  validateField,
  value,
  withMoneyIcon,
  whiteBg,
  onChange,
  onFocus,
  length,
  disabled,
  serverError,
  marginTopSpacing1,
  marginTopSpacing2,
  noMarginTop,
  noMarginTopDesktopOnly,
  withViewIcon,
  inputA11y,
  showIconA11y,
  hideIconA11y,
  innerRef,
  disabledAutocomplete,
  className,
  required = false,
  isError,
  topPlacementofError = false,
  customerTypeErrorforAccountCreation,
  // errorsForB2C,
  // touchedForB2C,
  // nameForB2C,
}) {
  const onChangeObject = onChange ? { onChange } : {};
  const valueObject = value ? { value } : {};
  const error = (touched[name] && errors[name]) || serverError;
  // const errorForB2CScreens = (touchedForB2C[nameForB2C] && errorsForB2C[nameForB2C]) || serverError;
  const [valueShown, setValueShown] = useState(false);
  const toggleValueVisiblity = () => {
    setValueShown(valueShown ? false : true);
  };

  const defaultType = type || 'text';
  const toggleVisibilityType = valueShown ? 'text' : 'password';

  return (
    <div
      className={`${className ? className : ''} text-field ${withMoneyIcon ? 'moneyIcon' : ''} ${
        whiteBg ? 'white-input' : ''
      } ${error ? 'error' : ''}  ${disabled ? 'disabled' : ''} ${
        marginTopSpacing1 ? 'margin-top-spacing1' : ''
      } ${marginTopSpacing2 ? 'margin-top-spacing2' : ''} ${noMarginTop ? 'margin-top-0' : ''} ${
        noMarginTopDesktopOnly ? 'margin-top-0-desktop-only' : ''
      }`}
    >
      {label ? (
        <label className={`text-field-label${hasLongLabel ? ' longLabel' : ''}`} htmlFor={name}>
          <Text field={label} />
        </label>
      ) : null}
      {error && topPlacementofError ? <InlineError>{error}</InlineError> : null}
      {customerTypeErrorforAccountCreation ? (
        <InlineError>{customerTypeErrorforAccountCreation}</InlineError>
      ) : null}
      <div className="text-field-wrapper">
        <Field
          {...onChangeObject}
          {...valueObject}
          onFocus={onFocus}
          name={name}
          id={name}
          type={withViewIcon ? toggleVisibilityType : defaultType}
          className={isError ? 'text-field-input text-field-input-error' : 'text-field-input'}
          placeholder={placeholder}
          validate={validateField}
          maxLength={length}
          disabled={disabled}
          tabIndex="0"
          aria-label={inputA11y}
          aria-required={required}
          innerRef={innerRef}
          autoComplete={disabledAutocomplete ? 'new-password' : null}
        />
        {withViewIcon ? (
          <>
            {valueShown && (
              <IconButton
                onClick={toggleValueVisiblity}
                className="hideIcon"
                icon={hideIcon}
                iconAlt={hideIconA11y}
              />
            )}
            {!valueShown && (
              <IconButton
                onClick={toggleValueVisiblity}
                className="viewIcon"
                icon={viewIcon}
                iconAlt={showIconA11y}
              />
            )}
          </>
        ) : null}
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {error && !topPlacementofError ? (
        <InlineError>{error}</InlineError>
      ) : bellowText ? (
        <div className="bellow-text">
          <Text field={bellowText} />
        </div>
      ) : null}
    </div>
  );
}
