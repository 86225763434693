import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { redirectToUrl } from '../../services/utils';
import { CardBlock, InlineButtonBlock } from '../../molecules';

const AddContactlessCard0 = (props) => {
  const { fields } = props;

  return (
    <CardBlock
      title={fields.addEmailDetailsText.value}
      titleA11y={fields.addEmailDetailsTextA11y.value}
    >
      <div aria-label={fields.noEmailNoticeDescriptionA11y.value}>
        <RichText field={fields.noEmailNoticeDescription} />
      </div>
      <InlineButtonBlock
        buttonText={fields.addEmailButtonText}
        buttonTextA11y={fields.addEmailButtonTextA11y.value}
        onClick={() => redirectToUrl(fields.linkToEmailVerification.value?.href)}
      />
    </CardBlock>
  );
};

export default AddContactlessCard0;
