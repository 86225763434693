import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button } from '../../atoms';
import routes from '../../constants/routes';
import api from '../../services/api';
import { redirectToUrl, getQueryParams, getParameterCaseInsensitive } from '../../services/utils';
import './verifyEmailLink.scss';

const INVALID_EMAIL_VERIFICATION = 0;
const EMAIL_VERIFICATION_EXPIRED = 1;
const EMAIL_ALREADY_VERIFIED = 2;
const EMAIL_VERIFICATION_SUCCESS = 3;
const RESEND_VERIFICATION_SUCCESS = 4;

const VerifyEmailLink = (props) => {
  const queryParams = getQueryParams(useLocation().search);
  const loginEmail = useSelector((reduxState) => reduxState?.user?.loginEmail);
  const [responseMode, setResponseMode] = useState(null);
  const userInfo = useSelector((reduxState) => reduxState.user);
  const authenticated = useSelector((state) => state.user && state.user.authenticated);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  useEffect(() => {
    let payload = {
      Token: getParameterCaseInsensitive(queryParams, 'token'),
      EmailAddress: loginEmail ? loginEmail : '',
      IsStubEnabled: true,
      IsErrorFlowEnabled: false,
    };
    api.verifyEmail(payload).then((response) => {
      setResponseMode(response?.data?.EmailVerificationResponse);
    });
  }, []);
  const resendVerificationEmail = () => {
    api
      .resendVerificationEmail({
        CustomerId: userInfo.customerId,
      })
      .then((response) => {
        if (response.data.Success) {
          setResponseMode(RESEND_VERIFICATION_SUCCESS);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const SuccessMessage = ({ title, titleA11y, message, messageA11y, children = null }) => {
    return (
      <div className="email-verify-container">
        <h2>{props.fields.SucessTitle.value}</h2>
        <div className="email-verify-content success">
          <div className="email-verify-success-message" role="alert">
            <div id="message" aria-label={titleA11y}>
              {title}
            </div>
            {children ? children : <p aria-label={messageA11y}>{message}</p>}
          </div>
        </div>
        <div className="email-verify-footer">
          {authenticated || anonymous ? (
            <Button id="email-verify-footer-btn" onClick={() => redirectToUrl(routes.dashboard)}>
              <Text field={props.fields.DashBoardCTA} />
            </Button>
          ) : (
            <Button onClick={() => redirectToUrl(routes.signIn)}>
              <Text field={props.fields.SIgninCTA} />
            </Button>
          )}
        </div>
      </div>
    );
  };
  const ErrorMessage = ({ title, titleA11y, message, messageA11y }) => {
    return (
      <div className="email-verify-container">
        <h2>{props.fields.VerificationTitle.value}</h2>
        <div className="email-verify-content error">
          <div className="email-verify-error-message" role="alert">
            <div id="message" aria-label={titleA11y}>
              {title}
            </div>
            <p aria-label={messageA11y}>{message}</p>
          </div>
        </div>
        <div className="email-verify-footer">
          <Button onClick={() => redirectToUrl(routes.signIn)}>
            <Text field={props.fields.SIgninCTA} />
          </Button>
        </div>
      </div>
    );
  };
  if (responseMode === INVALID_EMAIL_VERIFICATION) {
    return (
      <ErrorMessage
        title={props?.fields?.LinkError?.value}
        titleA11y={props?.fields?.LinkErrorA11y?.value}
        message={props?.fields?.SigninagainNote?.value}
        messageA11y={props?.fields?.SigninagainNoteA11y?.value}
      />
    );
  }
  if (responseMode === EMAIL_VERIFICATION_EXPIRED) {
    return (
      <ErrorMessage
        title={props?.fields?.LinkExpiredTitle?.value}
        titleA11y={props?.fields?.LinkExpiredTitleA11y?.value}
        message={props?.fields?.SigninagainNote?.value}
        messageA11y={props?.fields?.SigninagainNoteA11y?.value}
      />
    );
  }
  if (responseMode === EMAIL_ALREADY_VERIFIED) {
    return (
      <SuccessMessage
        title={props.fields.EmailSuccess.value}
        titleA11y={props.fields.EmailSuccessA11y.value}
        message={props?.fields?.EmailVerifiedDescription?.value}
        messageA11y={props?.fields?.EmailVerifiedDescriptionA11y?.value}
      />
    );
  }
  if (responseMode === EMAIL_VERIFICATION_SUCCESS) {
    if (authenticated || anonymous) {
      return (
        <SuccessMessage
          title={props.fields.EmailSuccess.value}
          titleA11y={props.fields.EmailSuccessA11y.value}
          message={props?.fields?.SignInNote?.value}
          messageA11y={props?.fields?.SignInNoteA11y?.value}
        />
      );
    }
    return (
      <SuccessMessage
        title={props.fields.EmailSuccess.value}
        titleA11y={props.fields.EmailSuccessA11y.value}
        message={props?.fields?.SigninCheckBalance?.value}
        messageA11y={props?.fields?.SigninCheckBalanceA11y?.value}
      />
    );
  }
  if (responseMode === RESEND_VERIFICATION_SUCCESS) {
    return (
      <SuccessMessage
        title={props?.fields?.VerifyEmailSuccessMessage.value}
        titleA11y={props?.fields?.VerifyEmailSuccessMessageA11y.value}
        message={props?.fields?.SucessDescription?.value}
        messageA11y={props?.fields?.SucessDescriptionA11y?.value}
      >
        <div>
          <p>{props?.fields?.VerifyEmailSentLabel.value}</p>
          <p className="verify-email-info">{userInfo?.email}</p>
          <p>{props?.fields?.VerifyEmailNote.value}</p>
        </div>
      </SuccessMessage>
    );
  }
  return null;
};

export default VerifyEmailLink;