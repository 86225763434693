import React, { useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { NavLink, PrestoLink } from '../../atoms';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import '../TopNavigationAuthenticated/topNavigationAuthenticated.scss';
import mediaTypes from '../../constants/mediaTypes';
import cardStatuses from '../../constants/cardStatuses';
import { getParentUrl, isFrench } from '../../services/utils';
import { fetchWalletCardDetails } from '../../redux/user/actions';
import routes from '../../constants/routes';
import api from '../../services/api';
import langConstants from '../../constants/lang';
import mediaSubType from '../../constants/mediaSubType';
import useSelectedCard from '../../hooks/useSelectedCard';

const TopNavigationCategoryItem = ({ navigationItem, contextItem }) => {
  const dispatch = useDispatch();
  const selectedPrestoCard = useSelectedCard();
  const dropdownRef = useRef(null);
  const userInfo = useSelector((state) => state.user);
  const { fareMedias, contactlessMedias, selectedCard } = userInfo;
  const allCards = fareMedias.concat(contactlessMedias);
  const [active, setActive] = useDetectOutsideClick(dropdownRef, false, true);
  const isMobileWallet = selectedCard.mediaType === mediaTypes.CRDNC;
  const isAppleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.APPLE;
  const isGoogleWalletCard = selectedCard.mediaSubTypeWallet === mediaSubType.GOOGLE;
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const isHiddenByFieldValue = (item, status) => {
    const statusName = getKeyByValue(cardStatuses, status);
    if (statusName && item.fields) {
      const fieldName = `HideFor${statusName}Card`;
      const statusField = item.fields.find((field) => {
        return field.name.replace(/\s/g, '') === fieldName;
      });
      return statusField?.jss.value === true;
    }

    return false;
  };

  if (navigationItem.Title && navigationItem.ShowInDesktop?.jss?.value === true) {
    const isSupportNavLinkActive = navigationItem.children?.some((a) => {
      return (
        a.Link?.jss.value?.href.includes('support') &&
        getParentUrl(a.Link?.jss.value.href) === getParentUrl(contextItem.url)
      );
    });

    return (
      <li>
        <NavLink
          title={navigationItem.Title?.value}
          onClick={setActive}
          isDropdown={active === navigationItem.Title?.value}
          isActive={isSupportNavLinkActive}
        />
        <ul ref={dropdownRef} className="dropdown-menu" role="menu">
          {navigationItem.children?.map((a, i) => {
            let hasChild = a?.children?.length > 0;
            let disableParent = a?.DisableParentLink?.jss?.value;
            let EnableSubNavigationMenu = a?.EnableSubNavigationMenu?.jss?.value;
            let className = `${disableParent ? `disable-nav-link` : ``}`;
            return (
              <li
                className={`${hasChild ? `menuitem--parent` : ``}`}
                key={i + a.Link?.jss}
                role="menuitem"
              >
                {(isHiddenByFieldValue(a, selectedPrestoCard?.cardStateCode)) ? ''
                  : <PrestoLink
                    field={a.Link?.jss}
                    className={className}
                    onClickCustom={(e) => {
                      if (hasChild && EnableSubNavigationMenu) {
                        e.preventDefault();
                      }
                    }}
                  />}


                {hasChild && EnableSubNavigationMenu ? (
                  <ul className="dropdown-menu-child" role="menu">
                    {a.children.map((gChild, j) => (
                      <li key={j + gChild.Link?.jss} role="menuitem">
                        <PrestoLink field={gChild.Link?.jss} />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
  const navigationProp = navigationItem.Link?.jss;
  const handleMyProductsAPIClick = () => {
    if (navigationItem.Link?.jss.value.href.includes('my-products') && isMobileWallet) {
      if (contactlessMedias[0].dpan === selectedCard.dpan && !selectedCard.cardNumber) {
        dispatch(fetchWalletCardDetails);
      } else {
        api
          .getStoredMediaTypeInSession({
            MediaType: selectedCard.mediaSubTypeWallet,
          })
          .then(({ res }) => {
            console.log('response', res);
          });
      }
    }
  };
  const checkMediaType = (array) => {
    if (array.length !== 0) {
      for (let i = 0; i < array.length; i++) {
        if (!array[i].hasOwnProperty('mediaType') || array[i].mediaType !== mediaTypes.CRDNC) {
          return false;
        }
      }
      return true;
    }
  };
  const onlyGWCards = checkMediaType(allCards);
  return (
    <li>
      <PrestoLink
        iconColor="white"
        field={
          navigationItem.Link?.jss.value.href.includes('my-products')
            ? isMobileWallet
              ? {
                  ...navigationProp,
                  value: {
                    ...navigationProp?.value,
                    href: `/${isFrench() ? langConstants.longFr : langConstants.en}${
                      routes.walletCardDetails
                    }`,
                  },
                }
              : onlyGWCards
              ? {
                  ...navigationProp,
                  value: {
                    ...navigationProp?.value,
                    href: `/${isFrench() ? langConstants.longFr : langConstants.en}${
                      routes.walletCardDetails
                    }`,
                  },
                }
              : navigationProp
            : navigationProp
        }
        className={`${
          navigationItem.Link?.jss.value.href === contextItem.url ||
          (navigationItem.Link?.jss.value.href.includes('my-products') &&
            getParentUrl(navigationItem.Link?.jss.value.href) === getParentUrl(contextItem.url))
            ? 'active'
            : ''
        }`}
        onClickCustom={handleMyProductsAPIClick}
      />
    </li>
  );
};

export default TopNavigationCategoryItem;
